import Cookies from 'js-cookie';
import { loginActiveStep } from '../../helpers/constants';
import { getLocalData } from '../../helpers/Utils';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_CAPABILITIES = 'SET_USER_CAPABILITIES';
export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';
export const SET_REGISTRATION_SMS_TOKEN = 'SET_REGISTRATION_SMS_TOKEN';
export const SET_SELECTED_CR = 'SET_SELECTED_CR';
export const SET_IS_MANAGER = 'SET_IS_MANAGER';

const initialState = {
  redirectURL: null,
  loginProcess: {
    activeLoginStep: loginActiveStep.loginAndBanner,
    cachedLoginStepData: null,
  },
  userToken: Cookies.get('token') || null,
  user: getLocalData('userinfo'),
  capabilities: null,
  account: {},
  registration: {
    form: null,
    smsToken: null,
    companyInformation: {
      selectedCR: null,
      isManager: 0,
    },
  },
  quickSignUp: {
    isFinishAndSuccess: false,
  },
};

export default function AuthReducer(state = initialState, action) {
  const draft = { ...state };
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };

    case 'SET_REDIRECT_URL':
      draft.redirectURL = action.payload;
      return draft;

    case 'SET_ACTIVE_LOGIN_STEP':
      draft.loginProcess.activeLoginStep = action.payload;
      return draft;

    case 'SET_CACHED_LOGIN_STEP_DATA':
      draft.loginProcess.cachedLoginStepData = action.payload;
      return draft;

    case 'SET_USER_TOKEN':
      draft.userToken = action.payload;
      return draft;

    case 'SET_FINISH_QUICK_SIGN_UP':
      draft.quickSignUp.isFinishAndSuccess = action.payload;
      return draft;

    case SET_SELECTED_CR:
      draft.registration.companyInformation.selectedCR = action.payload;
      return draft;

    case SET_IS_MANAGER:
      draft.registration.companyInformation.isManager = action.payload;
      return draft;

    case SET_REGISTRATION_SMS_TOKEN:
      draft.registration.smsToken = action.payload;
      return draft;

    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };

    case 'CLEAR_USER_DATA':
      return {
        ...state,
        user: null,
        capabilities: null,
        userToken: null,
        loginProcess: {
          activeLoginStep: loginActiveStep.loginAndBanner,
          cachedLoginStepData: null,
        },
      };

    case SET_USER_CAPABILITIES:
      if (action.payload?.role[0] && action.payload?.role[0]?.permissions) {
        const permissionsArr = action.payload.role[0].permissions;

        const serializedCapabilities = {};

        permissionsArr.forEach((ele) => {
          serializedCapabilities[ele.name] = ele.name;
        });

        return {
          ...state,
          capabilities: serializedCapabilities,
        };
      }
      return {
        ...state,
      };

    case SET_USER_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
