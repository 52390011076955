/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import 'moment/locale/en-gb';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import Select from 'react-select';
// eslint-disable-next-line import/no-extraneous-dependencies
import gregorian from 'react-date-object/calendars/gregorian';

// eslint-disable-next-line camelcase,import/no-extraneous-dependencies
import moment from 'moment-hijri';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import {useDispatch, useSelector} from 'react-redux';
import {setAction} from '../../helpers/ActionsHelper';
import {InlineTextInput} from '../FormInputs/Inputs';
import {AppBody} from "../html/html";

function DateFilter({
  setDaysFilter,
  setFormDateFilter,
  setToDateFilter,
  setTypeDateFilter,
  split,
  setPage,
}) {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const { filterDays, filterFrom, filterTo, filterDateType, filterDaysSplit, filterDateTypeSplit } =
    useSelector((state) => state.transactions);

  const withinOptions = [
    { value: 'days', label: t('within') },
    { value: 'today', label: t('today') },
    { value: 'month', label: t('month_of') },
    { value: 'custom', label: t('custom') },
  ];
  const daysFilters = [
    { value: '7', label: t('last_7') },
    { value: '30', label: t('last_30') },
    { value: '60', label: t('last_60') },
    { value: '90', label: t('last_90') },
    { value: '180', label: t('last_180') },
  ];

  // eslint-disable-next-line no-nested-ternary
  const [selectedDateOptions, setSelectedDateOptions] = useState(withinOptions[0]);
  const [selectedDaysOptions, setSelectedDaysOptions] = useState(daysFilters[1]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const monthsFilters = [
    {
      value: '0',
      label: t('january'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 0,
    },
    {
      value: '1',
      label: t('february'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 1,
    },
    {
      value: '2',
      label: t('march'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 2,
    },
    {
      value: '3',
      label: t('april'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 3,
    },
    {
      value: '4',
      label: t('may'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 4,
    },
    {
      value: '5',
      label: t('june'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 5,
    },
    {
      value: '6',
      label: t('july'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 6,
    },
    {
      value: '7',
      label: t('august'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 7,
    },
    {
      value: '8',
      label: t('september'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 8,
    },
    {
      value: '9',
      label: t('october'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 9,
    },
    {
      value: '10',
      label: t('november'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 10,
    },
    {
      value: '11',
      label: t('december'),
      isDisabled: selectedYear === new Date().getFullYear() && new Date().getMonth() < 11,
    },
  ];

  const [selectedMonthOptions, setSelectedMonthOptions] = useState(monthsFilters[0]);
  const todayDate = new Date();
  const lastThreeMonth = new Date(todayDate.setDate(todayDate.getDate() - 93));
  const [startDate, setStartDate] = useState(lastThreeMonth.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
  }

  const filterChange = (value) => {
    setSelectedDateOptions(
      withinOptions && withinOptions.filter((option) => value === option.value)[0],
    );
    if (value === 'days') {
      setSelectedDaysOptions({ value: '30', label: t('last_30') });
    } else if (value === 'month') {
      setSelectedMonthOptions({ value: '0', label: t('january') });
      setSelectedYear(new Date().getFullYear());
    } else if (value === 'custom') {
      setStartDate(lastThreeMonth.toISOString().split('T')[0]);
      setEndDate(new Date().toISOString().split('T')[0]);
    }
  };

  useEffect(() => {
    if (split) {
      if (filterDateTypeSplit === null && parseInt(filterDaysSplit, 10) === 30) {
        filterChange('days');
      }
    } else if (filterDateType === null && parseInt(filterDays, 10) === 30) {
      filterChange('days');
    } else if (filterDateType === null && parseInt(filterDays, 10) === 1) {
      setSelectedDateOptions(
        withinOptions && withinOptions.filter((option) => option.value === 'today')[0],
      );
    } else if (filterDateType === null) {
      setSelectedDateOptions(
        withinOptions && withinOptions.filter((option) => option.value === 'days')[0],
      );
      setSelectedDaysOptions(
        daysFilters && daysFilters.filter((option) => option.value === filterDays)[0],
      );
    } else if (filterDateType === 'month') {
      setSelectedDateOptions(
        withinOptions && withinOptions.filter((option) => option.value === 'month')[0],
      );
      setSelectedMonthOptions(
        filterFrom ? monthsFilters[new Date(filterFrom).getMonth()] : monthsFilters[0],
      );
      setSelectedYear(filterFrom ? new Date(filterFrom).getFullYear() : new Date().getFullYear());
    } else if (filterDateType === 'custom') {
      setSelectedDateOptions(
        withinOptions && withinOptions.filter((option) => option.value === 'custom')[0],
      );
      setStartDate(
        filterFrom
          ? new Date(filterFrom).toISOString().split('T')[0]
          : lastThreeMonth.toISOString().split('T')[0],
      );
      setEndDate(
        filterTo
          ? new Date(filterTo).toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0],
      );
    }
  }, [split]);

  useEffect(() => {
    setPage(1);
    if (selectedDateOptions.value === 'today') {
      dispatch(setAction(setDaysFilter, 1));
      dispatch(setAction(setTypeDateFilter, null));
      dispatch(setAction(setFormDateFilter, null));
      dispatch(setAction(setToDateFilter, null));
    }

    if (selectedDateOptions.value === 'days') {
      dispatch(setAction(setDaysFilter, selectedDaysOptions.value));
      dispatch(setAction(setTypeDateFilter, null));
      dispatch(setAction(setFormDateFilter, null));
      dispatch(setAction(setToDateFilter, null));
    }

    if (selectedDateOptions.value === 'month' && selectedYear && selectedMonthOptions) {
      dispatch(setAction(setDaysFilter, null));
      dispatch(setAction(setTypeDateFilter, 'month'));
      dispatch(
        setAction(
          setFormDateFilter,
          moment(
            new Date(getFirstDayOfMonth(selectedYear, parseInt(selectedMonthOptions.value, 10))),
          )
            .locale('en')
            .format('YYYY-MM-DD'),
        ),
      );
      dispatch(
        setAction(
          setToDateFilter,
          moment(
            new Date(getLastDayOfMonth(selectedYear, parseInt(selectedMonthOptions.value, 10))),
          )
            .locale('en')
            .format('YYYY-MM-DD'),
        ),
      );
    }
    if (selectedDateOptions.value === 'custom' && selectedYear && selectedMonthOptions) {
      dispatch(setAction(setDaysFilter, null));
      dispatch(setAction(setTypeDateFilter, 'custom'));
      dispatch(setAction(setFormDateFilter, startDate));
      dispatch(setAction(setToDateFilter, endDate));
    }
  }, [
    selectedDaysOptions,
    selectedDateOptions,
    startDate,
    endDate,
    selectedMonthOptions,
    selectedYear,
    split,
  ]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFFFFF80',
      borderColor: 'rgb(199,208,213)',
      fontWeight: 'normal',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingLeft: '0rem',
      paddingRight: '0rem',
      minHeight: '20px',
      marginTop: '0rem',
      boxShadow: 'none',
      borderRadius: '5px',
      fontSize: '0.75rem',
      lineHeight: '1rem',
    }),
  };

  return (
    <AppBody type="label" pClass="Caption1Regular"
      htmlFor="show_result_for"
      className="w-full justify-start ltr:text-left rtl:text-right"
    >
      <span className={split ? 'hidden' : 'hidden lg:inline'}>{t('show_result_for')}</span>{' '}
      <Select
        styles={customStyles}
        className="rlt:font-normal inline-block w-auto text-xs ltr:font-light"
        key="show_result_for_key"
        value={selectedDateOptions}
        isSearchable
        name="show_result_for_key"
        options={withinOptions}
        onChange={(e) => filterChange(e.value)}
      />{' '}
      {selectedDateOptions.value === 'days' && (
        <Select
          styles={customStyles}
          className="rlt:font-normal inline-block w-auto text-xs ltr:font-light"
          key="days_options"
          value={selectedDaysOptions}
          isSearchable
          name="days_options"
          options={daysFilters}
          onChange={(e) => {
            setSelectedDaysOptions(daysFilters.filter((option) => option.value === e.value)[0]);
          }}
        />
      )}
      {selectedDateOptions.value === 'month' && (
        <>
          <Select
            styles={customStyles}
            className="rlt:font-normal inline-block w-auto text-xs ltr:font-light"
            key="month_options"
            value={selectedMonthOptions}
            isSearchable
            name="month_options"
            options={monthsFilters}
            onChange={(e) => {
              setSelectedMonthOptions(monthsFilters.filter((option) => option.value === e.value));
              setSelectedMonthOptions(e);
            }}
          />{' '}
          <InlineTextInput
            className="rlt:font-normal rlt:font-normal inline-block w-auto border px-5 py-1 text-xs ltr:font-light ltr:font-light"
            style={{ 'border-radius': '5px', 'border-color': 'rgb(199,208,213)', width: '70px' }}
            id="year"
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
            }}
          />
        </>
      )}
      {selectedDateOptions.value === 'custom' && (
        <div className="inline-block">
          <CustomRow
            split={split}
            endDate={endDate}
            startDate={startDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
        </div>
      )}
    </AppBody>
  );
}

function CustomRow({ startDate, endDate, setStartDate, setEndDate, split }) {
  const { t } = useTranslation(['common']);
  const [calendarType] = useState(gregorian);
  const [calendarLocaleType] = useState(gregorian_en);
  return (
    <div
      className={`${
        split ? 'w-full' : 'w-full lg:m-0 lg:w-auto lg:rtl:text-left lg:rtl:text-right'
      } m-auto text-center`}
    >
      <span className="inline-block px-2">{t('from')}</span>
      <DatePicker
        id="start_date"
        className="w-full"
        style={{
          'border-radius': '5px',
          'border-color': 'rgb(199,208,213)',
          width: '110px',
          display: 'inline',
        }}
        containerClassName="w-full"
        maxDate={endDate}
        calendar={calendarType}
        locale={calendarLocaleType}
        inputClass="border py-1 px-5 mt-1 text-xs ltr:font-light rlt:font-normal w-auto inline"
        onChange={(date) => {
          if (date) {
            setStartDate(moment(new Date(date)).locale('en').format('YYYY-MM-DD'));
            if (
              new Date(endDate) > new Date(new Date(date).setDate(new Date(date).getDate() + 93))
            ) {
              setEndDate(
                moment(new Date(new Date(new Date(date).setDate(new Date(date).getDate() + 93))))
                  .locale('en')
                  .format('YYYY-MM-DD'),
              );
            }
          }
        }}
        dropdownMode="scroll"
        showYearDropdown
        value={startDate}
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <span className="inline-block px-2">{t('to')}</span>
      <DatePicker
        id="end_date"
        className="w-full"
        style={{
          'border-radius': '5px',
          'border-color': 'rgb(199,208,213)',
          width: '110px',
          display: 'inline',
        }}
        containerClassName="w-full"
        maxDate={
          startDate && new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 93))
        }
        calendar={calendarType}
        locale={calendarLocaleType}
        inputClass="border py-1 px-5 mt-1 text-xs ltr:font-light rlt:font-normal w-auto inline"
        onChange={(date) => {
          if (date) {
            setEndDate(moment(new Date(date)).locale('en').format('YYYY-MM-DD'));
          }
        }}
        dropdownMode="scroll"
        showYearDropdown
        value={endDate}
        selected={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  );
}
export default DateFilter;
