import { appendURLParams } from 'javascript-functions';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import { AppHeader } from '../../../../components/html/html';
import { devLog, generateMockCard, getServerErrMessage } from '../../../../helpers/Utils';
import { cardStatusKeys, cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import {
  saveNewCreatedCardAction,
  setActiveCreateNewCardStepAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import NqoodCardAnimation from './NqoodCardAnimation';

function VirtualCardActivation() {
  const { useMock } = useSelector((state) => state);
  const { selectedCardDesign, selectedCardProgram, otpData, createCardUserData, cardToReplace } =
    useSelector((state) => state.createNewCard);
  const { t } = useTranslation('cardsV2');

  const dispatch = useDispatch();
  const [isCardCreating, setIsCardCreating] = useState(true);

  const findVirtualCard = useCallback(
    (card, tryCount = 0, notFoundCount = 0) => {
      let mockParams = null;

      if (useMock.createVirtualCardMock) {
        devLog(`Mocking [Create] Virtual Card`);
        mockParams = generateMockCard({
          status: useMock.createVirtualCardMockStatus,
          isPhysical: false,
          design: selectedCardDesign,
        });
      }

      if (useMock.replaceVirtualCardMock) {
        devLog(`Mocking [Replace] Virtual Card`);
        mockParams = generateMockCard({
          status: useMock.replaceVirtualCardMockStatus,
          isPhysical: false,
        });
      }

      http
        .get(`cardmanager/cards/show/${card.id}`, {
          loader: 'findVirtualCard',
          params: { successIfNotFound: true },
          useMock: useMock.createVirtualCardMock || useMock.replaceVirtualCardMock,
          mockParams,
        })
        .then((res) => {
          if (Object.keys(res?.data).length) {
            // ==================== Saving New Created Card ==================== //
            dispatch(saveNewCreatedCardAction(res.data));
            const cardStatus = res?.data?.status?.key.toUpperCase();

            // ==================== if ACTIVE ==================== //
            if (cardStatus === cardStatusKeys.ACTIVE) {
              devLog(`Card Found and ${cardStatus}!`);
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.manageCardControl));

              // ==================== if INACTIVE ==================== //
            } else if (cardStatus === cardStatusKeys.INACTIVE) {
              devLog(`Card Found and it's Status is [${cardStatus}], Manual Activation Needed!`);
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.manualCardActivation));

              // ==================== if PENDING ==================== //
            } else if (cardStatus === cardStatusKeys.PENDING) {
              if (tryCount < 1) {
                devLog(
                  `Card Found and it's status is [${cardStatus}], tryCount: [${tryCount + 1}]`,
                );
                setTimeout(
                  () => {
                    findVirtualCard(card, tryCount + 1);
                  },
                  useMock.createVirtualCardMock || useMock.replaceVirtualCardMock ? 2000 : 15000,
                );

                // ==================== if PENDING and Still PENDING ==================== //
              } else {
                history.replace({
                  search: appendURLParams(
                    'creationError',
                    `Card Status is still ${cardStatus} after more than 30 seconds.`,
                  ),
                });
                dispatch(
                  setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardActivationFailed),
                );
              }
              // ==================== if Any Other STATUS ==================== //
            } else {
              devLog(`Card Found and it's status is [${cardStatus}]`);
              history.replace({
                search: appendURLParams('creationError', `Created Card Status is ${cardStatus}`),
              });
              dispatch(
                setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardActivationFailed),
              );
            }
          } else if (notFoundCount < 1) {
            devLog(`No Card Found, notFoundCount: [${notFoundCount + 1}]`);
            setTimeout(
              () => {
                findVirtualCard(card, tryCount, notFoundCount + 1);
              },
              useMock.createVirtualCardMock || useMock.replaceVirtualCardMock ? 2000 : 15000,
            );
            // ==================== if Not Found and Still Not Found ==================== //
          } else {
            history.replace({
              search: appendURLParams(
                'creationError',
                `Card Status is still NOT Found after more than 30 seconds.`,
              ),
            });
            dispatch(
              setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardActivationFailed),
            );
          }
        })
        .catch((err) => {
          devLog(`Finding Virtual Card Failed due to: ${getServerErrMessage(err)}`);
          history.replace({
            search: appendURLParams('creationError', getServerErrMessage(err)),
          });
          dispatch(
            setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardActivationFailed),
          );
        });
    },
    [useMock],
  );

  const createVirtualCard = useCallback(() => {
    setIsCardCreating(true);
    const body = {
      token: otpData.OTPToken,
      code: otpData.code,
      user_id: createCardUserData?.id,
      name_on_card: `${createCardUserData?.first_name} ${createCardUserData?.last_name}`,
      card_type: cardTypesKeys.VIRTUAL,
      card_product_id: selectedCardProgram.id,
      design_id: selectedCardDesign?.id || '',
      card_name: selectedCardDesign?.cardName || '',
    };

    http
      .post(`cardmanager/cards`, body, {
        headers: { 'Content-Type': 'application/json' },
        loader: 'createVirtualCard',
        lang: 'en',
        useMock: useMock.createVirtualCardMock,
        mockParams: generateMockCard({ status: useMock.createVirtualCardMockStatus }),
      })
      .then((res) => {
        if (
          useMock.createVirtualCardMock &&
          useMock.createVirtualCardMockStatus === 'CREATION FAILED'
        ) {
          history.replace({
            search: appendURLParams('creationError', 'Virtual Card CREATION FAILED!'),
          });
          dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardCreationFailed));
        } else {
          devLog('Virtual Card Created!');
          setIsCardCreating(false);
          setTimeout(
            () => {
              findVirtualCard(res.data);
            },
            useMock.createVirtualCardMock ? 2000 : 15000,
          );
        }
      })
      .catch((err) => {
        history.replace({
          search: appendURLParams('creationError', getServerErrMessage(err)),
        });
        dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardCreationFailed));
      });
  }, [otpData, createCardUserData, selectedCardProgram, selectedCardDesign, useMock]);

  const replaceVirtualCard = useCallback(() => {
    const body = {
      token: otpData.OTPToken,
      code: otpData.code,
    };

    http
      .post(`cardmanager/cards/${cardToReplace.id}/renew`, body, {
        headers: { 'Content-Type': 'application/json' },
        loader: 'replaceVirtualCard',
        useMock: useMock.replaceVirtualCardMock,
        mockParams: generateMockCard({ status: cardStatusKeys.EXPIRED, isPhysical: false }),
      })
      .then((res) => {
        devLog('Virtual Card Replaced!');
        setTimeout(
          () => {
            findVirtualCard({ id: res?.data?.new_uuid });
          },
          useMock.replaceVirtualCardMock ? 2000 : 15000,
        );
      })
      .catch((err) => {
        devLog(`Virtual Card [Replacing] Failed due to: ${getServerErrMessage(err)}`);
        history.replace({ search: appendURLParams('creationError', getServerErrMessage(err)) });
        dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardActivationFailed));
      });
  }, [useMock, otpData, cardToReplace]);

  useEffect(() => {
    if (cardToReplace) {
      replaceVirtualCard();
    } else {
      createVirtualCard();
    }

    return () => {
      setIsCardCreating(true);
    };
  }, [cardToReplace]);

  return (
    <div className="mx-auto mt-[25vh] max-w-[350px] pb-5 sm:px-0 md:px-5">
      {isCardCreating && <AppHeader h="h3">{t('Your Card is Currently Under Creation')}</AppHeader>}

      {!isCardCreating && (
        <AppHeader h="h3">{t('Your Card is Currently Under Activation')}</AppHeader>
      )}

      <div className="my-5">
        <NqoodCardAnimation />
      </div>
    </div>
  );
}

export default VirtualCardActivation;
