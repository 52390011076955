import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppImageComponent from '../../../../components/AppImageComponent';
import AppSpinner from '../../../../components/AppSpinner';
import BackBtn from '../../../../components/BackBtn';
import AppTextInput from '../../../../components/FormInputs/AppTextInput';
import TranslatedData from '../../../../components/TranslatedData';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { generateCardName, getServerErrMessage } from '../../../../helpers/Utils';
import { cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  selectCardDesignAction,
  setActiveCreateNewCardStepAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';

function CustomizePrimaryCard() {
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();
  const { loaders, useMock, appConfig } = useSelector((state) => state);

  const {
    selectedCardProgram,
    selectedCardDesign,
    availableCardTypes,
    userPrimaryCard,
    selectedCardType,
  } = useSelector((state) => state.createNewCard);

  const [cardName, setCardName] = useState('');
  const [firstCardName, setFirstCardName] = useState('');
  const [secondCardName, setSecondCardName] = useState('');
  const [availableDesigns, setAvailableDesigns] = useState([]);
  const [defaultCardDesign, setDefaultCardDesign] = useState(null);

  const cardCurrencyAndFeesData = useMemo(() => {
    return availableCardTypes?.find((ele) => ele.name === cardTypesKeys.VIRTUAL);
  }, [availableCardTypes]);

  const isComingFromCreateOrReplaceStep = useMemo(() => {
    // user selected replace physical card
    if (
      (selectedCardType.name === cardTypesKeys.PHYSICAL &&
        selectedCardProgram.physical_can_create_and_replace) ||
      useMock.plasticCreateAndReplaceMock
    ) {
      return true;
    }

    // user selected replace virtual card
    if (
      (selectedCardType.name === cardTypesKeys.VIRTUAL &&
        selectedCardProgram.virtual_can_create_and_replace) ||
      useMock.virtualCreateAndReplaceMock
    ) {
      return true;
    }

    return false;
  }, [selectedCardType, selectedCardProgram, useMock, cardTypesKeys]);

  const getCardDesigns = useCallback(() => {
    http
      .get(`cardmanager/card-design/available-designs`, {
        params: {
          card_product_id: selectedCardProgram?.id,
        },
        loader: 'getCardDesigns',
      })
      .then((res) => {
        setAvailableDesigns(res.data || []);
        const defaultDesign = res?.data.find((ele) => ele.is_default);
        setDefaultCardDesign(defaultDesign);
        dispatch(selectCardDesignAction(defaultDesign || null));
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [selectedCardProgram]);

  const handleNext = useCallback(() => {
    dispatch(selectCardDesignAction({ ...selectedCardDesign, cardName }));
    dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.sendOTP));
  }, [cardName, selectedCardDesign]);

  const handleChangeName = useCallback((e) => {
    const { value } = e.target;
    const finalValue = generateCardName(value);
    const firstWord = finalValue.split(' ')[0];
    const secondWord = finalValue.split(' ')[1];

    setCardName(finalValue);

    setFirstCardName(firstWord);
    setSecondCardName(secondWord || '');
  }, []);

  useEffect(() => {
    getCardDesigns();
  }, []);

  return (
    <div className="mx-auto mt-[10vh] w-full p-5 sm:max-w-[550px]">
      <div className="mb-5 ">
        <BackBtn
          className="mb-4"
          onClick={() => {
            if (isComingFromCreateOrReplaceStep) {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createOrReplaceCard));
            } else if (!userPrimaryCard) {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPrimaryCard));
            } else {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardType));
            }
          }}
        />
        <AppHeader h="h3">{t("Let's Customize Your Virtual Card")}</AppHeader>
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t(
            'To assist you in identifying the card, color and name will be exclusively visible via our web platform and our mobile App',
          )}
        </AppBody>
      </div>

      <div className="rounded-lg border border-gray-3 bg-white px-4 py-5 md:px-16">
        {/* Change Color */}
        <div className="mb-3">
          <AppBody pClass="Body2Bold" className="mb-1">
            {t('Assign Color', { ns: 'cardsV2' })}
          </AppBody>
          <div className="flex flex-col gap-3 sm:flex-row">
            <div className="relative h-[103px] w-[164px] overflow-hidden rounded-lg shadow">
              <AppImageComponent
                src={selectedCardDesign?.attachment}
                skeletonWidth={164}
                skeletonHeight={103}
              />
              {/* ====================== Card Name ====================== */}
              {!loaders.getCardDesigns && !!availableDesigns?.length && (
                <div
                  style={{ direction: 'ltr' }}
                  className="absolute left-2 top-3 flex items-center text-5xl text-[9px]"
                >
                  <i
                    className="fa-kit fa-nqoodlet-icon-left text-sm"
                    style={{ color: selectedCardDesign?.color_hex }}
                  />

                  <span
                    className="pl-1"
                    style={{
                      color: selectedCardDesign?.color_hex,
                    }}
                  >
                    {firstCardName && <span className="block">{firstCardName}</span>}
                    {secondCardName && <span className="block">{secondCardName}</span>}

                    {!cardName && !loaders.getCardDesigns && (
                      <span className="flex">
                        <span className="font-black">Nqood</span>
                        <span>let</span>
                      </span>
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-row flex-wrap gap-2 sm:flex-col">
              {availableDesigns.map((ele) => {
                return (
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(selectCardDesignAction(ele));
                    }}
                    key={ele.id}
                    className="group flex items-center gap-1"
                  >
                    <div
                      style={{ backgroundColor: ele.color_hex }}
                      className={`h-5 w-5 rounded-full shadow outline-stone-500 ${
                        selectedCardDesign?.id === ele.id ? 'outline outline-2' : 'border'
                      }`}
                    />
                    <span
                      className="text-sm opacity-100 transition-opacity duration-75 group-hover:opacity-100 sm:opacity-0"
                      style={{ color: ele.color_hex }}
                    >
                      <TranslatedData en={ele.color_name} ar={ele.arabic_color_name} />
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* ========= Assign Name ========= */}
        <div className="mb-4">
          <AppBody pClass="Body2Bold" className="mb-1">
            {t('Assign Name', { ns: 'cardsV2' })}
          </AppBody>
          <AppTextInput
            value={cardName}
            maxLength={25}
            onChange={handleChangeName}
            disabled={loaders.getCardDesigns}
          />
        </div>
      </div>

      <div className="mt-5 flex flex-col items-center justify-between pb-5 sm:flex-row">
        <p className="flex items-center gap-2">
          <span className="text-sm">{t('Virtual Card Annual Fees: ')}</span>
          {!loaders.getSingleCardProgram && (
            <span style={{ direction: 'ltr' }} className="flex gap-1">
              <AppBody pClass="Body1Bold" type="span">
                {cardCurrencyAndFeesData?.fee}
              </AppBody>
              <AppBody pClass="Body1Bold" type="span">
                {cardCurrencyAndFeesData?.currency}
              </AppBody>
            </span>
          )}
          {loaders.getSingleCardProgram && (
            <span>
              <AppSpinner size={20} />
            </span>
          )}
        </p>

        <div className="flex gap-3">
          <AppButton
            button="link"
            onClick={() => {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.sendOTP));
              dispatch(selectCardDesignAction(defaultCardDesign));
            }}
          >
            {t('Skip', { ns: 'commonV2' })}
          </AppButton>

          <AppButton onClick={handleNext} isLoading={false} button="black">
            {t('Create Your Virtual Card', { ns: 'cardsV2' })}
          </AppButton>
        </div>
      </div>
    </div>
  );
}

export default CustomizePrimaryCard;
