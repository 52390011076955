import {AppBody} from "../html/html";

function AppNumberInput({
  symbol,
  onBlur,
  name,
  value,
  onChange,
  defaultValue,
  placeholder,
  className,
}) {
  return (
    <div className="relative">
      <input
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`app-input pe-10 ${className || ''}`}
        type="number"
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onWheel={(e) => e.target.blur()}
      />
      <AppBody type="span" pClass="Caption1Regular" className="absolute end-3 top-[50%] -translate-y-[50%] text-gray-6">
        {symbol}
      </AppBody>
    </div>
  );
}

export default AppNumberInput;
