import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BackBtn from '../../../../components/BackBtn';
import CardDetailsTable from '../../../../components/SharedComponents/CardDetailsTable';
import { inPages, transactionTabs } from '../../../../helpers/constants';
import {
  hideAllPopups,
  setActiveTabAction,
} from '../../../../redux/CardDetailsReducer/CardDetailsActions';

function UsersAllTransactions({ handleBackToUsersList }) {
  const { selectedSingleUserCardDetails } = useSelector((state) => state.users);
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setActiveTabAction(transactionTabs.EXPENSES));
      dispatch(hideAllPopups());
    };
  }, []);

  return (
    <div className="pe-6">
      <div className="mb-14 hidden pt-7 lg:block">
        <BackBtn className="mb-4" label={t('Back')} onClick={handleBackToUsersList} />
      </div>
      <CardDetailsTable
        inPage={inPages.allTransactions}
        cardDetails={selectedSingleUserCardDetails}
      />
    </div>
  );
}

export default UsersAllTransactions;
