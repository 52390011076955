import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as Check } from '../../../assets/svg/no_circle_check.svg';
import { ReactComponent as Spinner } from '../../../assets/svg/spinner.svg';
import { ReactComponent as Tag } from '../../../assets/svg/tag.svg';
import TableDropDown from '../../../components/TableDropDown';
import { apiDeleteRequest, apiGetRequest, apiPostRequest } from '../../../helpers/APIHelper';
import { setAction } from '../../../helpers/ActionsHelper';
import useNotifications from '../../../helpers/useNotifications';
import { PATCH_TRANSACTION } from '../../../redux/TransactionsReducer';
import {AppBody} from "../../../components/html/html";

// eslint-disable-next-line func-names
const DropdownButton = function (props) {
  const { t, i18n } = useTranslation(['transactions', 'common']);
  const { tag, mappedTags, transactionID, setCollapsed, setEditTags } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const [, addNotification] = useNotifications();
  const onClick = () => {
    setIsLoading(true);
    const handler = mappedTags && mappedTags.id === tag.id ? apiDeleteRequest : apiPostRequest;
    handler(
      'transactions/tags',
      {
        transaction_id: transactionID,
        tag_id: tag.id,
      },
      cookies.token,
    )
      .then((response) => {
        setIsLoading(false);
        // has parent transaction
        if (response.parent_transaction_id) {
          apiGetRequest(`transactions/${response.parent_transaction_id}`, cookies.token).then(
            (response1) => {
              dispatch(setAction(PATCH_TRANSACTION, response1));
            },
          );
        } else {
          dispatch(setAction(PATCH_TRANSACTION, response));
        }
        setCollapsed(true);
      })
      .catch((err) => {
        setIsLoading(false);
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        if (typeof setEditTags === 'function') {
          setEditTags(true);
        }
      });
  };

  return (
    <button
      onClick={onClick}
      style={{ color: tag.color, direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
      className="grid w-full px-1 py-0.5 hover:bg-gray-2 hover:bg-opacity-25 hover:bg-blend-multiply"
      type="button"
      key={tag.id}
    >
      <div className="justify-baseline flex flex-grow flex-row self-stretch">
        <Tag style={{ fill: tag.color, minWidth: '14px', minHeight: '14px' }} />
        <AppBody type="span" pClass="Body2Regular" className="ltr:pl-2  ltr:text-left rtl:pr-2 rtl:text-right">
          {i18n.language === 'ar' ? tag.name_ar : tag.name}
        </AppBody>

        <div className="flex flex-grow flex-row justify-end self-stretch">
          {isLoading && <Spinner className="h-5 w-5 animate-spin fill-lightGray" />}
          {!isLoading && mappedTags && mappedTags.id === tag.id && (
            <Check className="fill-lightGray" />
          )}
        </div>
      </div>
    </button>
  );
};
function DropdownHeader({ selectedTags, noTagsEdit, setCollapsed, collapsed, details }) {
  const { t, i18n } = useTranslation(['transactions']);
  return (
    <div className="flex flex-row items-baseline justify-between self-stretch">
      <div className="items-top flex flex-row">
        <Tag
          style={{
            fill: selectedTags ? selectedTags.color : '',
            minWidth: '14.951px',
            minHeight: '14.951px',
          }}
          className={selectedTags ? '' : 'fill-none stroke-gray'}
        />{' '}
        <button
          style={{ color: selectedTags ? selectedTags.color : '' }}
          type="button"
          className="text-sm font-normal text-gray-6 hover:text-black ltr:pl-1 ltr:text-left rtl:pr-1 rtl:text-right"
          disabled={noTagsEdit}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {selectedTags && details === true && `${selectedTags.name}  ${selectedTags.name_ar}`}
          {selectedTags &&
            details !== true &&
            (i18n.language === 'ar' ? selectedTags.name_ar : selectedTags.name)}
          {!selectedTags && t('select_tag')}
        </button>
      </div>
    </div>
  );
}

// eslint-disable-next-line func-names
const TagsDropdown = function ({
  tags,
  selectedTags,
  transactionID,
  noTagsEdit,
  className,
  details,
  setEditTags,
}) {
  const [collapsed, setCollapsed] = useState(true);
  const wrapperRef = useRef(null);

  return (
    <div
      ref={wrapperRef}
      className={`relative flex flex-grow flex-col self-stretch ${className || ''}`}
    >
      <TableDropDown
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        wrapperRef={wrapperRef}
        header={
          <DropdownHeader
            details={details}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            selectedTags={selectedTags}
            noTagsEdit={noTagsEdit}
          />
        }
        innerWrapperClassName="max-h-52 overflow-y-auto z-40 w-full bg-white shadow-md p-2"
      >
        {tags?.length &&
          tags.map((tag) => (
            <DropdownButton
              setCollapsed={setCollapsed}
              key={tag.id}
              tag={tag}
              mappedTags={selectedTags}
              transactionID={transactionID}
              setEditTags={setEditTags}
            />
          ))}
      </TableDropDown>
    </div>
  );
};

export default TagsDropdown;
