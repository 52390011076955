import { useDispatch } from 'react-redux';
import AppCloseButton from '../../../components/AppCloseButton';
import { hideModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import CompanyEditAddress from './CompanyEditAddress';

function CompanyEditAddressPopup() {
  const dispatch = useDispatch();

  return (
    <div className="max-h-[90vh] min-h-[50vh] w-[90%] overflow-y-auto rounded-lg border bg-gray-1 p-5 shadow lg:w-[60%] xl:w-[40%]">
      <div className="mb-2 flex justify-end">
        <AppCloseButton
          onClick={() => {
            dispatch(hideModalAction());
          }}
        />
      </div>

      <CompanyEditAddress />
    </div>
  );
}

export default CompanyEditAddressPopup;
