import { objToQueryStr } from 'javascript-functions';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../../AppRouter/history';
import AppAlert from '../../../../../components/AppAlert';
import AppSpinner from '../../../../../components/AppSpinner';
import BackBtn from '../../../../../components/BackBtn';
import TranslatedData from '../../../../../components/TranslatedData';
import { AppBody, AppButton } from '../../../../../components/html/html';
import { toIntAndDecimalNumber } from '../../../../../helpers/Utils';
import { http } from '../../../../../http';
import { showToasterAction } from '../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  getCardProgramDetailsBalanceAction,
  setIsFinishAction,
} from '../../../../../redux/WalletReducer/WalletActions';

function TransferDetailsContent({ handleBackStep, handleCancel }) {
  const dispatch = useDispatch();
  const { loaders } = useSelector((state) => state);
  const { actions } = useSelector((state) => state.wallet);
  const { t } = useTranslation('walletV2');
  const [estimatedAmount, setEstimatedAmount] = useState('');

  const renderEditBtn = useCallback(({ stepIndex }) => {
    return (
      <AppButton
        size="md"
        button="link"
        className="text-primary"
        onClick={() => {
          handleBackStep(stepIndex);
        }}
      >
        {t('Edit', { ns: 'commonV2' })}
      </AppButton>
    );
  }, []);

  const renderSectionTitle = useCallback((title) => {
    return <AppBody pClass="Body1Bold">{title}</AppBody>;
  }, []);

  const renderSingleTransferContent = useCallback(({ title, value }) => {
    return (
      <div>
        <AppBody pClass="Body2Regular" className="text-gray-6">
          {title}
        </AppBody>
        <AppBody pClass="Body1Regular" className="break-words">
          {value}
        </AppBody>
      </div>
    );
  }, []);

  const calculateToAmount = useCallback(() => {
    http
      .get('cardmanager/funds/rate', {
        loader: 'calculateToAmount',
        params: {
          fundable_id: actions.transferToData.id,
          from_fundable_id: actions.transferFromData.id,
          amount: actions.amount,
        },
      })
      .then((res) => {
        setEstimatedAmount(res.data?.destAmount || '');
      })
      .catch(() => {
        setEstimatedAmount('');
      });
  }, [actions]);

  const handleSubmitTransfer = useCallback(() => {
    const formBody = new FormData();
    formBody.append('type', 'TRANSFER');
    formBody.append('amount', actions.amount);
    formBody.append('currency', actions.transferFromData.currency);
    formBody.append('estimated_amount', estimatedAmount);

    // from
    formBody.append('from_fundable_id', actions.transferFromData.id);
    // to
    formBody.append('fundable_id', actions.transferToData.id);

    http
      .post('cardmanager/funds', formBody, { loader: 'handleSubmitTransfer' })
      .then((res) => {
        dispatch(setIsFinishAction(true));
        history.replace({
          search: objToQueryStr({
            requestNumber: res.data.id,
            message: 'You have Successfully request transferring amount between your card program',
            transferAnimation: true,
            selectedCardProgramId: actions.transferFromData.id,
          }),
        });
      })
      .catch((err) => {
        dispatch(showToasterAction({ type: 'error', message: err.data.error }));
      });
  }, [actions, estimatedAmount]);

  useEffect(() => {
    calculateToAmount();

    dispatch(
      getCardProgramDetailsBalanceAction({
        cardProgramId: actions.transferToData.id,
        fromActions: true,
        getToBalance: true,
      }),
    );
  }, []);

  return (
    <div>
      <BackBtn
        className="mb-4"
        onClick={() => {
          handleBackStep(1);
        }}
      />
      <AppBody pClass="Body1Bold" className="mt-8">
        {t('Transfer Details')}
      </AppBody>

      <div className="mb-10 mt-8 grid gap-4 md:px-16">
        {/* Transfer Details */}
        <div>
          <div className="mb-4 flex items-center justify-between border-b pb-2">
            <AppBody pClass="Body1Bold">{t('Transfer Debited  From')}</AppBody>
            {renderEditBtn({ stepIndex: 0 })}
          </div>

          <div className="grid grid-cols-2 gap-10 font-light lg:gap-4">
            {renderSingleTransferContent({
              title: t('Card Program'),
              value: (
                <>
                  <AppBody pClass="Body1Regular">
                    {actions.transferFromData.currency} • {actions.transferFromData.scheme} -{' '}
                    <TranslatedData
                      en={actions.transferFromData.issuer_country.name}
                      ar={actions.transferFromData.issuer_country.name_ar}
                    />
                  </AppBody>
                  <AppBody pClass="Body1Regular" className="flex items-center gap-1">
                    <span className="flex gap-3 rtl:justify-end" style={{ direction: 'ltr' }}>
                      <span>
                        {
                          toIntAndDecimalNumber(actions.transferFromData?.balance?.amount || 0)
                            .fullNumber
                        }
                      </span>
                      <span>{actions.transferFromData?.currency}</span>
                    </span>
                  </AppBody>
                </>
              ),
            })}

            <div className="grid gap-4">
              {renderSingleTransferContent({
                title: t('Amount', { ns: 'commonV2' }),
                value: actions.amount,
              })}

              {renderSingleTransferContent({
                title: t('Currency', { ns: 'commonV2' }),
                value: actions.transferFromData.currency,
              })}
            </div>
          </div>
        </div>

        {/* Withdraw Transferred To */}
        <div>
          <div className="mb-4 flex items-center justify-between border-b pb-2">
            {renderSectionTitle(t('Transfer Credited In'))}
            {renderEditBtn({ stepIndex: 1 })}
          </div>
          <div className="grid grid-cols-2 gap-10 font-light lg:gap-4">
            {renderSingleTransferContent({
              title: t('Card Program'),
              value: (
                <>
                  <AppBody pClass="Body1Regular">
                    {actions.transferToData.currency} • {actions.transferToData.scheme} -{' '}
                    <TranslatedData
                      en={actions.transferToData.issuer_country.name}
                      ar={actions.transferToData.issuer_country.name_ar}
                    />
                  </AppBody>
                  <AppBody pClass="Body1Regular" className="flex items-center gap-1">
                    {!loaders.getCardProgramDetailsBalanceAction && (
                      <span className="flex gap-3 rtl:justify-end" style={{ direction: 'ltr' }}>
                        <span>
                          {
                            toIntAndDecimalNumber(actions.transferToData?.balance?.amount || 0)
                              .fullNumber
                          }
                        </span>
                        <span>{actions.transferToData?.currency}</span>
                      </span>
                    )}

                    {loaders.getCardProgramDetailsBalanceAction && (
                      <span className="flex items-center gap-2">
                        <span>
                          <AppSpinner size={24} />
                        </span>
                        <span>{t('Loading Balance')}</span>
                      </span>
                    )}
                  </AppBody>
                </>
              ),
            })}

            <div className="grid gap-4">
              {renderSingleTransferContent({
                title: t('Amount', { ns: 'commonV2' }),
                value: (
                  <>
                    {!loaders.calculateToAmount && <span>{estimatedAmount || 'NV'}</span>}
                    {loaders.calculateToAmount && (
                      <div className="w-12 py-2">
                        <AppSpinner size={20} />
                      </div>
                    )}
                  </>
                ),
              })}

              {renderSingleTransferContent({
                title: t('Currency', { ns: 'commonV2' }),
                value: actions.transferToData.currency,
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-10 lg:w-2/3">
        <AppAlert
          type="info"
          text={
            <AppBody pClass="Body2Medium">
              {t(
                'The actual amount depends on the currency exchange at the time you make the transfer.',
              )}
            </AppBody>
          }
        />
      </div>

      <div className="flex justify-end gap-8">
        <AppButton button="link" onClick={handleCancel} size="md">
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          size="md"
          className="min-w-[150px]"
          onClick={handleSubmitTransfer}
          button="black"
          isLoading={loaders.handleSubmitTransfer}
        >
          {t('Submit', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default TransferDetailsContent;
