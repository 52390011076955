import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ActionFail from '../../../../../../components/SharedComponents/ActionFail';
import ActionSuccess from '../../../../../../components/SharedComponents/ActionSuccess';
import DeclineDetails from '../../../../../../components/SharedComponents/DeclineDetails';
import ReceiptBalanceAndStatus from '../../../../../../components/SharedComponents/ReceiptBalanceAndStatus';
import ReceiptMerchantData from '../../../../../../components/SharedComponents/ReceiptMerchantData';
import RequestFrom from '../../../../../../components/SharedComponents/RequestFrom';
import { inPages } from '../../../../../../helpers/constants';
import { getTagsAction } from '../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function WalletDeclineTabPopupContent() {
  const {
    showWalletRequestFormPopup,
    singleWalletRequestDetails,
    singleWalletTransactionDetails,
    walletTags,
    selectedWalletSubActiveTab,
    walletRequestActionAnimation,
  } = useSelector((state) => state.wallet);

  const showActionSuccess = useMemo(() => {
    return (
      walletRequestActionAnimation.show &&
      walletRequestActionAnimation.isSuccess &&
      !showWalletRequestFormPopup
    );
  }, [walletRequestActionAnimation, showWalletRequestFormPopup]);

  const showActionFail = useMemo(() => {
    return (
      walletRequestActionAnimation.show &&
      !walletRequestActionAnimation.isSuccess &&
      !showWalletRequestFormPopup
    );
  }, [walletRequestActionAnimation, showWalletRequestFormPopup]);

  return (
    <>
      {!showWalletRequestFormPopup && !walletRequestActionAnimation.show && (
        <>
          <div>
            <ReceiptBalanceAndStatus transactionDetails={singleWalletTransactionDetails} />
          </div>
          <div>
            <ReceiptMerchantData
              activeTab={selectedWalletSubActiveTab}
              transactionDetails={singleWalletTransactionDetails}
              tags={walletTags}
              getTagsAction={getTagsAction}
              inPage={inPages.wallet}
            />
          </div>
          <div>
            <DeclineDetails transactionDetails={singleWalletTransactionDetails} />
          </div>
        </>
      )}

      {showWalletRequestFormPopup && (
        <div>
          <RequestFrom
            requestDetails={singleWalletRequestDetails}
            transactionDetails={singleWalletTransactionDetails}
            activeTab={selectedWalletSubActiveTab}
            fromWalletTransactions
          />
        </div>
      )}

      {showActionSuccess && <ActionSuccess requestAction={walletRequestActionAnimation} />}

      {showActionFail && <ActionFail requestAction={walletRequestActionAnimation} />}
    </>
  );
}

export default WalletDeclineTabPopupContent;
