import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import ActionButton from '../../components/Button/ActionButton';
import {TextInput} from '../../components/FormInputs/Inputs';
import {HeadlineCenter} from '../../components/Titles';
import {apiPostFormDataRequest} from '../../helpers/APIHelper';
import {PasswordValidationPattern} from '../../helpers/RegexPatterns';
import useNotifications from '../../helpers/useNotifications';
import Head from '../Head';
import {AppBody} from "../../components/html/html";

function ResetPassword() {
  const { t, i18n } = useTranslation(['reset', 'common']);
  const params = useParams();
  const navigate = useNavigate();
  const [, addNotification] = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
  } = useForm({ mode: 'onBlur' });

  const submitForm = (data) => {
    setIsSubmitting(true);
    const form = new FormData();
    form.append('token', params.token);
    form.append('password', data.password);
    form.append('password_confirmation', data.password_confirm);

    apiPostFormDataRequest('email/reset', form)
      .then(() => {
        addNotification(t('reset_success'));
        navigate('/auth/login');
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  return (
    <div className="flex h-full items-center justify-center">
      <div className="m-auto flex w-full flex-col lg:w-6/12">
        <Head title="Reset Password" />
        <div className="flex flex-col text-center">
          <HeadlineCenter>{t('reset_title')}</HeadlineCenter>
          <AppBody type="span" pClass="Body2Regular" className="pt-2 text-gray-6">{t('subtitle')}</AppBody>
        </div>
        <form
          className="flex flex-grow flex-col items-center justify-start pt-10"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="flex w-full max-w-sm pb-5">
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_password"
              className="flex w-full flex-col"
            >
              {t('inputs.password')}
              <TextInput
                stars="true"
                error={errors.password}
                id="user_password"
                {...register('password', {
                  required: t('validation.required.password'),
                  pattern: {
                    value: PasswordValidationPattern,
                    message: t('validation.patterns.password'),
                  },
                  minLength: {
                    value: 8,
                    message: t('validation.min.password'),
                  },
                })}
              />
            </AppBody>
          </div>
          <div className="flex w-full max-w-sm pb-5">
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_password_confirm"
              className="flex w-full flex-col"
            >
              {t('inputs.password_confirm')}
              <TextInput
                stars="true"
                error={errors.password_confirm}
                id="user_password_confirm"
                {...register('password_confirm', {
                  required: t('validation.required.password_confirm'),
                  validate: {
                    passwordEqual: (value) =>
                      value === getValues().password || t('validation.password_not_matching'),
                  },
                })}
              />
            </AppBody>
          </div>

          <AppBody type="div" pClass="Caption1Regular" className="self-stretch pb-10 text-gray-6 ltr:text-left rtl:text-right">
            {t('inputs.password_hint')}
          </AppBody>

          <div className="mt-10 flex w-full flex-row items-baseline justify-end pt-2">
            <ActionButton
              className="flex flex-row"
              text={t('cancel', { ns: 'common' })}
              onClick={() => {
                navigate('/auth/login');
              }}
            />
            <ActionButton
              className="flex flex-row"
              isLoading={isSubmitting}
              primary
              text={t('submit', { ns: 'common' })}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
