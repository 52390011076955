import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppIcon } from '../../../../components/html/html';
import { hideModalAction } from '../../../../redux/AppModalReducer/AppModalActions';
import { hideUsersListSearchPopupAction } from '../../../../redux/UsersReducer/UsersActions';

function UsersListSearchPopup({ onSearch }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { showUsersListSearchPopup } = useSelector((state) => state.users);
  const { t } = useTranslation('commonV2');
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');

  const inputHasValue = useMemo(() => {
    return !!inputValue.length;
  }, [inputValue]);

  const handleSearch = useCallback(() => {
    onSearch(inputValue);
  }, [inputValue]);

  const handleClearSearch = useCallback(() => {
    if (isMobile) {
      dispatch(hideModalAction());
    }
    dispatch(hideUsersListSearchPopupAction());
  }, [isMobile]);

  useEffect(() => {
    if (!showUsersListSearchPopup) {
      setInputValue('');
    }
  }, [showUsersListSearchPopup]);

  return (
    showUsersListSearchPopup && (
      <div className="relative end-4 z-30 h-12 w-80 lg:absolute lg:end-12 lg:top-8">
        <input
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          placeholder={`${t('Search')}...`}
          type="text"
          className="h-full w-full rounded-2xl  border-0 bg-white pe-11 ps-11 shadow ring-0 placeholder:text-gray-6 focus:shadow-md focus:ring-0"
        />

        <button
          type="button"
          className="absolute start-3 top-[14px] flex items-center justify-center"
          onClick={handleClearSearch}
        >
          <AppIcon
            iClass="XXLargeFont"
            className="fa-duotone fa-circle-xmark"
            style={{ '--fa-primary-color': '#3842ff', '--fa-secondary-color': '#F2F5FC' }}
          />
        </button>
        {inputHasValue && (
          <button
            onClick={handleSearch}
            type="button"
            className="absolute end-4 top-[15px] text-primary"
          >
            <AppIcon
              iClass="Default"
              className="fa-regular fa-arrow-turn-down-left rtl:rotate-180 rtl:scale-x-[1] rtl:scale-y-[-1]"
            />
          </button>
        )}
      </div>
    )
  );
}

export default UsersListSearchPopup;
