import AccountWidget from './AccountWidget';
import FundsWidget from './FundsWidget';
import TransactionsWidget from './TransactionsWidget';

function DashboardSidebar({ daysFilter }) {
  return (
    <div className="col-span-3 flex flex-col justify-start sm:px-5 lg:col-span-1 lg:px-5 lg:px-5 xl:px-10 2xl:px-10">
      <AccountWidget daysFilter={daysFilter} />
      <FundsWidget />
      <TransactionsWidget className="flex lg:hidden" />
    </div>
  );
}

export default DashboardSidebar;
