import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppSelect from '../FormInputs/AppSelect';

function WalletTransactionsTypeFilters({ onChange, transactionTypeOptions, value }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { t } = useTranslation('walletV2');

  return (
    <div className="flex w-36 items-center gap-2 py-2 lg:w-full">
      <p className="hidden text-xs xs:text-sm lg:block">{t('Select Type: ')}</p>

      <AppSelect
        styleType={isMobile ? 'custom' : 'normal'}
        className="w-40 rounded-2xl border border-gray-3 text-sm xs:text-base lg:border-0 lg:text-xs"
        options={transactionTypeOptions}
        value={value}
        onChange={(option) => {
          onChange(option);
        }}
      />
    </div>
  );
}

export default WalletTransactionsTypeFilters;
