import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import ActionButton from '../../../../components/Button/ActionButton';
import LinkButton from '../../../../components/Button/LinkButton';
import {SelectInputCustom} from '../../../../components/FormInputs/Inputs';
import DropzoneSingleFileForm from '../../../../components/Upload/DropzoneSingleFileForm';
import {apiGetRequest, apiPostFormDataRequest} from '../../../../helpers/APIHelper';
import {setAction} from '../../../../helpers/ActionsHelper';
import useNotifications from '../../../../helpers/useNotifications';
import {PATCH_TRANSACTION} from '../../../../redux/TransactionsReducer';
import TransactionAttachmentDownload from '../TransactionAttachmentDownload';
import DeleteAttachmentPopup from './DeleteAttachmentPopup';
import {AppBody} from "../../../../components/html/html";

function AttachmentEditForm(props) {
  const { setEditAttachment, attachment, transaction, setTransaction } = props;
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [cookies] = useCookies(['token']);
  const [, addNotification] = useNotifications();
  const [docTypes, setDocTypes] = useState([]);
  const [removeAttachment, setRemoveAttachment] = useState(false);
  const [replaceAttachment, setReplaceAttachment] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n } = useTranslation(['transactions', 'common']);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: 'onBlur' });

  const fetchDocTypes = () => {
    apiGetRequest('transactions/receipts-doc-types', cookies.token).then((response) => {
      setDocTypes(
        response.map(({ id: value, title: label, ...rest }) => ({
          value,
          label: t(label),
          ...rest,
        })),
      );
    });
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('doc_type_id', data.doc_type_id?.value ?? data.doc_type_id);
    formData.append('transaction_id', transaction.id);

    if (files) {
      files.forEach((file) => {
        formData.append('receipt', file);
      });
    }

    apiPostFormDataRequest(`transactions/receipts/${attachment.id}`, formData, cookies.token)
      .then((res) => {
        dispatch(setAction(PATCH_TRANSACTION, res));
        setTransaction(res);
        setEditAttachment(false);
        setReplaceAttachment(false);
        addNotification(t('attachment_edited'));
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    fetchDocTypes();
  }, [i18n.language]);

  return (
    <div className="flex flex-grow flex-col justify-start ltr:text-left rtl:text-right">
      {removeAttachment && (
        <DeleteAttachmentPopup
          attachmentId={attachment.id}
          setRemoveAttachment={setRemoveAttachment}
          setEditAttachment={setEditAttachment}
          transactionId={transaction.id}
          setTransaction={setTransaction}
        />
      )}
      <form
        className="relative flex flex-grow flex-col items-start justify-start self-stretch"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="z-10 flex w-full flex-row items-center justify-between lg:px-10">
          <LinkButton
            isDeleteRed
            className="rlt:font-bold px-2 text-base text-red-700 ltr:font-semibold"
            text=""
            onClick={() => {
              setRemoveAttachment(true);
              setReplaceAttachment(false);
            }}
          />
          <ActionButton
            isLoading={isSubmitting}
            type="submit"
            className="rlt:font-bold px-2 text-base text-primary ltr:font-semibold"
            text={t('save', { ns: 'common' })}
          />
        </div>
        <div className="-mt-5 flex w-full flex-col justify-start">
          {replaceAttachment && (
            <DropzoneSingleFileForm
              setFile={setFiles}
              file={files}
              fileError={fileError}
              setFileError={setFileError}
              fileHint={t('rejectReason', { ns: 'common' })}
              rejectReason={t('rejectReason_simple', { ns: 'common' })}
              uploadInput={t('documents_or_photos', { ns: 'common' })}
              acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
            />
          )}

          {!replaceAttachment && (
            <div className="relative mb-2 mt-5 flex items-center">
              <TransactionAttachmentDownload
                attachmentId={attachment.id}
                attachmentPath={attachment.path}
                transaction={transaction}
              />
              <div className="no_print absolute bottom-0 mb-0 w-full rounded-b-lg bg-gray-2 bg-opacity-80 text-center">
                <button
                  type="button"
                  className="align-baseline text-sm font-semibold ltr:text-left rtl:text-right"
                  onClick={() => {
                    setReplaceAttachment(true);
                  }}
                >
                  {t('replace', { ns: 'common' })}
                </button>
              </div>
            </div>
          )}
        </div>

        <AppBody pClass="Body2bold" type="label"
          htmlFor="doc_type_id"
          className="flex w-full flex-col"
        >
          {docTypes.length > 0 && (
            <SelectInputCustom
              onChange={(e) => {
                setValue('doc_type_id', e);
              }}
              defaultValue={
                docTypes.filter((doc) => doc.label === t(attachment.doc_type))[0] || null
              }
              error={errors.doc_type_id}
              name="doc_type_id"
              id="doc_type_id"
              control={control}
              rules={{
                required: t('doc_type_required'),
              }}
              options={docTypes}
            />
          )}
          {docTypes.length === 0 && (
            <span className="pt-5 font-normal">{t('loading', { ns: 'common' })}</span>
          )}
        </AppBody>
      </form>
    </div>
  );
}

export default AttachmentEditForm;
