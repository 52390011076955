import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppHeader } from '../../../../../../../components/html/html';
import { devLog } from '../../../../../../../helpers/Utils';
import { cardStatusKeys, settingsPopupContent } from '../../../../../../../helpers/constants';
import { setCurrentSettingsPopupContent } from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function ChangeStatus() {
  const { t } = useTranslation('cardsV2');
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);

  const dispatch = useDispatch();

  const selectedCardStatus = useMemo(() => {
    return settingsPopupCardDetails?.status?.key?.toUpperCase();
  }, [settingsPopupCardDetails]);

  devLog(`Selected Card Status is: [${selectedCardStatus}]`);

  const statuses = useMemo(() => {
    const activateContentName =
      settingsPopupCardDetails.is_physical &&
      (selectedCardStatus === cardStatusKeys.INACTIVE ||
        selectedCardStatus === cardStatusKeys.PENDING)
        ? settingsPopupContent.verifyLastFour
        : settingsPopupContent.activateCard;

    return [
      {
        label: t('Activate'),
        contentName: activateContentName,
        show:
          selectedCardStatus === cardStatusKeys.INACTIVE ||
          selectedCardStatus === cardStatusKeys.SUSPENDED,
      },
      {
        label: t('Suspend'),
        contentName: settingsPopupContent.suspendCard,
        show: selectedCardStatus === cardStatusKeys.ACTIVE,
      },
      {
        label: t('Terminate'),
        contentName: settingsPopupContent.terminateCard,
        show:
          selectedCardStatus === cardStatusKeys.ACTIVE ||
          selectedCardStatus === cardStatusKeys.SUSPENDED,
        className: 'text-red-500',
      },
      // {
      //   label: 'Reissue',
      // },
    ];
  }, []);

  return (
    <>
      <AppHeader h="h5" className="mb-3">
        {t('Change Status')}
      </AppHeader>
      <div className="overflow-hidden rounded-lg border border-gray-3">
        {statuses.map((ele) => {
          return (
            ele.show && (
              <button
                key={ele.label}
                type="button"
                className="flex w-full justify-between border-b p-4 text-start text-xs font-normal last:border-0 hover:bg-stone-50"
                onClick={() => {
                  dispatch(setCurrentSettingsPopupContent(ele.contentName));
                }}
              >
                <span className={ele.className || ''}>{ele.label}</span>
              </button>
            )
          );
        })}
      </div>
    </>
  );
}

export default ChangeStatus;
