function AppTextArea({ name, value, onBlur, onChange, maxLength }) {
  return (
    <textarea
      className="app-input"
      rows="3"
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={(e) => {
        if (maxLength) {
          const { key } = e;
          const currentLength = value.length;
          if (currentLength >= maxLength && key !== 'Backspace') {
            e.preventDefault();
          }
        }
      }}
      onPaste={(e) => {
        if (maxLength) {
          const pastedText = (e.clipboardData || window.clipboardData).getData('text');
          const totalLength = value.length + pastedText.length;

          if (totalLength > maxLength) {
            e.preventDefault();
            const remainingChars = maxLength - value.length;
            const truncatedText = pastedText.substring(0, remainingChars);
            document.execCommand('insertText', false, truncatedText);
          }
        }
      }}
    />
  );
}

export default AppTextArea;
