import { dummyArr } from 'javascript-functions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import AppNoData from '../../../../../components/AppNoData';
import BackBtn from '../../../../../components/BackBtn';
import { AppBody, AppButton, AppHeader } from '../../../../../components/html/html';
import {
  addTransferFromDataAction,
  getWhitlistedIBANIDAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import SingleBankCard from '../SingleBankCard';
import DoubleNotes from './DoubleNotes';

function SelectWhitelistedContent({ onNext, handleBackStep, handleCancel }) {
  const { loaders } = useSelector((state) => state);
  const { actions } = useSelector((state) => state.wallet);
  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWhitlistedIBANIDAction());
  }, []);

  return (
    <div>
      <div>
        <BackBtn
          className="mb-4"
          onClick={() => {
            handleBackStep(0);
          }}
        />
        <AppHeader h="h4" className="mb-2">
          {t('Select Your Whitelisted IBAN')}
        </AppHeader>
        <AppBody pClass="Body1Bold" className="mb-2">
          {t('From', { ns: 'commonV2' })}
        </AppBody>
        <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
          {t(
            'Please Note you need to do the actual transfer from your bank account website or app',
          )}
        </AppBody>

        <div className="mb-10 grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
          {!loaders.getWhitlistedIBANIDAction &&
            actions?.whitelistedIBANS?.data?.map((ele) => {
              return (
                <SingleBankCard
                  key={ele.id}
                  onClick={() => {
                    dispatch(addTransferFromDataAction(ele));
                  }}
                  item={ele}
                  isSelected={actions.transferFromData?.id === ele?.id}
                />
              );
            })}

          {loaders.getWhitlistedIBANIDAction &&
            dummyArr(8).map((_, i) => {
              return (
                <div key={JSON.stringify(i)}>
                  <Skeleton height={130} />
                </div>
              );
            })}
        </div>

        {!loaders.getWhitlistedIBANIDAction && !actions?.whitelistedIBANS?.data?.length && (
          <AppNoData />
        )}
      </div>

      <div className="mb-16 mt-8 lg:w-2/3">
        <DoubleNotes />
      </div>

      <div className="flex justify-end gap-8">
        <AppButton button="link" onClick={handleCancel} size="md">
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          size="md"
          className="min-w-[150px]"
          disabled={!actions.transferFromData}
          onClick={() => {
            onNext({ stepIndex: 1 });
          }}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default SelectWhitelistedContent;
