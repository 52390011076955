import { AppIcon } from '../../../../../components/html/html';

function ReceiptIcon({ item }) {
  if (item?.transaction_type === 'Reversal') return null;

  if (item.has_receipt) {
    return (
      <span>
        <AppIcon iClass="XLargeFont" className="fa-kit fa-done font-normal" />
      </span>
    );
  }
  return (
    <span>
      <AppIcon iClass="XLargeFont" className="fa-kit fa-up font-normal text-danger" />
    </span>
  );
}

export default ReceiptIcon;
