import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Attachment } from '../../../../assets/svg/attachment.svg';
import { ReactComponent as NoAttachment } from '../../../../assets/svg/no_attachment.svg';
import { ReactComponent as SplitIcon } from '../../../../assets/svg/split.svg';
import { DataTableItem } from '../../../../components/DataTable/DataTable';
import { Details, Info, Input } from '../../../../components/Titles';
import { AppBody } from '../../../../components/html/html';
import { formatDate } from '../../../../helpers/Utils';
import usePermissions from '../../../../hooks/usePermissions';
import TagsDropdown from '../TagsDropdown';
import { TransactionDetails } from '../TransactionDetails';

export function TransactionsSplitTableItem({
  transaction,
  className,
  tags,
  onClick,
  selected,
  last,
  isSplit,
}) {
  const userCan = usePermissions();
  const { t, i18n } = useTranslation(['transactions', 'common']);
  return (
    <DataTableItem className="px-4 pt-2" onClick={onClick} selected={selected} last={last}>
      <button
        type="button"
        tabIndex={transaction.id}
        className={`${className || ''} flex grid w-full grid-cols-7 gap-0 py-5 ${
          last ? '' : 'border-b border-gray-3'
        }`}
        onClick={onClick}
        onKeyDown={onClick}
      >
        <div className="col-span-5 flex flex-grow flex-col items-start ltr:pl-5 rtl:pr-5">
          <div className="items-top flex flex-row justify-between self-stretch">
            {isSplit && <Info>{transaction.merchant_name}</Info>}
            {!isSplit && (
              <Info>
                {`${
                  i18n.language === 'ar' && transaction.user_id
                    ? transaction.user_id.first_name_ar
                    : ''
                } ${
                  i18n.language === 'ar' && transaction.user_id
                    ? transaction.user_id.last_name_ar
                    : ''
                }`}
                {`${
                  i18n.language !== 'ar' && transaction.user_id
                    ? transaction.user_id.first_name
                    : ''
                } ${
                  i18n.language !== 'ar' && transaction.user_id ? transaction.user_id.last_name : ''
                }`}
              </Info>
            )}
          </div>
          <AppBody
            pClass="Caption1Regular"
            type="div"
            className="flex flex-grow flex-row justify-between self-stretch text-gray-6 ltr:text-left rtl:text-right"
          >
            <div>
              {userCan('transaction_list_view') && transaction.department_id && (
                <Details className="block py-1">
                  {i18n.language === 'ar'
                    ? transaction.department_id.name_ar
                    : transaction.department_id.name}
                </Details>
              )}
              {userCan('transaction_list_view') && !transaction.department_id && (
                <Details className="block py-1">---</Details>
              )}
              {!userCan('transaction_list_view') && (
                <Details className="block py-1">{transaction.card_last4}</Details>
              )}
              <span className="block py-1">
                {formatDate(transaction.transaction_timestamp, 'HH:mm | DD MMM YYYY')}
              </span>
              <span className="py-1">{transaction.status}</span>
              {Array.isArray(transaction.splits) && transaction.splits.length > 0 && (
                <AppBody pClass="Caption1Bold" type="span" className="block py-1">
                  <SplitIcon className="inline-block ltr:pr-1 rtl:pl-1" /> {t('split')}
                </AppBody>
              )}
              {Array.isArray(transaction.splits) && transaction.splits.length === 0 && (
                <TagsDropdown
                  className="pt-2"
                  noTagsEdit
                  transactionID={transaction.id}
                  tags={tags}
                  selectedTags={transaction.tags}
                />
              )}
            </div>
          </AppBody>
        </div>
        <div className="col-span-2 flex h-full flex-col items-center justify-center px-2">
          {transaction.has_receipt ? <Attachment /> : <NoAttachment />}
          {transaction.transaction_amount >= 0 && (
            <Input className="mt-2 tabular-nums" style={{ direction: 'ltr' }}>{`${parseFloat(
              transaction.transaction_amount,
            ).toLocaleString()} ${transaction.transaction_currency}`}</Input>
          )}
          {transaction.transaction_amount < 0 && (
            <Input className="mt-2 tabular-nums" style={{ direction: 'ltr' }}>{`(${parseFloat(
              transaction.transaction_amount * -1,
            ).toLocaleString()}) ${transaction.transaction_currency}`}</Input>
          )}
        </div>
      </button>
    </DataTableItem>
  );
}

export function TransactionSplitPreview({ transactionId }) {
  if (transactionId === null) {
    return null;
  }

  return (
    <div className="col-span-1 hidden lg:block" style={{ direction: 'ltr' }}>
      <TransactionDetails transactionId={transactionId} isSplit />
    </div>
  );
}
