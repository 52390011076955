import { useTranslation } from 'react-i18next';
import { AppBody } from '../../html/html';

function UploadPlaceholder({ acceptedFileTypes, readableFileSize, placeHolder, showAccepted }) {
  const { t } = useTranslation('commonV2');

  return (
    <div className="absolute bottom-2 flex flex-col items-center gap-1">
      {placeHolder && placeHolder}

      {!placeHolder && (
        <div>
          <AppBody pClass="Body2Bold" className="mb-1 px-3 text-center">
            <span className="me-1">{t('Drag your', { ns: 'commonV2' })}</span>
            <span className="me-1 text-primary">
              {t('documents ,or photos', { ns: 'commonV2' })}
            </span>
            <span>{t('here to start uploading.', { ns: 'commonV2' })}</span>
          </AppBody>
          <AppBody pClass="Caption1Regular" className="mb-2 text-center text-gray-6">
            {t('Or Browse Files', { ns: 'commonV2' })}
          </AppBody>
        </div>
      )}

      {showAccepted && (
        <span className="w-2/3 text-center text-[7px] text-gray-6">
          {t('File must be')} {`${acceptedFileTypes} ${readableFileSize}`}
        </span>
      )}
    </div>
  );
}

export default UploadPlaceholder;
