import { getURLParams } from 'javascript-functions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLink } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import AppImageComponent from '../../../../components/AppImageComponent';
import NqoodCardImg from '../../../../components/NqoodCardImg';
import { AppButton, AppHeader } from '../../../../components/html/html';
import ShippingAddress from './ShippingAddress';

function RequestPlasticCardSuccess() {
  const { t } = useTranslation('cardsV2');
  const { createCardUserData, userPrimaryCard } = useSelector((state) => state.createNewCard);

  const isCreationForOtherUser = useMemo(() => {
    return Boolean(+getURLParams('createCardUserId'));
  }, [getURLParams('createCardUserId')]);

  return (
    <div className="mx-auto mt-[12vh] w-full max-w-[410px] px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <AppHeader h="h3" className="mb-6">
        {t('Card Successfully Requested')}
      </AppHeader>

      <div className="-mb-16" style={{ direction: 'ltr' }}>
        <div className="relative max-w-[350px] rounded-xl shadow-md">
          <AppImageComponent
            skeletonWidth={256}
            skeletonHeight={151}
            src={userPrimaryCard?.design?.attachment}
            alt="Virtual Card"
          />
          <div className="absolute -right-10 top-6">
            <AiOutlineLink size={28} />
          </div>
        </div>

        <div className="relative mb-4 max-w-[350px] -translate-y-20 translate-x-10 rounded-xl shadow-md">
          <NqoodCardImg
            skeletonWidth={256}
            skeletonHeight={151}
            alt="Plastic Card"
            type="plastic"
          />
        </div>
      </div>

      <ShippingAddress />

      <div className="mt-4 pb-5">
        <AppButton
          onClick={() => {
            if (isCreationForOtherUser) {
              history.push(`/dashboard/users/${createCardUserData.id}`);
            } else {
              history.push('/dashboard/my_cards');
            }
          }}
          isLoading={false}
          button="black"
          className="w-full"
          size="md"
        >
          {!isCreationForOtherUser && t('Go to Your Card')}
          {isCreationForOtherUser && t('Go to User Card')}
        </AppButton>
      </div>
    </div>
  );
}

export default RequestPlasticCardSuccess;
