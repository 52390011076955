import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AttachmentPreviewPath from '../../../components/AttachmentPreviewPath';
import LinkButton from '../../../components/Button/LinkButton';
import { LargerTitle2 } from '../../../components/Titles';
import { AppBody } from '../../../components/html/html';
import { apiPatchRequest } from '../../../helpers/APIHelper';
import { setAction } from '../../../helpers/ActionsHelper';
import { formatDate } from '../../../helpers/Utils';
import usePermissions from '../../../hooks/usePermissions';
import { UPDATE_FUND_ITEM } from '../../../redux/FundsReducer';
import { ApproveStatus } from './Common';
import FundApproveDecline from './FundApproveDecline';
import FundAttachment from './FundAttachment';
import { isApprovalStatus } from './Helper';

const mapStateToProps = (state) => ({
  funds: state.funds.funds,
});

export const FundItemHeader = function (props) {
  const { selectedItem, noBorder } = props;
  const { t, i18n } = useTranslation(['funds']);

  return (
    <div
      className={`grid w-full grid-cols-3  bg-gray-1 p-5 ltr:text-left rtl:text-right ${
        !noBorder ? 'border-b pb-3' : ''
      } border-gray-4`}
    >
      <div className="col-span-3 flex flex-col pb-2 lg:col-span-1">
        <span className="text-gray-light">{t('name')}</span>
        <AppBody type="span" pClass="Body1Bold">
          {selectedItem.requested_by &&
          i18n.language === 'ar' &&
          selectedItem.requested_by.first_name_ar
            ? selectedItem.requested_by.first_name_ar
            : selectedItem.requested_by.first_name ?? ''}{' '}
          {selectedItem.requested_by &&
          i18n.language === 'ar' &&
          selectedItem.requested_by.last_name_ar
            ? selectedItem.requested_by.last_name_ar
            : selectedItem.requested_by.last_name ?? ''}
        </AppBody>
      </div>
      <div className="col-span-3 flex flex-col pb-2 lg:col-span-1">
        <span className="text-gray-light">{t('date')}</span>
        <AppBody type="span" pClass="Body1Bold">
          {formatDate(selectedItem.created_at, 'DD / MMM / YYYY')}
        </AppBody>
      </div>
      <div className="col-span-3 flex flex-col pb-2 lg:col-span-1">
        <span className="text-gray-light">{t('time')}</span>
        <AppBody type="span" pClass="Body1Bold">
          {formatDate(selectedItem.created_at, 'hh:mm:ss a')}
        </AppBody>
      </div>
    </div>
  );
};
function FundPreview(props) {
  const { user } = useSelector((state) => state.auth);

  const { selectedItem, approveAction, rejectAction, mobileView } = props;
  const { t, i18n } = useTranslation(['funds', 'common']);
  const dispatch = useDispatch();
  const [filteredFund, setFilteredFund] = useState(selectedItem);
  const userCan = usePermissions();
  const [cookies] = useCookies(['token']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setFilteredFund(selectedItem);
  }, [selectedItem]);

  return (
    <div
      key={selectedItem}
      className={`${
        mobileView ? '' : 'hidden lg:block'
      } col-span-1 flex flex-col justify-start rounded-2xl ltr:text-left rtl:text-right`}
    >
      {!filteredFund && (
        <span
          className="text-gray-light flex items-center justify-center"
          style={{ height: '100%' }}
        >
          {t('no_request_selected')}
        </span>
      )}
      {filteredFund && (
        <>
          <div className="flex flex-row flex-wrap justify-between self-stretch">
            <div className="self-start ltr:text-left rtl:text-right">
              <LargerTitle2>
                {parseFloat(filteredFund.amount).toFixed(2).toLocaleString()}{' '}
                {filteredFund.currency}
              </LargerTitle2>
            </div>
            {(userCan('update_account_funds') ||
              (userCan('update_own_funds') && user.id === filteredFund.requested_by?.id)) &&
              !isApprovalStatus(filteredFund.status_id) &&
              filteredFund?.card_id?.status?.key === 'ACTIVE' && (
                <button
                  type="button"
                  className="text-md mb-5 flex flex-row items-baseline justify-end align-baseline font-medium text-primary ltr:text-left rtl:text-right"
                  onClick={() => {
                    navigate(`/dashboard/funds/edit/${filteredFund.id}`);
                  }}
                >
                  {t('edit', { ns: 'common' })}
                </button>
              )}
          </div>
          <FundItemHeader selectedItem={filteredFund} noBorder />

          <div className="mt-2 grid w-full grid-cols-2 bg-gray-1 p-5 ltr:text-left rtl:text-right">
            <div className="col-span-2 flex flex-col py-2 lg:col-span-1 lg:pt-20">
              <div className="pb-2">
                <span className="text-gray-light block">{t('card')}</span>
                <AppBody type="span" pClass="Body1Bold" className="block">
                  {filteredFund.card_id &&
                    filteredFund.card_id.cardproduct &&
                    filteredFund.card_id.cardproduct.scheme}{' '}
                  **
                  {filteredFund.card_id && filteredFund.card_id.last_four}
                </AppBody>
              </div>
              {filteredFund.department_id && filteredFund.department_id !== 0 && (
                <div className="pb-2">
                  <span className="text-gray-light block">{t('department')}</span>
                  <AppBody type="span" pClass="Body1Bold" className="block">
                    {i18n.language === 'ar'
                      ? filteredFund.department_id.name_ar
                      : filteredFund.department_id.name}
                  </AppBody>
                </div>
              )}
              {(!filteredFund.department_id || filteredFund.department_id === 0) && (
                <div className="pb-2">
                  <span className="text-gray-light block">{t('department')}</span>
                  <AppBody type="span" pClass="Body1Bold" className="block">
                    ---
                  </AppBody>
                </div>
              )}
              <div className="pb-2">
                <span className="text-gray-light block">{t('reason')}</span>
                <AppBody type="span" pClass="Body1Bold" className="block">
                  {filteredFund.reason}
                </AppBody>
              </div>
            </div>

            <div className="col-span-2 flex flex-col lg:col-span-1 ">
              {filteredFund.attachments.length === 0 && (
                <div className="">
                  <img
                    alt="img"
                    src={
                      i18n.language === 'ar'
                        ? '/images/no-file-uploaded-ar.svg'
                        : '/images/no-file-uploaded-en.svg'
                    }
                    className="fundDefaultImage h-auto w-full"
                  />
                </div>
              )}

              {filteredFund.attachments.length > 0 && (
                <div className="mb-5 mt-5 block h-auto w-full">
                  {filteredFund.attachments.map((attachment) => {
                    if (attachment.type && attachment.type.includes('image/')) {
                      return (
                        <AttachmentPreviewPath
                          divPreview
                          attachmentId={attachment.id}
                          attachmentPath={attachment.path}
                          attachmentType={attachment.type}
                        />
                      );
                    }
                    return (
                      <FundAttachment
                        attachmentId={attachment.id}
                        attachmentPath={attachment.path}
                        fund={filteredFund}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col justify-between pb-2 pt-5 lg:flex-row">
            {filteredFund.status && parseInt(filteredFund.status.id, 10) === 2 && (
              <AppBody
                type="div"
                pClass="Body1Bold"
                className="mt-2  flex w-full flex-col justify-between py-1 lg:flex-row"
              >
                <ApproveStatus text={filteredFund.status.name} />
                <div
                  className={`rounded-xl ${
                    filteredFund.ref_id && filteredFund.ref_id.status.key === 'SUCCESS'
                      ? 'bg-green-50 text-green-600'
                      : 'bg-yellow-100 text-yellow-500'
                  } flex-col px-4 py-2 lg:py-1`}
                >
                  {filteredFund.ref_id && filteredFund.ref_id.status.key === 'SUCCESS' && (
                    <div className="text-center font-normal text-green-600">
                      {t('approve_status')}{' '}
                      <AppBody
                        type="span"
                        pClass="Body1Bold"
                        className="capitalize ltr:pl-2 rtl:pr-2"
                      >
                        {filteredFund.ref_id.status.name}
                      </AppBody>
                    </div>
                  )}
                  {filteredFund.ref_id && filteredFund.ref_id.status.key === 'FAILURE' && (
                    <div className="text-center font-normal text-yellow-500">
                      {t('approve_status')}{' '}
                      <AppBody
                        type="span"
                        pClass="Body1Bold"
                        className="capitalize ltr:pl-2 rtl:pr-2"
                      >
                        {filteredFund.ref_id.status.name}
                      </AppBody>
                      <LinkButton
                        isLoading={isSubmitting}
                        className="mx-2 mt-1 rounded-full border border-yellow-400 bg-transparent px-5 py-1.5 font-semibold capitalize text-yellow-500"
                        onClick={() => {
                          setIsSubmitting(true);
                          apiPatchRequest(`funds/${filteredFund.id}/retry`, {}, cookies.token)
                            .then((result) => {
                              dispatch(setAction(UPDATE_FUND_ITEM, result));
                              setFilteredFund(result);
                            })
                            .finally(() => {
                              setIsSubmitting(false);
                            });
                        }}
                        text={t('reload')}
                      />
                    </div>
                  )}
                  {filteredFund.ref_id &&
                    filteredFund.ref_id.status.key !== 'SUCCESS' &&
                    filteredFund.ref_id.status.key !== 'FAILURE' && (
                      <div className="text-center">
                        {t('approve_status')}{' '}
                        <span className="text-gray-light capitalize ltr:pl-2 rtl:pr-2">
                          {filteredFund.ref_id.status.name}
                        </span>
                      </div>
                    )}
                </div>
              </AppBody>
            )}
            <FundApproveDecline
              filteredFund={filteredFund}
              approveAction={approveAction}
              rejectAction={rejectAction}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps)(FundPreview);
