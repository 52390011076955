import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {apiGetRequest} from '../../helpers/APIHelper';
import {setAction} from '../../helpers/ActionsHelper';
import {UPDATE_WIDGET_TRANSACTIONS} from '../../redux/TransactionsReducer';
import {TransactionsDataTable} from './Transactions';
import {AppHeader} from "../../components/html/html";

const TransactionsWidget = function ({ userID, cardID, className }) {
  const { t, i18n } = useTranslation(['common']);
  const [cookies] = useCookies(['token']);
  const [tags, setTags] = useState([]);
  const { widgetTransactions } = useSelector((state) => state.transactions);
  const [isLoading, setIsLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    apiGetRequest('tags', cookies.token).then((data) => {
      setTags(data);
    });
  }, [i18n.language]);

  const fetchTransactions = () => {
    setIsLoading(true);
    apiGetRequest(
      'transactions',
      cookies.token,
      {
        days: 30,
        paginate: 4,
        page: 1,
        ...(userID && { 'card_holders[0]': userID }),
        ...(cardID && { card_id: cardID }),
        'transaction_type[]': '4',
        created_at: 'desc',
      },
      ['tags', 'receipts', 'category', 'splits.tags', 'splits.receipts', 'splits.category'],
      {},
      true,
    )
      .then(([response, paginator]) => {
        setErrored(false);
        dispatch(setAction(UPDATE_WIDGET_TRANSACTIONS, response));
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setAction(UPDATE_WIDGET_TRANSACTIONS, []));
    fetchTransactions();
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [i18n.language]);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div className={`min-w-full flex-col self-start ${className}`}>
      <AppHeader h="h6" className="my-5 self-start">
        {t('latest_purchases')}
      </AppHeader>
      <TransactionsDataTable
        setFilter={false}
        isSplit={!!isMobile}
        retryCallback={fetchTransactions}
        errored={errored}
        pagination={false}
        transactions={widgetTransactions}
        tags={tags}
        updating={isLoading}
        isWidget
        hideNameColumn={!!userID}
        noTagsEdit={false}
      />
    </div>
  );
};

export default TransactionsWidget;
