import { useTranslation } from 'react-i18next';
import { toIntAndDecimalNumber } from '../../helpers/Utils';
import { AppBody } from '../html/html';
import TranslatedData from '../TranslatedData';
import CardProgramSchemeLogo from './CardProgramSchemeLogo';
import ReceiptCardDescription from './ReceiptCardDescription';

function ReceiptCardDetails({ transactionDetails, inPage }) {
  const { t } = useTranslation('cardsV2');

  return (
    <div className="rounded-lg border border-gray-3 p-3">
      <div className="mb-5 flex items-start justify-between">
        <div className="flex flex-col gap-4">
          <AppBody pClass="Body2Bold" type="span">
            {`${transactionDetails.user_id.first_name} ${transactionDetails.user_id.last_name}`}
          </AppBody>
          <AppBody pClass="Body2Bold" type="span">
            <TranslatedData
              en={transactionDetails.department_id.name}
              ar={transactionDetails.department_id.name_ar}
            />
          </AppBody>
        </div>
        <div className="flex items-center gap-1">
          <span>
            <CardProgramSchemeLogo cardProgramScheme={transactionDetails.network} />
          </span>
          <span>{transactionDetails.card_last4}</span>
        </div>
      </div>

      <div className="mb-4">
        <AppBody pClass="Body2Bold" className="mb-4 flex justify-between text-gray-6">
          <span>{t('Amount in card currency')}</span>
          <span style={{ direction: 'ltr' }}>
            {toIntAndDecimalNumber(transactionDetails.billing_amount).fullNumber}{' '}
            {transactionDetails.billing_currency}
          </span>
        </AppBody>
        <AppBody pClass="Body2Bold" className="flex justify-between text-gray-6">
          <span>{t('FX Fees')}</span>
          <span>{transactionDetails.fx_fee}</span>
        </AppBody>
      </div>

      <div className="mb-5">
        <AppBody pClass="Body2Light" className="mb-4 text-gray-6">
          # {transactionDetails.id}
        </AppBody>
        <AppBody pClass="Body2Light" className="gap-1 break-words text-gray-6">
          <span className="ltr:mr-3 rtl:ml-3">
            <TranslatedData
              en={transactionDetails.transaction_type || '-'}
              ar={transactionDetails.transaction_type_ar}
            />
          </span>
          <span className="ltr:mr-3 rtl:ml-3">
            <TranslatedData
              en={transactionDetails.status_description}
              ar={transactionDetails.status_description_ar}
            />
          </span>
          <span>{transactionDetails.transaction_reference}</span>
        </AppBody>
      </div>
      <div>
        <ReceiptCardDescription transactionDetails={transactionDetails} inPage={inPage} />
      </div>
    </div>
  );
}

export default ReceiptCardDetails;
