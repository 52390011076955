/* eslint-disable no-use-before-define */
import { getURLParams } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../../AppRouter/history';
import AppStepper from '../../../../../components/AppStepper';
import {
  clearWalletActionsAction,
  setActiveWalletStepIndexAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import ActionFinishPage from '../ActionFinishPage';
import SelectCardProgramContent from './SelectCardProgramContent';
import TransferDetailsContent from './TransferDetailsContent';
import TransferDetermineAmountContent from './TransferDetermineAmountContent';

function TransferPage() {
  const { t } = useTranslation('walletV2');
  const { actions } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const [completedSteps, setCompletedSteps] = useState({});

  const handleBackStep = useCallback((stepIndex) => {
    dispatch(setActiveWalletStepIndexAction(stepIndex));
  }, []);

  const onNext = useCallback(({ stepIndex }) => {
    setCompletedSteps((prev) => {
      return {
        ...prev,
        [stepIndex]: true,
      };
    });
    dispatch(setActiveWalletStepIndexAction(stepIndex + 1));
  }, []);

  const handleCancel = useCallback(() => {
    history.push(
      `/dashboard/wallet?selectedCardProgramId=${getURLParams('selectedCardProgramId')}`,
    );
  }, []);

  const transferPageSteps = useMemo(() => {
    return [
      {
        label: t('Determine Your Amount'),
        value: 'Determine Your Amount',
        content: <TransferDetermineAmountContent handleCancel={handleCancel} onNext={onNext} />,
      },
      {
        label: t('Select The Card Program'),
        value: 'Select The Card Program',
        content: (
          <SelectCardProgramContent
            handleBackStep={handleBackStep}
            handleCancel={handleCancel}
            onNext={onNext}
          />
        ),
      },
      {
        label: t('Transfer Details'),
        value: 'Transfer Details ',
        content: (
          <TransferDetailsContent
            handleBackStep={handleBackStep}
            handleCancel={handleCancel}
            onNext={onNext}
          />
        ),
      },
    ];
  }, [t]);

  useEffect(() => {
    dispatch(setActiveWalletStepIndexAction(0));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearWalletActionsAction());
    };
  }, []);

  return (
    <div className="h-full pb-4 lg:pt-8">
      {!actions.isFinish && (
        <AppStepper
          sideBarHeader={t('3 Steps to Transfer Money')}
          activeStepIndex={actions.activeWalletStepIndex}
          steps={transferPageSteps}
          completedSteps={completedSteps}
        />
      )}
      {actions.isFinish && <ActionFinishPage />}
    </div>
  );
}

export default TransferPage;
