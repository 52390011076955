import React from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { ReactComponent as AttachmentIcon } from '../assets/svg/pdf.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function AttachmentPreviewPath({
  attachmentId,
  attachmentPath,
  attachmentType,
  divPreview,
  uploadPreview,
}) {
  return (
    <div className="flex flex-grow flex-col justify-start ltr:text-left rtl:text-right">
      <div className="flex h-auto max-w-full flex-row">
        <div key={attachmentId} className="h-auto max-w-full">
          <a href={attachmentPath} target="_blank" rel="noreferrer" className="h-auto max-w-full">
            {attachmentType && attachmentType.includes('image/') && divPreview && (
              <div
                style={{
                  display: 'table-cell',
                  backgroundImage: `url(${attachmentPath})`,
                  width: '284px',
                  height: '284px',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
                className="max-h-fit max-w-full rounded-lg"
              />
            )}
            {attachmentType && attachmentType.includes('image/') && !divPreview && (
              <img src={attachmentPath} className="h-auto max-h-fit max-w-full rounded-lg" />
            )}
            {attachmentType && attachmentType.includes('application/') && uploadPreview && (
              <div>
                <AttachmentIcon />
              </div>
            )}
            {attachmentType && attachmentType.includes('application/') && !uploadPreview && (
              <div>
                <Document
                  onLoadError={(error) =>
                    console.log(`Error while loading document! ${error.message}`)
                  }
                  file={{ url: attachmentPath }}
                  className="h-auto max-w-full rounded-lg"
                >
                  <Page pageNumber={1} height={300} />
                </Document>
              </div>
            )}
          </a>
        </div>
      </div>
    </div>
  );
}

export default AttachmentPreviewPath;
