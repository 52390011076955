import { formatDate } from '../../helpers/Utils';

function LoadMerchantCountryCity({ transactionDetails }) {
  return (
    <div className="mb-6 flex flex-col gap-1">
      <div className="flex gap-1 text-sm text-gray-6">
        <span>{transactionDetails.merchant_country}</span>
        <span>{transactionDetails.merchant_city}</span>
      </div>
      <span className="text-sm text-gray-6">
        {formatDate(transactionDetails.transaction_timestamp, 'HH:mm')}
        &nbsp;&nbsp;&nbsp;
        {formatDate(transactionDetails.transaction_timestamp, 'DD MMMM YYYY')}
      </span>
    </div>
  );
}

export default LoadMerchantCountryCity;
