import { useTranslation } from 'react-i18next';

function ReplaceReceiptBtn() {
  const { t } = useTranslation('commonV2');

  return (
    <div className="absolute bottom-0 z-20 flex h-[20px] w-full items-center justify-center bg-stone-200 text-[8px]">
      {t('Replace Receipt')}
    </div>
  );
}

export default ReplaceReceiptBtn;
