import { getURLParams } from 'javascript-functions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import fundRequestFinishAnimation from '../../../../assets/gifs/fundRequestFinishAnimation.gif';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { setActiveProfileTabIndexAction } from '../../../../redux/ProfileReducer/ProfileActions';

function FundMethodFinish() {
  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();
  return (
    <div className="flex h-full items-center justify-center overflow-y-auto rounded-lg border border-gray-3 shadow">
      <div className="w-[90%] md:w-[70%] lg:w-[50%]">
        <img src={fundRequestFinishAnimation} alt="finish" className="mx-auto w-[75%]" />

        <AppHeader h="h5" className="mb-3">
          {t('The request to approve a new bank account for transfer has been sent successfully')}
        </AppHeader>

        <AppBody pClass="Body1Regular" className="mb-3">
          <span>{t('Request N')}</span>
          <span>:</span>
          <span className="ms-2">{getURLParams('requestNumber')}</span>
        </AppBody>

        <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
          <span className="block">
            {t(
              'The process of approving the submitted funding method (the way you will transfer funds from your company bank account), which includes verifying and whitelisting the IBAN (the account number used for international transactions), takes 5 to 10 working days. You will be contacted by our team to confirm the details.',
            )}
          </span>
          <span className="block">
            {t('It’s not possible to transfer from the sending account before it is approved.')}
          </span>
        </AppBody>

        <div className="mx-auto text-center md:w-2/3">
          <AppButton
            className="mb-6 w-full"
            size="lg"
            onClick={() => {
              history.push(`/dashboard/profile/company`);
              setTimeout(() => {
                dispatch(setActiveProfileTabIndexAction(1));
              }, 0);
            }}
            button="black"
          >
            {t('Back to Funding Methods')}
          </AppButton>
        </div>
      </div>
    </div>
  );
}

export default FundMethodFinish;
