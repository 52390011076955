import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ApprovedRequestAr from '../../../../../../assets/lotties/Request_Approved_Ar.json';
import ApprovedRequestEn from '../../../../../../assets/lotties/Request_Approved_En.json';
import RejectedRequestAr from '../../../../../../assets/lotties/Request_Rejected_Ar.json';
import RejectedRequestEn from '../../../../../../assets/lotties/Request_Rejected_En.json';
import AppLottie from '../../../../../../components/AppLottie';
import { AppHeader } from '../../../../../../components/html/html';

function ApprovedRejectedRequestAnimation() {
  const { lang } = useSelector((state) => state.appConfig);
  const { approvedRejectedRequestAnimation } = useSelector((state) => state.wallet);
  const { t } = useTranslation('walletV2');
  return (
    <>
      {lang === 'ar' && (
        <AppLottie
          animationData={
            approvedRejectedRequestAnimation.isApproved ? ApprovedRequestAr : RejectedRequestAr
          }
        />
      )}

      {lang !== 'ar' && (
        <AppLottie
          animationData={
            approvedRejectedRequestAnimation.isApproved ? ApprovedRequestEn : RejectedRequestEn
          }
        />
      )}

      <AppHeader h="h5" className="text-center">
        {approvedRejectedRequestAnimation.isApproved
          ? t('The Request Approved Successfully')
          : t('The Request Rejected Successfully')}
      </AppHeader>
    </>
  );
}

export default ApprovedRejectedRequestAnimation;
