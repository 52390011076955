import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBody, AppButton } from '../html/html';

function OTPResendTimer({ duration, onResend, onFinish, isResend = true }) {
  const { t } = useTranslation('commonV2');
  const [timer, setTimer] = useState(duration * 1000);

  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = ((ms % 60000) / 1000).toFixed(0);
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleResend = () => {
    // Trigger resend action
    if (timer === 0) {
      setTimer(duration * 1000);
      onResend();
    }
  };

  useEffect(() => {
    if (!isResend && timer === 0) {
      onFinish();
    }
  }, [isResend, timer]);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1000);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  return (
    <>
      {(!!timer || !isResend) && (
        <AppBody pClass="Body2Medium" className="inline text-gray-6">
          <span className="font-bold text-primary">{formatTime(timer)}</span>
        </AppBody>
      )}

      {!timer && isResend && (
        <AppButton className="text-sm font-bold text-primary" button="link" onClick={handleResend}>
          {t('Resend')}
        </AppButton>
      )}
    </>
  );
}

export default OTPResendTimer;
