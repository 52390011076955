import { useDispatch, useSelector } from 'react-redux';
import useIsLoggedIn from '../../helpers/useIsLoggedIn';
import { changeLang } from '../../redux/AppConfigReducer/AppConfigActions';

function ChangeLangButton() {
  const { appConfig } = useSelector((state) => state);
  const loggedIn = useIsLoggedIn();
  const dispatch = useDispatch();

  return (
    !loggedIn && (
      <button
        className="text-2xl font-bold"
        onClick={() => {
          dispatch(changeLang());
        }}
        type="button"
      >
        {appConfig.lang === 'ar' ? 'English' : 'العربية'}
      </button>
    )
  );
}

export default ChangeLangButton;
