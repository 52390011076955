import { useSelector } from 'react-redux';
import LoadMerchantCountryCity from '../../../../../../components/SharedComponents/LoadMerchantCountryCity';
import LoadUnloadDetails from '../../../../../../components/SharedComponents/LoadUnloadDetails';
import ReceiptBalanceAndStatus from '../../../../../../components/SharedComponents/ReceiptBalanceAndStatus';
import { inPages } from '../../../../../../helpers/constants';

function WalletLoadTabPopupContent() {
  const { singleWalletTransactionDetails, singleSelectedCardProgramDetails } = useSelector(
    (state) => state.wallet,
  );

  return (
    <>
      <div>
        <ReceiptBalanceAndStatus transactionDetails={singleWalletTransactionDetails} />
      </div>
      <div>
        <LoadMerchantCountryCity transactionDetails={singleWalletTransactionDetails} />
      </div>
      <div>
        <LoadUnloadDetails
          transactionDetails={singleWalletTransactionDetails}
          singleSelectedCardProgramDetails={singleSelectedCardProgramDetails}
          inPage={inPages.wallet}
        />
      </div>
    </>
  );
}

export default WalletLoadTabPopupContent;
