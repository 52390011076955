export const UPDATE_WIDGET_TRANSACTIONS = 'UPDATE_WIDGET_TRANSACTIONS';
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export const PATCH_TRANSACTION = 'PATCH_TRANSACTION';
export const UPDATE_TAGS = 'UPDATE_TAGS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SET_TRANSACTION_FILTER = 'SET_TRANSACTION_FILTER';
export const RESET_TRANSACTION_FILTER = 'RESET_TRANSACTION_FILTER';
export const UPDATE_SPLIT_FILTERS = 'UPDATE_SPLIT_FILTERS';
export const SET_TRANSACTION_SPLIT_FILTER = 'SET_TRANSACTION_SPLIT_FILTER';
export const RESET_TRANSACTION_SPLIT_FILTER = 'RESET_TRANSACTION_SPLIT_FILTER';
export const SET_TRANSACTION_DAYS_FILTER = 'SET_TRANSACTION_DAYS_FILTER';
export const SET_TRANSACTION_FROM_FILTER = 'SET_TRANSACTION_FROM_FILTER';
export const SET_TRANSACTION_TO_FILTER = 'SET_TRANSACTION_TO_FILTER';
export const SET_TRANSACTION_DATE_TYPE_FILTER = 'SET_TRANSACTION_DATE_TYPE_FILTER';
export const SET_TRANSACTION_DAYS_SPLIT_FILTER = 'SET_TRANSACTION_DAYS_SPLIT_FILTER';
export const SET_TRANSACTION_FROM_SPLIT_FILTER = 'SET_TRANSACTION_FROM_SPLIT_FILTER';
export const SET_TRANSACTION_TO_SPLIT_FILTER = 'SET_TRANSACTION_TO_SPLIT_FILTER';
export const SET_TRANSACTION_DATE_TYPE_SPLIT_FILTER = 'SET_TRANSACTION_DATE_TYPE_SPLIT_FILTER';
export const RESET_TRANSACTION_DATE_FILTER = 'RESET_TRANSACTION_DATE_FILTER';
export const RESET_TRANSACTION_DATE_FILTER_SPLIT = 'RESET_TRANSACTION_DATE_FILTER_SPLIT';

const initialState = {
  transactions: [],
  tags: [],
  widgetTransactions: [],
  filters: [],
  filtersSplit: [],
  filterDays: 30,
  filterFrom: null,
  filterTo: null,
  filterDateType: null,
  filterDaysSplit: 30,
  filterFromSplit: null,
  filterToSplit: null,
  filterDateTypeSplit: null,
};

const TransactionsReducer = (state = initialState, action) => {
  const draft = { ...state };
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };
    case PATCH_TRANSACTION: {
      draft.transactions = draft.transactions.map((transaction) => {
        if (transaction.id === action.payload.id) {
          return {
            ...transaction,
            ...action.payload,
          };
        }
        return transaction;
      });
      draft.widgetTransactions = draft.widgetTransactions.map((transaction) => {
        if (transaction.id === action.payload.id) {
          return {
            ...transaction,
            ...action.payload,
          };
        }
        return transaction;
      });
      return draft;
    }
    case UPDATE_TAGS:
      draft.tags = action.payload;
      return draft;
    case UPDATE_FILTERS:
      draft.filters = action.payload;
      return draft;
    case RESET_TRANSACTION_FILTER:
      draft.filters = [];
      return draft;
    case SET_TRANSACTION_DAYS_FILTER:
      draft.filterDays = action.payload;
      return draft;
    case SET_TRANSACTION_FROM_FILTER:
      draft.filterFrom = action.payload;
      return draft;
    case SET_TRANSACTION_TO_FILTER:
      draft.filterTo = action.payload;
      return draft;
    case SET_TRANSACTION_DATE_TYPE_FILTER:
      draft.filterDateType = action.payload;
      return draft;
    case SET_TRANSACTION_DAYS_SPLIT_FILTER:
      draft.filterDaysSplit = action.payload;
      return draft;
    case SET_TRANSACTION_FROM_SPLIT_FILTER:
      draft.filterFromSplit = action.payload;
      return draft;
    case SET_TRANSACTION_TO_SPLIT_FILTER:
      draft.filterToSplit = action.payload;
      return draft;
    case SET_TRANSACTION_DATE_TYPE_SPLIT_FILTER:
      draft.filterDateTypeSplit = action.payload;
      return draft;
    case RESET_TRANSACTION_DATE_FILTER:
      draft.filterDateType = null;
      draft.filterDays = 30;
      draft.filterFrom = null;
      draft.filterToSplit = null;
      return draft;
    case RESET_TRANSACTION_DATE_FILTER_SPLIT:
      draft.filterDateTypeSplit = null;
      draft.filterDaysSplit = 30;
      draft.filterFromSplit = null;
      draft.filterToSplit = null;
      return draft;
    case SET_TRANSACTION_FILTER: {
      const { type, object } = action.payload;
      switch (type) {
        case 'add':
          draft.filters.push(object);
          break;
        case 'delete':
          if (object.type === 'compound') {
            Object.keys(object.options.subfilters).forEach((i) => {
              object.options.subfilters[i].value = null;
            });
          }
          draft.filters = draft.filters.filter((obj) => obj.key !== object.key);
          break;
        case 'update': {
          const { existing, updated } = object;
          const indexToUpdate = draft.filters.findIndex((item) => item.key === existing.key);
          draft.filters[indexToUpdate] = updated;
          break;
        }
        default:
          return draft;
      }
      return draft;
    }
    case UPDATE_SPLIT_FILTERS:
      draft.filtersSplit = action.payload;
      return draft;
    case RESET_TRANSACTION_SPLIT_FILTER:
      draft.filtersSplit = [];
      return draft;
    case SET_TRANSACTION_SPLIT_FILTER: {
      const { type, object } = action.payload;
      switch (type) {
        case 'add':
          draft.filtersSplit.push(object);
          break;
        case 'delete':
          if (object.type === 'compound') {
            Object.keys(object.options.subfilters).forEach((i) => {
              object.options.subfilters[i].value = null;
            });
          }
          draft.filtersSplit = draft.filtersSplit.filter((obj) => obj.key !== object.key);
          break;
        case 'update': {
          const { existing, updated } = object;
          const indexToUpdate = draft.filtersSplit.findIndex((item) => item.key === existing.key);
          draft.filtersSplit[indexToUpdate] = updated;
          break;
        }
        default:
          return draft;
      }
      return draft;
    }
    case UPDATE_TRANSACTIONS:
      draft.transactions = action.payload;
      return draft;
    case UPDATE_WIDGET_TRANSACTIONS:
      draft.widgetTransactions = action.payload;
      return draft;
    default:
      return draft;
  }
};

export default TransactionsReducer;
