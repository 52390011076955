import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inPages, transactionTabs } from '../../../../../../helpers/constants';
import {
  clearSingleTransactionCardDetailsAction,
  getSingleTransactionCardDetailsAction,
} from '../../../../../../redux/CardDetailsReducer/CardDetailsActions';
import DeclineTabPopupContent from './DeclineTabPopupContent';
import ExpensesAndRefundPopupContent from './ExpensesAndRefundPopupContent';
import LoadTabPopupContent from './LoadTabPopupContent';

function TransactionsPopupContent({ cardDetails, inPage }) {
  const { singleTransactionDetails, selectedActiveTab, singleTransactionCardDetails } = useSelector(
    (state) => state.cardDetails,
  );
  const dispatch = useDispatch();

  const currentCardDetails = useMemo(() => {
    if (inPage === inPages.allTransactions) {
      const cardsObj = { virtualCard: null, physicalCard: null };

      if (singleTransactionCardDetails?.is_physical) {
        cardsObj.physicalCard = singleTransactionCardDetails;
      } else {
        cardsObj.virtualCard = singleTransactionCardDetails;
      }

      return cardsObj;
    }
    return cardDetails;
  }, [inPage, singleTransactionCardDetails, cardDetails]);

  useEffect(() => {
    if (inPage === inPages.allTransactions) {
      dispatch(getSingleTransactionCardDetailsAction(singleTransactionDetails.card_id));
    }
    return () => {
      dispatch(clearSingleTransactionCardDetailsAction(singleTransactionDetails.card_id));
    };
  }, [inPage, singleTransactionDetails]);

  return (
    <div className="grid gap-4">
      {/* Expenses and Refund Tabs Content */}
      {(selectedActiveTab === transactionTabs.EXPENSES ||
        selectedActiveTab === transactionTabs.REFUNDS) && <ExpensesAndRefundPopupContent />}

      {/* Decline Tab Content */}
      {selectedActiveTab === transactionTabs.DECLINES && (
        <DeclineTabPopupContent cardDetails={currentCardDetails} />
      )}

      {/* Load Tab Content */}
      {selectedActiveTab === transactionTabs.LOADS && (
        <LoadTabPopupContent cardDetails={currentCardDetails} />
      )}
    </div>
  );
}

export default TransactionsPopupContent;
