import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ActionButton from '../../../../components/Button/ActionButton';
import DropzoneSingleFileForm from '../../../../components/Upload/DropzoneSingleFileForm';
import { apiPostFormDataRequest } from '../../../../helpers/APIHelper';
import { setAction } from '../../../../helpers/ActionsHelper';
import useNotifications from '../../../../helpers/useNotifications';
import { PATCH_TRANSACTION } from '../../../../redux/TransactionsReducer';

function ReceiptEditForm(props) {
  const { transactionId, setEditReceipt, setTransaction } = props;
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [cookies] = useCookies(['token']);
  const [, addNotification] = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation(['transactions', 'common']);
  const dispatch = useDispatch();

  const onSubmit = () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('transaction_id', transactionId);
    formData.append('doc_type_id', '1');
    files.forEach((file) => {
      formData.append('receipts[]', file);
    });

    apiPostFormDataRequest('transactions/receipts', formData, cookies.token)
      .then((res) => {
        dispatch(setAction(PATCH_TRANSACTION, res));
        setTransaction(res);
        setEditReceipt(false);
        addNotification(t('attachment_added'));
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="flex flex-grow flex-col justify-start ltr:text-left rtl:text-right">
      <form className="relative flex flex-grow flex-col items-start justify-start self-stretch">
        <div className="flex w-full flex-col  justify-start">
          <DropzoneSingleFileForm
            setFile={setFiles}
            file={files}
            fileError={fileError}
            setFileError={setFileError}
            fileHint={t('rejectReason', { ns: 'common' })}
            rejectReason={t('rejectReason_simple', { ns: 'common' })}
            uploadInput={t('documents_or_photos', { ns: 'common' })}
            acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
          />
        </div>

        {files.length > 0 && (
          <div className="absolute -bottom-7 m-auto w-full text-center">
            <ActionButton
              className="inline-block w-auto bg-primary px-6 py-1 text-center text-white"
              isLoading={isSubmitting}
              text={t('upload_receipt')}
              onClick={() => {
                if (!files || (files && files.length === 0)) {
                  setFileError(t('receipt_required'));
                } else {
                  onSubmit();
                }
              }}
            />
          </div>
        )}
      </form>
    </div>
  );
}

export default ReceiptEditForm;
