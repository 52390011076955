import {useEffect, useState} from 'react';
import useNotifications from '../../helpers/useNotifications';
import {AppBody} from "../../components/html/html";

const SuccessNotification = function (props) {
  const { children } = props;
  const [fadeout, setFadeout] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setFadeout('animate-fadeOutRight');
    }, 3000);
  }, []);
  return (
    <AppBody type="div" pClass="Body1Bold"
      className={`animate-animated animate-fadeInDown animate-faster ${fadeout} mb-2 rounded-xl border border-green-500 bg-green-50 px-2 py-1 font-bold text-green-600`}
    >
      {children}
    </AppBody>
  );
};

const ErrorNotification = function (props) {
  const { children } = props;
  const [fadeout, setFadeout] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setFadeout('animate-fadeOutRight');
    }, 3000);
  }, []);
  return (
    <AppBody pClass="Body1Bold" type="div"
      className={`animate-animated animate-fadeInDown animate-faster ${fadeout} mb-2 rounded-xl border border-red-400 bg-red-50 px-2 py-1 font-bold text-red-700`}
    >
      {children}
    </AppBody>
  );
};

const NotificationsArea = function () {
  const [notifications, , readNotification] = useNotifications();
  return (
    <div
      className="fixed right-10 top-5 flex w-auto max-w-full flex-col lg:w-96"
      style={{ zIndex: '99' }}
    >
      {notifications.map((notification, index) => {
        if (notification.read) {
          return null;
        }
        setTimeout(() => {
          readNotification(index);
        }, 4000);
        if (notification.success === true) {
          // eslint-disable-next-line react/no-array-index-key
          return <SuccessNotification key={index + 1}>{notification.body}</SuccessNotification>;
        }
        // eslint-disable-next-line react/no-array-index-key
        return <ErrorNotification key={index + 1}>{notification.body}</ErrorNotification>;
      })}
    </div>
  );
};

export default NotificationsArea;
