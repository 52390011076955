import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {isFilePDF, toIntAndDecimalNumber} from '../../helpers/Utils';
import PreviewButton from '../FormInputs/AppUpload/PreviewButton';
import TranslatedData from '../TranslatedData';
import {AppBody, AppHeader, AppIcon} from '../html/html';

function SplitsList({ transactionDetails }) {
  const { t } = useTranslation('cardsV2');

  const splitCount = useMemo(() => {
    return transactionDetails?.splits.length || 0;
  }, [transactionDetails]);

  return (
    <div className="overflow-x-auto rounded-lg border border-gray-3 p-3 print:hidden">
      {transactionDetails?.splits.map((ele, i) => {
        const isLastEl = i === transactionDetails.splits.length - 1;
        return (
          <div key={ele.id} className={`grid gap-3 py-4 ${!isLastEl ? 'border-b' : ''}`}>
            <AppHeader h="h6" className="flex gap-1">
              <span>{t('Split')}</span>
              <span>{i + 1}</span>
              <span>{t('of')}</span>
              <span>{splitCount}</span>
            </AppHeader>

            <div className="flex justify-between gap-4">
              <div className="flex flex-col gap-2">
                <div>
                  <AppBody pClass="Body2Medium" className="text-gray-6">
                    {t('Department')}
                  </AppBody>
                  <span className="text-xs">
                    <TranslatedData ar={ele.department_id.name_ar} en={ele.department_id.name} />
                  </span>
                </div>

                <div>
                  <AppBody pClass="Body2Medium" className="mb-1 text-gray-6">
                    {t('Assign Tag')}
                  </AppBody>
                  <div className="flex gap-1 text-xs" style={{ color: ele.tags.color }}>
                    <span>
                      <AppIcon iClass="BaseFont" className="fa-regular fa-tag" />
                    </span>
                    <TranslatedData ar={ele.tags.name_ar} en={ele.tags.name} />
                  </div>
                </div>

                <div>
                  <AppBody pClass="Body2Medium" className="text-gray-6">
                    {t('Amount')}
                  </AppBody>
                  <div className="flex gap-1 text-sm rtl:justify-end" style={{ direction: 'ltr' }}>
                    <div style={{ direction: 'ltr' }}>
                      <span className="font-medium">
                        {toIntAndDecimalNumber(ele.transaction_amount).integerPart}
                      </span>
                      <span>{toIntAndDecimalNumber(ele.transaction_amount).decimalPart}</span>
                    </div>
                    <span className="font-medium">{ele.transaction_currency}</span>
                  </div>
                </div>

                {ele.reason && (
                  <div className="break-all rounded-md border border-gray-3 bg-stone-50 p-3">
                    <AppBody pClass="Body2Medium" className="flex gap-1 text-gray-6">
                      <span>{t('Split')}</span>
                      <span>{i + 1}</span>
                      <span>{t('Description')}</span>
                    </AppBody>
                    <p className="text-xs">{ele.reason}</p>
                  </div>
                )}
              </div>

              <div>
                <div className="no_print h-[120px] w-[120px] overflow-hidden rounded-lg border-2 border-gray-3">
                  <PreviewButton href={ele.receipts[0].path} isPDF={isFilePDF(ele.receipts[0])} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SplitsList;
