import { BarElement, CategoryScale, Chart as ChartJS, LinearScale } from 'chart.js';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { Bar } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import LoadingError from '../../components/LoadingError';
import { apiGetRequest } from '../../helpers/APIHelper';
import { setAction } from '../../helpers/ActionsHelper';
import { SET_TOP_MERCHANTS } from '../../redux/StatisticsReducer';
import { AppHeader } from '../../components/html/html';

ChartJS.register(CategoryScale, LinearScale, BarElement);
const TopMerch = function ({ daysFilter }) {
  const { t, i18n } = useTranslation(['dashboard']);

  const getGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(0, '#EA4989');
    gradient.addColorStop(0, '#743CCD');
    gradient.addColorStop(1, '#0000FF');
    return gradient;
  };

  const { merchants: topMerch } = useSelector((state) => state.statistics);
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(topMerch.length === 0);
  const [errored, setErrored] = useState(false);

  const loadMerchants = () => {
    setLoading(true);
    apiGetRequest('transactions/stats/top-merchants', cookies.token, {
      days: daysFilter,
      limit: 5,
    })
      .then((response) => {
        dispatch(setAction(SET_TOP_MERCHANTS, response));
        setLoading(false);
        setErrored(false);
      })
      .catch(() => {
        setErrored(topMerch.length === 0);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMerchants();
  }, [daysFilter, i18n.language]);

  return (
    <div className="col-span-2 flex flex-col justify-start px-5 lg:col-span-1">
      <AppHeader h="h6" className={`my-5 self-start capitalize ${!loading ? 'pb-0 lg:pb-10' : ''}`}>
        {t('merchant_stat')}
      </AppHeader>
      {loading && <Skeleton containerClassName="p-5" className="w-full" count={3} />}
      <LoadingError show={errored && !loading} retryCallback={loadMerchants} />
      {!loading && topMerch.length > 0 && (
        <Bar
          className="max-h-60 max-w-fit"
          options={{
            indexAxis: 'y',
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: true,
                displayColors: false,
                callbacks: {
                  label(context) {
                    let label = '';

                    if (parseFloat(context.formattedValue) < 0) {
                      label += `${context.label} :(${parseFloat(context.formattedValue) * -1})`;
                    } else {
                      label += `${context.label} :${context.formattedValue}`;
                    }
                    return label;
                  },
                  title(context) {
                    return '';
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
            },
          }}
          data={{
            labels: topMerch.map((merch) => merch.merchant_name),
            datasets: [
              {
                data: topMerch.map((merch) => {
                  if (merch.billing_amount < 0) {
                    return merch.billing_amount * -1;
                  }
                  return merch.billing_amount;
                }),
                borderRadius: 5,
                maxBarThickness: 14,
                borderSkipped: false,
                backgroundColor: (context) => {
                  const { chart } = context;
                  const { ctx, chartArea } = chart;

                  if (!chartArea) {
                    // This case happens on initial chart load
                    return;
                  }
                  // eslint-disable-next-line consistent-return
                  return getGradient(ctx, chartArea);
                },
              },
            ],
          }}
          type="bar"
        />
      )}
      {!loading && topMerch.length === 0 && (
        <Bar
          className="max-h-60 max-w-fit"
          options={{
            indexAxis: 'y',
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
            },
          }}
          data={{
            labels: ['-', '-', '-', '-', '-'],
            datasets: [
              {
                data: [100, 70, 50, 30, 10],
                backgroundColor: '#EFF1F5',
                borderColor: '#EFF1F5',
                borderRadius: 5,
                maxBarThickness: 14,
                borderSkipped: false,
              },
            ],
          }}
          type="bar"
        />
      )}
    </div>
  );
};

export default TopMerch;
