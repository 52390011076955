import React from 'react';
import Head from './Head';

function NotFound() {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="m-auto flex w-full max-w-sm flex-col text-center lg:w-7/12">
        <Head title="Not Found" />
        <img src="/images/NotFound.png" alt="404" className="m-auto max-w-xs" />
      </div>
    </div>
  );
}

export default NotFound;
