import { useDispatch, useSelector } from 'react-redux';
import AppCloseButton from '../../../../../components/AppCloseButton';
import { AppHeader } from '../../../../../components/html/html';
import { hideCardActionsPopup } from '../../../../../redux/CardDetailsReducer/CardDetailsActions';

function CardActionsPopupHeader() {
  const { currentCardActionsPopupContent } = useSelector((state) => state.cardDetails);

  const dispatch = useDispatch();

  return (
    <div className="flex items-center justify-between">
      <AppHeader h="h5">{currentCardActionsPopupContent?.header}</AppHeader>

      <AppCloseButton
        className="ms-auto"
        onClick={() => {
          dispatch(hideCardActionsPopup());
        }}
      />
    </div>
  );
}

export default CardActionsPopupHeader;
