import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MaxLimitImg from '../../../../assets/png/maxLimit.png';
import AppImageComponent from '../../../../components/AppImageComponent';
import BackBtn from '../../../../components/BackBtn';
import { AppHeader } from '../../../../components/html/html';
import { cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import { setActiveCreateNewCardStepAction } from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';

function CardMaxLimit() {
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();
  const { selectedCardType } = useSelector((state) => state.createNewCard);

  return (
    <div className="mx-auto mt-[10vh] w-full max-w-[470px] px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <div className="mb-5">
        <BackBtn
          className="mb-4"
          onClick={() => {
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardType));
          }}
        />
      </div>
      <AppHeader h="h3" className="text-center">
        {selectedCardType?.name === cardTypesKeys.VIRTUAL &&
          t('Virtual Cards Issuance Limit Reached')}
        {selectedCardType?.name === cardTypesKeys.PHYSICAL &&
          t('Plastic Cards Issuance Limit Reached!')}
      </AppHeader>
      <div className="mx-auto my-5 w-52">
        <AppImageComponent
          skeletonWidth={208}
          skeletonHeight={198}
          src={MaxLimitImg}
          alt="Full Image"
        />
      </div>
      <AppHeader h="h5" className="mb-3">
        {t('When you reach the maximum limit: ')}
      </AppHeader>
      <ul className="mb-16 list-disc ps-5">
        <li>
          {t(
            'You are unable to issue any new cards until one of the existing cards expires or is terminated',
          )}
        </li>
        <li>
          {t(
            'Once a card expires or is terminated, you have the option to replace it with a new one',
          )}
        </li>
      </ul>
      <div className="flex justify-end">
        <CancelBtn className="text-lg font-bold" />
      </div>
    </div>
  );
}

export default CardMaxLimit;
