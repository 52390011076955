import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ActionFail from '../../../../../../components/SharedComponents/ActionFail';
import ActionSuccess from '../../../../../../components/SharedComponents/ActionSuccess';
import DeclineDetails from '../../../../../../components/SharedComponents/DeclineDetails';
import ReceiptBalanceAndStatus from '../../../../../../components/SharedComponents/ReceiptBalanceAndStatus';
import ReceiptMerchantData from '../../../../../../components/SharedComponents/ReceiptMerchantData';
import RequestFrom from '../../../../../../components/SharedComponents/RequestFrom';
import { inPages } from '../../../../../../helpers/constants';
import { getTagsAction } from '../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function DeclineTabPopupContent({ cardDetails }) {
  const {
    showRequestFormPopup,
    singleRequestDetails,
    singleTransactionDetails,
    selectedActiveTab,
    tags,
    requestActionAnimation,
  } = useSelector((state) => state.cardDetails);

  const showActionSuccess = useMemo(() => {
    return requestActionAnimation.show && requestActionAnimation.isSuccess && !showRequestFormPopup;
  }, [requestActionAnimation, showRequestFormPopup]);

  const showActionFail = useMemo(() => {
    return (
      requestActionAnimation.show && !requestActionAnimation.isSuccess && !showRequestFormPopup
    );
  }, [requestActionAnimation, showRequestFormPopup]);

  return (
    <>
      {!showRequestFormPopup && !requestActionAnimation.show && (
        <>
          <div>
            <ReceiptBalanceAndStatus transactionDetails={singleTransactionDetails} />
          </div>
          <div>
            <ReceiptMerchantData
              activeTab={selectedActiveTab}
              transactionDetails={singleTransactionDetails}
              tags={tags}
              getTagsAction={getTagsAction}
              inPage={inPages.myCards}
            />
          </div>
          <div>
            <DeclineDetails transactionDetails={singleTransactionDetails} />
          </div>
        </>
      )}

      {showRequestFormPopup && (
        <div>
          <RequestFrom
            requestDetails={singleRequestDetails}
            cardDetails={cardDetails}
            transactionDetails={singleTransactionDetails}
            activeTab={selectedActiveTab}
            fromCardTransactions
          />
        </div>
      )}

      {showActionSuccess && <ActionSuccess requestAction={requestActionAnimation} />}

      {showActionFail && <ActionFail requestAction={requestActionAnimation} />}
    </>
  );
}

export default DeclineTabPopupContent;
