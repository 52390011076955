function ReceiptStatus({ color, children }) {
  const style = {
    color,
    background: `${color}30`,
  };
  return (
    <span className="rounded px-3 py-1 font-bold" style={style}>
      {children}
    </span>
  );
}

export default ReceiptStatus;
