/* eslint-disable import/no-extraneous-dependencies, camelcase */

import moment from 'moment-hijri';
import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import arabic from 'react-date-object/calendars/arabic';
import gregorian from 'react-date-object/calendars/gregorian';
import arabic_en from 'react-date-object/locales/arabic_en';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../../../../components/Button/ActionButton';
import {HeadlineCenter} from '../../../../components/Titles';
import {apiPostFormDataRequest} from '../../../../helpers/APIHelper';
import {setAction} from '../../../../helpers/ActionsHelper';

import 'moment/locale/en-gb';
import {TextInput} from '../../../../components/FormInputs/Inputs';
import {ArabicNamePattern, NameValidationPattern} from '../../../../helpers/RegexPatterns';
import useNotifications from '../../../../helpers/useNotifications';
import {SET_USER_DATA} from '../../../../redux/AuthReducer';
import {AppBody} from "../../../../components/html/html";

function ConfirmIdentityDetails({ identityDetails, setIdentityDetails, isOwner }) {
  const user = localStorage.getItem('userinfo')
    ? JSON.parse(localStorage.getItem('userinfo'))
    : null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['signup', 'common']);
  const userName =
    user && i18n.language === 'ar' && user.first_name_ar !== ''
      ? `${user.first_name_ar}`
      : `${user.first_name}`;
  const [expireDate, setExpireDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [, addNotification] = useNotifications();
  const [calendarType, setCalendarType] = useState(arabic);
  const [calendarLocaleType, setCalendarLocaleType] = useState(arabic_en);

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (identityDetails.id_type === 'nid') {
      setCalendarType(arabic);
      setCalendarLocaleType(arabic_en);
    } else if (identityDetails.id_type === 'passport') {
      setCalendarType(gregorian);
      setCalendarLocaleType(gregorian_en);
    } else {
      setCalendarType(gregorian);
      setCalendarLocaleType(gregorian_en);
    }
    setValue('first_name', identityDetails.first_name);
    setValue('father_name', identityDetails.father_name);
    setValue('grandfather_name', identityDetails.grandfather_name);
    setValue('last_name', identityDetails.last_name);
    setValue('first_name_ar', identityDetails.first_name_ar);
    setValue('father_name_ar', identityDetails.father_name_ar);
    setValue('grandfather_name_ar', identityDetails.grandfather_name_ar);
    setValue('last_name_ar', identityDetails.last_name_ar);
  }, []);

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  const onSubmit = (data) => {
    if (expireDate === null || expireDate === '') {
      setDateError(true);
    } else {
      const form = new FormData();
      setIsSubmitting(true);
      Object.keys(identityDetails).forEach(
        (key) => identityDetails[key] !== '' && form.append(key, identityDetails[key]),
      );
      Object.keys(data).forEach((key) => key !== 'id_expiry_date' && form.append(key, data[key]));

      form.append('id_expiry_date', moment(new Date(expireDate)).locale('en').format('YYYY-MM-DD'));
      form.append('id_file', identityDetails.id_file);

      apiPostFormDataRequest('users/identity', form, cookies.token, {
        'Content-Type': 'multipart/form-data',
      })
        .then((response) => {
          dispatch(setAction(SET_USER_DATA, response));
          if (isOwner) {
            navigate('/signup/verify_company');
          } else {
            navigate('/signup/accept_terms');
          }
        })
        .catch((err) => {
          addNotification(
            (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
            false,
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div className="flex flex-grow flex-col">
      <HeadlineCenter>{`${t('company_account.hi')}, ${userName},`}</HeadlineCenter>
      <form className="flex flex-col pt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid flex-grow grid-cols-4 gap-x-7 gap-y-2 rounded-lg bg-gray-3 bg-opacity-25 p-5 text-center">
          <div className="col-span-4 mt-5 flex flex-col justify-start lg:col-span-1 ltr:text-left rtl:text-right">
            <span>{t('verify_identity.national_no')}</span>
            <AppBody pClass="Body1Bold" type="span">{identityDetails.id_number}</AppBody>
          </div>
          <div className="col-span-4 mt-5 flex flex-col justify-start lg:col-span-1 ltr:text-left rtl:text-right">
            <span>{t('verify_identity.id_type')}</span>
            <AppBody pClass="Body1Bold" type="span" className="capitalize">{identityDetails.id_type}</AppBody>
          </div>
          <div className="col-span-4 mt-5 flex flex-col justify-start lg:col-span-1 ltr:text-left rtl:text-right">
            <span>{t('verify_identity.expiry_date')}</span>
            <DatePicker
              className="w-full"
              style={{ width: '100%' }}
              containerClassName="w-full"
              minDate={new Date()}
              inputClass={`${
                dateError ? 'border-red-400' : 'border-gray-3'
              } border rounded-full py-1 px-10 mt-1 `}
              value={expireDate}
              onChange={(date) => {
                if (date) {
                  setDateError(false);
                  setExpireDate(date);
                }
              }}
              calendar={calendarType}
              locale={calendarLocaleType}
              dropdownMode="scroll"
              showYearDropdown
            />
            <AppBody pClass="Caption1Regular" className="pt-2 text-red-700">
              {(dateError || expireDate === '') && t('verify_identity.enter_expire_date')}
            </AppBody>
          </div>

          <div className="col-span-4 mt-5 flex flex-col justify-start lg:col-span-1 ltr:text-left rtl:text-right">
            <span>{t('verify_identity.birth_date')}</span>
            <AppBody pClass="Body1Bold" type="span">{identityDetails.birth_date}</AppBody>
          </div>
        </div>
        <div className="flex flex-col pt-2 lg:pt-10">
          <div className="border-b border-gray-3 pb-5" dir="ltr">
            <div className=" grid flex-grow grid-cols-4 gap-2 p-5 text-center">
              <div className="col-span-4 mt-5 flex flex-col justify-start text-left lg:col-span-1">
                <span>First Name</span>
                <TextInput
                  error={errors.first_name}
                  id="first_name"
                  disabled={identityDetails.first_name !== '' ? 'disabled' : ''}
                  defaultValue={identityDetails.first_name}
                  {...register('first_name', {
                    required: t('verify_identity.first_name_required'),
                    pattern: {
                      value: NameValidationPattern,
                      message: t('details.validation.patterns.first_name'),
                    },
                  })}
                />
              </div>
              <div className="col-span-4 mt-5 flex flex-col justify-start text-left lg:col-span-1">
                <span>Father Name</span>
                <AppBody pClass="Body1Bold" type="span">{identityDetails.father_name}</AppBody>
                <TextInput
                  error={errors.father_name}
                  id="father_name"
                  disabled={identityDetails.father_name !== '' ? 'disabled' : ''}
                  defaultValue={identityDetails.father_name}
                  {...register('father_name', {
                    required: t('verify_identity.father_name_required'),
                    pattern: {
                      value: NameValidationPattern,
                      message: t('details.validation.patterns.father_name'),
                    },
                  })}
                />
              </div>
              <div className="col-span-4 mt-5 flex flex-col justify-start text-left lg:col-span-1">
                <span>Middle Name</span>
                <AppBody pClass="Body1Bold" type="span">{identityDetails.grandfather_name}</AppBody>
                <TextInput
                  error={errors.grandfather_name}
                  id="grandfather_name"
                  disabled={identityDetails.grandfather_name !== '' ? 'disabled' : ''}
                  defaultValue={identityDetails.grandfather_name}
                  {...register('grandfather_name', {
                    required: t('verify_identity.grandfather_name_required'),
                    pattern: {
                      value: NameValidationPattern,
                      message: t('details.validation.patterns.grandfather_name'),
                    },
                  })}
                />
              </div>

              <div className="col-span-4 mt-5 flex flex-col justify-start text-left lg:col-span-1">
                <span>Last Name</span>
                <AppBody pClass="Body1Bold" type="span">{identityDetails.last_name}</AppBody>
                <TextInput
                  error={errors.last_name}
                  id="last_name"
                  disabled={identityDetails.last_name !== '' ? 'disabled' : ''}
                  defaultValue={identityDetails.last_name}
                  {...register('last_name', {
                    required: t('verify_identity.last_name_required'),
                    pattern: {
                      value: NameValidationPattern,
                      message: t('details.validation.patterns.last_name'),
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className=" grid flex-grow grid-cols-4 gap-2 p-5 text-center" dir="rtl">
            <div className="col-span-4 mt-5 flex flex-col justify-start text-right lg:col-span-1">
              <span>الاسم الاول</span>
              <TextInput
                error={errors.first_name_ar}
                id="first_name_ar"
                disabled={identityDetails.first_name_ar !== '' ? 'disabled' : ''}
                defaultValue={identityDetails.first_name_ar}
                {...register('first_name_ar', {
                  required: t('verify_identity.first_name_ar_required'),
                  pattern: {
                    value: ArabicNamePattern,
                    message: t('details.validation.patterns.first_name'),
                  },
                })}
              />
            </div>
            <div className="col-span-4 mt-5 flex flex-col justify-start text-right lg:col-span-1">
              <span>اسم الاب</span>
              <TextInput
                error={errors.father_name_ar}
                id="father_name_ar"
                disabled={identityDetails.father_name_ar !== '' ? 'disabled' : ''}
                defaultValue={identityDetails.father_name_ar}
                {...register('father_name_ar', {
                  required: t('verify_identity.father_name_ar_required'),
                  pattern: {
                    value: ArabicNamePattern,
                    message: t('details.validation.patterns.father_name'),
                  },
                })}
              />
            </div>
            <div className="col-span-4 mt-5 flex flex-col justify-start text-right lg:col-span-1">
              <span>اسم الجد</span>
              <TextInput
                error={errors.grandfather_name_ar}
                id="grandfather_name_ar"
                disabled={identityDetails.grandfather_name_ar !== '' ? 'disabled' : ''}
                defaultValue={identityDetails.grandfather_name_ar}
                {...register('grandfather_name_ar', {
                  required: t('verify_identity.grandfather_name_ar_required'),
                  pattern: {
                    value: ArabicNamePattern,
                    message: t('details.validation.patterns.grandfather_name'),
                  },
                })}
              />
            </div>

            <div className="col-span-4 mt-5 flex flex-col justify-start text-right lg:col-span-1">
              <span>اسم العائلة</span>
              <TextInput
                error={errors.last_name_ar}
                id="last_name_ar"
                disabled={identityDetails.last_name_ar !== '' ? 'disabled' : ''}
                defaultValue={identityDetails.last_name_ar}
                {...register('last_name_ar', {
                  required: t('verify_identity.last_name_ar_required'),
                  pattern: {
                    value: ArabicNamePattern,
                    message: t('details.validation.patterns.last_name'),
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className="mt-10 flex flex-row justify-end self-stretch pt-8">
          <ActionButton
            className="flex flex-row"
            text={t('back', { ns: 'common' })}
            onClick={() => {
              setIdentityDetails(null);
            }}
          />
          <ActionButton
            type="submit"
            className="flex flex-row"
            primary
            text={t('verify_identity.its_me')}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
}

export default ConfirmIdentityDetails;
