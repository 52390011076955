import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleCharacterLimit } from '../../helpers/Utils';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import { AppBody, AppButton } from '../html/html';

import { inPages } from '../../helpers/constants';
import usePermissions from '../../hooks/usePermissions';
import { getSingleTransactionDetailsAction } from '../../redux/CardDetailsReducer/CardDetailsActions';
import { getSingleWalletTransactionDetailsAction } from '../../redux/WalletReducer/WalletActions';

function ReceiptCardDescription({ transactionDetails, inPage }) {
  const userCan = usePermissions();

  const maxDescriptionCharNum = 150;
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const { auth } = useSelector((state) => state);
  const { loaders } = useSelector((state) => state);

  const [isEditActive, setIsEditActive] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [descriptionRequiredError, setDescriptionRequiredError] = useState(false);
  const ref = useRef();
  const dispatch = useDispatch();

  const canEditDescription = useMemo(() => {
    const canAddOrEditTransactionDescription =
      userCan('add_account_transaction_description') ||
      userCan('edit_account_transaction_description');

    const canAddOrEditOwnTransactionDescription =
      (userCan('add_own_transaction_description') || userCan('edit_own_transaction_description')) &&
      auth.user?.id === transactionDetails.user_id?.id;

    return canAddOrEditTransactionDescription || canAddOrEditOwnTransactionDescription;
  }, [transactionDetails, auth]);

  const handleEditTransactionDescription = (description) => {
    if (!description) {
      setDescriptionRequiredError(true);
    } else {
      setDescriptionRequiredError(false);
      http
        .put(`transactions/${transactionDetails.id}`, null, {
          params: { reason: description },
          loader: 'handleEditTransactionDescription',
        })
        .then(() => {
          setDescriptionValue(description);
          setIsEditActive(false);
          dispatch(
            showToasterAction({
              type: 'success',
              message: t('The description was updated successfully!'),
            }),
          );

          if (inPage === inPages.myCards) {
            dispatch(getSingleTransactionDetailsAction(transactionDetails.id));
          }
          if (inPage === inPages.wallet) {
            dispatch(getSingleWalletTransactionDetailsAction(transactionDetails.id));
          }
        })
        .catch((err) => {
          setIsEditActive(true);
          dispatch(showToasterAction({ type: 'error', message: err.data.error }));
        });
    }
  };

  useEffect(() => {
    if (isEditActive) {
      ref.current.focus();
    }
  }, [isEditActive]);

  useEffect(() => {
    if (transactionDetails?.reason) {
      setDescriptionValue(transactionDetails.reason);
    }
  }, [transactionDetails]);

  return (
    <div className="rounded-md bg-[#def1f2] p-4">
      <AppBody pClass="Body2Bold" className="mb-2 flex justify-between text-cyan-700">
        <span>{t('Transaction Description:')}</span>
        {canEditDescription && (
          <>
            {!isEditActive && (
              <AppButton
                button="link"
                className="print:hidden"
                onClick={() => {
                  setIsEditActive(true);
                }}
              >
                {descriptionValue && t('Edit', { ns: 'commonV2' })}
                {!descriptionValue && t('Add', { ns: 'commonV2' })}
              </AppButton>
            )}
            {isEditActive && (
              <AppButton
                button="link"
                isLoading={loaders.handleEditTransactionDescription}
                loadingLabel={t('Saving', { ns: 'commonV2' })}
                onClick={() => {
                  handleEditTransactionDescription(descriptionValue);
                }}
              >
                {t('Save', { ns: 'commonV2' })}
              </AppButton>
            )}
          </>
        )}
      </AppBody>

      <textarea
        ref={ref}
        disabled={!isEditActive || loaders.handleEditTransactionDescription}
        rows={isEditActive ? 5 : 1}
        className="w-full rounded-md border border-cyan-700 bg-transparent p-1 text-sm disabled:border-0 disabled:opacity-70"
        onChange={(e) => {
          setDescriptionValue(e.target.value);
        }}
        onKeyDown={(e) => {
          const value = handleCharacterLimit(descriptionValue, maxDescriptionCharNum, e);
          if (value !== descriptionValue) {
            setDescriptionValue(value);
          }
        }}
        value={descriptionValue}
        required
      />
      <div className="flex justify-between gap-1">
        {isEditActive && descriptionRequiredError && (
          <AppBody pClass="Caption2Medium" className="text-start text-red-600">
            ({t('This field is required!', { ns: 'commonV2' })})
          </AppBody>
        )}
        {isEditActive && (
          <div className="text-end text-xs text-gray-6">
            {descriptionValue.length} / {maxDescriptionCharNum}
          </div>
        )}
      </div>
    </div>
  );
}

export default ReceiptCardDescription;
