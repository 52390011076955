import { walletTabs } from '../../helpers/constants';

const INITIAL_STATE = {
  walletCardPrograms: null,
  singleSelectedCardProgramData: null,
  singleSelectedCardProgramDetails: null,
  selectedCardProgramDetailsBalance: null,
  selectedWalletMainActiveTab: walletTabs.REQUESTS,
  selectedWalletSubActiveTab: walletTabs.PENDING,
  walletRequests: null,
  walletFundActivities: null,
  walletCardActivities: null,
  showWalletFilterPopup: false,
  showWalletSearchPopup: false,
  walletRequestCurrencies: null,
  walletTransactionCurrencies: null,
  walletRequestsDepartments: null,
  walletUsers: null,
  walletFundActivityStatuses: null,
  singleWalletRequestDetails: null,
  singleWalletFundActivityDetails: null,
  singleWalletCardActivityDetails: null,
  showWalletReceiptPopup: false,
  showWalletRequestFormPopup: false,
  showWalletFundsActivityFormPopup: false,
  showWalletCardsActivityFormPopup: false,
  willingToRejectRequest: false,
  selectedRejectionReason: null,
  otherRejectionReason: null,
  walletPendingRequestsCount: null,
  approvedRejectedRequestAnimation: {
    show: false,
    isApproved: null,
  },
  walletTransactions: null,
  singleWalletTransactionDetails: null,
  showWalletSections: null,
  walletTags: null,
  walletDocTypes: null,
  walletDepartments: null,
  walletRequestActionAnimation: {
    show: false,
    isSuccess: null,
    amount: null,
    message: null,
    reason: null,
  },
  actions: {
    activeWalletStepIndex: null,
    targetIBANID: null,
    amount: null,
    whitelistedIBANS: null,
    transferFromData: null,
    supportedIBANS: null,
    transferToData: null,
    isFinish: false,
  },
  currentCardProgramActionsPopupContent: null,
  addFundMethod: {
    IBANData: null,
    fundMethodFormData: null,
    isFinish: false,
  },
};

const WalletReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // ====== Card Program List ====== //
    case 'GET_WALLET_CARD_PROGRAMS_LIST': {
      return { ...state, walletCardPrograms: action.payload };
    }

    case 'CLEAR_CARD_PROGRAM_LIST': {
      return { ...state, walletCardPrograms: null };
    }

    case 'UPDATE_SINGLE_COLUMN_CARD_PROGRAM_BALANCE': {
      const updated = state.walletCardPrograms.map((ele) => {
        if (ele.id === action.payload.card.id) {
          return {
            ...ele,
            balance: action.payload.balance,
          };
        }
        return ele;
      });

      return { ...state, walletCardPrograms: updated };
    }

    case 'SELECT_SINGLE_CARD_PROGRAM': {
      return { ...state, singleSelectedCardProgramData: action.payload };
    }

    case 'CLEAR_SELECTED_CARD_PROGRAM_DATA': {
      return { ...state, singleSelectedCardProgramData: null };
    }

    case 'ADD_SELECTED_CARD_PROGRAM_DETAILS': {
      return { ...state, singleSelectedCardProgramDetails: action.payload };
    }

    case 'CLEAR_SELECTED_CARD_PROGRAM_DETAILS': {
      return { ...state, singleSelectedCardProgramDetails: null };
    }

    // ====== Card Program Details ====== //
    case 'ADD_CARD_PROGRAM_DETAILS_BALANCE': {
      return {
        ...state,
        selectedCardProgramDetailsBalance: { ...action.payload },
      };
    }

    case 'CLEAR_CARD_PROGRAM_BALANCE': {
      return {
        ...state,
        selectedCardProgramDetailsBalance: null,
      };
    }

    // ====== Wallet Tabs ====== //
    case 'SET_ACTIVE_WALLET_MAIN_TAB': {
      return {
        ...state,
        selectedWalletMainActiveTab: action.payload,
      };
    }

    case 'SET_ACTIVE_WALLET_SUB_TAB': {
      return {
        ...state,
        selectedWalletSubActiveTab: action.payload,
      };
    }

    // ====== Wallet Requests ====== //
    case 'ADD_WALLET_REQUESTS': {
      return {
        ...state,
        walletRequests: action.payload,
      };
    }

    case 'CLEAR_WALLET_REQUESTS': {
      return {
        ...state,
        walletRequests: null,
      };
    }

    case 'UPDATE_WALLET_REQUESTS': {
      const updatedSingleWalletRequestDetails = state.singleWalletRequestDetails
        ? { ...action.payload }
        : null;

      const updatedItems = state.walletRequests.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...state,
        singleWalletRequestDetails: updatedSingleWalletRequestDetails,
        walletRequests: { data: updatedItems },
      };
    }

    // ====== Wallet Fund Activity ====== //
    case 'ADD_WALLET_FUND_ACTIVITIES': {
      return {
        ...state,
        walletFundActivities: action.payload,
      };
    }

    case 'CLEAR_WALLET_FUND_ACTIVITIES': {
      return {
        ...state,
        walletFundActivities: null,
      };
    }

    case 'UPDATE_WALLET_FUND_ACTIVITIES': {
      const updatedSingleWalletRequestDetails = state.singleWalletFundActivityDetails
        ? { ...action.payload }
        : null;

      const updatedItems = state.walletFundActivities.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...state,
        singleWalletFundActivityDetails: updatedSingleWalletRequestDetails,
        walletFundActivities: { data: updatedItems },
      };
    }

    // ====== Wallet CARD Activity ====== //
    case 'ADD_WALLET_CARD_ACTIVITIES': {
      return {
        ...state,
        walletCardActivities: action.payload,
      };
    }

    case 'CLEAR_WALLET_CARD_ACTIVITIES': {
      return {
        ...state,
        walletCardActivities: null,
      };
    }

    case 'UPDATE_WALLET_CARD_ACTIVITIES': {
      const updatedSingleWalletRequestDetails = state.singleWalletCardActivityDetails
        ? { ...action.payload }
        : null;

      const updatedItems = state.walletCardActivities.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...state,
        singleWalletCardActivityDetails: updatedSingleWalletRequestDetails,
        walletCardActivities: { data: updatedItems },
      };
    }

    // ====== Wallet Request Details ====== //
    case 'ADD_SINGLE_WALLET_REQUEST_DETAILS': {
      return {
        ...state,
        singleWalletRequestDetails: action.payload,
      };
    }

    case 'CLEAR_SINGLE_WALLET_REQUEST_DETAILS': {
      return {
        ...state,
        singleWalletRequestDetails: null,
      };
    }

    case 'ADD_WALLET_REQUEST_CURRENCIES': {
      return {
        ...state,
        walletRequestCurrencies: action.payload,
      };
    }

    case 'CLEAR_WALLET_REQUEST_CURRENCIES': {
      return {
        ...state,
        walletRequestCurrencies: null,
      };
    }

    case 'ADD_WALLET_REQUEST_DEPARTMENTS': {
      return {
        ...state,
        walletRequestsDepartments: action.payload,
      };
    }

    case 'CLEAR_WALLET_REQUEST_DEPARTMENTS': {
      return {
        ...state,
        walletRequestsDepartments: null,
      };
    }

    case 'ADD_WALLET_USERS': {
      return {
        ...state,
        walletUsers: action.payload,
      };
    }

    case 'CLEAR_WALLET_USERS': {
      return {
        ...state,
        walletUsers: null,
      };
    }

    case 'SET_WILLING_TO_REJECT_REQUEST': {
      return { ...state, willingToRejectRequest: action.payload };
    }

    case 'SET_SELECTED_REJECTION_REASON': {
      return { ...state, selectedRejectionReason: action.payload };
    }

    case 'SET_OTHER_REJECTION_REASON': {
      return { ...state, otherRejectionReason: action.payload };
    }

    case 'SET_PENDING_REQUESTS_COUNT': {
      return { ...state, walletPendingRequestsCount: action.payload };
    }

    case 'CLEAR_PENDING_REQUESTS_COUNT': {
      return { ...state, walletPendingRequestsCount: 0 };
    }

    case 'SHOW_WALLET_APPROVE_REJECT_REQUEST': {
      return {
        ...state,
        approvedRejectedRequestAnimation: {
          show: true,
          isApproved: action.payload.isApproved,
        },
      };
    }

    case 'HIDE_WALLET_APPROVE_REJECT_REQUEST': {
      return {
        ...state,
        approvedRejectedRequestAnimation: {
          show: false,
          isApproved: null,
        },
      };
    }

    // ====== Wallet Transactions ====== //
    case 'ADD_WALLET_TRANSACTIONS': {
      return { ...state, walletTransactions: action.payload };
    }

    case 'CLEAR_WALLET_TRANSACTIONS': {
      return { ...state, walletTransactions: null };
    }

    case 'UPDATE_WALLET_TRANSACTIONS': {
      const updatedSingleWalletTransactionDetails = state.singleWalletTransactionDetails
        ? { ...action.payload }
        : null;

      const updatedItems = state.walletTransactions.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...state,
        singleWalletTransactionDetails: updatedSingleWalletTransactionDetails,
        walletTransactions: { data: updatedItems },
      };
    }

    // ====== Wallet Transaction Details ====== //

    case 'ADD_SINGLE_WALLET_TRANSACTION_DETAILS': {
      return {
        ...state,
        singleWalletTransactionDetails: action.payload,
      };
    }

    case 'CLEAR_SINGLE_WALLET_TRANSACTION_DETAILS': {
      return {
        ...state,
        singleWalletTransactionDetails: null,
      };
    }

    case 'ADD_WALLET_DEPARTMENTS': {
      return {
        ...state,
        walletDepartments: action.payload,
      };
    }

    case 'CLEAR_WALLET_DEPARTMENTS': {
      return {
        ...state,
        walletDepartments: null,
      };
    }

    case 'ADD_WALLET_TAGS': {
      return {
        ...state,
        walletTags: action.payload,
      };
    }

    case 'CLEAR_WALLET_TAGS': {
      return {
        ...state,
        walletTags: null,
      };
    }

    case 'GET_WALLET_DOC_TYPES': {
      return {
        ...state,
        walletDocTypes: action.payload,
      };
    }

    case 'CLEAR_WALLET_DOC_TYPES': {
      return {
        ...state,
        walletDocTypes: null,
      };
    }

    case 'ADD_WALLET_TRANSACTION_CURRENCIES': {
      return {
        ...state,
        walletTransactionCurrencies: action.payload,
      };
    }

    case 'CLEAR_WALLET_TRANSACTION_CURRENCIES': {
      return {
        ...state,
        walletTransactionCurrencies: null,
      };
    }

    case 'SHOW_WALLET_SECTION': {
      return {
        ...state,
        showWalletSections: { ...state.showWalletSections, [action.payload]: true },
      };
    }

    case 'HIDE_WALLET_SECTION': {
      return {
        ...state,
        showWalletSections: { ...state.showWalletSections, [action.payload]: false },
      };
    }

    case 'HIDE_ALL_WALLET_SECTIONS': {
      return { ...state, showWalletSections: null };
    }

    // ====== Wallet Popups ====== //
    case 'SHOW_WALLET_FILTER_POPUP': {
      return {
        ...state,
        showWalletFilterPopup: true,
      };
    }

    case 'HIDE_WALLET_FILTER_POPUP': {
      return {
        ...state,
        showWalletFilterPopup: false,
      };
    }

    case 'SHOW_WALLET_SEARCH_POPUP': {
      return {
        ...state,
        showWalletSearchPopup: true,
      };
    }

    case 'HIDE_WALLET_SEARCH_POPUP': {
      return {
        ...state,
        showWalletSearchPopup: false,
      };
    }

    case 'SHOW_RECEIPT_POPUP': {
      return {
        ...state,
        showWalletReceiptPopup: true,
      };
    }

    case 'HIDE_RECEIPT_POPUP': {
      return {
        ...state,
        showWalletReceiptPopup: false,
      };
    }

    case 'SHOW_WALLET_REQUEST_FORM_POPUP': {
      return { ...state, showWalletRequestFormPopup: true };
    }

    case 'HIDE_WALLET_REQUEST_FORM_POPUP': {
      return { ...state, showWalletRequestFormPopup: false };
    }

    case 'SHOW_WALLET_FUND_ACTIVITY_FORM_POPUP': {
      return { ...state, showWalletFundsActivityFormPopup: true };
    }

    case 'HIDE_WALLET_FUND_ACTIVITY_FORM_POPUP': {
      return { ...state, showWalletFundsActivityFormPopup: false };
    }

    case 'SHOW_WALLET_CARD_ACTIVITY_FORM_POPUP': {
      return { ...state, showWalletCardsActivityFormPopup: true };
    }

    case 'HIDE_WALLET_CARD_ACTIVITY_FORM_POPUP': {
      return { ...state, showWalletCardsActivityFormPopup: false };
    }

    case 'ADD_WALLET_REQUEST_ACTION_ANIMATION': {
      return { ...state, walletRequestActionAnimation: action.payload };
    }

    case 'CLEAR_WALLET_REQUEST_ACTION_ANIMATION': {
      return {
        ...state,
        walletRequestActionAnimation: {
          show: false,
          isSuccess: null,
          amount: null,
          message: null,
          reason: null,
        },
      };
    }
    // ====== Wallet Fund Activity Details ====== //
    case 'ADD_SINGLE_WALLET_FUND_ACTIVITY_DETAILS': {
      return {
        ...state,
        singleWalletFundActivityDetails: action.payload,
      };
    }

    case 'CLEAR_SINGLE_WALLET_FUND_ACTIVITY_DETAILS': {
      return {
        ...state,
        singleWalletFundActivityDetails: null,
      };
    }

    // ====== Wallet Activity Details ====== //
    case 'ADD_SINGLE_WALLET_CARD_ACTIVITY_DETAILS': {
      return {
        ...state,
        singleWalletCardActivityDetails: action.payload,
      };
    }

    case 'CLEAR_SINGLE_WALLET_CARD_ACTIVITY_DETAILS': {
      return {
        ...state,
        singleWalletCardActivityDetails: null,
      };
    }

    // ====== Wallet Actions ====== //

    case 'CLEAR_WALLET_ACTIONS': {
      const updatedActions = {
        activeWalletStepIndex: null,
        targetIBANID: null,
        amount: null,
        whitelistedIBANS: null,
        transferFromData: null,
        supportedIBANS: null,
        transferToData: null,
        isFinish: false,
      };

      return {
        ...state,
        actions: updatedActions,
      };
    }

    case 'SET_ACTIVE_WALLET_STEP_INDEX': {
      const updatedActions = { ...state.actions };
      updatedActions.activeWalletStepIndex = action.payload;

      return { ...state, actions: updatedActions };
    }

    case 'ADD_TARGET_IBAN_ID': {
      const updatedActions = { ...state.actions };
      updatedActions.targetIBANID = action.payload;

      return { ...state, actions: updatedActions };
    }

    case 'ADD_TRANSFER_AMOUNT': {
      const updatedActions = { ...state.actions };
      updatedActions.amount = action.payload;

      return { ...state, actions: updatedActions };
    }

    case 'ADD_WHITELISTED_IBANS': {
      const updatedActions = { ...state.actions };
      updatedActions.whitelistedIBANS = action.payload;

      return { ...state, actions: updatedActions };
    }

    case 'ADD_TRANSFER_FROM_DATA': {
      const updatedActions = { ...state.actions };
      updatedActions.transferFromData = action.payload;

      return { ...state, actions: updatedActions };
    }

    case 'ADD_SUPPORTED_IBANS': {
      const updatedActions = { ...state.actions };
      updatedActions.supportedIBANS = action.payload;

      return { ...state, actions: updatedActions };
    }

    case 'ADD_TRANSFER_TO_DATA': {
      const updatedActions = { ...state.actions };
      updatedActions.transferToData = action.payload;

      return { ...state, actions: updatedActions };
    }

    case 'ADD_TRANSFER_FROM_CARD_PROGRAM_BALANCE': {
      const balance = action.payload;
      const updatedActions = { ...state.actions };
      updatedActions.transferFromData.balance = balance;

      return { ...state, actions: updatedActions };
    }

    case 'ADD_TRANSFER_TO_CARD_PROGRAM_BALANCE': {
      const balance = action.payload;
      const updatedActions = { ...state.actions };
      updatedActions.transferToData.balance = balance;

      return { ...state, actions: updatedActions };
    }

    case 'SET_IS_FINISH': {
      const updatedActions = { ...state.actions };
      updatedActions.isFinish = action.payload;

      return { ...state, actions: updatedActions };
    }

    // ====== Card Program Actions Popup ====== //
    case 'SET_CURRENT_CARD_PROGRAM_ACTIONS_POPUP_CONTENT': {
      return { ...state, currentCardProgramActionsPopupContent: action.payload };
    }

    case 'HIDE_CARD_PROGRAM_ACTIONS_POPUP': {
      return { ...state, currentCardProgramActionsPopupContent: null };
    }

    case 'ADD_WALLET_FUND_ACTIVITY_STATUS': {
      return {
        ...state,
        walletFundActivityStatuses: action.payload,
      };
    }

    case 'CLEAR_WALLET_FUND_ACTIVITY_STATUS': {
      return {
        ...state,
        walletFundActivityStatuses: null,
      };
    }

    // ====== Add Fund Method ====== //
    case 'SAVE_IBAN_DATA': {
      return {
        ...state,
        addFundMethod: { ...state.addFundMethod, IBANData: action.payload },
      };
    }

    case 'SAVE_FUND_METHOD_FORM_DATA': {
      return {
        ...state,
        addFundMethod: {
          ...state.addFundMethod,
          fundMethodFormData: action.payload,
        },
      };
    }

    case 'CLEAR_ADD_FUND_METHOD': {
      return {
        ...state,
        addFundMethod: {
          IBANData: null,
          fundMethodFormData: null,
          isFinish: false,
        },
      };
    }

    case 'SET_IS_FINISH_FUND_METHOD': {
      return {
        ...state,
        addFundMethod: {
          ...state.addFundMethod,
          isFinish: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default WalletReducer;
