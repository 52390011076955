import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BackBtn from '../../../../components/BackBtn';
import NqoodCardImg from '../../../../components/NqoodCardImg';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { devLog } from '../../../../helpers/Utils';
import { cardStatusKeys, cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import {
  setActiveCreateNewCardStepAction,
  setPendingVirtualCardAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';

function CreateOrReplaceCard() {
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();
  const { selectedCardProgram, selectedCardType, userPrimaryCard } = useSelector(
    (state) => state.createNewCard,
  );
  const [action, setAction] = useState('');

  const actionIds = useMemo(() => {
    return {
      CREATE: 'CREATE',
      REPLACE: 'REPLACE',
    };
  });

  const actions = useMemo(() => {
    return [
      {
        id: actionIds.CREATE,
        name: t('New Card'),
      },
      {
        id: actionIds.REPLACE,
        name: t('Replace Inactive Card'),
      },
    ];
  }, [t, actionIds]);

  const selectedClasses = useMemo(() => {
    return 'outline-2 outline-black';
  }, []);

  const unSelectedClasses = useMemo(() => {
    return 'outline-1 outline-stone-300';
  }, []);

  const handleCreatePhysical = useCallback(() => {
    if (userPrimaryCard) {
      const primaryCardStatus = userPrimaryCard?.status?.key?.toUpperCase();
      if (
        primaryCardStatus === cardStatusKeys.SUSPENDED ||
        primaryCardStatus === cardStatusKeys.INACTIVE
      ) {
        devLog(
          `[Create Or Replace Page] Primary Card is ${primaryCardStatus}, Adding it to pendingPrimaryVirtualCard.`,
        );
        dispatch(setPendingVirtualCardAction(userPrimaryCard));
      }
      devLog('[Create Or Replace Page] User has a Primary Card!');
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPlasticCard));
    } else {
      devLog(
        '[Create Or Replace Page] User has [NO] Primary Card, Navigating to create a New Virtual Primary Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPrimaryCard));
    }
  }, [userPrimaryCard]);

  const handleCreateVirtual = useCallback(() => {
    if (!userPrimaryCard) {
      devLog(
        '[Create Or Replace Page] User has [NO] Primary Card, Navigating to [Create] a New Virtual Primary Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPrimaryCard));
    } else {
      devLog(
        '[Create Or Replace Page] User [has] a Primary Card, Navigating to [Customize] the New Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.customizePrimaryCard));
    }
  }, [userPrimaryCard]);

  const handleNextStep = useCallback(() => {
    if (action.id === actionIds.REPLACE) {
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectReplaceCard));
    } else {
      if (selectedCardType?.name === cardTypesKeys.PHYSICAL) {
        handleCreatePhysical();
      }

      if (selectedCardType?.name === cardTypesKeys.VIRTUAL) {
        handleCreateVirtual();
      }
    }
  }, [action]);

  return (
    <div className="mx-auto mt-[10vh] w-full px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <BackBtn
        className="mb-4"
        onClick={() => {
          dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardType));
        }}
      />
      <div className="mb-5 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {actions.map((ele) => {
          return (
            <button
              onClick={() => {
                setAction(ele);
              }}
              type="button"
              key={ele.id}
              className={`
              ${
                action?.id === ele.id ? selectedClasses : unSelectedClasses
              } relative m-1 flex flex-col justify-between rounded-md bg-white text-start outline`}
            >
              <div className="p-5">
                <AppHeader h="h6" className="w-full text-center">
                  {ele.name}
                </AppHeader>
                <div
                  className={`${
                    ele.id === actionIds.REPLACE ? 'grayscale' : ''
                  } my-4 overflow-hidden rounded-lg shadow`}
                >
                  <NqoodCardImg
                    bankLogo={selectedCardProgram?.funding_source_type?.bank_logo}
                    scheme={selectedCardProgram.scheme}
                    skeletonHeight={163}
                    skeletonWidth={260}
                    type={selectedCardType.name.toLowerCase()}
                    alt={`${ele.type} Card`}
                  />
                </div>
              </div>

              <div className="flex w-full items-center gap-2 border-t border-gray-3 p-5">
                <span className="text-sm">{t('Annual Fees: ')}</span>
                <AppBody
                  pClass="Body1Bold"
                  type="span"
                  style={{ direction: 'ltr' }}
                  className="flex gap-1"
                >
                  <span>{selectedCardType.fee}</span>
                  <span>{selectedCardType.currency}</span>
                </AppBody>
              </div>
            </button>
          );
        })}
      </div>

      <div className="flex justify-end gap-3 pb-5">
        <CancelBtn />

        <AppButton
          disabled={!selectedCardType}
          onClick={handleNextStep}
          isLoading={false}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default CreateOrReplaceCard;
