import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import AttachmentPreview from '../../../components/AttachmentPreview';
import { apiGetRequestDownload } from '../../../helpers/APIHelper';

// eslint-disable-next-line func-names
const TransactionAttachmentDownload = function (props) {
  const { attachmentId, attachmentPath, transaction, forPrint, setAttachmentRender } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [cookies] = useCookies(['token']);
  const [attachment, setAttachment] = useState('#');
  const [attachmentType, setAttachmentType] = useState(null);

  const fetchAttachment = () => {
    setIsLoading(true);

    apiGetRequestDownload(`transactions/receipts/${attachmentId}/${transaction.id}`, cookies.token)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers['content-type'] }),
        );

        setAttachmentType(response.headers['content-type']);
        setAttachment(url);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAttachment();
  }, [transaction]);

  return (
    <AttachmentPreview
      attachment={attachment}
      attachmentId={attachmentId}
      attachmentPath={attachmentPath}
      attachmentType={attachmentType}
      errored={errored}
      fetchAttachment={fetchAttachment}
      isLoading={isLoading}
      forPrint={forPrint}
      setAttachmentRender={setAttachmentRender}
    />
  );
};

export default TransactionAttachmentDownload;
