import { dummyArr } from 'javascript-functions';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import masterCardLogo from '../../../../assets/png/Mastercard-logo.png';
import visaCardLogo from '../../../../assets/png/Visa-logo.png';
import cardProgramCard from '../../../../assets/png/cardProgramCard.png';
import AppImageComponent from '../../../../components/AppImageComponent';
import { AppButton, AppHeader, AppBody } from '../../../../components/html/html';
import { devLog, generateMockCard, getServerErrMessage } from '../../../../helpers/Utils';
import { createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  getCardProgramsAction,
  selectCardProgramAction,
  setActiveCreateNewCardStepAction,
  setUserPrimaryCardAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';

function SelectCardProgram() {
  const { loaders, useMock } = useSelector((state) => state);
  const { cardPrograms, selectedCardProgram, createCardUserData } = useSelector(
    (state) => state.createNewCard,
  );

  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const renderCardLogo = useCallback((scheme) => {
    if (scheme.toUpperCase() === 'MASTER') {
      return <img src={masterCardLogo} alt="masterCard Logo" width={50} />;
    }
    if (scheme.toUpperCase() === 'VISA') {
      return <img src={visaCardLogo} alt="visa Logo" width={50} />;
    }
    return null;
  }, []);

  const handleNextStep = useCallback(() => {
    devLog(`Getting user Primary Card...`);
    let mockParams = null;

    if (useMock.hasPrimaryCardMock) {
      mockParams = generateMockCard({ status: useMock.hasPrimaryCardMockStatus });
    }
    if (useMock.hasNoPrimaryCardMock) {
      mockParams = [];
    }

    http
      .get(`cardmanager/cards/primary`, {
        loader: `getUserPrimaryCardAction`,
        params: { card_product_id: selectedCardProgram.id, user_id: createCardUserData.id },
        useMock: useMock.hasPrimaryCardMock || useMock.hasNoPrimaryCardMock,
        mockParams,
      })
      .then((res) => {
        if (Object.keys(res.data).length) {
          devLog(`Primary Card Found and It's Status is [${res.data.status.key}]`);
          dispatch(setUserPrimaryCardAction(res.data));
        } else {
          devLog(`[NO] Primary Card Found!`);
          dispatch(setUserPrimaryCardAction(null));
        }
        dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardType));
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [selectedCardProgram, createCardUserData, useMock]);

  useEffect(() => {
    dispatch(getCardProgramsAction());
  }, []);

  return (
    <div className="mx-auto mt-[15vh] w-full px-5 pb-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <div className="mb-5">
        <AppHeader h="h3">{t('Card Program')}</AppHeader>
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('Select card program to continue')}
        </AppBody>
      </div>

      <div className="mb-5 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {!loaders.getCardProgramsAction &&
          cardPrograms?.map((ele) => {
            return (
              <button
                onClick={() => {
                  dispatch(selectCardProgramAction(ele));
                }}
                type="button"
                key={ele.id}
                id={ele.id}
                className={`${
                  selectedCardProgram?.id === ele.id
                    ? 'outline-2 outline-black'
                    : 'outline-1 outline-stone-300'
                } relative m-1 overflow-hidden rounded-lg bg-white text-start shadow-md outline`}
              >
                <i
                  className={`${ele?.funding_source_type?.bank_logo} absolute right-4 top-4 text-xl`}
                />
                <AppImageComponent src={cardProgramCard} skeletonHeight={187} skeletonWidth={296} />

                <AppHeader h="h6" className="absolute bottom-4 left-4">
                  {ele.currency}
                </AppHeader>

                <div className="absolute bottom-4 right-4">{renderCardLogo(ele.scheme)}</div>
              </button>
            );
          })}
        {loaders.getCardProgramsAction &&
          dummyArr(4).map((_, i) => {
            return (
              <div key={JSON.stringify(i)}>
                <Skeleton height={187} />
              </div>
            );
          })}
      </div>

      <div className="flex justify-end gap-3">
        <CancelBtn />
        <AppButton
          disabled={!selectedCardProgram}
          onClick={handleNextStep}
          isLoading={loaders.getUserPrimaryCardAction}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default SelectCardProgram;
