export const addLoader = (loaderKey) => (dispatch) => {
  const loader = {};
  loader[loaderKey] = true;
  dispatch({
    type: 'ADD_LOADER',
    payload: loader,
  });
};
export const removeLoader = (loaderKey) => (dispatch) => {
  dispatch({
    type: 'REMOVE_LOADER',
    payload: loaderKey,
  });
};
