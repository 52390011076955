import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import { AppBody, AppIcon } from '../../../../components/html/html';
import { inPages, userRoles, usersStatus } from '../../../../helpers/constants';
import useClickOutside from '../../../../hooks/useClickOutside';
import usePermissions from '../../../../hooks/usePermissions';
import { showModalAction } from '../../../../redux/AppModalReducer/AppModalActions';
import {
  changeUserStatusAction,
  getSingleUserDetailsAction,
  resendUserActivationEmailAction,
} from '../../../../redux/UsersReducer/UsersActions';
import ChangeStatusConfirmationPopup from '../ChangeStatusConfirmationPopup';
import EditSingleUserPopup from '../EditSingleUserPopup';

function SingleUserOptionsPopup({
  item,
  isLastEl,
  setShowOptionsPopup,
  showOptionsPopup,
  navigateToSingleUserDetails,
}) {
  const userCan = usePermissions();
  const { t } = useTranslation('usersV2');
  const { isMobile } = useSelector((state) => state.appConfig);
  const { auth } = useSelector((state) => state);
  const popupRef = useRef();
  const dispatch = useDispatch();

  useClickOutside(popupRef, () => {
    setShowOptionsPopup({});
  });

  const isActive = useMemo(() => {
    return item.status === usersStatus.Active;
  }, [item]);

  const isPending = useMemo(() => {
    return item.status === usersStatus.Pending;
  }, [item]);

  const isSuspended = useMemo(() => {
    return item.status === usersStatus.Suspended;
  }, [item]);

  const isDeactivated = useMemo(() => {
    return item.status === usersStatus.Deactivate;
  }, [item]);

  const isBanned = useMemo(() => {
    return item.status === usersStatus.Banned;
  }, [item]);

  const notSameLoggedInUser = useMemo(() => {
    return item.id !== auth.user.id;
  }, [item]);

  const notAccountOwner = useMemo(() => {
    return !item.roles.includes(userRoles.Owner);
  }, [item]);

  const canChangeStatus = useMemo(() => {
    return userCan('update_account') && notAccountOwner && notSameLoggedInUser;
  }, [notAccountOwner, notSameLoggedInUser]);

  const handleEditUser = useCallback(() => {
    if (isMobile) {
      history.push(`/dashboard/users/${item.id}/edit?backToList=true`);
    } else {
      dispatch(showModalAction(<EditSingleUserPopup inPage={inPages.usersList} />));
    }

    dispatch(getSingleUserDetailsAction({ userId: item.id }));
  }, [isMobile, item.id]);

  const changeUserStatus = useCallback(
    (status) => {
      if (status === usersStatus.Suspended) {
        dispatch(showModalAction(<ChangeStatusConfirmationPopup item={item} status={status} />));
      } else if (status === usersStatus.Deactivate) {
        dispatch(showModalAction(<ChangeStatusConfirmationPopup item={item} status={status} />));
      } else {
        dispatch(changeUserStatusAction({ userId: item.id, status }));
      }
    },
    [item.id],
  );

  const resendActivationEmail = useCallback(() => {
    dispatch(resendUserActivationEmailAction({ userId: item.id }));
  }, [item.id]);

  return (
    <button
      type="button"
      className="relative me-1.5"
      onClick={() => {
        setShowOptionsPopup({
          [item.id]: !showOptionsPopup[item.id],
        });
      }}
    >
      <AppIcon className="fa-regular fa-ellipsis-vertical h-6 w-6" iClass="LargeFont" />

      {showOptionsPopup[item.id] && (
        <div ref={popupRef}>
          <AppBody
            pClass="Caption1Regular"
            type="div"
            className={`absolute end-0 z-10 grid min-w-36 rounded bg-white p-2 text-start shadow ${
              isLastEl ? 'bottom-8' : ''
            }`}
          >
            {!isBanned && (
              <span className="block py-1 ps-2 hover:bg-gray-1" onClick={handleEditUser}>
                {t('Edit', { ns: 'commonV2' })}
              </span>
            )}
            <span className="block py-1 ps-2 hover:bg-gray-1" onClick={navigateToSingleUserDetails}>
              {t('View Details')}
            </span>

            {canChangeStatus && (isSuspended || isDeactivated) && (
              <span
                className="block py-1 ps-2 hover:bg-gray-1"
                onClick={() => {
                  changeUserStatus(usersStatus.Active);
                }}
              >
                {t('Reactivate')}
              </span>
            )}

            {canChangeStatus && (isActive || isDeactivated) && (
              <span
                className="block py-1 ps-2 hover:bg-gray-1"
                onClick={() => {
                  changeUserStatus(usersStatus.Suspended);
                }}
              >
                {t('Suspend')}
              </span>
            )}

            {canChangeStatus && (isActive || isSuspended) && (
              <span
                className="block py-1 ps-2 hover:bg-gray-1"
                onClick={() => {
                  changeUserStatus(usersStatus.Deactivate);
                }}
              >
                {t('Deactivate(v)')}
              </span>
            )}

            {isPending && (
              <span className="block py-1 ps-2 hover:bg-gray-1" onClick={resendActivationEmail}>
                {t('Resend Activation')}
              </span>
            )}
          </AppBody>
        </div>
      )}
    </button>
  );
}

export default SingleUserOptionsPopup;
