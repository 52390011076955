import { getURLParams } from 'javascript-functions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { devLog } from '../../../../helpers/Utils';
import {
  clearCardProgramListAction,
  getWalletCardProgramListAction,
  selectSingleCardProgramAction,
} from '../../../../redux/WalletReducer/WalletActions';
import CardProgramColumn from './CardProgramColumn';
import CardProgramListButtons from './CardProgramListButtons';

function CardProgramList() {
  const dispatch = useDispatch();

  useEffect(() => {
    devLog(`Checking cached card program ID in URL
    virtualCardId: [${getURLParams('selectedCardProgramId')}]
    `);

    const selectedCardProgramId = getURLParams('selectedCardProgramId') || null;
    const cardProgram = { id: selectedCardProgramId };
    if (selectedCardProgramId) dispatch(selectSingleCardProgramAction({ cardProgram }));
  }, []);

  useEffect(() => {
    dispatch(getWalletCardProgramListAction());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearCardProgramListAction());
    };
  }, []);

  return (
    <>
      <div className="flex h-full flex-col justify-between">
        <CardProgramColumn />
      </div>

      <div>
        <CardProgramListButtons />
      </div>
    </>
  );
}

export default CardProgramList;
