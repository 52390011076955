import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import StepperSidebar from './StepperSidebar';

function AppStepper({ steps, sideBarHeader, activeStepIndex, onStepChange, completedSteps }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const [mobileLineWidth, setMobileLineWidth] = useState('100px');

  const stepContainerRef = useRef();

  useEffect(() => {
    const stepWidth = stepContainerRef.current.offsetWidth / (steps.length - 1) - 28;
    setMobileLineWidth(`${stepWidth}px`);
  }, [steps.length, window.innerWidth, stepContainerRef]);

  return (
    <div className="flex h-full flex-col items-center gap-2 lg:flex-row lg:gap-0">
      <div
        ref={stepContainerRef}
        className={`w-full rounded-3xl border border-gray-3 bg-gray-2 py-5 pe-14 ps-4 lg:h-full lg:w-[400px] lg:rounded-lg ${
          isMobile ? '-translate-y-6' : ''
        }`}
      >
        {/* Sidebar */}
        <StepperSidebar
          sideBarHeader={sideBarHeader}
          activeStepIndex={activeStepIndex}
          steps={steps}
          onStepChange={onStepChange}
          completedSteps={completedSteps}
          mobileLineWidth={mobileLineWidth}
        />
      </div>

      {/* Content */}
      <div className="h-[93%] w-full overflow-y-auto rounded-lg border-gray-3 bg-white pt-4 lg:-me-8 lg:border lg:px-12 lg:py-8 ltr:lg:-translate-x-8 rtl:lg:translate-x-8">
        {steps[activeStepIndex]?.content}
      </div>
    </div>
  );
}

export default AppStepper;
