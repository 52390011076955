import {useTranslation} from 'react-i18next';
import UserPicture from '../../../components/UserPicture';
import {smartDateFormat} from '../../../helpers/Utils';
import {AppBody} from "../../../components/html/html";

const FundTableItem = function (props) {
  const { fundItem, onClick, className, last } = props;
  const { t, i18n } = useTranslation(['funds', 'common']);

  return (
    <button
      type="button"
      tabIndex={fundItem.id}
      className={`${className || ''} flex grid w-full grid-cols-7 gap-0 py-5 ${
        last ? '' : 'border-b border-gray-3'
      }`}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <UserPicture
        className="col-span-1 m-auto"
        initials={`${fundItem.requested_by && fundItem.requested_by.first_name[0]}${
          fundItem.requested_by && fundItem.requested_by.last_name[0]
        }`}
      />
      <div className="col-span-4 flex flex-grow flex-col items-start lg:col-span-4 ltr:pl-5 rtl:pr-5">
        <div className="items-top flex flex-row justify-between self-stretch">
          <AppBody type="span" pClass="Body1Bold" className="lg:text-lg ltr:text-left rtl:text-right">
            {fundItem.requested_by && i18n.language === 'ar' && fundItem.requested_by.first_name_ar
              ? fundItem.requested_by.first_name_ar
              : fundItem.requested_by.first_name ?? ''}{' '}
            {fundItem.requested_by && i18n.language === 'ar' && fundItem.requested_by.last_name_ar
              ? fundItem.requested_by.last_name_ar
              : fundItem.requested_by.last_name ?? ''}
          </AppBody>
        </div>
        <div className="flex flex-grow flex-row justify-between self-stretch text-gray-6 ltr:text-left rtl:text-right">
          <div>
            <span className="block">{smartDateFormat(fundItem.created_at)}</span>
            <span className="block"> {fundItem.reason}</span>
          </div>
        </div>
      </div>
      <div className="col-span-2 flex h-full flex-col px-2">
        <span className="pt-1 tabular-nums" style={{ direction: 'ltr' }}>{`${parseFloat(
          fundItem.amount,
        ).toLocaleString()} ${fundItem.currency}`}</span>
      </div>
    </button>
  );
};

export default FundTableItem;
