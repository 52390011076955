import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import deleteAttachmentImg from '../../assets/svg/deleteAttachmentImg.svg';
import { inPages } from '../../helpers/constants';
import { http } from '../../http';
import { hideModalAction } from '../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import { getSingleTransactionDetailsAction } from '../../redux/CardDetailsReducer/CardDetailsActions';
import { getSingleWalletTransactionDetailsAction } from '../../redux/WalletReducer/WalletActions';
import { AppButton, AppHeader, AppBody } from '../html/html';

function DeleteAttachmentConfirm({ item, inPage, transactionDetails }) {
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();
  const { loaders } = useSelector((state) => state);

  const deleteAttachment = useCallback(() => {
    http
      .delete(`transactions/receipts/${item.id}`, {
        loader: 'handleDeleteAttachment',
        params: { transaction_id: transactionDetails.id },
      })
      .then(() => {
        dispatch(
          showToasterAction({
            type: 'success',
            message: t('The attachment was deleted successfully!'),
          }),
        );
        dispatch(hideModalAction());
        if (inPage === inPages.myCards) {
          dispatch(getSingleTransactionDetailsAction(transactionDetails.id));
        }

        if (inPage === inPages.wallet) {
          dispatch(getSingleWalletTransactionDetailsAction(transactionDetails.id));
        }
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, [item, transactionDetails]);

  return (
    <div className="h-80 w-80 rounded-lg bg-white">
      <div className="ps-5">
        <img src={deleteAttachmentImg} alt="delete attachment" />
      </div>
      <div className="px-10">
        <AppHeader h="h6" className="mb-3">
          {t('Are You Sure You Want to Delete File Document?')}
        </AppHeader>
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('When you remove the file, you will lose the file and the type of the file.')}
        </AppBody>
      </div>
      <div className="mt-5 flex items-center justify-center gap-4 font-medium">
        <AppButton
          button="link"
          onClick={() => {
            dispatch(hideModalAction());
          }}
        >
          {t('Discard Changes', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          button="danger"
          className="px-3"
          isLoading={loaders.handleDeleteAttachment}
          loadingLabel={t('Deleting', { ns: 'commonV2' })}
          onClick={() => {
            deleteAttachment();
          }}
        >
          {t('Delete', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default DeleteAttachmentConfirm;
