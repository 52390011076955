import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import TranslatedData from '../../../../../../components/TranslatedData';
import { AppBody } from '../../../../../../components/html/html';

export function FundMethodDetails({ selectedItem, type }) {
  const { t } = useTranslation('commonV2');

  const renderSingleTransferContent = useCallback(({ title, value }) => {
    return (
      <div>
        <AppBody pClass="Caption1Regular" className="text-gray-6">
          {title}
        </AppBody>
        <AppBody pClass="Caption1Regular" className="break-words">
          {value}
        </AppBody>
      </div>
    );
  }, []);
  return (
    <div className="my-2 grid w-full grid-cols-2 gap-3">
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Bank name'),
          value: selectedItem?.funding_method_id.bank_name,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: '',
          value: selectedItem?.funding_method_id.iban,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t(type === 'withdraw' ? 'Beneficiary Name' : 'Account Name'),
          value: selectedItem?.funding_method_id?.account_holder_name,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Account number'),
          value: selectedItem?.funding_method_id?.account_number,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Country'),
          value: (
            <TranslatedData
              en={selectedItem?.funding_method_id?.country_code?.name}
              ar={selectedItem?.funding_method_id?.country_code?.name_ar}
            />
          ),
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Currency'),
          value: selectedItem?.funding_method_id.currency,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('SWIFT Code'),
          value: selectedItem?.funding_method_id.swift,
        })}
      </div>
    </div>
  );
}
