/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppCloseButton from '../../../../../components/AppCloseButton';
import AppSpinner from '../../../../../components/AppSpinner';
import { cardDetailsSectionIds } from '../../../../../helpers/constants';
import useSimplifi from '../../../../../hooks/useSimplifi';
import { hideCardInfoPopupAction } from '../../../../../redux/CardDetailsReducer/CardDetailsActions';
import './CardInfoPopup.css';

function CardInfoPopup({ card }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { cardInfoDetails } = useSelector((state) => state.cardDetails);
  const dispatch = useDispatch();

  const { iframe, isSimplifiLoading } = useSimplifi({
    cardId: cardInfoDetails?.id,
    userId: cardInfoDetails?.cardholder?.id,
    simplifiAction: 'viewCardDetail',
  });

  return (
    cardInfoDetails?.id === card.id && (
      <div
        className="absolute start-0 top-20 z-30 h-auto overflow-y-auto rounded-2xl border border-gray-3 bg-white p-5 pt-0 shadow lg:top-44"
        style={{
          width:
            document.getElementById(cardDetailsSectionIds.RIGHT_SIDE_CONTAINER)?.clientWidth -
            (isMobile ? 5 : 96),

          maxHeight:
            document.getElementById(cardDetailsSectionIds.RIGHT_SIDE_CONTAINER)?.clientHeight -
            document.getElementById(cardDetailsSectionIds.CARD_DETAILS_HEADER)?.clientHeight -
            20,
        }}
      >
        <div className="sticky top-0 flex justify-end bg-white py-4">
          <AppCloseButton
            className="ms-auto"
            onClick={() => {
              dispatch(hideCardInfoPopupAction());
            }}
          />
        </div>

        {isSimplifiLoading && (
          <div className="h-72">
            <AppSpinner />
          </div>
        )}

        {iframe}
      </div>
    )
  );
}

export default CardInfoPopup;
