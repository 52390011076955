import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import ActionButton from '../../../../components/Button/ActionButton';
import LinkButton from '../../../../components/Button/LinkButton';
import LoadingError from '../../../../components/LoadingError';
import { Headline } from '../../../../components/Titles';
import DropzoneSingleFileForm from '../../../../components/Upload/DropzoneSingleFileForm';
import { apiGetRequest, apiPostFormDataRequest } from '../../../../helpers/APIHelper';
import { setAction } from '../../../../helpers/ActionsHelper';
import useNotifications from '../../../../helpers/useNotifications';
import { PATCH_TRANSACTION } from '../../../../redux/TransactionsReducer';
import {AppBody} from "../../../../components/html/html";

function UploadAttachmentPopup({ setUploadDocument, transactionId, setTransaction }) {
  const { t, i18n } = useTranslation(['transactions', 'common']);
  const [cookies] = useCookies(['token']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [docTypeId, setDocTypeId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [docTypes, setDocTypes] = useState(null);
  const [, addNotification] = useNotifications();
  const dispatch = useDispatch();
  const { handleSubmit } = useForm({ mode: 'onBlur' });

  const fetchDocTypes = () => {
    setIsLoading(true);
    apiGetRequest('transactions/receipts-doc-types', cookies.token)
      .then((response) => {
        setDocTypeId(response.length ? response[response.length - 1].id : null);
        setDocTypes(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = () => {
    if (!files || (files && files.length === 0)) {
      setFileError(t('attachment_required'));
      return false;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('doc_type_id', docTypeId);
    formData.append('transaction_id', transactionId);

    files.forEach((file) => {
      formData.append('receipts[]', file);
    });

    apiPostFormDataRequest('transactions/receipts', formData, cookies.token)
      .then((res) => {
        dispatch(setAction(PATCH_TRANSACTION, res));
        setTransaction(res);
        addNotification(t('attachment_added'));
        setUploadDocument(false);
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });

    return true;
  };

  useEffect(() => {
    fetchDocTypes();
  }, [i18n.language]);

  return (
    <div className="">
      <div className="absolute inset-0 z-10 h-full w-full bg-black opacity-50" />
      <form
        className="relative flex flex-grow flex-col items-start justify-start self-stretch"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="confirm_box lg:max-w-auto fixed inset-x-0 z-50 mb-4 w-full p-3 px-10 py-5 lg:mx-auto lg:w-1/2">
          <div className="absolute top-5 ltr:right-5 rtl:left-5">
            <LinkButton isClose onClick={() => setUploadDocument(false)} />
          </div>
          <div className="flex items-center">
            <div className="w-full">
              <Headline>{t('upload_attachment')}</Headline>
            </div>
          </div>
          <div className="flex w-full flex-col  justify-start px-10 py-1">
            <DropzoneSingleFileForm
              setFile={setFiles}
              file={files}
              fileError={fileError}
              setFileError={setFileError}
              fileHint={t('rejectReason', { ns: 'common' })}
              rejectReason={t('rejectReason_simple', { ns: 'common' })}
              uploadInput={t('documents_or_photos', { ns: 'common' })}
              acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
            />
          </div>
          <div className="my-2 flex items-center">
            <div className="w-full">
              <Headline>{t('select_type_file')}</Headline>
            </div>
          </div>
          <div className="flex items-center text-center">
            {!docTypes && isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchDocTypes} />
          </div>
          <div className="flex w-full flex-col justify-start">
            {docTypes && (
              <div className="my-5 grid w-full grid-cols-1 gap-5 lg:grid-cols-3">
                {docTypes.map((doc, index) => (
                  <div className="col-span-1 rounded-xl border border-gray-3 bg-gray-5 bg-opacity-25 px-2 py-2 bg-blend-multiply ltr:text-left rtl:text-right">
                    <div className="radio-item" style={{ margin: '0' }}>
                      <input
                        type="radio"
                        className="scale-125 transform"
                        name="doc_type_id"
                        id={`doc_type_id_${index}`}
                        defaultChecked
                        onClick={(evt) => {
                          if (evt.target.checked) {
                            setDocTypeId(doc.id);
                          }
                        }}
                      />
                      <AppBody type="label" pClass="Caption1Bold" htmlFor={`doc_type_id_${index}`}>
                        {t(doc.title)}
                      </AppBody>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mt-2 flex ltr:justify-end rtl:justify-start">
            <LinkButton
              text={t('cancel', { ns: 'common' })}
              onClick={() => setUploadDocument(false)}
              className="mt-4 block w-full px-4 py-3 text-center text-sm lg:order-1 lg:mt-0 lg:inline-block lg:w-auto lg:py-2"
            />
            <ActionButton
              primary
              isLoading={isSubmitting}
              type="submit"
              className="inline-block w-auto px-6 py-1 text-center"
              text={t('upload', { ns: 'common' })}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default UploadAttachmentPopup;
