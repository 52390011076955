/* eslint-disable no-use-before-define */
import { getURLParams } from 'javascript-functions';
import { devLog, getServerErrMessage, toIntAndDecimalNumber } from '../../helpers/Utils';
import {
  receiptPopupIconIds,
  requestsSharedParams,
  transactionsSharedParams,
} from '../../helpers/constants';
import { http } from '../../http';
import { showToasterAction } from '../AppToastersReducer/AppToastersActions';
import store from '../store';

// ====== Card Programs List Actions ====== //
export const getWalletCardProgramListAction = () => (dispatch) => {
  devLog(`Getting Wallet card Programs...`);

  http
    .get(`cardmanager/cardproducts`, {
      loader: `getWalletCardProgramListAction`,
      params: {
        'multiStatus[0]': 'ACTIVE',
        'multiStatus[1]': 'SUSPENDED',
        'multiStatus[2]': 'DEACTIVATED',
      },
    })
    .then((res) => {
      dispatch({
        type: 'GET_WALLET_CARD_PROGRAMS_LIST',
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: getServerErrMessage(err),
        }),
      );
    });
};

export const clearCardProgramListAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_CARD_PROGRAM_LIST',
  });
};

export const getSingleColumnCardProgramBalanceAction = (card) => (dispatch) => {
  http
    .get(`cardmanager/cardproducts/balance/${card.id}`, {
      loader: `getSingleColumnCardProgramBalanceAction[${card.id}]`,
    })
    .then((res) => {
      dispatch({
        type: 'UPDATE_SINGLE_COLUMN_CARD_PROGRAM_BALANCE',
        payload: {
          card,
          balance: {
            amount: toIntAndDecimalNumber(res.data?.amount || 0).fullNumber,
            currency: res.data?.currency,
          },
        },
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: getServerErrMessage(err),
        }),
      );
    });
};

export const clearSelectedCardProgramDataAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SELECTED_CARD_PROGRAM_DATA',
  });
};

export const selectSingleCardProgramAction =
  ({ cardProgram }) =>
  (dispatch) => {
    dispatch({
      type: 'SELECT_SINGLE_CARD_PROGRAM',
      payload: cardProgram,
    });
  };

// ====== Card Programs Details Actions ====== //
export const getSelectedCardProgramDetailsAction =
  ({ cardProgramId, fromActions = false }) =>
  (dispatch) => {
    devLog(`Getting Card Program Details Data...`);

    http
      .get(`cardmanager/cardproducts/${cardProgramId}`, {
        loader: fromActions ? 'transferFromData' : 'getSelectedCardProgramDetailsAction',
      })
      .then((res) => {
        dispatch({
          type: 'ADD_SELECTED_CARD_PROGRAM_DETAILS',
          payload: res.data,
        });
      });
  };

export const clearSelectedCardProgramDetailsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SELECTED_CARD_PROGRAM_DETAILS',
  });
};

export const getCardProgramDetailsBalanceAction =
  ({ cardProgramId, fromActions = false, getToBalance = false, getFromBalance = false }) =>
  (dispatch) => {
    devLog('Getting Card Program Details Balance...');
    http
      .get(`cardmanager/cardproducts/balance/${cardProgramId}`, {
        loader: `getCardProgramDetailsBalanceAction`,
      })
      .then((res) => {
        const payload = {
          amount: res.data?.amount || 0,
          currency: res.data?.currency,
        };
        if (fromActions && getFromBalance) {
          dispatch({
            type: 'ADD_TRANSFER_FROM_CARD_PROGRAM_BALANCE',
            payload,
          });
        } else if (fromActions && getToBalance) {
          dispatch({
            type: 'ADD_TRANSFER_TO_CARD_PROGRAM_BALANCE',
            payload,
          });
        } else {
          dispatch({
            type: 'ADD_CARD_PROGRAM_DETAILS_BALANCE',
            payload,
          });
        }
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

export const clearCardProgramDetailsBalanceAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_CARD_PROGRAM_BALANCE',
  });
};

// ====== Wallet Tabs Actions ====== //
export const setActiveWalletMainTabAction = (mainTab) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_WALLET_MAIN_TAB',
    payload: mainTab,
  });
};

export const setActiveWalletSubTabAction = (subTab) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_WALLET_SUB_TAB',
    payload: subTab,
  });
};

// ====== Wallet Requests Actions ====== //
export const getPendingRequestsCountAction = () => (dispatch) => {
  http
    .get(`funds/filter/count`, {
      params: {
        card_product_id: getURLParams('selectedCardProgramId'),
        status_id: 1, // pending status
      },
    })
    .then((res) => {
      dispatch({
        type: 'SET_PENDING_REQUESTS_COUNT',
        payload: res?.data?.count || 0,
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: err.data?.error,
        }),
      );
    });
};

export const clearPendingRequestsCountAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_PENDING_REQUESTS_COUNT',
  });
};

export const getWalletRequestsAction =
  ({ params }) =>
  (dispatch) => {
    http
      .get(`funds`, {
        loader: 'getWalletRequestsAction',
        params: {
          ...requestsSharedParams,
          ...params,
        },
      })
      .then((res) => {
        dispatch({
          type: 'ADD_WALLET_REQUESTS',
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err.data?.error,
          }),
        );
      });
  };

export const clearWalletRequestsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_REQUESTS',
  });
};

export const updateWalletRequestsAction = (request) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WALLET_REQUESTS',
    payload: request,
  });
};

// ====== Wallet Request Details Actions ====== //

export const getSingleWalletRequestDetailsAction = (fundId) => (dispatch) => {
  devLog('Getting wallet single REQUEST details...');
  http
    .get(`funds/${fundId}`, {
      loader: 'getWalletSingleRequestDetailsAction',
    })
    .then((res) => {
      dispatch({
        type: 'ADD_SINGLE_WALLET_REQUEST_DETAILS',
        payload: res.data,
      });
    });
};

export const clearSingleWalletRequestDetailsAction = () => (dispatch) => {
  devLog('Clearing wallet single REQUEST details...');

  dispatch(setSelectedRejectionReasonAction(null));
  dispatch(setOtherRejectionReasonAction(null));
  dispatch({
    type: 'CLEAR_SINGLE_WALLET_REQUEST_DETAILS',
  });
};

export const setWillingToRejectRequestAction = (flag) => (dispatch) => {
  dispatch({
    type: 'SET_WILLING_TO_REJECT_REQUEST',
    payload: flag,
  });
};

export const setSelectedRejectionReasonAction = (reason) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_REJECTION_REASON',
    payload: reason,
  });
};

export const setOtherRejectionReasonAction = (reason) => (dispatch) => {
  dispatch({
    type: 'SET_OTHER_REJECTION_REASON',
    payload: reason,
  });
};

export const handleApproveRejectRequestAction =
  ({ approve = false, requestId }) =>
  (dispatch) => {
    const params = {
      status_id: approve ? 2 : 3,
    };

    if (!approve) {
      const reason = store.getState().wallet?.selectedRejectionReason.value;
      const otherReason = store.getState().wallet?.otherRejectionReason;
      params.rejection_reason = reason === 'Other' ? otherReason : reason;
    }

    http
      .patch(`funds/${requestId}/status`, null, {
        params,
        loader: approve ? 'handleApprove' : 'handleReject',
      })
      .then((res) => {
        dispatch(updateWalletRequestsAction(res.data));
        dispatch(showWalletApproveRejectRequestAction({ isApproved: approve }));
        dispatch(getPendingRequestsCountAction());
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

export const showWalletApproveRejectRequestAction =
  ({ isApproved }) =>
  (dispatch) => {
    dispatch({
      type: 'SHOW_WALLET_APPROVE_REJECT_REQUEST',
      payload: { isApproved },
    });
  };

export const hideWalletApproveRejectRequestAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_WALLET_APPROVE_REJECT_REQUEST',
  });
};

export const handleReloadRequestAction = (request) => (dispatch) => {
  http
    .patch(`funds/${request.id}/retry`, null, {
      loader: `handleReloadRequestAction[${request.id}]`,
    })
    .then((res) => {
      dispatch(updateWalletRequestsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: getServerErrMessage(err),
        }),
      );
    });
};

export const getWalletRequestCurrenciesAction = () => (dispatch) => {
  http
    .get(`funds/currencies`, {
      loader: 'getWalletRequestCurrenciesAction',
    })
    .then((res) => {
      dispatch({
        type: 'ADD_WALLET_REQUEST_CURRENCIES',
        payload: res.data,
      });
    });
};

export const clearWalletRequestCurrenciesAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_REQUEST_CURRENCIES',
  });
};

export const getWalletRequestDepartmentsAction = () => (dispatch) => {
  http
    .get(`departments`, {
      loader: 'getWalletRequestDepartmentsAction',
    })
    .then((res) => {
      dispatch({
        type: 'ADD_WALLET_REQUEST_DEPARTMENTS',
        payload: res.data,
      });
    });
};
export const clearWalletRequestDepartmentsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_REQUEST_DEPARTMENTS',
  });
};
export const getWalletUsersAction = () => (dispatch) => {
  http
    .get(`employees?no_paging=1`, {
      loader: 'getWalletUsersAction',
    })
    .then((res) => {
      dispatch({
        type: 'ADD_WALLET_USERS',
        payload: res.data,
      });
    });
};
export const clearWalletUsersAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_USERS',
  });
};
export const addWalletRequestActionAnimationAction = (data) => (dispatch) => {
  dispatch({
    type: 'ADD_WALLET_REQUEST_ACTION_ANIMATION',
    payload: data,
  });
};

export const clearWalletRequestActionAnimationAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_REQUEST_ACTION_ANIMATION',
  });
};

// ====== Wallet Transactions Actions ====== //
export const getWalletTransactionsAction =
  ({ params }) =>
  (dispatch) => {
    http
      .get(`transactions`, {
        loader: 'getWalletTransactionsAction',
        params: {
          ...transactionsSharedParams,
          ...params,
        },
      })
      .then((res) => {
        dispatch({
          type: 'ADD_WALLET_TRANSACTIONS',
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err.data?.error,
          }),
        );
      });
  };

export const clearWalletTransactionsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_TRANSACTIONS',
  });
};

export const updateWalletTransactionsAction = (transaction) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WALLET_TRANSACTIONS',
    payload: transaction,
  });
};

// ====== Wallet Transactions Details Actions ====== //
export const getSingleWalletTransactionDetailsAction = (transactionId) => (dispatch) => {
  devLog('Getting single Wallet TRANSACTION details...');
  http
    .get(`transactions/${transactionId}`, {
      loader: 'getSingleWalletTransactionDetailsAction',
    })
    .then((res) => {
      const splitCount = res.data?.splits?.length || 0;

      const attachmentsCount =
        res.data?.receipts?.filter((ele) => ele.doc_type !== 'Receipt').length || 0;

      dispatch({
        type: 'ADD_SINGLE_WALLET_TRANSACTION_DETAILS',
        payload: res.data,
      });

      if (splitCount) {
        devLog(
          `single Wallet TRANSACTION has [${splitCount} Splits], Showing [${receiptPopupIconIds.SPLIT_RECEIPT}] Section `,
        );
        dispatch(showWalletReceiptSectionAction(receiptPopupIconIds.SPLIT_RECEIPT));
      }
      if (attachmentsCount) {
        devLog(
          `single TRANSACTION has [${attachmentsCount} Attachments], Showing [${receiptPopupIconIds.UPLOAD_ATTACHMENT}] Section `,
        );
        dispatch(showWalletReceiptSectionAction(receiptPopupIconIds.UPLOAD_ATTACHMENT));
      }
    });
};

export const clearSingleWalletTransactionDetailsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SINGLE_WALLET_TRANSACTION_DETAILS',
  });
};

// ====== Wallet Funds Activity Actions ====== //
export const getWalletFundsActivityAction =
  ({ params }) =>
  (dispatch) => {
    http
      .get(`cardmanager/funds`, {
        loader: 'getWalletFundsActivityAction',
        params: {
          ...params,
        },
      })
      .then((res) => {
        dispatch({
          type: 'ADD_WALLET_FUND_ACTIVITIES',
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err.data?.error,
          }),
        );
      });
  };

export const clearWalletFundsActivityAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_FUND_ACTIVITIES',
  });
};

export const updateWalletFundsActivityAction = (transaction) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WALLET_FUND_ACTIVITIES',
    payload: transaction,
  });
};

// ====== Wallet FundsActivity Details Actions ====== //
export const getSingleWalletFundsActivityDetailsAction = (fundId) => (dispatch) => {
  devLog('Getting single Wallet FUNDS_ACTIVITY details...');
  http
    .get(`cardmanager/funds/${fundId}`, {
      loader: 'getSingleWalletFundsActivityDetailsAction',
    })
    .then((res) => {
      dispatch({
        type: 'ADD_SINGLE_WALLET_FUND_ACTIVITY_DETAILS',
        payload: res.data,
      });
    });
};

export const clearSingleWalletFundsActivityDetailsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SINGLE_WALLET_FUND_ACTIVITY_DETAILS',
  });
};

// ====== Wallet Cards Activity Actions ====== //
export const getWalletCardsActivityAction =
  ({ params }) =>
  (dispatch) => {
    http
      .get(`cardmanager/activities`, {
        loader: 'getWalletCardsActivityAction',
        params: {
          ...params,
        },
      })
      .then((res) => {
        dispatch({
          type: 'ADD_WALLET_CARD_ACTIVITIES',
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err.data?.error,
          }),
        );
      });
  };

export const clearWalletCardsActivityAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_CARD_ACTIVITIES',
  });
};

export const updateWalletCardsActivityAction = (transaction) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WALLET_CARD_ACTIVITIES',
    payload: transaction,
  });
};

// ====== Wallet FundsActivity Details Actions ====== //
export const getSingleWalletCardsActivityDetailsAction = (idempKey) => (dispatch) => {
  devLog('Getting single Wallet CARDS_ACTIVITY details...');
  http
    .get(`cardmanager/activities/${idempKey}`, {
      loader: 'getSingleWalletCardsActivityDetailsAction',
    })
    .then((res) => {
      dispatch({
        type: 'ADD_SINGLE_WALLET_CARD_ACTIVITY_DETAILS',
        payload: res.data,
      });
    });
};

export const clearSingleWalletCardsActivityDetailsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SINGLE_WALLET_CARD_ACTIVITY_DETAILS',
  });
};

// ====== Fund Status Actions ====== //
export const getWalletFundStatusAction = () => (dispatch) => {
  http
    .get(`cardmanager/status?type=fund`, {
      loader: 'getFundStatusAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_WALLET_FUND_ACTIVITY_STATUS',
        payload: response.data,
      });
    });
};

export const clearWalletFundStatusAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_FUND_ACTIVITY_STATUS',
  });
};

// ====== Departments Actions ====== //
export const getWalletDepartmentsAction = () => (dispatch) => {
  http
    .get(`departments`, {
      loader: 'getDepartmentsAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_WALLET_DEPARTMENTS',
        payload: response.data,
      });
    });
};

export const clearWalletDepartmentsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_DEPARTMENTS',
  });
};

// ====== Tags Actions ====== //
export const getWalletTagsAction = () => (dispatch) => {
  http
    .get(`tags`, {
      loader: 'getTagsAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_WALLET_TAGS',
        payload: response.data,
      });
    });
};

export const clearWalletTagsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_TAGS',
  });
};

// ====== Doc Types Actions ====== //
export const getWalletDocTypesAction = () => (dispatch) => {
  http
    .get(`transactions/receipts-doc-types`, {
      loader: 'getDocTypesAction',
    })
    .then((res) => {
      dispatch({
        type: 'GET_WALLET_DOC_TYPES',
        payload: res.data,
      });
    });
};

export const clearWalletDocTypesAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_DOC_TYPES',
  });
};

export const getWalletTransactionCurrenciesAction = () => (dispatch) => {
  http
    .get(`transactions/transaction-currencies`, {
      loader: 'getWalletTransactionCurrenciesAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_WALLET_TRANSACTION_CURRENCIES',
        payload: response.data,
      });
    });
};

export const clearWalletTransactionCurrenciesAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_TRANSACTION_CURRENCIES',
  });
};

// ====== Wallet Receipt Popup Actions ====== //
export const showWalletReceiptSectionAction = (sectionName) => (dispatch) => {
  devLog(`Show Wallet Receipt [${sectionName}] section`);
  dispatch({
    type: 'SHOW_WALLET_SECTION',
    payload: sectionName,
  });
};

export const hideWalletReceiptSectionAction = (sectionName) => (dispatch) => {
  devLog(`Hide Wallet Receipt [${sectionName}] section`);
  dispatch({
    type: 'HIDE_WALLET_SECTION',
    payload: sectionName,
  });
};

export const hideAllWalletReceiptSectionAction = () => (dispatch) => {
  devLog(`Hide [ALL] Wallet Receipt sections`);
  dispatch({
    type: 'HIDE_ALL_WALLET_SECTIONS',
  });
};

// ====== Wallet Popups Actions ====== //
export const hideAllWalletPopups = () => (dispatch) => {
  dispatch(hideWalletFilterPopupAction());
  dispatch(hideWalletSearchPopupAction());
  dispatch(hideWalletReceiptPopupAction());
  dispatch(hideWalletApproveRejectRequestAction());
  dispatch(hideCardProgramActionsPopup());
};

export const showWalletFilterPopupAction = () => (dispatch) => {
  dispatch(hideAllWalletPopups());

  dispatch({
    type: 'SHOW_WALLET_FILTER_POPUP',
  });
};

export const hideWalletFilterPopupAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_WALLET_FILTER_POPUP',
  });
};

export const showWalletSearchPopupAction = () => (dispatch) => {
  dispatch(hideAllWalletPopups());

  dispatch({
    type: 'SHOW_WALLET_SEARCH_POPUP',
  });
};

export const hideWalletSearchPopupAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_WALLET_SEARCH_POPUP',
  });
};

export const showWalletReceiptPopupAction = () => (dispatch) => {
  dispatch(hideAllWalletPopups());
  dispatch({
    type: 'SHOW_RECEIPT_POPUP',
  });
};

export const hideWalletReceiptPopupAction = () => (dispatch) => {
  dispatch(clearSingleWalletRequestDetailsAction());
  dispatch(clearSingleWalletTransactionDetailsAction());
  dispatch(hideWalletRequestFormPopupAction());
  dispatch(setWillingToRejectRequestAction(false));
  dispatch(clearWalletRequestActionAnimationAction());
  dispatch({
    type: 'HIDE_RECEIPT_POPUP',
  });
};

export const showWalletRequestFormPopupAction = () => (dispatch) => {
  dispatch(setSelectedRejectionReasonAction(null));
  dispatch(setOtherRejectionReasonAction(null));
  dispatch({
    type: 'SHOW_WALLET_REQUEST_FORM_POPUP',
  });
};

export const hideWalletRequestFormPopupAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_WALLET_REQUEST_FORM_POPUP',
  });
};

export const showWalletFundActivityFormPopupAction = () => (dispatch) => {
  dispatch({
    type: 'SHOW_WALLET_FUND_ACTIVITY_FORM_POPUP',
  });
};

export const hideWalletFundActivityFormPopupAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_WALLET_FUND_ACTIVITY_FORM_POPUP',
  });
};

export const showWalletCardActivityFormPopupAction = () => (dispatch) => {
  dispatch({
    type: 'SHOW_WALLET_CARD_ACTIVITY_FORM_POPUP',
  });
};

export const hideWalletCardActivityFormPopupAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_WALLET_CARD_ACTIVITY_FORM_POPUP',
  });
};

export const setActiveWalletStepIndexAction = (stepIndex) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_WALLET_STEP_INDEX',
    payload: stepIndex,
  });
};

// ====== Wallet Actions (Actions) ====== //

export const clearWalletActionsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_WALLET_ACTIONS',
  });
};

export const getTargetIBANIDAction = () => (dispatch) => {
  http
    .get(`users/profile`, {
      loader: 'getTargetIBANIDAction',
    })
    .then((res) => {
      dispatch({
        type: 'ADD_TARGET_IBAN_ID',
        payload: res.data.account.address.country.code,
      });
    });
};

export const addActionAmountAction = (amount) => (dispatch) => {
  dispatch({
    type: 'ADD_TRANSFER_AMOUNT',
    payload: amount,
  });
};

export const getWhitlistedIBANIDAction = () => (dispatch) => {
  http
    .get(`funding_method`, {
      loader: 'getWhitlistedIBANIDAction',
      params: {
        paginate: 100,
      },
    })
    .then((res) => {
      dispatch({
        type: 'ADD_WHITELISTED_IBANS',
        payload: res,
      });
    });
};

export const addTransferFromDataAction = (iban) => (dispatch) => {
  dispatch({
    type: 'ADD_TRANSFER_FROM_DATA',
    payload: iban,
  });
};

export const getSupportedIBANSAction = (targetIBANID) => (dispatch) => {
  http
    .get(`cardmanager/supported-countries`, {
      loader: 'getSupportedIBANSAction',
      params: {
        code: targetIBANID,
      },
    })
    .then((res) => {
      const IBANS = [];
      res.data.forEach((ele) => {
        ele.ibans.forEach((el) => {
          IBANS.push(el);
        });
      });

      dispatch({
        type: 'ADD_SUPPORTED_IBANS',
        payload: IBANS,
      });
    });
};

export const addTransferToDataAction = (data) => (dispatch) => {
  dispatch({
    type: 'ADD_TRANSFER_TO_DATA',
    payload: data,
  });
};

export const setIsFinishAction = (flag) => (dispatch) => {
  dispatch({
    type: 'SET_IS_FINISH',
    payload: flag,
  });
};

// ====== Card ActionsPopup Actions ====== //
export const setCurrentCardProgramActionsPopupContent = (currentContent) => (dispatch) => {
  dispatch(hideAllWalletPopups());
  dispatch({
    type: 'SET_CURRENT_CARD_PROGRAM_ACTIONS_POPUP_CONTENT',
    payload: currentContent,
  });
};

export const hideCardProgramActionsPopup = () => (dispatch) => {
  dispatch({
    type: 'HIDE_CARD_PROGRAM_ACTIONS_POPUP',
  });
};

export const saveIBANDataAction = (data) => (dispatch) => {
  dispatch({
    type: 'SAVE_IBAN_DATA',
    payload: data,
  });
};

export const saveFundMethodFormDataAction = (formData) => (dispatch) => {
  dispatch({
    type: 'SAVE_FUND_METHOD_FORM_DATA',
    payload: formData,
  });
};

export const clearFundMethodAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_ADD_FUND_METHOD',
  });
};

export const setIsFinishFundMethodAction = (flag) => (dispatch) => {
  dispatch({
    type: 'SET_IS_FINISH_FUND_METHOD',
    payload: flag,
  });
};
