import { useFormik } from 'formik';
import { getURLParams } from 'javascript-functions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AppAlert from '../../../../../components/AppAlert';
import AppInput from '../../../../../components/AppFormInputs/AppInput';
import { AppBody, AppButton, AppHeader } from '../../../../../components/html/html';
import {
  addActionAmountAction,
  getTargetIBANIDAction,
} from '../../../../../redux/WalletReducer/WalletActions';

function DetermineAmountContent({ onNext, handleCancel }) {
  const { t } = useTranslation('walletV2');
  const { actions } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { amount: actions.amount || '' },
    onSubmit: (values) => {
      onNext({ stepIndex: 0 });
      dispatch(addActionAmountAction(values.amount));
    },
    validationSchema: yup.object({
      amount: yup
        .number()
        .min(1, t('Amount is invalid must be more than 0', { ns: 'commonV2' }))
        .required(t('This field is required!', { ns: 'commonV2' })),
    }),
    enableReinitialize: true,
  });

  useEffect(() => {
    dispatch(getTargetIBANIDAction());
  }, []);

  return (
    <div>
      <div className="lg:w-2/3">
        <AppHeader h="h4" className="mb-2">
          {t('Determine Your Amount')}
        </AppHeader>
        <AppBody pClass="Body1Bold" className="mb-8">
          {t('How much do you want to transfer?')}
        </AppBody>

        <div className="mb-8">
          <AppAlert
            type="info"
            text={
              <>
                <AppBody pClass="Body2Medium">
                  {t('You can add details about the transfer amount here.')}
                </AppBody>
                <AppBody pClass="Body2Medium">
                  {t(
                    'Make sure to transfer the actual amount from your whitelisted IBAN first To find your whitelisted IBANs, check your company profile.',
                  )}
                </AppBody>
              </>
            }
          />
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="mb-8 flex flex-col gap-5 lg:w-1/2">
          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Amount')}
            </AppBody>
            <AppInput
              className="rounded-xl"
              size="md"
              type="number"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.amount && formik.touched.amount && (
              <small className="text-danger">{t(formik.errors.amount, { ns: 'commonV2' })}</small>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Currency', { ns: 'commonV2' })}
            </AppBody>
            <AppInput
              disabled
              value={getURLParams('selectedCardProgramCurrency')}
              className="rounded-xl"
              size="md"
              name="currency"
            />
          </label>
        </div>

        <div className="mb-32 lg:w-2/3">
          <AppAlert
            type="info"
            text={
              <AppBody pClass="Body2Medium">
                {t(
                  'The transfer must be made in the same currency as the card program. If the currency does not match, the transaction will be declined.',
                )}
              </AppBody>
            }
          />
        </div>

        <div className="flex justify-end gap-8">
          <AppButton button="link" onClick={handleCancel} size="md">
            {t('Cancel', { ns: 'commonV2' })}
          </AppButton>
          <AppButton
            size="md"
            disabled={!formik.isValid || !formik.values.amount}
            className="min-w-[150px]"
            type="submit"
            button="black"
          >
            {t('Next', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </form>
    </div>
  );
}

export default DetermineAmountContent;
