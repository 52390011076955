import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { cardStatusKeys, settingsPopupContent } from '../../../../../../../helpers/constants';
import usePermissions from '../../../../../../../hooks/usePermissions';
import {
  setCurrentSettingsPopupContent,
  showCardInfoPopupAction,
} from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function MobileSettingsList() {
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const userCan = usePermissions();

  const selectedCardStatus = useMemo(() => {
    return settingsPopupCardDetails?.status?.key?.toUpperCase();
  }, [settingsPopupCardDetails]);

  const isShowInfoDisabled = useMemo(() => {
    return (
      !userCan('show_card_secret') ||
      auth.user?.id !== settingsPopupCardDetails.user_id ||
      selectedCardStatus !== cardStatusKeys.ACTIVE
    );
  }, [auth, selectedCardStatus]);

  const buttons = useMemo(() => {
    return [
      {
        label: t('Settings'),
        contentName: settingsPopupContent.settingsList,
        disabled: false,
        show: true,
      },
      {
        label: t('Show Info'),
        onClick: () => {
          dispatch(showCardInfoPopupAction(settingsPopupCardDetails));
        },
        disabled: isShowInfoDisabled,
        show: true,
      },
    ];
  }, [isShowInfoDisabled]);

  return (
    <div className="overflow-hidden rounded-lg border border-gray-3">
      {buttons.map((ele) => {
        return (
          ele.show && (
            <button
              key={ele.label}
              type="button"
              className="flex w-full justify-between border-b p-4 text-start text-xs font-normal text-black last:border-0 hover:bg-stone-50 disabled:cursor-not-allowed disabled:opacity-50"
              disabled={ele.disabled}
              onClick={() => {
                if (ele.onClick) {
                  ele.onClick();
                } else {
                  dispatch(setCurrentSettingsPopupContent(ele.contentName));
                }
              }}
            >
              <span>{ele.label}</span>
              <IoIosArrowForward size={14} className="text-gray-5 rtl:rotate-180" />
            </button>
          )
        );
      })}
    </div>
  );
}

export default MobileSettingsList;
