import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../../../../components/Button/ActionButton';
import {SelectInputCustom, TextInput} from '../../../../components/FormInputs/Inputs';
import CompanyStepBar from '../../../../components/Stepbar/CompanyStepBar';
import {HeadlineCenter} from '../../../../components/Titles';
import {apiGetRequest, apiPostFormDataRequest} from '../../../../helpers/APIHelper';
import {
  AddressValidationPattern,
  NameValidationPattern,
  PostalValidationPattern,
} from '../../../../helpers/RegexPatterns';
import useNotifications from '../../../../helpers/useNotifications';
import {AppBody} from "../../../../components/html/html";

function CompanyAddress() {
  const { t, i18n } = useTranslation(['signup', 'common']);
  const { user } = useSelector((state) => state.auth);
  const [cookies] = useCookies(['token']);
  const [isReady, setIsReady] = useState(false);
  const [isReadyCountry, setIsReadyCountry] = useState(false);
  const [shortAddress, setShortAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, addNotification] = useNotifications();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
    control,
  } = useForm({ mode: 'onBlur' });

  const navigate = useNavigate();

  const getCities = (countryId) => {
    apiGetRequest(`countries/${countryId}/cities`, cookies.token)
      .then((response) => {
        setIsReadyCountry(true);
        setCities(response);
      })
      .catch(() => {
        setCities([]);
      });
  };

  const onInputChange = (e) => {
    setIsReadyCountry(false);
    setValue('country_id', e);
    getCities(e);
  };

  const onInputChange1 = (e) => {
    setValue('city_id', e);
  };

  const prepare = () => {
    setIsReadyCountry(false);
    apiGetRequest('countries', cookies.token)
      .then((countriesResponse) => {
        setIsReady(true);
        setIsReadyCountry(true);
        setCountries(countriesResponse);
        const defaultCountry = countries.filter((country) => country.code === 'SAU');
        if (defaultCountry.length > 0) {
          setValue('country_id', defaultCountry.length === 1 && defaultCountry[0].id);
          getCities(defaultCountry[0].id);
        }
      })
      .catch(() => {
        setCountries([]);
      });
  };

  useEffect(() => {
    prepare();
    clearErrors();
  }, [i18n.language]);

  const onSubmit = (data) => {};

  const onSubmitLongAddress = (data) => {
    const form = new FormData();
    setIsSubmitting(true);

    form.append('user_id', user.id);
    form.append('country_id', data.country_id);
    form.append('city_id', data.city_id);
    form.append('state', data.state);
    form.append('postal_code', data.postal_code);
    form.append('address1', data.address1);
    if (data.address2 !== 'undefined') {
      form.append('address2', data.address2);
    }
    form.append('is_account_address', 1);

    apiPostFormDataRequest('shipping_address', form, cookies.token)
      .then((response) => {
        navigate('/signup/verify_company/second_contact');
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setIsSubmitting(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="flex h-full items-center justify-center">
      <div className="mx-auto flex w-full flex-col lg:w-8/12">
        <CompanyStepBar step={2} />
        <HeadlineCenter>{t('company_address.address')}</HeadlineCenter>

        <div>
          {/* <AppBody pClass="Body2bold" type="label" className="text-black flex flex-col flex-grow">{t('company_address.address_type')}</AppBody> */}
          {/* <div className="flex flex-row pt-5 items-start"> */}
          {/*    <label className="flex flex-row items-baseline ltr:pl-10 rtl:pr-10"> */}
          {/*        <input */}
          {/*            className="transform scale-125" */}
          {/*            type="radio" */}
          {/*            name="address_type" */}
          {/*            defaultChecked */}
          {/*            onChange={(evt) => { */}
          {/*                if (evt.target.checked) { */}
          {/*                    setShortAddress(true); */}
          {/*                } */}
          {/*            }} /> */}
          {/*        <AppBody pClass="Body1Bold" type="span" className="ltr:pl-2 rtl:pr-2">{t('company_address.short')}</AppBody> */}
          {/*    </label> */}

          {/*    <label className="flex flex-row items-baseline ltr:pl-10 rtl:pr-10"> */}
          {/*        <input */}
          {/*            type="radio" */}
          {/*            className="ltr:pr-2 rtl:pl-2 transform scale-125" */}
          {/*            name="address_type" */}
          {/*            onChange={(evt) => { */}
          {/*                if (evt.target.checked) { */}
          {/*                    setShortAddress(false); */}
          {/*                } */}
          {/*            }} /> */}
          {/*        <AppBody pClass="Body1Bold" type="span" className="ltr:pl-2 rtl:pr-2">{t('company_address.long')}</AppBody> */}
          {/*    </label> */}
          {/* </div> */}

          {shortAddress && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-10 flex flex-grow flex-col items-start justify-start self-stretch"
            >
              <div className="flex w-full flex-col justify-start">
                <div className="flex flex-grow flex-row self-start self-stretch">
                  <AppBody pClass="Body2bold" type="label"
                    htmlFor="short_address"
                    className="flex flex-grow flex-col"
                  >
                    {t('company_address.short')}
                    <TextInput
                      error={errors.short_address}
                      id="short_address"
                      {...register('short_address', {
                        required: t('company_address.short_code_required'),
                      })}
                    />
                  </AppBody>
                </div>
              </div>
              <div className="flex flex-row justify-end self-stretch pt-10">
                <ActionButton
                  text={t('submit', { ns: 'common' })}
                  type="submit"
                  primary
                  isLoading={isLoading}
                />
              </div>
            </form>
          )}

          {!shortAddress && isReady && (
            <form className="flex flex-col" onSubmit={handleSubmit(onSubmitLongAddress)}>
              <div className="flex flex-col">
                <div className="flex flex-col pt-10">
                  <div className="grid grid-cols-2 gap-5 pb-5">
                    <AppBody pClass="Body2bold" type="label"
                      htmlFor="country_id"
                      className="col-span-2 flex flex-col lg:col-span-1"
                    >
                      {t('company_address.country')}
                      {!countries && <Skeleton />}
                      {countries && (
                        <SelectInputCustom
                          onChange={(e) => onInputChange(e)}
                          error={errors.country_id}
                          name="country_id"
                          id="country_id"
                          control={control}
                          rules={{
                            required: t('company_address.country_required'),
                          }}
                          options={countries.map(({ id: value, name: label, ...rest }) => ({
                            value,
                            label,
                            ...rest,
                          }))}
                        />
                      )}
                    </AppBody>
                    <AppBody pClass="Body2bold" type="label"
                      htmlFor="city_id"
                      className="col-span-2 flex flex-col lg:col-span-1"
                    >
                      {t('company_address.city')}
                      {isReadyCountry && cities && (
                        <SelectInputCustom
                          onChange={(e) => onInputChange1(e)}
                          error={errors.city_id}
                          name="city_id"
                          id="city_id"
                          control={control}
                          rules={{
                            required: t('company_address.city_required'),
                          }}
                          options={cities.map(({ id: value, name: label, ...rest }) => ({
                            value,
                            label,
                            ...rest,
                          }))}
                        />
                      )}
                      {!isReadyCountry && <Skeleton />}
                    </AppBody>
                  </div>

                  <div className="grid grid-cols-2 gap-5 pb-5">
                    <AppBody pClass="Body2bold" type="label"
                      htmlFor="state"
                      className="col-span-2 flex flex-col lg:col-span-1"
                    >
                      {t('company_address.state')}
                      <TextInput
                        error={errors.state}
                        id="state"
                        {...register('state', {
                          required: t('company_address.state_required'),
                          pattern: {
                            value: NameValidationPattern,
                            message: t('company_address.state_pattern'),
                          },
                        })}
                      />
                    </AppBody>
                    <AppBody pClass="Body2bold" type="label"
                      htmlFor="postal_code"
                      className="col-span-2 flex flex-col lg:col-span-1"
                    >
                      {t('company_address.postal_code')}
                      <TextInput
                        error={errors.postal_code}
                        id="postal_code"
                        {...register('postal_code', {
                          required: t('company_address.postal_code_required'),
                          pattern: {
                            value: PostalValidationPattern,
                            message: t('company_address.postal_code_validation'),
                          },
                        })}
                      />
                    </AppBody>
                  </div>
                  <div className="flex flex-col">
                    <AppBody pClass="Body2bold" type="label"
                      htmlFor="address1"
                      className="flex flex-col pb-5"
                    >
                      {t('company_address.address_line_1')}
                      <TextInput
                        error={errors.address1}
                        id="address1"
                        {...register('address1', {
                          required: t('company_address.address_line_1_required'),
                          pattern: {
                            value: AddressValidationPattern,
                            message: t('company_address.address_line_1_pattern'),
                          },
                        })}
                      />
                    </AppBody>
                    <AppBody pClass="Body2bold" type="label"
                      htmlFor="address2"
                      className="flex flex-col"
                    >
                      {t('company_address.address_line_2')}
                      <AppBody pClass="Caption1Regular" type="span" className="contents text-gray-6">
                        {` (${t('optional', { ns: 'common' })})`}
                      </AppBody>
                      <TextInput
                        id="address2"
                        error={errors.address2}
                        {...register('address2', {
                          pattern: {
                            value: AddressValidationPattern,
                            message: t('company_address.address_line_2_pattern'),
                          },
                        })}
                      />
                    </AppBody>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex flex-row justify-end self-stretch pt-5">
                <ActionButton
                  className="flex flex-row"
                  type="submit"
                  primary
                  text={t('submit', { ns: 'common' })}
                  isLoading={isSubmitting}
                />
              </div>
            </form>
          )}

          {!shortAddress && !isReady && <Skeleton />}
        </div>
      </div>
    </div>
  );
}

export default CompanyAddress;
