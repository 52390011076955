import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppNoData from '../../../../components/AppNoData';
import CardProgramDetailsPage from './CardProgramDetailsPage';

function CardProgramDetails() {
  const { singleSelectedCardProgramData } = useSelector((state) => state.wallet);
  const { t } = useTranslation('walletV2');

  return (
    <>
      {singleSelectedCardProgramData && <CardProgramDetailsPage />}
      {!singleSelectedCardProgramData && (
        <AppNoData
          className="h-full"
          message={t("Select a Card Program to Display it's Details")}
        />
      )}
    </>
  );
}

export default CardProgramDetails;
