import { useSelector } from 'react-redux';
import { walletTabs } from '../../../../../../helpers/constants';
import WalletDeclineTabPopupContent from './WalletDeclineTabPopupContent';
import WalletExpensesAndRefundPopupContent from './WalletExpensesAndRefundPopupContent';
import WalletLoadTabPopupContent from './WalletLoadTabPopupContent';

function WalletTransactionPopupContent() {
  const { selectedWalletSubActiveTab } = useSelector((state) => state.wallet);

  return (
    <div className="grid gap-4">
      {/* Expenses and Refund Tabs Content */}
      {(selectedWalletSubActiveTab === walletTabs.EXPENSES ||
        selectedWalletSubActiveTab === walletTabs.REFUNDS) && (
        <WalletExpensesAndRefundPopupContent />
      )}

      {/* Decline Tab Content */}
      {selectedWalletSubActiveTab === walletTabs.DECLINES && <WalletDeclineTabPopupContent />}

      {/* Load Tab Content */}
      {selectedWalletSubActiveTab === walletTabs.LOADS && <WalletLoadTabPopupContent />}
    </div>
  );
}

export default WalletTransactionPopupContent;
