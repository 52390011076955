import { useTranslation } from 'react-i18next';
import Thanks from '../../../../assets/png/thank.png';
import {AppBody, AppHeader} from '../../../../components/html/html';

function RegistrationThankYou({ isContact }) {
  const user = localStorage.getItem('userinfo')
    ? JSON.parse(localStorage.getItem('userinfo'))
    : null;
  const { t, i18n } = useTranslation(['signup']);

  if (!user) {
    return null;
  }
  const userName =
    user && i18n.language === 'ar' && user.first_name_ar !== ''
      ? `${user.first_name_ar}`
      : `${user.first_name}`;

  return (
    <div className="flex h-full items-center justify-center">
      <div className="m-auto flex w-full max-w-sm flex-col text-center lg:w-7/12">
        <img alt="thanks" src={Thanks} className="m-auto max-w-xs" />
        <AppHeader h="h4" className="text-center">
          {`${t('thank.thank_you')} ${userName}`}
        </AppHeader>
        <AppBody pClass="Body2Regular" className="pt-5">
          {isContact && t('thank.contact_msg')}
          {!isContact && t('thank.account_msg')}
        </AppBody>
      </div>
    </div>
  );
}

export default RegistrationThankYou;
