import React, { useEffect, useState } from 'react';

function ProgressBar({ width, percent }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(percent * width);
  }, []);

  return (
    <div>
      <div className="progress-div" style={{ width }}>
        <div style={{ width: `${value}px` }} className="progress" />
      </div>
    </div>
  );
}

export default ProgressBar;
