import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/expire-finish.json';

function SuccessLottie() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <span className="inline-block">
      <Lottie options={defaultOptions} height={62} width={62} />
    </span>
  );
}

export default SuccessLottie;
