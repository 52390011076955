import Compressor from 'compressorjs';
import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeleteIcon} from '../../assets/svg/closeIconRed.svg';
import AttachmentPreviewPath from '../AttachmentPreviewPath';
import UploadLottie from '../Lottie/UploadLottie';
import {AppBody} from '../html/html';

function DropzoneSingleFileForm({
  setFile,
  file,
  fileError,
  setFileError,
  rejectReason,
  fileHint,
  uploadInput,
  label,
  optionalLabel,
  acceptArray,
  labelHint,
  oldValue,
  name,
}) {
  const { t } = useTranslation(['common']);
  const [isStopped, setIsStopped] = useState(true);
  let newAcceptedFiles = [];

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const { getRootProps, getInputProps, isDragActive, isFileDialogActive } = useDropzone({
    accept: acceptArray,
    multiple: false,
    // maxSize: 2048000,
    onDrop: async (acceptedFiles, fileRejections, event) => {
      setFile([]);
      setIsStopped(false);

      setFileError(null);
      newAcceptedFiles = [];
      acceptedFiles.forEach((fileR, i) => {
        // don't try to process non-images
        if (fileR.type.includes('image/') && fileR.size > 2048000) {
          // eslint-disable-next-line no-new
          new Compressor(fileR, {
            quality: 0.6,
            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
              if (result.size <= 2048000) {
                const fileFormate = new File([result], result.name, { type: result.type });
                fileFormate.path = fileR.path;
                fileFormate.preview = URL.createObjectURL(result);
                newAcceptedFiles.push(fileFormate);
              } else {
                setIsStopped(true);
                setFileError(rejectReason);
              }
            },
            error(err) {
              setIsStopped(true);
              setFileError(rejectReason);
            },
          });
        } else if (fileR.size > 2048000) {
          setIsStopped(true);
          setFileError(rejectReason);
        } else {
          newAcceptedFiles.push(fileR);
        }
      });

      await sleep(3000).then(() => {
        setFile(newAcceptedFiles.map((f) => Object.assign(f, { preview: URL.createObjectURL(f) })));
      });
      setIsStopped(true);
    },
    onDropRejected: (fileRejections) => {
      setIsStopped(true);
      setFileError(rejectReason);
    },
  });

  const thumbs = file.map((f, key) => (
    <div key={f.name}>
      <AppBody pClass="Caption1Regular" type="div" className="m-auto mb-3 max-w-fit break-words text-gray-6">
        <div className="text-md flex flex-row items-center bg-transparent text-gray-6">
          <div className="mx-2 inline-block w-10">
            <AttachmentPreviewPath
              uploadPreview
              attachmentPath={f.preview}
              attachmentType={f.type}
              attachmentId={key}
            />
          </div>
          <span className="ml-2 mr-10 inline">{f.name} </span>
          <button
            type="button"
            className="text-md bg-transparent text-gray-6"
            onClick={() => {
              const array = [...file]; // make a separate copy of the array
              const index = array.indexOf(f);
              if (index !== -1) {
                array.splice(index, 1);
                setFile(array);
              }
            }}
          >
            <DeleteIcon className="inline" height={20} width={20} />
          </button>
        </div>
      </AppBody>
    </div>
  ));

  return (
    <div className="relative flex w-full flex-col pt-5">
      <span className="relative font-bold ltr:text-left rtl:text-right">
        {label}{' '}
        {optionalLabel && (
          <AppBody pClass="Body2Light" type="span" className="text-gray-6 ltr:pl-2 rtl:pr-2">
            {t('optional')}
          </AppBody>
        )}
      </span>
      {labelHint && <span className="text-gray-6 ltr:text-left rtl:text-right">{labelHint}</span>}

      <section className="container mx-0 mt-1 text-center">
        <div className="pb-2">
          {thumbs.length === 0 && (
            <div
              {...getRootProps({
                className: `dropzone-container dropzone cursor-pointer ${
                  isDragActive || isFileDialogActive || !isStopped ? 'active' : ''
                }`,
              })}
            >
              <input {...getInputProps()} name={name} />
              <UploadLottie isStopped={isStopped} />
              {(isDragActive || isFileDialogActive || !isStopped) && (
                <AppBody
                  pClass="Caption1Bold"
                  className="m-auto max-w-xs text-center"
                  style={{ marginTop: '-60px', minHeight: '70px' }}
                >
                  {t('drag_text')}
                </AppBody>
              )}
              {!isDragActive && !isFileDialogActive && isStopped && (
                <>
                  <AppBody
                    pClass="Caption1Bold"
                    className="m-auto max-w-xs text-center"
                    style={{ marginTop: '-60px' }}
                  >
                    {t('drag_files')}{' '}
                    <span className="text-primary">
                      {isDragActive || isFileDialogActive || !isStopped
                        ? t('drag_text')
                        : uploadInput}
                    </span>{' '}
                    {t('here_uploading')}
                    <AppBody pClass="Caption1Regular" type="span" className="mb-2 mt-2 block underline">
                      {t('browse_files')}
                    </AppBody>
                  </AppBody>
                  <AppBody pClass="Caption1Regular" className="m-auto max-w-sm pb-2 text-center">
                    {fileHint}
                  </AppBody>
                </>
              )}
            </div>
          )}
          <aside>
            <aside className="flex flex-col break-all pt-2">
              {thumbs.length > 0
                ? thumbs
                : oldValue &&
                  oldValue.map((attachment) => (
                    <div key={attachment.path}>
                      <AppBody pClass="Caption1Regular" type="div" className="m-auto mb-3 max-w-fit break-words text-gray-6">
                        {attachment.path.split('?')[0] &&
                          attachment.path
                            .split('?')[0]
                            .substring(attachment.path.split('?')[0].lastIndexOf('/') + 1)}
                        {!attachment.path.split('?')[0] && attachment.path}
                      </AppBody>
                    </div>
                  ))}
            </aside>
          </aside>
        </div>
      </section>
      {fileError && (
        <AppBody pClass="Caption1Regular" className="absolute bottom-0 w-full pt-2 text-center text-red-700">
          {fileError}
        </AppBody>
      )}
    </div>
  );
}

export default DropzoneSingleFileForm;
