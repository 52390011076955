import { cardStatusKeys } from '../../helpers/constants';

const INITIAL_STATE = {
  // Employee Registration Mocks
  employeeExpiredLink: false,
  employeeAlreadyActivatedAccount: false,
  employeeInvalidToken: false,
  employeeYakeenVerified: false,
  // Create New Card Mocks
  hasCardHolder: false,
  hasNoCardHolder: false,
  hasPrimaryCardMock: false,
  hasPrimaryCardMockStatus: cardStatusKeys.ACTIVE,
  hasNoPrimaryCardMock: false,
  createVirtualCardMock: false,
  createVirtualCardMockStatus: cardStatusKeys.ACTIVE,
  createPlasticCardMock: false,
  createPlasticCardMockStatus: 'SUCCESS',
  replacePlasticCardMock: false,
  replacePlasticCardMockStatus: 'SUCCESS',
  replaceVirtualCardMock: false,
  replaceVirtualCardMockStatus: cardStatusKeys.ACTIVE,
  virtualCreateAndReplaceMock: false,
  plasticCreateAndReplaceMock: false,
  maxVirtualLimitMock: false,
  maxPlasticLimitMock: false,
  // Card List Mocks
  showCardModes: false,
  showCardCreationDates: false,
  showCardStatus: false,
  showCardProgramStatus: false,
};

const useMockReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USE_MOCK': {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default useMockReducer;
