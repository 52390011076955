import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import usePermissions from '../../../../hooks/usePermissions';
import TransactionAttachmentDownload from '../TransactionAttachmentDownload';
import ReceiptEditForm from './ReceiptEditForm';

function ReceiptPreview({ editReceipt, transaction, receipt, setEditReceipt, setTransaction }) {
  const { t } = useTranslation(['transactions', 'common']);
  const { user } = useSelector((state) => state.auth);

  const userCan = usePermissions();

  return (
    <>
      {(userCan('upload_account_receipt') ||
        (userCan('upload_own_transaction_receipt') && user.id === transaction.user_id?.id) ||
        userCan('edit_account_transaction_receipt') ||
        (userCan('edit_own_transaction_receipt') && user.id === transaction.user_id?.id)) &&
        (editReceipt || transaction.receipts.length === 0 || receipt === null) &&
        transaction.can_upload_reciept && (
          <ReceiptEditForm
            transactionId={transaction.id}
            setEditReceipt={setEditReceipt}
            setTransaction={setTransaction}
          />
        )}

      {receipt && !editReceipt && (
        <div className="relative flex items-center">
          <TransactionAttachmentDownload
            attachmentId={receipt.id}
            attachmentPath={receipt.path}
            transaction={transaction}
          />
          <div className="no_print absolute bottom-0 mb-0 w-full rounded-b-lg bg-gray-2 bg-opacity-80 text-center">
            {(userCan('upload_account_receipt') ||
              (userCan('upload_own_transaction_receipt') && user.id === transaction.user_id?.id) ||
              userCan('edit_account_transaction_receipt') ||
              (userCan('edit_own_transaction_receipt') && user.id === transaction.user_id?.id)) &&
              transaction.can_upload_reciept && (
                <button
                  type="button"
                  className="align-baseline text-sm font-semibold ltr:text-left rtl:text-right"
                  onClick={() => {
                    setEditReceipt(true);
                  }}
                >
                  {t('replace_receipt')}
                </button>
              )}
          </div>
        </div>
      )}
    </>
  );
}

export default ReceiptPreview;
