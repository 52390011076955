import { useTranslation } from 'react-i18next';
import invalidLink from '../../assets/png/invalidLink.png';
import { AppBody, AppHeader } from '../../components/html/html';

function InvalidToken() {
  const { t } = useTranslation('employeeRegistration');

  return (
    <div className="flex h-full items-center justify-center text-center">
      <div className="max-w-[550px] px-2 xxs:px-4">
        <div className="mb-12 flex justify-center">
          <img src={invalidLink} alt="Already Activated Account" />
        </div>

        <AppHeader h="h6" className="mb-5">
          {t('This link is not valid')}
        </AppHeader>

        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('Please ask your account manager to resend the link or contact our support team.')}
        </AppBody>
      </div>
    </div>
  );
}

export default InvalidToken;
