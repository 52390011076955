export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

const initialState = {
  notifications: [],
};

const NotificationsReducer = (state = initialState, action) => {
  const draft = { ...state };
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };
    case ADD_NOTIFICATION:
      draft.notifications.push(action.payload);
      return draft;
    case READ_NOTIFICATION:
      draft.notifications[action.payload] = {
        ...draft.notifications[action.payload],
        read: true,
      };
      return draft;
    default:
      return draft;
  }
};

export default NotificationsReducer;
