// Large Title
export const LargeTitle = function (props) {
  const { children } = props;
  return (
    <h1 className="mb-4 text-4xl font-black uppercase lg:text-7xl ltr:tracking-tighter rtl:tracking-normal">
      {children}
    </h1>
  );
};

// Large Title 2
export const LargerTitle2 = function (props) {
  const { children, className, style } = props;
  return (
    <h1
      style={style}
      className={`${className || ''} ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      } text-3xl font-black capitalize lg:text-4xl`}
    >
      {children}
    </h1>
  );
};

export const HeadlineCenter = function (props) {
  const { children } = props;
  return (
    <h1 className="centerTitle text-center text-3xl font-black capitalize lg:text-4xl ltr:tracking-tight rtl:tracking-normal">
      {children}
    </h1>
  );
};

export const HeadlineCenterAlways = function (props) {
  const { children } = props;
  return (
    <h1 className="text-center text-3xl font-black capitalize lg:text-4xl ltr:tracking-tight rtl:tracking-normal">
      {children}
    </h1>
  );
};

// Headline 2
export const PrintTitle = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${
        className || ''
      } text-xl ltr:font-black ltr:tracking-tight rtl:font-semibold rtl:tracking-normal ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Headline 2
export const Headline2 = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${
        className || ''
      } text-base ltr:font-black ltr:tracking-tight rtl:font-semibold rtl:tracking-normal ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Headline
export const Headline = function (props) {
  const { children, className, style } = props;
  return (
    <h2
      style={style}
      className={`${
        className || ''
      } font-bold ltr:text-base ltr:tracking-tight rtl:text-lg rtl:tracking-normal ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Action2
export const Action2 = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${className || ''} rlt:font-bold text-base ltr:font-semibold ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Body
export const Body = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${className || ''} text-base font-normal ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Details
export const Details = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${className || ''} text-sm font-normal ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Input
export const Input = function (props) {
  const { children, className, style } = props;
  return (
    <h2
      style={style}
      className={`${className || ''} text-sm ltr:font-light rtl:font-normal ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Small Buttons
export const SmallButton = function (props) {
  const { children, className, style } = props;
  return (
    <h2
      style={style}
      className={`${className || ''} text-sm ltr:font-semibold rtl:font-bold ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Info
export const Info = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${className || ''} text-sm font-bold ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Messages
export const Messages = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${className || ''} text-sm ltr:font-bold rtl:font-semibold ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Status
export const Status = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${className || ''} text-xs font-bold ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// Caption2
export const Caption2 = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${className || ''} text-xs ltr:font-light rtl:font-normal ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

// TABLE COLUMNS
export const TableColumn = function (props) {
  const { children, className } = props;
  return (
    <h2
      className={`${
        className || ''
      } text-xs uppercase tracking-wider ltr:font-semibold rtl:font-bold ${
        className && className.includes('text-center')
          ? 'text-center'
          : 'ltr:text-left rtl:text-right'
      }`}
    >
      {children}
    </h2>
  );
};

export const Title = function (props) {
  const { children } = props;
  return (
    <h2 className="text-base ltr:font-black ltr:tracking-tight rtl:font-semibold rtl:tracking-normal">
      {children}
    </h2>
  );
};

export const SubTitle = function (props) {
  const { children } = props;
  return (
    <h1 className="text-2xl font-black ltr:text-left rtl:text-right">{children}</h1>
  );
};

export const SectionTitle = function (props) {
  const { children } = props;
  return (
    <h2 className="text-md pb-3 pt-3 font-bold lg:text-xl ltr:text-left rtl:text-right">
      {children}
    </h2>
  );
};

export const SectionTitleMobileCenter = function (props) {
  const { children } = props;
  return (
    <h2 className="text-md pt-r w-full pb-3 text-center font-bold lg:w-auto lg:text-xl lg:ltr:text-left lg:rtl:text-right">
      {children}
    </h2>
  );
};

export const SmallTitle = function (props) {
  const { children } = props;
  return (
    <h2 className="pt-r pb-1 text-xs text-gray-6 ltr:text-left rtl:text-right">{children}</h2>
  );
};

export const XSmallText = function (props) {
  const { children, className } = props;
  return <h6 className={`${className || ''} text-xs font-normal`}>{children}</h6>;
};
