import { useTranslation } from 'react-i18next';
import { ReactComponent as Correct } from '../../assets/svg/correct.svg';

function RegisterStepBar({ step }) {
  const { t } = useTranslation(['signup']);
  return (
    <div className="w-full px-0 pb-6 pt-0 lg:px-10 lg:pt-6">
      <span className="block text-gray-6 lg:hidden">{`${step}/5`}</span>
      {step === 1 && (
        <span className="block text-gray-6 lg:hidden">
          {t('steps.complete_info', { br: '\n' })}
        </span>
      )}
      {step === 2 && (
        <span className="block text-gray-6 lg:hidden">
          {t('steps.verify_phone', { br: '\n' })}
        </span>
      )}
      {step === 3 && (
        <span className="block text-gray-6 lg:hidden">
          {t('steps.verify_email', { br: '\n' })}
        </span>
      )}
      {step === 4 && (
        <span className="block text-gray-6 lg:hidden">
          {t('steps.yakeen_information', { br: '\n' })}
        </span>
      )}
      {step === 5 && (
        <span className="block text-gray-6 lg:hidden">
          {t('steps.company_information', { br: '\n' })}
        </span>
      )}
      <div className="hidden lg:flex">
        <div className="w-1/5">
          <div
            className={`text-center text-xs lg:text-base ${
              step >= 1 ? 'font-bold' : 'text-gray-6'
            }`}
          >
            <span className="twe_line mb-1 block">{t('steps.complete_info', { br: '\n' })}</span>
          </div>
          <div className="relative mb-2">
            <div className="step mx-auto flex items-center text-lg">
              <span
                className={`text-center text-white ${step >= 1 ? 'borderDarkGrey' : ''} ${
                  step >= 2 ? 'stepBorderDarkGray' : 'stepBorderLightGray'
                }`}
              >
                {step >= 2 && <Correct />}
              </span>
            </div>
          </div>
        </div>

        <div className="w-1/5">
          <div
            className={`text-center text-xs lg:text-base ${
              step >= 2 ? 'font-bold' : 'text-gray-6'
            }`}
          >
            <span className="twe_line mb-1 block">{t('steps.verify_phone', { br: '\n' })}</span>
          </div>
          <div className="relative mb-2">
            <div className="align-center stepWidth absolute flex content-center items-center align-middle">
              <div className="stepLightGray align-center w-full flex-1 items-center align-middle">
                <div className={`stepDarkGray linePadding ${step >= 2 ? 'w-full' : 'w-0'}`} />
              </div>
            </div>

            <div className="step mx-auto flex items-center text-lg">
              <span
                className={`text-center text-white ${step >= 2 ? 'borderDarkGrey' : ''} ${
                  step >= 3 ? 'stepBorderDarkGray' : 'stepBorderLightGray'
                }`}
              >
                {step >= 3 && <Correct />}
              </span>
            </div>
          </div>
        </div>

        <div className="w-1/5">
          <div
            className={`text-center text-xs lg:text-base ${
              step >= 3 ? 'font-bold' : 'text-gray-6'
            }`}
          >
            <span className="twe_line mb-1 block">{t('steps.verify_email', { br: '\n' })}</span>
          </div>
          <div className="relative mb-2">
            <div className="align-center stepWidth absolute flex content-center items-center align-middle">
              <div className="stepLightGray align-center w-full flex-1 items-center align-middle">
                <div className={`stepDarkGray linePadding ${step >= 3 ? 'w-full' : 'w-0'}`} />
              </div>
            </div>

            <div className="step mx-auto flex items-center text-lg">
              <span
                className={`text-center text-white ${step >= 3 ? 'borderDarkGrey' : ''} ${
                  step >= 4 ? 'stepBorderDarkGray' : 'stepBorderLightGray'
                }`}
              >
                {step >= 4 && <Correct />}
              </span>
            </div>
          </div>
        </div>

        <div className="w-1/5">
          <div
            className={`text-center text-xs lg:text-base ${
              step >= 4 ? 'font-bold' : 'text-gray-6'
            }`}
          >
            <span className="twe_line mb-1 block">
              {t('steps.yakeen_information', { br: '\n' })}
            </span>
          </div>
          <div className="relative mb-2">
            <div className="align-center stepWidth absolute flex content-center items-center align-middle">
              <div className="stepLightGray align-center w-full flex-1 items-center align-middle">
                <div className={`stepDarkGray linePadding ${step >= 4 ? 'w-full' : 'w-0'}`} />
              </div>
            </div>

            <div className="step mx-auto flex items-center text-lg">
              <span
                className={`text-center text-white ${step >= 4 ? 'borderDarkGrey' : ''} ${
                  step >= 5 ? 'stepBorderDarkGray' : 'stepBorderLightGray'
                }`}
              >
                {step >= 5 && <Correct />}
              </span>
            </div>
          </div>
        </div>

        <div className="w-1/5">
          <div
            className={`text-center text-xs lg:text-base ${
              step >= 5 ? 'font-bold' : 'text-gray-6'
            }`}
          >
            <span className="twe_line mb-1 block">
              {t('steps.company_information', { br: '\n' })}
            </span>
          </div>
          <div className="relative mb-2">
            <div className="align-center stepWidth absolute flex content-center items-center align-middle">
              <div className="stepLightGray align-center w-full flex-1 items-center align-middle">
                <div className={`stepDarkGray linePadding ${step >= 5 ? 'w-full' : 'w-0'}`} />
              </div>
            </div>

            <div className="step mx-auto flex items-center text-lg">
              <span
                className={`text-center text-white ${
                  step >= 5 ? 'stepBorderLightGray borderDarkGrey' : 'stepBorderLightGray'
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterStepBar;
