import React, { useState } from 'react';

function HoverableFontIcon({ iconClass, hoverIconClass, className, style }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <>
      {!isHovered && (
        <i
          onFocus={handleMouseOver}
          onMouseOver={handleMouseOver}
          onBlur={handleMouseOut}
          onMouseOut={handleMouseOut}
          className={`${iconClass} ${className}`}
          style={style}
        />
      )}

      {isHovered && (
        <i
          onFocus={handleMouseOver}
          onMouseOver={handleMouseOver}
          onBlur={handleMouseOut}
          onMouseOut={handleMouseOut}
          className={`${hoverIconClass} ${className}`}
          style={style}
        />
      )}
    </>
  );
}

export default HoverableFontIcon;
