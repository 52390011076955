export const UPDATE_FUND_REQUESTS = 'UPDATE_FUND_REQUESTS';
export const PATCH_FUND_REQUEST = 'PATCH_FUND_REQUEST';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SET_FUND_REQUEST_FILTER = 'SET_FUND_REQUEST_FILTER';
export const RESET_FUND_REQUEST_FILTER = 'RESET_FUND_REQUEST_FILTER';

const initialState = {
  requests: [],
  filters: [],
};

const FundRequestsReducer = (state = initialState, action) => {
  const draft = { ...state };
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };
    case PATCH_FUND_REQUEST: {
      draft.requests = draft.requests.map((activity) => {
        if (activity.id === action.payload.id) {
          return {
            ...activity,
            ...action.payload,
          };
        }
        return activity;
      });
      return draft;
    }
    case UPDATE_FILTERS:
      draft.filters = action.payload;
      return draft;
    case RESET_FUND_REQUEST_FILTER:
      draft.filters = [];
      return draft;
    case SET_FUND_REQUEST_FILTER: {
      const { type, object } = action.payload;
      switch (type) {
        case 'add':
          draft.filters.push(object);
          break;
        case 'delete':
          if (object.type === 'compound') {
            Object.keys(object.options.subfilters).forEach((i) => {
              object.options.subfilters[i].value = null;
            });
          }
          draft.filters = draft.filters.filter((obj) => obj.key !== object.key);
          break;
        case 'update': {
          const { existing, updated } = object;
          const indexToUpdate = draft.filters.findIndex((item) => item.key === existing.key);
          draft.filters[indexToUpdate] = updated;
          break;
        }
        default:
          return draft;
      }
      return draft;
    }
    case UPDATE_FUND_REQUESTS:
      draft.requests = action.payload;
      return draft;
    default:
      return draft;
  }
};

export default FundRequestsReducer;
