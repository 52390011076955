import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppBody, AppButton, AppHeader } from '../../../components/html/html';
import { usersStatus } from '../../../helpers/constants';
import { hideModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import { changeUserStatusAction } from '../../../redux/UsersReducer/UsersActions';

function ChangeStatusConfirmationPopup({ item, status, fromDetails = false }) {
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();

  return (
    <div className="flex w-[95vw] max-w-[685px] items-center justify-center rounded-2xl bg-white pb-10 pt-16 lg:w-[50vw]">
      {status === usersStatus.Suspended && (
        <div className="w-3/4">
          <AppHeader h="h4" className="mb-20">
            {t('Suspend this user?')}
          </AppHeader>

          <AppBody pClass="Body1Medium" className="mb-24 text-gray-6">
            {t(
              'When this user is suspended, they can log into the platform but will have view-only access. The user will not be able to use the platform or activate their cards.',
            )}
          </AppBody>

          <div className="flex items-center justify-end gap-5">
            <AppButton
              button="link"
              className="font-bold"
              onClick={() => {
                dispatch(hideModalAction());
              }}
            >
              {t('Cancel', { ns: 'commonV2' })}
            </AppButton>

            <button
              className="min-w-36 rounded-lg bg-warn px-5 py-3 text-base font-bold"
              type="button"
              onClick={() => {
                dispatch(changeUserStatusAction({ userId: item.id, status, fromDetails }));
                dispatch(hideModalAction());
              }}
            >
              {t('Suspend')}
            </button>
          </div>
        </div>
      )}
      {status === usersStatus.Deactivate && (
        <div className="w-3/4">
          <AppHeader h="h4" className="mb-6">
            {t('Deactivate this user?')}
          </AppHeader>

          <AppBody pClass="Body1Bold" className="mb-9">
            {t(
              'If this user has a balance on his cards. We will unload the amount back to the card program.',
            )}
          </AppBody>

          <AppBody pClass="Body1Medium" className="mb-20 text-gray-6">
            {t(
              'When this user is deactivated, they will not be able to access the platform unless an admin reactivates their account. The user will also need to manually replace their cards after their account is reactivated.',
            )}
          </AppBody>

          <div className="flex items-center justify-end gap-5">
            <AppButton
              button="link"
              className="font-bold"
              onClick={() => {
                dispatch(hideModalAction());
              }}
            >
              {t('Cancel', { ns: 'commonV2' })}
            </AppButton>

            <button
              className="min-w-36 rounded-lg bg-danger px-5 py-3 text-base font-bold text-white"
              type="button"
              onClick={() => {
                dispatch(changeUserStatusAction({ userId: item.id, status, fromDetails }));
                dispatch(hideModalAction());
              }}
            >
              {t('Deactivate(v)')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangeStatusConfirmationPopup;
