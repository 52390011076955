import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TranslatedData from '../../../../components/TranslatedData';
import { AppBody, AppIcon } from '../../../../components/html/html';

function SingleBankCard({ item, isSelected, hasBorder = false, ...rest }) {
  const { t } = useTranslation('commonV2');

  const isPending = useMemo(() => {
    return item.status !== 'Active';
  }, [item]);

  return (
    <div
      className={`relative h-[140px] cursor-pointer overflow-hidden rounded-2xl border p-4 shadow-lg ${
        hasBorder || isSelected ? 'border-black' : 'border-gray-3'
      } ${isPending ? 'pointer-events-none ' : ''}`}
      {...rest}
    >
      <div className={isPending ? 'opacity-60' : ''}>
        <div className="mb-6 flex items-center gap-2">
          {!item.bank?.logo && <AppIcon iClass="XXLargeFont" className="fa-kit fa-landmark" />}
          {item.bank?.logo && <img src={item.bank.logo} alt="bank logo" width={25} />}

          <AppBody pClass="Body2Heavy">{item.bank_name}</AppBody>
        </div>
        <div className="mb-2">
          <AppBody pClass="Body2Medium" className="inline-block">
            <TranslatedData ar={item.country.name_ar} en={item.country.name} />{' '}
          </AppBody>
          <AppBody pClass="Body2Heavy" type="span" className="inline-block">
            .{item.currency}
          </AppBody>
        </div>
        <AppBody pClass="Caption1Bold">{item.iban}</AppBody>
      </div>
      {isPending && (
        <div className="absolute -end-9 top-5 w-[130px] bg-gray-3 text-center ltr:rotate-45 rtl:-rotate-45">
          {t('Pending')}
        </div>
      )}
    </div>
  );
}

export default SingleBankCard;
