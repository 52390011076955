import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppSpinner from '../../../../components/AppSpinner';
import { AppBody } from '../../../../components/html/html';
import NqoodCardProgram from '../CardProgramList/NqoodCardProgram';

function CardProgramFrom({ fromTransfer = false, fromWithdraw = false }) {
  const { t } = useTranslation('walletV2');
  const { actions } = useSelector((state) => state.wallet);

  return (
    <>
      <div>
        <AppBody pClass="Body1Bold" className="mb-2">
          {t('From', { ns: 'commonV2' })}
        </AppBody>
        <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
          {fromWithdraw && t('The money will be withdrawn from this selected card program')}
          {fromTransfer && t('The money will be transfer from this selected card program')}
        </AppBody>
      </div>

      <div className="mb-6 grid grid-cols-1 gap-4 p-1 lg:grid-cols-3 2xl:grid-cols-4">
        {actions.transferFromData?.balance && (
          <NqoodCardProgram fromActions cardProgram={actions.transferFromData} />
        )}

        {!actions.transferFromData?.balance && (
          <div className="h-32 rounded-xl border">
            <AppSpinner />
          </div>
        )}
      </div>
    </>
  );
}

export default CardProgramFrom;
