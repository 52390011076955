import { useTranslation } from 'react-i18next';
import { AppBody, AppIcon } from '../../../components/html/html';

function UsersListFilterTags({ filterTags, handleRemoveSinglePopupFilter }) {
  const { t } = useTranslation('commonV2');

  return (
    !!Object.values(filterTags).length && (
      <AppBody type="div" pClass="Body2Regular" className="my-2 flex items-center gap-3">
        <p>{t('Filter By: ')}</p>
        <div className="flex flex-wrap gap-3">
          {Object.values(filterTags).map((ele) => {
            return (
              <div
                key={ele.filterName}
                className="flex items-center gap-3 rounded-full bg-gray-2 px-3 py-1 text-primary"
              >
                <span>
                  {t(ele.filterName)}: {t(ele.filterValue)}
                </span>
                <button
                  type="button"
                  onClick={() => {
                    handleRemoveSinglePopupFilter(ele);
                  }}
                >
                  <AppIcon iClass="XSmall" className="fa-regular fa-x" />
                </button>
              </div>
            );
          })}
        </div>
      </AppBody>
    )
  );
}

export default UsersListFilterTags;
