import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Thanks1 from '../../../../assets/png/thanks1.png';
import ActionButton from '../../../../components/Button/ActionButton';
import {AppBody, AppHeader} from '../../../../components/html/html';

function RegistrationStep1ThankYou() {
  const { t } = useTranslation(['signup']);
  const navigate = useNavigate();

  return (
    <div className="flex h-full items-center justify-center">
      <div className="m-auto flex w-full max-w-sm flex-col text-center lg:w-7/12">
        <img alt="Thanks" src={Thanks1} className="m-auto w-full max-w-xs" />

        <AppHeader h="h4" className="text-center">
          {t('thank.step1_header')}
        </AppHeader>
        <AppBody pClass="Body2Regular" className="mb-10 pt-5 text-center">{t('thank.step1_content')}</AppBody>
        <ActionButton
          className="m-auto flex w-auto flex-row px-10 lg:px-40"
          primary
          text={t('thank.lets_do')}
          onClick={() => {
            navigate('../verify_company/business_info');
          }}
        />
      </div>
    </div>
  );
}

export default RegistrationStep1ThankYou;
