import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import {AppBody} from "../html/html";

function preventNonNumericalInput(e) {
  // eslint-disable-next-line no-param-reassign
  e = e || window.event;
  const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
  const charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9.]+$/)) e.preventDefault();
}

export const NumberInput = React.forwardRef((props, ref) => {
  const { error, className, small } = props;

  return (
    <div className="flex flex-col justify-start">
      <input
        ref={ref}
        pattern="[0-9]*"
        onKeyPress={preventNonNumericalInput}
        type="number"
        min="0.1"
        step=".01"
        className={`mt-1 rounded-full border  py-1.5 text-sm font-normal ${
          className ?? ''
        } ${small ? 'px-3' : 'px-10'} ${error ? 'border-red-400' : 'border-gray-3'}`}
        {...props}
      />
      <AppBody pClass="Caption1Regular" className="pt-2 text-red-700 ltr:text-left rtl:text-right">
        {error && error.message}
      </AppBody>
    </div>
  );
});

export const RealNumberInput = React.forwardRef((props, ref) => {
  const { error, className, small } = props;

  return (
    <div className="flex flex-col justify-start">
      <input
        ref={ref}
        pattern="[0-9]*"
        onKeyPress={preventNonNumericalInput}
        type="number"
        min="0"
        step="1"
        className={`mt-1 rounded-full border  py-1.5 text-sm font-normal ${
          className ?? ''
        } ${small ? 'px-3' : 'px-10'} ${error ? 'border-red-400' : 'border-gray-3'}`}
        {...props}
      />
      <AppBody pClass="Caption1Regular" className="pt-2 text-red-700 ltr:text-left rtl:text-right">
        {error && error.message}
      </AppBody>
    </div>
  );
});

export const TextInput = React.forwardRef((props, ref) => {
  const { error, className, stars } = props;
  return (
    <div className="flex flex-col justify-start">
      <input
        ref={ref}
        type={stars ? 'password' : 'text'}
        className={`text-sm font-normal ${className ?? ''} ${
          error ? 'border-red-400' : 'border-gray-3'
        } mt-1 rounded-full border  px-10 py-1.5`}
        {...props}
      />
      <AppBody pClass="Caption1Regular" className="pt-2 text-red-700 ltr:text-left rtl:text-right">
        {error && error.message}
      </AppBody>
    </div>
  );
});

export const InlineTextInput = React.forwardRef((props, ref) => {
  const { error, className, stars } = props;
  return (
    <div className="inline-block">
      <input
        ref={ref}
        type={stars ? 'password' : 'text'}
        className={`text-sm font-normal ${className ?? ''} ${
          error ? 'border-red-400' : 'border-gray-3'
        } mt-1 rounded-full border  px-10 py-1.5`}
        {...props}
      />
      <AppBody pClass="Caption1Regular" className="pt-2 text-red-700 ltr:text-left rtl:text-right">
        {error && error.message}
      </AppBody>
    </div>
  );
});

export const TextArea = React.forwardRef((props, ref) => {
  const { error, className } = props;
  const [countLetters, setCountLetters] = useState(props.existValue ?? 0);

  const handleMessageChange = (event) => {
    setCountLetters(event.target.value.length);
  };
  return (
    <div className="relative flex flex-col justify-start">
      <textarea
        ref={ref}
        className={`text-sm font-normal ${className ?? ''} ${
          error ? 'border-red-400' : 'border-gray-3'
        } mt-1 rounded-full border  px-5 py-1.5`}
        {...props}
        onChange={handleMessageChange}
      />
      {props.maxLength && (
        <p
          className="absolute w-full px-10 text-sm font-normal text-gray-6 ltr:text-right rtl:text-left"
          style={{ bottom: '-15px' }}
        >
          {countLetters} /{props.maxLength}
        </p>
      )}
      <AppBody pClass="Caption1Regular" className="pt-2 text-red-700 ltr:text-left rtl:text-right">
        {error && error.message}
      </AppBody>
    </div>
  );
});

export const Checkbox = React.forwardRef((props, ref) => {
  const { className } = props;
  return <input className={className} type="checkbox" ref={ref} {...props} />;
});

export const SelectInputCustom = React.forwardRef((props, ref) => {
  const { error, options, defaultValue, onChange, disabled, id } = props;

  const { t, i18n } = useTranslation(['common']);
  const [defaultVal, setDefaultVal] = useState([]);

  useEffect(() => {
    setDefaultVal(
      !defaultValue
        ? { label: t('select'), value: '' }
        : { label: defaultValue.label, value: defaultValue.value },
    );
  }, [defaultValue]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFFFFF80',
      borderColor: 'rgb(199,208,213)',
      fontWeight: 'normal',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      minHeight: '34px',
      marginTop: '0.25rem',
      boxShadow: 'none',
      borderRadius: '9999px',
    }),
    // eslint-disable-next-line no-shadow
    option: (base, { data }) => ({
      ...base,
      color: data?.color || '#333333',
    }),
  };

  return (
    <div className="flex flex-col justify-start">
      <Controller
        {...props}
        ref={ref}
        render={({ field: { value1, ref1 } }) => (
          <Select
            id={props.name}
            inputId={props.name}
            className="text-sm font-normal"
            isDisabled={disabled}
            isRtl={i18n === 'ar'}
            placeholder={t('select')}
            defaultValue={defaultVal}
            styles={customStyles}
            name={props.name}
            value={value1 || defaultVal}
            inputRef={ref1}
            options={options}
            onChange={(val) => {
              onChange(val.value);
              setDefaultVal({ label: val.label, value: val.value });
            }}
          />
        )}
      />
      <AppBody pClass="Caption1Regular" className="pt-2 text-red-700">{error && error.message}</AppBody>
    </div>
  );
});
