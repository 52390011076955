import { useSelector } from 'react-redux';
import RequestAttachmentsPrint from '../../../../../../components/SharedComponents/RequestAttachmentsPrint';
import RequestBalanceAndStatus from '../../../../../../components/SharedComponents/RequestBalanceAndStatus';
import RequestCardDetailsAndDescription from '../../../../../../components/SharedComponents/RequestCardDetailsAndDescription';
import RequestFrom from '../../../../../../components/SharedComponents/RequestFrom';
import RequestMainInfoData from '../../../../../../components/SharedComponents/RequestMainInfoData';
import { inPages } from '../../../../../../helpers/constants';
import usePermissions from '../../../../../../hooks/usePermissions';
import ApprovedRejectedRequestAnimation from './ApprovedRejectedRequestAnimation';
import RequestActions from './RequestActions';
import RequestRejectionReasons from './RequestRejectionReasons';

function WalletRequestPopupContent() {
  const {
    showWalletRequestFormPopup,
    singleWalletRequestDetails,
    selectedWalletMainActiveTab,
    singleSelectedCardProgramDetails,
    willingToRejectRequest,
    approvedRejectedRequestAnimation,
  } = useSelector((state) => state.wallet);
  const userCan = usePermissions();

  return (
    <div>
      {!showWalletRequestFormPopup && !approvedRejectedRequestAnimation.show && (
        <>
          <div className="mb-10">
            <RequestBalanceAndStatus requestDetails={singleWalletRequestDetails} />
          </div>
          <RequestMainInfoData
            inPage={inPages.wallet}
            requestDetails={singleWalletRequestDetails}
          />
          <div className="mb-5">
            {!willingToRejectRequest && (
              <RequestCardDetailsAndDescription
                inPage={inPages.wallet}
                cardProgramScheme={singleSelectedCardProgramDetails.scheme}
                requestDetails={singleWalletRequestDetails}
              />
            )}

            {willingToRejectRequest && (
              <RequestRejectionReasons
                cardProgramScheme={singleSelectedCardProgramDetails.scheme}
                requestDetails={singleWalletRequestDetails}
              />
            )}
          </div>
          {userCan('update_account_funds_status') && singleWalletRequestDetails.status_id === 1 && (
            <div className="mb-3">
              <RequestActions requestDetails={singleWalletRequestDetails} />
            </div>
          )}
          <div className="hidden print:block">
            <RequestAttachmentsPrint requestDetails={singleWalletRequestDetails} />
          </div>
        </>
      )}

      {showWalletRequestFormPopup && !approvedRejectedRequestAnimation.show && (
        <div>
          <RequestFrom
            requestDetails={singleWalletRequestDetails}
            activeTab={selectedWalletMainActiveTab}
            fromWalletRequests
          />
        </div>
      )}

      {approvedRejectedRequestAnimation.show && <ApprovedRejectedRequestAnimation />}
    </div>
  );
}

export default WalletRequestPopupContent;
