import { translatedDataFunction } from '../../helpers/Utils';
import store from '../store';

export const hideToasterAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_TOASTER',
    payload: { show: false },
  });
};

export const showToasterAction =
  ({ type, message, timeout = 3000 }) =>
  (dispatch) => {
    const { lang } = store.getState().appConfig;
    const msg =
      message || translatedDataFunction({ lang, en: 'Something went wrong.', ar: 'حدث خطأ ما.' });

    if (msg) {
      dispatch({
        type: 'SHOW_TOASTER',
        payload: { show: true, type, message: msg },
      });

      setTimeout(() => {
        store.dispatch(hideToasterAction());
      }, timeout);
    }
  };
