import { devLog } from '../../helpers/Utils';
import { http } from '../../http';
import { showToasterAction } from '../AppToastersReducer/AppToastersActions';

export const setActiveProfileTabIndexAction = (activeTabIndex) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_PROFILE_TAB_INDEX',
    payload: activeTabIndex,
  });
};

export const getUserInfoAction = () => (dispatch) => {
  devLog(`Getting User Info...`);

  http
    .get('users/profile', { loader: 'getUserInfoAction' })
    .then((res) => {
      dispatch({
        type: 'GET_USER_INFO',
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: err?.data?.error,
        }),
      );
    });
};
