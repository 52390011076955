import TopDept from './TopDept';

const AccountWidget = function ({ daysFilter }) {
  return (
    <div className="border-b border-gray-3">
      <TopDept daysFilter={daysFilter} />
    </div>
  );
};

export default AccountWidget;
