import { getURLParams } from 'javascript-functions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '../../../../components/AppSpinner';
import { createNewCardSteps } from '../../../../helpers/constants';
import {
    clearCardListAction,
    getCardListAction,
} from '../../../../redux/CardListReducer/CardListActions';

import {
    clearAllCreateNewCardStateAction,
    getCreateCardUserDataAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CardMaxLimit from './CardMaxLimit';
import CreateOrReplaceCard from './CreateOrReplaceCard';
import CreatePlasticCard from './CreatePlasticCard';
import CreatePrimaryCard from './CreatePrimaryCard';
import CustomizePrimaryCard from './CustomizePrimaryCard';
import ManageCardControl from './ManageCardControl';
import ManualVirtualCardActivation from './ManualVirtualCardActivation';
import NoUserCards from './NoUserCards';
import PlasticCardCreation from './PlasticCardCreation';
import ReplaceCardConfirmation from './ReplaceCardConfirmation';
import RequestPlasticCardFailed from './RequestPlasticCardFailed';
import RequestPlasticCardSuccess from './RequestPlasticCardSuccess';
import SelectCardProgram from './SelectCardProgram';
import SelectCardType from './SelectCardType';
import SelectReplaceCard from './SelectReplaceCard';
import SendOTP from './SendOTP';
import ShippingDetails from './ShippingDetails';
import VerifyAccount from './VerifyAccount';
import VirtualCardActivation from './VirtualCardActivation';
import VirtualCardActivationFailed from './VirtualCardActivationFailed';
import VirtualCardCreationFailed from './VirtualCardCreationFailed';

function CreateNewCard() {
  const { loaders } = useSelector((state) => state);
  const { activeCreateNewCardStep } = useSelector((state) => state.createNewCard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCardListAction());
    dispatch(getCreateCardUserDataAction(+getURLParams('createCardUserId')));

    return () => {
      dispatch(clearAllCreateNewCardStateAction());
      dispatch(clearCardListAction());
    };
  }, []);

  return (
    <div className="h-full py-2">
      <div className="relative h-full overflow-y-auto rounded-xl border border-gray-3 bg-slate-50">
        {loaders.getCardListAction && <AppSpinner />}

        {!loaders.getCardListAction && (
          <>
            {activeCreateNewCardStep === createNewCardSteps.noUserCards && <NoUserCards />}
            {activeCreateNewCardStep === createNewCardSteps.verifyAccount && <VerifyAccount />}
            {activeCreateNewCardStep === createNewCardSteps.selectCardProgram && (
              <SelectCardProgram />
            )}
            {activeCreateNewCardStep === createNewCardSteps.selectCardType && <SelectCardType />}
            {activeCreateNewCardStep === createNewCardSteps.createOrReplaceCard && (
              <CreateOrReplaceCard />
            )}
            {activeCreateNewCardStep === createNewCardSteps.createPrimaryCard && (
              <CreatePrimaryCard />
            )}
            {activeCreateNewCardStep === createNewCardSteps.customizePrimaryCard && (
              <CustomizePrimaryCard />
            )}
            {activeCreateNewCardStep === createNewCardSteps.sendOTP && <SendOTP />}
            {activeCreateNewCardStep === createNewCardSteps.virtualCardActivation && (
              <VirtualCardActivation />
            )}

            {activeCreateNewCardStep === createNewCardSteps.manualCardActivation && (
              <ManualVirtualCardActivation />
            )}
            {activeCreateNewCardStep === createNewCardSteps.virtualCardActivationFailed && (
              <VirtualCardActivationFailed />
            )}
            {activeCreateNewCardStep === createNewCardSteps.virtualCardCreationFailed && (
              <VirtualCardCreationFailed />
            )}
            {activeCreateNewCardStep === createNewCardSteps.manageCardControl && (
              <ManageCardControl />
            )}

            {activeCreateNewCardStep === createNewCardSteps.createPlasticCard && (
              <CreatePlasticCard />
            )}
            {activeCreateNewCardStep === createNewCardSteps.shippingDetails && <ShippingDetails />}
            {activeCreateNewCardStep === createNewCardSteps.plasticCardCreation && (
              <PlasticCardCreation />
            )}
            {activeCreateNewCardStep === createNewCardSteps.requestPlasticCardSuccess && (
              <RequestPlasticCardSuccess />
            )}
            {activeCreateNewCardStep === createNewCardSteps.requestPlasticCardFailed && (
              <RequestPlasticCardFailed />
            )}
            {activeCreateNewCardStep === createNewCardSteps.cardMaxLimit && <CardMaxLimit />}
            {activeCreateNewCardStep === createNewCardSteps.selectReplaceCard && (
              <SelectReplaceCard />
            )}
            {activeCreateNewCardStep === createNewCardSteps.replaceCardConfirmation && (
              <ReplaceCardConfirmation />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CreateNewCard;
