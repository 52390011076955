import { status } from 'constants-js';
import Cookies from 'js-cookie';
import { history } from '../AppRouter/history';
import { devLog, isDev } from '../helpers/Utils';
import { mockEndPoint } from '../helpers/constants';
import { showToasterAction } from '../redux/AppToastersReducer/AppToastersActions';
import { addLoader, removeLoader } from '../redux/LoadersReducer/LoaderActions';
import store from '../redux/store';

export const requestHandler = (request) => {
  const token = Cookies.get('token');
  const lang = request?.lang || Cookies.get('lang');
  const updatedRequest = { ...request };

  if (!updatedRequest.headers.Authorization && !updatedRequest.noToken) {
    updatedRequest.headers.Authorization = `Bearer ${token}`;
  }

  updatedRequest.headers['Accept-Language'] = lang || 'en';

  if (request.useMock) {
    updatedRequest.url = mockEndPoint;
    if (request.mockParams) {
      updatedRequest.params = request.mockParams;
    }
  }

  if (request.loader) {
    store.dispatch(addLoader(request.loader));
  }
  return updatedRequest;
};

export const successHandler = (response) => {
  if (response.config.loader) {
    store.dispatch(removeLoader(response.config.loader));
  }
  return response.data;
};

export const errorHandler = (error) => {
  if (error.config.loader) {
    store.dispatch(removeLoader(error.config.loader));
  }

  if (!error.config.disableUnAuthRedirect && error.response.status === status.unauthorized) {
    devLog('[Unauthenticated!], Redirecting user to Login Page...');
    if (isDev()) {
      store.dispatch(
        showToasterAction({
          type: 'error',
          message: '[Unauthenticated!], Redirecting user to Login Page...',
        }),
      );
    }
    history.push('/auth/logout');
    return Promise.resolve({ ...error.response });
  }

  if (error.config.disableCatch) {
    devLog('[Catch] Disabled!');
    store.dispatch(
      showToasterAction({
        type: 'error',
        message: error.response?.data?.error,
      }),
    );
    return Promise.resolve({ ...error.response });
  }

  return Promise.reject({ ...error.response });
};
