import { React, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppTd, AppTr } from '../../../../../components/html/html';
import { formatDate, toIntAndDecimalNumber } from '../../../../../helpers/Utils';
import {
  getSingleWalletCardsActivityDetailsAction,
  showWalletReceiptPopupAction,
} from '../../../../../redux/WalletReducer/WalletActions';

function CardsActivityTableRow({ item }) {
  const { isMobile, lang } = useSelector((state) => state.appConfig);

  const { singleSelectedCardProgramDetails } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const handleViewReceipt = useCallback(() => {
    dispatch(showWalletReceiptPopupAction());
    dispatch(getSingleWalletCardsActivityDetailsAction(item.id));
  }, [item]);

  return (
    <>
      {/* ================= Desktop Row ================= */}
      {!isMobile && (
        <AppTr
          className="hidden cursor-pointer border-b last:border-0 lg:table-row [&>*]:max-w-[100px] [&>*]:py-3"
          onClick={handleViewReceipt}
        >
          <AppTd className="w-12">{formatDate(item.created_at, 'HH:mm')}</AppTd>
          <AppTd className="min-w-[50px]">{item.id}</AppTd>
          <AppTd>{item?.function}</AppTd>
          <AppTd className="col-span-2">
            {item?.function_key !== 'card_load' &&
              `${item?.description.name_on_card} *${item?.description.card_last_four}`}
            {item?.function_key === 'card_load' && singleSelectedCardProgramDetails?.description}
          </AppTd>
          <AppTd className="col-span-2">
            {item?.function_key === 'card_load' &&
              `${item?.description.name_on_card} *${item?.description.card_last_four}`}
            {item?.function_key !== 'card_load' && singleSelectedCardProgramDetails?.description}
          </AppTd>

          <AppTd>
            <div
              className={`flex gap-1 ${lang === 'ar' ? 'justify-end' : ''}`}
              style={{ direction: 'ltr' }}
            >
              <div style={{ direction: 'ltr' }}>
                {toIntAndDecimalNumber(item?.description?.amount).integerPart}
                <span className="text-[13px] font-normal">
                  {toIntAndDecimalNumber(item?.description?.amount).decimalPart}
                </span>
              </div>
              <span>{item?.description?.currency}</span>
            </div>
          </AppTd>

          <AppTd>
            <span style={{ color: item?.status?.color }}>{item?.status?.name}</span>
          </AppTd>
        </AppTr>
      )}

      {/* ================= Mobile Row ================= */}
      {isMobile && (
        <div
          onClick={handleViewReceipt}
          className="mb-2 flex cursor-pointer items-center gap-2 rounded-2xl p-4 font-bold shadow-xl"
        >
          <div className="flex-1">{item.id}</div>

          <div className="flex flex-1 justify-center gap-1">{item?.function}</div>

          <div className="flex-1">
            <span style={{ color: item?.status?.color }}>{item?.status?.name}</span>
          </div>
        </div>
      )}
    </>
  );
}

export default CardsActivityTableRow;
