import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '../../../../../../../components/AppSpinner';
import AppSwitchInput from '../../../../../../../components/AppSwitchInput';
import { AppButton, AppHeader } from '../../../../../../../components/html/html';
import { getServerErrMessage } from '../../../../../../../helpers/Utils';
import { cardStatusKeys } from '../../../../../../../helpers/constants';
import { http } from '../../../../../../../http';
import { showToasterAction } from '../../../../../../../redux/AppToastersReducer/AppToastersActions';
import { hideSettingsPopupDataAction } from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function CardControl() {
  const { t } = useTranslation('cardsV2');
  const [checkedItems, setCheckedItems] = useState({});
  const { loaders } = useSelector((state) => state);
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const [cardControlError, setCardControlError] = useState(false);

  const dispatch = useDispatch();

  const selectedCardStatus = useMemo(() => {
    return settingsPopupCardDetails?.status?.key?.toUpperCase();
  }, [settingsPopupCardDetails]);

  const disableAllOptions = useMemo(() => {
    return selectedCardStatus !== cardStatusKeys.ACTIVE || cardControlError;
  }, [selectedCardStatus, cardControlError]);

  const cardControlOptions = useMemo(() => {
    return [
      {
        id: 'ecommerce',
        label: t('E-Commerce'),
        disabled: disableAllOptions,
      },
      {
        id: 'atm',
        label: t('Cash Withdrawal'),
        disabled: disableAllOptions,
      },
      {
        id: 'pos',
        label: t('Point of Sale'),
        disabled: disableAllOptions,
      },
    ];
  }, [disableAllOptions]);

  const handleSaveCardControl = useCallback(() => {
    const cardId = settingsPopupCardDetails.id;

    http
      .put(`cardmanager/cards/${cardId}/control`, checkedItems, {
        loader: 'handleSaveCardControl',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        dispatch(
          showToasterAction({
            type: 'success',
            message: t('Card control updated successfully!'),
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err.data?.error,
          }),
        );
      });
  }, [settingsPopupCardDetails, checkedItems]);

  const handleCheckItem = useCallback(
    ({ item, checked }) => {
      setCheckedItems((prev) => {
        return {
          ...prev,
          [item.id]: checked,
        };
      });
    },
    [checkedItems],
  );

  const getCardControls = useCallback(() => {
    const cardId = settingsPopupCardDetails.id;
    http
      .get(`cardmanager/cards/${cardId}/control`, { loader: 'getCardControls' })
      .then((res) => {
        setCardControlError(false);
        setCheckedItems(res.data?.card_control || {});
      })
      .catch((err) => {
        setCardControlError(true);
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [settingsPopupCardDetails]);

  useEffect(() => {
    getCardControls();

    return () => {
      setCardControlError(false);
    };
  }, []);

  return (
    <>
      <AppHeader h="h5" className="mb-3">
        {t('Card Control')}
      </AppHeader>
      {!loaders.getCardControls && (
        <>
          <div className="mb-6 overflow-hidden rounded-lg border border-gray-3">
            {cardControlOptions.map((ele) => {
              return (
                <div
                  key={ele.label}
                  className={`flex w-full items-center justify-between border-b p-4 text-start font-normal last:border-0 hover:bg-stone-50 ${
                    ele.disabled ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                >
                  <span>{ele.label}</span>
                  <AppSwitchInput
                    disabled={loaders.handleSaveCardControl || ele.disabled}
                    checked={checkedItems[ele.id]}
                    onChange={(checked) => {
                      handleCheckItem({ item: ele, checked });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex justify-end gap-3">
            <AppButton
              button="link"
              onClick={() => {
                dispatch(hideSettingsPopupDataAction());
              }}
            >
              {t('Cancel', { ns: 'commonV2' })}
            </AppButton>
            <AppButton
              disabled={disableAllOptions}
              onClick={handleSaveCardControl}
              isLoading={loaders.handleSaveCardControl}
              loadingLabel={t('Saving...', { ns: 'commonV2' })}
              button="black"
            >
              {t('Save', { ns: 'commonV2' })}
            </AppButton>
          </div>
        </>
      )}

      {loaders.getCardControls && (
        <div className="h-32">
          <AppSpinner />
        </div>
      )}
    </>
  );
}

export default CardControl;
