import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FaArrowsRotate} from 'react-icons/fa6';
import {IoIosCheckmarkCircleOutline} from 'react-icons/io';
import {useDispatch, useSelector} from 'react-redux';
import requestsNoFileUploaded from '../../assets/png/requestsNoFileUploaded.png';
import {formatDate, getServerErrMessage} from '../../helpers/Utils';
import {inPages, requestStatusIds} from '../../helpers/constants';
import {http} from '../../http';
import {showToasterAction} from '../../redux/AppToastersReducer/AppToastersActions';
import {handleReloadRequestAction} from '../../redux/WalletReducer/WalletActions';
import AppUpload from '../FormInputs/AppUpload';
import {AppBody, AppIcon} from "../html/html";

function RequestMainInfoData({ requestDetails, inPage }) {
  const { loaders } = useSelector((state) => state);
  const { willingToRejectRequest } = useSelector((state) => state.wallet);

  const { t } = useTranslation('commonV2');
  const dispatch = useDispatch();

  const [isReminderSent, setIsReminderSent] = useState(false);

  const showSendReminderButton = useMemo(() => {
    return (
      inPage === inPages.myCards &&
      !requestDetails.ref_id &&
      requestDetails.status_id === requestStatusIds.PENDING
    );
  }, [requestDetails, inPage]);

  const showUploadInput = useMemo(() => {
    return inPage === inPages.myCards || (inPage === inPages.wallet && !willingToRejectRequest);
  }, [inPage, willingToRejectRequest]);

  const showClickToReloadButton = useMemo(() => {
    return inPage === inPages.wallet && requestDetails.ref_id?.status?.key === 'FAILURE';
  }, [requestDetails, inPage]);

  const showReloadRequestedMessage = useMemo(() => {
    return (
      inPage === inPages.wallet &&
      requestDetails.status_id === requestStatusIds.APPROVED &&
      requestDetails.ref_id?.status?.key !== 'FAILURE' &&
      requestDetails.ref_id?.status?.key !== 'SUCCESS'
    );
  }, [requestDetails, inPage]);

  const handleSendReminder = useCallback(() => {
    http
      .patch(`funds/${requestDetails.id}/reminder`, null, {
        loader: 'handleSendReminder',
      })
      .then(() => {
        setIsReminderSent(true);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  useEffect(() => {
    setIsReminderSent(Boolean(requestDetails.is_reminder_sent));
  }, []);

  return (
    <div className="mb-6 flex justify-between gap-4">
      <div>
        <div className="flex flex-col gap-1">
          <span className="text-sm text-gray-6">
            {formatDate(requestDetails.created_at, 'HH:mm')}
            &nbsp;&nbsp;&nbsp;
            {formatDate(requestDetails.created_at, 'DD MMMM YYYY')}
          </span>
          <AppBody pClass="Body1Bold" type="span">
            {requestDetails.status_id === requestStatusIds.PENDING && t('Pending')}
            {requestDetails.status_id === requestStatusIds.APPROVED && t('Approved')}
            {requestDetails.status_id === requestStatusIds.REJECTED && t('Rejected')}
          </AppBody>

          <AppBody pClass="Body1Bold" type="span">{requestDetails?.id}</AppBody>

          {requestDetails?.status_id === requestStatusIds.APPROVED &&
            requestDetails.ref_id?.status?.key !== 'FAILURE' &&
            requestDetails?.ref_id?.description?.transaction_id && (
              <>
                <span className="mt-1 text-sm text-gray-6">
                  {t('Transaction number: ', { ns: 'cardsV2' })}
                </span>
                <span className="text-sm text-gray-6">
                  {requestDetails.ref_id.description.transaction_id}
                </span>
              </>
            )}
        </div>

        {showSendReminderButton && (
          <button
            disabled={loaders.handleSendReminder || isReminderSent}
            onClick={handleSendReminder}
            type="button"
            className={`mt-1 flex items-center gap-1 ${
              isReminderSent ? 'text-gray-3' : 'text-warn hover:underline'
            }`}
          >
            <span>
              {loaders.handleSendReminder && <AppIcon iClass="Default"  className="fa-regular fa-loader animate-spin" />}

              {!loaders.handleSendReminder && !isReminderSent && <AppIcon iClass="Default"  className="fa-regular fa-bell" />}

              {!loaders.handleSendReminder && isReminderSent && (
                <AppIcon iClass="Default" className="fa-regular fa-circle-check" />
              )}
            </span>

            {!isReminderSent && <span>{t('Send Reminder', { ns: 'cardsV2' })}</span>}
            {isReminderSent && <span>{t('Reminder Sent', { ns: 'cardsV2' })}</span>}
          </button>
        )}

        {showClickToReloadButton && (
          <button
            disabled={loaders.handleReloadRequestAction}
            onClick={() => {
              dispatch(handleReloadRequestAction(requestDetails));
            }}
            type="button"
            className="mt-1 flex items-center gap-1 text-sm text-danger hover:underline disabled:cursor-not-allowed disabled:opacity-50"
          >
            <span>
              <FaArrowsRotate
                className={loaders.handleReloadRequestAction ? 'animate-spin' : ''}
                size={15}
              />
            </span>

            <span>{t('Click to Reload money', { ns: 'walletV2' })}</span>
          </button>
        )}

        {showReloadRequestedMessage && (
          <p
            type="button"
            className="mt-1 flex items-center gap-1 text-xs text-gray-5  disabled:cursor-not-allowed disabled:opacity-50"
          >
            <span>
              <IoIosCheckmarkCircleOutline size={20} />
            </span>

            <span>{t('Money Reload Requested', { ns: 'walletV2' })}</span>
          </p>
        )}
      </div>

      {showUploadInput && (
        <div>
          <div className="print:hidden">
            <AppUpload
              showAccepted={false}
              noFileUploadedStateImage={
                <img
                  src={requestsNoFileUploaded}
                  alt="no file uploaded"
                  className="mb-4"
                  width={120}
                />
              }
              isDisabled
              placeHolder={
                <AppBody type="div" pClass="Caption1Bold">
                  <span>{t('No File Uploaded')}</span>
                </AppBody>
              }
              uploadedFilePreview={requestDetails.attachments[0]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestMainInfoData;
