import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PeriodSelection from '../../components/FormInputs/PeriodSelection';
import { AppBody } from '../../components/html/html';
import usePermissions from '../../hooks/usePermissions';
import Head from '../Head';
import DashboardHeader from './DashboardHeader';
import DashboardSidebar from './DashboardSidebar';
import TopMerch from './TopMerch';
import TopTags from './TopTags';
import TransactionsWidget from './TransactionsWidget';

function DashboardBody(props) {
  const { user } = props;
  const userCan = usePermissions();
  const [daysFilter, setDaysFilter] = useState(30);

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  if (user.has_on_boarding === false && userCan('list_account_bank_funds')) {
    return (
      <div className="m-auto flex w-full flex-col text-center lg:w-8/12" style={{ marginTop: '0' }}>
        <Head title="OnBoarding" />

        <img alt="img" src="/images/boarding.png" className="m-auto mb-2 max-w-xs" />
        <div className="m-auto my-5 flex flex-row justify-between">
          <AppBody pClass="Body1Bold" type="div" className="flex px-5 text-xl">
            <AppBody
              pClass="Body1Bold"
              type="span"
              className="text-md mx-2 flex h-6 w-6 flex-col justify-center rounded-full bg-black text-center text-white"
            >
              1
            </AppBody>
            {t('deposit', { ns: 'common' })}
          </AppBody>
          <AppBody pClass="Body1Bold" type="div" className="flex px-5 text-xl">
            <AppBody
              pClass="Body1Bold"
              type="span"
              className="text-md mx-2 flex h-6 w-6 flex-col justify-center rounded-full bg-black text-center text-white"
            >
              2
            </AppBody>
            {t('create_card1', { ns: 'common' })}
          </AppBody>
          <AppBody pClass="Body1Bold" type="div" className="flex px-5 text-xl">
            <AppBody
              pClass="Body1Bold"
              type="span"
              className="text-md mx-2 flex h-6 w-6 flex-col justify-center rounded-full bg-black text-center text-white"
            >
              3
            </AppBody>
            {t('start_Paying', { ns: 'common' })}
          </AppBody>
        </div>
        <button
          onClick={() => {
            navigate('/dashboard/wallet');
          }}
          className="m-auto mt-5 max-w-xs rounded-full border border-gray-3 bg-none px-5 py-1 font-bold"
          type="button"
        >
          {t('add_fund', { ns: 'common' })}
        </button>
      </div>
    );
  }
  return (
    <div className="grid flex-grow grid-cols-3 gap-0">
      <Head title="Dashboard" />
      <div className="col-span-3 flex flex-col justify-start lg:col-span-2">
        <div className="flex flex-row flex-wrap justify-between self-stretch">
          <DashboardHeader user={user} />
          <PeriodSelection setDaysFilter={setDaysFilter} />
        </div>
        <div className="grid grid-cols-2 pt-2">
          <TopMerch daysFilter={daysFilter} />
          <TopTags daysFilter={daysFilter} />
        </div>
        <TransactionsWidget className="hidden lg:flex" />
      </div>
      <DashboardSidebar daysFilter={daysFilter} />
    </div>
  );
}

export default DashboardBody;
