import { Turnstile } from '@marsidev/react-turnstile';
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../../AppRouter/history';
import AppInput from '../../../components/AppFormInputs/AppInput';
import { AppButton, AppHeader } from '../../../components/html/html';
import { getServerErrMessage, isDev, isTesting } from '../../../helpers/Utils';
import { captchaKey, loginActiveStep } from '../../../helpers/constants';
import { http } from '../../../http';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';
import {
  setActiveLoginStepAction,
  setCachedLoginFormDataAction,
} from '../../../redux/AuthReducer/AuthActions';
import '../NotRobot.css';

function LoginForm() {
  const { loaders } = useSelector((state) => state);
  const { lang } = useSelector((state) => state.appConfig);
  const { t } = useTranslation(['loginV2', 'commonV2']);
  const dispatch = useDispatch();
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);
  const [serverError, setServerError] = useState(null);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('Please Enter a Valid Email', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
    password: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const handleLogin = useCallback((values) => {
    setServerError(null);
    const formData = new FormData();

    formData.append('email', values.email);
    formData.append('password', values.password);

    http
      .post('login', formData, {
        loader: 'handleLogin',
        disableUnAuthRedirect: true,
        noToken: true,
      })
      .then((res) => {
        dispatch(
          setCachedLoginFormDataAction({
            ...values,
            smsToken: res.data.token,
            hashedMobile: res.data.mobile,
            hashedEmail: res.data.email,
          }),
        );
        dispatch(setActiveLoginStepAction(loginActiveStep.loginOTP));
      })
      .catch((err) => {
        setServerError(getServerErrMessage(err));
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: handleLogin,
    validationSchema,
  });

  const handleInputChange = useCallback((e) => {
    setServerError(null);
    formik.handleChange(e);
  }, []);

  return (
    <div className="flex h-[95%] items-center justify-center rounded-lg border bg-stone-50 px-6 py-12 shadow-lg lg:h-full lg:px-12">
      <div className="w-full">
        <AppHeader h="h3" className="mb-4 text-center">
          {t('Login')}
        </AppHeader>
        <form onSubmit={formik.handleSubmit}>
          <label className="mb-4 block">
            <span className="mb-1 block text-xl font-bold">{t('Email')}</span>
            <AppInput
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              name="email"
              type="email"
              placeholder="blue@nqood.com"
              invalid={serverError || (formik.errors.email && formik.touched.email)}
              valid={!loaders.handleLogin && !formik.errors.email && formik.touched.email}
            />
            {formik.errors.email && formik.touched.email && (
              <p className="mb-2 text-end text-sm text-danger">{formik.errors.email}</p>
            )}
          </label>

          <label className="mb-4 block">
            <span className="mb-1 block text-xl font-bold">{t('Password')}</span>
            <AppInput
              onChange={handleInputChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              name="password"
              type="password"
              placeholder="password"
              invalid={serverError || (formik.errors.password && formik.touched.password)}
              valid={!loaders.handleLogin && !formik.errors.password && formik.touched.password}
            />
            {formik.errors.password && formik.touched.password && (
              <p className="mb-2 text-end text-sm text-danger">{formik.errors.password}</p>
            )}
          </label>

          <AppButton
            className="mb-6 block text-base"
            button="link"
            onClick={() => {
              if (isDev() || isTesting()) {
                history.push('/forget-password');
              } else {
                history.push('/forget_password');
              }
            }}
          >
            {t('Forgot Password?')}
          </AppButton>

          <AppButton
            className="mb-4 w-full"
            size="lg"
            rounded="md"
            button="primary"
            type="submit"
            isLoading={loaders.handleLogin}
            disabled={!isDev() ? !isValidCaptcha : false}
          >
            {t('Sign In')}
          </AppButton>
        </form>

        {!isDev() && (
          <div id="notRobot" className="mb-4 flex justify-center">
            <Turnstile
              autoResetOnExpire
              siteKey={captchaKey}
              onError={() => {
                setIsValidCaptcha(false);
              }}
              onSuccess={() => {
                setIsValidCaptcha(true);
              }}
              options={{
                theme: 'light',
                size: 'flexible',
                language: lang,
              }}
            />
          </div>
        )}

        <div className="flex flex-col justify-center gap-1 sm:flex-row">
          <span className="text-center">{t('Don’t have an account yet?')}</span>
          <AppButton
            button="link"
            className="underline"
            onClick={() => {
              window
                .open(
                  `https://nqoodlet.com/${lang === 'en' ? 'en/book-a-demo/' : 'حجز-موعد/'}`,
                  '_blank',
                )
                .focus();
            }}
          >
            {t('Contact Us')}
          </AppButton>
        </div>

        <div className="mt-2 text-center">
          <AppButton
            bClass="ButtonHeader1"
            onClick={() => {
              history.push('/signup/quick-signup');
            }}
            className=" font-bold text-primary"
            button="link"
          >
            {t('Sign Up', { ns: 'commonV2' })}
          </AppButton>

          {(isDev() || isTesting()) && (
            <button
              type="button"
              onClick={() => {
                history.push('/complete-registration/123');
              }}
              className="mt-8 block"
            >
              Complete Registration (For Mocks)
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default LoginForm;
