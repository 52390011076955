import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppLi } from '../html/html';

function AppTabs({ tabs, onTabChange, defaultActiveTab, isDisabled, styleType = 'pill' }) {
  const { t } = useTranslation('commonV2');
  const [activeTab, setActiveTab] = useState();
  const [sharedClasses, setSharedClasses] = useState();
  const [activeClasses, setActiveClasses] = useState();
  const [inActiveClasses, setInActiveClasses] = useState();

  const getClasses = useCallback(
    (ele) => {
      return tabs.indexOf(ele) === activeTab ? activeClasses : inActiveClasses;
    },
    [activeTab, tabs],
  );

  const handleClick = useCallback(
    ({ index, item }) => {
      if (!isDisabled) {
        setActiveTab(index);
        onTabChange(item);
      }
    },
    [isDisabled],
  );

  useEffect(() => {
    setActiveTab(tabs.indexOf(defaultActiveTab) || 0);
    if (styleType === 'pill') {
      setSharedClasses('px-2 py-1 rounded-full');
      setActiveClasses('text-white bg-primary shadow-sm');
      setInActiveClasses('text-black hover:bg-gray-2');
    }
    if (styleType === 'line') {
      setSharedClasses('px-2 py-1');
      setActiveClasses('border-primary border-b-4');
      setInActiveClasses('text-black');
    }
  }, [styleType, defaultActiveTab]);

  return (
    <ul className="gap-3 lg:inline-flex">
      {tabs.map((item, index) => {
        return (
          <AppLi
            key={item.label}
            className={`${sharedClasses} ${getClasses(item)} ${
              isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            onClick={() => {
              handleClick({ index, item });
            }}
          >
            {t(item.label)}
          </AppLi>
        );
      })}
    </ul>
  );
}

export default AppTabs;
