import SingleCardDetailsCard from './SingleCardDetailsCard';

function CardDetailsCards({ cardDetails, inPage }) {
  return (
    <div className="flex flex-col gap-5 lg:flex-row">
      <div className="flex flex-col items-center gap-4 lg:flex-row lg:items-start">
        {cardDetails?.virtualCard && (
          <SingleCardDetailsCard
            inPage={inPage}
            card={cardDetails?.virtualCard}
            cardDetails={cardDetails}
          />
        )}

        {cardDetails?.physicalCard && (
          <SingleCardDetailsCard
            inPage={inPage}
            card={cardDetails?.physicalCard}
            cardDetails={cardDetails}
          />
        )}
      </div>
    </div>
  );
}

export default CardDetailsCards;
