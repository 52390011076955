import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../../../../components/Button/ActionButton';
import {TextInput} from '../../../../components/FormInputs/Inputs';
import {apiPostFormDataRequest} from '../../../../helpers/APIHelper';
import {EmailValidationPattern} from '../../../../helpers/RegexPatterns';
import useNotifications from '../../../../helpers/useNotifications';
import {AppBody} from "../../../../components/html/html";

function NonCompanyOwnerContactDetails() {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['signup', 'common']);
  const [, addNotification] = useNotifications();

  const onSubmit = (data) => {
    const form = new FormData();
    setIsSubmitting(true);
    form.append('company_name', data.company_name);
    form.append('full_name', data.full_name);
    form.append('email', data.work_email);
    form.append('job_title', data.title);
    apiPostFormDataRequest('accounts/contact', form, cookies.token)
      .then(() => {
        navigate('../contact_thanks');
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setIsSubmitting(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  return (
    <form className="pt-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-10 py-5">
        <AppBody pClass="Body2bold" type="label"
          htmlFor="full_name"
          className="col-span-2 flex flex-col lg:col-span-1"
        >
          {t('company_account.full_name')}
          <TextInput
            error={errors.full_name}
            id="full_name"
            {...register('full_name', {
              required: t('company_account.full_name_required'),
            })}
          />
        </AppBody>

        <AppBody pClass="Body2bold" type="label"
          htmlFor="title"
          className="col-span-2 flex flex-col lg:col-span-1"
        >
          {t('company_account.title')}
          <TextInput
            error={errors.title}
            id="title"
            {...register('title', {
              required: t('company_account.title_required'),
            })}
          />
        </AppBody>
      </div>

      <div className="grid grid-cols-2 gap-10 py-5">
        <AppBody pClass="Body2bold" type="label"
          htmlFor="company_name"
          className="col-span-2 flex flex-col lg:col-span-1"
        >
          {t('company_account.company_name')}
          <TextInput
            error={errors.company_name}
            id="company_name"
            {...register('company_name', {
              required: t('company_account.company_name_required'),
            })}
          />
        </AppBody>

        <AppBody pClass="Body2bold" type="label"
          htmlFor="work_email"
          className="col-span-2 flex flex-col lg:col-span-1"
        >
          {t('company_account.work_email')}
          <TextInput
            error={errors.work_email}
            id="work_email"
            {...register('work_email', {
              required: t('company_account.work_email_required'),
              pattern: {
                value: EmailValidationPattern,
                message: t('company_account.email_pattern'),
              },
            })}
          />
        </AppBody>
      </div>

      <div className="flex flex-row justify-end self-stretch pt-10">
        <ActionButton
          className="flex flex-row"
          primary
          type="submit"
          text={t('submit', { ns: 'common' })}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  );
}

export default NonCompanyOwnerContactDetails;
