import { useFormik } from 'formik';
import { objToQueryStr } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../../../AppRouter/history';
import AppInput from '../../../../components/AppFormInputs/AppInput';
import BackBtn from '../../../../components/BackBtn';
import AppSelect from '../../../../components/FormInputs/AppSelect';
import AppUpload from '../../../../components/FormInputs/AppUpload';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { getServerErrMessage } from '../../../../helpers/Utils';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import { setIsFinishFundMethodAction } from '../../../../redux/WalletReducer/WalletActions';
import AddFundMethodNote from './AddFundMethodNote';

function UploadIBANLetterContent({ handleCancel, handleBackStep }) {
  const { loaders } = useSelector((state) => state);
  const { addFundMethod } = useSelector((state) => state.wallet);
  const [showOtherSourceOfFund, setShowOtherSourceOfFund] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);

  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();

  const sourceOfFundOptions = useMemo(() => {
    return [
      { value: 'Investments', label: t('Investments') },
      { value: 'Trading Activities', label: t('Trading Activities') },
      { value: 'Retained Profits', label: t('Retained Profits') },
      { value: 'Other', label: `${t('Other [Please Specify]')}` },
    ];
  }, [t]);

  const accountTypeOptions = useMemo(() => {
    return [
      { value: 'Business Account', label: t('Business Account') },
      { value: 'Current Account', label: t('Current Account') },
      { value: 'Saving Account', label: t('Saving Account') },
      { value: 'Salary Account', label: t('Salary Account') },
      { value: 'Other', label: `${t('Other [Please Specify]')}` },
    ];
  }, [t]);

  const getCountries = useCallback(() => {
    http
      .get(`countries`, { loader: 'getCountries' })
      .then((res) => {
        const serialized = res.data.map((ele) => {
          return {
            label: ele.name,
            value: ele.code,
          };
        });
        setCountryOptions(serialized);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  });

  const handleAddFundMethod = useCallback((values) => {
    const formBody = new FormData();

    const sourcesOfFun = [...values.sourceOfFund.map((ele) => ele.value), values.otherSourceOfFund]
      .filter((ele) => ele !== 'Other')
      .join('|');

    const accountTypes = values.otherAccountType || values.accountType;

    formBody.append('fund_source', sourcesOfFun);
    formBody.append('account_type', accountTypes);

    formBody.append('bank_name', values.bankName);
    formBody.append('country_code', values.country);
    formBody.append('account_holder_name', values.accountHolderName);
    formBody.append('iban', values.iban);
    formBody.append('swift', values.swift);
    formBody.append('account_number', values.accountNumber);
    formBody.append('currency', values.currencyAccountRequirements);
    formBody.append('fileIBANLetter', values.proofOfIBANLetter);
    formBody.append('fileBankStatement', values.bankStatement);

    http
      .post('funding_method', formBody, { loader: 'handleAddFundMethod' })
      .then((res) => {
        dispatch(setIsFinishFundMethodAction(true));
        history.replace({ search: objToQueryStr({ requestNumber: res.data.id }) });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  });

  const initialValues = useMemo(() => {
    return {
      sourceOfFund: addFundMethod.fundMethodFormData?.sourceOfFund || [],
      otherSourceOfFund: addFundMethod.fundMethodFormData?.otherSourceOfFund || '',
      accountType: addFundMethod.fundMethodFormData?.accountType || '',
      otherAccountType: addFundMethod.fundMethodFormData?.otherAccountType || '',
      accountHolderName: addFundMethod.fundMethodFormData?.accountHolderName || '',
      iban: addFundMethod.fundMethodFormData?.iban || '',
      bankName: addFundMethod.IBANData?.bank.bank_name || '',
      country:
        countryOptions.find((ele) => ele.value === addFundMethod.IBANData?.iso_alpha3)?.value || '',
      accountNumber: addFundMethod.IBANData?.bank_account || '',
      currencyAccountRequirements: '',
      swift: '',
      proofOfIBANLetter: '',
      bankStatement: '',
    };
  }, [addFundMethod, countryOptions]);

  const validationSchema = yup.object().shape({
    sourceOfFund: yup.array().min(1, t('This field is required!', { ns: 'commonV2' })),
    otherSourceOfFund: yup.string().when('sourceOfFund', {
      is: (values) => {
        const hasOther = values.find((ele) => ele.value === 'Other');
        return Boolean(hasOther);
      },
      then: () => yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    }),
    accountType: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    otherAccountType: yup.string().when('accountType', {
      is: 'Other',
      then: () => yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    }),
    accountHolderName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    iban: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    bankName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    country: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    accountNumber: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    currencyAccountRequirements: yup
      .string()
      .required(t('This field is required!', { ns: 'commonV2' })),
    swift: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    proofOfIBANLetter: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    bankStatement: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const onSubmit = useCallback((values) => {
    handleAddFundMethod(values);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const otherOption = formik.values.sourceOfFund.find((ele) => ele.value === 'Other');
    setShowOtherSourceOfFund(Boolean(otherOption));
  }, [formik.values.sourceOfFund]);

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div>
      <BackBtn
        className="mb-4"
        onClick={() => {
          handleBackStep(0);
        }}
      />
      <AppHeader h="h4" className="mb-4">
        {t('Add Funding Method')}
      </AppHeader>

      <div className="mb-4 px-1 xl:w-[80%]">
        <AddFundMethodNote />
      </div>

      <form>
        <div className="mb-6 grid grid-cols-1 gap-4 px-1 md:grid-cols-2 xl:w-[80%]">
          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Source of Fund')}
            </AppBody>

            <AppSelect
              isMulti
              className="text-sm"
              mobileSelect={false}
              name="sourceOfFund"
              value={formik.values.sourceOfFund}
              onMenuClose={() => {
                formik.setFieldTouched(`sourceOfFund`);
              }}
              options={sourceOfFundOptions}
              onChange={(option) => {
                formik.setFieldValue(`sourceOfFund`, option);
              }}
            />
            {formik.errors.sourceOfFund && formik.touched.sourceOfFund && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.sourceOfFund, { ns: 'commonV2' })}
              </AppBody>
            )}
            {showOtherSourceOfFund && (
              <AppInput
                className="mt-2 rounded-xl text-sm"
                placeholder={t('Enter Here', { ns: 'commonV2' })}
                size="md"
                type="text"
                name="otherSourceOfFund"
                value={formik.values.otherSourceOfFund}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            )}
            {formik.errors.otherSourceOfFund && formik.touched.otherSourceOfFund && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.otherSourceOfFund, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Account Type')}
            </AppBody>

            <AppSelect
              isClearable
              className="w-full text-sm"
              mobileSelect
              name="accountType"
              value={accountTypeOptions.find((ele) => ele.value === formik.values.accountType)}
              onMenuClose={() => {
                formik.setFieldTouched(`accountType`);
              }}
              options={accountTypeOptions}
              onChange={(option) => {
                formik.setFieldValue(`accountType`, option?.value || '');
              }}
            />
            {formik.errors.accountType && formik.touched.accountType && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.accountType, { ns: 'commonV2' })}
              </AppBody>
            )}
            {formik.values.accountType === 'Other' && (
              <AppInput
                className="mt-2 rounded-xl text-sm"
                placeholder={t('Enter Here', { ns: 'commonV2' })}
                size="md"
                type="text"
                name="otherAccountType"
                value={formik.values.otherAccountType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            )}
            {formik.errors.otherAccountType && formik.touched.otherAccountType && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.otherAccountType, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Account Holder Name')}
            </AppBody>

            <AppInput
              className="rounded-xl"
              size="md"
              type="text"
              name="accountHolderName"
              value={formik.values.accountHolderName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.accountHolderName && formik.touched.accountHolderName && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.accountHolderName, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('IBAN', { ns: 'commonV2' })}
            </AppBody>

            <AppInput
              className="rounded-xl"
              size="md"
              type="text"
              name="iban"
              value={formik.values.iban}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.iban && formik.touched.iban && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.iban, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Bank Name', { ns: 'commonV2' })}
            </AppBody>

            <AppInput
              className="rounded-xl"
              size="md"
              type="text"
              name="bankName"
              value={formik.values.bankName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.bankName && formik.touched.bankName && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.bankName, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Country', { ns: 'commonV2' })}
            </AppBody>

            <AppSelect
              isLoading={loaders.getCountries}
              className="text-sm"
              mobileSelect={false}
              name="country"
              value={countryOptions.find((ele) => ele.value === formik.values.country)}
              onMenuClose={() => {
                formik.setFieldTouched(`country`);
              }}
              options={countryOptions}
              onChange={(option) => {
                formik.setFieldValue(`country`, option.value);
              }}
            />

            {formik.errors.country && formik.touched.country && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.country, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label className="col-span-1 lg:col-span-2">
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Account Number', { ns: 'commonV2' })}
            </AppBody>

            <AppInput
              className="rounded-xl"
              size="md"
              type="text"
              name="accountNumber"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.accountNumber && formik.touched.accountNumber && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.accountNumber, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Currency Account Requirements')}
            </AppBody>

            <AppInput
              className="rounded-xl"
              size="md"
              type="text"
              name="currencyAccountRequirements"
              value={formik.values.currencyAccountRequirements}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.currencyAccountRequirements &&
              formik.touched.currencyAccountRequirements && (
                <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                  {t(formik.errors.currencyAccountRequirements, { ns: 'commonV2' })}
                </AppBody>
              )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Swift', { ns: 'commonV2' })}
            </AppBody>

            <AppInput
              className="rounded-xl"
              size="md"
              type="text"
              name="swift"
              value={formik.values.swift}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.swift && formik.touched.swift && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.swift, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>
        </div>

        <div className="mb-6">
          <AppHeader h="h6" className="mb-1">
            {t('Proof of the IBAN letter')}
          </AppHeader>
          <AppBody pClass="Body2Medium" className="mb-4 text-gray-6">
            {t(
              'Please upload a valid proof of IBAN, showing both the IBAN number and the company name associated with that account.',
            )}
          </AppBody>

          <AppUpload
            isLoading={false}
            width="100%"
            height={165}
            showAccepted={false}
            onChange={({ FILE }) => {
              formik.setFieldValue('proofOfIBANLetter', FILE);
            }}
          />
          {formik.errors.proofOfIBANLetter && formik.touched.proofOfIBANLetter && (
            <AppBody pClass="Caption2Medium" type="span" className="text-danger">
              {t(formik.errors.proofOfIBANLetter, { ns: 'commonV2' })}
            </AppBody>
          )}
        </div>

        <div>
          <AppHeader h="h6" className="mb-1">
            {t('Bank Statement For The Last Six Months')}
          </AppHeader>
          <AppBody pClass="Body2Medium" className="mb-4 text-gray-6">
            {t(
              'Please upload a valid bank statement for the last six months, showing both the IBAN number and the company name associated with that account.',
            )}
          </AppBody>

          <AppUpload
            isLoading={false}
            width="100%"
            height={165}
            showAccepted={false}
            onChange={({ FILE }) => {
              formik.setFieldValue('bankStatement', FILE);
            }}
          />
          {formik.errors.bankStatement && formik.touched.bankStatement && (
            <AppBody pClass="Caption2Medium" type="span" className="text-danger">
              {t(formik.errors.bankStatement, { ns: 'commonV2' })}
            </AppBody>
          )}
        </div>
      </form>

      <div className="mt-6 flex justify-end gap-4 lg:gap-8">
        <AppButton button="link" onClick={handleCancel} size="lg">
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          disabled={!formik.isValid}
          isLoading={loaders.handleAddFundMethod}
          type="button"
          size="lg"
          className="lg:min-w-[170px]"
          button="black"
          onClick={formik.handleSubmit}
        >
          {t('Submit', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default UploadIBANLetterContent;
