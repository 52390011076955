import { useCallback, useEffect, useRef, useState } from 'react';

export const useDropdown = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [showOnTop, setShowOnTop] = useState(false);
  const wrapperRef = useRef(null);

  return {
    collapsed,
    setCollapsed,
    showOnTop,
    setShowOnTop,
    wrapperRef,
  };
};
function TableDropDown({
  children,
  collapsed,
  header,
  wrapperRef,
  innerWrapperClassName,
  setCollapsed,
}) {
  const [showOnTop, setShowOnTop] = useState(false);
  const innerWrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setCollapsed(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const observerCallback = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (!collapsed && !entry.isIntersecting) {
          setShowOnTop(true);
        }
      });
    },
    [collapsed],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      rootMargin: '0px',
      threshold: 1,
    });

    if (innerWrapperRef.current) {
      observer.observe(innerWrapperRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [wrapperRef, innerWrapperRef, observerCallback]);

  return (
    <div className="relative flex flex-grow flex-col">
      {header}
      <div
        style={{ minWidth: '200px' }}
        ref={innerWrapperRef}
        className={`absolute flex flex-grow flex-col ${
          showOnTop ? 'bottom-6 rounded-t-md' : 'top-6 rounded-b-md'
        } ${
          collapsed ? 'hidden' : ''
        } animate-animated animate-faster animate-fadeIn items-start ltr:text-left rtl:text-right ${
          innerWrapperClassName ?? ''
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default TableDropDown;
