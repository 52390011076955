import { getURLParams } from 'javascript-functions';
import { history } from '../../AppRouter/history';
import { devLog, getServerErrMessage, toIntAndDecimalNumber } from '../../helpers/Utils';
import { createNewCardSteps } from '../../helpers/constants';
import { http } from '../../http';
import { showToasterAction } from '../AppToastersReducer/AppToastersActions';
import { setActiveCreateNewCardStepAction } from '../CreateNewCardReducer/CreateNewCardActions';
import store from '../store';

export const getCardListAction = () => (dispatch) => {
  if (+getURLParams('userDetailsId') || +getURLParams('createCardUserId')) {
    devLog(
      `Getting User: [${
        +getURLParams('userDetailsId') || +getURLParams('createCardUserId')
      }] Card List...`,
    );
  } else {
    devLog('Getting Logged In User Card List...');
  }

  const loggedInUserId = store.getState().auth.user.id;
  const userId =
    +getURLParams('userDetailsId') || +getURLParams('createCardUserId') || loggedInUserId;

  http
    .get(`cardmanager/cards/${userId}`, {
      loader: 'getCardListAction',
      params: {
        'with[0]': 'cardproduct',
      },
    })
    .then((res) => {
      dispatch({
        type: 'ADD_CARD_LIST',
        payload: res.data,
      });
      const isInCreateNewCardPage = window.location.pathname.split('/').includes('create-new-card');

      if (!res.data?.length) {
        devLog('User has No Cards!');
        if (!isInCreateNewCardPage) {
          history.push(`/dashboard/my_cards/create-new-card`);
        }
        dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.noUserCards));
      } else {
        devLog('User has Cards!');
        dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.verifyAccount));
      }
    });
};

export const clearCardListAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_CARD_LIST',
  });
};

export const selectSingleCardAction =
  ({ virtualCard, physicalCard }) =>
  (dispatch) => {
    dispatch({
      type: 'SELECT_SINGLE_CARD',
      payload: { virtualCard, physicalCard },
    });
  };

export const clearSingleCardAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SELECTED_SINGLE_CARD',
  });
};

export const getSingleColumnCardBalanceAction = (card) => (dispatch) => {
  http
    .get(`cardmanager/cards/balance/${card.id}`, {
      loader: `getSingleColumnCardBalanceAction[${card.id}]`,
    })
    .then((response) => {
      dispatch({
        type: 'UPDATE_SINGLE_COLUMN_CARD_BALANCE',
        payload: {
          card,
          balance: {
            amount: toIntAndDecimalNumber(response.data?.amount || 0).integerPart,
            currency: response.data?.currency,
          },
        },
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: getServerErrMessage(err),
        }),
      );
    });
};

export const updateSingleColumnCardAction = (card) => (dispatch) => {
  devLog('Updating single [Column Card] data');

  dispatch({
    type: 'UPDATE_SINGLE_COLUMN_CARD',
    payload: card,
  });
};
