import React, { useMemo } from 'react';
import { cardStatusKeys } from '../../../helpers/constants';

function SingleCardProgramStatus({ cardProgram, className }) {
  const isSuspendedCardProgram = useMemo(() => {
    return cardStatusKeys.SUSPENDED === cardProgram.status.toUpperCase();
  }, [cardProgram]);

  const isDeactivatedCardProgram = useMemo(() => {
    return cardStatusKeys.DEACTIVATED === cardProgram.status.toUpperCase();
  }, [cardProgram]);

  if (isSuspendedCardProgram) {
    return <i className={`fa-kit fa-wallet-suspend ${className}`} />;
  }

  if (isDeactivatedCardProgram) {
    return <i className={`fa-kit fa-wallet-deactivate ${className}`} />;
  }
}

export default SingleCardProgramStatus;
