import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { inPages } from '../../helpers/constants';
import { http } from '../../http';
import { hideModalAction } from '../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import { getSingleTransactionDetailsAction } from '../../redux/CardDetailsReducer/CardDetailsActions';
import { getSingleWalletTransactionDetailsAction } from '../../redux/WalletReducer/WalletActions';
import AppRadioInput from '../FormInputs/AppRadioInput';
import AppUpload from '../FormInputs/AppUpload';
import { AppBody, AppButton } from '../html/html';

function AddAttachmentPopup({ transactionDetails, docTypes, inPage }) {
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();
  const { loaders } = useSelector((state) => state);

  const initialValues = {
    file: '',
    docType: '',
  };

  const handleAddAttachment = (formBody) => {
    http
      .post(`transactions/receipts`, formBody, {
        loader: 'handleAddAttachment',
      })
      .then(() => {
        dispatch(hideModalAction());
        dispatch(
          showToasterAction({ type: 'success', message: t('Attachment was added successfully!') }),
        );
        if (inPage === inPages.myCards) {
          dispatch(getSingleTransactionDetailsAction(transactionDetails.id));
        }
        if (inPage === inPages.wallet) {
          dispatch(getSingleWalletTransactionDetailsAction(transactionDetails.id));
        }
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  };

  const onSubmit = (values) => {
    const formBody = new FormData();
    formBody.append('transaction_id', transactionDetails.id);
    formBody.append('receipts[]', values.file);
    formBody.append('doc_type_id', values.docType);

    handleAddAttachment(formBody);
  };

  const validationSchema = yup.object({
    file: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    docType: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema });
  return (
    <div className="w-[400px] rounded-lg bg-white p-8">
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-6">
          <div className="flex items-center gap-2">
            <AppBody pClass="Caption1Bold" className="mb-2">
              {t('Upload Attachment')}
            </AppBody>
            {formik.errors.file && (
              <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">
                ({formik.errors.file})
              </AppBody>
            )}
          </div>
          <AppUpload
            isLoading={loaders.handleAddAttachment}
            showAccepted={false}
            uploadedFilePreview={null}
            height={130}
            width="100%"
            isDisabled={false}
            onChange={({ FILE }) => {
              formik.setFieldValue('file', FILE);
            }}
          />
        </div>

        <div className="mb-4">
          <div className="flex items-center gap-2">
            <AppBody pClass="Caption1Bold" className="mb-3">
              {t('Select Type File')}
            </AppBody>
            {formik.errors.docType && (
              <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">
                ({formik.errors.docType})
              </AppBody>
            )}
          </div>
          <div className="grid grid-cols-2 gap-6">
            {docTypes?.map((ele, i) => {
              return (
                <div
                  key={ele.id}
                  className="flex items-center rounded-lg border border-gray-3 bg-stone-50 px-3 "
                >
                  <AppRadioInput
                    id={`radio-${i}`}
                    name="docType"
                    value={ele.id}
                    onChange={(e) => {
                      formik.setFieldValue('docType', e.target.value);
                    }}
                  />
                  <label htmlFor={`radio-${i}`} className="ms-2 w-full py-2 text-xs font-medium">
                    {t(ele.title)}
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex items-center justify-end gap-4">
          <AppButton
            button="link"
            onClick={() => {
              dispatch(hideModalAction());
            }}
          >
            {t('Cancel', { ns: 'commonV2' })}
          </AppButton>
          <AppButton
            isLoading={loaders.handleAddAttachment}
            loadingLabel={t('Uploading', { ns: 'commonV2' })}
            disabled={!formik.isValid}
            button="black"
            type="submit"
          >
            {t('Upload', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </form>
    </div>
  );
}

export default AddAttachmentPopup;
