import { randomId } from 'javascript-functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isFilePDF } from '../../helpers/Utils';

import { PrintTitle } from '../Titles';
import SingleAttachmentFilePrint from './SingleAttachmentFilePrint';

function RequestAttachmentsPrint({ requestDetails }) {
  const { t } = useTranslation('cardsV2');

  return (
    <div className="w-full">
      {/* Header */}
      {requestDetails?.attachments[0]?.path && (
        <PrintTitle className="mb-3">{t('Attachment Uploaded')}</PrintTitle>
      )}

      {/* Looping Content */}
      {requestDetails.attachments[0]?.path && (
        <SingleAttachmentFilePrint
          key={randomId()}
          isPDF={isFilePDF(requestDetails.attachments[0])}
          attachment={requestDetails.attachments[0]}
          noTitle
        />
      )}
    </div>
  );
}

export default RequestAttachmentsPrint;
