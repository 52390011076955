import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import NoUserCardImgOnly from '../../../../assets/svg/Card.svg';
import NoUserCardImg from '../../../../assets/svg/NoUserCardImg.svg';
import {AppBody, AppButton, AppHeader} from '../../../../components/html/html';
import {createNewCardSteps} from '../../../../helpers/constants';
import {setActiveCreateNewCardStepAction} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';

function NoUserCards() {
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const steps = useMemo(() => {
    return [
      {
        label: t('Create Card'),
      },
      {
        label: t('Request Money'),
      },
      {
        label: t('Start Paying'),
      },
    ];
  }, [t]);

  return (
    <div className="mx-auto mt-[15vh] pb-5">
      <div className="grid gap-8">
        <div className="text-center">
          <img className="mx-auto hidden lg:block" alt="Nqood-Card" src={NoUserCardImg} />
          <img className="mx-auto block lg:hidden" alt="Nqood-Card" src={NoUserCardImgOnly} />
        </div>

        <AppBody pClass="Body2Medium" className="text-center text-gray-6">
          {t("You Don't Have Any Card, Please Create One")}
        </AppBody>
        <div className="flex flex-col flex-wrap items-start justify-center gap-8 sm:flex-row sm:items-center">
          {steps.map((ele, index) => {
            return (
              <div key={ele.label} className="flex items-center gap-2">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-black text-xl font-extrabold text-white">
                  {index + 1}
                </div>
                <AppHeader h="h5">{ele.label}</AppHeader>
              </div>
            );
          })}
        </div>

        <AppButton
          size="md"
          className="mx-auto w-32"
          button="black"
          onClick={() => {
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.verifyAccount));
          }}
        >
          {t('Create Card', { ns: 'cardsV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default NoUserCards;
