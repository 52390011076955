import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BackBtn from '../../components/BackBtn/index';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../components/html/html';
import { uploadDocumentSteps } from '../../helpers/constants';
import {
  setActiveDocumentStepAction,
  setSingleDocumentCardDetailsAction,
} from '../../redux/UploadDocumentsReducer/UploadDocumentsActions';
import SingleDocumentUploadInput from './SingleDocumentUploadInput';

function DocumentCardDetails() {
  const { singleDocumentCardDetails, documentCards } = useSelector(
    (state) => state.uploadDocuments,
  );
  const { t } = useTranslation('uploadDocuments');
  const dispatch = useDispatch();

  const hasRejectedDocuments = useMemo(() => {
    return Boolean(
      singleDocumentCardDetails.documents.filter((ele) => Boolean(ele.reject_reason)).length,
    );
  }, [singleDocumentCardDetails, documentCards]);

  const hasUploadedDocuments = useMemo(() => {
    return Boolean(singleDocumentCardDetails.documents.filter((ele) => ele.path).length);
  }, [singleDocumentCardDetails, documentCards]);

  const requiredDocumentsToUpload = useMemo(() => {
    return singleDocumentCardDetails.documents.length;
  }, [singleDocumentCardDetails, documentCards]);

  const uploadedDocumentsLength = useMemo(() => {
    return singleDocumentCardDetails.documents.filter((ele) => ele.path).length;
  }, [singleDocumentCardDetails, documentCards]);

  const statusColor = useMemo(() => {
    if (hasRejectedDocuments) {
      return 'bg-danger';
    }
    if (!hasUploadedDocuments) {
      return 'bg-primary';
    }
    if (hasUploadedDocuments && requiredDocumentsToUpload > uploadedDocumentsLength) {
      return 'bg-warn';
    }
    if (hasUploadedDocuments && requiredDocumentsToUpload === uploadedDocumentsLength) {
      return 'bg-success';
    }
    return 'bg-gray';
  }, [
    hasUploadedDocuments,
    requiredDocumentsToUpload,
    uploadedDocumentsLength,
    hasRejectedDocuments,
  ]);

  const handleBack = useCallback(() => {
    dispatch(setActiveDocumentStepAction(uploadDocumentSteps.documentList));
    dispatch(setSingleDocumentCardDetailsAction(null));
  }, []);

  return (
    <div className="p-5 lg:p-28">
      <AppBody pClass="Body1Bold" className="mb-4">
        <BackBtn className="mb-4" onClick={handleBack} />
      </AppBody>

      <div className="relative mb-12 overflow-hidden rounded-xl bg-white p-5">
        <div className="flex items-center justify-between">
          <div className={`absolute start-0 top-0 h-full w-4 ${statusColor}`} />
          <div className="grid gap-1 ps-8">
            <AppHeader h="h4">{singleDocumentCardDetails.name}</AppHeader>
            <AppHeader h="h5" className="text-gray-6">
              {singleDocumentCardDetails.entity_type}-{singleDocumentCardDetails.entity_number}
            </AppHeader>
            <AppHeader h="h5" className="text-gray-6">
              {singleDocumentCardDetails.relation}
            </AppHeader>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <AppHeader h="h5" className="mb-2 text-primary">
          {t('Uploading Documents')}
        </AppHeader>
        <AppBody pClass="Body1Bold" className="text-gray-6">
          {t(
            'If you need any further details about the required documents please refer to this link',
          )}
          <a href="https://nqoodlet.com/en/faq/dcouments" target="_blank" rel="noreferrer">
            <AppIcon
              className="fa-regular fa-arrow-up-right-from-square ms-2 text-primary"
              iClass="LargeFont"
            />
          </a>
        </AppBody>
      </div>

      <div className="grid gap-4">
        {singleDocumentCardDetails.documents.map((ele) => {
          return <SingleDocumentUploadInput key={ele.id} document={ele} />;
        })}
      </div>

      <div className="mt-6 text-center">
        <AppButton
          onClick={handleBack}
          button="primary"
          bClass="Button1Bold"
          size="lg"
          rounded="md"
          className="w-full lg:w-2/6"
        >
          {t('Save', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default DocumentCardDetails;
