import React from 'react';
import { Outlet } from 'react-router-dom';
import Head from '../../Head';

function Signup() {
  return (
    <>
      <Head title="Signup" />
      <Outlet />
    </>
  );
}

export default Signup;
