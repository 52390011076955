import { dummyArr } from 'javascript-functions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import AppNoData from '../../../../../components/AppNoData';
import BackBtn from '../../../../../components/BackBtn';
import { AppBody, AppButton, AppHeader } from '../../../../../components/html/html';
import { cardStatusKeys } from '../../../../../helpers/constants';
import {
  addTransferToDataAction,
  getWalletCardProgramListAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import NqoodCardProgram from '../../CardProgramList/NqoodCardProgram';
import CardProgramFrom from '../CardProgramFrom';

function SelectCardProgramContent({ handleBackStep, handleCancel, onNext }) {
  const { loaders } = useSelector((state) => state);
  const { walletCardPrograms, actions } = useSelector((state) => state.wallet);
  const [toCardPrograms, setToCardPrograms] = useState([]);

  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWalletCardProgramListAction());
  }, []);

  useEffect(() => {
    if (walletCardPrograms?.length) {
      const activeCardPrograms = walletCardPrograms.filter((ele) => {
        return ele.status === cardStatusKeys.ACTIVE && ele.id !== actions.transferFromData.id;
      });
      setToCardPrograms(activeCardPrograms);
    }
  }, [walletCardPrograms, actions]);

  return (
    <div>
      <BackBtn
        className="mb-4"
        onClick={() => {
          handleBackStep(0);
        }}
      />

      <AppHeader h="h4" className="mb-6">
        {t('Select the Card program')}
      </AppHeader>

      <CardProgramFrom fromTransfer />

      <div>
        <AppBody pClass="Body1Bold" className="mb-2">
          {t('To', { ns: 'commonV2' })}
        </AppBody>
        <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
          {t('Select the card program that you need to transfer the money to')}
        </AppBody>
      </div>

      <div className="mb-6 grid grid-cols-1 gap-4 p-1 lg:grid-cols-3 2xl:grid-cols-4">
        {!loaders.getWalletCardProgramListAction &&
          toCardPrograms?.map((ele) => {
            return (
              <NqoodCardProgram
                onClick={() => {
                  dispatch(addTransferToDataAction(ele));
                }}
                fromActions
                actionSelect
                key={ele.id}
                cardProgram={ele}
              />
            );
          })}

        {loaders.getWalletCardProgramListAction &&
          dummyArr(4).map((_, i) => {
            return (
              <div key={JSON.stringify(i)}>
                <Skeleton height={130} />
              </div>
            );
          })}
      </div>

      {!loaders.getWalletCardProgramListAction && !toCardPrograms?.length && (
        <div className="mb-2">
          <AppNoData />
        </div>
      )}

      <div className="flex justify-end gap-8">
        <AppButton button="link" onClick={handleCancel} size="md">
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          size="md"
          className="min-w-[150px]"
          disabled={!actions.transferToData}
          onClick={() => {
            onNext({ stepIndex: 1 });
          }}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default SelectCardProgramContent;
