import { getURLParams, objToQueryStr } from 'javascript-functions';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../../../AppRouter/history';
import AppSpinner from '../../../../components/AppSpinner';
import AppTabs2 from '../../../../components/AppTabs2';
import BackBtn from '../../../../components/BackBtn';
import { AppBody, AppHeader } from '../../../../components/html/html';
import { userRoles, usersStatus } from '../../../../helpers/constants';
import { initializeCardSelection } from '../../../../helpers/Utils';
import usePermissions from '../../../../hooks/usePermissions';
import {
  clearSingleUserCardAction,
  getSingleUserCardsAction,
  getSingleUserDetailsAction,
  selectSingleUserCardAction,
  setActiveUserDetailsTabIndexAction,
} from '../../../../redux/UsersReducer/UsersActions';
import SingleUserCardDetails from './SingleUserCardDetails';
import SingleUserStatusBadge from './SingleUserStatusBadge';
import UserCardsDropDownContent from './UserCardsDropDownContent';
import UserPersonalInformationContent from './UserPersonalInformationContent';
import UsersAllTransactions from './UsersAllTransactions';

function SingleUserDetails() {
  const userCan = usePermissions();
  const { loaders, appConfig } = useSelector((state) => state);
  const { singleUserDetails, activeUserDetailsTabIndex, singleUserSelectedCard } = useSelector(
    (state) => state.users,
  );
  const { t } = useTranslation('usersV2');
  const { userId } = useParams();
  const dispatch = useDispatch();

  const isURLHasCardIds = useMemo(() => {
    return getURLParams('virtualCardId');
  }, []);

  const userDetailsTabsIds = useMemo(() => {
    return {
      PersonalInformation: 'PersonalInformation',
      AllTransactions: 'AllTransactions',
      UserCards: 'UserCards',
    };
  }, []);

  const showUserNameAndStatus = useMemo(() => {
    const isMobileAndUserCardsActive = appConfig.isMobile && activeUserDetailsTabIndex !== 2;
    return isMobileAndUserCardsActive || !appConfig.isMobile;
  }, [appConfig.isMobile, activeUserDetailsTabIndex]);

  const handleBackToUsersList = useCallback(() => {
    const usersListParams = JSON.parse(getURLParams('usersListParams'));
    history.replace(`/dashboard/users?${objToQueryStr(usersListParams)}`);
  }, []);

  const userDetailsTabs = useMemo(() => {
    const isAuditor = singleUserDetails?.roles?.includes(userRoles.Observer);
    const isPending = singleUserDetails?.status === usersStatus.Pending;

    return [
      {
        id: userDetailsTabsIds.PersonalInformation,
        label: t('Personal Information'),
        content: <UserPersonalInformationContent handleBackToUsersList={handleBackToUsersList} />,
        show: true,
      },
      {
        id: userDetailsTabsIds.AllTransactions,
        label: t('All Transactions'),
        content: <UsersAllTransactions handleBackToUsersList={handleBackToUsersList} />,
        show: !isAuditor && !isPending,
      },
      {
        // ! Index (Order) here is important <<<<<<<<<<<<<<<<<<<
        id: userDetailsTabsIds.UserCards,
        label: t('User Cards'),
        content: <SingleUserCardDetails handleBackToUsersList={handleBackToUsersList} />,
        dropDown: <UserCardsDropDownContent />,
        isOpen: Boolean(singleUserSelectedCard),
        show: userCan('list_account_cards') && !isAuditor && !isPending,
      },
    ];
  }, [singleUserSelectedCard, singleUserDetails, handleBackToUsersList, t]);

  const handleSingleUserTabChange = useCallback(
    (tabIndex) => {
      history.push({ search: null });
      dispatch(setActiveUserDetailsTabIndexAction(tabIndex));
    },
    [singleUserSelectedCard, appConfig.isMobile],
  );

  useEffect(() => {
    dispatch(getSingleUserDetailsAction({ userId }));
    dispatch(getSingleUserCardsAction({ userId }));
  }, [appConfig.lang]);

  useEffect(() => {
    // If URL has card IDs Params, Select Card from DDL in Side Menu
    if (isURLHasCardIds) {
      dispatch(setActiveUserDetailsTabIndexAction(2)); // User Cards Index

      const { virtualCard, physicalCard } = initializeCardSelection();
      dispatch(selectSingleUserCardAction({ virtualCard, physicalCard }));
    } else {
      dispatch(setActiveUserDetailsTabIndexAction(0));
    }
  }, [isURLHasCardIds]);

  useEffect(() => {
    return () => {
      dispatch(clearSingleUserCardAction());
    };
  }, []);

  return (
    <div className="my-2 h-[98%] overflow-y-auto rounded-lg border-gray-5 lg:my-0 lg:border">
      {loaders.getSingleUserDetailsAction && (
        <div className="h-[90%]">
          <AppSpinner />
        </div>
      )}

      {!loaders.getSingleUserDetailsAction && singleUserDetails && (
        <div className="flex h-full flex-col gap-8 lg:flex-row lg:gap-2">
          {/* ============== Left Side ============== */}
          <div className="flex flex-col gap-7 lg:w-[30%] lg:gap-11 lg:overflow-y-auto lg:ps-7 lg:pt-7 xl:w-[25%] 2xl:w-[20%]">
            {showUserNameAndStatus && (
              <div className="order-2 md:order-1">
                <AppHeader h="h4">
                  {singleUserDetails.first_name} {singleUserDetails.last_name}
                </AppHeader>
                <AppBody pClass="Body1Bold" className="text-gray-6">
                  {singleUserDetails.job_title}
                </AppBody>
                <div className="mt-1 max-w-28">
                  <SingleUserStatusBadge
                    userDetails={singleUserDetails}
                    isLoading={loaders[`changeUserStatusAction[${singleUserDetails.id}]`]}
                  />
                </div>
              </div>
            )}

            <div className="order-1 md:order-2">
              <div className="lg:hidden">
                <BackBtn className="mb-4" label={t('All Users')} onClick={handleBackToUsersList} />
              </div>

              <AppTabs2
                key={singleUserSelectedCard}
                customTranslateY={activeUserDetailsTabIndex === 2 ? '-translate-y-[5vh]' : null}
                tabs={userDetailsTabs}
                defaultActiveTabIndex={activeUserDetailsTabIndex}
                onTabChange={handleSingleUserTabChange}
              />
            </div>
          </div>

          {/* ============== Right Side ============== */}
          <div className="lg:w-[80%] lg:overflow-y-auto">
            <div className="h-full">{userDetailsTabs[activeUserDetailsTabIndex]?.content}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleUserDetails;
