import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppButton } from '../../../../../../components/html/html';
import {
  handleApproveRejectRequestAction,
  setSelectedRejectionReasonAction,
  setWillingToRejectRequestAction,
} from '../../../../../../redux/WalletReducer/WalletActions';

function RequestActions({ requestDetails }) {
  const { loaders, appConfig } = useSelector((state) => state);
  const { willingToRejectRequest, selectedRejectionReason, otherRejectionReason } = useSelector(
    (state) => state.wallet,
  );
  const { t } = useTranslation('commonV2');
  const dispatch = useDispatch();

  return (
    <div
      className={`flex items-center gap-2 ${appConfig.lang === 'en' ? 'justify-end' : ''}`}
      style={{ direction: 'ltr' }}
    >
      {willingToRejectRequest && (
        <AppButton
          button="link"
          disabled={loaders.handleApprove || loaders.handleReject}
          onClick={() => {
            dispatch(setWillingToRejectRequestAction(false));
            dispatch(setSelectedRejectionReasonAction(null));
          }}
        >
          {t('Cancel')}
        </AppButton>
      )}

      <AppButton
        className="min-w-[80px] font-bold"
        isLoading={loaders.handleReject}
        disabled={
          loaders.handleApprove ||
          loaders.handleReject ||
          (willingToRejectRequest && !selectedRejectionReason) ||
          (selectedRejectionReason?.value === 'Other' && !otherRejectionReason)
        }
        loadingLabel={t('Rejecting...')}
        button="danger"
        onClick={() => {
          if (willingToRejectRequest) {
            dispatch(
              handleApproveRejectRequestAction({ requestId: requestDetails.id, approve: false }),
            );
          } else {
            dispatch(setWillingToRejectRequestAction(true));
          }
        }}
      >
        {t('Reject')}
      </AppButton>

      {!willingToRejectRequest && (
        <AppButton
          className="min-w-[80px] font-bold"
          isLoading={loaders.handleApprove}
          disabled={loaders.handleApprove || loaders.handleReject}
          loadingLabel={t('Approving...')}
          button="success"
          onClick={() => {
            dispatch(
              handleApproveRejectRequestAction({ requestId: requestDetails.id, approve: true }),
            );
          }}
        >
          {t('Approve')}
        </AppButton>
      )}
    </div>
  );
}

export default RequestActions;
