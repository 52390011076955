import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import ReactCodeInput from 'react-verification-code-input';
import Timer from '../../components/Timer';
import {HeadlineCenterAlways} from '../../components/Titles';
import {apiPostFormDataRequest} from '../../helpers/APIHelper';
import useNotifications from '../../helpers/useNotifications';
import Head from '../Head';
import {AppBody} from "../../components/html/html";

function ResetVerifyPhone() {
  const { t } = useTranslation(['reset', 'common']);
  const params = useParams();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errored, setErrored] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, addNotification] = useNotifications();
  const [smsToken, setSmsToken] = useState(null);
  const [tokenError, setTokenError] = useState('');
  const [complete, setComplete] = useState(false);
  const navigate = useNavigate();
  const codeRef = useRef();

  function clear() {
    codeRef.current.state.values[0] = '';
    codeRef.current.state.values[1] = '';
    codeRef.current.state.values[2] = '';
    codeRef.current.state.values[3] = '';
    codeRef.current.state.values[4] = '';
  }
  const sendOtp = () => {
    clear();
    setIsLoading(true);
    const form = new FormData();
    form.append('reset_token', params.token);
    apiPostFormDataRequest('phone/verify', form)
      .then((response) => {
        setSmsToken(response.token);
        setErrored(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrored(true);
        setIsLoading(false);
        setTokenError((err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }));
      });
  };

  useEffect(() => {
    sendOtp();
  }, []);

  const handleCompletion = (code) => {
    setComplete(true);

    const registrationForm = new FormData();
    setIsSubmitting(true);
    registrationForm.append('sms_token', smsToken);
    registrationForm.append('code', code);
    registrationForm.append('reset_token', params.token);

    apiPostFormDataRequest('otp/verify', registrationForm)
      .then((response) => {
        navigate(`/reset_password/${params.token}`);
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setComplete(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="flex h-full items-center justify-center">
      <div className="m-auto flex w-full flex-col lg:w-6/12">
        <Head title="Reset Password" />
        <div className="m-auto flex max-w-md  flex-col text-center">
          <HeadlineCenterAlways>{t('headline')}</HeadlineCenterAlways>
          <AppBody type="span" pClass="Body2Regular" className="pt-2 text-center text-gray-6">{t('subtitle')}</AppBody>
          <div className="text-md text-gray-6">
            {isLoading && <Skeleton containerClassName="pt-5" className="pt-5" count={3} />}
            {!isLoading && errored && <span className="text-red-700">{tokenError}</span>}
            {!isLoading && !errored && (
              <div className="directionLTR flex flex-col pt-10">
                <AppBody pClass="Body1Bold">{t('enter_code')}</AppBody>
                <ReactCodeInput
                  ref={codeRef}
                  fields={5}
                  type="number"
                  className="verify-code mt-5 self-center"
                  loading={isSubmitting}
                  onComplete={(code) => {
                    if (!complete) {
                      handleCompletion(code);
                    }
                  }}
                />

                <Timer isLoading={isLoading} sendOtp={sendOtp} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetVerifyPhone;
