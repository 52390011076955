import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import ActionButton from '../../../components/Button/ActionButton';
import {TextArea} from '../../../components/FormInputs/Inputs';
import {LargerTitle2} from '../../../components/Titles';
import {setAction} from '../../../helpers/ActionsHelper';
import useNotifications from '../../../helpers/useNotifications';
import {UPDATE_FUND_ITEM} from '../../../redux/FundsReducer';
import {FundItemHeader} from './FundPreview';
import {rejectClickHandler} from './Helper';
import {AppBody} from "../../../components/html/html";

const RejectionForm = function (props) {
  const { setRejectingFund, selectedFund, setSelectedItem, setSelectedFund } = props;
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const [selectedReason, setSelectedReason] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOther, setSelectedOther] = useState(false);
  const { t, i18n } = useTranslation(['funds', 'common']);
  const [, addNotification] = useNotifications();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: 'onBlur' });

  const defaultRejectionReasons = [
    t('reason1'),
    t('reason2'),
    t('reason3'),
    t('reason4'),
    t('reason5'),
  ];

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const reason = selectedOther ? data.reason : defaultRejectionReasons[selectedReason];
    rejectClickHandler(selectedFund.id, cookies.token, reason)
      .then((response) => {
        dispatch(setAction(UPDATE_FUND_ITEM, response));
        // setSelectedFund(response);
        setRejectingFund(null);
        setSelectedItem(null);
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  return (
    <div className="col-span-1 flex flex-col justify-start rounded-2xl ltr:text-left rtl:text-right">
      <div className="flex flex-row flex-wrap justify-between self-stretch">
        <div className="self-start ltr:text-left rtl:text-right">
          <LargerTitle2>{t('reject_fund')}</LargerTitle2>
        </div>
        <div className="mb-5 flex flex-row items-baseline justify-end align-baseline ltr:text-left ltr:text-left rtl:text-right rtl:text-right">
          <AppBody type="span" pClass="Body1Bold">
            {selectedFund.currency} {parseFloat(selectedFund.amount).toFixed(2).toLocaleString()}
          </AppBody>
        </div>
      </div>

      <FundItemHeader noBorder selectedItem={selectedFund} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="no_print mt-2 flex flex-grow flex-col items-start justify-start self-stretch"
      >
        <div className="flex w-full flex-col justify-start bg-gray-1 p-5">
          {defaultRejectionReasons.map((reason, index) => (
            <div
              className="flex flex-row pt-2 ltr:pl-2 rtl:pr-2"
              key={`rejection-reason-${index + 1}`}
            >
              <div className="radio-item">
                <input
                  className="scale-125 transform ltr:mr-2 rtl:ml-2"
                  id={`rejection-reason-${index}`}
                  type="radio"
                  name="rejection_reason"
                  value={index}
                  defaultChecked={index === 0}
                  onChange={(evt) => {
                    setSelectedReason(evt.target.value);
                    if (index === defaultRejectionReasons.length - 1) {
                      setSelectedOther(true);
                    } else {
                      setSelectedOther(false);
                    }
                  }}
                />
                <label htmlFor={`rejection-reason-${index}`}>{reason}</label>
              </div>
            </div>
          ))}

          {selectedOther && (
            <div className="flex flex-grow flex-row self-start self-stretch">
              <AppBody type="label" pClass="Body2Bold"
                htmlFor="reason"
                className="flex flex-grow flex-col"
              >
                <TextArea
                  {...register('reason', {
                    required: t('reason_required'),
                  })}
                  error={errors.reason}
                  id="reason"
                  maxLength={255}
                  className={`${
                    errors.reason ? 'border-red-400' : 'border-gray-3'
                  } mt-1 w-full resize-none self-stretch rounded-2xl border  p-5`}
                />
              </AppBody>
            </div>
          )}
        </div>
        <div className="mt-5 flex w-full flex-row justify-end pt-5">
          <ActionButton
            className="flex flex-row"
            text={t('cancel', { ns: 'common' })}
            onClick={() => {
              setRejectingFund(null);
            }}
          />
          <ActionButton
            type="submit"
            className="flex flex-row border border-red-400 text-red-700"
            text={t('reject')}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
};

export default RejectionForm;
