import { useSelector } from 'react-redux';
import LoadMerchantCountryCity from '../../../../../../components/SharedComponents/LoadMerchantCountryCity';
import LoadUnloadDetails from '../../../../../../components/SharedComponents/LoadUnloadDetails';
import ReceiptBalanceAndStatus from '../../../../../../components/SharedComponents/ReceiptBalanceAndStatus';
import { inPages } from '../../../../../../helpers/constants';

function LoadTabPopupContent({ cardDetails }) {
  const { singleTransactionDetails } = useSelector((state) => state.cardDetails);

  return (
    <>
      <div>
        <ReceiptBalanceAndStatus transactionDetails={singleTransactionDetails} />
      </div>
      <div>
        <LoadMerchantCountryCity transactionDetails={singleTransactionDetails} />
      </div>
      <div>
        <LoadUnloadDetails
          transactionDetails={singleTransactionDetails}
          cardDetails={cardDetails}
          inPage={inPages.myCards}
        />
      </div>
    </>
  );
}

export default LoadTabPopupContent;
