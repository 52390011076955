import axios from 'axios';
import { errorHandler, requestHandler, successHandler } from './interceptors';

// TODO:: dont append v1/ in base url
export const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  timeout: 150000,

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

// Attach axios.all and axios.spread to the http instance
http.all = axios.all;
http.spread = axios.spread;

// Handle request process
http.interceptors.request.use((request) => requestHandler(request));
// Handle response process
http.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);
