import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckIcon} from '../../../assets/svg/check.svg';
import {ReactComponent as CrosskIcon} from '../../../assets/svg/close.svg';
import {AppBody} from "../../../components/html/html";

export const ItemStatusBadge = function (props) {
  const { status, text, className } = props;
  const approved = status === 2;

  return (
    <div
      className={`ml-1 rounded-full px-5 py-1 ${className || ''} ${
        approved ? 'bg-green-50 text-green-600' : 'bg-red-50 text-red-700'
      }`}
    >
      {text}
    </div>
  );
};

export const ItemStatus = function (props) {
  const { text, className } = props;

  const { t } = useTranslation(['funds']);
  return (
    <div className={`ml-1 px-0 py-2 lg:px-5 lg:py-1 ${className || ''}`}>
      <span className="text-gray-6 ltr:pr-3 rtl:pl-3">{t('request_status')}</span>
        <AppBody type="span" pClass="Body1Bold">{t(text.toLowerCase())}</AppBody>
    </div>
  );
};

export const ApproveStatus = function (props) {
  const { text, className } = props;

  const { t } = useTranslation(['funds']);
  return (
    <div className={`ml-1 px-0 py-2 lg:py-1 ${className || ''}`}>
        <AppBody type="span" pClass="Body1Bold" className="text-green-600">
        <CheckIcon height="24" width="24" className="inline" /> {t(text.toLowerCase())}
        </AppBody>
    </div>
  );
};

export const RejectStatus = function (props) {
  const { text, className } = props;

  const { t } = useTranslation(['funds']);
  return (
    <div className={`ml-1 px-0 py-2 lg:py-1 ${className || ''}`}>
      <AppBody pClass="Body1Bold" type="span" className="text-red-700">
        <CrosskIcon height="24" width="24" className="inline" /> {t(text.toLowerCase())}
      </AppBody>
    </div>
  );
};
