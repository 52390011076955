/* eslint-disable import/no-extraneous-dependencies, camelcase */
import moment from 'moment-hijri';
import 'moment/locale/en-gb';
import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import arabic from 'react-date-object/calendars/arabic';
import gregorian from 'react-date-object/calendars/gregorian';
import arabic_en from 'react-date-object/locales/arabic_en';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import ActionButton from '../../../../components/Button/ActionButton';
import {TextInput} from '../../../../components/FormInputs/Inputs';
import {HeadlineCenter} from '../../../../components/Titles';
import TooltipPackage from '../../../../components/TooltipPackage';
import Dropzone from '../../../../components/Upload/Dropzone';
import {apiPostFormDataRequest} from '../../../../helpers/APIHelper';
import {IDNUMBERPATTERN, IQAMANUMBERPATTERN, PASSPORTPATTERN,} from '../../../../helpers/RegexPatterns';
import useNotifications from '../../../../helpers/useNotifications';
import {AppBody} from "../../../../components/html/html";

function VerifyIdentity({ setIdentityDetails }) {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm({ mode: 'onBlur' });
  const [birthDate, setBirthDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [calendarType, setCalendarType] = useState(gregorian);
  const [calendarLocaleType, setCalendarLocaleType] = useState(gregorian_en);
  const [watchIdType] = watch(['id_type']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [cookies] = useCookies(['token']);
  const { t, i18n } = useTranslation(['signup']);
  const [IDRegex, setIDRegex] = useState(IDNUMBERPATTERN);
  const [, addNotification] = useNotifications();

  const onSubmit = (data) => {
    let submitFailed = false;

    if (birthDate === null || birthDate === '') {
      setDateError(true);
      submitFailed = true;
    }

    if (!files || (files && files.length === 0)) {
      setFileError(t('verify_identity.upload_your_id_required'));
      submitFailed = true;
    }

    if (!submitFailed) {
      const form = new FormData();
      form.append('id_type', data.id_type);
      form.append(
        'birth_date_h',
        moment(new Date(birthDate)).locale('en').subtract(1, 'days').format('iYYYY/iMM/DD'),
      );
      form.append('birth_date', moment(new Date(birthDate)).locale('en').format('YYYY-MM-DD'));
      form.append('id_number', data.id_number);

      setIsSubmitting(true);
      apiPostFormDataRequest('users/identity/verify', form, cookies.token)
        .then((response) => {
          if (files) {
            files.forEach((file) => {
              response.id_file = file;
            });
          }
          setIdentityDetails(response);
        })
        .catch((err) => {
          addNotification(
            (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
            false,
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (watchIdType === 'nid') {
      setCalendarType(arabic);
      setCalendarLocaleType(arabic_en);
      setIDRegex(IDNUMBERPATTERN);
    } else if (watchIdType === 'passport') {
      setCalendarType(gregorian);
      setCalendarLocaleType(gregorian_en);
      setIDRegex(PASSPORTPATTERN);
    } else {
      setCalendarType(gregorian);
      setCalendarLocaleType(gregorian_en);
      setIDRegex(IQAMANUMBERPATTERN);
    }
  });

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  return (
    <div className="flex flex-col text-center">
      <HeadlineCenter>{t('verify_identity.headline')}</HeadlineCenter>
      <AppBody type="span" pClass="Body2Regular" className="centerTitle relative m-auto max-w-md pb-4 pt-2 text-center text-gray-6">
        {t('verify_identity.subtitle')}
        <TooltipPackage tooltipMessage={t('verify_identity.subtitle_more')} id="infoPreview" />
      </AppBody>
      <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="m-auto flex max-w-screen-md flex-col">
          <AppBody pClass="Body1Bold" className="ltr:text-left rtl:text-right">{t('verify_identity.id_type')}</AppBody>
          <div className="flex flex-row items-baseline pt-5">
            <div className="ltr:pl-2 rtl:pr-2">
              <div className="radio-item">
                <input
                  type="radio"
                  className="scale-125 transform"
                  {...register('id_type')}
                  value="nid"
                  id="nid"
                  defaultChecked
                />
                <label htmlFor="nid">{t('verify_identity.national_id')}</label>
              </div>
            </div>
            <div className="ltr:pl-12 rtl:pr-12">
              <div className="radio-item">
                <input
                  type="radio"
                  className="scale-125 transform"
                  {...register('id_type')}
                  value="iqama"
                  id="iqama"
                />
                <label htmlFor="iqama">{t('verify_identity.iqama')}</label>
              </div>
            </div>
            <div className="ltr:pl-12 rtl:pr-12">
              <div className="radio-item">
                <input
                  type="radio"
                  className="scale-125 transform"
                  {...register('id_type')}
                  value="passport"
                  id="passport"
                />
                <label htmlFor="passport">{t('verify_identity.passport')}</label>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 pt-10 ltr:text-left rtl:text-right">
            <AppBody type="label" pClass="Body2Regular" className="col-span-2 flex flex-col lg:col-span-1">
              <AppBody pClass="Body1Bold" type="span" className="relative">
                {t('verify_identity.national_no')}
                <TooltipPackage
                  tooltipMessage={t('verify_identity.id_hint')}
                  hasID
                  id="idPreview"
                />
              </AppBody>

              <TextInput
                type="text"
                error={errors.id_number}
                {...register('id_number', {
                  required: t('verify_identity.id_required'),
                  pattern: {
                    value: IDRegex,
                    message: t('verify_identity.id_pattern'),
                  },
                })}
              />
            </AppBody>
            <div
              className="ltr:pl-50 rtl:pr-50 col-span-2 flex flex-col lg:col-span-1"
              htmlFor="birthDate"
            >
              <AppBody pClass="Body1Bold" type="span">{t('verify_identity.birth_date')}</AppBody>
              <DatePicker
                id="birthDate"
                className="w-full"
                style={{ width: '100%' }}
                containerClassName="w-full"
                maxDate={new Date()}
                calendar={calendarType}
                locale={calendarLocaleType}
                inputClass={`${
                  dateError ? 'border-red-400' : 'border-gray-3'
                } border rounded-full py-1 px-10 mt-1 `}
                onChange={(date) => {
                  if (date) {
                    setDateError(false);
                    setBirthDate(date);
                  }
                }}
                dropdownMode="scroll"
                showYearDropdown
                value={birthDate}
              />
              <AppBody pClass="Caption1Regular" className="pt-2 text-red-700">
                {(dateError || birthDate === '') && t('verify_identity.enter_birth_date')}
              </AppBody>
            </div>
          </div>

          <div className="pt-10">
            <Dropzone
              setFile={setFiles}
              file={files}
              fileError={fileError}
              setFileError={setFileError}
              fileHint={t('rejectReason', { ns: 'common' })}
              rejectReason={t('rejectReason_simple', { ns: 'common' })}
              uploadInput={t('documents_or_photos', { ns: 'common' })}
              label={t('verify_identity.upload_your_id')}
              acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
            />
          </div>

          <div className="mt-10 flex flex-row justify-end self-stretch pt-8">
            <ActionButton
              className="flex flex-row"
              primary
              type="submit"
              text={t('verify_identity.next')}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default VerifyIdentity;
