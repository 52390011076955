import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SuccessCheckAnimation from '../../../assets/lotties/SuccessCheckAnimation2.json';
import AppLottie from '../../../components/AppLottie';
import { AppHeader } from '../../../components/html/html';
import { setFinishQuickSignUpAction } from '../../../redux/AuthReducer/AuthActions';

function QuickSignUpSuccess() {
  const { t } = useTranslation(['quickSignUp', 'commonV2']);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setFinishQuickSignUpAction(false));
    };
  }, []);

  return (
    <div>
      <div className="mx-auto w-1/2">
        <AppLottie animationData={SuccessCheckAnimation} />
      </div>

      <div className="mx-auto w-[60%]">
        <AppHeader h="h1" className="mb-3 text-center">
          {t('Successful!', { ns: 'commonV2' })}
        </AppHeader>

        <AppHeader h="h6" className="text-center text-gray-6">
          {t(
            'Your request has been submitted to our team. We will contact you shortly to complete the process.',
          )}
        </AppHeader>
      </div>
    </div>
  );
}

export default QuickSignUpSuccess;
