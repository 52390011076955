import {XSmallText} from '../Titles';
import {AppBody} from "../html/html";

function HeaderUserName({ user }) {
  return (
    <>
      <AppBody pClass="Caption1Bold" type="span">
        {user && (user.company_name ? user.company_name : 'N/A')}
      </AppBody>
      <XSmallText className="text-xs text-gray-400">{user && user.email}</XSmallText>
    </>
  );
}

export default HeaderUserName;
