import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';
import { logOutAction } from '../../redux/AuthReducer/AuthActions';

function Logout() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['logout']);

  useEffect(() => {
    dispatch(logOutAction());
  }, []);

  return (
    <div className="flex flex-grow flex-col items-center justify-center">
      <Spinner className="h-10 w-10 animate-spin lg:h-16 lg:w-16" />
      <p>{t('headline')}</p>
    </div>
  );
}

export default Logout;
