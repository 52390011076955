import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import activatedSuccessfullyAnimation from '../../../../../../../assets/lotties/activatedSuccessfully.json';
import AppLottie from '../../../../../../../components/AppLottie';
import AppSpinner from '../../../../../../../components/AppSpinner';
import { AppHeader } from '../../../../../../../components/html/html';
import { cardStatusKeys } from '../../../../../../../helpers/constants';
import { http } from '../../../../../../../http';
import { showToasterAction } from '../../../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  hideSettingsPopupDataAction,
  updateSelectedCardDetailsAction,
} from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';
import CardStatusImage from './CardStatusImage';

function ActivateCard() {
  const { loaders } = useSelector((state) => state);
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const cardStatus = useMemo(() => {
    return settingsPopupCardDetails.status?.key?.toUpperCase();
  }, [settingsPopupCardDetails]);

  const cardId = useMemo(() => {
    return settingsPopupCardDetails?.id;
  }, [settingsPopupCardDetails]);

  const handleActivateCard = useCallback(() => {
    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('status', cardStatusKeys.ACTIVE);

    http
      .post(`cardmanager/cards/${cardId}/status`, formData, {
        loader: 'handleActivateCard',
      })
      .then((res) => {
        setShowSuccessMessage(true);

        dispatch(
          updateSelectedCardDetailsAction({
            card: {
              ...res.data,
              status: {
                ...res.data.status,
                key: cardStatusKeys.ACTIVE,
                name: t('Active'),
              },
            },
          }),
        );
      })
      .catch((err) => {
        dispatch(hideSettingsPopupDataAction());
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, [cardStatus, cardId]);

  useEffect(() => {
    handleActivateCard();

    return () => {
      setShowSuccessMessage(false);
    };
  }, []);

  return (
    <>
      {loaders.handleActivateCard && (
        <div className="p-2">
          <AppSpinner />
        </div>
      )}

      {showSuccessMessage && !loaders.handleActivateCard && (
        <div className="relative">
          <AppHeader h="h6" className="mb-3 flex flex-col items-center">
            <div className="flex gap-1">
              <span>{t('Card')}</span>
              <span>*{settingsPopupCardDetails.last_four}</span>
            </div>
            <span>{t('Activated Successfully')}</span>
          </AppHeader>

          <CardStatusImage />

          <div className="absolute top-[50%] z-0">
            <AppLottie loop={false} animationData={activatedSuccessfullyAnimation} width={350} />
          </div>
        </div>
      )}
    </>
  );
}

export default ActivateCard;
