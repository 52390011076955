import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../AppRouter/history';
import { AppBody, AppButton, AppHeader } from '../../../components/html/html';
import { showModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import UserChangePasswordPopup from './UserChangePasswordPopup';

function PrivacyContent() {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { t } = useTranslation('profileV2');
  const dispatch = useDispatch();

  return (
    <div className="rounded-2xl border-gray-5 lg:border lg:p-5">
      <AppHeader h="h6">{t('Password', { ns: 'commonV2' })}</AppHeader>

      <div className="flex justify-end">
        <AppButton
          bClass="ButtonHeader1"
          button="link"
          className="font-bold text-primary"
          onClick={() => {
            if (!isMobile) {
              dispatch(showModalAction(<UserChangePasswordPopup />));
            } else {
              history.push('/dashboard/profile/user/change-password');
            }
          }}
        >
          {t('Change Password')}
        </AppButton>
      </div>

      <AppBody pClass="Body2Bold" className="mb-2 text-gray-6">
        {t('Current password')}
      </AppBody>

      <AppBody pClass="Body1Bold" className="text-black">
        ********************
      </AppBody>
    </div>
  );
}

export default PrivacyContent;
