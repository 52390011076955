import { getURLParams } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NqoodCardImg from '../../../../components/NqoodCardImg';
import { AppBody, AppHeader } from '../../../../components/html/html';
import { devLog, getServerErrMessage } from '../../../../helpers/Utils';
import { createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  selectCardProgramAction,
  selectCardToReplaceAction,
  setActiveCreateNewCardStepAction,
  setUserHasCardHolder,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import ContactUs from './ContactUs';
import NqoodCardAnimation from './NqoodCardAnimation';

function VerifyAccount() {
  const { useMock } = useSelector((state) => state);
  const { userCardList } = useSelector((state) => state.cardList);
  const { createCardUserData } = useSelector((state) => state.createNewCard);
  const [error, setError] = useState('');
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const isReplacingCard = useMemo(() => {
    return Boolean(getURLParams('cardToReplaceId'));
  }, []);

  // # 3 After creating a new card holder, Check again if the user has a card holder or not.
  const verifyUserCardHolder = useCallback(
    (tryCount = 0) => {
      devLog(`Verifying user Card Holder...`);

      const params = { user_id: createCardUserData?.id };

      if (useMock.hasCardHolder) {
        params.has_cardholder = 1;
      }
      if (useMock.hasNoCardHolder) {
        params.has_cardholder = 0;
      }

      http
        .get(`cardmanager/individual/exist`, {
          loader: 'verifyUserCardHolder',
          params,
          useMock: useMock.hasCardHolder || useMock.hasNoCardHolder,
        })
        .then((res) => {
          if (res?.data?.has_cardholder) {
            devLog(`User has a Card Holder!`);
            dispatch(setUserHasCardHolder({ userHasCardHolder: true }));
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardProgram));
          } else if (tryCount < 3) {
            setTimeout(() => {
              devLog(`Try Count: [${tryCount + 1}]`);
              verifyUserCardHolder(tryCount + 1);
            }, 5000);
          } else {
            setError('User has NO Card Holder!');
          }
        })
        .catch((err) => {
          setError(getServerErrMessage(err));
        });
    },
    [createCardUserData],
  );

  // # 2 In case user has NOT a card holder, Create one.
  const createUserCardHolder = useCallback(() => {
    devLog(`Creating user Card Holder...`);
    http
      .post(`cardmanager/cardholders/create`, null, {
        loader: 'createUserCardHolder',
        params: { user_id: createCardUserData?.id },
      })
      .then(() => {
        verifyUserCardHolder();
      })
      .catch((err) => {
        setError(getServerErrMessage(err));
      });
  }, [createCardUserData]);

  // # 1 First check if user has a card holder or not.
  const checkUserCardHolder = useCallback(() => {
    devLog(`Checking user Card Holder...`);

    const params = { user_id: createCardUserData?.id };

    if (useMock.hasCardHolder) {
      params.has_cardholder = 1;
    }
    if (useMock.hasNoCardHolder) {
      params.has_cardholder = 0;
    }

    http
      .get(`cardmanager/individual/exist`, {
        loader: 'checkUserCardHolder',
        params,
        useMock: useMock.hasCardHolder || useMock.hasNoCardHolder,
      })
      .then((res) => {
        if (res?.data?.has_cardholder) {
          devLog(`User has a Card Holder!`);
          dispatch(setUserHasCardHolder({ userHasCardHolder: true }));
          dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardProgram));
        } else {
          devLog(`User has [NO] a Card Holder!`);
          createUserCardHolder();
        }
      })
      .catch((err) => {
        setError(getServerErrMessage(err));
      });
  }, [createCardUserData, useMock]);

  const getSingleCardProgram = useCallback((toReplaceCard) => {
    devLog(
      `[Replacing] a ${
        toReplaceCard.is_physical ? 'Plastic' : 'Virtual'
      } Card, Getting and Selecting Single Card Program Data...`,
    );
    http
      .get(`cardmanager/cardproducts/${toReplaceCard?.cardproduct?.id}`, {
        loader: 'getSingleCardProgram',
      })
      .then((res) => {
        dispatch(selectCardProgramAction(res.data));
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  const findCardToReplace = useCallback(() => {
    if (+getURLParams('userDetailsId')) {
      devLog(`Finding Card For userDetailsId:[${+getURLParams('userDetailsId')}]`);
    } else {
      devLog(`Finding Card To Replace For Logged In User...`);
    }

    const toReplaceCard = userCardList?.find((ele) => ele.id === getURLParams('cardToReplaceId'));
    if (toReplaceCard) {
      devLog(`Card To Replace Found!`);
      dispatch(selectCardToReplaceAction(toReplaceCard));
      getSingleCardProgram(toReplaceCard);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.replaceCardConfirmation));
    } else {
      setError('Card To Replace Not Found!');
    }
  }, [userCardList]);

  useEffect(() => {
    if (getURLParams('cardToReplaceId')) {
      findCardToReplace();
    } else if (createCardUserData) {
      checkUserCardHolder();
    }
  }, [createCardUserData]);

  return (
    <div className="mx-auto mt-[20vh] max-w-[350px] px-5 pb-5 sm:px-0">
      <AppHeader h="h3">
        {!error && t('Verifying Your Account')}
        {error && !isReplacingCard && t('Account Verification Failed')}
        {error && isReplacingCard && t('Replacing Card Failed')}
      </AppHeader>

      {error && (
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('Please contact customer support team')}
        </AppBody>
      )}

      <div className="mt-5">
        <div className="mb-5 rounded-md shadow-xl blur-[2px] grayscale">
          {error && (
            <NqoodCardImg hideLogos skeletonWidth={350} skeletonHeight={226} alt="Failed Card" />
          )}
        </div>

        {!error && <NqoodCardAnimation />}

        {!error && (
          <AppBody pClass="Body2Medium" className="mt-3 text-gray-6">
            {t('We are verifying your account, this will take a moment')}
          </AppBody>
        )}

        {error && (
          <ContactUs
            emailSubject={isReplacingCard ? 'Replacing Card Failed' : 'Account Verification Failed'}
            emailError={error}
          />
        )}
      </div>
    </div>
  );
}

export default VerifyAccount;
