/* eslint-disable no-nested-ternary */
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoadingIcon } from '../../assets/svg/refresh.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
// eslint-disable-next-line import/no-named-as-default
import { ReactComponent as Export } from '../../assets/svg/export.svg';
import { ReactComponent as Filter } from '../../assets/svg/filter.svg';
import { ReactComponent as Search } from '../../assets/svg/filter_search.svg';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';
import Pagination from '../Pagination/Pagination';
import DateFilter from './DateFilter';
import TableFilter from './TableFilter';

export const DataTableItem = function ({ children, selected, isHeader, className, last }) {
  return (
    <div
      className={`${selected ? 'bg-gray-2 bg-opacity-60 bg-blend-multiply' : ''} ${
        last ? 'rounded-b-none lg:rounded-b-2xl' : ''
      } ${isHeader ? '' : 'hover:bg-gray-2 hover:bg-opacity-25 hover:bg-blend-multiply'}  ${
        className || ''
      }`}
    >
      <div>{children}</div>
    </div>
  );
};

const PaginationControls = function (props) {
  const { t } = useTranslation(['common']);
  const { setPage, totalResults, limit, currentPage, currentCount } = props;

  if (limit <= 0 || currentPage <= 0 || totalResults <= 0) {
    return null;
  }
  const pageCount = Math.ceil(totalResults / limit);

  return (
    <div className="mt-5 flex flex-row flex-wrap justify-end self-stretch text-sm text-gray-6 ltr:mr-5 rtl:ml-5">
      <span className="pt-0.5 text-gray-6">
        {`${t('results')} ${currentCount} ${t('of')} ${totalResults}`}
      </span>
      <Pagination
        currentPage={currentPage}
        totalCount={totalResults}
        pageSize={limit}
        onPageChange={(page) => setPage(page)}
      />
    </div>
  );
};

const DataTable = function ({
  updating,
  children,
  pagination,
  setPage,
  totalResults,
  limit,
  currentPage,
  currentCount,
  setSearch,
  split,
  setFilter,
  filterObject,
  filterState,
  setFilterState,
  resetFilter,
  applyFilters,
  setDateFilter,
  setExport,
  exportAction,
  exportLoading,
  setDaysFilter,
  setTypeDateFilter,
  setFormDateFilter,
  setToDateFilter,
  showFilterRowFlag,
}) {
  const { t } = useTranslation(['common']);
  const [showFilterRow, setShowFilterRow] = useState(showFilterRowFlag);
  const [showSearchRow, setShowSearchRow] = useState(false);

  const searchOnChange = (event) => {
    setPage(1);
    setSearch(event.target.value);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  const debouncedSetSearch = useCallback(debounce(searchOnChange, 300), []);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (showFilterRowFlag === true) {
      setShowFilterRow(showFilterRowFlag);
    }
  }, [showFilterRowFlag]);

  return (
    <div className="flex flex-col">
      <div className="no-border -mx-5 flex flex-col rounded-none pb-5 lg:mx-0 lg:rounded-2xl lg:border lg:border-gray-3">
        {(setSearch || setFilter || setDateFilter || setExport) && (
          <div className="relative flex flex-row items-start border-b border-gray-3 px-5 pb-2 pt-2 align-baseline">
            <div
              className={`flex ${
                split ? 'flex-row pt-5' : 'flex-col lg:flex-row'
              } dateFilter flex-grow items-baseline justify-start`}
            >
              {setDateFilter && (
                <DateFilter
                  split={split}
                  setDaysFilter={setDaysFilter}
                  setTypeDateFilter={setTypeDateFilter}
                  setFormDateFilter={setFormDateFilter}
                  setToDateFilter={setToDateFilter}
                  setPage={setPage}
                />
              )}
            </div>
            {/* eslint-disable-next-line no-nested-ternary */}
            <div
              className={
                setDateFilter
                  ? split
                    ? 'absolute top-0 ltr:right-0 rtl:left-0'
                    : 'absolute -top-5 lg:relative lg:top-0 lg:flex lg:flex-row lg:items-baseline lg:justify-end ltr:right-0 lg:ltr:right-0 rtl:left-0 lg:rtl:left-0'
                  : 'flex items-baseline justify-end lg:flex-row'
              }
            >
              {setFilter && (
                <button
                  className={`${
                    split ? 'lg:px-1' : 'lg:px-4'
                  } rlt:font-normal mx-2 py-1 text-xs hover:text-black ltr:font-light ${
                    showFilterRow ? 'rounded-full bg-gray-2 text-black' : 'text-gray-6'
                  }`}
                  type="button"
                  onClick={() => {
                    resetFilter();
                    if (!showFilterRow) {
                      setPage(1);
                      setFilterState(filterObject[0], 'add');
                    }

                    setShowFilterRow(!showFilterRow);
                  }}
                >
                  <Filter className={`inline-block ${showFilterRow ? 'fill-tableFilter' : ''}`} />{' '}
                  <span className={split ? 'hidden' : 'hidden lg:inline-block'}>{t('filter')}</span>
                </button>
              )}
              {setSearch && (
                <button
                  className={`${
                    split ? 'lg:px-1' : 'lg:px-4'
                  } rlt:font-normal mx-2 py-1 text-xs hover:text-black ltr:font-light ${
                    showSearchRow ? 'rounded-full bg-gray-2 text-black' : 'text-gray-6'
                  }`}
                  type="button"
                  onClick={() => {
                    if (showSearchRow) {
                      setPage(1);
                      setSearch('');
                    }
                    setShowSearchRow(!showSearchRow);
                  }}
                >
                  <Search className={`inline-block ${showSearchRow ? 'fill-tableFilter' : ''}`} />{' '}
                  <span className={split ? 'hidden' : 'hidden lg:inline-block'}>{t('search')}</span>
                </button>
              )}
              {setExport && (
                <button
                  className={`${
                    split ? 'lg:px-1' : 'lg:px-4'
                  } rlt:font-normal mx-2 py-1 text-xs hover:text-black ltr:font-light ${
                    exportLoading ? 'rounded-full bg-gray-2 text-black' : 'text-gray-6'
                  }`}
                  type="button"
                  onClick={exportAction}
                >
                  <Export className={`inline-block ${exportLoading ? 'fill-tableFilter' : ''}`} />{' '}
                  <span className={split ? 'hidden' : 'hidden lg:inline-block'}>{t('export')}</span>
                  {exportLoading && (
                    <Spinner className="inline-block h-4 w-4 animate-spin fill-lightGray" />
                  )}
                </button>
              )}
            </div>
          </div>
        )}
        {showSearchRow && (
          <div className="flex flex-row items-start border-b border-gray-3 px-5 pb-2 pt-2 align-baseline">
            <SearchIcon className="mt-1 h-4 w-4 ltr:mr-2 rtl:ml-2" />
            <input
              className="w-full border-0 bg-transparent text-sm outline-none focus:border-0 focus:outline-none focus:outline-transparent focus:ring-0 ltr:font-light rtl:font-normal"
              type="text"
              name="search"
              placeholder={t('search_table')}
              onChange={debouncedSetSearch}
            />
          </div>
        )}
        {showFilterRow && (
          <div className="flex flex-col" id="filterRow">
            <div className="flex flex-col">
              <TableFilter
                setPage={setPage}
                split={split}
                resetFilter={resetFilter}
                applyFilters={applyFilters}
                setSelectedFilters={setFilterState}
                filterObject={filterObject}
                selectedFilters={filterState}
                showFilterRow={showFilterRow}
                setShowFilterRow={setShowFilterRow}
              />
            </div>
          </div>
        )}
        <div
          className={`flex flex-col ${split ? 'overflow-y-auto' : 'overflow-visible'} ${
            isMobile ? 'max-h-full' : 'max-h-fit'
          } relative`}
        >
          {updating && (
            <div className="absolute z-40 flex h-full w-full flex-col items-center justify-center rounded-b-none bg-white bg-opacity-30 backdrop-blur-sm backdrop-filter lg:rounded-b-2xl">
              <LoadingIcon className="h-8 w-8 animate-spin fill-darkGray" />
            </div>
          )}
          {children}
        </div>
      </div>
      {pagination && (
        <PaginationControls
          currentCount={currentCount}
          setPage={setPage}
          limit={limit}
          totalResults={totalResults}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default DataTable;
