import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from 'react-icons/io';
import { AppButton } from '../html/html';

function BackBtn({ onClick, label, className }) {
  const { t } = useTranslation('commonV2');

  return (
    <AppButton
      size="md"
      className={`flex -translate-x-2 items-center gap-1 text-primary ${className}`}
      button="link"
      onClick={onClick}
    >
      <IoIosArrowForward size={24} className="ltr:rotate-180" />
      <span>{label || t('Back')}</span>
    </AppButton>
  );
}

export default BackBtn;
