import { useSelector } from 'react-redux';
import RequestFrom from '../../../../../components/SharedComponents/RequestFrom';

function MoneyRequestContent({ cardDetails }) {
  const { singleRequestDetails, singleTransactionDetails, selectedActiveTab } = useSelector(
    (state) => state.cardDetails,
  );

  return (
    <RequestFrom
      requestDetails={singleRequestDetails}
      cardDetails={cardDetails}
      transactionDetails={singleTransactionDetails}
      activeTab={selectedActiveTab}
      fromCardActions
    />
  );
}

export default MoneyRequestContent;
