import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../AppRouter/history';
import { apiGetRequest } from '../../helpers/APIHelper';
import { loginActiveStep } from '../../helpers/constants';
import useAccountReady from '../../helpers/useAccountReady';
import useIsLoggedIn from '../../helpers/useIsLoggedIn';
import { isAccountReady } from '../../helpers/Utils';
import usePermissions from '../../hooks/usePermissions';
import TestingAlter from '../../modules/Notifications/TestingAlter';
import { changeLang } from '../../redux/AppConfigReducer/AppConfigActions';
import { setActiveLoginStepAction } from '../../redux/AuthReducer/AuthActions';
import ChangeLangButton from '../ChangeLangButton';
import { AppIcon } from '../html/html';
import MainMenu from '../MainMenu/MainMenu';
import HeaderMenu, { HeaderMenuItem } from './HeaderMenu';
import UserPictureHeader from './UserPictureHeader';

function Header() {
  const { activeLoginStep } = useSelector((state) => state.auth.loginProcess);
  const { appConfig } = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  const loggedIn = useIsLoggedIn();
  const accountReady = useAccountReady();
  const { t, i18n } = useTranslation(['common']);
  const userCan = usePermissions();
  const dispatch = useDispatch();
  const [staticLinks, setStaticLinks] = useState(null);

  const fetchStaticPages = () => {
    apiGetRequest('static_pages').then((response) => {
      if (response.length > 0) {
        setStaticLinks(response);
      }
    });
  };

  useEffect(() => {
    fetchStaticPages();
  }, [i18n.language]);

  return (
    <header>
      <div className="flex h-[8vh] items-center justify-between">
        <button
          type="button"
          className="flex flex-col justify-between"
          onClick={() => {
            if (
              window.location.pathname === '/auth/login' &&
              activeLoginStep === loginActiveStep.loginOTP
            ) {
              dispatch(setActiveLoginStepAction(loginActiveStep.loginAndBanner));
            }

            if (loggedIn) {
              if (isAccountReady(user)) {
                history.replace('/dashboard');
              }
            } else {
              history.replace('/auth/login');
            }
          }}
        >
          {appConfig.lang !== 'ar' && (
            <AppIcon
              className="fa-kit fa-nqoodlet-logo-english text-xl text-black md:text-2xl lg:text-3xl"
              disableIClasses
            />
          )}
          {appConfig.lang === 'ar' && (
            <AppIcon
              className="fa-kit fa-nqoodlet-logo-arabic text-xl text-black md:text-2xl lg:text-3xl"
              disableIClasses
            />
          )}
          <TestingAlter />
        </button>
        {loggedIn && <MainMenu />}

        {loggedIn && (
          <HeaderMenu>
            <UserPictureHeader className="z-50" width="w-12" height="h-12" />
            {userCan('show_account') && accountReady && (
              <HeaderMenuItem label={t('company_profile')} to="/dashboard/profile/company" />
            )}

            {staticLinks &&
              staticLinks.map((item) => (
                <HeaderMenuItem
                  key={item.title}
                  target="_blank"
                  to={i18n.language === 'ar' ? item.link_ar : item.link}
                  label={i18n.language === 'ar' ? item.title_ar : item.title}
                />
              ))}

            <div className="border-b border-gray-3 border-opacity-40 px-4 py-2 last:border-b-0 hover:bg-gray-100">
              <button
                type="button"
                className="flex flex-row items-baseline justify-between self-stretch"
                onClick={() => {
                  dispatch(changeLang());
                }}
              >
                <p className="text-sm">{t('change_lang')}</p>
                <span />
              </button>
            </div>

            <HeaderMenuItem
              label={t('logout')}
              to="/auth/logout"
              icon={
                <AppIcon
                  iClass="LargeFont"
                  className="fa-regular fa-arrow-right-from-bracket text-danger"
                />
              }
            />
          </HeaderMenu>
        )}
        <ChangeLangButton />
      </div>
    </header>
  );
}

export default Header;
