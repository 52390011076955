import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import { AppBody, AppIcon } from '../../../../components/html/html';
import SingleUserStatusBadge from '../SingleUserDetails/SingleUserStatusBadge';
import SingleUserOptionsPopup from './SingleUserOptionsPopup';

function SingleUserRow({
  item,
  isLastEl,
  setShowOptionsPopup,
  showOptionsPopup,
  paginationParams,
}) {
  const { loaders } = useSelector((state) => state);

  const navigateToSingleUserDetails = useCallback(() => {
    history.push(`/dashboard/users/${item.id}?usersListParams=${JSON.stringify(paginationParams)}`);
  }, [item]);

  return (
    <div
      className={`flex justify-between border-gray-3 py-4 pe-4 ps-2 xxs:ps-4 lg:py-6 lg:pe-3 lg:ps-9 ${
        !isLastEl ? 'border-b' : 'border-0'
      }`}
    >
      <div onClick={navigateToSingleUserDetails} className="cursor-pointer">
        <AppBody pClass="Body1Bold" className="mb-0.5 capitalize">
          <span>{item.first_name}</span> <span>{item.last_name}</span>
        </AppBody>
        <AppBody pClass="Caption1Medium" className="mb-0.5 capitalize">
          {item.job_title}
        </AppBody>
        <AppBody pClass="Caption1Medium" className="text-gray-6">
          <span>{item.mobile}</span> - <span>{item.email}</span>
        </AppBody>
      </div>

      <div className="flex items-center justify-center">
        <div>
          <SingleUserStatusBadge
            userDetails={item}
            isLoading={
              loaders[`changeUserStatusAction[${item.id}]`] ||
              loaders[`resendUserActivationEmailAction[${item.id}]`]
            }
          />
        </div>
        <div>
          <SingleUserOptionsPopup
            isLastEl={isLastEl}
            item={item}
            setShowOptionsPopup={setShowOptionsPopup}
            showOptionsPopup={showOptionsPopup}
            navigateToSingleUserDetails={navigateToSingleUserDetails}
          />
        </div>
        <a
          href={`${window.location.origin}/dashboard/users/${item.id}`}
          target="_blank"
          rel="noreferrer"
          className="hidden lg:inline"
        >
          <AppIcon
            className="fa-regular fa-arrow-up-right-from-square text-primary"
            iClass="BaseFont"
          />
        </a>
      </div>
    </div>
  );
}

export default SingleUserRow;
