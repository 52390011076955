import React from 'react';
import NqoodletLogo from '../../assets/png/nqoodlet.png';
import { AppBody } from '../html/html';

function ComponentToPrint({ children, reference }) {
  return (
    <>
      {/* Display content directly without table in web */}
      <div className="print:hidden">{children}</div>

      {/* Display content with table in print */}
      <div className="hidden print:block">
        <table className="hidden w-full print:table">
          <thead className="hidden print:table-header-group">
            <tr>
              <td>
                <div className="header-space">&nbsp;</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{children}</td>
            </tr>
          </tbody>
          <tfoot className="hidden print:table-footer-group">
            <tr>
              <td>
                <div className="footer-space">&nbsp;</div>
              </td>
            </tr>
          </tfoot>
        </table>

        {/* PRINT HEADER AND FOOTER */}
        <div className="fixed top-4 hidden w-full flex-row items-center justify-between print:flex">
          <AppBody pClass="Caption1Regular">{reference}</AppBody>
          <img
            src={NqoodletLogo}
            alt="logo"
            className="mx-10 w-1/5"
            style={{
              maxHeight: '90px',
            }}
          />
        </div>

        <div
          className="fixed bottom-2 hidden w-full flex-row justify-between print:flex"
          style={{ maxHeight: '90px' }}
        >
          <AppBody pClass="Caption1Regular">Copyright &copy; Nqoodlet</AppBody>
          {/* TODO:: Page number for PDF */}
          <AppBody pClass="Caption1Regular" className="page-number" />
        </div>
      </div>
    </>
  );
}

export default ComponentToPrint;
