import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import activatedSuccessfullyAnimation from '../../../../../../../assets/lotties/activatedSuccessfully.json';
import AppImageComponent from '../../../../../../../components/AppImageComponent';
import AppLottie from '../../../../../../../components/AppLottie';
import { AppBody, AppHeader } from '../../../../../../../components/html/html';

function RequestSent() {
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const { t } = useTranslation('cardsV2');

  return (
    <div className="relative">
      <AppHeader h="h6" className="mb-3 flex flex-col items-center">
        <div className="flex gap-1">
          <span>{t('Card', { ns: 'cardsV2' })}</span>
          <span>*{settingsPopupCardDetails.last_four}</span>
        </div>
        <span>{t('Request Has Been Successfully Sent', { ns: 'cardsV2' })}</span>
      </AppHeader>

      <div className="relative z-10 mx-auto w-52 overflow-hidden shadow">
        <AppImageComponent
          src={settingsPopupCardDetails?.design?.attachment}
          alt={t('An image of a card', { ns: 'cardsV2' })}
          skeletonHeight={120}
        />
        <div
          className="absolute bottom-3 start-4 flex items-center tracking-wide"
          style={{ direction: 'ltr' }}
        >
          <AppBody
            pClass="Caption1Medium"
            className={settingsPopupCardDetails?.is_physical ? 'text-white' : 'text-black'}
          >
            {settingsPopupCardDetails?.last_four}
          </AppBody>
        </div>
      </div>

      <div className="absolute top-[50%] z-0">
        <AppLottie loop={false} animationData={activatedSuccessfullyAnimation} width={350} />
      </div>
    </div>
  );
}

export default RequestSent;
