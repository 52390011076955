import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AppSelect from '../../../../components/FormInputs/AppSelect';
import { AppBody, AppButton, AppIcon } from '../../../../components/html/html';
import { usersStatus } from '../../../../helpers/constants';
import { getServerErrMessage } from '../../../../helpers/Utils';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';

function UsersListFilterPopupContent({ onApply, filterTags }) {
  const defaultValue = useMemo(() => {
    return {
      filters: [
        {
          filter: '',
          subFilter: '',
        },
      ],
    };
  }, []);

  const { loaders, appConfig } = useSelector((state) => state);

  const [tabFilters, setTabFilters] = useState([]);
  const [initialValues, setInitialValues] = useState(defaultValue);
  const [rolesOptions, setRolesOptions] = useState([]);
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();

  const customSelectStyles = useMemo(() => {
    if (appConfig.isMobile) {
      return {};
    }
    return {
      menuList: (provided) => ({
        ...provided,
        maxHeight: '90px',
      }),
    };
  }, [appConfig.isMobile]);

  const roleOption = useMemo(() => {
    return {
      label: t('Role', { ns: 'commonV2' }),
      value: 'role',
      name: 'role',
      queryName: 'role',
      subFilterOptions: rolesOptions,
    };
  }, [t, rolesOptions]);

  const statusOption = useMemo(() => {
    return {
      label: t('Status', { ns: 'commonV2' }),
      value: 'Status',
      name: 'status',
      queryName: 'status',
      subFilterOptions: [
        { label: t([usersStatus.Active]), value: usersStatus.Active },
        { label: t([usersStatus.Pending]), value: usersStatus.Pending },
        { label: t([usersStatus.Suspended]), value: usersStatus.Suspended },
        { label: t([usersStatus.Deactivate]), value: usersStatus.Deactivate },
        { label: t([usersStatus.Banned]), value: usersStatus.Banned },
      ],
    };
  }, [t]);

  const usersListFilters = useMemo(() => {
    return [roleOption, statusOption];
  }, [rolesOptions]);

  const handleApply = useCallback((values) => {
    onApply(values);
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      filters: yup.array().of(
        yup.object().shape({
          filter: yup
            .object()
            .nullable()
            .required(t('This field is required!', { ns: 'commonV2' })),
          subFilter: yup
            .object()
            .nullable()
            .required(t('This field is required!', { ns: 'commonV2' })),
        }),
      ),
    });
  }, []);

  const formik = useFormik({
    validateOnBlur: !appConfig.isMobile,
    initialValues,
    onSubmit: handleApply,
    validationSchema,
    enableReinitialize: true,
  });

  const isAddMoreDisabled = useMemo(() => {
    const isMoreThanFilterCount = formik.values.filters.length >= tabFilters.length;
    const lastSubFilterHasValue =
      formik.values.filters[formik.values.filters.length - 1]?.subFilter;

    return isMoreThanFilterCount || !lastSubFilterHasValue;
  }, [formik, tabFilters]);

  const showInputError = useCallback(
    ({ inputName, index }) => {
      return (
        formik.touched.filters &&
        formik.touched.filters[index] &&
        formik.touched.filters[index][inputName] &&
        formik.errors.filters &&
        formik.errors.filters[index] &&
        formik.errors.filters[index][inputName]
      );
    },
    [formik],
  );

  const selectedFilters = useMemo(() => {
    return formik.values.filters.map((ele) => ele.filter?.value);
  }, [formik, initialValues]);

  const getRoles = useCallback(() => {
    http
      .get('employees/types/filter', { loader: 'getRoles' })
      .then((res) => {
        const serialized = res.data.map((ele) => {
          return {
            label: ele.value,
            value: ele.key,
          };
        });

        setRolesOptions(serialized);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [t]);

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (rolesOptions.length) {
      setTabFilters(usersListFilters);
    }
  }, [rolesOptions]);

  useEffect(() => {
    const filteredFormikInitialValues = Object.values(filterTags).filter((ele) => ele.formValue);
    const formikInitialValues = filteredFormikInitialValues.map((ele) => ele.formValue);
    if (formikInitialValues.length) {
      setInitialValues({ filters: formikInitialValues });
    }
  }, [filterTags]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {formik.values.filters.map((ele, index) => {
          const key = `filters-form[${index}]`;
          const showRemoveBtn = index >= 1;
          const showSubFilter = !!ele.filter;

          return (
            <div key={key} className="mb-4 grid gap-4 border-b pb-4">
              <div>
                <AppBody pClass="Caption1Regular" type="span" className="mb-2 flex justify-between">
                  <AppBody type="span" pClass="Body1Bold">
                    {t('Select Filters')}
                  </AppBody>

                  {showRemoveBtn && (
                    <AppButton
                      className="text-primary"
                      button="link"
                      onClick={() => {
                        const updatedValue = formik.values.filters;
                        updatedValue.splice(index, 1);
                        formik.setFieldValue('filters', updatedValue);
                      }}
                    >
                      {t('Clear', { ns: 'commonV2' })}
                    </AppButton>
                  )}
                </AppBody>
                <AppSelect
                  isLoading={loaders.getRoles}
                  isSearchable={!appConfig.isMobile}
                  mobileSelect={false}
                  customStyleConfigs={customSelectStyles}
                  className="w-full text-xs"
                  name={`filters[${index}].filter`}
                  value={ele.filter}
                  onMenuClose={() => {
                    formik.setFieldTouched(`filters[${index}].filter`);
                  }}
                  options={tabFilters.filter((f) => !selectedFilters.includes(f.value))}
                  onChange={(option) => {
                    formik.setFieldValue(`filters[${index}].subFilter`, '');
                    formik.setFieldValue(`filters[${index}].filter`, option);
                  }}
                />
                {showInputError({ index, inputName: `filter` }) && (
                  <AppBody pClass="Caption2Medium" type="span" className="text-red-600">
                    {formik.errors.filters[index].filter}
                  </AppBody>
                )}
              </div>

              {/* SubFilter */}
              {showSubFilter && (
                <div>
                  <div>
                    <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                      {t('Select', { ns: 'commonV2' })} {ele.filter.label}
                    </AppBody>
                    <AppSelect
                      isSearchable={!appConfig.isMobile}
                      mobileSelect={false}
                      customStyleConfigs={customSelectStyles}
                      className="w-full text-xs"
                      name={`filters[${index}].subFilter`}
                      value={ele.subFilter}
                      onMenuClose={() => {
                        formik.setFieldTouched(`filters[${index}].subFilter`);
                      }}
                      options={ele.filter.subFilterOptions}
                      onChange={(option) => {
                        formik.setFieldValue(`filters[${index}].subFilter`, option);
                      }}
                    />
                    {showInputError({ index, inputName: `subFilter` }) && (
                      <AppBody pClass="Caption2Medium" type="span" className="text-red-600">
                        {formik.errors.filters[index].subFilter}
                      </AppBody>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}

        <AppButton
          className="flex items-center gap-2"
          onClick={() => {
            const updatedValue = formik.values.filters;
            updatedValue.push(defaultValue);
            formik.setFieldValue('filters', updatedValue);
          }}
          button="link"
          disabled={isAddMoreDisabled}
        >
          <AppIcon iClass="XXLargeFont" className="fa-solid fa-circle-plus" />
          <span>{t('Add More', { ns: 'commonV2' })}</span>
        </AppButton>

        <div className="mb-3 flex items-center justify-end gap-4">
          <AppButton
            isLoading={loaders.getCardTransactionsAction || loaders.getCardRequestsAction}
            disabled={!formik.isValid}
            button="black"
            type="submit"
          >
            {t('Apply', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </form>
    </div>
  );
}

export default UsersListFilterPopupContent;
