import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppSpinner from '../../../../../components/AppSpinner';
import { devLog } from '../../../../../helpers/Utils';
import { walletDetailsSectionIds, walletTabs } from '../../../../../helpers/constants';
import {
  clearCardProgramDetailsBalanceAction,
  clearPendingRequestsCountAction,
  clearSelectedCardProgramDataAction,
  clearSelectedCardProgramDetailsAction,
  clearWalletDepartmentsAction,
  clearWalletDocTypesAction,
  clearWalletRequestCurrenciesAction,
  clearWalletRequestDepartmentsAction,
  clearWalletRequestsAction,
  clearWalletTagsAction,
  clearWalletTransactionsAction,
  clearWalletUsersAction,
  getCardProgramDetailsBalanceAction,
  getSelectedCardProgramDetailsAction,
  getWalletCardProgramListAction,
  hideAllWalletPopups,
  selectSingleCardProgramAction,
  setActiveWalletMainTabAction,
  setActiveWalletSubTabAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import CardProgramDetailsHeader from './CardProgramDetailsHeader';
import CardProgramDetailsTable from './CardProgramDetailsTable';

function CardProgramDetailsPage() {
  const { loaders } = useSelector((state) => state);
  const { singleSelectedCardProgramData, singleSelectedCardProgramDetails, walletCardPrograms } =
    useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const { cardProgramId } = useParams();

  useEffect(() => {
    if (cardProgramId) {
      devLog(`[Card Program Details Page]`);
      const cardProgram = { id: cardProgramId };
      dispatch(selectSingleCardProgramAction({ cardProgram }));
    }
  }, [cardProgramId]);

  useEffect(() => {
    if (singleSelectedCardProgramData) {
      dispatch(
        getSelectedCardProgramDetailsAction({ cardProgramId: singleSelectedCardProgramData.id }),
      );
    }
  }, [singleSelectedCardProgramData]);

  // Getting Balance
  useEffect(() => {
    if (singleSelectedCardProgramDetails) {
      dispatch(
        getCardProgramDetailsBalanceAction({ cardProgramId: singleSelectedCardProgramDetails.id }),
      );
    }
  }, [singleSelectedCardProgramDetails]);

  useEffect(() => {
    if (!walletCardPrograms?.length) dispatch(getWalletCardProgramListAction());
  }, [walletCardPrograms]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedCardProgramDataAction());
      dispatch(clearSelectedCardProgramDetailsAction());
      dispatch(clearCardProgramDetailsBalanceAction());
      dispatch(setActiveWalletMainTabAction(walletTabs.REQUESTS));
      dispatch(setActiveWalletSubTabAction(walletTabs.PENDING));
      dispatch(clearWalletRequestsAction());
      dispatch(clearWalletRequestCurrenciesAction());
      dispatch(clearWalletRequestDepartmentsAction());
      dispatch(clearWalletUsersAction());
      dispatch(clearPendingRequestsCountAction());
      dispatch(clearWalletTransactionsAction());
      dispatch(clearWalletDepartmentsAction());
      dispatch(clearWalletTagsAction());
      dispatch(clearWalletDocTypesAction());
      dispatch(hideAllWalletPopups());
    };
  }, []);

  return (
    <div
      className="h-full overflow-y-auto md:px-6 2xl:px-12"
      id={walletDetailsSectionIds.RIGHT_SIDE_CONTAINER}
    >
      {!loaders.getSelectedCardProgramDetailsAction && singleSelectedCardProgramDetails && (
        <>
          <div
            className="top-0 z-20 py-8 lg:sticky lg:bg-white lg:px-0"
            id={walletDetailsSectionIds.WALLET_DETAILS_HEADER}
          >
            <CardProgramDetailsHeader />
          </div>

          <div>
            <CardProgramDetailsTable />
          </div>
        </>
      )}

      {loaders.getSelectedCardProgramDetailsAction && <AppSpinner />}
    </div>
  );
}

export default CardProgramDetailsPage;
