import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppImageComponent from '../../../../../../../components/AppImageComponent';
import { AppBody, AppIcon } from '../../../../../../../components/html/html';

function CardStatusImage({ grayCard = false }) {
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const { t } = useTranslation('cardsV2');

  return (
    <div className="relative z-10 mx-auto mb-3 h-[110px] w-[180px] overflow-hidden rounded-md shadow">
      <AppImageComponent
        src={settingsPopupCardDetails?.design?.attachment}
        alt={t('An image of a card')}
        skeletonWidth={180}
        skeletonHeight={110}
        className={grayCard ? 'grayscale' : 'grayscale-0'}
      />
      <div
        className="absolute left-2 top-3 flex items-center gap-2 text-[9px]"
        style={{ color: settingsPopupCardDetails?.design?.color_hex }}
      >
        <AppIcon
          iClass="Default"
          className={`fa-kit fa-nqoodlet-logo-english ${grayCard ? 'text-gray-6' : ''}`}
        />
      </div>
      <div
        className="absolute bottom-3 left-2 flex items-center tracking-wide"
        style={{ direction: 'ltr' }}
      >
        <AppBody
          pClass="Caption1Medium"
          className={settingsPopupCardDetails?.is_physical ? 'text-white' : 'text-black'}
        >
          {settingsPopupCardDetails?.last_four}
        </AppBody>
      </div>
    </div>
  );
}

export default CardStatusImage;
