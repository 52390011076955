import { useSelector } from 'react-redux';
import { cardActionsPopupContent } from '../../../../../helpers/constants';
import CardActionsPopupContent from './CardActionsPopupContent';
import CardActionsPopupHeader from './CardActionsPopupHeader';

function CardActionsPopup({ cardDetails, cardDetailsBalance, inPage }) {
  const { currentCardActionsPopupContent } = useSelector((state) => state.cardDetails);

  return (
    currentCardActionsPopupContent?.content && (
      <div
        className={`absolute -end-1 top-16 z-40 w-full overflow-y-auto rounded-2xl border border-gray-3 bg-white p-5 pt-0 shadow lg:end-1 lg:w-96 ${
          currentCardActionsPopupContent?.content === cardActionsPopupContent.statement
            ? 'lg:max-h-[600px]'
            : 'lg:max-h-[400px]'
        }`}
      >
        {/* Header */}
        <div className="sticky top-0 z-10 mb-3 bg-white pb-3 pt-5">
          <CardActionsPopupHeader />
        </div>

        {/* Content */}
        <div>
          <CardActionsPopupContent
            cardDetails={cardDetails}
            cardDetailsBalance={cardDetailsBalance}
            inPage={inPage}
          />
        </div>
      </div>
    )
  );
}

export default CardActionsPopup;
