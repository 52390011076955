import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

function AppImageComponent({ src, alt, skeletonHeight, skeletonWidth, className }) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <>
      {!isImageLoaded && <Skeleton height={skeletonHeight} width={skeletonWidth} />}

      <img
        className={className}
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        style={{ display: isImageLoaded ? 'block' : 'none' }}
      />
    </>
  );
}

export default AppImageComponent;
