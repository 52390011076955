import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import nqoodBG from '../../assets/png/nqoodBG.png';
import { employeeIdentityVerification } from '../../helpers/constants';
import { setIdentityVerificationsStepAction } from '../../redux/EmployeeRegistrationReducer/EmployeeRegistrationActions';
import EmployeeIdentityVerificationForm from './EmployeeIdentityVerificationForm';
import EmployeeIdentityVerificationFullNameForm from './EmployeeIdentityVerificationFullNameForm';

function EmployeeIdentityVerification() {
  const dispatch = useDispatch();

  const { activeIdentityVerification } = useSelector((state) => state.employeeRegistration);
  useEffect(() => {
    return () => {
      dispatch(
        setIdentityVerificationsStepAction(employeeIdentityVerification.identityVerificationForm),
      );
    };
  }, []);

  return (
    <div
      className="h-full bg-gray-1 bg-cover bg-no-repeat p-0 xxs:p-4 xs:p-7 md:p-12 lg:h-[98%] lg:rounded-xl lg:border lg:border-gray-5 lg:px-24 lg:py-9 xl:px-40"
      style={{ backgroundImage: `url(${nqoodBG})` }}
    >
      <div className="h-full overflow-y-auto rounded-2xl bg-gray-1 shadow-xl">
        {activeIdentityVerification === employeeIdentityVerification.identityVerificationForm && (
          <EmployeeIdentityVerificationForm />
        )}
        {activeIdentityVerification ===
          employeeIdentityVerification.identityVerificationFullNameForm && (
          <EmployeeIdentityVerificationFullNameForm />
        )}
      </div>
    </div>
  );
}

export default EmployeeIdentityVerification;
