import { appendURLParams } from 'javascript-functions';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppImageComponent from '../../../../components/AppImageComponent';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import ContactUs from './ContactUs';

import { history } from '../../../../AppRouter/history';
import { devLog, generateMockCard, getServerErrMessage } from '../../../../helpers/Utils';
import { cardStatusKeys, cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import {
  setActiveCreateNewCardStepAction,
  setPendingVirtualCardAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';

function ManualVirtualCardActivation() {
  const { loaders, useMock } = useSelector((state) => state);
  const { newCreatedCard, pendingPrimaryVirtualCard, selectedCardType, userPrimaryCard } =
    useSelector((state) => state.createNewCard);
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();

  const inactiveCardData = useMemo(() => {
    if (pendingPrimaryVirtualCard) {
      return pendingPrimaryVirtualCard;
    }
    if (newCreatedCard) {
      return newCreatedCard;
    }
    return userPrimaryCard;
  }, [newCreatedCard, pendingPrimaryVirtualCard, userPrimaryCard]);

  const activateButtonText = useMemo(() => {
    // in case user wants to activate virtual card
    if (!pendingPrimaryVirtualCard && selectedCardType?.name === cardTypesKeys.VIRTUAL) {
      devLog(`User wants to activate [Virtual] Card`);
      return t('Click to Activate');
    }

    // in case user wants to create plastic card but his primary card in inactive or suspended
    if (pendingPrimaryVirtualCard && selectedCardType?.name === cardTypesKeys.PHYSICAL) {
      devLog(
        `User wants to create [Plastic] Card and Primary card is ${pendingPrimaryVirtualCard.status.key}`,
      );
      return t('Click to Activate');
    }

    // in case user wants to create plastic card and doesn't have primary card
    // so he must create primary card first and the primary card needs manual activation
    if (!userPrimaryCard && selectedCardType?.name === cardTypesKeys.PHYSICAL) {
      devLog(`User wants to create [Plastic] Card, Need to Create and Activate Primary Card`);
      return t('Click to Activate');
    }

    // in case user wants to create plastic card and activated the inactive or suspended primary card
    // then he continues to create plastic card process
    if (
      !pendingPrimaryVirtualCard &&
      selectedCardType?.name === cardTypesKeys.PHYSICAL &&
      userPrimaryCard
    ) {
      devLog(`User Activated his Primary Card!`);
      return t('Continue To Create Your Plastic Card');
    }

    return t('Click to Activate');
  }, [selectedCardType, userPrimaryCard, pendingPrimaryVirtualCard]);

  const handleActivateCard = useCallback(() => {
    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('status', cardStatusKeys.ACTIVE);

    let mockParams = {};

    if (useMock.createVirtualCardMock) {
      mockParams = generateMockCard({ status: useMock.createVirtualCardMockStatus });
    }
    if (useMock.replaceVirtualCardMock) {
      mockParams = generateMockCard({ status: useMock.replaceVirtualCardMockStatus });
    }
    if (useMock.hasPrimaryCardMock) {
      mockParams = generateMockCard({ status: useMock.hasPrimaryCardMockStatus });
    }

    http
      .post(`cardmanager/cards/${inactiveCardData.id}/status`, formData, {
        loader: 'handleActivateCard',
        useMock:
          useMock.createVirtualCardMock ||
          useMock.replaceVirtualCardMock ||
          useMock.hasPrimaryCardMock,
        mockParams,
      })
      .then(() => {
        if (pendingPrimaryVirtualCard) {
          devLog(`Pending Primary Card Activated, Back to Plastic Card Creation.`);
          dispatch(setPendingVirtualCardAction(null));
        } else {
          devLog(`Card Activated, Continue to Card Control.`);
          dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.manageCardControl));
        }
      })
      .catch((err) => {
        history.replace({
          search: appendURLParams('creationError', getServerErrMessage(err)),
        });
        dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardActivationFailed));
      });
  }, [inactiveCardData, useMock]);

  return (
    <div className="mx-auto mt-[22vh] max-w-[350px] px-5 pb-5 sm:px-0">
      {!pendingPrimaryVirtualCard && <AppHeader h="h3">{t('Your Card is Ready')}</AppHeader>}

      {pendingPrimaryVirtualCard && (
        <>
          <AppHeader h="h3">{t("Let's Activate Your Primary Virtual Card")}</AppHeader>
          <AppBody pClass="Body2Medium" className="text-gray-6">
            {t('You must activate this card before create your Plastic Card')}
          </AppBody>
        </>
      )}

      <div
        className={`my-5 overflow-hidden rounded-md shadow-xl ${
          pendingPrimaryVirtualCard ? 'blur-[2px]' : ''
        }`}
      >
        <AppImageComponent
          skeletonWidth={350}
          skeletonHeight={226}
          src={inactiveCardData?.design?.attachment}
          alt="Ready Card"
        />
      </div>

      <AppButton
        onClick={() => {
          if (
            !pendingPrimaryVirtualCard &&
            selectedCardType?.name === cardTypesKeys.PHYSICAL &&
            userPrimaryCard
          ) {
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.shippingDetails));
          } else {
            handleActivateCard();
          }
        }}
        isLoading={loaders.handleActivateCard}
        button="black"
        className="mb-12 mt-4 w-full"
        size="md"
      >
        {activateButtonText}
      </AppButton>

      <ContactUs
        header={t('Or Contact Us', { ns: 'commonV2' })}
        emailSubject="Manual Virtual Card Activation"
      />
    </div>
  );
}

export default ManualVirtualCardActivation;
