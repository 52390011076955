import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../../../../components/Button/ActionButton';
import {TextInput} from '../../../../components/FormInputs/Inputs';
import {apiGetRequest, getCancelToken} from '../../../../helpers/APIHelper';
import {setAction} from '../../../../helpers/ActionsHelper';
import {NumberValidationPattern} from '../../../../helpers/RegexPatterns';
import useNotifications from '../../../../helpers/useNotifications';
import {SET_IS_MANAGER, SET_SELECTED_CR} from '../../../../redux/AuthReducer';
import CompanyOwnerData from './CompanyOwnerData';
import {AppBody} from "../../../../components/html/html";

function RelatedCR(props) {
  const { user } = props;
  const { t, i18n } = useTranslation(['signup', 'common']);

  const [cookies] = useCookies(['token']);
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [noRelatedCompanies, setNoRelatedCompanies] = useState(null);
  const [crNumberSelected, setCrNumberSelected] = useState(null);
  const [selectOther, SetSelectOther] = useState(false);
  const [otherObj, SetOtherObj] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancelToken = getCancelToken();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [, addNotification] = useNotifications();
  const [isComplete, setIsComplete] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);
    apiGetRequest(`users/cr/${data.cr}`, cookies.token)
      .then((response) => {
        SetOtherObj(response);
        setIsLoading(false);
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    apiGetRequest(
      'users/related',
      cookies.token,
      {
        id_number: user && user.id_number,
        id_type: user && user.id_type,
      },
      [],
      {},
      false,
    )
      .then((response) => {
        if (response.length === 0) {
          setNoRelatedCompanies(t('company_account.no_related'));
        } else {
          setNoRelatedCompanies(null);
        }
        setRelatedCompanies(response);
      })
      .catch(() => {
        setNoRelatedCompanies(t('company_account.no_related'));
      });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  return (
    <div className="flex flex-col">
      {!isComplete && (
        <>
          <AppBody pClass="Body1Bold" className="pb-2">
            {t('company_account.select_company')}
          </AppBody>
          <div className="mb-2 flex flex-row items-baseline justify-between self-stretch rounded-xl border border-gray-3 p-4">
            {noRelatedCompanies === null && relatedCompanies.length === 0 && (
              <Skeleton count={3} containerClassName="w-full" />
            )}
            {noRelatedCompanies !== null && relatedCompanies.length === 0 && (
              <span className="w-full text-center text-red-700">{noRelatedCompanies}</span>
            )}
          </div>
          {relatedCompanies.length > 0 &&
            relatedCompanies.map((relatedCompany) => (
              <div
                key={relatedCompany.crNumber}
                className={`mb-2 flex flex-row items-baseline justify-between self-stretch rounded-xl border border-gray-3 p-4 ${
                  !relatedCompany.allowChoose ? 'text-gray-6' : ''
                }`}
              >
                <div className="radio-item">
                  <input
                    className="scale-125 transform"
                    type="radio"
                    name="selected_company"
                    id={`selected_company_${relatedCompany.crNumber}`}
                    value={relatedCompany.crNumber}
                    disabled={!relatedCompany.allowChoose}
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        setCrNumberSelected(evt.target.value);
                        SetSelectOther(false);
                      }
                    }}
                  />
                  <label htmlFor={`selected_company_${relatedCompany.crNumber}`}>
                    {relatedCompany.crName}
                  </label>
                </div>
              </div>
            ))}

          <div
            key="other"
            className="justify-between} mb-2 flex flex-row items-baseline self-stretch rounded-xl border border-gray-3 p-4"
          >
            <div className="radio-item">
              <input
                className="scale-125 transform"
                type="radio"
                name="selected_company"
                id="selected_company_other"
                value="other"
                onChange={(evt) => {
                  if (evt.target.checked) {
                    SetSelectOther(true);
                    setCrNumberSelected(null);
                  }
                }}
              />
              <label htmlFor="selected_company_other">{t('verify_company.other')}</label>
            </div>
          </div>

          {selectOther && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-grow flex-col items-start justify-start self-stretch"
            >
              <div className="flex w-full flex-col justify-start">
                <div className="flex flex-grow flex-row self-start self-stretch">
                  <AppBody pClass="Body1Bold" type="label" htmlFor="cr" className="flex flex-grow flex-col">
                    {t('verify_company.cr_number')}
                    <TextInput
                      error={errors.cr}
                      id="cr"
                      {...register('cr', {
                        required: t('verify_company.cr_number_required'),
                        minLength: {
                          value: 10,
                          message: t('verify_company.cr_number_min'),
                        },
                        pattern: {
                          value: NumberValidationPattern,
                          message: t('verify_company.cr_number_pattern'),
                        },
                      })}
                    />
                  </AppBody>
                </div>
              </div>
              <div className="flex flex-row justify-end self-stretch pt-2">
                <ActionButton
                  text={t('send', { ns: 'common' })}
                  type="submit"
                  className="-mt-2 flex flex-row text-primary"
                  isLoading={isLoading}
                />
              </div>
            </form>
          )}

          {otherObj && (
            <label
              htmlFor="other_company"
              className="mb-2 flex flex-row items-baseline justify-between self-stretch rounded-xl bg-gray-2 p-4 text-right"
            >
              {otherObj.crName}
            </label>
          )}

          <div className="mt-5 flex flex-row justify-end self-stretch">
            {crNumberSelected && (
              <ActionButton
                className="flex flex-row"
                primary
                text={t('next', { ns: 'common' })}
                onClick={() => {
                  dispatch(setAction(SET_IS_MANAGER, 1));
                  dispatch(setAction(SET_SELECTED_CR, crNumberSelected));
                  setIsComplete(true);
                }}
              />
            )}
            {selectOther && otherObj && (
              <ActionButton
                className="flex flex-row"
                primary
                text={t('next', { ns: 'common' })}
                onClick={() => {
                  dispatch(setAction(SET_IS_MANAGER, 0));
                  dispatch(setAction(SET_SELECTED_CR, otherObj.crNumber));
                  setIsComplete(true);
                }}
              />
            )}
          </div>
        </>
      )}
      {isComplete && (
        <CompanyOwnerData
          isManager={!!crNumberSelected}
          crNumber={crNumberSelected || otherObj.crNumber}
        />
      )}
    </div>
  );
}

export default RelatedCR;
