const INITIAL_STATE = {
  activeCreateNewCardStep: null,
  createCardUserData: null,
  userHasCardHolder: null,
  cardPrograms: null,
  selectedCardProgram: null,
  availableCardTypes: null,
  selectedCardType: null,
  userAgreeTerm: null,
  selectedCardDesign: null,
  otpData: null,
  newCreatedCard: null,
  userPrimaryCard: null,
  pendingPrimaryVirtualCard: null,
  shippingAddress: null,
  cardToReplace: null,
};

const CreateNewCardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_ACTIVE_CREATE_NEW_CARD_STEP': {
      return { ...state, activeCreateNewCardStep: action.payload };
    }

    case 'SET_CREATE_CARD_USER_DATA': {
      return { ...state, createCardUserData: action.payload };
    }

    case 'SET_HAS_CARD_HOLDER': {
      return { ...state, userHasCardHolder: action.payload };
    }

    case 'ADD_CARD_PROGRAMS': {
      return { ...state, cardPrograms: action.payload };
    }

    case 'SELECT_CARD_PROGRAM': {
      return { ...state, selectedCardProgram: action.payload };
    }

    case 'SET_AVAILABLE_CARD_TYPES': {
      return { ...state, availableCardTypes: action.payload };
    }

    case 'SELECT_CARD_TYPE': {
      return { ...state, selectedCardType: action.payload };
    }

    case 'SET_USER_AGREE_TERM': {
      return { ...state, userAgreeTerm: action.payload };
    }

    case 'SELECT_CARD_DESIGN': {
      return { ...state, selectedCardDesign: action.payload };
    }

    case 'ADD_OTP_DATA': {
      return { ...state, otpData: action.payload };
    }

    case 'SAVE_NEW_CREATED_CARD': {
      return { ...state, newCreatedCard: action.payload };
    }

    case 'SET_USER_PRIMARY_CARD': {
      return { ...state, userPrimaryCard: action.payload };
    }

    case 'SET_PENDING_VIRTUAL_CARD': {
      return { ...state, pendingPrimaryVirtualCard: action.payload };
    }

    case 'SET_SHIPPING_ADDRESS': {
      return { ...state, shippingAddress: action.payload };
    }

    case 'SELECT_CARD_TO_REPLACE': {
      return { ...state, cardToReplace: action.payload };
    }

    case 'CLEAR_ALL': {
      return { ...INITIAL_STATE };
    }
    default:
      return state;
  }
};

export default CreateNewCardReducer;
