import { employeeIdentityVerification, employeeRegistrationSteps } from '../../helpers/constants';

const INITIAL_STATE = {
  activeRegistrationStep: employeeRegistrationSteps.activateAccount,
  activeIdentityVerification: employeeIdentityVerification.identityVerificationForm,
};

const EmployeeRegistrationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_REGISTRATION_STEP': {
      return { ...state, activeRegistrationStep: action.payload };
    }
    case 'SET_IDENTITY_VERIFICATIONS_STEP': {
      return { ...state, activeIdentityVerification: action.payload };
    }
    default:
      return state;
  }
};

export default EmployeeRegistrationReducer;
