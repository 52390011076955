import { AppBody, AppHeader } from '../html/html';

function StepperSidebar({
  sideBarHeader,
  steps,
  activeStepIndex,
  completedSteps,
  mobileLineWidth,
}) {
  return (
    <div className="grid gap-4">
      <AppHeader h="h4" className="mb-8 hidden lg:block">
        {sideBarHeader}
      </AppHeader>
      <div className="z-10 mx-auto flex w-full justify-between gap-4 lg:flex-col">
        {steps.map((ele, i) => {
          const isCompletedStep = completedSteps[i];
          const isActiveStep = activeStepIndex === i;
          const showLine = activeStepIndex > i;

          let outerCircleClasses =
            'lg:absolute lg:-end-[63px] lg:top-[50%] lg:z-10 flex h-16 w-16 lg:h-12 lg:w-12 lg:-translate-y-[50%] items-center justify-center rounded-full shadow-lg translate-y-12';

          if (isCompletedStep && !isActiveStep && activeStepIndex > i) {
            outerCircleClasses += ' border-0 bg-primary';
          } else if (isActiveStep) {
            outerCircleClasses += ' border-4 border-primary bg-white';
          } else if (!isActiveStep) {
            outerCircleClasses += ' border border-gray-4 bg-white';
          }

          return (
            <div
              key={ele.value}
              className={`relative me-3 w-0 items-center rounded-xl bg-white text-start shadow-lg lg:flex lg:min-h-[75px] lg:w-auto lg:px-4 ${
                isActiveStep ? 'border-primary lg:border-2' : 'border-gray-4 lg:border'
              }`}
            >
              <AppBody pClass="Body1Medium" type="span" className="hidden lg:block">
                {ele.label}
              </AppBody>

              {showLine && (
                <>
                  {/* line desktop */}
                  <div className="absolute top-[150%] hidden h-[55px] w-[3px] -translate-y-[100%] bg-primary lg:block ltr:-right-[40px] rtl:-left-[70px]" />

                  {/* line mobile */}
                  <div
                    className="absolute top-[84px] h-[3px] bg-primary lg:hidden"
                    style={{ width: mobileLineWidth }}
                  />
                </>
              )}

              {/* Circle */}
              <div className={outerCircleClasses}>
                {isActiveStep && (
                  <div
                    className={`${
                      !isActiveStep ? 'hidden' : ''
                    } h-8 w-8 rounded-full bg-primary lg:h-6 lg:w-6`}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StepperSidebar;
