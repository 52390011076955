/* eslint-disable no-unsafe-optional-chaining */
import { appendURLParams, getURLParams } from 'javascript-functions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../../AppRouter/history';
import AppGrowTabs from '../../../../../components/AppGrowTabs/AppGrowTabs';
import AppNoData from '../../../../../components/AppNoData';
import AppPagination from '../../../../../components/AppPagination';
import AppSpinner from '../../../../../components/AppSpinner';
import AppTabs from '../../../../../components/AppTabs';
import AppSelect from '../../../../../components/FormInputs/AppSelect';
import TimeRangeFilter from '../../../../../components/SharedComponents/TimeRangeFilter';
import WalletTransactionsTypeFilters from '../../../../../components/SharedComponents/WalletTransactionsTypeFilters';
import { DynamicElement } from '../../../../../components/html/html';
import { devLog, formatDate } from '../../../../../helpers/Utils';
import {
  transactionsSharedParams,
  walletDetailsSectionIds,
  walletTabs,
} from '../../../../../helpers/constants';
import { hideModalAction } from '../../../../../redux/AppModalReducer/AppModalActions';
import {
  getPendingRequestsCountAction,
  getWalletCardsActivityAction,
  getWalletFundsActivityAction,
  getWalletRequestsAction,
  getWalletTransactionsAction,
  hideAllWalletPopups,
  hideWalletFilterPopupAction,
  setActiveWalletMainTabAction,
  setActiveWalletSubTabAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import ViewWalletReceipt from '../ViewWalletReceipt';
import WalletFilterPopup from '../WalletFilterPopup';
import WalletSearchPopup from '../WalletSearchPopup';
import CardProgramDetailsFilterTags from './CardProgramDetailsFilterTags';
import WalletCardsActivityTableBody from './WalletCardsActivityTableBody';
import WalletFundsActivityTableBody from './WalletFundsActivityTableBody';
import WalletRequestsTableBody from './WalletRequestsTableBody';
import WalletTableActionButtons from './WalletTableActionButtons';
import WalletTransactionsTableBody from './WalletTransactionsTableBody';

function CardProgramDetailsTable() {
  const { loaders, appConfig } = useSelector((state) => state);
  const { isMobile } = useSelector((state) => state.appConfig);
  const {
    selectedWalletSubActiveTab,
    selectedWalletMainActiveTab,
    walletRequests,
    walletFundActivities,
    walletCardActivities,
    walletPendingRequestsCount,
    walletTransactions,
  } = useSelector((state) => state.wallet);
  const { t } = useTranslation(['walletV2', 'commonV2']);
  const dispatch = useDispatch();

  const isLoadingList = useMemo(() => {
    return (
      loaders.getWalletRequestsAction ||
      loaders.getWalletTransactionsAction ||
      loaders.getWalletFundsActivityAction ||
      loaders.getWalletCardsActivityAction
    );
  }, [loaders]);

  const totalPages = useMemo(() => {
    if (selectedWalletMainActiveTab === walletTabs.REQUESTS) {
      return walletRequests?.paginator?.total_pages;
    }
    if (selectedWalletMainActiveTab === walletTabs.TRANSACTIONS) {
      return walletTransactions?.paginator?.total_pages;
    }
    if (
      selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
      selectedWalletSubActiveTab === walletTabs.FUNDSACTIVITY
    ) {
      return walletFundActivities?.paginator?.total_pages;
    }
    if (
      selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
      selectedWalletSubActiveTab === walletTabs.CARDSACTIVITY
    ) {
      return walletCardActivities?.paginator?.total_pages;
    }

    return 1;
  }, [
    selectedWalletMainActiveTab,
    walletRequests,
    walletTransactions,
    walletFundActivities,
    walletCardActivities,
  ]);

  const tableMainTabs = useMemo(() => {
    return [
      {
        key: walletTabs.REQUESTS,
        label: t('Requests'),
        badgeNumber: walletPendingRequestsCount,
      },
      {
        key: walletTabs.TRANSACTIONS,
        label: t('Transactions'),
        badgeNumber: 0,
      },
      {
        key: walletTabs.ACTIVITIES,
        label: t('Activities'),
        badgeNumber: 0,
      },
    ];
  }, [t, walletPendingRequestsCount]);

  const requestsSubTabs = useMemo(() => {
    return [
      {
        key: walletTabs.ALL,
        label: t('All', { ns: 'commonV2' }),
      },
      {
        key: walletTabs.PENDING,
        label: t('Pending', { ns: 'commonV2' }),
        searchParam: [{ status_id: 1 }],
      },
      {
        key: walletTabs.APPROVED,
        label: t('Approved', { ns: 'commonV2' }),
        searchParam: [{ status_id: 2 }],
      },
      {
        key: walletTabs.REJECTED,
        label: t('Rejected', { ns: 'commonV2' }),
        searchParam: [{ status_id: 3 }],
      },
    ];
  }, [t]);

  const transactionsSubTabs = useMemo(() => {
    return [
      {
        key: walletTabs.EXPENSES,
        label: t('Expenses'),
        searchParam: [{ is_expense: 1 }],
      },
      {
        key: walletTabs.DECLINES,
        label: t('Declines'),
        searchParam: [{ status: 0 }],
      },
      {
        key: walletTabs.REFUNDS,
        label: t('Refunds'),
        searchParam: [
          {
            is_refund_and_reversal: 1,
          },
        ],
      },
      {
        key: walletTabs.LOADS,
        label: t('Loads'),
        searchParam: [
          {
            'transaction_type[0]': 2,
            'transaction_type[1]': 3,
          },
        ],
      },
      // {
      //   key: walletTabs.ALL,
      //   label: t('All', { ns: 'commonV2' }),
      // },
    ];
  }, [t]);

  const activitiesSubTabs = useMemo(() => {
    return [
      {
        key: walletTabs.FUNDSACTIVITY,
        label: t('Funds Activity'),
      },
      {
        key: walletTabs.CARDSACTIVITY,
        label: t('Cards Activity'),
        searchParam: [{ created_at: 'desc' }],
      },
    ];
  }, [t]);

  const tableSubTabs = useMemo(() => {
    if (selectedWalletMainActiveTab === walletTabs.REQUESTS) {
      return requestsSubTabs;
    }

    if (selectedWalletMainActiveTab === walletTabs.TRANSACTIONS) {
      return transactionsSubTabs;
    }

    if (selectedWalletMainActiveTab === walletTabs.ACTIVITIES) {
      return activitiesSubTabs;
    }

    return [];
  }, [selectedWalletMainActiveTab, t]);

  const customTabsDDLStyles = useMemo(() => {
    return {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        boxShadow: 'none',
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0',
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: 'black',
      }),
      singleValue: (provided) => ({
        ...provided,
        fontWeight: 'bold',
      }),
    };
  }, []);

  const transactionTypeOptions = useMemo(() => {
    return [
      {
        label: t('Auth'),
        value: 'AUTH',
        searchParam: [
          {
            'transaction_type[]': 4,
          },
        ],
      },
      {
        label: t('Settled'),
        value: 'SETTLEMENT',
        searchParam: [
          {
            'transaction_type[]': 5,
          },
        ],
      },
      {
        label: t('Split'),
        value: 'SPLIT',
        searchParam: [
          {
            'transaction_type[]': 7,
          },
        ],
      },
    ];
  }, [t]);

  // ! Important, Need to change default value if the default main tab or sub tab changed!
  const [activeSubTabParams, setActiveSubTabParams] = useState(requestsSubTabs[1].searchParam[0]);
  const [paginationParams, setPaginationParams] = useState({ page: 1 });
  const [timeRangeParams, setTimeRangeParams] = useState({});
  const [requestsDates, setRequestsDates] = useState({});
  const [exportParams, setExportParams] = useState({});
  const [filterTags, setFilterTags] = useState({});
  const [filtersPopupParams, setFiltersPopupParams] = useState({});
  const [searchPopupParams, setSearchPopupParams] = useState({});

  const [transactionsDates, setTransactionsDates] = useState({});
  const [transactionType, setTransactionType] = useState(transactionTypeOptions[0]);
  const [transactionTypeParams, setTransactionTypeParams] = useState(
    transactionTypeOptions[0].searchParam[0],
  );

  const createKeywordTagObj = useCallback((keyword) => {
    return {
      filterQueryName: 'keyword',
      filterName: t('Search By'),
      filterValue: keyword,
      formValue: null,
    };
  }, []);

  const handleSearch = useCallback(
    (keyword) => {
      setSearchPopupParams({ keyword });
      setFilterTags((prev) => {
        return {
          ...prev,
          keyword: createKeywordTagObj(keyword),
        };
      });

      if (isMobile) {
        dispatch(hideModalAction());
      }
    },
    [isMobile],
  );

  const resetPagination = useCallback(() => {
    setPaginationParams({ page: 1 });
  }, []);

  const resetKeywordSearchParams = useCallback(() => {
    setSearchPopupParams({});
  }, []);

  const handleRemoveSinglePopupFilter = useCallback((singleFilter) => {
    dispatch(hideWalletFilterPopupAction());

    setFilterTags((prev) => {
      const updated = { ...prev };
      delete updated[singleFilter.filterQueryName];
      return updated;
    });

    if (singleFilter.filterQueryName === 'keyword') {
      resetKeywordSearchParams();
    } else {
      setFiltersPopupParams((prev) => {
        const updated = { ...prev };
        if (singleFilter.filterQueryName === 'amount') {
          delete updated.from_amount;
          delete updated.to_amount;
          delete updated.currency;
        } else {
          delete updated[singleFilter.filterQueryName];
        }
        return updated;
      });
    }
  }, []);

  const handleOnSearch = useCallback(
    (keyword) => {
      handleSearch(keyword);
      resetPagination();
    },
    [searchPopupParams],
  );

  const handleApplyFilters = useCallback(
    (values) => {
      const queryObject = {};
      const tags = {};
      values.filters.forEach((ele) => {
        if (ele.filter.name === 'amount') {
          Object.entries(ele.subFilter).forEach(([key, { value }]) => {
            queryObject[key] = value;
          });

          tags.amount = {
            filterQueryName: 'amount',
            filterName: ele.filter.label,
            filterValue: `${queryObject.from_amount} - ${queryObject.to_amount}- ${queryObject.currency}`,
            formValue: ele,
          };
        } else {
          queryObject[ele.filter.queryName] = ele.subFilter.value;
          tags[ele.filter.queryName] = {
            filterQueryName: ele.filter.queryName,
            filterName: ele.filter.label,
            filterValue: ele.subFilter.label,
            formValue: ele,
          };
        }
      });

      setFiltersPopupParams({ ...queryObject });
      resetPagination();
      setFilterTags(() => {
        if (searchPopupParams.keyword) {
          return {
            keyword: createKeywordTagObj(searchPopupParams.keyword),
            ...tags,
          };
        }
        return tags;
      });

      if (isMobile) {
        dispatch(hideModalAction());
      }
    },
    [searchPopupParams, isMobile],
  );

  const resetSearchParams = useCallback(() => {
    setTimeRangeParams({ days: 30 });
    resetPagination();
    resetKeywordSearchParams();
    setFiltersPopupParams({});
    setFilterTags({});
  }, []);

  const handleMainTabChange = useCallback(
    (tab) => {
      dispatch(setActiveWalletMainTabAction(tab.key));
      dispatch(hideAllWalletPopups());

      if (JSON.stringify(transactionType) !== JSON.stringify(transactionTypeOptions[0])) {
        setTransactionType(transactionTypeOptions[0]);
      }
    },
    [transactionType],
  );

  const handleSubTabChange = useCallback(
    (tab) => {
      if (JSON.stringify(transactionType) !== JSON.stringify(transactionTypeOptions[0])) {
        setTransactionType(transactionTypeOptions[0]);
      }

      dispatch(hideAllWalletPopups());

      const tabParams = {};

      if (tab?.searchParam) {
        tab.searchParam?.forEach((ele) => {
          Object.entries(ele).forEach(([key, value]) => {
            tabParams[key] = value;
          });
        });
      }

      // This to prevent multiple API call because of the change of activeSubTabParams
      if (JSON.stringify(tabParams) !== JSON.stringify(activeSubTabParams)) {
        setActiveSubTabParams(tabParams);
      }

      resetSearchParams();
      dispatch(setActiveWalletSubTabAction(tab?.key));
    },
    [activeSubTabParams, transactionType],
  );

  const handleApplyTimeRangeFilter = useCallback((queryObj) => {
    history.replace({ search: appendURLParams('timeRangeFilter', JSON.stringify(queryObj)) });

    setTimeRangeParams((prev) => {
      const prevSearchParams = { ...prev };
      const newQueryObj = { ...queryObj };

      if (newQueryObj.from_date && newQueryObj.to_date && prevSearchParams.days) {
        delete prevSearchParams.days;
      }
      if (prevSearchParams.from_date && prevSearchParams.to_date && newQueryObj.days) {
        delete prevSearchParams.from_date;
        delete prevSearchParams.to_date;
      }

      return { ...prevSearchParams, ...newQueryObj };
    });
    resetPagination();
  }, []);

  const handlePaginate = useCallback((page) => {
    setPaginationParams((prev) => {
      return {
        ...prev,
        page,
      };
    });
  }, []);

  const updateTransactionTypeParams = useCallback(
    (params) => {
      devLog(
        `In: [${selectedWalletSubActiveTab}], Clearing transaction_type[n] and getting values...`,
      );
      const transactionTypeRegex = /^transaction_type\[\d+\]$/;
      const updatedParams = {};
      Object.entries(params).forEach(([key, value]) => {
        if (!transactionTypeRegex.test(key)) {
          updatedParams[key] = value;
        }
      });

      return updatedParams;
    },
    [selectedWalletSubActiveTab],
  );

  const addConditionalReversalStatus = useCallback(
    (params) => {
      devLog(
        `In: [${selectedWalletSubActiveTab}], Adding Conditional Status if Type is ((REVERSAL))...`,
      );

      const updatedParams = { ...params };
      if (updatedParams['transaction_type[]'] === '1') {
        updatedParams.status = 1;
      }

      return updatedParams;
    },
    [selectedWalletSubActiveTab],
  );

  // ! ================ This is FIRST Transaction Call ================ //
  const handleTimeRangeChange = useCallback((queryObj) => {
    devLog('>>>>>>>>>> This is FIRST Transaction Call <<<<<<<<<<');
    handleApplyTimeRangeFilter(queryObj);
  }, []);

  useEffect(() => {
    if (selectedWalletMainActiveTab === walletTabs.REQUESTS) {
      handleSubTabChange(requestsSubTabs[1]);
    }

    if (selectedWalletMainActiveTab === walletTabs.TRANSACTIONS) {
      handleSubTabChange(transactionsSubTabs[0]);
    }

    if (selectedWalletMainActiveTab === walletTabs.ACTIVITIES) {
      handleSubTabChange(activitiesSubTabs[0]);
    }
  }, [selectedWalletMainActiveTab]);

  useEffect(() => {
    const params = {};
    transactionType.searchParam.forEach((ele) => {
      Object.entries(ele).forEach(([key, value]) => {
        params[key] = value;
      });
    });
    setTransactionTypeParams(params);
  }, [transactionType]);

  useEffect(() => {
    const dates = {};
    if (walletRequests?.data) {
      walletRequests?.data?.forEach((ele) => {
        const formattedDate = formatDate(ele.created_at, 'DD MMM YYYY');
        dates[formattedDate] = formattedDate;
      });
      setRequestsDates(dates);
    }
  }, [walletRequests]);

  useEffect(() => {
    const dates = {};
    if (walletTransactions?.data) {
      walletTransactions.data.forEach((ele) => {
        const formattedDate = formatDate(ele.transaction_timestamp, 'DD MMM YYYY');
        dates[formattedDate] = formattedDate;
      });
      setTransactionsDates(dates);
    }
  }, [walletTransactions]);

  useEffect(() => {
    const dates = {};
    if (walletFundActivities?.data) {
      walletFundActivities?.data?.forEach((ele) => {
        const formattedDate = formatDate(ele.created_at, 'DD MMM YYYY');
        dates[formattedDate] = formattedDate;
      });
      setRequestsDates(dates);
    }
  }, [walletFundActivities]);

  useEffect(() => {
    const dates = {};
    if (walletCardActivities?.data) {
      walletCardActivities?.data?.forEach((ele) => {
        const formattedDate = formatDate(ele.created_at, 'DD MMM YYYY');
        dates[formattedDate] = formattedDate;
      });
      setRequestsDates(dates);
    }
  }, [walletCardActivities]);

  useEffect(() => {
    if (selectedWalletMainActiveTab === walletTabs.REQUESTS) {
      dispatch(getPendingRequestsCountAction());
    }
  }, [selectedWalletMainActiveTab]);

  // =========== Getting Data Based on Tab =========== //
  useEffect(() => {
    if (Object.values(timeRangeParams).length) {
      const params = {
        card_product_id: getURLParams('selectedCardProgramId'),
        ...activeSubTabParams,
        ...paginationParams,
        ...timeRangeParams,
        ...searchPopupParams,
        ...filtersPopupParams,
      };

      setExportParams(params);

      // =================== REQUESTS =================== //
      if (selectedWalletMainActiveTab === walletTabs.REQUESTS) {
        devLog(
          `[${selectedWalletMainActiveTab}] is Selected, Getting [${selectedWalletSubActiveTab}] Data...`,
        );
        dispatch(getWalletRequestsAction({ params }));
      }

      // =================== TRANSACTIONS =================== //
      if (selectedWalletMainActiveTab === walletTabs.TRANSACTIONS) {
        devLog(
          `[${selectedWalletMainActiveTab}] is Selected, Getting [${selectedWalletSubActiveTab}] Data...`,
        );

        // ================ EXPENSES With Filter ================ //
        if (selectedWalletSubActiveTab === walletTabs.EXPENSES) {
          const PARAMS = { ...params, ...transactionTypeParams };
          // if transactionTypeOption is "Settled"
          if (PARAMS['transaction_type[]'] === 5) {
            delete PARAMS.is_expense;
          }
          dispatch(getWalletTransactionsAction({ params: PARAMS }));

          // ================ (REFUNDS || LOADS) With Filter ================ //
        } else if (
          (selectedWalletSubActiveTab === walletTabs.REFUNDS ||
            selectedWalletSubActiveTab === walletTabs.LOADS) &&
          params['transaction_type[]']
        ) {
          let updatedParams = updateTransactionTypeParams(params);
          updatedParams = addConditionalReversalStatus(params);

          // Delete is_refund_and_reversal since we need only one of them
          delete updatedParams.is_refund_and_reversal;

          setExportParams(updatedParams);
          dispatch(getWalletTransactionsAction({ params: updatedParams }));

          // ================ Rest of Tabs... ================ //
        } else {
          dispatch(getWalletTransactionsAction({ params }));
        }
      }

      // =================== ACTIVITIES =================== //
      if (
        selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
        selectedWalletSubActiveTab === walletTabs.FUNDSACTIVITY
      ) {
        dispatch(getWalletFundsActivityAction({ params }));
      }
      if (
        selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
        selectedWalletSubActiveTab === walletTabs.CARDSACTIVITY
      ) {
        dispatch(getWalletCardsActivityAction({ params }));
      }
    }
  }, [
    activeSubTabParams,
    paginationParams,
    timeRangeParams,
    searchPopupParams,
    filtersPopupParams,
    selectedWalletSubActiveTab,
    transactionTypeParams,
  ]);

  useEffect(() => {
    setTransactionType((prev) => {
      const updated = transactionTypeOptions.find((option) => option.value === prev?.value);
      return updated || transactionTypeOptions[0];
    });
  }, [appConfig.lang, transactionTypeOptions]);

  return (
    <>
      {/* Table Header */}
      <div
        className="bg-white pt-2 lg:sticky lg:z-10"
        style={{
          top: document.getElementById(walletDetailsSectionIds.WALLET_DETAILS_HEADER)?.clientHeight,
        }}
      >
        {/* View Wallet Receipt Popup */}
        <ViewWalletReceipt />

        {/* Filter Popup */}
        {!isMobile && <WalletFilterPopup filterTags={filterTags} onApply={handleApplyFilters} />}

        {/* Search Popup */}
        {!isMobile && <WalletSearchPopup onSearch={handleOnSearch} />}

        {/* Table Main Tabs */}
        <div className="mb-11">
          <AppGrowTabs
            isDisabled={isLoadingList}
            defaultActiveTab={tableMainTabs.find((ele) => ele.key === selectedWalletMainActiveTab)}
            onTabChange={(tab) => {
              handleMainTabChange(tab);
            }}
            tabs={tableMainTabs}
          />
        </div>

        {/* Mobile Table Sub Tab DDL */}
        <div className="mb-4 lg:hidden">
          <AppSelect
            styleType="custom"
            className="w-full rounded-2xl border-0 text-sm shadow xs:text-base"
            key={selectedWalletMainActiveTab}
            mobileSelect
            options={tableSubTabs.map((ele) => ({ ...ele, value: ele.label }))}
            value={{
              value: tableSubTabs.find((ele) => ele.key === selectedWalletSubActiveTab)?.label,
            }}
            onChange={(val) => {
              handleSubTabChange(val);
            }}
          />
        </div>

        <div className="lg-px-0 flex items-center justify-between px-1">
          {/* Desktop Table Sub Tabs */}
          {!isMobile && (
            <AppTabs
              isDisabled={isLoadingList}
              defaultActiveTab={tableSubTabs.find((ele) => ele.key === selectedWalletSubActiveTab)}
              styleType="line"
              onTabChange={(tab) => {
                if (tab.key !== selectedWalletSubActiveTab) {
                  handleSubTabChange(tab);
                }
              }}
              tabs={tableSubTabs}
            />
          )}

          <WalletTableActionButtons
            isDisabled={isLoadingList}
            transactionsSharedParams={transactionsSharedParams}
            exportParams={exportParams}
            transactionTypeParams={transactionTypeParams}
            filterTags={filterTags}
            handleOnSearch={handleOnSearch}
            handleApplyFilters={handleApplyFilters}
            handleTimeRangeChange={handleTimeRangeChange}
          />
        </div>

        <div className={`hidden lg:block ${isLoadingList ? 'pointer-events-none opacity-50' : ''}`}>
          {/* Desktop Time Range Filter */}
          <TimeRangeFilter
            activeTab={selectedWalletSubActiveTab}
            handleTimeRangeChange={handleTimeRangeChange}
          />

          {/* Desktop Transactions Type Filter */}
          {selectedWalletMainActiveTab === walletTabs.TRANSACTIONS &&
            selectedWalletSubActiveTab === walletTabs.EXPENSES && (
              <WalletTransactionsTypeFilters
                key={appConfig.lang}
                transactionTypeOptions={transactionTypeOptions}
                value={transactionType}
                onChange={(value) => {
                  setTransactionType(value);
                }}
              />
            )}
        </div>

        <CardProgramDetailsFilterTags
          filterTags={filterTags}
          handleRemoveSinglePopupFilter={handleRemoveSinglePopupFilter}
        />
      </div>

      {/* Mobile Transactions Type Filter */}
      <div className="flex justify-end lg:hidden">
        {selectedWalletMainActiveTab === walletTabs.TRANSACTIONS &&
          selectedWalletSubActiveTab === walletTabs.EXPENSES && (
            <WalletTransactionsTypeFilters
              key={appConfig.lang}
              transactionTypeOptions={transactionTypeOptions}
              value={transactionType}
              onChange={(value) => {
                setTransactionType(value);
              }}
            />
          )}
      </div>

      <div>
        {/* Table Body (Requests) */}
        {selectedWalletMainActiveTab === walletTabs.REQUESTS &&
          !loaders.getWalletRequestsAction && (
            <DynamicElement element={isMobile ? 'div' : 'table'} className="w-full table-auto">
              <DynamicElement element={isMobile ? 'div' : 'tbody'}>
                <WalletRequestsTableBody
                  walletRequests={walletRequests}
                  requestsDates={requestsDates}
                />
              </DynamicElement>
            </DynamicElement>
          )}

        {/* No Requests Data */}
        {selectedWalletMainActiveTab === walletTabs.REQUESTS &&
          !loaders.getWalletRequestsAction &&
          !walletRequests?.data.length && <AppNoData className="h-72" />}

        {/* Table Body (Transactions) */}
        {selectedWalletMainActiveTab === walletTabs.TRANSACTIONS &&
          !loaders.getWalletTransactionsAction && (
            <DynamicElement element={isMobile ? 'div' : 'table'} className="w-full table-auto">
              <DynamicElement element={isMobile ? 'div' : 'tbody'}>
                <WalletTransactionsTableBody
                  activeTab={selectedWalletSubActiveTab}
                  walletTransactions={walletTransactions}
                  transactionsDates={transactionsDates}
                />
              </DynamicElement>
            </DynamicElement>
          )}

        {/* No Transactions Data */}
        {selectedWalletMainActiveTab === walletTabs.TRANSACTIONS &&
          !loaders.getWalletTransactionsAction &&
          !walletTransactions?.data.length && <AppNoData className="h-72" />}

        {/* Table Body (Fund Activities) */}
        {selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
          selectedWalletSubActiveTab === walletTabs.FUNDSACTIVITY &&
          !loaders.getWalletFundsActivityAction && (
            <DynamicElement element={isMobile ? 'div' : 'table'} className="w-full table-auto">
              <DynamicElement element={isMobile ? 'div' : 'tbody'}>
                <WalletFundsActivityTableBody
                  walletFundActivities={walletFundActivities}
                  requestsDates={requestsDates}
                />
              </DynamicElement>
            </DynamicElement>
          )}

        {/* No Fund Activities Data */}
        {selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
          selectedWalletSubActiveTab === walletTabs.FUNDSACTIVITY &&
          !loaders.getWalletFundsActivityAction &&
          !walletFundActivities?.data.length && <AppNoData className="h-72" />}

        {/* Table Body (Card Activities) */}
        {selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
          selectedWalletSubActiveTab === walletTabs.CARDSACTIVITY &&
          !loaders.getWalletCardsActivityAction && (
            <DynamicElement element={isMobile ? 'div' : 'table'} className="w-full table-auto">
              <DynamicElement element={isMobile ? 'div' : 'tbody'}>
                <WalletCardsActivityTableBody
                  walletCardActivities={walletCardActivities}
                  requestsDates={requestsDates}
                />
              </DynamicElement>
            </DynamicElement>
          )}

        {/* No Card Activities Data */}
        {selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
          selectedWalletSubActiveTab === walletTabs.CARDSACTIVITY &&
          !loaders.getWalletCardsActivityAction &&
          !walletCardActivities?.data.length && <AppNoData className="h-72" />}

        {/* Spinner */}
        {isLoadingList && (
          <div className="h-72">
            <AppSpinner />
          </div>
        )}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="sticky bottom-0 flex justify-end bg-white py-4">
          <AppPagination
            isDisabled={isLoadingList}
            currentPage={paginationParams.page}
            totalPages={totalPages}
            onPageChange={(page) => {
              handlePaginate(page);
            }}
          />
        </div>
      )}
    </>
  );
}

export default CardProgramDetailsTable;
