import { useCallback, useEffect, useState } from 'react';

function AppGrowTabs({ tabs, onTabChange, isDisabled, defaultActiveTab }) {
  const [activeTab, setActiveTab] = useState();
  const [sharedClasses, setSharedClasses] = useState();
  const [activeClasses, setActiveClasses] = useState();
  const [inActiveClasses, setInActiveClasses] = useState();

  const getClasses = useCallback(
    (ele) => {
      return tabs.indexOf(ele) === activeTab ? activeClasses : inActiveClasses;
    },
    [activeTab, tabs],
  );

  const handleClick = useCallback(
    ({ index, item }) => {
      if (!isDisabled) {
        setActiveTab(index);
        onTabChange(item);
      }
    },
    [isDisabled],
  );

  useEffect(() => {
    setActiveTab(tabs.indexOf(defaultActiveTab) || 0);

    setSharedClasses('relative flex-1 py-2 text-center rounded-xl');
    setActiveClasses('text-white bg-primary shadow-lg font-bold');
    setInActiveClasses('text-black');
  }, [defaultActiveTab]);

  return (
    <ul className="flex gap-2 rounded-xl bg-gray-1">
      {tabs.map((item, index) => {
        return (
          <li
            key={item.label}
            className={`${sharedClasses} ${getClasses(item)} ${
              isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            onClick={() => {
              handleClick({ index, item });
            }}
          >
            <span>{item.label}</span>
            {!!item.badgeNumber && (
              <span className="absolute -top-2 end-0 z-20 flex h-5 w-5 items-center justify-center rounded-full bg-danger pt-[1px] text-xxs text-white">
                {item.badgeNumber}
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default AppGrowTabs;
