import React, { useState } from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';

import AppSpinner from '../AppSpinner/index';
import { getInputSizeClassName, getInputStateClassNames, getPasswordIconSize } from './helpers';

function AppInput({
  type = 'text',
  className,
  defaultValue,
  name,
  placeholder,
  value,
  valid,
  invalid,
  size = 'lg',
  onChange,
  onBlur,
  showInvalidIcon = true,
  symbol,
  disabled,
  isLoading,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);
  const stateClassNames = getInputStateClassNames({ invalid, valid });
  const inputSize = getInputSizeClassName({ size });
  const passwordIconSize = getPasswordIconSize({ size });

  return (
    <div className="relative w-full">
      <input
        {...rest}
        type={showPassword ? 'text' : type}
        className={`${className || ''} ${stateClassNames} ${inputSize} ${symbol ? 'pe-10' : ''}`}
        defaultValue={defaultValue}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onWheel={(e) => e.target.blur()}
        disabled={disabled || isLoading}
      />
      {type === 'password' && (
        <button
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          type="button"
          className="absolute end-2 top-1/2 z-10 -translate-y-[50%] text-gray-6"
        >
          {showPassword && (
            <i className="fa-regular fa-regular fa-eye" style={{ fontSize: passwordIconSize }} />
          )}
          {!showPassword && (
            <i className="fa-regular fa-eye-slash" style={{ fontSize: passwordIconSize }} />
          )}
        </button>
      )}

      {symbol && (
        <span className="absolute end-3 top-[50%] -translate-y-[50%] text-xs text-gray-6">
          {symbol}
        </span>
      )}

      {isLoading && (
        <span className="absolute end-3 top-[50%] -translate-y-[50%] text-xs text-gray-6">
          <AppSpinner size={20} />
        </span>
      )}

      {invalid && showInvalidIcon && (
        <IoCloseCircleSharp
          className="absolute -end-8 top-1/2 -translate-y-[50%] text-danger"
          size={passwordIconSize}
        />
      )}
    </div>
  );
}

export default AppInput;
