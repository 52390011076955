import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '../../../../../components/AppSpinner';
import BackBtn from '../../../../../components/BackBtn';
import CopyToClipboard from '../../../../../components/CopyToClipboard';
import TranslatedData from '../../../../../components/TranslatedData';
import { AppBody, AppButton, AppHeader } from '../../../../../components/html/html';
import {
  addTransferToDataAction,
  getSupportedIBANSAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import SingleBankCard from '../SingleBankCard';
import DoubleNotes from './DoubleNotes';

function CopyIBANContent({ handleBackStep, handleCancel, onNext }) {
  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();
  const { loaders } = useSelector((state) => state);
  const { actions } = useSelector((state) => state.wallet);

  const findToData = useCallback(() => {
    const toData = actions.supportedIBANS.find((ele) => {
      return ele.currency === actions.transferFromData.currency;
    });
    if (toData) {
      dispatch(addTransferToDataAction(toData));
    } else {
      dispatch(addTransferToDataAction(actions.supportedIBANS[0]));
    }
  }, [actions.supportedIBANS]);

  useEffect(() => {
    dispatch(getSupportedIBANSAction(actions.targetIBANID));
  }, []);

  useEffect(() => {
    if (actions.supportedIBANS) {
      findToData();
    }
  }, [actions.supportedIBANS]);

  return (
    <div>
      <BackBtn
        className="mb-4"
        onClick={() => {
          handleBackStep(1);
        }}
      />
      <AppHeader h="h4" className="mb-2">
        {t('Copy the IBAN & Start Your Transfer')}
      </AppHeader>

      <AppBody pClass="Body1Bold" className="mb-2">
        {t('From', { ns: 'commonV2' })}
      </AppBody>
      <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
        {t('Please Note you need to do the actual transfer from your bank account website or app')}
      </AppBody>

      <div className="mb-2 max-w-[250px]">
        {actions.transferFromData && <SingleBankCard hasBorder item={actions.transferFromData} />}
      </div>
      <div className="mb-10">
        {actions.transferFromData?.bank?.website && (
          <AppBody
            type="a"
            pClass="Body2Medium"
            key={`bank_${actions.transferFromData.bank.id}`}
            className="text-primary"
            target="_blank"
            href={actions.transferFromData.bank.website}
            rel="noreferrer"
          >
            <i
              className="fa-regular fa-link-simple fa-rotate-by text-primary"
              style={{ '--fa-rotate-angle': '-45deg' }}
            />
            &nbsp;
            {t('Visit the bank website to complete your transfer')}
          </AppBody>
        )}
      </div>

      <AppBody pClass="Body1Bold" className="mb-2">
        {t('To', { ns: 'commonV2' })}
      </AppBody>

      <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
        {t('Copy the IBAN that you need to transfer money to')}
      </AppBody>

      <div className="mb-8 rounded-2xl border border-gray-3 p-5">
        {loaders.getSupportedIBANSAction && (
          <div className="h-32">
            <AppSpinner />
          </div>
        )}
        {!loaders.getSupportedIBANSAction && (
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
            <div>
              <AppBody pClass="Caption1Medium" className="text-gray-4">
                {t('Bank Name', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body2Medium">{actions.transferToData?.bank_name}</AppBody>
            </div>
            <div>
              <AppBody pClass="Caption1Medium" className="text-gray-4">
                {t('Currency', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body2Medium">
                <div className="flex gap-1">
                  <span>
                    <TranslatedData
                      en={actions.transferToData?.iban_country?.name}
                      ar={actions.transferToData?.iban_country?.name_ar}
                    />
                  </span>
                  <span>•</span>
                  <span> {actions.transferToData?.currency}</span>
                </div>
              </AppBody>
            </div>
            <div>
              <AppBody pClass="Caption1Medium" className="text-gray-4">
                {t('IBAN', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body2Medium">
                <div className="flex items-center gap-1">
                  <span className="break-all">{actions.transferToData?.iban}</span>
                  <button type="button">
                    <CopyToClipboard text={actions.transferToData?.iban} />
                  </button>
                </div>
              </AppBody>
            </div>
            <div>
              <AppBody pClass="Caption1Medium" className="text-gray-4">
                {t('Beneficiary Name', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body2Medium">{actions.transferToData?.account_name}</AppBody>
            </div>
            <div>
              <AppBody pClass="Caption1Medium" className="text-gray-4">
                {t('Account number', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body2Medium">
                <div className="flex items-center gap-1">
                  <span className="break-all">{actions.transferToData?.account_number}</span>
                  <button type="button">
                    <CopyToClipboard text={actions.transferToData?.account_number} />
                  </button>
                </div>
              </AppBody>
            </div>
            <div>
              <AppBody pClass="Caption1Medium" className="text-gray-4">
                {t('SWIFT Code', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body2Medium">{actions.transferToData?.swift_code}</AppBody>
            </div>
          </div>
        )}
      </div>

      <div className="mb-16 lg:w-2/3">
        <DoubleNotes />
      </div>

      <div className="flex justify-end gap-8">
        <AppButton button="link" onClick={handleCancel} size="md">
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          size="md"
          className="min-w-[150px]"
          onClick={() => {
            onNext({ stepIndex: 2 });
          }}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default CopyIBANContent;
