import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import successCheckAnimation from '../../../../../../../assets/lotties/successCheckAnimation.json';
import AppImageComponent from '../../../../../../../components/AppImageComponent';
import AppLottie from '../../../../../../../components/AppLottie';
import { AppBody, AppHeader } from '../../../../../../../components/html/html';

function ChangeColorNameSuccess() {
  const { t } = useTranslation(['cardsV2']);
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const { theChangedData, changedData, cardName, selectedDesign } = useSelector(
    (state) => state.cardDetails?.changedColorOrNameData,
  );
  return (
    <>
      {theChangedData === changedData?.nameOnly && (
        <div className="flex flex-col items-center justify-center">
          <div>
            <AppLottie loop={false} animationData={successCheckAnimation} width={250} />
          </div>
          <AppBody pClass="Body2Medium" className="text-gray-6">
            {cardName}
          </AppBody>
          <AppHeader h="h6" className="mb-3 flex flex-col items-center">
            <span>{t('Card Name Changed Successfully', { ns: 'cardsV2' })}</span>
          </AppHeader>
        </div>
      )}

      {theChangedData === changedData?.colorOnly && (
        <div className="flex flex-col items-center justify-center">
          <div className="relative mb-2 w-52 overflow-hidden rounded-md shadow">
            <AppImageComponent src={selectedDesign?.attachment} skeletonHeight={120} />
            <div
              className="absolute bottom-3 start-4 flex items-center tracking-wide"
              style={{ direction: 'ltr' }}
            >
              <AppBody
                pClass="Caption1Medium"
                className={settingsPopupCardDetails?.is_physical ? 'text-white' : 'text-black'}
              >
                {settingsPopupCardDetails?.last_four}
              </AppBody>
            </div>
          </div>
          <AppHeader h="h6" className="mb-3 flex flex-col items-center">
            <span>{t('Card Color Changed Successfully', { ns: 'cardsV2' })}</span>
          </AppHeader>
        </div>
      )}

      {theChangedData === changedData?.bothColorAndName && (
        <div className="flex flex-col items-center justify-center">
          <div className="relative mb-2 w-52 overflow-hidden rounded-md shadow">
            <AppImageComponent src={selectedDesign?.attachment} skeletonHeight={120} />
            <div
              className="absolute bottom-3 start-4 flex items-center tracking-wide"
              style={{ direction: 'ltr' }}
            >
              <AppBody
                pClass="Caption1Medium"
                className={settingsPopupCardDetails?.is_physical ? 'text-white' : 'text-black'}
              >
                {settingsPopupCardDetails?.last_four}
              </AppBody>
            </div>
          </div>

          <AppBody pClass="Body2Medium" className="mb-2 text-gray-6">
            {cardName}
          </AppBody>
          <AppHeader h="h6" className="mb-3 flex flex-col items-center text-center">
            <span>{t('Card Name and Color Changed Successfully', { ns: 'cardsV2' })}</span>
          </AppHeader>
        </div>
      )}
    </>
  );
}

export default ChangeColorNameSuccess;
