import { useSelector } from 'react-redux';
import AppSelect from '../../../../components/FormInputs/AppSelect';

function SideBarFilter({ cardProgramsFilterOptions, onFilterChange, cardProgramFilterValue }) {
  const { loaders } = useSelector((state) => state);

  return (
    <AppSelect
      className="relative z-30 mb-4 text-xs"
      isSearchable={false}
      styleType="flippyArrow"
      value={cardProgramFilterValue}
      options={cardProgramsFilterOptions}
      isLoading={loaders.getCardPrograms}
      mobileSelect={false}
      onChange={(value) => {
        onFilterChange(value);
      }}
    />
  );
}

export default SideBarFilter;
