import { Turnstile } from '@marsidev/react-turnstile';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AppAlert from '../../../components/AppAlert/index';
import AppInput from '../../../components/AppFormInputs/AppInput';
import AppPhoneInput from '../../../components/AppFormInputs/AppPhoneInput';
import AppSelect from '../../../components/FormInputs/AppSelect';
import { AppBody, AppButton, AppHeader } from '../../../components/html/html';
import { captchaKey } from '../../../helpers/constants';
import { getServerErrMessage, isDev, translatedDataFunction } from '../../../helpers/Utils';
import { http } from '../../../http';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';
import { setFinishQuickSignUpAction } from '../../../redux/AuthReducer/AuthActions';

function QuickSignUpForm() {
  const { t } = useTranslation('quickSignUp');
  const { loaders, appConfig } = useSelector((state) => state);
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);
  const [validMobileNumber, setValidMobileNumber] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);

  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      mobileNumber: '',
      country: '',
      CRNumber: '',
      iban: '',
      isAgree: false,
    };
  }, []);

  const getCountries = useCallback(() => {
    http
      .get('account-countries', { loader: 'getCountries' })
      .then((res) => {
        const serialized = res.data.map((ele) => {
          return {
            label: translatedDataFunction({ ar: ele.name_ar, en: ele.name, lang: appConfig.lang }),
            value: ele.id,
          };
        });

        setCountryOptions(serialized);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [appConfig.lang]);

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append('first_name', values.firstName);
    formData.append('last_name', values.lastName);
    formData.append('cr_number', values.CRNumber);
    formData.append('iban', values.iban);
    formData.append('email', values.email);
    formData.append('email_confirmation', values.confirmEmail);
    formData.append('mobile', values.mobileNumber);
    formData.append('account_country_id', values.country.value);
    formData.append('register_browser', window.navigator.userAgent);

    http
      .post('lead/signup', formData, { loader: 'handleQuickSignUp' })
      .then(() => {
        dispatch(setFinishQuickSignUpAction(true));
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

  const validationSchema = yup.object({
    firstName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    lastName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    email: yup
      .string()
      .email(t('Please Enter a Valid Email', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
    confirmEmail: yup
      .string()
      .email(t('Please Enter a Valid Email', { ns: 'commonV2' }))
      .oneOf([yup.ref('email'), null], t("Email confirmation doesn't match", { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
    mobileNumber: yup
      .string()
      .min(10, t('The Mobile must be at least 10 numbers.', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
    country: yup.mixed().required(t('This field is required!', { ns: 'commonV2' })),
    CRNumber: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    iban: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    isAgree: yup
      .boolean()
      .required(t('This field is required!', { ns: 'commonV2' }))
      .oneOf([true], t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  const handleCheckEmail = useCallback((email) => {
    formik.setFieldError('email', null);

    http.get(`email/exist`, { loader: 'handleCheckEmail', params: { email } }).catch(() => {
      formik.setFieldError('email', t('The email has already been taken.'));
    });
  }, []);

  useEffect(() => {
    getCountries();
  }, [appConfig.lang]);

  return (
    <>
      <div className="mb-8">
        <div className="mb-4">
          <div className="hidden lg:block">
            <AppHeader h="h1" className="text-center">
              {t("Let's take back the control of expense")}
            </AppHeader>
          </div>
          <div className="lg:hidden">
            <AppHeader h="h1" className="text-center">
              {t("Let's take back")}
            </AppHeader>
            <AppHeader h="h5" className="text-center">
              {t('the control of expense')}
            </AppHeader>
          </div>
        </div>

        <AppBody className="text-center text-gray-6" pClass="Body2Medium">
          {t('You’re applying for an account with NQOOD Wallet Co.')}
        </AppBody>
        <AppBody className="text-center text-gray-6" pClass="Body2Medium">
          {t('The software and related services are provided by Nqood Wallet LLC & Partners.')}
        </AppBody>
      </div>

      <form className="mb-6 grid gap-6">
        <div className="grid grid-cols-2 gap-2 sm:gap-6">
          <label className="block">
            <span className="mb-1 block text-xl font-bold">
              {t('First Name', { ns: 'commonV2' })}
            </span>
            <AppInput
              name="firstName"
              type="text"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.firstName && formik.touched.firstName && (
              <span className="mb-2 text-sm text-danger">
                {t(formik.errors.firstName, { ns: 'commonV2' })}
              </span>
            )}
          </label>

          <label className="block">
            <span className="mb-1 block text-xl font-bold">
              {t('Last Name', { ns: 'commonV2' })}
            </span>
            <AppInput
              name="lastName"
              type="text"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.lastName && formik.touched.lastName && (
              <span className="mb-2 text-sm text-danger">
                {t(formik.errors.lastName, { ns: 'commonV2' })}
              </span>
            )}
          </label>
        </div>

        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          <label className="block">
            <span className="mb-1 block text-xl font-bold">{t('Email', { ns: 'commonV2' })}</span>
            <AppInput
              isLoading={loaders.handleCheckEmail}
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={(e) => {
                formik.handleBlur(e);
                handleCheckEmail(e.target.value);
              }}
            />
            {formik.errors.email && formik.touched.email && (
              <span className="mb-2 text-sm text-danger">
                {t(formik.errors.email, { ns: 'commonV2' })}
              </span>
            )}
          </label>

          <label className="block">
            <span className="mb-1 block text-xl font-bold">
              {t('Confirm Email', { ns: 'commonV2' })}
            </span>
            <AppInput
              name="confirmEmail"
              type="email"
              value={formik.values.confirmEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.confirmEmail && formik.touched.confirmEmail && (
              <span className="mb-2 text-sm text-danger">
                {t(formik.errors.confirmEmail, { ns: 'commonV2' })}
              </span>
            )}
          </label>

          <label className="block">
            <span className="mb-1 block text-xl font-bold">
              {t('Mobile Number', { ns: 'commonV2' })}
            </span>

            <AppPhoneInput
              name="mobileNumber"
              onChange={({ value }) => {
                setValidMobileNumber(true);
                formik.setFieldValue('mobileNumber', value);
              }}
              value={formik.values.mobileNumber}
              onBlur={({ isValid }) => {
                setValidMobileNumber(isValid);
              }}
            />

            {formik.errors.mobileNumber && formik.touched.mobileNumber && (
              <span className="mb-2 text-sm text-danger">
                {t(formik.errors.mobileNumber, { ns: 'commonV2' })}
              </span>
            )}

            {!validMobileNumber && (
              <small className="text-danger">
                {t('Please enter a valid phone number.', { ns: 'commonV2' })}
              </small>
            )}
          </label>

          <label className="block">
            <span className="mb-1 block text-xl font-bold">{t('Country', { ns: 'commonV2' })}</span>

            <AppSelect
              isLoading={loaders.getCountries}
              options={countryOptions}
              name="country"
              styleType="inForm"
              onChange={(value) => {
                formik.setFieldValue('country', value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              onMenuClose={() => {
                formik.setFieldTouched(`country`);
              }}
            />

            {formik.errors.country && formik.touched.country && (
              <span className="mb-2 text-sm text-danger">
                {t(formik.errors.country, { ns: 'commonV2' })}
              </span>
            )}
          </label>
        </div>

        <div className="grid gap-6">
          <label className="block">
            <span className="mb-1 block text-xl font-bold">
              {t('CR Number', { ns: 'commonV2' })}
            </span>
            <AppInput
              name="CRNumber"
              type="text"
              value={formik.values.CRNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.CRNumber && formik.touched.CRNumber && (
              <span className="mb-2 text-sm text-danger">
                {t(formik.errors.CRNumber, { ns: 'commonV2' })}
              </span>
            )}
          </label>

          <label className="block">
            <span className="mb-1 block text-xl font-bold">{t('IBAN')}</span>
            <AppInput
              name="iban"
              type="text"
              value={formik.values.iban}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.iban && formik.touched.iban && (
              <span className="mb-2 text-sm text-danger">
                {t(formik.errors.iban, { ns: 'commonV2' })}
              </span>
            )}
          </label>
        </div>
      </form>

      <div className="mb-6">
        <AppAlert
          type="info"
          text={
            <AppBody pClass="Body2Medium">
              {t(
                'We will collect your company information from the Ministry of Commerce (MOC). Our team will then contact you to gather the required documents to open your account, based on your Commercial Registration (CR) number.',
              )}
            </AppBody>
          }
        />
      </div>

      {!isDev() && (
        <div className="mb-4">
          <Turnstile
            size="flexible"
            autoResetOnExpire
            siteKey={captchaKey}
            onError={() => {
              setIsValidCaptcha(false);
            }}
            onSuccess={() => {
              setIsValidCaptcha(true);
            }}
            options={{
              theme: 'light',
              size: 'flexible',
              language: appConfig.lang,
            }}
          />
        </div>
      )}

      <div className="mb-4">
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            className="rounded checked:bg-black focus:ring-0"
            checked={formik.values.isAgree}
            onChange={(e) => {
              formik.setFieldValue('isAgree', e.target.checked);
            }}
          />
          <AppBody pClass="Caption1Medium" type="span">
            {t('I Read the', { ns: 'commonV2' })}{' '}
            <a
              href="https://nqoodlet.com/legal/privacy-policy/"
              target="_blank"
              className="text-primary"
              rel="noreferrer"
            >
              {t('Privacy Policy', { ns: 'commonV2' })}
            </a>
          </AppBody>
        </label>
      </div>

      <AppButton
        button="primary"
        rounded="md"
        size="lg"
        className="mx-auto block w-full lg:w-1/4"
        onClick={formik.handleSubmit}
        disabled={
          !isDev()
            ? !isValidCaptcha && !formik.isValid && validMobileNumber
            : !formik.isValid && validMobileNumber
        }
        isLoading={loaders.handleQuickSignUp}
        bClass="Button1Bold"
      >
        {t('Submit')}
      </AppButton>
    </>
  );
}

export default QuickSignUpForm;
