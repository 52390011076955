import { ReactComponent as BlueSpinner } from '../../assets/svg/BlueLoading.svg';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';

function ActionButton(props) {
  const { text, primary, onClick, type, className, isLoading, disabled } = props;

  const textColor = primary ? 'text-white' : 'text-black';
  return (
    <button
      onClick={onClick}
      className={`${
        className || ''
      }  rounded-full px-5 py-1 text-base font-semibold tracking-tight ${
        primary ? 'disabled:bg-gray-3' : ''
      } ${className && className.includes('text-primary') ? 'text-primary' : textColor}  ${
        primary ? 'bg-black text-white' : 'bg-none'
      }`}
      disabled={isLoading || disabled}
      // eslint-disable-next-line react/button-has-type
      type={type ?? 'button'}
    >
      <p className={isLoading ? 'inline-block pr-5' : ''}>{text}</p>
      {isLoading && className && className.includes('text-primary') && (
        <BlueSpinner
          className={`inline-block animate-spin fill-white ${
            className && className.includes('absolute')
              ? 'absolute bottom-0.5 inline-block h-4 w-4 ltr:right-2 rtl:left-2'
              : 'h-6 w-6'
          }`}
        />
      )}
      {isLoading && !className && (
        <Spinner className="fill-lightGray inline-block h-6 w-6 animate-spin" />
      )}
      {isLoading && className && !className.includes('text-primary') && (
        <Spinner className="fill-lightGray inline-block h-6 w-6 animate-spin" />
      )}
    </button>
  );
}

export default ActionButton;
