const INITIAL_STATE = {};

const LoadersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_LOADER': {
      return { ...state, ...action.payload };
    }
    case 'REMOVE_LOADER': {
      const updatedState = { ...state };
      updatedState[action.payload] = false;
      return { ...updatedState };
    }
    default:
      return state;
  }
};

export default LoadersReducer;
