import { randomId } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppSpinner from '../../components/AppSpinner';
import OTPInput from '../../components/FormInputs/OTPInput';
import OTPResendTimer from '../../components/FormInputs/OTPResendTimer';
import { AppBody, AppHeader } from '../../components/html/html';
import LTRForce from '../../components/LTRForce/index';
import { employeeRegistrationSteps } from '../../helpers/constants';
import { devLog, getServerErrMessage, isDev } from '../../helpers/Utils';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import { setRegistrationStepAction } from '../../redux/EmployeeRegistrationReducer/EmployeeRegistrationActions';

function ActivateAccount() {
  const { loaders, useMock } = useSelector((state) => state);
  const { t } = useTranslation('employeeRegistration');
  const [OTPKey, setOTPKey] = useState(randomId());
  const dispatch = useDispatch();
  const { employeeToken } = useParams();
  const [userHashedData, setUserHashedData] = useState(null);

  const isMockUsed = useMemo(() => {
    return (
      useMock.employeeAlreadyActivatedAccount ||
      useMock.employeeExpiredLink ||
      useMock.employeeInvalidToken
    );
  }, []);

  const handleCompleteActivationOTP = useCallback(
    (code) => {
      const formBody = new FormData();

      formBody.append('reset_token', employeeToken);
      formBody.append('sms_token', userHashedData.token);
      formBody.append('code', code);

      http
        .post(`otp/verify`, formBody, { loader: 'handleCompleteActivationOTP' })
        .then(() => {
          dispatch(setRegistrationStepAction(employeeRegistrationSteps.setupPassword));
        })
        .catch((err) => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: getServerErrMessage(err),
            }),
          );
        });
    },
    [employeeToken, userHashedData],
  );

  const handleVerifyUser = useCallback(() => {
    devLog(`Verifying User from token...`);
    const formBody = new FormData();

    formBody.append('reset_token', employeeToken);

    http
      .post(`user/verify`, formBody, { loader: 'handleVerifyUser' })
      .then((res) => {
        devLog(`User Verified!, SMS Send!`);
        setUserHashedData(res.data);
      })
      .catch((err) => {
        setUserHashedData(null);

        const error = err?.data?.data || {};
        if (error.has_password) {
          dispatch(setRegistrationStepAction(employeeRegistrationSteps.alreadyActivatedAccount));
        } else if (error.invalid_token) {
          dispatch(setRegistrationStepAction(employeeRegistrationSteps.invalidToken));
        } else if (error.expire_token) {
          dispatch(setRegistrationStepAction(employeeRegistrationSteps.employeeExpiredLink));
        } else {
          dispatch(
            showToasterAction({
              type: 'error',
              message: getServerErrMessage(err),
            }),
          );
        }
      });
  }, [employeeToken]);

  useEffect(() => {
    if (isMockUsed) {
      devLog(`Mock is Active!`);
      if (useMock.employeeAlreadyActivatedAccount) {
        dispatch(setRegistrationStepAction(employeeRegistrationSteps.alreadyActivatedAccount));
      }
      if (useMock.employeeExpiredLink) {
        dispatch(setRegistrationStepAction(employeeRegistrationSteps.expiredLink));
      }
      if (useMock.employeeInvalidToken) {
        dispatch(setRegistrationStepAction(employeeRegistrationSteps.invalidToken));
      }
    } else {
      handleVerifyUser();
    }
  }, []);

  return (
    <div className="flex h-full items-center justify-center">
      {!loaders.handleVerifyUser && userHashedData && (
        <div className="max-w-[550px] px-2 xxs:px-4">
          <AppHeader h="h2" className="mb-2">
            {t('Let’s Activate Your Account')}
          </AppHeader>

          <AppBody pClass="Body2Medium" className="mb-4 text-gray-6">
            {t('Please enter the verification code we sent to', { ns: 'commonV2' })}
          </AppBody>

          <AppBody pClass="Body2Bold" className="mb-5 text-gray-6">
            <span className="block text-start">
              <span>{t('Mobile', { ns: 'commonV2' })} </span>
              <span>
                <LTRForce>{userHashedData.mobile}</LTRForce>
              </span>
            </span>
            <span>
              <span>{t('OR', { ns: 'commonV2' })} </span>
              <span className="block">
                <LTRForce>{userHashedData.email}</LTRForce>
              </span>
            </span>
          </AppBody>

          <AppBody pClass="Body2Bold" className="mb-3">
            {t('Enter The Code', { ns: 'commonV2' })}
          </AppBody>

          <div className="mb-5">
            <OTPInput
              key={OTPKey}
              isLoading={loaders.handleCompleteActivationOTP}
              count={5}
              onComplete={(code) => {
                handleCompleteActivationOTP(code);
              }}
            />
          </div>

          <div className="flex items-center gap-2">
            <AppBody pClass="Caption1Medium" className="text-gray-6">
              {t("Didn't receive the one time code?", { ns: 'commonV2' })}
            </AppBody>

            <OTPResendTimer duration={isDev() ? 2 : 180} onResend={handleVerifyUser} />
          </div>
        </div>
      )}

      {loaders.handleVerifyUser && !userHashedData && <AppSpinner />}
    </div>
  );
}

export default ActivateAccount;
