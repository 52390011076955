import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { supportedCountries } from '../../helpers/constants';
import AppSelect from '../FormInputs/AppSelect';
import AppInput from './AppInput';

function AppPhoneInput({ onChange, onBlur, name, initialValue, isLoading }) {
  const { dir, lang } = useSelector((state) => state.appConfig);

  const mobileCountryCodeStyleConfigs = useMemo(() => {
    return {
      control: (provided, state) => ({
        ...provided,
        borderRadius: '.5rem 0 0 .5rem',
        opacity: state.isDisabled ? '.5' : '1',
        '&:hover': {},
        height: '48px',
        border: `1px solid #C1CBDE`,
        boxShadow: 'none',
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
      }),
      option: (provided, state) => {
        let { backgroundColor } = provided;

        if (state.isSelected) {
          backgroundColor = '#F2F5FC';
        }

        if (state.isFocused) {
          backgroundColor = '#F2F5FC';
        }

        return {
          ...provided,
          color: state.isSelected ? '#3842FF' : '#2D2926',
          backgroundColor,
        };
      },
    };
  }, [dir]);

  const [serializedCountries, setSerializedCountries] = useState([]);
  const [mobileCountryCode, setMobileCountryCode] = useState('+966');
  const [mobileNumber, setMobileNumber] = useState('');

  const handleBlur = useCallback(() => {
    const fullNumber = mobileCountryCode + mobileNumber;
    const isValid = parsePhoneNumberFromString(fullNumber)?.isValid() || false;

    onBlur({ isValid });
  }, [mobileCountryCode, mobileNumber]);

  useEffect(() => {
    const fullNumber = mobileCountryCode + mobileNumber;

    onChange({ value: fullNumber });
  }, [mobileCountryCode, mobileNumber]);

  useEffect(() => {
    if (initialValue) {
      const countryCallingCode =
        parsePhoneNumberFromString(initialValue)?.countryCallingCode || '966';

      const number = initialValue?.replace(`+${countryCallingCode}`, '') || '';

      setMobileCountryCode(`+${countryCallingCode}`);
      setMobileNumber(number);
    }
  }, [initialValue]);

  useEffect(() => {
    setSerializedCountries(
      supportedCountries.map((ele) => ({
        label: lang === 'en' ? ele.name : ele.name_ar,
        value: ele.dial_code,
        abbr: ele.code,
      })),
    );
  }, [lang]);

  return (
    <div className="flex">
      <AppSelect
        mobileSelect
        style={{ direction: 'ltr' }}
        options={serializedCountries}
        name="mobileCountryCode"
        className="min-w-32 rounded-l-md rounded-r-none border-gray-3 focus:border-gray-3 lg:border-r-0 rtl:order-3"
        onChange={({ value }) => {
          setMobileCountryCode(value);
        }}
        onBlur={handleBlur}
        value={serializedCountries.find((ele) => mobileCountryCode === ele.value)}
        styleType="custom"
        customStyleConfigs={mobileCountryCodeStyleConfigs}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        formatOptionLabel={({ label, abbr }, { context }) => (context === 'menu' ? label : abbr)}
      />

      <div className="relative w-full">
        <AppInput
          isLoading={isLoading}
          style={{ direction: 'ltr' }}
          name={name}
          className="rounded-s-none border-s-0 pl-11 focus:border-s-0 focus:shadow-none rtl:order-1"
          value={mobileNumber}
          onChange={(e) => {
            setMobileNumber(e.target.value);
          }}
          onBlur={handleBlur}
          placeholder="05xxxxxxxx"
        />

        <span style={{ direction: 'ltr' }} className="absolute left-1 top-3">
          {mobileCountryCode}
        </span>
      </div>
    </div>
  );
}

export default AppPhoneInput;
