import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import TranslatedData from '../../../../../../components/TranslatedData';
import { AppBody } from '../../../../../../components/html/html';

export function TargetIBANDetails({ selectedItem }) {
  const { t } = useTranslation('commonV2');
  const renderSingleTransferContent = useCallback(({ title, value }) => {
    return (
      <div>
        <AppBody pClass="Caption1Regular" className="text-gray-6">
          {title}
        </AppBody>
        <AppBody pClass="Caption1Regular" className="break-words">
          {value}
        </AppBody>
      </div>
    );
  }, []);
  return (
    <div className="my-2 grid w-full grid-cols-2 gap-3">
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Bank name'),
          value: selectedItem?.target_iban.bank_name,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: '',
          value: selectedItem?.target_iban.iban,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Beneficiary Name'),
          value: selectedItem?.target_iban?.account_name,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Account number'),
          value: selectedItem?.target_iban?.account_number,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Country'),
          value: (
            <TranslatedData
              en={selectedItem?.target_iban.iban_country?.name}
              ar={selectedItem?.target_iban.iban_country?.name_ar}
            />
          ),
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('Currency'),
          value: selectedItem?.target_iban.currency,
        })}
      </div>
      <div className="col-span-1">
        {renderSingleTransferContent({
          title: t('SWIFT Code'),
          value: selectedItem?.target_iban?.swift_code,
        })}
      </div>
    </div>
  );
}
