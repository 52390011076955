import { apiPatchRequest } from '../../../helpers/APIHelper';

export const isApprovalStatus = (status) => status === 2 || status === 3;

export const approveClickHandler = (itemID, token) =>
  apiPatchRequest(
    `funds/${itemID}/status`,
    {
      status_id: process.env.REACT_APP_APPROVAL_STATUS_ID,
    },
    token,
  );

export const rejectClickHandler = (itemID, token, rejectionReason = '') =>
  apiPatchRequest(
    `funds/${itemID}/status`,
    {
      status_id: process.env.REACT_APP_REJECTION_STATUS_ID,
      rejection_reason: rejectionReason,
    },
    token,
  );
