/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
function MultiColorProgressBar(props) {
  const { readings, completeSplit, completeSplitError } = props;

  let bars = '';
  if (completeSplitError && !completeSplit) {
    bars = <ErrorProgress />;
  } else {
    // eslint-disable-next-line array-callback-return,consistent-return
    bars =
      readings &&
      readings.length &&
      readings.map((item, i) => {
        if (item.value > 0) {
          return (
            <div
              className="bar"
              style={{ backgroundColor: item.color, width: `${item.value}%` }}
              key={i}
            />
          );
        }
      }, this);
  }

  return (
    <div className="multicolor-bar">
      <div className="bars">{bars === '' ? '' : bars}</div>
    </div>
  );
}

function ErrorProgress() {
  return (
    <div className="bar" style={{ backgroundColor: 'red', width: '100%' }} key="progress_error" />
  );
}
export default MultiColorProgressBar;
