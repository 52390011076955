import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../../../../components/Button/ActionButton';
import CustomPhone from '../../../../components/FormInputs/CustomPhone';
import {TextInput} from '../../../../components/FormInputs/Inputs';
import CompanyStepBar from '../../../../components/Stepbar/CompanyStepBar';
import {HeadlineCenter} from '../../../../components/Titles';
import {apiPostFormDataRequest} from '../../../../helpers/APIHelper';
import {EmailValidationPattern, NameValidationPattern} from '../../../../helpers/RegexPatterns';
import useNotifications from '../../../../helpers/useNotifications';
import {AppBody} from "../../../../components/html/html";

function SecondContact() {
  const { t, i18n } = useTranslation(['signup', 'common']);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    control,
  } = useForm({ mode: 'onBlur' });
  const [cookies] = useCookies(['token']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const [, addNotification] = useNotifications();

  const onSubmit = (data) => {
    const form = new FormData();
    setIsSubmitting(true);

    form.append('name', data.name);
    form.append('designation', data.designation);
    form.append('email', data.email);
    form.append('mobile', data.mobile);

    apiPostFormDataRequest('second_contact', form, cookies.token)
      .then((response) => {
        navigate('/signup/verify_company/funding_method');
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setIsSubmitting(false);
      })
      .finally((err) => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  return (
    <div className="flex h-full items-center justify-center">
      <div className="mx-auto flex w-full flex-col lg:w-8/12">
        <CompanyStepBar step={3} />
        <div className="m-auto mb-10 mt-0">
          <HeadlineCenter>{t('second_contact.title')}</HeadlineCenter>
          <AppBody type="span" pClass="Body2Regular" className="centerTitle relative max-w-md pb-4 pt-2 text-center text-gray-6">
            {t('second_contact.subtitle')}
          </AppBody>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-5">
            <AppBody type="label" pClass="Body2Regular" className="col-span-2 flex flex-col lg:col-span-1" htmlFor="name">
              <AppBody pClass="Body1Bold" type="span">{t('second_contact.inputs.name')}</AppBody>
              <TextInput
                id="name"
                error={errors.name}
                {...register('name', {
                  required: t('second_contact.inputs.validation.required.name'),
                  pattern: {
                    value: NameValidationPattern,
                    message: t('second_contact.inputs.validation.patterns.name'),
                  },
                })}
              />
            </AppBody>
            <AppBody type="label" pClass="Body2Regular" className="col-span-2 flex flex-col lg:col-span-1" htmlFor="designation">
              <AppBody pClass="Body1Bold" type="span">{t('second_contact.inputs.designation')}</AppBody>
              <TextInput
                id="designation"
                error={errors.designation}
                {...register('designation', {
                  required: t('second_contact.inputs.validation.required.designation'),
                  pattern: {
                    value: NameValidationPattern,
                    message: t('second_contact.inputs.validation.patterns.designation'),
                  },
                })}
              />
            </AppBody>
          </div>

          <div className="grid grid-cols-2 gap-5 pt-5">
            <AppBody type="label" pClass="Body2Regular" className="col-span-2 flex flex-col lg:col-span-1" htmlFor="mobile">
              <AppBody pClass="Body1Bold" type="span">{t('second_contact.inputs.mobile')}</AppBody>
              <CustomPhone
                name="mobile"
                control={control}
                errors={errors.mobile}
                patternError={t('second_contact.inputs.validation.patterns.mobile')}
                requiredString={t('second_contact.inputs.validation.required.mobile')}
              />
            </AppBody>
            <AppBody type="label" pClass="Body2Regular"
              className="relative col-span-2 flex flex-col lg:col-span-1"
              htmlFor="email"
            >
              <AppBody pClass="Body1Bold" type="span">{t('second_contact.inputs.email')}</AppBody>
              <TextInput
                error={errors.email}
                id="email"
                {...register('email', {
                  required: t('second_contact.inputs.validation.required.email'),
                  pattern: {
                    value: EmailValidationPattern,
                    message: t('second_contact.inputs.validation.patterns.email'),
                  },
                })}
              />
            </AppBody>
          </div>

          <div className="mt-5 flex flex-row justify-end self-stretch pt-5">
            <ActionButton
              className="flex flex-row"
              type="submit"
              primary
              text={t('next', { ns: 'common' })}
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default SecondContact;
