import { randomId } from 'javascript-functions';
import PreviewButton from './PreviewButton';

function FilePreview({ filePreview, localFilePreview, isPDF }) {
  return (
    <>
      {/* server image preview */}
      {filePreview && !localFilePreview && !isPDF && <PreviewButton href={filePreview} />}

      {/* server pdf preview */}
      {filePreview && !localFilePreview && isPDF && <PreviewButton href={filePreview} isPDF />}

      {/* local image preview */}
      {localFilePreview && !filePreview && !isPDF && (
        <img
          src={localFilePreview}
          alt={randomId()}
          className="h-full w-full object-cover opacity-30"
        />
      )}

      {/* local pdf preview */}
      {!filePreview && localFilePreview && isPDF && (
        <iframe
          className="h-full w-full border-0 bg-white opacity-30"
          src={localFilePreview}
          title="pdf"
        />
      )}
    </>
  );
}

export default FilePreview;
