import { React, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppTd, AppTr } from '../../../../../components/html/html';
import TranslatedData from '../../../../../components/TranslatedData';
import { formatDate, toIntAndDecimalNumber } from '../../../../../helpers/Utils';
import {
  getSingleWalletFundsActivityDetailsAction,
  showWalletReceiptPopupAction,
} from '../../../../../redux/WalletReducer/WalletActions';

function FundsActivityTableRow({ item }) {
  const { isMobile, lang } = useSelector((state) => state.appConfig);
  const dispatch = useDispatch();

  const handleViewReceipt = useCallback(() => {
    dispatch(showWalletReceiptPopupAction());
    dispatch(getSingleWalletFundsActivityDetailsAction(item.id));
  }, [item]);

  return (
    <>
      {/* ================= Desktop Row ================= */}
      {!isMobile && (
        <AppTr
          className="hidden cursor-pointer border-b last:border-0 lg:table-row [&>*]:max-w-[100px] [&>*]:py-3"
          onClick={handleViewReceipt}
        >
          <AppTd className="w-12">{formatDate(item.created_at, 'HH:mm')}</AppTd>
          <AppTd className="min-w-[50px]">{item.id}</AppTd>
          <AppTd>
            <TranslatedData en={item?.type || ''} ar={item?.type_ar || ''} />
          </AppTd>
          <AppTd>
            {item?.type.toLowerCase() === 'deposit' &&
              item?.funding_method_id &&
              `${item?.funding_method_id.bank_name} *${item?.funding_method_id.iban.slice(-4)}`}
            {item?.type.toLowerCase() === 'transfer' && item?.transfer_from?.description}
            {item?.type.toLowerCase() === 'withdraw' && item?.transfer_from?.description}
          </AppTd>
          <AppTd>
            {item?.type.toLowerCase() === 'deposit' && item?.fundable?.description}
            {item?.type.toLowerCase() === 'transfer' && item?.fundable?.description}
            {item?.type.toLowerCase() === 'withdraw' &&
              `${
                item?.funding_method_id &&
                `${item?.funding_method_id.bank_name} *${item?.funding_method_id.iban.slice(-4)}`
              }`}
          </AppTd>

          <AppTd>
            <div
              className={`flex gap-1 ${lang === 'ar' ? 'justify-end' : ''}`}
              style={{ direction: 'ltr' }}
            >
              <div style={{ direction: 'ltr' }}>
                {toIntAndDecimalNumber(item.amount).integerPart}
                <span className="text-[13px] font-normal">
                  {toIntAndDecimalNumber(item.amount).decimalPart}
                </span>
              </div>
              <span>{item.currency}</span>
            </div>
          </AppTd>

          <AppTd>
            <span style={{ color: item?.status?.color }}>{item?.status?.name}</span>
          </AppTd>
        </AppTr>
      )}

      {/* ================= Mobile Row ================= */}
      {isMobile && (
        <div
          onClick={handleViewReceipt}
          className="mb-2 flex cursor-pointer items-center gap-2 rounded-2xl p-4 font-bold shadow-xl"
        >
          <div className="flex-1">{item.id}</div>

          <div className="flex flex-1 justify-center gap-1">
            {' '}
            <TranslatedData en={item?.type || ''} ar={item?.type_ar || ''} />
          </div>

          <div className="flex-1">
            <span style={{ color: item?.status?.color }}>{item?.status?.name}</span>
          </div>
        </div>
      )}
    </>
  );
}

export default FundsActivityTableRow;
