import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';
import { translatedDataFunction } from '../../helpers/Utils';
import { inPages, receiptPopupIconIds } from '../../helpers/constants';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import {
  getSingleTransactionDetailsAction,
  hideReceiptSectionAction,
  updateCardTransactionsAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';
import {
  getSingleWalletTransactionDetailsAction,
  hideWalletReceiptSectionAction,
  updateWalletTransactionsAction,
} from '../../redux/WalletReducer/WalletActions';
import AppNumberInput from '../FormInputs/AppNumberInput';
import AppSelect from '../FormInputs/AppSelect';
import AppTextArea from '../FormInputs/AppTextArea';
import AppUpload from '../FormInputs/AppUpload';
import { AppBody, AppButton, AppHeader, AppIcon } from '../html/html';
import SplitProgressBar from './SplitProgressBar';

function CreateEditSplitReceipt({
  isEdit,
  setIsEdit,
  tags,
  departments,
  transactionDetails,
  getTagsAction,
  getDepartmentsAction,
  inPage,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const { loaders, appConfig } = useSelector((state) => state);
  const [tagOptions, setTagOptions] = useState([]);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);

  const formInputNames = useMemo(() => {
    return {
      prefix: 'splits',
      id: 'id',
      departmentId: 'department_id',
      tagId: 'tag_id',
      transactionAmount: 'transaction_amount',
      description: 'reason',
      receipt: 'receipt',
    };
  }, []);

  const setSelectInputInitialValue = useCallback(
    ({ serverValue }) => {
      if (serverValue) {
        return {
          label: translatedDataFunction({
            lang: appConfig.lang,
            en: serverValue.name,
            ar: serverValue.name_ar,
          }),
          value: serverValue.id,
        };
      }
      return '';
    },
    [appConfig],
  );

  const setUploadInputInitialValue = useCallback(({ serverValue }) => {
    if (serverValue) {
      return {
        path: serverValue.path,
        type: serverValue.type,
      };
    }
    return '';
  }, []);

  const handleAddSplit = useCallback(
    (formBody) => {
      const endPoint = `transactions/split${isEdit ? `/${transactionDetails.id}` : ''}`;

      http
        .post(endPoint, formBody, {
          loader: 'handleAddSplit',
        })
        .then((response) => {
          dispatch(
            showToasterAction({
              type: 'success',
              message: t('Split was created successfully!'),
            }),
          );

          if (inPage === inPages.myCards) {
            dispatch(getSingleTransactionDetailsAction(transactionDetails.id));
            dispatch(updateCardTransactionsAction(response.data));
          }
          if (inPage === inPages.wallet) {
            dispatch(getSingleWalletTransactionDetailsAction(transactionDetails.id));
            dispatch(updateWalletTransactionsAction(response.data));
          }
        })
        .catch((err) => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: err?.data?.error,
            }),
          );
        });
    },
    [transactionDetails],
  );

  const serializeFormData = useCallback((values) => {
    const serializedData = {};

    values.forEach((split, index) => {
      Object.entries(split).forEach(([inputName, value]) => {
        const { prefix, id, receipt } = formInputNames;
        const key = `${prefix}[${index}][${inputName}]`;
        // not adding ID in case CREATE
        if (!isEdit && inputName === id) {
          return;
        }
        // not sending ID in case EDIT and id has no value
        if (isEdit && inputName === id && !value) {
          return;
        }
        // not sending receipt in case EDIT and id has path (path means initial value coming from server so not sending it means the user didn't update the image)
        if (isEdit && inputName === receipt && value.path) {
          return;
        }
        serializedData[key] = value?.value || value;
      });
    });

    return serializedData;
  }, []);

  const onSubmit = useCallback((values) => {
    const serializedValues = serializeFormData(values.splits);

    const formBody = new FormData();
    formBody.append('parent_transaction_id', values.parent_transaction_id);

    Object.entries(serializedValues).forEach(([inputName, value]) => {
      formBody.append(inputName, value);
    });

    handleAddSplit(formBody);
  }, []);

  const initialValues = useMemo(() => {
    const { prefix, id, departmentId, tagId, transactionAmount, description, receipt } =
      formInputNames;
    return {
      parent_transaction_id: transactionDetails.id,
      [prefix]: [
        {
          [id]: '',
          [departmentId]: '',
          [tagId]: '',
          [transactionAmount]: '',
          [description]: '',
          [receipt]: '',
        },
        {
          [id]: '',
          [departmentId]: '',
          [tagId]: '',
          [transactionAmount]: '',
          [description]: '',
          [receipt]: '',
        },
      ],
    };
  }, [transactionDetails]);

  const validationSchema = useMemo(() => {
    const { prefix, departmentId, tagId, transactionAmount, receipt } = formInputNames;
    return yup.object().shape({
      [prefix]: yup.array().of(
        yup.object().shape({
          [departmentId]: yup
            .object()
            .nullable()
            .required(t('This field is required!', { ns: 'commonV2' })),
          [tagId]: yup
            .object()
            .nullable()
            .required(t('This field is required!', { ns: 'commonV2' })),
          [transactionAmount]: yup
            .number()
            .min(1, t('Amount is invalid must be more than 0'))
            .required(t('This field is required!', { ns: 'commonV2' })),
          [receipt]: yup.mixed().required(t('This field is required!', { ns: 'commonV2' })),
        }),
      ),
    });
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  // console.log(JSON.stringify(formik, null, 4));

  const showInputError = useCallback(
    ({ inputName, index }) => {
      const { prefix } = formInputNames;
      return (
        formik.touched[prefix] &&
        formik.touched[prefix][index] &&
        formik.touched[prefix][index][inputName] &&
        formik.errors[prefix] &&
        formik.errors[prefix][index] &&
        formik.errors[prefix][index][inputName]
      );
    },
    [formik],
  );

  const calculateRemainingAmount = useCallback(() => {
    const { transactionAmount } = formInputNames;
    let splitAmounts = 0;

    formik.values.splits.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key.endsWith(transactionAmount)) {
          splitAmounts += +value;
        }
      });
    });

    const totalAmount = Math.abs(transactionDetails.transaction_amount);
    const percentage = (splitAmounts / totalAmount) * 100;

    return {
      remaining: totalAmount - splitAmounts,
      percentage: percentage.toFixed(6),
    };
  }, [formik]);

  const serializeSingleInitialValue = useCallback((item) => {
    const { departmentId, tagId, transactionAmount, description, receipt, id } = formInputNames;

    return {
      [departmentId]: setSelectInputInitialValue({
        serverValue: item?.department_id,
      }),
      [tagId]: setSelectInputInitialValue({
        serverValue: item?.tags,
      }),
      [transactionAmount]: Math.abs(item?.transaction_amount) || '',
      [description]: item?.reason || '',
      [receipt]: setUploadInputInitialValue({
        serverValue: item?.receipts[0],
      }),
      [id]: item?.id || '',
    };
  }, []);

  useEffect(() => {
    if (transactionDetails.splits?.length) {
      const serializedInitialValues = [];

      transactionDetails[formInputNames.prefix].forEach((item) => {
        const serializedItem = serializeSingleInitialValue(item);
        serializedInitialValues.push(serializedItem);
      });

      formik.setValues((prev) => {
        return {
          ...prev,
          [formInputNames.prefix]: serializedInitialValues,
        };
      });
    }
  }, [transactionDetails]);

  useEffect(() => {
    if (!tags) {
      dispatch(getTagsAction());
    }
    if (!departments) {
      dispatch(getDepartmentsAction());
    }
  }, []);

  useEffect(() => {
    if (tags && appConfig.lang) {
      const filteredTags = tags.filter((ele) => !ele.deleted);
      const serialized = filteredTags.map((ele) => {
        return {
          label: translatedDataFunction({ lang: appConfig.lang, en: ele.name, ar: ele.name_ar }),
          value: ele.id,
          color: ele.color,
        };
      });
      setTagOptions(serialized);
    }
  }, [appConfig, tags]);

  useEffect(() => {
    if (departments && appConfig.lang) {
      const serialized = departments.map((ele) => {
        return {
          label: translatedDataFunction({ lang: appConfig.lang, en: ele.name, ar: ele.name_ar }),
          value: ele.id,
        };
      });
      setDepartmentsOptions(serialized);
    }
  }, [appConfig, departments]);

  return (
    <div className="rounded-lg border border-gray-3 p-3 print:hidden">
      <div className="sticky top-[68px] z-30 mb-3 bg-white pb-2 lg:top-[68px]">
        <SplitProgressBar
          className="mb-2"
          splitRemaining={calculateRemainingAmount()}
          transactionDetails={transactionDetails}
        />
      </div>

      <div className="mb-4 flex gap-1">
        <div>
          <BsFillInfoCircleFill size={18} />
        </div>
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('The amount of the split transaction must be equal to the parent transaction')}
        </AppBody>
      </div>

      {/* Dynamic Form */}
      <form onSubmit={formik.handleSubmit}>
        <div>
          {formik.values[formInputNames.prefix].map((ele, index) => {
            const { prefix, departmentId, tagId, transactionAmount, description, receipt, id } =
              formInputNames;
            const key = `form[${index}]`;
            const isLastEl = index === formik.values.splits.length - 1;
            const showRemoveBtn = index >= 2;

            return (
              <div className={`mb-3 grid gap-4 pb-4 ${!isLastEl ? 'border-b' : ''}`} key={key}>
                {/* Split count and Delete Btn */}
                <div className="flex items-center justify-between">
                  <AppHeader h="h6" className="flex gap-1">
                    <span>{t('Split')}</span>
                    <span>{index + 1}</span>
                  </AppHeader>
                  {showRemoveBtn && (
                    <button
                      type="button"
                      onClick={() => {
                        const updatedValue = formik.values[prefix];
                        updatedValue.splice(index, 1);
                        formik.setFieldValue(prefix, updatedValue);
                      }}
                    >
                      <IoCloseSharp size={24} />
                    </button>
                  )}
                </div>
                {/* ID */}
                <label className="hidden">
                  <input type="hidden" name={`${prefix}[${index}]${id}`} value={ele[id]} />
                </label>
                {/* Department */}
                <label>
                  <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                    {t('Department')} *
                  </AppBody>
                  <AppSelect
                    className="w-full text-xs"
                    isClearable
                    name={`${prefix}[${index}]${departmentId}`}
                    value={ele[departmentId]}
                    onMenuClose={() => {
                      formik.setFieldTouched(`${prefix}[${index}]${departmentId}`);
                    }}
                    options={departmentsOptions}
                    onChange={(option) => {
                      formik.setFieldValue(`${prefix}[${index}]${departmentId}`, option);
                    }}
                  />
                  {showInputError({ index, inputName: departmentId }) && (
                    <AppBody pClass="Caption2Medium" type="span" className="text-red-600">
                      {t(formik.errors[prefix][index][departmentId], { ns: 'commonV2' })}
                    </AppBody>
                  )}
                </label>

                <label>
                  <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                    {t('Assign Tag')} *
                  </AppBody>
                  <AppSelect
                    className="w-full text-xs"
                    isClearable
                    name={`${prefix}[${index}]${tagId}`}
                    value={ele[tagId]}
                    onMenuClose={() => {
                      formik.setFieldTouched(`${prefix}[${index}]${tagId}`);
                    }}
                    options={tagOptions}
                    onChange={(option) => {
                      formik.setFieldValue(`${prefix}[${index}]${tagId}`, option);
                    }}
                  />
                  {showInputError({ index, inputName: tagId }) && (
                    <AppBody pClass="Caption2Medium" type="span" className="text-red-600">
                      {t(formik.errors[prefix][index][tagId], { ns: 'commonV2' })}
                    </AppBody>
                  )}
                </label>

                <label>
                  <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                    {t('Amount')} *
                  </AppBody>
                  <AppNumberInput
                    symbol={transactionDetails.transaction_currency}
                    name={`${prefix}[${index}]${transactionAmount}`}
                    value={ele[transactionAmount]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {showInputError({ index, inputName: transactionAmount }) && (
                    <AppBody pClass="Caption2Medium" type="span" className="text-red-600">
                      {t(formik.errors[prefix][index][transactionAmount], { ns: 'commonV2' })}
                    </AppBody>
                  )}
                </label>

                <label>
                  <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                    {t('Description')}
                    <AppBody pClass="Caption1Regular" type="span" className="mx-1 text-gray-6">
                      ({t('Optional', { ns: 'commonV2' })})
                    </AppBody>
                  </AppBody>
                  <AppTextArea
                    name={`${prefix}[${index}]${description}`}
                    value={ele[description]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </label>

                <div>
                  <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                    {t('Upload Receipt')} *
                  </AppBody>
                  <AppUpload
                    uploadedFilePreview={{ path: ele[receipt].path, type: ele[receipt].type }}
                    showAccepted={false}
                    width="100%"
                    height={130}
                    onChange={({ FILE }) => {
                      formik.setFieldValue(`${prefix}[${index}]${receipt}`, FILE);
                    }}
                  />
                  {showInputError({ index, inputName: receipt }) && (
                    <AppBody pClass="Caption2Medium" type="span" className="text-red-600">
                      {t(formik.errors[prefix][index][receipt], { ns: 'commonV2' })}
                    </AppBody>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Add more and Submit Btns */}
        <div className="flex items-center gap-2">
          <AppIcon iClass="XXLargeFont" className="fa-solid fa-circle-plus" />
          <AppButton
            onClick={() => {
              const { prefix } = formInputNames;
              const updatedValue = formik.values[prefix];
              updatedValue.push(initialValues[prefix][0]);
              formik.setFieldValue(prefix, updatedValue);
            }}
            button="link"
            disabled={formik.values[formInputNames.prefix].length > 9}
          >
            {t('Add More Split')}
          </AppButton>
        </div>

        <div className="flex justify-end gap-3">
          <AppButton
            onClick={() => {
              if (isEdit) {
                setIsEdit(!isEdit);
              }
              if (!isEdit) {
                if (inPage === inPages.myCards) {
                  dispatch(hideReceiptSectionAction(receiptPopupIconIds.SPLIT_RECEIPT));
                }
                if (inPage === inPages.wallet) {
                  dispatch(hideWalletReceiptSectionAction(receiptPopupIconIds.SPLIT_RECEIPT));
                }
              }
            }}
            button="link"
            className="font-medium"
          >
            <span>{t('Cancel', { ns: 'commonV2' })}</span>
          </AppButton>

          <AppButton
            isLoading={loaders.handleAddSplit}
            disabled={!formik.isValid}
            loadingLabel={t('Saving', { ns: 'commonV2' })}
            type="submit"
            button="black"
          >
            {t('Save', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </form>
    </div>
  );
}

export default CreateEditSplitReceipt;
