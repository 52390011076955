import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AppInput from '../../../../components/AppFormInputs/AppInput';
import AppSelect from '../../../../components/FormInputs/AppSelect';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { http } from '../../../../http';
import {
  saveFundMethodFormDataAction,
  saveIBANDataAction,
} from '../../../../redux/WalletReducer/WalletActions';
import AddFundMethodNote from './AddFundMethodNote';

function EnterIBANNumberContent({ onNext, handleCancel }) {
  const { loaders } = useSelector((state) => state);
  const { addFundMethod } = useSelector((state) => state.wallet);
  const [showOtherSourceOfFund, setShowOtherSourceOfFund] = useState(false);
  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();

  const sourceOfFundOptions = useMemo(() => {
    return [
      { value: 'Investments', label: t('Investments') },
      { value: 'Trading Activities', label: t('Trading Activities') },
      { value: 'Retained Profits', label: t('Retained Profits') },
      { value: 'Other', label: `${t('Other [Please Specify]')}` },
    ];
  }, [t]);

  const accountTypeOptions = useMemo(() => {
    return [
      { value: 'Business Account', label: t('Business Account') },
      { value: 'Current Account', label: t('Current Account') },
      { value: 'Saving Account', label: t('Saving Account') },
      { value: 'Salary Account', label: t('Salary Account') },
      { value: 'Other', label: `${t('Other [Please Specify]')}` },
    ];
  }, [t]);

  const getIBANData = useCallback((values) => {
    http
      .get(`funding_method/iban/${values.iban}`, { loader: 'getIBANData' })
      .then((res) => {
        dispatch(saveIBANDataAction(res.data));

        onNext({ stepIndex: 0 });
      })
      .catch(() => {
        onNext({ stepIndex: 0 });
      });
  });

  const initialValues = useMemo(() => {
    return {
      sourceOfFund: addFundMethod.fundMethodFormData?.sourceOfFund || [],
      otherSourceOfFund: addFundMethod.fundMethodFormData?.otherSourceOfFund || '',
      accountType: addFundMethod.fundMethodFormData?.accountType || '',
      otherAccountType: addFundMethod.fundMethodFormData?.otherAccountType || '',
      accountHolderName: addFundMethod.fundMethodFormData?.accountHolderName || '',
      iban: addFundMethod.fundMethodFormData?.iban || '',
    };
  }, [addFundMethod]);

  const validationSchema = yup.object().shape({
    sourceOfFund: yup.array().min(1, t('This field is required!', { ns: 'commonV2' })),
    otherSourceOfFund: yup.string().when('sourceOfFund', {
      is: (values) => {
        const hasOther = values.find((ele) => ele.value === 'Other');
        return Boolean(hasOther);
      },
      then: () => yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    }),
    accountType: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    otherAccountType: yup.string().when('accountType', {
      is: 'Other',
      then: () => yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    }),
    accountHolderName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    iban: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const onSubmit = useCallback((values) => {
    getIBANData(values);
    dispatch(saveFundMethodFormDataAction(values));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const otherOption = formik.values.sourceOfFund.find((ele) => ele.value === 'Other');
    setShowOtherSourceOfFund(Boolean(otherOption));
  }, [formik.values.sourceOfFund]);

  return (
    <div className="px-1">
      <AppHeader h="h4" className="mb-4">
        {t('Add Funding Method')}
      </AppHeader>

      <div className="mb-4 xl:w-[80%]">
        <AddFundMethodNote />
      </div>

      <form>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:w-[80%]">
          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Source of Fund')}
            </AppBody>

            <AppSelect
              isMulti
              isClearable={false}
              className="text-sm"
              mobileSelect={false}
              name="sourceOfFund"
              value={formik.values.sourceOfFund}
              onMenuClose={() => {
                formik.setFieldTouched(`sourceOfFund`);
              }}
              options={sourceOfFundOptions}
              onChange={(option) => {
                formik.setFieldValue(`sourceOfFund`, option);
                formik.setErrors({ sourceOfFund: '' });
              }}
            />
            {formik.errors.sourceOfFund && formik.touched.sourceOfFund && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.sourceOfFund, { ns: 'commonV2' })}
              </AppBody>
            )}
            {showOtherSourceOfFund && (
              <AppInput
                className="mt-2 rounded-xl text-sm"
                placeholder={t('Enter Here', { ns: 'commonV2' })}
                size="md"
                type="text"
                name="otherSourceOfFund"
                value={formik.values.otherSourceOfFund}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            )}
            {formik.errors.otherSourceOfFund && formik.touched.otherSourceOfFund && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.otherSourceOfFund, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Account Type')}
            </AppBody>

            <AppSelect
              className="w-full text-sm"
              mobileSelect
              name="accountType"
              value={accountTypeOptions.find((ele) => ele.value === formik.values.accountType)}
              onMenuClose={() => {
                formik.setFieldTouched(`accountType`);
              }}
              options={accountTypeOptions}
              onChange={(option) => {
                formik.setFieldValue(`accountType`, option?.value || '');
              }}
            />
            {formik.errors.accountType && formik.touched.accountType && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.accountType, { ns: 'commonV2' })}
              </AppBody>
            )}
            {formik.values.accountType === 'Other' && (
              <AppInput
                className="mt-2 rounded-xl text-sm"
                placeholder={t('Enter Here', { ns: 'commonV2' })}
                size="md"
                type="text"
                name="otherAccountType"
                value={formik.values.otherAccountType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            )}
            {formik.errors.otherAccountType && formik.touched.otherAccountType && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.otherAccountType, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Account Holder Name')}
            </AppBody>

            <AppInput
              className="rounded-xl"
              size="md"
              type="text"
              name="accountHolderName"
              value={formik.values.accountHolderName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.accountHolderName && formik.touched.accountHolderName && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.accountHolderName, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>

          <label>
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('IBAN')}
            </AppBody>

            <AppInput
              className="rounded-xl"
              size="md"
              type="text"
              name="iban"
              value={formik.values.iban}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.iban && formik.touched.iban && (
              <AppBody pClass="Caption2Medium" type="span" className="text-danger">
                {t(formik.errors.iban, { ns: 'commonV2' })}
              </AppBody>
            )}
          </label>
        </div>
      </form>

      <div className="mt-6 flex justify-end gap-4 lg:mt-44 lg:gap-8">
        <AppButton button="link" onClick={handleCancel} size="lg" className="text-base font-bold">
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          disabled={!formik.isValid}
          isLoading={loaders.getIBANData}
          type="button"
          size="lg"
          className="lg:min-w-[170px]"
          button="black"
          onClick={formik.handleSubmit}
        >
          {t('Send', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default EnterIBANNumberContent;
