import { useSelector } from 'react-redux';
import { cardProgramActionsPopupContent } from '../../../../../helpers/constants';
import CardProgramStatementContent from './CardProgramStatementContent';

function CardProgramActionsPopupContent() {
  const { currentCardProgramActionsPopupContent } = useSelector((state) => state.wallet);

  return (
    <div>
      {/* Statement */}
      {currentCardProgramActionsPopupContent.content ===
        cardProgramActionsPopupContent.statement && <CardProgramStatementContent />}
    </div>
  );
}

export default CardProgramActionsPopupContent;
