import {AppBody} from "./html/html";

const StatBadge = function (props) {
  const { status, bgColor, color } = props;
  const divStyle = {
    minWidth: '88px',
    background: `${bgColor}`,
    color,
  };
  return (
    <AppBody pClass="Caption1Regular" type="span" className="inline-block rounded-2xl px-5 py-1 text-center" style={divStyle}>
      {status}
    </AppBody>
  );
};

export default StatBadge;
