const INITIAL_STATE = {
  activeProfileTabIndex: null,
  userInfo: null,
};

const ProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_PROFILE_TAB_INDEX': {
      return { ...state, activeProfileTabIndex: action.payload };
    }

    case 'GET_USER_INFO': {
      return { ...state, userInfo: action.payload };
    }

    default:
      return state;
  }
};

export default ProfileReducer;
