import React from 'react';
import masterCardLogo from '../../assets/png/Mastercard-logo.png';
import visaCardLogo from '../../assets/png/Visa-logo.png';
import {AppIcon} from "../html/html";

function CardProgramLogo({ cardproduct }) {
  return (
    <>
      <span>
        <AppIcon iClass="XLargeFont" className={`${cardproduct?.funding_source_type?.bank_logo}`} />
      </span>
      <span>
        {cardproduct?.scheme === 'MASTER' && (
          <img src={masterCardLogo} alt="masterCard Logo" width={35} style={{ maxWidth: '35px' }} />
        )}
        {cardproduct?.scheme === 'VISA' && (
          <img src={visaCardLogo} alt="visa Logo" width={35} style={{ maxWidth: '35px' }} />
        )}
      </span>
    </>
  );
}

export default CardProgramLogo;
