import AppNoData from '../../components/AppNoData';

const INITIAL_STATE = {
  show: false,
  data: (
    <div className="bg-white p-5">
      <AppNoData />
    </div>
  ),
};

const AppModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // ================ App Modal ================ //
    case 'SHOW_MODAL': {
      return { ...state, show: true, data: action.payload || INITIAL_STATE.data };
    }
    case 'HIDE_MODAL': {
      return { ...state, show: false, data: INITIAL_STATE.data };
    }

    default:
      return state;
  }
};

export default AppModalReducer;
