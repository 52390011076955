import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Page, pdfjs } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';

import LoadingError from './LoadingError';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function AttachmentPreview({
  isLoading,
  errored,
  fetchAttachment,
  attachmentId,
  attachmentPath,
  attachment,
  attachmentType,
  forPrint,
  setAttachmentRender,
}) {
  const [pages, setPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setPages(numPages);
    setAttachmentRender(true);
  }
  return (
    <div className="flex w-full flex-grow flex-col justify-start ltr:text-left rtl:text-right">
      {isLoading && (
        <Skeleton containerClassName="pt-5 pb-5 w-full" className="pb-4 pt-4" count={1} />
      )}
      {!isLoading && <LoadingError show={errored} retryCallback={fetchAttachment} />}
      {!isLoading && !errored && (
        <div className="flex h-auto w-full max-w-full flex-row">
          <div key={attachmentId} className="h-auto w-full max-w-full">
            <a
              href={attachment}
              target="_blank"
              rel="noreferrer"
              title={attachmentPath && attachmentPath.split('/').pop()}
              className="h-auto w-full max-w-full"
            >
              {attachmentType && attachmentType.includes('image/') && !forPrint && (
                <img src={attachment} className="h-auto max-h-52 w-full max-w-full rounded-lg" />
              )}
              {attachmentType && attachmentType.includes('image/') && forPrint && (
                <img src={attachment} className="h-auto max-w-full rounded-lg" />
              )}
              {attachmentType && attachmentType.includes('application/') && !forPrint && (
                <div>
                  <Document
                    file={{ url: attachment }}
                    className="m-auto h-auto max-h-52 w-full max-w-full rounded-lg"
                  >
                    <Page pageNumber={1} height={200} />
                  </Document>
                </div>
              )}

              {attachmentType && attachmentType.includes('application/') && forPrint && (
                <div>
                  {/* eslint-disable-next-line react/jsx-no-bind */}
                  <Document
                    file={{ url: attachment }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="m-auto h-auto max-h-fit w-auto max-w-full rounded-lg"
                  >
                    {pages &&
                      Array.from(new Array(pages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                      ))}
                  </Document>
                </div>
              )}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default AttachmentPreview;
