import parse from 'html-react-parser';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppNoData from '../../../components/AppNoData';
import AppSpinner from '../../../components/AppSpinner';
import { AppHeader } from '../../../components/html/html';
import { http } from '../../../http';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';

function CompanyAgreementContent() {
  const { loaders } = useSelector((state) => state);
  const { lang } = useSelector((state) => state.appConfig);
  const [agreements, setAgreements] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation('profileV2');

  const getCompanyAgreements = useCallback(() => {
    http
      .get('agreements/key/company-agreement', { loader: 'getCompanyAgreements' })
      .then((res) => {
        setAgreements(lang === 'en' ? res.data.content || '' : res.data.content_ar || '');
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  });

  useEffect(() => {
    getCompanyAgreements();
  }, [lang]);

  return (
    <div>
      {loaders.getCompanyAgreements && (
        <div className="h-[50vh]">
          <AppSpinner />
        </div>
      )}

      {!loaders.getCompanyAgreements && (
        <>
          <AppHeader h="h4" className="mb-4">
            {t('Terms & Conditions')}
          </AppHeader>
          <div className="flex h-[50vh] items-center justify-center">
            {agreements && (
              <div style={{ direction: 'ltr' }} className="h-full w-full">
                {parse(agreements)}
              </div>
            )}
            {!agreements && <AppNoData />}
          </div>
        </>
      )}
    </div>
  );
}

export default CompanyAgreementContent;
