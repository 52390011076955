import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from '../../../components/Button/BackButton';
import LoadingError from '../../../components/LoadingError';
import { apiGetRequest } from '../../../helpers/APIHelper';
import { setAction } from '../../../helpers/ActionsHelper';
import useNotifications from '../../../helpers/useNotifications';
import { UPDATE_FUND_ITEM } from '../../../redux/FundsReducer';
import Head from '../../Head';
import FundPreview from './FundPreview';
import { approveClickHandler } from './Helper';
import RejectionForm from './RejectionForm';

export default function FundDetails() {
  const { t } = useTranslation(['funds', 'common']);
  const params = useParams();
  const fundId = parseInt(params.id, 10);
  const [rejectingFund, setRejectingFund] = useState(null);
  const dispatch = useDispatch();
  const [cookies] = useCookies(['token']);
  const [fund, setFund] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [, addNotification] = useNotifications();

  const fetchFund = () => {
    setIsLoading(true);
    apiGetRequest(`funds/${fundId}`, cookies.token)
      .then((response) => {
        setFund(response);
        setErrored(false);
        setIsLoading(false);
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchFund();
  }, [fundId]);

  if (!fund) {
    return (
      <div className="mx-auto flex w-full flex-col lg:w-10/12">
        <Head title="Fund Request Details" />
        <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
          <div className="col-span-6 flex flex-col justify-start">
            {isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchFund} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col ltr:text-left rtl:text-right">
      <Head title="Fund Request Details" />

      <div className="px-2 lg:px-10">
        <BackButton />
      </div>
      {rejectingFund === null && (
        <FundPreview
          mobileView
          rejectAction={() => Promise.resolve(setRejectingFund(fund))}
          approveAction={() =>
            approveClickHandler(fund.id, cookies.token)
              .then((result) => {
                dispatch(setAction(UPDATE_FUND_ITEM, result));
                setFund(result);
              })
              .catch((err) => {
                addNotification(
                  (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
                  false,
                );
              })
          }
          selectedItem={fund}
        />
      )}
      {rejectingFund !== null && (
        <RejectionForm
          setRejectingFund={setRejectingFund}
          selectedFund={fund}
          setSelectedFund={setFund}
        />
      )}
    </div>
  );
}
