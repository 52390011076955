import { getURLParams } from 'javascript-functions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import handAnimationIn from '../../../../assets/gifs/handAnimation_in.gif';
import handAnimationOut from '../../../../assets/gifs/handAnimation_out.gif';
import transferAnimation from '../../../../assets/gifs/transferAnimation.gif';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';

function ActionFinishPage() {
  const { t } = useTranslation('walletV2');
  const { actions, singleSelectedCardProgramDetails } = useSelector((state) => state.wallet);

  return (
    <div className="flex h-full items-center justify-center overflow-y-auto rounded-lg border border-gray-3 shadow">
      <div className="w-[80%] md:w-[60%] lg:w-[40%]">
        {getURLParams('handAnimationIn') && (
          <img src={handAnimationIn} alt="finish" className="mb-6" />
        )}
        {getURLParams('handAnimationOut') && (
          <img src={handAnimationOut} alt="finish" className="mb-6" />
        )}
        {getURLParams('transferAnimation') && <img src={transferAnimation} alt="finish" />}
        <AppHeader h="h5" className="my-6">
          {t(getURLParams('message'))}
        </AppHeader>
        <AppBody pClass="Body1Regular" className="mb-2">
          <span>{t('Amount', { ns: 'commonV2' })}</span>
          <span>:</span>
          <span className="ms-2">{actions?.amount}</span>
          <span className="ms-1">{singleSelectedCardProgramDetails?.currency}</span>
        </AppBody>
        <AppBody pClass="Body1Regular" className="mb-4">
          <span>{t('Request N')}</span>
          <span>:</span>
          <span className="ms-2">{getURLParams('requestNumber')}</span>
        </AppBody>
        {getURLParams('note') && (
          <AppBody pClass="Body2Bold" className="mb-8 text-gray-3">
            {t(getURLParams('note'))}
          </AppBody>
        )}
        <div className="mx-auto text-center md:w-1/2">
          <AppButton
            className="mb-5 w-full"
            size="lg"
            onClick={() => {
              history.push(
                `/dashboard/wallet?selectedCardProgramId=${getURLParams('selectedCardProgramId')}`,
              );
            }}
            button="black"
          >
            {t('Back To Wallet')}
          </AppButton>
        </div>
      </div>
    </div>
  );
}

export default ActionFinishPage;
