const useAccountReady = () => {
  let accountReady = false;
  const userinfo = localStorage.getItem('userinfo')
    ? JSON.parse(localStorage.getItem('userinfo'))
    : null;

  if (userinfo !== null) {
    accountReady =
      userinfo.phone_verified &&
      userinfo.email_verified &&
      userinfo.yakeen_verified &&
      userinfo.account_verified;

    if (!accountReady) {
      return false;
    }
    if (accountReady && userinfo.account_id === 0) {
      return false;
    }
    accountReady = userinfo.business_verified && userinfo.address_verified && userinfo.accept_terms;
    if (
      userinfo.account &&
      userinfo.account.status !== 'Activated' &&
      userinfo.account.status !== 'Suspended'
    ) {
      return false;
    }
  }

  return accountReady;
};
export default useAccountReady;
