import React from 'react';
import TranslatedData from '../TranslatedData';
import CardProgramSchemeLogo from './CardProgramSchemeLogo';
import {AppBody} from '../html/html';

function DeclineDetails({ transactionDetails }) {
  return (
    <div className="rounded-lg border border-gray-3 p-3">
      <div className="mb-2 flex items-start justify-between">
        <div className="flex flex-col">
          <AppBody pClass="Body2Bold" type="span">
            {`${transactionDetails?.user_id.first_name} ${transactionDetails?.user_id.last_name}`}
          </AppBody>
          <AppBody pClass="Body2Bold" type="span">
            <TranslatedData
              en={transactionDetails.department_id.name}
              ar={transactionDetails.department_id.name_ar}
            />
          </AppBody>
        </div>
        <div className="flex items-center gap-1">
          <span>
            <CardProgramSchemeLogo cardProgramScheme={transactionDetails?.network} />
          </span>
          <span>{transactionDetails?.card_last4}</span>
        </div>
      </div>

      <div className="mb-3 mt-4">
        <AppBody pClass="Body2Light" className="mb-1 text-gray-6">
          # {transactionDetails.id}
        </AppBody>
        <AppBody pClass="Body2Light" className="flex gap-1 text-gray-6">
          <span>
            <TranslatedData
              en={transactionDetails.status_description}
              ar={transactionDetails.status_description_ar}
            />
          </span>
        </AppBody>
      </div>
    </div>
  );
}

export default DeclineDetails;
