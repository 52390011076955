import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import AppRadioInput from '../../../../../../components/FormInputs/AppRadioInput';
import AppTextArea from '../../../../../../components/FormInputs/AppTextArea';
import CardProgramSchemeLogo from '../../../../../../components/SharedComponents/CardProgramSchemeLogo';
import TranslatedData from '../../../../../../components/TranslatedData';
import {
  setOtherRejectionReasonAction,
  setSelectedRejectionReasonAction,
} from '../../../../../../redux/WalletReducer/WalletActions';
import {AppBody} from "../../../../../../components/html/html";

function RequestRejectionReasons({ requestDetails, cardProgramScheme }) {
  const { selectedRejectionReason } = useSelector((state) => state.wallet);
  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();

  const rejectionReasons = useMemo(() => {
    return [
      {
        label: t('The attached document is not clear'),
        value: t('The attached document is not clear'),
      },
      {
        label: t('The reason is not accepted'),
        value: t('The reason is not accepted'),
      },
      {
        label: t('Invoice or quotations are required for request'),
        value: t('Invoice or quotations are required for request'),
      },
      {
        label: t('No allocated budget'),
        value: t('No allocated budget'),
      },
      {
        label: t('Other'),
        value: 'Other',
      },
    ];
  });

  return (
    <div className="rounded-lg border border-gray-300 p-3">
      <div className="mb-5 flex items-start justify-between">
        <div className="flex flex-col gap-4">
          <AppBody pClass="Body2Bold" type="span">
            {requestDetails.card_id?.name_on_card ?? ''}
          </AppBody>
          <AppBody pClass="Body2Bold" type="span">
            <TranslatedData
              en={requestDetails.department_id?.name}
              ar={requestDetails.department_id?.name_ar}
            />
          </AppBody>
        </div>
        <div className="flex items-center gap-1">
          <span>
            <CardProgramSchemeLogo cardProgramScheme={cardProgramScheme} />
          </span>
          <span>{requestDetails.card_id?.last_four}</span>
        </div>
      </div>

      <div className="mx-auto mb-2 h-[1px] w-1/2 bg-stone-300" />
      <div>
        <div className="mb-3 grid gap-1">
          {rejectionReasons.map((o) => {
            return (
              <AppRadioInput
                key={o.value}
                id={o.value}
                value={o.value}
                label={o.label}
                name="rejectionReasons"
                onChange={() => {
                  dispatch(setSelectedRejectionReasonAction(o));
                }}
              />
            );
          })}
        </div>

        {selectedRejectionReason?.value === 'Other' && (
          <div>
            <AppTextArea
              name="reason"
              onChange={(e) => {
                dispatch(setOtherRejectionReasonAction(e.target.value));
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default RequestRejectionReasons;
