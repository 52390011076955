import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAccountReady from '../../helpers/useAccountReady';
import { ProfileMenuItem } from './HeaderMenu';

const UserPictureHeader = function ({ className, height, width }) {
  const { user } = useSelector((state) => state.auth);
  const accountReady = useAccountReady();
  const { t, i18n } = useTranslation(['common']);
  const userName =
    i18n.language === 'ar' && user.first_name_ar !== '' && user.last_name_ar !== ''
      ? `${user.first_name_ar} ${user.last_name_ar}`
      : `${user.first_name} ${user.last_name}`;

  return (
    <div className="flex grid-cols-4 border-b border-gray-3 border-opacity-40 px-4 py-6 hover:bg-gray-100">
      <div
        className={`${className || ''} ${height || 'h-10 xl:h-16'} ${
          width || 'w-10 xl:w-16'
        } relative col-span-1 flex flex-row justify-center ltr:mr-1 rtl:ml-1`}
      >
        <div className="text-md flex h-full w-full flex-col justify-center rounded-full border border-black bg-gray-2 text-center font-bold text-gray-6">
          {`${user.first_name[0]}${user.last_name[0]}`}
        </div>
      </div>

      <div className="col-span-3">
        <span>{userName}</span>
        {accountReady && <ProfileMenuItem label={t('view_profile')} to="/dashboard/profile/user" />}
      </div>
    </div>
  );
};

export default UserPictureHeader;
