import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { history } from '../../AppRouter/history';
import AppInput from '../../components/AppFormInputs/AppInput';
import { AppBody, AppButton, AppHeader } from '../../components/html/html';
import { employeeRegistrationSteps } from '../../helpers/constants';
import { PasswordValidationPattern } from '../../helpers/RegexPatterns';
import { devLog, getServerErrMessage } from '../../helpers/Utils';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import { setRegistrationStepAction } from '../../redux/EmployeeRegistrationReducer/EmployeeRegistrationActions';

function SetupPassword() {
  const { loaders } = useSelector((state) => state);
  const { t } = useTranslation('employeeRegistration');
  const dispatch = useDispatch();
  const { employeeToken } = useParams();

  const onSubmit = useCallback(
    (values) => {
      devLog(`Setting User Password...`);
      const formBody = new FormData();

      formBody.append('password', values.password);
      formBody.append('password_confirmation', values.confirmPassword);
      formBody.append('token', employeeToken);

      http
        .post(`email/reset`, formBody, { loader: 'handleSettingUserPassword' })
        .then(() => {
          devLog(`User Password Set!`);
          dispatch(setRegistrationStepAction(employeeRegistrationSteps.passwordSetSuccess));
        })
        .catch((err) => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: getServerErrMessage(err),
            }),
          );
        });
    },
    [employeeToken],
  );

  const initialValues = useMemo(() => {
    return { password: '', confirmPassword: '' };
  }, []);

  const validationSchema = yup.object({
    password: yup
      .string()
      .matches(PasswordValidationPattern, t('Please enter a valid password.', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
    confirmPassword: yup
      .string()
      .matches(PasswordValidationPattern, t('Please enter a valid password.', { ns: 'commonV2' }))
      .oneOf([yup.ref('password'), null], t('The password does not match.', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <div className="flex h-full items-center justify-center">
      <div className="max-w-[550px] px-2 xxs:px-4">
        <AppHeader h="h2" className="mb-2 text-center">
          {t('Setup your password')}
        </AppHeader>

        <AppBody pClass="Body1Medium" className="mb-16 text-center text-gray-6">
          {t('Enter your new password below to complete your account activation')}
        </AppBody>

        <form onSubmit={formik.handleSubmit} className="mx-auto max-w-[400px]">
          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">
              {t('Password', { ns: 'commonV2' })}
            </span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              showInvalidIcon={false}
              size="lg"
              name="password"
              type="password"
              invalid={formik.errors.password && formik.touched.password}
              valid={!formik.errors.password && formik.touched.password}
            />
            {formik.errors.password && formik.touched.password && (
              <small className="text-danger">{t(formik.errors.password, { ns: 'commonV2' })}</small>
            )}
          </label>

          <label className="mb-2 block">
            <span className="mb-1 block text-xl font-bold">
              {t('Confirm Password', { ns: 'commonV2' })}
            </span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              showInvalidIcon={false}
              size="lg"
              name="confirmPassword"
              type="password"
              invalid={formik.errors.confirmPassword && formik.touched.confirmPassword}
              valid={!formik.errors.confirmPassword && formik.touched.confirmPassword}
            />
            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
              <small className="text-danger">
                {t(formik.errors.confirmPassword, { ns: 'commonV2' })}
              </small>
            )}
          </label>

          <AppBody pClass="Caption1Medium" className="mb-16 text-gray-6">
            {t(
              'Be a minimum of 8 characters ،Include at least one lower letter (a-z) ،Include at least one uppercase (A-Z) ، Include at least one number (9-0)',
            )}
          </AppBody>

          <div>
            <AppButton
              disabled={!formik.isValid}
              isLoading={loaders.handleSettingUserPassword}
              type="submit"
              size="lg"
              className="mb-6 w-full"
              button="primary"
              rounded="xl"
            >
              {t('Submit')}
            </AppButton>

            <AppButton
              button="link"
              className="w-full text-center font-bold"
              onClick={() => {
                history.push('/auth/login');
              }}
              size="lg"
            >
              {t('Cancel', { ns: 'commonV2' })}
            </AppButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SetupPassword;
