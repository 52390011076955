import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../../AppRouter/history';
import AppInput from '../../../components/AppFormInputs/AppInput';
import AppSelect from '../../../components/FormInputs/AppSelect';
import { AppButton, AppHeader, AppIcon } from '../../../components/html/html';
import { getServerErrMessage } from '../../../helpers/Utils';
import { http } from '../../../http';
import { hideModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';
import { getUserInfoAction } from '../../../redux/ProfileReducer/ProfileActions';

function UserEditAddress() {
  const { userInfo } = useSelector((state) => state.profile);
  const { isMobile } = useSelector((state) => state.appConfig);
  const { t } = useTranslation('profileV2');
  const { user } = useSelector((state) => state.auth);
  const { loaders } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const customSelectStyles = useMemo(() => {
    return {
      control: (provided) => ({
        ...provided,
        borderRadius: '.25rem',
        height: '48px',
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: '150px',
      }),
    };
  }, []);

  const handleEditAddress = useCallback((values) => {
    const formData = new FormData();

    formData.append('user_id', values.user_id);
    formData.append('country_id', values.country_id);
    formData.append('city_id', values.city_id);
    formData.append('state', values.state);
    formData.append('postal_code', values.postal_code);
    formData.append('address1', values.address1 || '');
    formData.append('address2', values.address2 || '');
    // Edit Profile Address
    formData.append('is_account_address', 0);

    http
      .post('shipping_address', formData, { loader: 'handleEditAddress' })
      .then(() => {
        if (!isMobile) {
          dispatch(hideModalAction());
          dispatch(getUserInfoAction());
        } else {
          history.push('/dashboard/profile/user');
        }

        dispatch(
          showToasterAction({
            type: 'success',
            message: t('Address Changed successfully!'),
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, []);

  const onSubmit = useCallback(
    (values) => {
      const dataValues = {
        user_id: user.id,
        country_id: values.country.value,
        city_id: values.cityOrState.value,
        state: values.cityOrState.label,
        postal_code: values.postalCode,
        address1: values.address1,
        address2: values.address2,
      };
      handleEditAddress(dataValues);
    },
    [user],
  );

  const initialValues = useMemo(() => {
    return {
      country:
        {
          label: userInfo?.address?.country.name,
          value: userInfo?.address?.country.id,
        } || '',
      cityOrState:
        {
          label: userInfo?.address?.city.name,
          value: userInfo?.address?.city.id,
        } || '',
      postalCode: userInfo?.address?.postal_code || '',
      address1: userInfo?.address?.address1 || '',
      address2: userInfo?.address?.address2 || '',
    };
  }, [userInfo]);

  const validationSchema = yup.object({
    country: yup.mixed().required(t('This field is required!', { ns: 'commonV2' })),
    cityOrState: yup.mixed().required(t('This field is required!', { ns: 'commonV2' })),
    postalCode: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    address1: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema, enableReinitialize: true });

  const getCountries = useCallback(() => {
    http
      .get('countries', { loader: 'getCountries' })
      .then((res) => {
        const serialized = res.data.map((ele) => {
          return {
            label: ele.name,
            value: ele.id,
          };
        });
        setCountries(serialized);
        formik.setFieldValue(
          'country',
          serialized.find((ele) => ele.value === userInfo?.address?.country?.id) || '',
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  const getCities = useCallback((countryId) => {
    http
      .get(`countries/${countryId}/cities`, { loader: 'getCities' })
      .then((res) => {
        const serialized = res.data.map((ele) => {
          return {
            label: ele.name,
            value: ele.id,
          };
        });
        setCities(serialized);
        formik.setFieldValue(
          'cityOrState',
          serialized.find((ele) => ele.value === userInfo?.address?.city?.id) || '',
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (formik.values.country?.value) {
      getCities(formik.values.country.value);
    }
  }, [formik.values.country]);

  return (
    <>
      {isMobile && (
        <button
          className="mt-8"
          type="button"
          onClick={() => {
            history.push('/dashboard/profile/user');
          }}
        >
          <AppIcon
            className="fa-regular fa-angle-left text-primary rtl:rotate-180"
            iClass="XXLargeFont"
          />
        </button>
      )}
      <AppHeader h="h1" className="mb-6 text-center">
        {t('Edit Address')}
      </AppHeader>

      <form onSubmit={formik.handleSubmit} className="mx-auto grid gap-6 lg:w-[80%]">
        <div>
          <AppHeader h="h6" className="mb-2">
            {t('Country', { ns: 'commonV2' })}
          </AppHeader>
          <AppSelect
            isDisabled={!!userInfo?.address?.country?.id}
            className="w-full"
            name="country"
            isLoading={loaders.getCountries}
            value={formik.values.country}
            options={countries}
            onChange={(option) => {
              formik.setFieldValue('country', option);
              if (formik.values.cityOrState) {
                formik.setFieldValue('cityOrState', '');
                formik.setFieldTouched('cityOrState', false);
              }
            }}
            onMenuClose={() => {
              formik.setFieldTouched('country');
            }}
            onMenuOpen={() => {}}
            customStyleConfigs={customSelectStyles}
          />
          {formik.errors.country && formik.touched.country && (
            <small className="text-danger">{t(formik.errors.country, { ns: 'commonV2' })}</small>
          )}
        </div>

        <div>
          <AppHeader h="h6" className="mb-2">
            {t('City/State', { ns: 'commonV2' })}
          </AppHeader>
          <AppSelect
            className="w-full"
            name="cityOrState"
            isDisabled={!formik.values.country}
            isLoading={loaders.getCities}
            value={formik.values.cityOrState}
            options={cities}
            onChange={(option) => {
              formik.setFieldValue('cityOrState', option);
            }}
            onMenuClose={() => {
              formik.setFieldTouched('cityOrState');
            }}
            onMenuOpen={() => {}}
            customStyleConfigs={customSelectStyles}
          />
          {formik.errors.cityOrState && formik.touched.cityOrState && (
            <small className="text-danger">
              {t(formik.errors.cityOrState, { ns: 'commonV2' })}
            </small>
          )}
        </div>

        <div>
          <AppHeader h="h6" className="mb-2">
            {t('Postal Code', { ns: 'commonV2' })}
          </AppHeader>
          <AppInput
            name="postalCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postalCode}
          />
          {formik.errors.postalCode && formik.touched.postalCode && (
            <small className="text-danger">{t(formik.errors.postalCode, { ns: 'commonV2' })}</small>
          )}
        </div>

        <div>
          <AppHeader h="h6" className="mb-2">
            {t('Address Line 1', { ns: 'commonV2' })}
          </AppHeader>
          <AppInput
            name="address1"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address1}
          />
          {formik.errors.address1 && formik.touched.address1 && (
            <small className="text-danger">{t(formik.errors.address1, { ns: 'commonV2' })}</small>
          )}
        </div>

        <div>
          <AppHeader h="h6" className="mb-2">
            {t('Address Line 2', { ns: 'commonV2' })}
          </AppHeader>
          <AppInput
            name="address2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address2}
          />
          {formik.errors.address2 && formik.touched.address2 && (
            <small className="text-danger">{t(formik.errors.address2, { ns: 'commonV2' })}</small>
          )}
        </div>

        <AppButton
          button="primary"
          size="lg"
          rounded="md"
          className="my-4 w-full"
          type="submit"
          isLoading={loaders.handleEditAddress}
          disabled={!formik.dirty}
        >
          {t('Confirm', { ns: 'commonV2' })}
        </AppButton>
      </form>
    </>
  );
}

export default UserEditAddress;
