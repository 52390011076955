import { randomId } from 'javascript-functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, pdfjs } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Headline } from '../Titles';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function SingleAttachmentFilePrint({ path, attachment, isPDF, noTitle }) {
  const { t } = useTranslation('cardsV2');
  const [pages, setPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setPages(numPages);
  }

  return (
    <div className="page-break-after">
      {!noTitle && <Headline className="mb-2">{t(attachment.doc_type)}</Headline>}
      {isPDF && (
        <div>
          <Document
            file={{ url: path ?? attachment.path }}
            onLoadSuccess={onDocumentLoadSuccess}
            className="m-auto h-auto max-h-fit w-auto max-w-full"
          >
            {pages &&
              Array.from(new Array(pages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
          </Document>
        </div>
      )}
      {!isPDF && (
        <img
          src={path ?? attachment.path}
          alt={randomId()}
          className="h-auto w-full"
          style={{ maxHeight: '500px' }}
        />
      )}
      <div />
    </div>
  );
}
export default SingleAttachmentFilePrint;
