import React from 'react';
import { useTranslation } from 'react-i18next';
import { Headline } from '../../../../components/Titles';
import SplitAttachmentPart from './SplitAttachmentPart';

function SplitPart({ transaction, split, tags, index, setEditTags }) {
  const { t } = useTranslation(['transactions', 'common']);

  return (
    <div className="my-1 bg-gray-1 p-2 lg:p-5">
      <div className="flex flex-row items-center justify-between lg:px-10">
        <Headline>
          {t('split_counter')} {index + 1} {t('of')} {transaction.splits.length}
        </Headline>
        <Headline style={{ direction: 'ltr' }}>
          {split.transaction_amount >= 0
            ? `${parseFloat(split.transaction_amount).toLocaleString()} ${
                split.transaction_currency
              }`
            : `(${parseFloat(split.transaction_amount * -1).toLocaleString()}) ${
                split.transaction_currency
              }`}
        </Headline>
      </div>
      <SplitAttachmentPart split={split} tags={tags} setEditTags={setEditTags} />
    </div>
  );
}

export default SplitPart;
