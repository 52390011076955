import { randomId } from 'javascript-functions';
import { useTranslation } from 'react-i18next';

function PreviewButton({ href, isPDF }) {
  const { t } = useTranslation('commonV2');

  return (
    <div className="group relative h-full w-full">
      <div
        data-id="preview-btn"
        className="absolute z-20 hidden h-full w-full items-center justify-center bg-white bg-opacity-70 group-hover:flex"
      >
        <a href={href} target="_blank" rel="noreferrer">
          {t('Preview')}
        </a>
      </div>

      {!isPDF && <img src={href} alt={randomId()} className="h-full w-full object-cover" />}

      {isPDF && <iframe className="h-[150%] w-[150%] border-0 bg-white" src={href} title="pdf" />}
    </div>
  );
}

export default PreviewButton;
