import { randomId } from 'javascript-functions';
import { useDispatch, useSelector } from 'react-redux';
import { cardStatusKeys } from '../../helpers/constants';
import { useMockAction } from '../../redux/UseMockReducer/UseMockActions';
import AppSwitchInput from '../AppSwitchInput';

function CreateNewCardMockContent() {
  const { useMock } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-2 text-xs [&>*]:border-b-2 [&>*]:pb-2">
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.hasCardHolder}
            onChange={() => {
              dispatch(
                useMockAction({
                  hasCardHolder: !useMock.hasCardHolder,
                  hasNoCardHolder: false,
                }),
              );
            }}
          />
          <span>Has Card Holder</span>
        </label>
      </div>

      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.hasNoCardHolder}
            onChange={() => {
              dispatch(
                useMockAction({
                  hasNoCardHolder: !useMock.hasNoCardHolder,
                  hasCardHolder: false,
                }),
              );
            }}
          />
          <span>Has NO Card Holder</span>
        </label>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.hasPrimaryCardMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  hasPrimaryCardMock: !useMock.hasPrimaryCardMock,
                  hasNoPrimaryCardMock: false,
                }),
              );
            }}
          />
          <span>Has Primary Card</span>
        </label>

        <div className="flex flex-wrap items-center gap-2">
          {['ACTIVE', 'SUSPENDED', 'INACTIVE'].map((ele) => {
            return (
              <label
                className={`flex items-center gap-1 ${
                  !useMock.hasPrimaryCardMock ? 'opacity-30' : ''
                }`}
              >
                <input
                  key={randomId()}
                  name="mockPrimaryStatus"
                  type="radio"
                  value={ele}
                  checked={useMock.hasPrimaryCardMockStatus === ele}
                  onChange={(e) => {
                    dispatch(
                      useMockAction({
                        hasPrimaryCardMockStatus: e.target.value,
                      }),
                    );
                  }}
                />
                <span>{ele}</span>
              </label>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.hasNoPrimaryCardMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  hasNoPrimaryCardMock: !useMock.hasNoPrimaryCardMock,
                  hasPrimaryCardMock: false,
                  replacePlasticCardMock: false,
                  replaceVirtualCardMock: false,
                  hasZeroCardsAvailableMock: false,
                  maxPlasticLimitMock: false,
                  maxVirtualLimitMock: false,
                }),
              );
            }}
          />
          <span>Has NO Primary Card</span>
        </label>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.createVirtualCardMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  createVirtualCardMock: !useMock.createVirtualCardMock,
                  hasZeroCardsAvailableMock: false,
                  replaceVirtualCardMock: false,
                  maxVirtualLimitMock: false,
                }),
              );
            }}
          />
          <span>Create Virtual Card</span>
        </label>

        <div className="flex flex-wrap items-center gap-2">
          {['CREATION FAILED', ...Object.values(cardStatusKeys)].map((ele) => {
            return (
              <label
                className={`flex items-center gap-1 ${
                  !useMock.createVirtualCardMock ? 'opacity-30' : ''
                }`}
              >
                <input
                  disabled={!useMock.createVirtualCardMock}
                  key={randomId()}
                  name="mockVirtualStatus"
                  type="radio"
                  value={ele}
                  checked={useMock.createVirtualCardMockStatus === ele}
                  onChange={(e) => {
                    dispatch(
                      useMockAction({
                        createVirtualCardMockStatus: e.target.value,
                      }),
                    );
                  }}
                />
                <span>{ele}</span>
              </label>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.createPlasticCardMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  createPlasticCardMock: !useMock.createPlasticCardMock,
                  hasZeroCardsAvailableMock: false,
                  replacePlasticCardMock: false,
                  maxPlasticLimitMock: false,
                }),
              );
            }}
          />
          <span>Create Plastic Card</span>
        </label>

        <div className="flex flex-wrap items-center gap-2">
          {['SUCCESS', 'FAIL'].map((ele) => {
            return (
              <label
                className={`flex items-center gap-1 ${
                  !useMock.createPlasticCardMock ? 'opacity-30' : ''
                }`}
              >
                <input
                  key={randomId()}
                  name="mockPlasticStatus"
                  type="radio"
                  value={ele}
                  checked={useMock.createPlasticCardMockStatus === ele}
                  onChange={(e) => {
                    dispatch(
                      useMockAction({
                        createPlasticCardMockStatus: e.target.value,
                      }),
                    );
                  }}
                />
                <span>{ele}</span>
              </label>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.replaceVirtualCardMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  replaceVirtualCardMock: !useMock.replaceVirtualCardMock,
                  hasNoPrimaryCardMock: false,
                  hasZeroCardsAvailableMock: false,
                  createVirtualCardMock: false,
                  maxVirtualLimitMock: false,
                }),
              );
            }}
          />
          <span>Replace Virtual Card</span>
        </label>

        <div className="flex flex-wrap items-center gap-2">
          {Object.values(cardStatusKeys).map((ele) => {
            return (
              <label
                className={`flex items-center gap-1 ${
                  !useMock.replaceVirtualCardMock ? 'opacity-30' : ''
                }`}
              >
                <input
                  key={randomId()}
                  name="mockReplaceVirtualStatus"
                  type="radio"
                  value={ele}
                  checked={useMock.replaceVirtualCardMockStatus === ele}
                  onChange={(e) => {
                    dispatch(
                      useMockAction({
                        replaceVirtualCardMockStatus: e.target.value,
                      }),
                    );
                  }}
                />
                <span>{ele}</span>
              </label>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.replacePlasticCardMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  replacePlasticCardMock: !useMock.replacePlasticCardMock,
                  hasNoPrimaryCardMock: false,
                  hasZeroCardsAvailableMock: false,
                  createPlasticCardMock: false,
                  maxPlasticLimitMock: false,
                }),
              );
            }}
          />
          <span>Replace Plastic Card</span>
        </label>

        <div className="flex flex-wrap items-center gap-2">
          {['SUCCESS', 'FAIL'].map((ele) => {
            return (
              <label
                className={`flex items-center gap-1 ${
                  !useMock.replacePlasticCardMock ? 'opacity-30' : ''
                }`}
              >
                <input
                  key={randomId()}
                  name="mockReplacePlasticStatus"
                  type="radio"
                  value={ele}
                  checked={useMock.replacePlasticCardMockStatus === ele}
                  onChange={(e) => {
                    dispatch(
                      useMockAction({
                        replacePlasticCardMockStatus: e.target.value,
                      }),
                    );
                  }}
                />
                <span>{ele}</span>
              </label>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.virtualCreateAndReplaceMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  virtualCreateAndReplaceMock: !useMock.virtualCreateAndReplaceMock,
                  createVirtualCardMock: false,
                  replaceVirtualCardMock: false,
                  maxVirtualLimitMock: false,
                }),
              );
            }}
          />
          <span>Virtual Can Create AND Replace</span>
        </label>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.plasticCreateAndReplaceMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  plasticCreateAndReplaceMock: !useMock.plasticCreateAndReplaceMock,
                  createPlasticCardMock: false,
                  replacePlasticCardMock: false,
                  maxPlasticLimitMock: false,
                }),
              );
            }}
          />
          <span>Plastic Can Create AND Replace</span>
        </label>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.maxVirtualLimitMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  maxVirtualLimitMock: !useMock.maxVirtualLimitMock,
                  virtualCreateAndReplaceMock: false,
                  createVirtualCardMock: false,
                  replaceVirtualCardMock: false,
                  hasNoPrimaryCardMock: false,
                }),
              );
            }}
          />
          <span>Max Virtual Limit</span>
        </label>
      </div>

      <div className="flex flex-col items-start gap-4">
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.maxPlasticLimitMock}
            onChange={() => {
              dispatch(
                useMockAction({
                  maxPlasticLimitMock: !useMock.maxPlasticLimitMock,
                  plasticCreateAndReplaceMock: false,
                  createPlasticCardMock: false,
                  replacePlasticCardMock: false,
                  hasNoPrimaryCardMock: false,
                }),
              );
            }}
          />
          <span>Max Plastic Limit</span>
        </label>
      </div>
    </div>
  );
}

export default CreateNewCardMockContent;
