import { useFormik } from 'formik';
import { getURLParams, objToQueryStr } from 'javascript-functions';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../../../../AppRouter/history';
import AppSpinner from '../../../../../components/AppSpinner';
import BackBtn from '../../../../../components/BackBtn';
import AppUpload from '../../../../../components/FormInputs/AppUpload';
import TranslatedData from '../../../../../components/TranslatedData';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../../../../components/html/html';
import { toIntAndDecimalNumber } from '../../../../../helpers/Utils';
import { http } from '../../../../../http';
import { showToasterAction } from '../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  getCardProgramDetailsBalanceAction,
  getSelectedCardProgramDetailsAction,
  setIsFinishAction,
} from '../../../../../redux/WalletReducer/WalletActions';

function UploadTransferContent({ handleBackStep, handleCancel }) {
  const { t } = useTranslation('walletV2');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { loaders } = useSelector((state) => state);
  const { actions, singleSelectedCardProgramDetails, selectedCardProgramDetailsBalance } =
    useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const [attachment, setAttachment] = useState(null);

  const renderEditBtn = useCallback(({ stepIndex }) => {
    return (
      <AppButton
        size="md"
        button="link"
        className="text-primary"
        onClick={() => {
          handleBackStep(stepIndex);
        }}
      >
        {t('Edit', { ns: 'commonV2' })}
      </AppButton>
    );
  }, []);

  const renderSectionTitle = useCallback((title) => {
    return <AppBody pClass="Body1Bold">{title}</AppBody>;
  }, []);

  const renderSingleTransferContent = useCallback(({ title, value }) => {
    return (
      <div>
        <AppBody pClass="Body2Regular" className="text-gray-6">
          {title}
        </AppBody>
        <AppBody pClass="Body1Regular" className="break-words">
          {value}
        </AppBody>
      </div>
    );
  }, []);

  const handleSubmitDeposit = useCallback(() => {
    const formBody = new FormData();
    formBody.append('type', 'DEPOSIT');
    formBody.append('amount', actions.amount);
    formBody.append('currency', singleSelectedCardProgramDetails.currency);

    if (attachment) {
      formBody.append('attachment', attachment);
    }

    // from
    formBody.append('funding_method_id', actions.transferFromData.id);
    // to
    formBody.append('target_iban', actions.transferToData.id);
    formBody.append('fundable_id', singleSelectedCardProgramDetails.id);

    http
      .post('cardmanager/funds', formBody, { loader: 'handleSubmitDeposit' })
      .then((res) => {
        dispatch(setIsFinishAction(true));
        history.replace({
          search: objToQueryStr({
            requestNumber: res.data.id,
            message: 'You have Successfully uploaded the transfer copy of receipt',
            note: 'We will notify you again once the amount is ready for use in your account.',
            handAnimationIn: true,
            selectedCardProgramId: singleSelectedCardProgramDetails.id,
          }),
        });
      })
      .catch((err) => {
        dispatch(showToasterAction({ type: 'error', message: err.data.error }));
      });
  }, [actions, attachment, singleSelectedCardProgramDetails]);

  const formik = useFormik({
    initialValues: { amount: actions.amount || '' },
    onSubmit: () => {
      handleSubmitDeposit();
    },
    validationSchema: yup.object({
      attachment: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    }),
    enableReinitialize: true,
  });

  useEffect(() => {
    dispatch(
      getSelectedCardProgramDetailsAction({
        cardProgramId: getURLParams('selectedCardProgramId'),
      }),
    );
    dispatch(
      getCardProgramDetailsBalanceAction({ cardProgramId: getURLParams('selectedCardProgramId') }),
    );
  }, []);

  return (
    <div>
      <BackBtn
        className="mb-4"
        onClick={() => {
          handleBackStep(2);
        }}
      />
      <AppHeader h="h4" className="mb-4">
        {t('Upload Transfer Copy')}
      </AppHeader>

      <div
        className={`mb-8 rounded-2xl border-black p-3 shadow-md ${!isCollapsed ? 'border' : ''}`}
      >
        <div
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
          className="flex cursor-pointer items-center justify-between"
        >
          <AppBody pClass="Body1Bold">{t('Deposit Details')}</AppBody>
          <AppIcon
            iClass="XXLargeFont"
            className={`fa-regular fa-angle-down duration-100 ${!isCollapsed ? 'rotate-180' : ''}`}
          />
        </div>
        {!isCollapsed && !loaders.getSelectedCardProgramDetailsAction && (
          <div className="mt-8 grid gap-4">
            {/* Deposit Details */}
            <div>
              <div className="mb-4 flex items-center justify-between border-b pb-2">
                <AppBody pClass="Body1Bold">{t('Deposit Details')}</AppBody>
                {renderEditBtn({ stepIndex: 0 })}
              </div>
              <div className="grid grid-cols-2 gap-10 font-light lg:gap-4">
                {renderSingleTransferContent({
                  title: t('Deposit Credited in (Card Program)'),
                  value: (
                    <>
                      <AppBody pClass="Body1Regular">
                        {singleSelectedCardProgramDetails.currency} •{' '}
                        {singleSelectedCardProgramDetails.scheme} -{' '}
                        <TranslatedData
                          en={singleSelectedCardProgramDetails.issuer_country.name}
                          ar={singleSelectedCardProgramDetails.issuer_country.name_ar}
                        />
                      </AppBody>
                      <AppBody pClass="Body1Regular" className="flex items-center gap-1">
                        {!loaders.getCardProgramDetailsBalanceAction && (
                          <span className="flex gap-3 rtl:justify-end" style={{ direction: 'ltr' }}>
                            <span>
                              {
                                toIntAndDecimalNumber(
                                  selectedCardProgramDetailsBalance?.amount || 0,
                                ).fullNumber
                              }
                            </span>
                            <span>{selectedCardProgramDetailsBalance?.currency}</span>
                          </span>
                        )}

                        {loaders.getCardProgramDetailsBalanceAction && (
                          <span className="flex items-center gap-2">
                            <span>
                              <AppSpinner size={24} />
                            </span>
                            <span>{t('Loading Balance')}</span>
                          </span>
                        )}
                      </AppBody>
                    </>
                  ),
                })}

                <div className="grid gap-4">
                  {renderSingleTransferContent({
                    title: t('Amount', { ns: 'commonV2' }),
                    value: actions.amount,
                  })}

                  {renderSingleTransferContent({
                    title: t('Currency', { ns: 'commonV2' }),
                    value: getURLParams('selectedCardProgramCurrency'),
                  })}
                </div>
              </div>
            </div>

            {/* Deposit Transferred From */}
            <div>
              <div className="mb-4 flex items-center justify-between border-b pb-2">
                {renderSectionTitle(t('Deposit Transferred From'))}
                {renderEditBtn({ stepIndex: 1 })}
              </div>
              <div className="grid grid-cols-2 gap-10 font-light lg:gap-4">
                {renderSingleTransferContent({
                  title: t('Bank Name', { ns: 'commonV2' }),
                  value: actions.transferFromData.bank_name,
                })}

                {renderSingleTransferContent({
                  title: t('IBAN', { ns: 'commonV2' }),
                  value: actions.transferFromData.iban,
                })}

                {renderSingleTransferContent({
                  title: t('Account Name', { ns: 'commonV2' }),
                  value: actions.transferFromData.account_holder_name,
                })}

                {renderSingleTransferContent({
                  title: t('Account number', { ns: 'commonV2' }),
                  value: actions.transferFromData.account_number,
                })}

                {renderSingleTransferContent({
                  title: t('Country', { ns: 'commonV2' }),
                  value: (
                    <TranslatedData
                      en={actions.transferFromData.country.name}
                      ar={actions.transferFromData.country.name_ar}
                    />
                  ),
                })}

                {renderSingleTransferContent({
                  title: t('Currency', { ns: 'commonV2' }),
                  value: actions.transferFromData.currency,
                })}

                {renderSingleTransferContent({
                  title: t('SWIFT Code', { ns: 'commonV2' }),
                  value: actions.transferFromData.swift,
                })}
              </div>
            </div>

            {/* Deposit Transferred To */}
            <div>
              <div className="mb-4 border-b pb-2">
                {renderSectionTitle(t('Deposit Transferred To'))}
              </div>

              <div className="grid grid-cols-2 gap-10 font-light lg:gap-4">
                {renderSingleTransferContent({
                  title: t('Bank Name', { ns: 'commonV2' }),
                  value: actions.transferToData.bank_name,
                })}

                {renderSingleTransferContent({
                  title: t('IBAN', { ns: 'commonV2' }),
                  value: actions.transferToData.iban,
                })}

                {renderSingleTransferContent({
                  title: t('Account Name', { ns: 'commonV2' }),
                  value: actions.transferToData.account_name,
                })}

                {renderSingleTransferContent({
                  title: t('Account number', { ns: 'commonV2' }),
                  value: actions.transferToData.account_number,
                })}

                {renderSingleTransferContent({
                  title: t('Country', { ns: 'commonV2' }),
                  value: (
                    <TranslatedData
                      en={actions.transferToData.code.name}
                      ar={actions.transferToData.code.name_ar}
                    />
                  ),
                })}

                {renderSingleTransferContent({
                  title: t('Currency', { ns: 'commonV2' }),
                  value: actions.transferToData.currency,
                })}

                {renderSingleTransferContent({
                  title: t('SWIFT Code', { ns: 'commonV2' }),
                  value: actions.transferToData.swift_code,
                })}
              </div>
            </div>
          </div>
        )}
        {!isCollapsed && loaders.getSelectedCardProgramDetailsAction && (
          <div className="h-96">
            <AppSpinner />
          </div>
        )}
      </div>

      <AppHeader h="h5" className="mb-2">
        {t('Upload Deposit Transferred Copy')}

        {formik.errors.attachment && (
          <AppBody type="span" pClass="Caption1Regular" className="ms-2 text-danger">
            ({formik.errors.attachment})
          </AppBody>
        )}
      </AppHeader>

      <AppBody pClass="Body2Medium" className="text-gray-6">
        {t('Please upload the transfer copy here to process your deposit money request')}
      </AppBody>

      <div className="mb-14 mt-8">
        <AppUpload
          isLoading={false}
          width="100%"
          height={165}
          showAccepted={false}
          onChange={({ FILE }) => {
            formik.setFieldValue('attachment', FILE);
            setAttachment(FILE);
          }}
        />
      </div>

      <div className="flex justify-end gap-8">
        <AppButton button="link" onClick={handleCancel} size="md">
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          size="md"
          className="min-w-[150px]"
          onClick={formik.handleSubmit}
          button="black"
          isLoading={loaders.handleSubmitDeposit}
        >
          {t('Submit', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default UploadTransferContent;
