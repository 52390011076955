import { getURLParams } from 'javascript-functions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import NqoodCardImg from '../../../../components/NqoodCardImg';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import ContactUs from './ContactUs';

function RequestPlasticCardFailed() {
  const { t } = useTranslation('cardsV2');
  const { createCardUserData, selectedCardProgram } = useSelector((state) => state.createNewCard);

  const isCreationForOtherUser = useMemo(() => {
    return Boolean(+getURLParams('createCardUserId'));
  }, [getURLParams('createCardUserId')]);

  return (
    <div className="mx-auto mt-[14vh] w-full max-w-[410px] px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <AppHeader h="h3" className="mb-1">
        {t('Card Request Has been rejected')}
      </AppHeader>

      <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
        {t('Please contact customer support team')}
      </AppBody>

      <div className="relative mx-auto mb-6 w-full rounded-md shadow-md blur-[2px]">
        <NqoodCardImg
          bankLogo={selectedCardProgram?.funding_source_type?.bank_logo}
          scheme={selectedCardProgram.scheme}
          skeletonWidth={256}
          skeletonHeight={151}
          type="plastic"
          alt="Plastic Card"
        />
        <AppHeader h="h6" className="absolute bottom-6 left-6 text-white">
          {`${createCardUserData.first_name} ${createCardUserData.last_name}`}
        </AppHeader>
      </div>

      <ContactUs
        emailSubject="Card Request Has been rejected"
        emailError={getURLParams('creationError')}
      />

      <div className="mt-4 pb-5">
        <AppButton
          onClick={() => {
            if (isCreationForOtherUser) {
              history.push(`/dashboard/users/${createCardUserData.id}`);
            } else {
              history.push('/dashboard/my_cards');
            }
          }}
          isLoading={false}
          button="black"
          className="w-full"
          size="md"
        >
          {!isCreationForOtherUser && t('Go to Your Card')}
          {isCreationForOtherUser && t('Go to User Card')}
        </AppButton>
      </div>
    </div>
  );
}

export default RequestPlasticCardFailed;
