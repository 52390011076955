import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppCloseButton from '../../../../components/AppCloseButton';
import { AppHeader } from '../../../../components/html/html';
import { hideModalAction } from '../../../../redux/AppModalReducer/AppModalActions';
import { hideUsersListFilterPopupAction } from '../../../../redux/UsersReducer/UsersActions';

function UsersListFilterPopupHeader() {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { t } = useTranslation('commonV2');
  const dispatch = useDispatch();

  return (
    <div className="flex items-center">
      <AppHeader h="h5">{t('Filter')}</AppHeader>

      <AppCloseButton
        className="ms-auto"
        onClick={() => {
          if (isMobile) {
            dispatch(hideModalAction());
          }
          dispatch(hideUsersListFilterPopupAction());
        }}
      />
    </div>
  );
}

export default UsersListFilterPopupHeader;
