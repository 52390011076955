import { appendURLParams } from 'javascript-functions';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import { AppHeader } from '../../../../components/html/html';
import { devLog, generateMockCard, getServerErrMessage } from '../../../../helpers/Utils';
import { cardStatusKeys, cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import { setActiveCreateNewCardStepAction } from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import NqoodCardAnimation from './NqoodCardAnimation';

function PlasticCardCreation() {
  const { useMock } = useSelector((state) => state);
  const { selectedCardDesign, selectedCardProgram, otpData, createCardUserData, cardToReplace } =
    useSelector((state) => state.createNewCard);
  const { t } = useTranslation('cardsV2');

  const dispatch = useDispatch();

  const findPlasticCard = useCallback(
    (tryCount = 0) => {
      devLog(`Finding Plastic Card...`);
      let mockParams = null;

      if (useMock.createPlasticCardMock) {
        devLog(`Mocking [Create] Plastic Card`);
        if (useMock.createPlasticCardMockStatus === 'SUCCESS') {
          mockParams = generateMockCard({
            status: useMock.createPlasticCardMockStatus,
            isPhysical: true,
          });
        } else {
          mockParams = [];
        }
      }

      if (useMock.replacePlasticCardMock) {
        devLog(`Mocking [Replace] Plastic Card`);
        if (useMock.replacePlasticCardMockStatus === 'SUCCESS') {
          mockParams = generateMockCard({ isPhysical: true });
        } else {
          mockParams = [];
        }
      }

      http
        .get(`cardmanager/cards/plastic`, {
          params: {
            user_id: createCardUserData.id,
            card_product_id: cardToReplace
              ? cardToReplace?.cardproduct?.id
              : selectedCardProgram.id,
          },
          useMock: useMock.createPlasticCardMock || useMock.replacePlasticCardMock,
          mockParams,
        })
        .then((res) => {
          if (Object.keys(res?.data).length) {
            devLog(`Plastic Card Found!`);
            dispatch(
              setActiveCreateNewCardStepAction(createNewCardSteps.requestPlasticCardSuccess),
            );
          } else if (tryCount < 1) {
            devLog(`No Card Found, Try Count: [${tryCount + 1}]`);
            setTimeout(
              () => {
                findPlasticCard(tryCount + 1);
              },
              useMock.createPlasticCardMock || useMock.replacePlasticCardMock ? 2000 : 15000,
            );
          } else {
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.requestPlasticCardFailed));
            history.replace({
              search: appendURLParams('creationError', 'Card NOT Found After More Than 30 Seconds'),
            });
          }
        })
        .catch((err) => {
          devLog(`Finding Plastic Card Failed due to: ${getServerErrMessage(err)}`);
          history.replace({ search: appendURLParams('creationError', getServerErrMessage(err)) });
          dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.requestPlasticCardFailed));
        });
    },
    [useMock, createCardUserData, selectedCardProgram, cardToReplace],
  );

  const createPhysicalCard = useCallback(() => {
    devLog(`Creating [PHYSICAL] Card...`);

    const body = {
      token: otpData.OTPToken,
      code: otpData.code,
      user_id: createCardUserData?.id,
      name_on_card: `${createCardUserData?.first_name} ${createCardUserData?.last_name}`,
      card_type: cardTypesKeys.PHYSICAL,
      card_product_id: selectedCardProgram.id,
    };

    http
      .post(`cardmanager/cards`, body, {
        headers: { 'Content-Type': 'application/json' },
        loader: 'createPhysicalCard',
        lang: 'en',
        useMock: useMock.createPlasticCardMock,
        mockParams:
          useMock.createPlasticCardMockStatus === 'SUCCESS'
            ? generateMockCard({ status: useMock.createPlasticCardMockStatus })
            : [],
      })
      .then(() => {
        devLog('Plastic Card Created!');
        setTimeout(
          () => {
            findPlasticCard();
          },
          useMock.createPlasticCardMock ? 2000 : 15000,
        );
      })
      .catch((err) => {
        devLog(`Plastic Card Creation Failed due to: ${getServerErrMessage(err)}`);
        history.replace({ search: appendURLParams('creationError', getServerErrMessage(err)) });
        dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.requestPlasticCardFailed));
      });
  }, [otpData, createCardUserData, selectedCardProgram, selectedCardDesign, useMock]);

  const replacePhysicalCard = useCallback(() => {
    const body = {
      token: otpData.OTPToken,
      code: otpData.code,
    };

    http
      .post(`cardmanager/cards/${cardToReplace.id}/renew`, body, {
        headers: { 'Content-Type': 'application/json' },
        loader: 'replacePhysicalCard',
        useMock: useMock.replacePlasticCardMock,
        mockParams: generateMockCard({ status: cardStatusKeys.EXPIRED, isPhysical: true }),
      })
      .then(() => {
        devLog('Plastic Card Replaced!');
        setTimeout(() => {
          findPlasticCard();
        }, 15000);
      })
      .catch((err) => {
        devLog(`Plastic Card [Replacing] Failed due to: ${getServerErrMessage(err)}`);
        history.replace({ search: appendURLParams('creationError', getServerErrMessage(err)) });
        dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.requestPlasticCardFailed));
      });
  }, [useMock, otpData, cardToReplace]);

  useEffect(() => {
    if (cardToReplace) {
      replacePhysicalCard();
    } else {
      createPhysicalCard();
    }
  }, [cardToReplace]);

  return (
    <div className="mx-auto mt-[25vh] max-w-[350px] pb-5 sm:px-0 md:px-5">
      <AppHeader h="h3">{t('Your card is being requested')}</AppHeader>

      <div className="my-5">
        <NqoodCardAnimation />
      </div>
    </div>
  );
}

export default PlasticCardCreation;
