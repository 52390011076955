/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Attachment } from '../../../assets/svg/attachment.svg';
import { ReactComponent as NoAttachment } from '../../../assets/svg/no_attachment.svg';
import DataTable, { DataTableItem } from '../../../components/DataTable/DataTable';
import EmptyTable from '../../../components/DataTable/EmptyTable';
import LoadingError from '../../../components/LoadingError';
import { LargerTitle2 } from '../../../components/Titles';
import { AppBody } from '../../../components/html/html';
import { apiGetRequest, apiGetRequestDownload } from '../../../helpers/APIHelper';
import { setAction } from '../../../helpers/ActionsHelper';
import { formatDate } from '../../../helpers/Utils';
import useNotifications from '../../../helpers/useNotifications';
import usePermissions from '../../../hooks/usePermissions';
import {
  RESET_TRANSACTION_DATE_FILTER,
  RESET_TRANSACTION_DATE_FILTER_SPLIT,
  RESET_TRANSACTION_FILTER,
  RESET_TRANSACTION_SPLIT_FILTER,
  SET_TRANSACTION_FILTER,
  SET_TRANSACTION_SPLIT_FILTER,
  UPDATE_TAGS,
  UPDATE_TRANSACTIONS,
} from '../../../redux/TransactionsReducer';
import Head from '../../Head';
import { TransactionSplitPreview, TransactionsSplitTableItem } from './Split/TransactionsSplit';
import TagsDropdown from './TagsDropdown';
import TransactionDropdown from './TransactionDropdown';

// eslint-disable-next-line func-names
export const TransactionsDataTable = function ({
  transactions,
  tags,
  setPage,
  totalResults,
  limit,
  currentPage,
  updating,
  pagination,
  noTagsEdit,
  errored,
  retryCallback,
  isSplit,
  setSearchKeywords,
  filterObject,
  setSearchFilters,
  setFilter,
  isWidget,
  hideNameColumn,
  userID,
  cardID,
  setExport,
  exportAction,
  exportLoading,
  setDateFilter,
}) {
  const { filters, filtersSplit } = useSelector((state) => state.transactions);
  const { t } = useTranslation(['transactions']);
  const userCan = usePermissions();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies] = useCookies(['token']);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  const fetchTransaction = (transactionId) => {
    apiGetRequest(`transactions/${transactionId}`, cookies.token).then((response) => {
      setSelectedTransaction(response);
    });
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (isWidget) {
      dispatch(setAction(RESET_TRANSACTION_FILTER));
    }
  }, [isWidget]);

  return (
    <div className={isSplit ? 'grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-20' : ''}>
      <DataTable
        setDateFilter={setDateFilter}
        setTypeDateFilter={
          isSplit ? 'SET_TRANSACTION_DATE_TYPE_SPLIT_FILTER' : 'SET_TRANSACTION_DATE_TYPE_FILTER'
        }
        setDaysFilter={
          isSplit ? 'SET_TRANSACTION_DAYS_SPLIT_FILTER' : 'SET_TRANSACTION_DAYS_FILTER'
        }
        setFormDateFilter={
          isSplit ? 'SET_TRANSACTION_FROM_SPLIT_FILTER' : 'SET_TRANSACTION_FROM_FILTER'
        }
        setToDateFilter={isSplit ? 'SET_TRANSACTION_TO_SPLIT_FILTER' : 'SET_TRANSACTION_TO_FILTER'}
        setExport={setExport}
        exportAction={exportAction}
        exportLoading={exportLoading}
        isMobile={isMobile}
        setSearch={setSearchKeywords}
        pagination={pagination}
        currentCount={transactions.length}
        setPage={setPage}
        totalResults={totalResults}
        limit={limit}
        currentPage={currentPage}
        updating={updating}
        split={!!isSplit}
        filterObject={filterObject}
        setFilter={setFilter}
        filterState={isSplit ? filtersSplit : filters}
        showFilterRowFlag={isSplit ? filtersSplit.length !== 0 : filters.length !== 0} // to keep filter when show details then back
        resetFilter={() => {
          setSearchFilters(null);
          dispatch(setAction(isSplit ? RESET_TRANSACTION_SPLIT_FILTER : RESET_TRANSACTION_FILTER));
        }}
        setFilterState={(object, type) => {
          dispatch(
            setAction(isSplit ? SET_TRANSACTION_SPLIT_FILTER : SET_TRANSACTION_FILTER, {
              type,
              object,
            }),
          );
        }}
        applyFilters={(filtersToApply) => {
          const allFilters =
            filtersToApply
              .map((obj) => (obj.type === 'compound' ? obj.options.subfilters : [obj]))
              .reduce((current, accum) => [...current, ...accum], []) ?? [];
          setSearchFilters(
            allFilters
              .filter((obj) => obj.value !== null)
              .map(({ key, value }) => ({ [key]: value }))
              .reduce(
                (current, accum) => ({
                  ...current,
                  ...accum,
                }),
                {},
              ),
          );
        }}
      >
        {!isSplit && !isMobile && (
          <DataTableItem isHeader>
            {/* eslint-disable-next-line no-nested-ternary */}
            <AppBody
              type="div"
              pClass="Caption1Bold"
              className={`grid ${
                // eslint-disable-next-line no-nested-ternary
                isWidget ? 'grid-cols-8' : hideNameColumn ? 'grid-cols-9' : 'grid-cols-10'
              } w-full gap-1 border-b border-gray-3 px-4 py-5 uppercase tracking-wider ltr:text-left rtl:text-right`}
            >
              <div className="col-span-1">{t('timing')}</div>
              {!hideNameColumn && (
                <div className="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap ltr:mr-5 rtl:ml-5">
                  {t('user')}
                </div>
              )}
              <div className="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap ltr:mr-5 rtl:ml-5">
                {t('merchant')}
              </div>
              <div className="col-span-1">{t('type')}</div>
              {userCan('transaction_list_view') && (
                <div className="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap ltr:mr-5 rtl:ml-5">
                  {t('department')}
                </div>
              )}
              {!userCan('transaction_list_view') && <div className="col-span-1">{t('card')}</div>}
              <div className="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap ltr:mr-5 rtl:ml-5">
                {t('amount')}
              </div>
              <div className="col-span-1">{isWidget ? t('currency') : t('status')}</div>
              {!isWidget && <div className="col-span-2">{t('tag')}</div>}
              {!isWidget && (
                <div className="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap ltr:mr-5 rtl:ml-5">
                  {t('receipt')}
                </div>
              )}
              {isWidget && t('status')}
            </AppBody>
          </DataTableItem>
        )}
        {transactions.length === 0 && !errored && <EmptyTable />}
        <LoadingError show={errored} retryCallback={retryCallback} />
        {transactions.map((transaction, key) => {
          if (!isSplit && !isMobile) {
            return (
              <TransactionExportDataItem
                isSplit={isSplit}
                hideNameColumn={hideNameColumn}
                isWidget={isWidget}
                key={transaction.id + 1}
                transaction={transaction}
                tags={tags}
                noTagsEdit={noTagsEdit}
                userID={userID}
                cardID={cardID}
              />
            );
          }
          return (
            <TransactionsSplitTableItem
              isSplit={isSplit}
              key={transaction.id + 1}
              last={key === transactions.length - 1}
              onClick={() => {
                if (isMobile) {
                  if (cardID) {
                    navigate(`/dashboard/cards/transactions/details/${transaction.id}`);
                  } else if (userID) {
                    navigate(`/dashboard/users/transactions/details/${transaction.id}`);
                  } else {
                    navigate(
                      `/dashboard/${isSplit ? 'expenses' : 'transactions'}/details/${
                        transaction.id
                      }`,
                    );
                  }
                } else {
                  fetchTransaction(transaction.id);
                }
              }}
              tags={tags}
              transaction={transaction}
              selected={selectedTransaction ? selectedTransaction.id === transaction.id : false}
            />
          );
        })}
      </DataTable>
      {isSplit && (
        <TransactionSplitPreview
          transactionId={selectedTransaction ? selectedTransaction.id : null}
        />
      )}
    </div>
  );
};

function TransactionExportDataItem({
  transaction,
  tags,
  isWidget,
  hideNameColumn,
  isSplit,
  cardID,
  userID,
}) {
  const { user } = useSelector((state) => state.auth);
  const userCan = usePermissions();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['transactions']);

  const transOnClick = (transactionId) => {
    if (cardID) {
      navigate(`/dashboard/cards/transactions/details/${transactionId}`);
    } else if (userID) {
      navigate(`/dashboard/users/transactions/details/${transactionId}`);
    } else {
      navigate(`/dashboard/${isSplit ? 'expenses' : 'transactions'}/details/${transactionId}`);
    }
  };
  return (
    <DataTableItem key={transaction.id}>
      {/* eslint-disable-next-line no-nested-ternary */}
      <AppBody
        type="div"
        pClass="Body2Regular"
        className={`grid ${
          // eslint-disable-next-line no-nested-ternary
          isWidget ? 'grid-cols-8' : hideNameColumn ? 'grid-cols-9' : 'grid-cols-10'
        } w-full gap-1 px-4 py-3 ltr:text-left rtl:text-right`}
      >
        <div className="col-span-1 tabular-nums ltr:text-left rtl:text-right">
          <button
            className="w-full ltr:text-left rtl:text-right"
            type="button"
            onClick={() => {
              transOnClick(transaction.id);
            }}
          >
            {formatDate(transaction.transaction_timestamp, 'HH:mm | DD MMM')}
          </button>
        </div>
        {!hideNameColumn && (
          <div className="col-span-1">
            <button
              className="w-full ltr:text-left rtl:text-right"
              type="button"
              onClick={() => {
                transOnClick(transaction.id);
              }}
            >
              {`${
                i18n.language === 'ar' && transaction.user_id
                  ? transaction.user_id.first_name_ar
                  : ''
              } ${
                i18n.language === 'ar' && transaction.user_id
                  ? transaction.user_id.last_name_ar
                  : ''
              }`}
              {`${
                i18n.language !== 'ar' && transaction.user_id ? transaction.user_id.first_name : ''
              } ${
                i18n.language !== 'ar' && transaction.user_id ? transaction.user_id.last_name : ''
              }`}
            </button>
          </div>
        )}
        <div
          className="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap ltr:mr-5 rtl:ml-5"
          title={transaction.merchant_name}
        >
          <button
            className="w-full ltr:text-left rtl:text-right"
            type="button"
            onClick={() => {
              transOnClick(transaction.id);
            }}
          >
            {transaction.merchant_name}
          </button>
        </div>

        <div className="col-span-1">
          <button
            className="w-full ltr:text-left rtl:text-right"
            type="button"
            onClick={() => {
              transOnClick(transaction.id);
            }}
          >
            {i18n.language === 'ar'
              ? transaction.transaction_type_ar
              : transaction.transaction_type}
          </button>
        </div>

        {userCan('transaction_list_view') && (
          <div className="col-span-1">
            <button
              className="w-full ltr:text-left rtl:text-right"
              type="button"
              onClick={() => {
                transOnClick(transaction.id);
              }}
            >
              {transaction.splits.length > 0 && (
                <span className="italic text-gray-6">{t('split')}</span>
              )}
              {transaction.splits.length === 0 &&
                transaction.department_id &&
                i18n.language !== 'ar' &&
                transaction.department_id.name}
              {transaction.splits.length === 0 &&
                transaction.department_id &&
                i18n.language === 'ar' &&
                transaction.department_id.name_ar}
              {transaction.splits.length === 0 && !transaction.department_id && '---'}
            </button>
          </div>
        )}
        {!userCan('transaction_list_view') && (
          <div className="col-span-1">
            <button
              className="w-full ltr:text-left rtl:text-right"
              type="button"
              onClick={() => {
                transOnClick(transaction.id);
              }}
            >
              {transaction.splits.length > 0 && (
                <span className="italic text-gray-6">{t('split')}</span>
              )}
              {transaction.splits.length === 0 && transaction.card_last4}
            </button>
          </div>
        )}

        {transaction.transaction_amount >= 0 && (
          <div className="col-span-1 tabular-nums" style={{ direction: 'ltr' }}>
            <button
              style={{ direction: 'ltr' }}
              className="w-full ltr:text-left rtl:text-right"
              type="button"
              onClick={() => {
                transOnClick(transaction.id);
              }}
            >
              {parseFloat(transaction.transaction_amount).toLocaleString()}
              {!isWidget ? ` ${transaction.transaction_currency}` : ''}
            </button>
          </div>
        )}
        {transaction.transaction_amount < 0 && (
          <div className="col-span-1 tabular-nums" style={{ direction: 'ltr' }}>
            <button
              style={{ direction: 'ltr' }}
              className="w-full ltr:text-left rtl:text-right"
              type="button"
              onClick={() => {
                transOnClick(transaction.id);
              }}
            >
              {`(${parseFloat(transaction.transaction_amount * -1).toLocaleString()})`}
              {!isWidget ? ` ${transaction.transaction_currency}` : ''}
            </button>
          </div>
        )}
        <div className="col-span-1">
          <button
            className="w-full ltr:text-left rtl:text-right"
            type="button"
            onClick={() => {
              transOnClick(transaction.id);
            }}
          >
            {isWidget ? transaction.transaction_currency : transaction.status}
          </button>
        </div>

        {!isWidget && (
          <div className="col-span-2">
            {(userCan('add_account_transaction_tag') ||
              userCan('edit_account_transaction_tag')) && (
              <TagsDropdown
                className="w-full"
                transactionID={transaction.id}
                tags={tags}
                selectedTags={transaction.tags}
                noTagsEdit={false}
              />
            )}
            {!userCan('add_account_transaction_tag') &&
              userCan('add_own_transaction_tag') &&
              user.id === transaction.user_id?.id &&
              !transaction.tags && (
                <TagsDropdown
                  className="w-full"
                  transactionID={transaction.id}
                  tags={tags}
                  selectedTags={transaction.tags}
                  noTagsEdit={false}
                />
              )}
            {!userCan('edit_account_transaction_tag') &&
              userCan('edit_own_transaction_tag') &&
              user.id === transaction.user_id?.id &&
              transaction.tags && (
                <TagsDropdown
                  className="w-full"
                  transactionID={transaction.id}
                  tags={tags}
                  selectedTags={transaction.tags}
                  noTagsEdit={false}
                />
              )}
            {!userCan('add_account_transaction_tag') &&
              !userCan('edit_account_transaction_tag') &&
              !userCan('add_own_transaction_tag') &&
              !userCan('edit_own_transaction_tag') && (
                <TagsDropdown
                  className="w-full"
                  transactionID={transaction.id}
                  tags={tags}
                  selectedTags={transaction.tags}
                  noTagsEdit
                />
              )}
          </div>
        )}
        {!isWidget && (
          <div
            className={`col-span-1 ${transaction.has_receipt ? 'text-cyan-500' : 'text-red-700'}`}
          >
            {transaction.has_receipt ? (
              <Attachment className="inline-block" />
            ) : (
              <NoAttachment className="inline-block" />
            )}
            <TransactionDropdown
              text="&#8942;"
              transaction={transaction}
              className="text-black ltr:pl-6 rtl:pr-6"
            />
          </div>
        )}
        {isWidget && <div className="col-span-1">{transaction.status}</div>}
      </AppBody>
      {!isWidget &&
        Array.isArray(transaction.splits) &&
        transaction.splits.map((childTrans) => (
          <button
            className={`grid ${
              hideNameColumn ? 'grid-cols-9' : 'grid-cols-10'
            } button-tree relative w-full gap-1 px-4 py-3 text-sm ltr:text-left ltr:font-light rtl:text-right rtl:font-normal`}
            type="button"
            onClick={() => {
              transOnClick(childTrans.id);
            }}
          >
            <div
              className={`tree-menu ${hideNameColumn ? 'col-span-3' : 'col-span-4'}`}
              style={{ position: 'relative' }}
            >
              &nbsp;
            </div>
            {userCan('transaction_list_view') && (
              <div className="col-span-1">
                {childTrans.department_id &&
                  i18n.language !== 'ar' &&
                  childTrans.department_id.name}
                {childTrans.department_id &&
                  i18n.language === 'ar' &&
                  childTrans.department_id.name_ar}
                {!childTrans.department_id && '---'}
              </div>
            )}
            {!userCan('transaction_list_view') && (
              <div className="col-span-1">{childTrans.card_last4}</div>
            )}

            {childTrans.transaction_amount >= 0 && (
              <div className="col-span-1 tabular-nums" style={{ direction: 'ltr' }}>
                {parseFloat(childTrans.transaction_amount).toLocaleString()}
              </div>
            )}
            {childTrans.transaction_amount < 0 && (
              <div className="col-span-1 tabular-nums" style={{ direction: 'ltr' }}>
                {`(${parseFloat(childTrans.transaction_amount * -1).toLocaleString()})`}
              </div>
            )}
            <div className="col-span-1">{childTrans.status}</div>
            <div className="col-span-2">
              {(userCan('add_account_transaction_tag') ||
                userCan('edit_account_transaction_tag')) && (
                <TagsDropdown
                  className="w-full"
                  transactionID={childTrans.id}
                  tags={tags}
                  selectedTags={childTrans.tags}
                  noTagsEdit={false}
                />
              )}
              {!userCan('add_account_transaction_tag') &&
                userCan('add_own_transaction_tag') &&
                user.id === childTrans.user_id?.id &&
                !childTrans.tags && (
                  <TagsDropdown
                    className="w-full"
                    transactionID={childTrans.id}
                    tags={tags}
                    selectedTags={childTrans.tags}
                    noTagsEdit={false}
                  />
                )}
              {!userCan('edit_account_transaction_tag') &&
                userCan('edit_own_transaction_tag') &&
                user.id === childTrans.user_id?.id &&
                childTrans.tags && (
                  <TagsDropdown
                    className="w-full"
                    transactionID={childTrans.id}
                    tags={tags}
                    selectedTags={childTrans.tags}
                    noTagsEdit={false}
                  />
                )}
              {!userCan('add_account_transaction_tag') &&
                !userCan('edit_account_transaction_tag') &&
                !userCan('add_own_transaction_tag') &&
                !userCan('edit_own_transaction_tag') && (
                  <TagsDropdown
                    className="w-full"
                    transactionID={childTrans.id}
                    tags={tags}
                    selectedTags={childTrans.tags}
                    noTagsEdit
                  />
                )}
            </div>
            <div
              className={`col-span-1 ${childTrans.has_receipt ? 'text-cyan-500' : 'text-red-700'}`}
            >
              {childTrans.has_receipt ? <Attachment /> : <NoAttachment />}
            </div>
          </button>
        ))}
    </DataTableItem>
  );
}

// eslint-disable-next-line func-names
const Transactions = function ({ userID, cardID, isSplit, isExpense }) {
  const { user } = useSelector((state) => state.auth);
  const {
    transactions,
    tags,
    filters,
    filtersSplit,
    filterDays,
    filterFrom,
    filterTo,
    filterDaysSplit,
    filterFromSplit,
    filterToSplit,
  } = useSelector((state) => state.transactions);
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLimit] = useState(10);
  const [totalResults, setTotalResults] = useState(0);
  const [errored, setErrored] = useState(false);
  const [, setUpdating] = useState(false);
  const [invalidateData, setInvalidateData] = useState(0);
  const { t, i18n } = useTranslation(['transactions', 'common']);
  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchFilter, setSearchFilters] = useState(null);
  const [, addNotification] = useNotifications();
  const [departments, setDepartments] = useState([]);
  const [filterTags, setfilterTags] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [transactionCurrencies, setTransactionCurrencies] = useState([]);
  const [users, setUsers] = useState([]);
  const params = useParams();
  const location = useLocation();
  const loadTags = () => {
    apiGetRequest('tags', cookies.token).then((data) => {
      dispatch(setAction(UPDATE_TAGS, data));
      const addedData = {
        id: -1,
        name: 'Without Tag',
        name_ar: 'بدون تصنيف',
        color: '#A1A8AB',
        deleted: false,
      };

      if (data?.length) {
        setfilterTags([addedData, ...data]);
      }
    });
  };

  const transactionParams = (savedFilter) => ({
    created_at: 'desc',
    ...(!filterFrom && !filterTo && !isSplit && filterDays && { days: filterDays }),
    ...(!filterFromSplit &&
      !filterToSplit &&
      isSplit &&
      filterDaysSplit && { days: filterDaysSplit }),
    ...(filterFrom && !isSplit && { from_date: filterFrom }),
    ...(filterTo && !isSplit && { to_date: filterTo }),
    ...(filterFromSplit && isSplit && { from_date: filterFromSplit }),
    ...(filterToSplit && isSplit && { to_date: filterToSplit }),
    paginate: paginationLimit,
    page: currentPage,
    ...(params.noReceipt && { has_receipt: params.noReceipt }),
    ...(searchKeywords.length > 0 && { keyword: searchKeywords }),
    ...(searchFilter ?? null),
    ...(savedFilter ?? null),
    ...(userID && { 'card_holders[0]': userID }),
    ...(isExpense && { 'card_holders[0]': user.id }),
    ...(cardID && { card_id: cardID }),
    ...((!searchFilter || (searchFilter && !searchFilter['transaction_type[]'])) &&
      isExpense && { 'transaction_type[]': '4' }),
  });

  const fetchTransactions = (savedFilter = null) => {
    setIsLoading(true);
    setUpdating(true);
    setTotalResults(0);
    apiGetRequest(
      'transactions',
      cookies.token,
      transactionParams(savedFilter),
      ['tags', 'splits.tags'],
      {},
      true,
    )
      .then(([response, paginator]) => {
        dispatch(setAction(UPDATE_TRANSACTIONS, response));
        setTimeout(() => {
          setInvalidateData(invalidateData + 1);
        }, 1000 * 60);
        setTotalResults(paginator.total);
        setErrored(false);
        setUpdating(false);
      })
      .catch(() => {
        setErrored(true);
        setUpdating(false);
      })
      .finally(() => {
        setIsLoading(false);
        setUpdating(false);
      });
  };

  const loadDepartment = () => {
    apiGetRequest('departments', cookies.token)
      .then((response) => {
        setDepartments(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };

  const loadTransactionTypes = () => {
    apiGetRequest('transactions/transaction-types', cookies.token)
      .then((response) => {
        setTransactionTypes(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };

  const loadTransactionCurrencies = () => {
    apiGetRequest('transactions/transaction-currencies', cookies.token)
      .then((response) => {
        setTransactionCurrencies(
          response.map(({ currency: id, currency: name, ...rest }) => ({
            id,
            name,
            ...rest,
          })),
        );
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };

  const loadUsers = () => {
    apiGetRequest(
      'employees',
      cookies.token,
      {
        no_paging: 1,
      },
      [],
      {},
      true,
    )
      .then(([response]) => {
        setUsers(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };

  const receiptFilter = [
    { key: '1', value: t('uploaded') },
    { key: '0', value: t('not_uploaded') },
  ];

  const statusFilter = [
    { key: '1', value: t('approved') },
    { key: '0', value: t('declined') },
  ];

  const filterObject = [];
  if (!userID) {
    filterObject.push({
      key: 'card_holders[]',
      label: t('user'),
      type: 'list',
      options: users,
      value: null,
    });
    filterObject.push({
      key: 'department_id',
      label: t('department'),
      type: 'list',
      options: departments,
      value: null,
    });
  }
  filterObject.push({
    key: 'has_receipt',
    label: t('receipt'),
    type: 'list',
    options: receiptFilter,
    value: null,
  });
  filterObject.push({
    key: 'tags[]',
    label: t('tag'),
    type: 'list',
    options: filterTags,
    value: null,
  });
  filterObject.push({
    key: 'transaction_type[]',
    label: t('type'),
    type: 'list',
    options: transactionTypes,
    value: null,
  });
  filterObject.push({
    key: 'status',
    label: t('status'),
    type: 'list',
    options: statusFilter,
    value: null,
  });
  filterObject.push({
    key: 'amount',
    label: t('amount'),
    type: 'compound',
    options: {
      subfilters: [
        {
          key: 'from_amount',
          type: 'number',
          options: null,
          value: null,
          label: 'from',
        },
        {
          key: 'to_amount',
          type: 'number',
          options: null,
          value: null,
          label: 'to',
        },
        {
          key: 'currency',
          type: 'list',
          options: transactionCurrencies,
          value: null,
          label: 'currency',
        },
      ],
    },
    value: null,
  });

  const filterObjectSplit = [
    {
      key: 'has_receipt',
      label: t('receipt'),
      type: 'list',
      options: receiptFilter,
      value: null,
    },
    {
      key: 'status',
      label: t('status'),
      type: 'list',
      options: statusFilter,
      value: null,
    },
    {
      key: 'amount',
      label: t('amount'),
      type: 'compound',
      options: {
        subfilters: [
          {
            key: 'from_amount',
            type: 'number',
            options: null,
            value: null,
            label: 'from',
          },
          {
            key: 'to_amount',
            type: 'number',
            options: null,
            value: null,
            label: 'to',
          },
          {
            key: 'currency',
            type: 'list',
            options: transactionCurrencies,
            value: null,
            label: 'currency',
          },
        ],
      },
      value: null,
    },
  ];

  const exportFunction = () => {
    setIsLoadingExcel(true);

    apiGetRequestDownload('transactions/export', cookies.token, transactionParams(null))
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers['content-type'] }),
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${new Date().getTime()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        addNotification(t('unknown_error', { ns: 'common' }), false);
      })
      .finally(() => {
        setIsLoadingExcel(false);
      });
  };

  useEffect(() => {
    loadTags();
    if (!isExpense) {
      loadUsers();
    }
    if ((isSplit && filtersSplit.length !== 0) || filters.length !== 0) {
      loadDepartment();
      loadTransactionTypes();
      loadTransactionCurrencies();
    }
  }, [i18n.language, filtersSplit, filters]);

  useEffect(() => {
    if (userID || cardID) {
      setSearchFilters(null);
      dispatch(setAction(RESET_TRANSACTION_FILTER));
    }

    if (params.status) {
      const filterSaved = isSplit ? filtersSplit : filters;
      const checkIndex = filterSaved.filter((count) => count.key === 'status');
      if (checkIndex.length === 0) {
        dispatch(
          setAction(isSplit ? SET_TRANSACTION_SPLIT_FILTER : SET_TRANSACTION_FILTER, {
            type: 'add',
            object: {
              key: 'status',
              label: t('status'),
              type: 'list',
              options: statusFilter,
              value: params.status,
            },
          }),
        );
        setSearchFilters({ status: params.status, 'code[0]': '51', 'code[1]': '99' });
      }
      fetchTransactions({ status: params.status, 'code[0]': '51', 'code[1]': '99' });
    } else if (params.noReceipt) {
      const filterSaved = isSplit ? filtersSplit : filters;
      const checkIndex = filterSaved.filter((count) => count.key === 'has_receipt');
      if (checkIndex.length === 0) {
        dispatch(
          setAction(isSplit ? SET_TRANSACTION_SPLIT_FILTER : SET_TRANSACTION_FILTER, {
            type: 'add',
            object: {
              key: 'has_receipt',
              label: t('receipt'),
              type: 'list',
              options: receiptFilter,
              value: params.noReceipt,
            },
          }),
        );
        setSearchFilters({ has_receipt: params.noReceipt });
      }
      fetchTransactions({ has_receipt: params.noReceipt });
    } else {
      setSearchFilters(null);
      dispatch(setAction(RESET_TRANSACTION_SPLIT_FILTER));
      dispatch(setAction(RESET_TRANSACTION_DATE_FILTER_SPLIT));
    }

    if (location.pathname.includes('expenses')) {
      dispatch(setAction(RESET_TRANSACTION_DATE_FILTER));
    }
  }, [isSplit]);

  useEffect(() => {
    let savedFilter = null;
    if (filters.length > 0 && searchFilter === null && !isSplit) {
      const allFilters =
        filters
          .map((obj) => (obj.type === 'compound' ? obj.options.subfilters : [obj]))
          .reduce((current, accum) => [...current, ...accum], []) ?? [];
      savedFilter = allFilters
        .filter((obj) => obj.value !== null)
        .map(({ key, value }) => ({ [key]: value }))
        .reduce(
          (current, accum) => ({
            ...current,
            ...accum,
          }),
          {},
        );
    }

    fetchTransactions(savedFilter);
  }, [
    filterDays,
    filterFrom,
    filterTo,
    filterDaysSplit,
    filterFromSplit,
    filterToSplit,
    currentPage,
    invalidateData,
    searchKeywords,
    searchFilter,
    i18n.language,
    isExpense,
  ]);

  return (
    <div className="flex flex-grow flex-col">
      <Head title={isSplit ? 'Expense' : 'Transactions'} />
      <div className="mb-10 flex flex-row justify-between self-stretch">
        <LargerTitle2>{isSplit ? t('expenses') : t('transactions')}</LargerTitle2>
      </div>

      <TransactionsDataTable
        setDateFilter
        setExport={!!(!userID && !isSplit)}
        exportAction={exportFunction}
        exportLoading={isLoadingExcel}
        userID={userID}
        cardID={cardID}
        setFilter
        filterObject={isSplit ? filterObjectSplit : filterObject}
        setSearchFilters={setSearchFilters}
        setSearchKeywords={setSearchKeywords}
        isSplit={isSplit}
        pagination
        retryCallback={fetchTransactions}
        errored={errored}
        transactions={transactions}
        tags={tags}
        setPage={setCurrentPage}
        totalResults={totalResults}
        limit={paginationLimit}
        currentPage={currentPage}
        updating={isLoading}
        hideNameColumn={!!userID}
      />
    </div>
  );
};

export default Transactions;
