function CardholderDescription({ physicalCardData, virtualCardData, item }) {
  if (virtualCardData?.id === item.card_id) {
    return virtualCardData?.cardproduct?.description;
  }
  if (physicalCardData?.id === item.card_id) {
    return physicalCardData?.cardproduct?.description;
  }
}

export default CardholderDescription;
