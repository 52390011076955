export const setRegistrationStepAction = (step) => (dispatch) => {
  dispatch({
    type: 'SET_REGISTRATION_STEP',
    payload: step,
  });
};

export const setIdentityVerificationsStepAction = (step) => (dispatch) => {
  dispatch({
    type: 'SET_IDENTITY_VERIFICATIONS_STEP',
    payload: step,
  });
};
