import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '../../../../../components/AppSpinner';
import TimeRangeFilter from '../../../../../components/SharedComponents/TimeRangeFilter';
import { AppBody } from '../../../../../components/html/html';
import { downloadFile } from '../../../../../helpers/Utils';
import { walletTabs } from '../../../../../helpers/constants';
import { http } from '../../../../../http';
import { showModalAction } from '../../../../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  showWalletFilterPopupAction,
  showWalletSearchPopupAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import WalletFilterPopup from '../WalletFilterPopup';
import WalletSearchPopup from '../WalletSearchPopup';

function WalletTableActionButtons({
  transactionsSharedParams,
  exportParams,
  handleOnSearch,
  filterTags,
  handleApplyFilters,
  isDisabled,
  transactionTypeParams,
  handleTimeRangeChange,
}) {
  const { loaders } = useSelector((state) => state);
  const { isMobile } = useSelector((state) => state.appConfig);
  const { selectedWalletSubActiveTab, selectedWalletMainActiveTab } = useSelector(
    (state) => state.wallet,
  );

  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();

  const handleExport = useCallback(() => {
    let PARAMS = {
      ...transactionsSharedParams,
      ...exportParams,
    };

    if (selectedWalletSubActiveTab === walletTabs.EXPENSES) {
      PARAMS = { ...PARAMS, ...transactionTypeParams };
    }

    // if transactionTypeOption is "Settled"
    if (PARAMS['transaction_type[]'] === 5) {
      delete PARAMS.is_expense;
    }

    http
      .get(`transactions/export-specific-type`, {
        loader: 'handleExport',
        responseType: 'blob',
        params: PARAMS,
      })
      .then((res) => {
        const fileName = `WALLET_${selectedWalletMainActiveTab}_${selectedWalletSubActiveTab}`;

        downloadFile({
          data: res,
          fileName,
          fileExtension: 'xlsx',
        });
      })
      .catch(() => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: t('No Results Found.'),
          }),
        );
      });
  }, [exportParams, selectedWalletMainActiveTab, selectedWalletSubActiveTab]);

  const tableActionButtons = useMemo(() => {
    return [
      {
        label: t('Search', { ns: 'commonV2' }),
        onClick: () => {
          if (isMobile) {
            dispatch(showModalAction(<WalletSearchPopup onSearch={handleOnSearch} />));
          }
          dispatch(showWalletSearchPopupAction());
        },
        show: true,
      },
      {
        label: t('Filter', { ns: 'commonV2' }),
        onClick: () => {
          if (isMobile) {
            dispatch(
              showModalAction(
                <WalletFilterPopup filterTags={filterTags} onApply={handleApplyFilters} />,
              ),
            );
          }
          dispatch(showWalletFilterPopupAction());
        },
        show: true,
      },
      {
        label: loaders.handleExport ? <AppSpinner size={16} /> : t('Export', { ns: 'commonV2' }),
        onClick: () => {
          handleExport();
        },
        disabled: loaders.handleExport,
        show: selectedWalletMainActiveTab === walletTabs.TRANSACTIONS,
      },
      // Mobile Time Range
      {
        label: t('Time Range', { ns: 'commonV2' }),
        onClick: () => {
          dispatch(
            showModalAction(
              <TimeRangeFilter
                handleTimeRangeChange={handleTimeRangeChange}
                activeTab={selectedWalletSubActiveTab}
              />,
            ),
          );
        },
        show: isMobile,
      },
    ];
  }, [t, loaders]);

  return (
    <AppBody
      type="div"
      pClass="Caption1Bold"
      className={`flex text-primary ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}
    >
      {tableActionButtons.map((ele) => {
        return (
          ele.show && (
            <button
              disabled={ele.disabled}
              key={ele.label}
              onClick={ele.onClick}
              type="button"
              className="rounded-full px-3 py-2 text-primary hover:bg-gray-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
            >
              {ele.label}
            </button>
          )
        );
      })}
    </AppBody>
  );
}

export default WalletTableActionButtons;
