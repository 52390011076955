import { useTranslation } from 'react-i18next';
import linkExpired from '../../assets/png/linkExpired.png';
import { AppBody, AppHeader } from '../../components/html/html';

function ExpiredLink() {
  const { t } = useTranslation('employeeRegistration');

  return (
    <div className="flex h-full items-center justify-center text-center">
      <div className="max-w-[550px] px-2 xxs:px-4">
        <div className="mb-12 flex justify-center">
          <img src={linkExpired} alt="Link Expired" />
        </div>

        <AppHeader h="h6" className="mb-5">
          {t('This link is expired')}
        </AppHeader>
        <AppBody pClass="Body1Medium" className="text-gray-6">
          {t('Please contact your account manager to resend it.')}
        </AppBody>
      </div>
    </div>
  );
}

export default ExpiredLink;
