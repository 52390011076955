import { useDispatch, useSelector } from 'react-redux';
import { useMockAction } from '../../redux/UseMockReducer/UseMockActions';
import AppSwitchInput from '../AppSwitchInput';

function CardListMockContent() {
  const { useMock } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-2 text-xs [&>*]:border-b-2 [&>*]:pb-2">
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.showCardCreationDates}
            onChange={() => {
              dispatch(
                useMockAction({
                  showCardCreationDates: !useMock.showCardCreationDates,
                }),
              );
            }}
          />
          <span>Show Card Creation Dates</span>
        </label>
      </div>
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.showCardStatus}
            onChange={() => {
              dispatch(
                useMockAction({
                  showCardStatus: !useMock.showCardStatus,
                }),
              );
            }}
          />
          <span>Show Card Status</span>
        </label>
      </div>
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.showCardProgramStatus}
            onChange={() => {
              dispatch(
                useMockAction({
                  showCardProgramStatus: !useMock.showCardProgramStatus,
                }),
              );
            }}
          />
          <span>Show Card Program Status</span>
        </label>
      </div>
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.showCardModes}
            onChange={() => {
              dispatch(
                useMockAction({
                  showCardModes: !useMock.showCardModes,
                }),
              );
            }}
          />
          <span>Show Card Modes</span>
        </label>
      </div>
    </div>
  );
}

export default CardListMockContent;
