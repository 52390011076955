import React from 'react';
import { useTranslation } from 'react-i18next';
import Thanks1 from '../../../../assets/png/thanks1.png';
import Head from '../../../Head';
import {AppBody, AppHeader} from '../../../../components/html/html';

function ForgetPasswordThanks() {
  const { t } = useTranslation(['forget']);

  return (
    <div className="flex h-full items-center justify-center">
      <div className="m-auto flex w-full max-w-sm flex-col text-center lg:w-7/12">
        <Head title="Forget Password" />
        <img alt="Thanks" src={Thanks1} className="m-auto w-full max-w-xs" />

        <AppHeader h="h4" className="text-center">
          {t('send_title')}
        </AppHeader>
        <AppBody pClass="Body2Regular" className="mb-10 pt-5 text-center">{t('send_content')}</AppBody>
      </div>
    </div>
  );
}

export default ForgetPasswordThanks;
