import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import AppNumberInput from '../../../../../components/FormInputs/AppNumberInput';
import AppSelect from '../../../../../components/FormInputs/AppSelect';
import { AppBody, AppButton, AppIcon } from '../../../../../components/html/html';
import { translatedDataFunction } from '../../../../../helpers/Utils';
import { transactionTabs } from '../../../../../helpers/constants';
import {
    getRequestCurrenciesAction,
    getTagsAction,
    getTransactionCurrenciesAction,
} from '../../../../../redux/CardDetailsReducer/CardDetailsActions';

function FilterPopupContent({ onApply, filterTags }) {
  const defaultValue = useMemo(() => {
    return {
      filters: [
        {
          filter: '',
          subFilter: '',
        },
      ],
    };
  }, []);

  const { loaders, appConfig } = useSelector((state) => state);
  const { selectedActiveTab, tags, transactionCurrencies, requestCurrencies } = useSelector(
    (state) => state.cardDetails,
  );
  const [serializedTags, setSerializedTags] = useState([]);
  const [serializedTransactionCurrencies, setSerializedTransactionCurrencies] = useState([]);
  const [serializedRequestCurrencies, setSerializedRequestCurrencies] = useState([]);
  const [tabFilters, setTabFilters] = useState([]);

  const [initialValues, setInitialValues] = useState(defaultValue);

  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();

  const customSelectStyles = useMemo(() => {
    if (appConfig.isMobile) {
      return {};
    }
    return {
      menuList: (provided) => ({
        ...provided,
        maxHeight: '90px',
      }),
    };
  }, [appConfig.isMobile]);

  // =========== Type Options =========== //

  const receiptOption = useMemo(() => {
    return {
      label: t('Receipt'),
      value: 'receipt',
      name: 'receipt',
      queryName: 'has_receipt_only',
      subFilterOptions: [
        { label: t('Uploaded'), value: '1' },
        { label: t('Not Uploaded'), value: '0' },
      ],
    };
  }, []);

  const tagsOption = useMemo(() => {
    return {
      label: t('Tag'),
      value: 'Tag',
      name: 'tag',
      queryName: 'tags[]',
      subFilterOptions: serializedTags,
    };
  }, [serializedTags]);

  const amountOption = useMemo(() => {
    const currencies =
      selectedActiveTab === transactionTabs.REQUESTS
        ? serializedRequestCurrencies
        : serializedTransactionCurrencies;
    return {
      label: t('Amount'),
      value: 'Amount',
      name: 'amount',
      queryName: ['from_amount', 'to_amount', 'currency'],
      subFilterOptions: currencies,
    };
  }, [serializedTransactionCurrencies, serializedRequestCurrencies, selectedActiveTab]);

  const statusOption = useMemo(() => {
    return {
      label: t('Status', { ns: 'commonV2' }),
      value: 'Status',
      name: 'status',
      queryName: 'status_id',
      subFilterOptions: [
        { label: t('Approved', { ns: 'commonV2' }), value: '2' },
        { label: t('Rejected', { ns: 'commonV2' }), value: '3' },
        { label: t('Pending', { ns: 'commonV2' }), value: '1' },
      ],
    };
  }, []);

  const createTypeOption = useCallback((subFilterOptions) => {
    return {
      label: t('Type'),
      value: 'Type',
      name: 'type',
      queryName: 'transaction_type[]',
      subFilterOptions,
    };
  }, []);

  // =========== Tab Filters =========== //

  const expenseTabFilters = useMemo(() => {
    return [
      { ...receiptOption },
      {
        ...createTypeOption([{ label: t('Split'), value: '7' }]),
      },
      {
        ...tagsOption,
      },
      {
        ...amountOption,
      },
    ];
  }, [tagsOption, amountOption, receiptOption]);

  const declinesTabFilters = useMemo(() => {
    return [
      {
        ...amountOption,
      },
    ];
  }, [amountOption]);

  const refundsTabFilters = useMemo(() => {
    return [
      {
        ...tagsOption,
      },
      {
        ...createTypeOption([
          { label: t('Reversal'), value: '1' },
          { label: t('Refund'), value: '6' },
        ]),
      },
      {
        ...receiptOption,
      },
      {
        ...amountOption,
      },
    ];
  }, [tagsOption, receiptOption, amountOption]);

  const loadsTabFilters = useMemo(() => {
    return [
      {
        ...createTypeOption([
          { label: t('Load'), value: '2' },
          { label: t('Unload'), value: '3' },
        ]),
      },
      {
        ...amountOption,
      },
    ];
  }, [amountOption]);

  const requestsTabFilters = useMemo(() => {
    return [
      {
        ...statusOption,
      },
      {
        ...amountOption,
      },
    ];
  }, [statusOption, amountOption]);

  const handleApply = useCallback((values, FORMIK) => {
    let hasError = false;
    values.filters.forEach((ele) => {
      if (ele.filter.name === 'amount') {
        if (!ele.subFilter.to_amount?.value) {
          FORMIK.setFieldError('to_amount', t('required', { ns: 'commonV2' }));
          hasError = true;
        }
        if (!ele.subFilter.from_amount?.value) {
          FORMIK.setFieldError('from_amount', t('required', { ns: 'commonV2' }));
          hasError = true;
        }
        if (!ele.subFilter.currency?.value) {
          FORMIK.setFieldError('currency', t('required', { ns: 'commonV2' }));
          hasError = true;
        }
      }
    });
    if (!hasError) {
      onApply(values);
    }
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      filters: yup.array().of(
        yup.object().shape({
          filter: yup
            .object()
            .nullable()
            .required(t('This field is required!', { ns: 'commonV2' })),
          subFilter: yup
            .object()
            .nullable()
            .required(t('This field is required!', { ns: 'commonV2' })),
        }),
      ),
    });
  }, []);

  const formik = useFormik({
    validateOnBlur: !appConfig.isMobile,
    initialValues,
    onSubmit: handleApply,
    validationSchema,
    enableReinitialize: true,
  });

  const clearNonAmountValues = useCallback(
    (index) => {
      const protectedKeys = {
        from_amount: 'from_amount',
        to_amount: 'to_amount',
        currency: 'currency',
      };
      const updated = {};
      if (formik.values.filters[index]) {
        Object.entries(formik.values.filters[index].subFilter).forEach(([key, value]) => {
          if (protectedKeys[key]) {
            updated[key] = value;
          }
        });
        formik.setFieldValue(`filters[${index}].subFilter`, updated);
      }
    },
    [formik],
  );

  const isAddMoreDisabled = useMemo(() => {
    const isMoreThanFilterCount = formik.values.filters.length >= tabFilters.length;
    const lastSubFilterHasValue =
      formik.values.filters[formik.values.filters.length - 1]?.subFilter;

    return isMoreThanFilterCount || !lastSubFilterHasValue;
  }, [formik, tabFilters]);

  const showInputError = useCallback(
    ({ inputName, index }) => {
      return (
        formik.touched.filters &&
        formik.touched.filters[index] &&
        formik.touched.filters[index][inputName] &&
        formik.errors.filters &&
        formik.errors.filters[index] &&
        formik.errors.filters[index][inputName]
      );
    },
    [formik],
  );

  const selectedFilters = useMemo(() => {
    return formik.values.filters.map((ele) => ele.filter?.value);
  }, [formik, initialValues]);

  useEffect(() => {
    if (selectedActiveTab === transactionTabs.EXPENSES) {
      setTabFilters(expenseTabFilters);
    }
    if (selectedActiveTab === transactionTabs.DECLINES) {
      setTabFilters(declinesTabFilters);
    }
    if (selectedActiveTab === transactionTabs.REFUNDS) {
      setTabFilters(refundsTabFilters);
    }
    if (selectedActiveTab === transactionTabs.LOADS) {
      setTabFilters(loadsTabFilters);
    }
    if (selectedActiveTab === transactionTabs.REQUESTS) {
      setTabFilters(requestsTabFilters);
    }
  }, [selectedActiveTab, serializedTransactionCurrencies, serializedTags]);

  useEffect(() => {
    if (tags && appConfig.lang) {
      const filteredTags = tags.filter((ele) => !ele.deleted);

      const serialized = filteredTags.map((ele) => {
        return {
          label: translatedDataFunction({ lang: appConfig.lang, en: ele.name, ar: ele.name_ar }),
          value: ele.id,
        };
      });
      const withoutTagOption = {
        label: translatedDataFunction({
          lang: appConfig.lang,
          en: 'Without Tag',
          ar: 'بدون تصنيف',
        }),
        value: -1,
      };
      setSerializedTags([withoutTagOption, ...serialized]);
    }

    if (transactionCurrencies) {
      const serialized = transactionCurrencies.map((ele) => {
        return {
          label: ele.currency,
          value: ele.currency,
        };
      });
      setSerializedTransactionCurrencies(serialized);
    }

    if (requestCurrencies) {
      const serialized = requestCurrencies.map((ele) => {
        return {
          label: ele.currency,
          value: ele.currency,
        };
      });
      setSerializedRequestCurrencies(serialized);
    }
  }, [tags, appConfig, transactionCurrencies, requestCurrencies]);

  useEffect(() => {
    if (!tags) {
      dispatch(getTagsAction());
    }

    if (!transactionCurrencies && selectedActiveTab !== transactionTabs.REQUESTS) {
      dispatch(getTransactionCurrenciesAction());
    }

    if (!requestCurrencies && selectedActiveTab === transactionTabs.REQUESTS) {
      dispatch(getRequestCurrenciesAction());
    }
  }, []);

  useEffect(() => {
    const filteredFormikInitialValues = Object.values(filterTags).filter((ele) => ele.formValue);
    const formikInitialValues = filteredFormikInitialValues.map((ele) => ele.formValue);
    if (formikInitialValues.length) {
      setInitialValues({ filters: formikInitialValues });
    }
  }, [filterTags]);

  console.log(JSON.stringify(formik, null, 3));

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {formik.values.filters.map((ele, index) => {
          const key = `filters-form[${index}]`;
          const showRemoveBtn = index >= 1;
          const showSubFilter = !!ele.filter;
          const showSelectInputs = ele.filter?.name !== 'amount';
          const showAmountInputs = ele.filter?.name === 'amount';
          return (
            <div key={key} className="mb-4 grid gap-4 border-b pb-4">
              <div>
                <AppBody pClass="Caption1Regular" type="span" className="mb-2 flex justify-between">
                  <AppBody type="span" pClass="Body1Bold">{t('Select Filters')}</AppBody>

                  {showRemoveBtn && (
                    <AppButton
                      className="text-primary"
                      button="link"
                      onClick={() => {
                        const updatedValue = formik.values.filters;
                        updatedValue.splice(index, 1);
                        formik.setFieldValue('filters', updatedValue);
                      }}
                    >
                      {t('Clear', { ns: 'commonV2' })}
                    </AppButton>
                  )}
                </AppBody>
                <AppSelect
                  isSearchable={!appConfig.isMobile}
                  mobileSelect={false}
                  customStyleConfigs={customSelectStyles}
                  className="w-full text-xs"
                  name={`filters[${index}].filter`}
                  value={ele.filter}
                  onMenuClose={() => {
                    formik.setFieldTouched(`filters[${index}].filter`);
                  }}
                  options={tabFilters.filter((f) => !selectedFilters.includes(f.value))}
                  onChange={(option) => {
                    formik.setFieldValue(`filters[${index}].subFilter`, '');
                    formik.setFieldValue(`filters[${index}].filter`, option);
                  }}
                />
                {showInputError({ index, inputName: `filter` }) && (
                  <AppBody pClass="Caption2Medium" type="span" className="text-red-600">{formik.errors.filters[index].filter}</AppBody>
                )}
              </div>

              {/* SubFilter */}
              {showSubFilter && (
                <div>
                  {/* Receipt, Type, Tag, Inputs */}
                  {showSelectInputs && (
                    <div>
                      <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                        {t('Select', { ns: 'commonV2' })} {ele.filter.label}
                      </AppBody>
                      <AppSelect
                        isSearchable={!appConfig.isMobile}
                        mobileSelect={false}
                        customStyleConfigs={customSelectStyles}
                        className="w-full text-xs"
                        name={`filters[${index}].subFilter`}
                        value={ele.subFilter}
                        onMenuClose={() => {
                          formik.setFieldTouched(`filters[${index}].subFilter`);
                        }}
                        options={ele.filter.subFilterOptions}
                        onChange={(option) => {
                          formik.setFieldValue(`filters[${index}].subFilter`, option);
                        }}
                      />
                      {showInputError({ index, inputName: `subFilter` }) && (
                        <AppBody pClass="Caption2Medium" type="span" className="text-red-600">
                          {formik.errors.filters[index].subFilter}
                        </AppBody>
                      )}
                    </div>
                  )}

                  {/* Amount Input */}
                  {showAmountInputs && (
                    <div>
                      <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                        {t('Amount Range')}
                      </AppBody>
                      <div className="grid grid-cols-3 gap-1">
                        <div className="col-span-1">
                          <AppNumberInput
                            className="text-sm"
                            placeholder={t('From', { ns: 'commonV2' })}
                            name={`filters[${index}].subFilter.from_amount`}
                            value={ele.subFilter.from_amount?.value || ''}
                            onChange={(e) => {
                              clearNonAmountValues(index);
                              formik.setFieldValue(`filters[${index}].subFilter.from_amount`, {
                                label: 'from_amount',
                                value: e.target.value,
                              });
                            }}
                          />
                          {formik.errors.from_amount && (
                            <AppBody pClass="Caption2Medium" type="span" className="text-red-600">{formik.errors.from_amount}</AppBody>
                          )}
                        </div>
                        <div className="col-span-1">
                          <AppNumberInput
                            className="text-sm"
                            placeholder={t('To', { ns: 'commonV2' })}
                            name={`filters[${index}].subFilter.to_amount`}
                            value={ele.subFilter.to_amount?.value || ''}
                            onChange={(e) => {
                              clearNonAmountValues(index);
                              formik.setFieldValue(`filters[${index}].subFilter.to_amount`, {
                                label: 'to_amount',
                                value: e.target.value,
                              });
                            }}
                          />
                          {formik.errors.to_amount && (
                            <AppBody pClass="Caption2Medium" type="span" className="text-red-600">{formik.errors.to_amount}</AppBody>
                          )}
                        </div>
                        <div className="col-span-1">
                          <AppSelect
                            isSearchable={!appConfig.isMobile}
                            mobileSelect={false}
                            customStyleConfigs={customSelectStyles}
                            className="w-full text-xs"
                            placeholder={t('Currency', { ns: 'commonV2' })}
                            name={`filters[${index}].subFilter.currency`}
                            value={ele.subFilter.currency}
                            onMenuClose={() => {
                              formik.setFieldTouched(`filters[${index}].subFilter.currency`);
                            }}
                            options={ele.filter.subFilterOptions}
                            onChange={(option) => {
                              clearNonAmountValues(index);
                              formik.setFieldValue(`filters[${index}].subFilter.currency`, option);
                            }}
                          />
                          {formik.errors.currency && (
                            <AppBody pClass="Caption2Medium" type="span" className="text-red-600">{formik.errors.currency}</AppBody>
                          )}
                        </div>
                      </div>
                      {showInputError({ index, inputName: `subFilter` }) && (
                        <AppBody pClass="Caption2Medium" type="span" className="text-red-600">
                          {formik.errors.filters[index].subFilter}
                        </AppBody>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}

        <AppButton
          className="flex items-center gap-2"
          onClick={() => {
            const updatedValue = formik.values.filters;
            updatedValue.push(defaultValue);
            formik.setFieldValue('filters', updatedValue);
          }}
          button="link"
          disabled={isAddMoreDisabled}
        >
          <AppIcon iClass="XXLargeFont" className="fa-solid fa-circle-plus" />
          <span>{t('Add More', { ns: 'commonV2' })}</span>
        </AppButton>

        <div className="mb-3 flex items-center justify-end gap-4">
          <AppButton
            isLoading={loaders.getCardTransactionsAction || loaders.getCardRequestsAction}
            disabled={!formik.isValid}
            button="black"
            type="submit"
          >
            {t('Apply', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </form>
    </div>
  );
}

export default FilterPopupContent;
