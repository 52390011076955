import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import terminatedSuccessfullyAnimation from '../../../../../../../assets/lotties/terminatedSuccessfully.json';
import AppLottie from '../../../../../../../components/AppLottie';
import AppRadioInput from '../../../../../../../components/FormInputs/AppRadioInput';
import { AppBody, AppButton, AppHeader } from '../../../../../../../components/html/html';
import { cardStatusKeys } from '../../../../../../../helpers/constants';
import { http } from '../../../../../../../http';
import { showToasterAction } from '../../../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  hideSettingsPopupDataAction,
  updateSelectedCardDetailsAction,
} from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';
import CardStatusImage from './CardStatusImage';

function TerminateCard() {
  const { loaders } = useSelector((state) => state);
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [terminationReasons, setTerminationReasons] = useState([]);
  const [selectedTerminationReason, setSelectedTerminationReason] = useState('');

  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const getTerminationReasons = useCallback(() => {
    http
      .get('cardmanager/status/termination', {
        loader: 'getTerminationReasons',
      })
      .then((res) => {
        const serialized = res.data.map((ele) => {
          return {
            label: ele.description,
            value: ele.id,
          };
        });
        setTerminationReasons(serialized);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, []);

  const handleTerminateCard = useCallback(() => {
    const cardId = settingsPopupCardDetails.id;

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('status', cardStatusKeys.TERMINATED);
    formData.append('status_reason_code', selectedTerminationReason.value);

    http
      .post(`cardmanager/cards/${cardId}/status`, formData, {
        loader: 'handleTerminateCard',
      })
      .then((res) => {
        setShowSuccessMessage(true);

        dispatch(
          updateSelectedCardDetailsAction({
            card: {
              ...res.data,
              status: {
                ...res.data.status,
                key: cardStatusKeys.TERMINATED,
                name: t('Terminated'),
                status_reason: selectedTerminationReason.label,
                status_reason_ar: selectedTerminationReason.label,
              },
            },
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, [selectedTerminationReason]);

  useEffect(() => {
    getTerminationReasons();
    return () => {
      setShowSuccessMessage(false);
    };
  }, []);

  return (
    <>
      {showSuccessMessage && (
        <div>
          <AppHeader h="h6" className="mb-3 flex flex-col items-center">
            <div className="flex gap-1">
              <span>{t('Card')}</span>
              <span>*{settingsPopupCardDetails.last_four}</span>
            </div>
            <span>{t('Terminated Successfully')}</span>
          </AppHeader>

          <CardStatusImage grayCard />

          <div className="absolute top-[50%] z-0">
            <AppLottie loop={false} animationData={terminatedSuccessfullyAnimation} width={350} />
          </div>
        </div>
      )}

      {!showSuccessMessage && (
        <div>
          <AppHeader h="h5" className="mb-3">
            <div className="flex gap-1">
              <span>{t('Terminate Card')}</span>
              <span>*{settingsPopupCardDetails.last_four}</span>
            </div>
          </AppHeader>

          <CardStatusImage grayCard />

          <div className="mx-auto mb-4 w-3/4">
            <AppHeader h="h6" className="mb-3">
              <span>{t('Are You Sure You Want to Terminate This Card?')}</span>
            </AppHeader>

            <AppBody pClass="Body2Medium" className="mb-4 text-gray-6">
              <span>
                {t(
                  'While your card is terminated, all transactions, including purchases and withdrawals, are blocked. You can reactivate the card at any time.',
                )}
              </span>
            </AppBody>

            <AppBody pClass="Body1Bold" className="mb-3">
              {t('The Reason of The Terminate:')}
            </AppBody>

            {loaders.getTerminationReasons && !terminationReasons.length && (
              <Skeleton count={3} className="mb-2" />
            )}

            {!loaders.getTerminationReasons && !!terminationReasons.length && (
              <div className="grid gap-1">
                {terminationReasons.map((o) => {
                  return (
                    <AppRadioInput
                      key={o.value}
                      id={o.value}
                      value={o.value}
                      label={o.label}
                      name="terminationReasons"
                      onChange={() => {
                        setSelectedTerminationReason(o);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className="mx-auto w-3/4">
            <div className="flex items-center justify-between">
              <AppButton
                button="link"
                className="text-sm"
                onClick={() => {
                  dispatch(hideSettingsPopupDataAction());
                }}
              >
                {t('Discard Changes', { ns: 'commonV2' })}
              </AppButton>
              <AppButton
                isLoading={loaders.handleTerminateCard}
                disabled={!selectedTerminationReason}
                button="danger"
                onClick={() => {
                  handleTerminateCard();
                }}
              >
                {t('Terminate')}
              </AppButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TerminateCard;
