import masterCardLogo from '../../assets/png/Mastercard-logo.png';
import visaCardLogo from '../../assets/png/Visa-logo.png';
import dummyPlaneCard from '../../assets/png/dummyPlaneCard.png';
import dummyPlanePlasticCard from '../../assets/png/dummyPlanePlasticCard.png';
import prepaidBankLogo from '../../assets/png/prepaidBankLogo.png';
import {cardSchemes} from '../../helpers/constants';
import AppImageComponent from '../AppImageComponent';
import {AppIcon} from "../html/html";

function NqoodCardImg({
  skeletonWidth,
  skeletonHeight,
  alt,
  type = 'virtual',
  scheme = cardSchemes.MASTER,
  bankLogo = prepaidBankLogo,
  hideLogos = false,
}) {
  return (
    <div className="relative w-full">
      <AppImageComponent
        skeletonWidth={skeletonWidth}
        skeletonHeight={skeletonHeight}
        src={type === 'virtual' ? dummyPlaneCard : dummyPlanePlasticCard}
        alt={alt}
      />

      {!hideLogos && (
        <div className="absolute right-4 top-4 w-6 max-w-[30px] xs:w-8 sm:w-6 md:w-7 lg:w-6 2xl:w-auto">
          <AppIcon iClass="XLargeFont" className={`${bankLogo}`} />
          <p className="relative right-2 mt-2 text-center text-xxs text-white sm:block md:right-2 2xl:right-1">
            prepaid
          </p>
        </div>
      )}

      {!hideLogos && (
        <img
          className="absolute bottom-4 right-4 w-6 max-w-[40px] xs:w-8 sm:w-7 md:w-7 lg:w-7 2xl:w-auto"
          src={scheme === cardSchemes.MASTER ? masterCardLogo : visaCardLogo}
          alt="Card Scheme Logo"
        />
      )}
    </div>
  );
}

export default NqoodCardImg;
