import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBody, AppHeader, AppIcon } from '../html/html';

function AppTabs2({ tabs, defaultActiveTabIndex, onTabChange, customTranslateY }) {
  const { t } = useTranslation('commonV2');
  const [activeTabIndex, setActiveTabIndex] = useState();
  const [showMobileTabs, setShowMobileTabs] = useState(false);
  const [openTabs, setOpenTabs] = useState({});

  const handleClick = useCallback(({ index, tab }) => {
    if (!tab.disabled) {
      setActiveTabIndex(index);
      onTabChange(index);
    }

    if (tab.dropDown) {
      setOpenTabs((prev) => {
        return {
          ...prev,
          [tab.id]: !prev[tab.id],
        };
      });
    } else {
      setShowMobileTabs(false);
      setOpenTabs({});
    }
  }, []);

  const handleDropDownContentClick = useCallback((tab) => {
    setShowMobileTabs(false);
    setTimeout(() => {
      setOpenTabs((prev) => {
        return {
          ...prev,
          [tab.id]: tab.isOpen,
        };
      });
    }, 0);
  }, []);

  useEffect(() => {
    setActiveTabIndex(defaultActiveTabIndex || 0);
  }, []);

  useEffect(() => {
    tabs.forEach((ele) => {
      if (ele.dropDown) {
        setOpenTabs((prev) => {
          return {
            ...prev,
            [ele.id]: ele.isOpen,
          };
        });
      }
    });
  }, []);

  return (
    <>
      {/* ================== Desktop Tabs ================== */}
      <ul className="hidden flex-col gap-6 lg:flex">
        {tabs
          .filter((ele) => ele.show)
          .map((tab, index) => {
            const isActiveTab = index === activeTabIndex;
            const activeClasses = 'text-primary';
            const inActiveClasses = 'ms-4';
            const disabledClasses = tab.disabled
              ? 'cursor-not-allowed opacity-30'
              : 'cursor-pointer';

            return (
              <li key={tab.label}>
                <div
                  onClick={() => {
                    handleClick({ index, tab });
                  }}
                  className={`flex items-center gap-10 ${disabledClasses} ${
                    isActiveTab ? activeClasses : inActiveClasses
                  }`}
                >
                  <div className="item-center flex gap-2">
                    {isActiveTab && <div className="h-6 w-1.5 rounded-e-lg bg-primary" />}
                    <AppBody pClass="Body1Bold" className="hover:text-primary">
                      {t(tab.label)}
                    </AppBody>
                  </div>

                  <div>
                    {tab.dropDown && (
                      <AppIcon
                        className={`fa-regular fa-angle-down ${
                          openTabs[tab.id] ? 'rotate-180' : ''
                        }`}
                        iClass="XLargeFont"
                      />
                    )}
                  </div>
                </div>

                {tab.dropDown && openTabs[tab.id] && <div>{tab.dropDown}</div>}
              </li>
            );
          })}
      </ul>

      {/* ================== Mobile Tabs ================== */}

      <button
        type="button"
        onClick={() => {
          setShowMobileTabs(true);
        }}
      >
        <AppHeader h="h5" className="block text-primary lg:hidden">
          <div className="flex items-center gap-1">
            <div className="h-6 w-2 rounded-e-lg bg-primary" />
            <span>{t(tabs[activeTabIndex]?.label)}</span>
            <AppIcon iClass="XLargeFont" className="fa-regular fa-angle-down ms-1" />
          </div>
        </AppHeader>
      </button>

      {/* ========= Mobile Tabs Popup ========= */}
      {showMobileTabs && (
        <div
          className="fixed inset-0 z-[60] bg-black/40"
          onClick={() => {
            setShowMobileTabs(false);
          }}
        />
      )}

      <div
        className={`fixed ${
          showMobileTabs ? `${customTranslateY || 'translate-y-[45vh]'}` : 'translate-y-[100vh]'
        } left-[50%] z-[70] h-[100vh] w-[98%] -translate-x-[50%] rounded-t-2xl bg-white p-5 pb-12 transition-transform duration-300 ease-in-out`}
      >
        <button
          type="button"
          className="mx-auto mb-4 block h-1 w-20 rounded-full bg-gray-4"
          onClick={() => {
            setShowMobileTabs(false);
          }}
        />

        <div
          className={`${customTranslateY ? 'h-auto' : 'h-60'} flex flex-col gap-5 overflow-y-auto`}
        >
          {tabs
            .filter((ele) => ele.show)
            .map((tab, index) => {
              const isActiveTab = index === activeTabIndex;
              const activeClasses = 'text-primary';
              const disabledClasses = tab.disabled
                ? 'cursor-not-allowed opacity-30'
                : 'cursor-pointer';

              return (
                <AppHeader
                  h="h6"
                  key={tab.label}
                  onClick={() => {
                    handleClick({ index, tab });
                  }}
                >
                  <div
                    className={`flex items-center gap-8 ${disabledClasses} ${
                      isActiveTab ? activeClasses : ''
                    }`}
                  >
                    <div>{t(tab.label)}</div>

                    <div>
                      {tab.dropDown && (
                        <AppIcon
                          className={`fa-regular fa-angle-down ${
                            openTabs[tab.id] ? 'rotate-180' : ''
                          }`}
                          iClass="XLargeFont"
                        />
                      )}
                    </div>
                  </div>

                  {tab.dropDown && openTabs[tab.id] && (
                    <div
                      onClick={() => {
                        handleDropDownContentClick(tab);
                      }}
                    >
                      {tab.dropDown}
                    </div>
                  )}
                </AppHeader>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default AppTabs2;
