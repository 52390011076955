import React from 'react';
import { useTranslation } from 'react-i18next';
import RegisterStepBar from '../../../../components/Stepbar/RegisterStepBar';
import { HeadlineCenter } from '../../../../components/Titles';
import RelatedCR from './RelatedCR';

function VerifyCompany() {
  const user = localStorage.getItem('userinfo')
    ? JSON.parse(localStorage.getItem('userinfo'))
    : null;
  if (!user) {
    return null;
  }
  const { t, i18n } = useTranslation(['signup']);
  const userName =
    user && i18n.language === 'ar' && user.first_name_ar !== ''
      ? `${user.first_name_ar}`
      : `${user.first_name}`;

  return (
    <div className="flex h-full items-center justify-center">
      <div className="mx-auto flex w-full flex-col lg:w-8/12">
        <RegisterStepBar step={5} />
        <HeadlineCenter>{`${t('company_account.hi')} ${userName},`}</HeadlineCenter>

        <RelatedCR user={user} />
      </div>
    </div>
  );
}

export default VerifyCompany;
