import { useFormik } from 'formik';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import idPreview from '../../assets/png/idPreview.png';
import AppDatePicker from '../../components/AppDatePicker';
import AppInput from '../../components/AppFormInputs/AppInput';
import AppTooltip from '../../components/AppTooltip';
import DeveloperComponent from '../../components/DeveloperComponent';
import AppRadioInput from '../../components/FormInputs/AppRadioInput';
import AppUpload from '../../components/FormInputs/AppUpload/index';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../components/html/html';
import { employeeIdentityVerification } from '../../helpers/constants';
import { formateDateToYYYYMMDD, isDev } from '../../helpers/Utils';
import { setIdentityVerificationsStepAction } from '../../redux/EmployeeRegistrationReducer/EmployeeRegistrationActions';

function EmployeeIdentityVerificationForm() {
  const { t } = useTranslation('employeeRegistration');
  const dispatch = useDispatch();

  const expiryDateRef = useRef();
  const dateOfBirthRef = useRef();

  const idTypes = useMemo(() => {
    return [
      {
        id: 'ID',
        changingLabel: 'ID',
        label: t('National ID'),
        value: 'nid',
      },
      {
        id: 'Iqama',
        changingLabel: 'Al Iqama',
        label: t('Iqama'),
        value: 'iqama',
      },
      {
        id: 'Passport',
        changingLabel: 'Passport',
        label: t('Passport'),
        value: 'passport',
      },
    ];
  }, [t]);

  const calendarTypes = useMemo(() => {
    return [
      {
        id: 'Georgian',
        label: t('Georgian'),
        value: 'Georgian',
      },
      {
        id: 'Hijri',
        label: t('Hijri'),
        value: 'Hijri',
      },
    ];
  }, []);

  const onSubmit = useCallback((values) => {
    dispatch(
      setIdentityVerificationsStepAction(
        employeeIdentityVerification.identityVerificationFullNameForm,
      ),
    );
  }, []);

  const initialValues = useMemo(() => {
    return {
      idType: 'nid',
      idNumber: '',
      calendarType: 'Georgian',
      expiryDate: '',
      dateOfBirth: '',
      idFile: '',
    };
  }, []);

  const validationSchema = yup.object({
    idType: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    idNumber: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    expiryDate: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    dateOfBirth: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    idFile: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  const selectedIdTypeLabel = useMemo(() => {
    return idTypes.find((ele) => formik.values.idType === ele.value)?.changingLabel;
  }, [idTypes, formik.values]);

  return (
    <div className="px-2 pt-14 xxs:px-4 xl:px-16">
      <div className="mx-auto max-w-[550px]">
        <AppHeader h="h2" className="mb-2 text-center">
          {t('Identity Verification')}
        </AppHeader>
        <AppBody pClass="Body1Medium" className="mb-7 text-center text-gray-6">
          <span>
            {t(
              'As per the regulation requirements, We need to collect information about the authorized person in the company',
            )}
          </span>

          <span>
            <AppTooltip
              style={{ backgroundColor: 'white' }}
              place="bottom"
              opacity={1}
              id="identity-verification"
              icon={<AppIcon className="fa-regular fa-circle-info ms-2" />}
            >
              <AppBody pClass="Body2Medium" className="max-w-80">
                {t(
                  'As a part of [know your client and money laundering procedures] to protect your rights and proceed with application. E.g National or Iqama ID information',
                )}
              </AppBody>
            </AppTooltip>
          </span>
        </AppBody>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="mb-7 block">
          <span className="mb-1 block text-xl font-bold">{t('ID Type')}</span>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            {idTypes?.map((ele, i) => {
              const isChecked = formik.values.idType === ele.value;
              return (
                <div
                  key={ele.id}
                  className={`${
                    isChecked ? 'border-primary bg-gray-2' : 'border-gray-3 bg-gray-1'
                  } flex h-10 cursor-pointer items-center rounded-lg border px-3`}
                >
                  <label
                    htmlFor={`radio-idTypes-${i}`}
                    className="ms-2 w-full cursor-pointer py-2 text-xs font-medium"
                  >
                    {t(ele.label)}
                  </label>
                  <AppRadioInput
                    color="primary"
                    id={`radio-idTypes-${i}`}
                    name="idType"
                    value={ele.value}
                    checked={isChecked}
                    onChange={(e) => {
                      formik.setFieldValue('idType', e.target.value);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <label className="mb-7 block">
          <span className="mb-1 block text-xl font-bold">
            <span className="inline-flex items-center gap-1">
              <span className="order-1 rtl:order-2">{t(selectedIdTypeLabel)}</span>
              <span className="order-2 rtl:order-1">{t('Number')}</span>
            </span>
            <AppTooltip
              place="bottom"
              opacity={1}
              style={{ backgroundColor: 'white' }}
              id="preview"
              icon={<AppIcon className="fa-regular fa-circle-info ms-2 text-xl" />}
            >
              <div className="max-w-80">
                <img src={idPreview} alt="Id Preview" />
                <AppBody pClass="Body2Regular" className="mt-4 text-gray-6">
                  {t(
                    "National identity is a person's identity or sense of belonging to one or more states or to one or more nations.",
                  )}
                </AppBody>
              </div>
            </AppTooltip>
          </span>
          <AppInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.idNumber}
            showInvalidIcon={false}
            size="md"
            name="idNumber"
            type="text"
          />
          {formik.errors.idNumber && formik.touched.idNumber && (
            <small className="text-danger">{t(formik.errors.idNumber, { ns: 'commonV2' })}</small>
          )}
        </label>

        <div className="mb-7 flex items-center gap-2">
          <span className="mb-1 block text-xl font-bold">{t('Calender type: ')}</span>
          <div className="flex gap-7">
            {calendarTypes?.map((ele, i) => {
              return (
                <div key={ele.id} className="flex items-center">
                  <AppRadioInput
                    color="primary"
                    id={`radio-calendarTypes-${i}`}
                    name="calendarType"
                    value={ele.value}
                    checked={formik.values.calendarType === ele.value}
                    onChange={(e) => {
                      formik.setFieldValue('calendarType', e.target.value);
                      formik.setFieldValue('expiryDate', '');
                      formik.setFieldValue('dateOfBirth', '');
                    }}
                  />
                  <label htmlFor={`radio-calendarTypes-${i}`} className="cursor-pointer">
                    <AppBody pClass="Body1Bold">{t(ele.label)}</AppBody>
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-7 grid grid-cols-1 gap-3 lg:grid-cols-2 xl:gap-10">
          <div>
            <div
              onClick={(e) => {
                if (!e.target.closest('[data-id="clear-expiryDate"]')) {
                  expiryDateRef.current.openCalendar();
                } else {
                  formik.setFieldValue('expiryDate', '');
                }
              }}
              className="flex h-12 cursor-pointer items-center justify-between rounded-lg bg-white px-4"
            >
              <div className="flex items-center gap-2">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-2">
                  <AppIcon className="fa-regular fa-calendar-lines text-primary" />
                </div>
                <AppBody pClass="Body1Bold">{t('Expiry Date')}</AppBody>
              </div>
              <div className="flex items-center">
                <AppDatePicker
                  ref={expiryDateRef}
                  calendarType={formik.values.calendarType}
                  name="expiryDate"
                  placeholder={t('Choose a Date')}
                  inputClass={`${
                    formik.values.expiryDate ? 'max-w-24' : ' max-w-28'
                  } bg-transparent border-0 text-gray-6 font-bold  p-0 text-primary`}
                  calendarPosition="bottom"
                  value={formik.values.expiryDate}
                  onChange={(val) => {
                    formik.setFieldValue(
                      'expiryDate',
                      formateDateToYYYYMMDD(val, formik.values.calendarType),
                    );
                  }}
                />
                {!formik.values.expiryDate && (
                  <AppIcon className="fa-regular fa-angle-down text-xl text-gray-6" />
                )}
                {formik.values.expiryDate && (
                  <button type="button" data-id="clear-expiryDate" className="hover:text-danger">
                    <AppIcon className="fa-regular fa-circle-xmark text-xl" />
                  </button>
                )}
              </div>
            </div>
            {formik.errors.expiryDate && formik.touched.expiryDate && (
              <small className="text-danger">
                {t(formik.errors.expiryDate, { ns: 'commonV2' })}
              </small>
            )}
          </div>

          <div>
            <div
              onClick={(e) => {
                if (!e.target.closest('[data-id="clear-dateOfBirth"]')) {
                  dateOfBirthRef.current.openCalendar();
                } else {
                  formik.setFieldValue('dateOfBirth', '');
                }
              }}
              className="flex h-12 cursor-pointer items-center justify-between rounded-lg bg-white px-4"
            >
              <div className="flex items-center gap-2">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-2">
                  <AppIcon className="fa-regular fa-calendar-lines text-primary" />
                </div>
                <AppBody pClass="Body1Bold">{t('Date of Birth')}</AppBody>
              </div>
              <div className="flex items-center">
                <AppDatePicker
                  maxDate={new Date()}
                  ref={dateOfBirthRef}
                  calendarType={formik.values.calendarType}
                  placeholder={t('Choose a Date')}
                  inputClass={`${
                    formik.values.dateOfBirth ? 'max-w-24' : ' max-w-28'
                  } bg-transparent border-0 text-gray-6 font-bold  p-0 text-primary`}
                  calendarPosition="bottom"
                  value={formik.values.dateOfBirth}
                  onChange={(val) => {
                    formik.setFieldValue(
                      'dateOfBirth',
                      formateDateToYYYYMMDD(val, formik.values.calendarType),
                    );
                  }}
                />

                {!formik.values.dateOfBirth && (
                  <AppIcon className="fa-regular fa-angle-down text-xl text-gray-6" />
                )}
                {formik.values.dateOfBirth && (
                  <button type="button" data-id="clear-dateOfBirth" className="hover:text-danger">
                    <AppIcon className="fa-regular fa-circle-xmark text-xl" />
                  </button>
                )}
              </div>
            </div>

            {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
              <small className="text-danger">
                {t(formik.errors.dateOfBirth, { ns: 'commonV2' })}
              </small>
            )}
          </div>
        </div>

        <div className="mb-5">
          <span className="mb-1 block text-xl font-bold">
            {t('Upload')} {t(selectedIdTypeLabel)}
          </span>

          <AppUpload
            isLoading={false}
            width="100%"
            name="idFile"
            height={190}
            onChange={({ FILE }) => {
              formik.setFieldValue('idFile', FILE);
            }}
          />
          {formik.errors.idFile && formik.touched.idFile && (
            <small className="text-danger">{t(formik.errors.idFile, { ns: 'commonV2' })}</small>
          )}
        </div>

        <div className="flex justify-end">
          <AppButton
            disabled={!formik.isValid}
            // isLoading={loaders.xxx}
            type="submit"
            size="lg"
            className="mb-6 w-full lg:w-44"
            button="primary"
            rounded="xl"
          >
            {t('Next', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </form>

      {isDev() && (
        <DeveloperComponent>
          <pre>{JSON.stringify(formik.values, null, 4)}</pre>
        </DeveloperComponent>
      )}
    </div>
  );
}

export default EmployeeIdentityVerificationForm;
