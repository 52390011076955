import { useTranslation } from 'react-i18next';

function PeriodSelection({ setDaysFilter }) {
  const { t } = useTranslation(['transactions']);

  return (
    <div className="flex flex-row items-baseline justify-end">
      <span className="text-sm ltr:pr-1 rtl:pl-1">{t('show_data_for')}</span>
      <select
        className="border-0 bg-transparent px-5 text-sm text-primary"
        onChange={(evt) => {
          setDaysFilter(evt.target.value);
        }}
      >
        <option value={30}>{t('last_30')}</option>
        <option value={60}>{t('last_60')}</option>
        <option value={180}>{t('last_180')}</option>
      </select>
    </div>
  );
}

export default PeriodSelection;
