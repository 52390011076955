import { useTranslation } from 'react-i18next';
import SuccessCheckAnimation from '../../assets/lotties/SuccessCheckAnimation2.json';
import AppLottie from '../../components/AppLottie';
import { AppHeader } from '../../components/html/html';

function DocumentUploadSuccess() {
  const { t } = useTranslation('uploadDocuments');
  return (
    <div className="h-full p-1 xs:p-5 lg:p-16">
      <div className="mx-auto flex h-full items-center justify-center rounded-2xl border bg-gray-1 p-5 shadow-lg lg:w-[90%] lg:p-8">
        <div>
          <div className="mx-auto sm:w-[60%] md:w-[40%]">
            <AppLottie animationData={SuccessCheckAnimation} />
          </div>

          <div className="mx-auto lg:w-[70%]">
            <AppHeader h="h1" className="mb-3 text-center">
              {t('Successful!')}
            </AppHeader>

            <AppHeader h="h6" className="text-center text-gray-6">
              {t(
                'Your documents has been successfully submitted to our compliance team for review. You are just one step away from transforming your financial experience',
              )}
            </AppHeader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentUploadSuccess;
