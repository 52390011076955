import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import AppSpinner from '../../../../../../../components/AppSpinner';
import useSimplifi from '../../../../../../../hooks/useSimplifi';
import './ChangePinNumber.css';

function ChangePinNumber() {
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);

  const cardId = useMemo(() => {
    return settingsPopupCardDetails?.id;
  }, [settingsPopupCardDetails]);

  const userId = useMemo(() => {
    return settingsPopupCardDetails?.cardholder?.id;
  }, [settingsPopupCardDetails]);

  const { iframe, isSimplifiLoading } = useSimplifi({
    cardId,
    userId,
    simplifiAction: 'setPin',
  });

  return (
    <>
      {isSimplifiLoading && <AppSpinner />}

      {iframe}
    </>
  );
}

export default ChangePinNumber;
