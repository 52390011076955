import { useTranslation } from 'react-i18next';
import emailSent from '../../../assets/png/emailSent.png';
import { AppBody, AppHeader } from '../../../components/html/html';

function UserForgetPasswordEmailSent() {
  const { t } = useTranslation('forgetPasswordV2');

  return (
    <div className="flex h-full items-center justify-center text-center">
      <div className="max-w-[550px] px-2 xxs:px-4">
        <div className="mb-12 flex justify-center">
          <img src={emailSent} alt="Already Activated Account" />
        </div>

        <AppHeader h="h6" className="mb-5">
          {t('Check your email for further instructions')}
        </AppHeader>

        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('We have sent password recovery instructions to your email.')}
        </AppBody>
      </div>
    </div>
  );
}

export default UserForgetPasswordEmailSent;
