import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/upload.json';

function UploadLottie({ isStopped, isPaused, ...rest }) {
  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="controlled">
      <Lottie
        options={defaultOptions}
        height={150}
        width={150}
        isStopped={isStopped}
        isPaused={isPaused}
        {...rest}
      />
    </div>
  );
}

export default UploadLottie;
