import nqoodWireLogo from '../../../../assets/lotties/nqoodWireLogo.json';
import nqoodWordLogo from '../../../../assets/lotties/nqoodWordLogo.json';
import AppLottie from '../../../../components/AppLottie';

function NqoodCardAnimation() {
  return (
    <div
      style={{ direction: 'ltr' }}
      className="relative h-[200px] w-[350px] overflow-hidden rounded-xl bg-white shadow-md"
    >
      <div className="absolute left-4 top-6">
        <AppLottie animationData={nqoodWordLogo} />
      </div>
      <div className="absolute top-[50%] -translate-y-[50%] translate-x-[80%] rotate-180">
        <AppLottie animationData={nqoodWireLogo} />
      </div>
    </div>
  );
}

export default NqoodCardAnimation;
