import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCopy } from 'react-icons/lu';
import { PiTriangleFill } from 'react-icons/pi';

function CopyToClipboard({ text }) {
  const { t } = useTranslation('commonV2');
  const [tooltipText, setTooltipText] = useState('Copy');
  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = () => {
    setTooltipText('Copied!');

    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  };

  return (
    <button
      className="relative"
      onFocus={() => {
        setShowTooltip(true);
      }}
      onMouseOver={() => {
        setShowTooltip(true);
      }}
      onBlur={() => {
        setShowTooltip(false);
        setTooltipText('Copy');
      }}
      onMouseOut={() => {
        setShowTooltip(false);
        setTooltipText('Copy');
      }}
      type="button"
      onClick={copyToClipboard}
    >
      <LuCopy />
      <div
        style={{
          visibility: showTooltip ? 'visible' : 'hidden',
        }}
        className="absolute bottom-6 left-[50%] -translate-x-[50%] whitespace-nowrap rounded-full bg-black px-2 py-1 text-center text-xs text-white"
      >
        {t(tooltipText)}
        <PiTriangleFill className="absolute bottom-0 left-[50%] -translate-x-[50%] translate-y-[80%] rotate-180" />
      </div>
    </button>
  );
}

export default CopyToClipboard;
