import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import Intercom from '@intercom/messenger-js-sdk';
import AppRouter from './AppRouter';
import { history } from './AppRouter/history';
import AppRoutes from './AppRoutes';
import AppModal from './components/AppModal';
import AppToaster from './components/AppToaster';
import Header from './components/Header/Header';
import UseMockButton from './components/UseMockButton';
import './i18n/config';
import NotificationsArea from './modules/Notifications/NotificationsArea';
import { updateIsMobileState } from './redux/AppConfigReducer/AppConfigActions';
import {
  clearUserDataAction,
  getUserDataAction,
  logOutAction,
  setRedirectURLAction,
} from './redux/AuthReducer/AuthActions';

function App() {
  const { appConfig } = useSelector((state) => state);
  const { user, userToken } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation(['common']);
  const dispatch = useDispatch();

  const handleOnIdle = () => {
    const pathName = window.location.pathname;
    const searchQueries = window.location.search;

    if (!pathName.includes('auth')) {
      dispatch(setRedirectURLAction(pathName + searchQueries));
    }

    dispatch(logOutAction());
  };

  const updateMedia = useCallback(() => {
    dispatch(updateIsMobileState(window.innerWidth <= 1023));
  }, []);

  const redirectNotLoggedInUser = useCallback(() => {
    const pathName = window.location.pathname;
    const searchQueries = window.location.search;

    const allowedPaths = [
      'verify_email',
      'reset_verify_phone',
      'verify_phone',
      'logout',
      'forget_password',
      'forget-password',
      'reset_password',
      'forget_password_thanks',
      'compliance',
      'signup',
      'complete-registration',
    ];

    const isAllowedPath = allowedPaths.some((path) => pathName.includes(path));
    history.push(isAllowedPath ? pathName + searchQueries : '/auth/login');
  }, []);

  useEffect(() => {
    document.lang = appConfig.lang;
    document.dir = appConfig.dir;
    document.body.dir = appConfig.dir;
    document.title = t('title');
    i18n.changeLanguage(appConfig.lang);
  }, [appConfig]);

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 minute
    // timeout: 5000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    if (!userToken) {
      dispatch(clearUserDataAction());
      redirectNotLoggedInUser();
    }
  }, [userToken]);

  useEffect(() => {
    let intervalId;
    if (userToken) {
      const dispatchGetUserDataAction = () => {
        dispatch(getUserDataAction());
      };

      dispatchGetUserDataAction();
      // 15 minutes 900000
      intervalId = setInterval(dispatchGetUserDataAction, 900000);
    }
    return () => clearInterval(intervalId);
  }, [userToken]);

  useEffect(() => {
    if (user) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_ID,
        user_id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        created_at: user.created_at,
        phone: user.mobile,
        company: {
          id: user.account_id,
          name: user.company_name,
        },
        user_type: user?.roles[0],
        hide_default_launcher: false,
        language_override: appConfig.lang === 'ar' ? 'ar' : 'en',
        alignment: appConfig.lang === 'ar' ? 'left' : 'right',
        user_hash: user.user_hash,
      });
    }
  }, [user, appConfig.lang]);

  useEffect(() => {
    updateMedia();

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="relative mx-auto h-screen max-w-[95vw] xl:max-w-[90vw] 2xl:max-w-[80vw]">
      {/* App Toasters */}
      <AppToaster />
      {/* App Modals */}
      <AppModal />

      {/* Mocks */}
      <UseMockButton />

      <NotificationsArea />
      <AppRouter history={history}>
        <Header />
        <AppRoutes />
      </AppRouter>
    </div>
  );
}

export default App;
