import {useNavigate} from 'react-router-dom';

import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import LoadingError from '../../components/LoadingError';
import UserPicture from '../../components/UserPicture';
import {apiGetRequest} from '../../helpers/APIHelper';
import {setAction} from '../../helpers/ActionsHelper';
import {formatDate} from '../../helpers/Utils';
import {SET_WIDGET_FUNDS} from '../../redux/FundsReducer';
import {AppBody} from "../../components/html/html";

const FundsWidget = function () {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const { widgetFunds } = useSelector((state) => state.funds);
  const [isLoading, setIsLoading] = useState(widgetFunds.length === 0);
  const [errored, setErrored] = useState(false);
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();

  const loadFunds = () => {
    apiGetRequest(
      'funds',
      cookies.token,
      {
        status_id: process.env.REACT_APP_OPEN_STATUS_ID,
        paginate: 4,
        page: 1,
        days: 30,
      },
      ['status', 'attachments'],
      {},
      true,
    )
      .then(([data, paginator]) => {
        dispatch(setAction(SET_WIDGET_FUNDS, data));
        setIsLoading(false);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  useEffect(loadFunds, []);

  if (errored && !isLoading) {
    return <LoadingError show retryCallback={loadFunds} />;
  }

  return (
    <div className="flex flex-col items-start justify-start p-5">
        <AppBody pClass="Body1Bold" className="pb-5 capitalize lg:text-xl">{t('fund_request')}</AppBody>
      {isLoading && <Skeleton count={3} containerClassName="w-full" />}
      {!isLoading && !errored && widgetFunds.length === 0 && (
        <div className="justify-middle flex w-full flex-grow">
          <AppBody pClass="Caption1Regular" type="span" className="my-5 w-full text-center text-gray-6">{t('no_fund')}</AppBody>
        </div>
      )}
      {widgetFunds.map((fund) => (
        <button
          type="button"
          onClick={() => {
            navigate(`funds/details/${fund.id}`);
          }}
          key={fund.id}
          className="flex flex-row justify-between self-stretch pb-5"
        >
          <div className="flex flex-row justify-start">
            <div className="ltr:pr-2 rtl:pl-2">
              <UserPicture
                initials={`${fund.requested_by && fund.requested_by.first_name[0]}${
                  fund.requested_by && fund.requested_by.last_name[0]
                }`}
              />
            </div>
            <div className="flex flex-col pt-2 ltr:text-left rtl:text-right">
              <AppBody pClass="Body2Regular">{`${fund.requested_by && fund.requested_by.first_name} ${
                fund.requested_by && fund.requested_by.last_name
              }`}</AppBody>
              <AppBody pClass="Caption1Regular" className="pt-1 text-gray-6">
                {formatDate(fund.created_at, 'd MMM yyyy')}
              </AppBody>
            </div>
          </div>
          <div className="pt-2 text-right">
            <AppBody pClass="Caption1Regular" className="text-gray-6" style={{ direction: 'ltr' }}>{`${parseFloat(
              fund.amount,
            ).toLocaleString()} ${fund.currency}`}</AppBody>
          </div>
        </button>
      ))}
    </div>
  );
};

export default FundsWidget;
