import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppTabs2 from '../../../components/AppTabs2';
import { AppHeader } from '../../../components/html/html';
import { setActiveProfileTabIndexAction } from '../../../redux/ProfileReducer/ProfileActions';
import CompanyAgreementContent from './CompanyAgreementContent';
import CompanyInformationContent from './CompanyInformationContent';
import FundingMethodContent from './FundingMethodContent';

function CompanyProfile() {
  const { t } = useTranslation('profileV2');
  const { activeProfileTabIndex } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const companyProfileTabs = useMemo(() => {
    return [
      {
        label: t('Company Information'),
        content: <CompanyInformationContent />,
        disabled: false,
        show: true,
      },
      {
        label: t('Funding Methods'),
        content: <FundingMethodContent />,
        disabled: false,
        show: true,
      },
      {
        label: t('Agreement'),
        content: <CompanyAgreementContent />,
        disabled: false,
        show: true,
      },
    ];
  }, [t]);

  useEffect(() => {
    dispatch(setActiveProfileTabIndexAction(0));
  }, []);

  return (
    <div className="my-2 h-[98%] overflow-y-auto rounded-lg border border-gray-5 p-6 lg:my-0">
      <AppHeader h="h6" className="mb-4">
        {t('Company Profile')}
      </AppHeader>

      <div className="flex flex-col gap-8 lg:flex-row lg:gap-2">
        <div className="lg:w-[20%]">
          <AppTabs2
            tabs={companyProfileTabs}
            defaultActiveTabIndex={activeProfileTabIndex}
            onTabChange={(tabIndex) => {
              dispatch(setActiveProfileTabIndexAction(tabIndex));
            }}
          />
        </div>
        <div className="lg:w-[80%]">{companyProfileTabs[activeProfileTabIndex]?.content}</div>
      </div>
    </div>
  );
}

export default CompanyProfile;
