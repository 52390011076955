/* eslint-disable no-nested-ternary */
import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Space from '../../../assets/png/space.png';
import { ReactComponent as BlackCheck } from '../../../assets/svg/black_check.svg';
import ActionButton from '../../../components/Button/ActionButton';
import { LargerTitle2 } from '../../../components/Titles';
import { apiGetRequest } from '../../../helpers/APIHelper';
import useNotifications from '../../../helpers/useNotifications';

function CompletionStep({ name, notCompletedText, completed, completionLink, sendEmail }) {
  const { t } = useTranslation(['signup']);
  const [cookies] = useCookies(['token']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, addNotifications] = useNotifications();

  const resendEmail = useCallback(() => {
    setIsSubmitting(true);
    apiGetRequest('users/email/resend', cookies.token)
      .then(() => {
        setIsSubmitting(false);
        addNotifications(t('complete_signup.success'));
      })
      .catch((err) => {
        addNotifications((err && err.data && err.data.error) || t('complete_signup.error'), false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, []);
  return (
    <>
      <div
        className={`col-span-1 flex flex-row items-baseline pb-2 ${
          completed ? 'text-gray-6 line-through' : ''
        }`}
      >
        <span>{name}</span>
        {sendEmail && (
          <ActionButton
            isLoading={isSubmitting}
            className="inline text-primary"
            onClick={resendEmail}
            type="button"
            text={t('complete_signup.resend')}
          />
        )}
      </div>
      <div className={`col-span-1 ${completed ? 'text-green-600' : 'text-gray-6'}`}>
        {completed ? (
          t('complete_signup.complete')
        ) : completionLink ? (
          <Link to={completionLink}>{t('complete_signup.complete_verification')}</Link>
        ) : (
          notCompletedText
        )}
      </div>
    </>
  );
}
function CompleteSignup() {
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation(['signup']);

  if (!user) {
    return null;
  }
  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-grow flex-row items-start justify-between">
        <img alt="Space" src={Space} className="h-80" />
        <div className="flex flex-grow flex-col justify-start ltr:pl-20 rtl:pr-20">
          <BlackCheck className="h-8 w-8" />
          <div className="pb-8 pt-5">
            <LargerTitle2>{t('complete_signup.well_done')}</LargerTitle2>
          </div>
          <p>{t('complete_signup.apply_account')}</p>
          <div className="grid grid-cols-2 pt-10">
            <CompletionStep name={t('complete_signup.complete_information')} completed />
            <CompletionStep
              name={t('complete_signup.verify_phone')}
              completed={user.phone_verified}
              notCompletedText={t('complete_signup.not_verified')}
              completionLink={!user.phone_verified && '/signup/verify_phone'}
            />
            <CompletionStep
              name={t('complete_signup.verify_email')}
              completed={user.email_verified}
              notCompletedText={t('complete_signup.not_verified')}
              sendEmail={!user.email_verified}
            />
            <CompletionStep
              name={t('complete_signup.verify_identity')}
              completed={user.yakeen_verified}
              notCompletedText={t('complete_signup.not_verified')}
              completionLink={user.email_verified && '/signup/verify_identity'}
            />
            <CompletionStep
              name={t('complete_signup.complete_company_info')}
              completed={user.account_verified}
              notCompletedText={t('complete_signup.not_verified')}
              completionLink={user.yakeen_verified && '/signup/verify_company'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteSignup;
