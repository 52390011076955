import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function SplitProgressBar({ splitRemaining = {}, transactionDetails }) {
  const { percentage, remaining } = splitRemaining;

  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const [statusClass, setStatusClass] = useState('');

  const splitStatus = {
    blue: {
      bgColor: 'bg-primary',
      textColor: percentage > 0 ? 'text-primary' : 'text-gray-6',
      text: (
        <div className="flex gap-1">
          <span>{t('Remaining Amount')}</span>
          <div className="flex gap-1 font-bold" style={{ direction: 'ltr' }}>
            <span>{remaining}</span>
            <span>{transactionDetails.transaction_currency}</span>
          </div>
        </div>
      ),
    },
    green: {
      bgColor: 'bg-green-600',
      textColor: 'text-green-600',
      text: (
        <div className="flex gap-1">
          <span>{t('You just')}</span>
          <div className="flex gap-1 font-bold">
            <span>{t('split all the amount!')}</span>
          </div>
        </div>
      ),
    },
    red: {
      bgColor: 'bg-red-500',
      textColor: 'text-red-500',
      text: (
        <div className="flex gap-1">
          <span>{t('You reach')}</span>
          <div className="flex gap-1 font-bold">
            <span>{t('the maximum amount!')}</span>
          </div>
        </div>
      ),
    },
  };

  useEffect(() => {
    if (+percentage === 100) {
      setStatusClass(splitStatus.green);
    }
    if (+percentage > 100) {
      setStatusClass(splitStatus.red);
    }
    if (+percentage < 100) {
      setStatusClass(splitStatus.blue);
    }
  }, [splitRemaining]);

  return (
    <>
      <div className={`text-bas mb-2 ${statusClass.textColor}`}>{statusClass.text}</div>

      <div className="h-5 w-full overflow-hidden rounded-md bg-stone-200">
        <div
          className={`h-5 rounded-md ${statusClass.bgColor}`}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </>
  );
}

export default SplitProgressBar;
