import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFirstAndLastDaysOfAMonthBasedOnAYear, monthsEnAndAr } from '../../../helpers/Utils';
import TranslatedData from '../../TranslatedData';
import TimeRangeFilterDesktop from './TimeRangeFilterDesktop';
import TimeRangeFilterMobile from './TimeRangeFilterMobile';

function TimeRangeFilter({ handleTimeRangeChange, activeTab }) {
  const { lang, isMobile } = useSelector((state) => state.appConfig);

  const timeRangeFilters = useMemo(() => {
    return [
      {
        label: <TranslatedData ar="آخر" en="Within Last" />,
        value: 'days',
      },
      {
        label: <TranslatedData ar="اليوم" en="Today" />,
        value: 'today',
      },
      {
        label: <TranslatedData ar="شهر" en="Month Of" />,
        value: 'monthOf',
      },
      {
        label: <TranslatedData ar="فترة زمنية محددة" en="Custom" />,
        value: 'custom',
      },
    ];
  }, []);

  const daysOptions = useMemo(() => {
    return [
      {
        label: (
          <>
            7 <TranslatedData ar="ايام" en="Days" />
          </>
        ),
        value: 7,
      },
      {
        label: (
          <>
            30 <TranslatedData ar="ايام" en="Days" />
          </>
        ),
        value: 30,
      },
      {
        label: (
          <>
            60 <TranslatedData ar="ايام" en="Days" />
          </>
        ),
        value: 60,
      },
      {
        label: (
          <>
            90 <TranslatedData ar="ايام" en="Days" />
          </>
        ),
        value: 90,
      },
      {
        label: (
          <>
            180 <TranslatedData ar="ايام" en="Days" />
          </>
        ),
        value: 180,
      },
    ];
  }, []);

  const monthsOptions = useMemo(() => {
    const serialized = monthsEnAndAr.map((ele) => {
      return {
        ...ele,
        label: <TranslatedData ar={ele.name_ar} en={ele.name} />,
        value: ele.value,
      };
    });
    return serialized;
  }, []);

  const yearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const fromYear = currentYear - 20;
    const years = [];

    Array.from({ length: currentYear - fromYear + 1 }, (_, index) => fromYear + index)
      .reverse()
      .forEach((year) => {
        years.push({ value: year, label: year });
      });
    return years;
  }, []);

  const [filterType, setFilterType] = useState(timeRangeFilters[0]);
  const [days, setDays] = useState(daysOptions[1]);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [customFrom, setCustomFrom] = useState('');
  const [customTo, setCustomTo] = useState('');

  const resetTimeRangeFilter = useCallback(() => {
    setFilterType(timeRangeFilters[0]);
    setDays(daysOptions[1]);
    setCustomFrom('');
    setCustomTo('');
    setMonth(null);
    setYear(null);
  }, []);

  const updateTimeRange = useCallback(() => {
    if (filterType?.value === 'days') {
      handleTimeRangeChange({ days: days.value });
    } else if (filterType?.value === 'today') {
      handleTimeRangeChange({ days: 1 });
    } else if (filterType?.value === 'monthOf' && month?.value && year?.value) {
      const [firstDay, lastDay] = getFirstAndLastDaysOfAMonthBasedOnAYear(month.value, year.value);
      handleTimeRangeChange({
        from_date: `${year.value}-${firstDay}`,
        to_date: `${year.value}-${lastDay}`,
      });
    } else if (filterType?.value === 'custom' && customFrom && customTo) {
      handleTimeRangeChange({ from_date: customFrom, to_date: customTo });
    }
  }, [filterType, days, month, year, customFrom, customTo]);

  useEffect(() => {
    if (!isMobile) {
      updateTimeRange();
    }
  }, [filterType, days, month, year, customFrom, customTo, lang, isMobile]);

  useEffect(() => {
    resetTimeRangeFilter();
  }, [activeTab]);

  return (
    <>
      {!isMobile && (
        <TimeRangeFilterDesktop
          timeRangeFilters={timeRangeFilters}
          filterType={filterType}
          setFilterType={setFilterType}
          customFrom={customFrom}
          setCustomFrom={setCustomFrom}
          customTo={customTo}
          setCustomTo={setCustomTo}
          daysOptions={daysOptions}
          days={days}
          setDays={setDays}
          monthsOptions={monthsOptions}
          month={month}
          setMonth={setMonth}
          yearsOptions={yearsOptions}
          year={year}
          setYear={setYear}
        />
      )}

      {isMobile && (
        <TimeRangeFilterMobile
          timeRangeFilters={timeRangeFilters}
          filterType={filterType}
          setFilterType={setFilterType}
          customFrom={customFrom}
          setCustomFrom={setCustomFrom}
          customTo={customTo}
          setCustomTo={setCustomTo}
          daysOptions={daysOptions}
          days={days}
          setDays={setDays}
          monthsOptions={monthsOptions}
          month={month}
          setMonth={setMonth}
          yearsOptions={yearsOptions}
          year={year}
          setYear={setYear}
          updateTimeRange={updateTimeRange}
        />
      )}
    </>
  );
}

export default TimeRangeFilter;
