import { useTranslation } from 'react-i18next';
import AppAlert from '../../../../components/AppAlert';
import { AppBody } from '../../../../components/html/html';

function AddFundMethodNote() {
  const { t } = useTranslation('walletV2');

  return (
    <AppAlert
      type="info"
      text={
        <AppBody pClass="Body2Medium">
          <span className="block">
            {t(
              'The process of approving the submitted funding method (the way you will transfer funds from your company bank account), which includes verifying and whitelisting the IBAN (the account number used for international transactions), takes 5 to 10 working days. You will be contacted by our team to confirm the details.',
            )}
          </span>
          <span className="block">
            {t(
              'It is not possible to transfer funds using this method until the account is approved.',
            )}
          </span>
        </AppBody>
      }
    />
  );
}

export default AddFundMethodNote;
