import { useState } from 'react';
import { ReactComponent as Checkmark } from '../../assets/svg/check.svg';
import { ReactComponent as Close } from '../../assets/svg/close.svg';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';

const IconButton = function (props) {
  const { close, onClick, text, className, iconHeight, iconWidth, disabled, setApproveInProgress } =
    props;
  const [actionRunning, setActionRunning] = useState(false);

  const wrappedOnClick = () => {
    setActionRunning(true);
    setApproveInProgress(true);
    onClick()
      .then((data) => {
        setActionRunning(false);
      })
      .catch((err) => {
        setActionRunning(false);
        setApproveInProgress(false);
      })
      .finally(() => {
        setApproveInProgress(false);
      });
  };
  let icon = <Checkmark height={iconHeight || 24} width={iconWidth || 24} />;

  if (close) {
    icon = <Close height={iconHeight || 24} width={iconWidth || 24} />;
  }

  return (
    <button
      type="button"
      className={`flex flex-row ${className || ''}`}
      onClick={wrappedOnClick}
      disabled={disabled || ''}
    >
      {icon}{' '}
      {text && (
        <span
          className={`text-md -mt-1 font-medium capitalize lg:text-lg ltr:pl-2 rtl:pr-2 ${
            close ? 'text-red-700' : 'text-green-600'
          }`}
        >
          {text}
        </span>
      )}
      {actionRunning && <Spinner className="h-6 w-6 animate-spin fill-lightGray" />}
    </button>
  );
};

export default IconButton;
