import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { inPages } from '../../helpers/constants';
import CardholderDescription from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/CardholderDescription';
import AppSpinner from '../AppSpinner';
import { AppBody, AppIcon } from '../html/html';
import TranslatedData from '../TranslatedData';
import CardProgramSchemeLogo from './CardProgramSchemeLogo';

function LoadUnloadDetails({
  transactionDetails,
  cardDetails,
  singleSelectedCardProgramDetails,
  inPage,
}) {
  const { t } = useTranslation('commonV2');

  const { loaders } = useSelector((state) => state);

  const isLoad = useMemo(() => {
    return transactionDetails.transaction_type.toLowerCase() === 'load';
  }, [transactionDetails]);

  const cardProgramLogo = useMemo(() => {
    if (inPage === inPages.myCards) {
      return cardDetails?.virtualCard?.cardproduct?.funding_source_type?.bank_logo;
    }
    if (inPage === inPages.wallet) {
      return singleSelectedCardProgramDetails.funding_source_type.bank_logo;
    }

    return '';
  }, [cardDetails, singleSelectedCardProgramDetails]);

  return (
    <div className="rounded-lg border border-gray-3 p-3">
      <AppBody pClass="Caption1Regular" type="div" className="text-gray-6">
        {!isLoad ? t('To') : t('From')}
      </AppBody>
      <div className="mb-2 flex items-start justify-between">
        <div className="flex flex-col gap-2">
          <AppBody pClass="Body2Bold" type="span">
            {loaders.getSingleTransactionCardDetailsAction && <AppSpinner size={16} />}

            {!loaders.getSingleTransactionCardDetailsAction && inPage === inPages.myCards && (
              <CardholderDescription
                item={transactionDetails}
                physicalCardData={cardDetails?.physicalCard}
                virtualCardData={cardDetails?.virtualCard}
              />
            )}
            {inPage === inPages.wallet && singleSelectedCardProgramDetails.description}
          </AppBody>
        </div>
        <div className="flex items-center gap-1">
          <span>
            <AppIcon
              iClass="XLargeFont"
              className={`${cardProgramLogo} ltr:float-right rtl:float-left`}
            />
          </span>
        </div>
      </div>
      <AppBody pClass="Caption1Regular" type="div" className="text-gray-6">
        {isLoad ? t('To') : t('From')}
      </AppBody>
      <div className="mb-2 flex items-start justify-between">
        <div className="flex flex-col">
          <AppBody pClass="Body2Bold" type="span">
            {`${transactionDetails?.user_id.first_name} ${transactionDetails?.user_id.last_name}`}
          </AppBody>
          <AppBody pClass="Body2Bold" type="span">
            <TranslatedData
              en={transactionDetails.department_id.name}
              ar={transactionDetails.department_id.name_ar}
            />
          </AppBody>
        </div>
        <div className="flex items-center gap-1">
          <span>
            <CardProgramSchemeLogo cardProgramScheme={transactionDetails?.network} />
          </span>
          <span>{transactionDetails?.card_last4}</span>
        </div>
      </div>
      <div className="mb-3 mt-4">
        <AppBody pClass="Body2Light" className="text-gray-6">
          # {transactionDetails.id}
        </AppBody>
      </div>
    </div>
  );
}

export default LoadUnloadDetails;
