import { truncate } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, translatedDataFunction } from '../../helpers/Utils';
import { inPages, transactionTabs } from '../../helpers/constants';
import usePermissions from '../../hooks/usePermissions';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import {
  getSingleTransactionDetailsAction,
  updateCardTransactionsAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';
import {
  getSingleWalletTransactionDetailsAction,
  updateWalletTransactionsAction,
} from '../../redux/WalletReducer/WalletActions';
import AppSelect from '../FormInputs/AppSelect';
import TranslatedData from '../TranslatedData';
import { AppBody, AppIcon } from '../html/html';

function ReceiptMerchantData({ transactionDetails, tags, getTagsAction, inPage, activeTab }) {
  const { appConfig, loaders } = useSelector((state) => state);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTag, setSelectedTag] = useState();
  const userCan = usePermissions();

  const dispatch = useDispatch();
  const { t } = useTranslation('cardsV2');
  const { auth } = useSelector((state) => state);

  const handleUpdateTag = useCallback(
    (tag) => {
      const formBody = new FormData();
      formBody.append('transaction_id', transactionDetails.id);
      formBody.append('tag_id', tag.value);

      http
        .post('transactions/tags', formBody, { loader: 'handleUpdateTag' })
        .then((res) => {
          dispatch(
            showToasterAction({ type: 'success', message: t('The tag was updated successfully!') }),
          );
          if (inPage === inPages.myCards) {
            dispatch(updateCardTransactionsAction(res.data));
            dispatch(getSingleTransactionDetailsAction(transactionDetails.id));
          }
          if (inPage === inPages.wallet) {
            dispatch(updateWalletTransactionsAction(res.data));
            dispatch(getSingleWalletTransactionDetailsAction(transactionDetails.id));
          }
        })
        .catch((err) => {
          dispatch(showToasterAction({ type: 'error', message: err.data.error }));
        });
    },
    [selectedTag, transactionDetails],
  );

  const canChangeTag = useMemo(() => {
    const canEditOwnTransactionTag =
      (userCan('add_own_transaction_tag') || userCan('edit_own_transaction_tag')) &&
      auth.user?.id === transactionDetails.user_id?.id;

    const canEditTransactionTag =
      userCan('add_account_transaction_tag') || userCan('edit_account_transaction_tag');

    return canEditOwnTransactionTag || canEditTransactionTag;
  }, [auth, transactionDetails]);

  useEffect(() => {
    if (tags && appConfig.lang) {
      const filteredTags = tags.filter((ele) => !ele.deleted);
      const serialized = filteredTags.map((ele) => {
        return {
          label: translatedDataFunction({ lang: appConfig.lang, en: ele.name, ar: ele.name_ar }),
          value: ele.id,
          color: ele.color,
        };
      });
      setTagOptions(serialized);

      setSelectedTag(serialized.find((ele) => ele.value === transactionDetails.tags?.id));
    }
  }, [tags, appConfig]);

  useEffect(() => {
    if (!tags) {
      dispatch(getTagsAction());
    }
  }, []);

  return (
    <div className="flex flex-col gap-1">
      {activeTab !== transactionTabs.LOADS && transactionDetails?.brand_name !== '' && (
        <AppBody pClass="Body1Bold" type="span">
          {transactionDetails?.brand_logo === '' && (
            <AppIcon iClass="Default" className="fa-kit fa-image ltr:pr-1 rtl:pl-1" />
          )}
          {transactionDetails?.brand_logo !== '' && (
            <img
              src={transactionDetails?.brand_logo}
              alt={transactionDetails?.brand_name}
              className="inline-block h-[18px] w-[18px] ltr:pr-1 rtl:pl-1"
            />
          )}
          {transactionDetails?.brand_name}
          <a href={transactionDetails?.brand_url} target="_blank" rel="noreferrer">
            <i
              className="fa-regular fa-link-simple fa-rotate-by text-primary"
              style={{ '--fa-rotate-angle': '-45deg' }}
            />
          </a>
        </AppBody>
      )}

      <AppBody pClass="Body1Bold" type="span">
        {transactionDetails.merchant_name}
      </AppBody>
      <span
        className="text-sm text-gray-6"
        title={TranslatedData({
          en: transactionDetails?.mcc?.name_en,
          ar: transactionDetails?.mcc?.name_ar,
        })}
      >
        <TranslatedData
          en={truncate(transactionDetails?.mcc?.name_en, 15)}
          ar={truncate(transactionDetails?.mcc?.name_ar, 15)}
        />
      </span>

      {activeTab !== transactionTabs.DECLINES && (
        <AppBody pClass="Body2Bold" type="span" className="flex items-center gap-1 text-gray-6">
          <span>
            <AppIcon iClass="BaseFont" className="fa-regular fa-tag" />
          </span>
          <AppSelect
            className="w-full text-xs"
            styleType="nude"
            isLoading={loaders.handleUpdateTag}
            value={selectedTag}
            onChange={(v) => {
              handleUpdateTag(v);
            }}
            options={tagOptions}
            isDisabled={!canChangeTag}
          />
        </AppBody>
      )}

      <div className="flex gap-1 text-sm text-gray-6">
        <span>{transactionDetails.merchant_country}</span>
        <span>{transactionDetails.merchant_city}</span>
      </div>
      <span className="text-sm text-gray-6">
        {formatDate(transactionDetails.transaction_timestamp, 'HH:mm')}
        &nbsp;&nbsp;&nbsp;
        {formatDate(transactionDetails.transaction_timestamp, 'DD MMMM YYYY')}
      </span>
    </div>
  );
}

export default ReceiptMerchantData;
