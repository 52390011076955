import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as InfoIcon} from '../../../assets/svg/info.svg';
import ActionButton from '../../../components/Button/ActionButton';
import BackButton from '../../../components/Button/BackButton';
import CustomDropDown, {useDropdown} from '../../../components/FormInputs/CustomDropDown';
import {NumberInput, TextArea, TextInput} from '../../../components/FormInputs/Inputs';
import LoadingError from '../../../components/LoadingError';
import StatBadge from '../../../components/StatBadge';
import {HeadlineCenter, LargerTitle2} from '../../../components/Titles';
import Dropzone from '../../../components/Upload/Dropzone';
import {apiGetRequest, apiPostFormDataRequest} from '../../../helpers/APIHelper';
import {setAction} from '../../../helpers/ActionsHelper';
import {cardBalance} from '../../../helpers/CardHelper';
import useNotifications from '../../../helpers/useNotifications';
import {UPDATE_FUND_ITEM} from '../../../redux/FundsReducer';
import Head from '../../Head';
import {AppBody} from "../../../components/html/html";

const FundRequestForm = function (props) {
  const { isEdit } = props;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [cookies] = useCookies(['token']);
  const [, addNotification] = useNotifications();
  const [editItem, setEditItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cards, setCards] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [balance, setBalance] = useState(0);
  const params = useParams();
  const fundId = params.id ?? null;

  const { t, i18n } = useTranslation(['funds', 'common']);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const fetchCardBalance = (cardId) => {
    cardBalance(cardId, cookies.token).then((response) => {
      setCurrency(response?.currency);
      setBalance(response.amount ? parseFloat(response.amount) : '');
    });
  };

  const fetchCards = () => {
    setIsLoading(true);
    apiGetRequest(`cardmanager/cards/${user.id}`, cookies.token, {
      status: 'ACTIVE',
    })
      .then((response) => {
        if (response) {
          setCards(response);
          if (response.length > 0) {
            fetchCardBalance(response[0].id);
          }
        } else {
          setCards([]);
        }
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCards();
    clearErrors();
  }, [i18n.language]);

  useEffect(() => {
    setValue('fundCurrency', currency);
  }, [currency]);

  const fetchFund = () => {
    setIsLoading(true);
    apiGetRequest(`funds/${parseInt(fundId, 10)}`, cookies.token)
      .then((response) => {
        setEditItem(response);
        setErrored(false);
        setIsLoading(false);
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (fundId) {
      fetchFund();
    }
  }, [fundId]);

  useEffect(() => {
    if (isEdit && fundId) {
      fetchFund();
      if (isEdit && editItem) {
        setValue('fundAmount', editItem.amount);
        setValue('fundReason', editItem.reason);
        setValue(
          'fundCurrency',
          editItem.card_id && editItem.card_id.cardproduct
            ? editItem.card_id.cardproduct.currency
            : editItem.currency,
        );
      }
    }
  }, [isEdit, fundId]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('card_id', selectedCard ? selectedCard.id : cards[0]?.id);
    formData.append('card_last4', selectedCard ? selectedCard.last_four : cards[0]?.last_four);
    formData.append('reason', data.fundReason);
    formData.append('amount', data.fundAmount);
    formData.append('currency', data.fundCurrency);

    if (isEdit) {
      formData.append('_method', 'PUT');
    }

    if (files) {
      files.forEach((file) => {
        formData.append('attachments[]', file);
      });
    }
    apiPostFormDataRequest(isEdit ? `funds/${params.id}` : 'funds', formData, cookies.token)
      .then((res) => {
        dispatch(setAction(UPDATE_FUND_ITEM, res));
        addNotification(t('added'));
        navigate(-1);
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (!cards) {
    return (
      <div className="mx-auto flex w-full flex-col lg:w-10/12">
        <Head title={`${isEdit ? 'Edit' : 'Add'} Fund Request`} />
        <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
          <div className="col-span-6 flex flex-col justify-start">
            {isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchCards} />
          </div>
        </div>
      </div>
    );
  }

  if (cards && cards.length === 0) {
    return (
      <div className="m-auto flex w-full flex-col text-center lg:w-8/12" style={{ marginTop: '0' }}>
        <Head title={`${isEdit ? 'Edit' : 'Add'} Fund Request`} />
        <img src="/images/card_create.png" className="m-auto mb-2 max-w-xs" />
        <HeadlineCenter>{t('no_card')}</HeadlineCenter>
        <button
          onClick={() => {
            navigate(`../my_cards/create-new-card`);
          }}
          className="m-auto mt-5 max-w-xs rounded-full border border-gray-3 bg-none px-5 py-1 font-bold"
          type="button"
        >
          {t('create_card', { ns: 'common' })}
        </button>
      </div>
    );
  }

  if (isEdit && !editItem) {
    return (
      <div className="mx-auto flex w-full flex-col lg:w-10/12">
        <Head title={`${isEdit ? 'Edit' : 'Add'} Fund Request`} />
        <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
          <div className="col-span-6 flex flex-col justify-start">
            {isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchFund} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto flex w-full flex-col lg:w-8/12 ltr:text-left rtl:text-right">
      <Head title={`${isEdit ? 'Edit' : 'Add'} Fund Request`} />
      <BackButton />
      <LargerTitle2>{isEdit ? t('edit_request1') : t('new_request1')}</LargerTitle2>
      {!isEdit && (
        <p className="text-gray-6">
          <InfoIcon className="inline" width="15" />
          <span className="px-2">{t('add_request_info')}</span>
        </p>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-7 flex flex-grow flex-col items-start justify-start self-stretch"
      >
        <div className="flex w-full flex-col justify-start">
          <div className="flex flex-grow flex-row self-start self-stretch pb-10">
            {!isLoading && !errored && (
              <Dropdown
                setSelectedCard={setSelectedCard}
                selectedCard={selectedCard}
                setCurrency={setCurrency}
                setBalance={setBalance}
                cards={cards}
                currency={currency}
                balance={balance}
              />
            )}
          </div>
          <div className="grid grid-cols-2 gap-10 pb-10">
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="fundAmount"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('request_amount')}{' '}
              <NumberInput
                error={errors.fundAmount}
                {...register('fundAmount', {
                  required: t('amount_required'),
                })}
                id="fundAmount"
                defaultValue={isEdit && editItem ? editItem.amount : ''}
              />
            </AppBody>

            <AppBody type="label" pClass="Body2Bold"
              htmlFor="fundCurrency"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('currency')}{' '}
              <TextInput
                {...register('fundCurrency', {
                  required: t('currency_required'),
                })}
                className={`${
                  errors.fundCurrency ? 'border-red-400' : 'border-gray-3'
                } mt-1 rounded-full border  px-20 py-1.5 disabled:bg-gray-100`}
                id="fundCurrency"
                defaultValue={currency}
                disabled
              />
            </AppBody>
          </div>
          <div className="flex flex-grow flex-row self-start self-stretch pb-10">
            <AppBody type="label" pClass="BodysBold"
              htmlFor="fundReason"
              className="flex flex-grow flex-col"
            >
              {t('reason')}{' '}
              <TextArea
                error={errors.fundReason}
                {...register('fundReason', {
                  required: t('reason_required'),
                })}
                id="fundReason"
                className={`${
                  errors.fundReason ? 'border-red-400' : 'border-gray-3'
                } mt-1 w-full resize-none self-stretch rounded-2xl border  p-5`}
                maxLength={255}
                defaultValue={isEdit && editItem ? editItem.reason : ''}
              />
            </AppBody>
          </div>
          <div className="pb-10">
            <Dropzone
              labelHint={t('upload_hint')}
              optionalLabel
              oldValue={isEdit && editItem ? editItem.attachments : null}
              setFile={setFiles}
              file={files}
              fileError={fileError}
              setFileError={setFileError}
              fileHint={t('rejectReason', { ns: 'common' })}
              rejectReason={t('rejectReason_simple', { ns: 'common' })}
              uploadInput={t('documents_or_photos', { ns: 'common' })}
              label={t('upload_receipt')}
              acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
            />
          </div>
        </div>
        <div className="mt-5 flex flex-row justify-end self-stretch pt-2">
          <ActionButton
            className="flex flex-row"
            isLoading={isSubmitting}
            primary
            text={isEdit ? t('save', { ns: 'common' }) : t('submit', { ns: 'common' })}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

function DropdownHeader({
  collapsed,
  setCollapsed,
  className,
  cards,
  selectedCard,
  currency,
  balance,
}) {
  return (
    <button
      type="button"
      onClick={() => {
        setCollapsed(!collapsed);
      }}
      className={`${className} z-10 ${cards.length === 1 ? 'cursor-default' : 'cursor-pointer'}`}
    >
      {selectedCard && (
        <DropdownCardItem
          card={cards.filter((card) => card.id === selectedCard.id)[0]}
          currency={currency}
          balance={balance}
        />
      )}
      {!selectedCard && (
        <DropdownCardItem
          card={cards.length > 0 ? cards[0] : null}
          currency={currency}
          balance={balance}
        />
      )}
    </button>
  );
}

function DropdownCardItem({ card, balance, currency }) {
  const { t } = useTranslation(['topup']);

  if (card === null) {
    return null;
  }
  return (
    <AppBody type="div" pClass="Body2Regular" className="bg-white-border z-40 flex flex-row justify-between self-stretch rounded-b-2xl bg-opacity-20 p-4 bg-blend-multiply ltr:pr-10 rtl:pl-10">
      <span>
        <strong className="ltr:pr-10 rtl:pl-10">
          {card.is_physical ? t('plastic_card') : t('virtual_card')}
          <span className="hidden lg:inline">
            {' '}
            **
            {card.last_four}
          </span>
        </strong>
        {card.status && (
          <StatBadge
            status={card.status.name}
            bgColor={card.status.button_color}
            color={card.status.color}
          />
        )}
        <span className="block lg:hidden ltr:text-left rtl:text-right">
          {' '}
          **
          {card.last_four}
        </span>
      </span>
      {currency && balance && (
          <AppBody type="span" pClass="Body1Bold" style={{ direction: 'ltr' }}>
          {balance} {currency}
          </AppBody>
      )}
    </AppBody>
  );
}

function DropdownChild({
  setCollapsed,
  collapsed,
  setSelectedCard,
  selectedCard,
  cards,
  setCurrency,
  setBalance,
}) {
  const [cookies] = useCookies(['token']);
  return (
    <div className="mt-8 flex flex-col self-stretch border-b border-gray-3 bg-white">
      {cards.map((card, i) => {
        if ((selectedCard && card.id === selectedCard.id) || (!selectedCard && i === 0)) {
          return null;
        }

        return (
          <button
            onClick={async () => {
              setSelectedCard(card);
              await cardBalance(card.id, cookies.token).then((response) => {
                setCurrency(response?.currency);
                setBalance(response.amount ? parseFloat(response.amount) : '');
              });
              setCollapsed(!collapsed);
            }}
            type="button"
          >
            <DropdownCardItem card={card} />
          </button>
        );
      })}
    </div>
  );
}

function Dropdown({
  setSelectedCard,
  cards,
  selectedCard,
  setCurrency,
  setBalance,
  currency,
  balance,
}) {
  const { collapsed, setCollapsed, wrapperRef } = useDropdown();
  return (
    <div className="relative flex w-full flex-row rounded-xl bg-white" ref={wrapperRef}>
      <CustomDropDown
        collapsed={collapsed}
        innerWrapperClassName="w-full mt-1 z-40"
        setCollapsed={setCollapsed}
        wrapperRef={wrapperRef}
        header={
          <DropdownHeader
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            cards={cards}
            selectedCard={selectedCard}
            currency={currency}
            balance={balance}
          />
        }
      >
        {cards.length > 0 && (
          <DropdownChild
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            setSelectedCard={setSelectedCard}
            setCurrency={setCurrency}
            setBalance={setBalance}
            cards={cards}
            selectedCard={selectedCard}
          />
        )}
      </CustomDropDown>
      {collapsed && cards.length > 1 && (
        <strong
          className="absolute px-2 text-lg text-gray-6 ltr:right-1 rtl:left-1"
          style={{ top: '0.8rem' }}
        >
          &#9660;
        </strong>
      )}
      {!collapsed && cards.length > 1 && (
        <strong
          className="absolute px-2 text-lg text-gray-6 ltr:right-1 rtl:left-1"
          style={{ top: '0.8rem' }}
        >
          &#9650;
        </strong>
      )}
    </div>
  );
}

export default FundRequestForm;
