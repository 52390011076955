import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../components/Button/IconButton';
import { AppBody } from '../../../components/html/html';
import usePermissions from '../../../hooks/usePermissions';
import { RejectStatus } from './Common';
import { isApprovalStatus } from './Helper';

function FundApproveDecline({ filteredFund, approveAction, rejectAction }) {
  const userCan = usePermissions();
  const [approveInProgress, setApproveInProgress] = useState(false);
  const { t } = useTranslation(['funds', 'common']);

  return (
    <>
      {filteredFund.status && parseInt(filteredFund.status.id, 10) === 3 && (
        <AppBody
          type="div"
          pClass="Body1Bold"
          className="mt-2 flex w-full flex-col justify-between py-1 text-red-700 lg:flex-row"
        >
          <RejectStatus text={filteredFund.status.name} />
          <div className="flex flex-col justify-center rounded-xl bg-red-50 px-4 ltr:text-left rtl:text-right">
            <p className="py-2 text-center font-normal text-red-700 lg:py-1">
              {filteredFund.rejection_reason}
            </p>
          </div>
        </AppBody>
      )}
      {userCan('update_account_funds_status') && !isApprovalStatus(filteredFund.status_id) && (
        <div className="flex w-full flex-row justify-end py-1">
          <div className="ltr:pr-5 rtl:pl-5">
            <IconButton
              onClick={rejectAction}
              close
              text={t('decline')}
              disabled={!!approveInProgress}
              setApproveInProgress={setApproveInProgress}
            />
          </div>
          <div>
            <IconButton
              setApproveInProgress={setApproveInProgress}
              onClick={approveAction}
              text={t('approve')}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default FundApproveDecline;
