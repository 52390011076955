import { randomId } from 'javascript-functions';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OTPInput from '../../../../components/FormInputs/OTPInput';
import OTPResendTimer from '../../../../components/FormInputs/OTPResendTimer';
import { AppBody, AppHeader } from '../../../../components/html/html';
import { getServerErrMessage } from '../../../../helpers/Utils';
import { cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  addOTPDataAction,
  setActiveCreateNewCardStepAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';

function SendOTP() {
  const { loaders } = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  const { selectedCardType, userPrimaryCard, cardToReplace } = useSelector(
    (state) => state.createNewCard,
  );

  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();
  const [OTPToken, setOTPToken] = useState('');
  const [OTPInputKey, setOTPInputKey] = useState(randomId());

  const sendAnOPT = useCallback(() => {
    setOTPInputKey(randomId());
    http
      .post(`users/otp/send`, null, { loader: 'sendAnOPT' })
      .then((res) => {
        setOTPToken(res.data.token);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  const verifyOTP = useCallback(
    (code) => {
      const formData = new FormData();
      formData.append('token', OTPToken);
      formData.append('code', code);
      formData.append('notDeleted', 1);

      http
        .post(`users/otp/verify`, formData, { loader: 'verifyOTP' })
        .then(() => {
          const OTP_DATA = { code, OTPToken };
          dispatch(addOTPDataAction(OTP_DATA));
          if (cardToReplace) {
            // ========== Replace PHYSICAL Card ========== //
            if (cardToReplace.is_physical) {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.plasticCardCreation));
            }
            // ========== Replace VIRTUAL Card ========== //
            if (!cardToReplace.is_physical) {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardActivation));
            }
            // ========== Create PHYSICAL Card ========== //
          } else if (selectedCardType.name === cardTypesKeys.PHYSICAL && userPrimaryCard) {
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.plasticCardCreation));
          } else {
            // ========== Create VIRTUAL Card ========== //
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.virtualCardActivation));
          }
        })
        .catch((err) => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: getServerErrMessage(err),
            }),
          );
        });
    },
    [OTPToken, userPrimaryCard, cardToReplace],
  );

  useEffect(() => {
    sendAnOPT();
  }, []);

  return (
    <div className="mx-auto mt-[25vh] max-w-[400px] px-5 pb-5 sm:px-0">
      <div className="mb-8">
        <AppHeader h="h3">{t('One - Time Password', { ns: 'commonV2' })}</AppHeader>
        <AppBody pClass="Body2Medium" className="text-gray-6">
          <span className="me-1">
            {t('We Sent You One Time Authorization Code To Your Mobile & Email')}
          </span>
          <span className="block text-start" style={{ direction: 'ltr' }}>
            <span>{user.mobile.slice(0, 4)}</span>
            <span>******</span>
            <span>{user.mobile.slice(-3)}</span>
          </span>
          <span>{user.email}</span>
        </AppBody>
      </div>

      <div>
        <AppHeader className="mb-3" h="h5">
          {t('Enter The Code', { ns: 'commonV2' })}
        </AppHeader>

        <div className="mb-6">
          <OTPInput
            key={OTPInputKey}
            isLoading={loaders.verifyOTP || loaders.createPlasticCard}
            count={5}
            onComplete={(code) => {
              verifyOTP(code);
            }}
          />
        </div>

        <div className="flex items-center gap-2">
          <AppBody pClass="Body2Medium" className="text-gray-6">
            {t("Didn't receive the one time code?", { ns: 'commonV2' })}
          </AppBody>
          <OTPResendTimer
            duration={180}
            onResend={() => {
              sendAnOPT();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SendOTP;
