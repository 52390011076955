import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../AppRouter/history';
import AppSpinner from '../../../components/AppSpinner';
import { AppBody, AppButton, AppHeader } from '../../../components/html/html';
import { showModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import { getUserInfoAction } from '../../../redux/ProfileReducer/ProfileActions';
import CompanyEditAddressPopup from './CompanyEditAddressPopup';

function CompanyInformationContent() {
  const { userInfo } = useSelector((state) => state.profile);
  const { isMobile } = useSelector((state) => state.appConfig);
  const { loaders } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation('profileV2');

  useEffect(() => {
    dispatch(getUserInfoAction());
  }, []);

  return (
    <>
      {loaders.getUserInfoAction && (
        <div className="h-[80vh] rounded-2xl border border-gray-5">
          <AppSpinner />
        </div>
      )}

      {!loaders.getUserInfoAction && (
        <div className="grid gap-6">
          {/* ============= Company Information ============= */}
          <div className="rounded-2xl border-gray-5 lg:border lg:p-5">
            <AppHeader h="h6" className="mb-6">
              {t('Company Information')}
            </AppHeader>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Short Company Name')}
            </AppBody>
            <AppBody pClass="Body1Bold">{userInfo?.account.company_short_name}</AppBody>
          </div>

          {/* ============= Company KYB ============= */}
          <div className="rounded-2xl border border-gray-5 p-5">
            <AppHeader h="h6" className="mb-6">
              {t('Company KYB', { ns: 'profileV2' })}
            </AppHeader>
            <div className="grid gap-6 lg:grid-cols-2">
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Number of Employees')}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.employee_count}</AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Average annual revenue')}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.avg_annual_revenue}</AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Average spend monthly')}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.avg_spend_monthly}</AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Company Website')}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.website}</AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Telephone Number')}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.mobile}</AppBody>
              </div>
            </div>
          </div>

          {/* ============= Address ============= */}
          <div className="rounded-2xl border border-gray-5 p-5">
            <div className="flex justify-between">
              <AppHeader h="h6" className="mb-6">
                {t('Address', { ns: 'commonV2' })}
              </AppHeader>

              <AppButton
                button="link"
                className="font-bold text-primary"
                bClass="ButtonHeader1"
                onClick={() => {
                  if (!isMobile) {
                    dispatch(showModalAction(<CompanyEditAddressPopup />));
                  } else {
                    history.push('/dashboard/profile/company/edit-address');
                  }
                }}
              >
                {t('Edit', { ns: 'commonV2' })}
              </AppButton>
            </div>

            <div className="grid gap-6 lg:grid-cols-2">
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Country', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.address?.country?.name}</AppBody>
              </div>
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('City/State', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.address?.city?.name}</AppBody>
              </div>
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Postal Code', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.address?.postal_code}</AppBody>
              </div>
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Address Line 1', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.address?.address1}</AppBody>
              </div>
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Address Line 2', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.account?.address?.address2}</AppBody>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompanyInformationContent;
