import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBody, AppIcon } from '../../../../components/html/html';
import { usersStatus } from '../../../../helpers/constants';

function SingleUserStatusBadge({ userDetails, isLoading }) {
  const { t } = useTranslation('usersV2');

  const statusColors = useMemo(() => {
    return {
      [usersStatus.Active]: 'bg-info-light text-info',
      [usersStatus.Pending]: 'bg-gray-2 text-gray-6',
      [usersStatus.Suspended]: 'bg-warn-light text-warn',
      [usersStatus.Deactivate]: 'bg-danger-light text-danger',
      [usersStatus.Banned]: 'bg-gray-2 text-danger',
    };
  }, []);

  return (
    <AppBody
      pClass="Caption1Bold"
      className={`${
        statusColors[userDetails.status]
      } me-1 min-w-24 rounded-full px-2 py-1 text-center`}
    >
      {isLoading && <AppIcon iClass="XSmall" className="fa-regular fa-loader me-1 animate-spin" />}
      {t(userDetails.status)}
    </AppBody>
  );
}

export default SingleUserStatusBadge;
