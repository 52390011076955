/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import arabicCalendar from 'react-date-object/calendars/arabic';
import gregorianCalendar from 'react-date-object/calendars/gregorian';
import arabic_ar from 'react-date-object/locales/arabic_ar';
import arabic_en from 'react-date-object/locales/arabic_en';
import gregorian_ar from 'react-date-object/locales/gregorian_ar';
import gregorian_en from 'react-date-object/locales/gregorian_en';

import DatePicker from 'react-multi-date-picker';
import { useSelector } from 'react-redux';
import './AppDatePicker.css';

const AppDatePicker = forwardRef(
  (
    {
      onChange,
      maxDate,
      minDate,
      disabled,
      placeholder,
      calendarPosition,
      inputClass,
      calendarType,
      ...rest
    },
    ref,
  ) => {
    const calendars = useMemo(() => {
      return {
        english: {
          calendar: gregorianCalendar,
          locale: gregorian_en,
          localeAr: gregorian_ar,
        },
        arabic: {
          calendar: arabicCalendar,
          locale: arabic_en,
          localeAr: arabic_ar,
        },
      };
    }, []);

    const { appConfig } = useSelector((state) => state);
    const [currentCalendar, setCurrentCalendar] = useState(calendars.english);

    const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const weekDaysAr = ['ح', 'ث', 'ث', 'ر', 'خ', 'ج', 'س'];

    useEffect(() => {
      if (calendarType === 'Hijri') {
        setCurrentCalendar(calendars.arabic);
      }
      if (calendarType === 'Georgian') {
        setCurrentCalendar(calendars.english);
      }
    }, [calendarType]);

    return (
      <div id="AppDatePickerClassic">
        <DatePicker
          ref={ref}
          containerClassName="w-full"
          inputClass={
            inputClass ||
            'text-xs h-10 w-full rounded-xl text-black border-gray-3 focus:border-primary disabled:cursor-not-allowed disabled:opacity-50 focus:border-2'
          }
          calendar={currentCalendar.calendar}
          locale={appConfig.lang === 'en' ? currentCalendar.locale : currentCalendar.localeAr}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          placeholder={placeholder}
          weekDays={appConfig.lang === 'en' ? weekDays : weekDaysAr}
          fixMainPosition
          calendarPosition={calendarPosition || 'left-start'}
          {...rest}
        />
      </div>
    );
  },
);

export default AppDatePicker;
