import {useSelector} from 'react-redux';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {formatDate} from '../../../../../../helpers/Utils';
import TranslatedData from '../../../../../../components/TranslatedData';
import {AppBody, AppHeader, AppIcon} from '../../../../../../components/html/html';

function WalletFundActivityPopupContent() {
  const {
    showWalletFundsActivityFormPopup,
    singleWalletCardActivityDetails,
    singleSelectedCardProgramDetails,
  } = useSelector((state) => state.wallet);

  const { t } = useTranslation(['walletV2', 'commonV2']);

  const functionType = useMemo(() => {
    return singleWalletCardActivityDetails?.function_key.toLowerCase();
  });

  return (
    <div>
      {!showWalletFundsActivityFormPopup && (
        <div className="mt-2 grid gap-2">
          <div className="mb-2 flex items-center justify-center gap-0.5">
            <AppHeader h="h5" className="text-center">
              {functionType === 'card_load' && t('Card Load Details')}
              {functionType !== 'card_load' && t('Card Unload Details')}
            </AppHeader>
          </div>
          <AppBody
              pClass="Body2Medium" type="span" className="text-center">
            {singleWalletCardActivityDetails?.id}
          </AppBody>
          <div className="mx-auto w-2/3 break-words text-center font-bold">
            <AppBody
              pClass="Body2Medium"
              type="span"
              className="block"
              style={{ color: singleWalletCardActivityDetails?.status?.color }}
            >
              {singleWalletCardActivityDetails?.status?.name}
            </AppBody>
          </div>

          <div className="flex flex-col justify-between self-stretch border-b-4 border-gray-2 border-opacity-40 px-1 py-1">
            <div className="my-2 w-full">
              <AppBody pClass="Body2Regular" className="block text-gray-6">
                <TranslatedData
                  en={singleSelectedCardProgramDetails?.issuer_country.name}
                  ar={singleSelectedCardProgramDetails?.issuer_country.name_ar}
                />
              </AppBody>
              <AppBody pClass="Body2Regular" className="block text-gray-6">
                {formatDate(singleWalletCardActivityDetails?.created_at, 'HH:mm  DD MMMM YYYY')}
              </AppBody>
            </div>
            <div className="rounded-lg border border-gray-3 p-3">
              <AppBody pClass="Caption1Regular" className="text-gray-6">
                {t('Amount')}
              </AppBody>
              <div className="mb-2 flex items-start justify-between">
                <div className="flex flex-col gap-2">
                  <AppBody pClass="Body2Bold" type="span">
                    {`${singleWalletCardActivityDetails?.description?.amount} ${singleWalletCardActivityDetails?.description?.currency}`}
                  </AppBody>
                </div>
              </div>

              <AppBody pClass="Caption1Regular" type="div" className="text-gray-6">
                {functionType !== 'card_load'
                  ? t('To', { ns: 'commonV2' })
                  : t('From', { ns: 'commonV2' })}
              </AppBody>
              <div className="mb-2 flex items-start justify-between">
                <div className="flex flex-col gap-2">
                  <AppBody pClass="Body2Bold" type="span">
                    {singleSelectedCardProgramDetails?.description}
                  </AppBody>
                </div>
                <div className="flex items-center gap-1">
                  <span>
                    <AppIcon iClass="XLargeFont"
                      className={`${singleSelectedCardProgramDetails?.funding_source_type.bank_logo} ltr:float-right rtl:float-left`}
                    />
                  </span>
                </div>
              </div>
              <AppBody pClass="Caption1Regular" type="div" className="text-gray-6">
                {functionType === 'card_load'
                  ? t('To', { ns: 'commonV2' })
                  : t('From', { ns: 'commonV2' })}
              </AppBody>
              <div className="mb-2 flex items-start justify-between">
                <div className="flex flex-col">
                  <AppBody pClass="Body2Bold" type="span">
                    {`${singleWalletCardActivityDetails?.description.name_on_card}`}
                  </AppBody>
                </div>
                <div className="flex items-center gap-1">
                  <span>{singleWalletCardActivityDetails?.description.card_last_four}</span>
                </div>
              </div>
              <div className="mb-3 mt-4">
                <AppBody pClass="Body2Light" className="text-gray-6">
                  #{' '}
                  {singleWalletCardActivityDetails?.description?.transaction_id !== ''
                    ? singleWalletCardActivityDetails.description.transaction_id
                    : 'NV'}
                </AppBody>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletFundActivityPopupContent;
