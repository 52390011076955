import {useTranslation} from 'react-i18next';
import Wrench from '../assets/png/Wrench.png';
import {AppBody} from "./html/html";

function LoadingError(props) {
  const { show, retryCallback, simple } = props;
  const { t } = useTranslation(['common']);

  if (!show) {
    return null;
  }

  if (simple) {
    return (
      <button
        type="button"
        className="mx-1 text-sm font-semibold text-primary underline"
        onClick={retryCallback}
      >
        {t('retry')}
      </button>
    );
  }
  return (
    <AppBody type="div" pClass="Body2Regular" className="mx-auto flex w-full flex-col justify-center text-gray-6">
      <img alt="try" src={Wrench} className="self-center" />
      <p className="text-center">{t('something_wrong')}</p>
      <button
        type="button"
        className="font-semibold text-primary underline"
        onClick={retryCallback}
      >
        {t('retry')}
      </button>
    </AppBody>
  );
}

export default LoadingError;
