import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import nqoodBG from '../../../assets/png/nqoodBG.png';
import '../NotRobot.css';
import UserForgetPasswordEmailSent from './UserForgetPasswordEmailSent';
import UserForgetPasswordForm from './UserForgetPasswordForm';

function UserForgetPassword() {
  const { t } = useTranslation('forgetPasswordV2');
  const { loaders } = useSelector((state) => state);
  const { lang } = useSelector((state) => state.appConfig);
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // ...
  }, [lang]);

  return (
    <div
      className="h-full bg-gray-1 bg-cover bg-no-repeat p-0 xxs:p-4 xs:p-7 md:p-12 lg:h-[98%] lg:rounded-xl lg:border lg:border-gray-5 lg:px-24 lg:py-9 xl:px-40"
      style={{ backgroundImage: `url(${nqoodBG})` }}
    >
      <div className="flex h-full items-center justify-center overflow-y-auto rounded-2xl bg-gray-1 shadow-xl">
        {!isEmailSent && <UserForgetPasswordForm setIsEmailSent={setIsEmailSent} />}

        {isEmailSent && <UserForgetPasswordEmailSent />}
      </div>
    </div>
  );
}

export default UserForgetPassword;
