import { appendURLParams, getURLParams } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../AppRouter/history';
import AppPagination from '../../../components/AppPagination';
import AppSpinner from '../../../components/AppSpinner';
import AppTabs2 from '../../../components/AppTabs2';
import { AppButton, AppHeader, AppIcon } from '../../../components/html/html';
import usePermissions from '../../../hooks/usePermissions';
import { hideModalAction, showModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import {
  clearUsersListAction,
  clearUsersListDepartmentsAction,
  getUserListDepartmentsAction,
  getUsersListAction,
  hideAllUsersListPopups,
  setActiveUserTabIndexAction,
} from '../../../redux/UsersReducer/UsersActions';
import AddUserPopup from './AddUserPopup';
import UsersListFilterPopup from './UsersListFilterPopup';
import UsersListFilterTags from './UsersListFilterTags';
import UsersListSearchPopup from './UsersListSearchPopup';
import UsersListTable from './UsersListTable';
import UsersListTableActionButtons from './UsersListTableActionButtons';

function UsersV2() {
  const userCan = usePermissions();
  const { loaders, appConfig } = useSelector((state) => state);
  const { activeUserTabIndex, userListDepartments, usersList } = useSelector(
    (state) => state.users,
  );

  const dispatch = useDispatch();
  const { t } = useTranslation('usersV2');

  const sharedParams = useMemo(() => {
    return { paginate: 10 };
  }, []);

  const [departmentParams, setDepartmentParams] = useState({});
  const [paginationParams, setPaginationParams] = useState({ page: +getURLParams('page') || 1 });
  const [filtersPopupParams, setFiltersPopupParams] = useState({});
  const [filterTags, setFilterTags] = useState({});
  const [searchPopupParams, setSearchPopupParams] = useState({});

  const createKeywordTagObj = useCallback((keyword) => {
    return {
      filterQueryName: 'keyword',
      filterName: 'Search By',
      filterValue: keyword,
      formValue: null,
    };
  }, []);

  const handlePaginate = useCallback((page) => {
    setPaginationParams({ page });
    history.replace({ search: appendURLParams('page', page) });
  }, []);

  const resetPagination = useCallback(() => {
    setPaginationParams({ page: 1 });
    history.replace({ search: appendURLParams('page', 1) });
  }, []);

  const resetKeywordSearchParams = useCallback(() => {
    setSearchPopupParams({});
  }, []);

  const handleSearch = useCallback(
    (keyword) => {
      setSearchPopupParams({ keyword });
      setFilterTags((prev) => {
        return {
          ...prev,
          keyword: createKeywordTagObj(keyword),
        };
      });

      if (appConfig.isMobile) {
        dispatch(hideModalAction());
      }
    },
    [appConfig.isMobile],
  );

  const handleOnSearch = useCallback(
    (keyword) => {
      handleSearch(keyword);
      resetPagination();
    },
    [searchPopupParams],
  );

  const handleApplyFilters = useCallback(
    (values) => {
      const queryObject = {};
      const tags = {};
      values.filters.forEach((ele) => {
        queryObject[ele.filter.queryName] = ele.subFilter.value;
        tags[ele.filter.queryName] = {
          filterQueryName: ele.filter.queryName,
          filterName: ele.filter.label,
          filterValue: ele.subFilter.label,
          formValue: ele,
        };
      });

      setFiltersPopupParams({ ...queryObject });
      resetPagination();
      setFilterTags(() => {
        if (searchPopupParams.keyword) {
          return {
            keyword: createKeywordTagObj(searchPopupParams.keyword),
            ...tags,
          };
        }
        return tags;
      });

      if (appConfig.isMobile) {
        dispatch(hideModalAction());
      }
    },
    [searchPopupParams, appConfig.isMobile],
  );

  const handleRemoveSinglePopupFilter = useCallback((singleFilter) => {
    dispatch(hideAllUsersListPopups());

    setFilterTags((prev) => {
      const updated = { ...prev };
      delete updated[singleFilter.filterQueryName];
      return updated;
    });

    if (singleFilter.filterQueryName === 'keyword') {
      resetKeywordSearchParams();
    } else {
      setFiltersPopupParams((prev) => {
        const updated = { ...prev };
        delete updated[singleFilter.filterQueryName];
        return updated;
      });
    }
  }, []);

  const handleTabChange = useCallback((tabIndex) => {
    dispatch(setActiveUserTabIndexAction(tabIndex));
    dispatch(hideAllUsersListPopups());
  }, []);

  useEffect(() => {
    dispatch(getUserListDepartmentsAction());
  }, [appConfig.lang]);

  useEffect(() => {
    if (userListDepartments?.length) {
      resetPagination();

      setDepartmentParams(() => {
        return {
          ...userListDepartments[activeUserTabIndex]?.searchParams,
        };
      });
    }
  }, [activeUserTabIndex]);

  // Search Call Here
  useEffect(() => {
    if (userListDepartments?.length) {
      dispatch(
        getUsersListAction({
          params: {
            ...sharedParams,
            ...paginationParams,
            ...departmentParams,
            ...filtersPopupParams,
            ...searchPopupParams,
          },
        }),
      );
    }
  }, [
    userListDepartments,
    sharedParams,
    paginationParams,
    departmentParams,
    filtersPopupParams,
    searchPopupParams,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearUsersListDepartmentsAction());
      dispatch(clearUsersListAction());
      dispatch(hideAllUsersListPopups());
      dispatch(setActiveUserTabIndexAction(0));
    };
  }, []);

  return (
    <div className="my-2 h-[98%] overflow-y-auto rounded-lg border-gray-5 lg:my-0 lg:border lg:p-7">
      {loaders.getUserListDepartmentsAction && (
        <div className="h-[90%]">
          <AppSpinner />
        </div>
      )}

      {!loaders.getUserListDepartmentsAction && !!userListDepartments?.length && (
        <div className="flex h-full flex-col gap-8 lg:flex-row lg:gap-2">
          {/* ============== Left Side ============== */}
          <div className="app-thin-scrollbar hover:pe-1 lg:w-[20%] lg:overflow-y-scroll lg:pe-3 lg:pt-0">
            <AppHeader h="h6" className="mb-11 hidden lg:block">
              {t('Users List')}
            </AppHeader>

            <AppTabs2
              tabs={userListDepartments}
              defaultActiveTabIndex={activeUserTabIndex}
              onTabChange={(tabIndex) => {
                handleTabChange(tabIndex);
              }}
            />
          </div>

          {/* ============== Right Side ============== */}
          <div className="app-thin-scrollbar overflow-y-scroll hover:pe-0.5 lg:w-[80%] lg:pe-3">
            <div className="lg:pe-2">
              <div className="sticky top-0 z-10 bg-white">
                <div className="mb-4 flex items-center justify-between lg:block">
                  <div className="order-2 mb-4 flex items-center justify-between lg:order-1">
                    {/* ============== Selected Department Name ============== */}
                    <AppHeader className="mt-2 hidden text-primary lg:mt-0 lg:block" h="h5">
                      {t(userListDepartments[activeUserTabIndex || 0]?.label, { ns: 'commonV2' })}
                    </AppHeader>

                    {/* ============== Add User Button ============== */}
                    {userCan('create_account_employees') && (
                      <div>
                        <AppButton
                          bClass="Button2Bold"
                          button="primary"
                          rounded="md"
                          size="lg"
                          onClick={() => {
                            if (appConfig.isMobile) {
                              history.push('/dashboard/users/add');
                            } else {
                              dispatch(showModalAction(<AddUserPopup />));
                            }
                          }}
                        >
                          <AppIcon className="fa-regular fa-user-plus me-2 text-white" />
                          <span>{t('New User')}</span>
                        </AppButton>
                      </div>
                    )}
                  </div>

                  {/* ============== Users List Actions ============== */}
                  <div className="relative order-1 my-2 flex lg:order-2 lg:justify-end">
                    <UsersListTableActionButtons
                      isDisabled={loaders.getUsersListAction}
                      filterTags={filterTags}
                      handleApplyFilters={handleApplyFilters}
                      handleOnSearch={handleOnSearch}
                    />

                    {/* ============== Users List Filter Popup ============== */}
                    {!appConfig.isMobile && (
                      <UsersListFilterPopup filterTags={filterTags} onApply={handleApplyFilters} />
                    )}

                    {/* ============== Users List Search Popup  ============== */}
                    {!appConfig.isMobile && <UsersListSearchPopup onSearch={handleOnSearch} />}
                  </div>
                </div>

                {/* ============== Users List Filter Tags ============== */}
                <UsersListFilterTags
                  filterTags={filterTags}
                  handleRemoveSinglePopupFilter={handleRemoveSinglePopupFilter}
                />
              </div>

              {/* ============== Users List Table ============== */}
              <div
                className={`rounded-2xl border border-gray-3 ${
                  loaders.getUsersListAction ? 'overflow-hidden' : ''
                }`}
              >
                <UsersListTable paginationParams={paginationParams} />
              </div>

              {/* ============== Users List Pagination ============== */}
              {usersList?.paginator?.total_pages > 1 && (
                <div className="flex justify-end bg-white py-4">
                  <AppPagination
                    isDisabled={loaders.getUsersListAction}
                    currentPage={paginationParams.page}
                    totalPages={usersList.paginator.total_pages}
                    onPageChange={(page) => {
                      handlePaginate(page);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersV2;
