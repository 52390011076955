import Compressor from 'compressorjs';
import React from 'react';
import {useDropzone} from 'react-dropzone';
import {ReactComponent as Attachment} from '../../assets/svg/attachment.svg';
import {ReactComponent as NoAttachment} from '../../assets/svg/no_attachment.svg';
import {AppBody} from "../html/html";

function DropzoneSimple({
  setFile,
  file,
  fileError,
  setFileError,
  rejectReason,
  acceptArray,
  name,
  oldFile,
}) {
  let newAcceptedFiles = null;

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptArray,
    // maxSize: 2048000,
    multiple: false,
    onDrop: async (acceptedFiles, fileRejections, event) => {
      setFile(null);
      setFileError(null);
      newAcceptedFiles = null;
      acceptedFiles.forEach((fileR, i) => {
        // don't try to process non-images
        if (fileR.type.includes('image/') && fileR.size > 2048000) {
          // eslint-disable-next-line no-new
          new Compressor(fileR, {
            quality: 0.6,
            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
              if (result.size <= 2048000) {
                const fileFormate = new File([result], result.name, { type: result.type });
                fileFormate.path = fileR.path;
                fileFormate.preview = URL.createObjectURL(result);
                newAcceptedFiles = fileFormate;
              } else {
                setFileError(rejectReason);
              }
            },
            error(err) {
              setFileError(rejectReason);
            },
          });
        } else if (fileR.size > 2048000) {
          setFileError(rejectReason);
        } else {
          newAcceptedFiles = fileR;
        }
      });

      setFile(newAcceptedFiles);
    },
    onDropRejected: (fileRejections) => {
      setFileError(rejectReason);
    },
  });

  const thumbs = file ? <Attachment /> : '';
  return (
    <div className=" m-auto">
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} name={name} />
          {!thumbs && !oldFile ? <NoAttachment /> : <Attachment />}
        </div>
      </section>
      {fileError && <AppBody pClass="Caption1Regular" className="pt-2 text-center text-red-700">{fileError}</AppBody>}
    </div>
  );
}

export default DropzoneSimple;
