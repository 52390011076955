import { useTranslation } from 'react-i18next';
import AppAlert from '../../../../../components/AppAlert';
import { AppBody } from '../../../../../components/html/html';

function DoubleNotes() {
  const { t } = useTranslation('walletV2');

  return (
    <>
      <div className="mb-4">
        <AppAlert
          type="info"
          text={
            <AppBody pClass="Body2Medium">
              {t(
                'Please Note you need to do the actual transfer from your bank account website or app',
              )}
            </AppBody>
          }
        />
      </div>

      <AppAlert
        type="info"
        text={
          <AppBody pClass="Body2Medium">
            {t(
              'To ensure compliance and smooth processing, only transfers from the bank account approved during your Nqoodlet onboarding will be accepted. Money from unapproved accounts will be reversed, typically within a 15-working-day window, based on your bank’s schedule.',
            )}
          </AppBody>
        }
      />
    </>
  );
}

export default DoubleNotes;
