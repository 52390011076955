import { useTranslation } from 'react-i18next';
import Head from '../../Head';
import CardProgramDetails from './CardProgramDetails';
import CardProgramList from './CardProgramList';

function Wallet() {
  const { t } = useTranslation('walletV2');

  return (
    <>
      <Head title={t('Wallet')} />

      <div className="flex h-[99%] w-full overflow-hidden rounded-lg border shadow-md">
        <div className="relative flex w-full flex-col justify-between overflow-y-auto bg-gray-4 px-2 pt-8 lg:w-[223px]">
          {/* Left Side */}
          <CardProgramList />
        </div>

        {/* Right Side */}
        <div className="hidden w-full lg:block">
          <CardProgramDetails />
        </div>
      </div>
    </>
  );
}

export default Wallet;
