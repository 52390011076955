/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from 'react-tooltip';

function AppTooltip({ children, id, icon, style, place = 'top', opacity = '.9' }) {
  return (
    <>
      <a data-tooltip-id={id}>{icon}</a>
      <Tooltip
        opacity={opacity}
        id={id}
        place={place}
        className="shadow-md"
        style={{
          color: '#2D2926', // black
          backgroundColor: '#F7F8F9', // gray-1
          zIndex: 50,
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          ...style,
        }}
      >
        {children}
      </Tooltip>
    </>
  );
}

export default AppTooltip;
