export const SET_CARD = 'SET_CARD';
export const SET_SMS_TOKEN = 'SET_SMS_TOKEN';
export const SET_SMS_CODE = 'SET_SMS_CODE';

const initialState = {
  card: null,
  smsToken: null,
  smsCode: null,
};

const CardReducer = (state = initialState, action) => {
  const draft = { ...state };
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };
    case SET_CARD:
      draft.card = action.payload;
      return draft;
    case SET_SMS_TOKEN:
      draft.smsToken = action.payload;
      return draft;
    case SET_SMS_CODE:
      draft.smsCode = action.payload;
      return draft;

    default:
      return draft;
  }
};

export default CardReducer;
