import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRightLong } from 'react-icons/fa6';

import { useDispatch, useSelector } from 'react-redux';
import { formatDate, toIntAndDecimalNumber } from '../../helpers/Utils';
import { inPages, transactionTabs } from '../../helpers/constants';
import ReceiptIcon from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/ReceiptIcon';
import {
  getSingleTransactionDetailsAction,
  hideAllReceiptSectionAction,
  hideCardActionsPopup,
  hideRequestFormPopupAction,
  showReceiptPopupAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';
import {
  getSingleWalletTransactionDetailsAction,
  hideAllWalletReceiptSectionAction,
  hideWalletRequestFormPopupAction,
  showWalletReceiptPopupAction,
} from '../../redux/WalletReducer/WalletActions';
import TranslatedData from '../TranslatedData';
import { AppIcon, AppTd, AppTr } from '../html/html';

function SplitArrowLine({ isLast }) {
  const lineHeight = isLast ? 'h-[60%]' : 'h-[102%]';

  return <div className={`${lineHeight} absolute start-[10.5px] top-0 w-[2px] bg-gray-5`} />;
}

function TransactionTableRow({ item, isSplit, isLast, splitItemIndex, inPage, activeTab }) {
  const { isMobile, lang } = useSelector((state) => state.appConfig);
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const handleViewReceipt = useCallback(() => {
    if (inPage === inPages.myCards) {
      dispatch(showReceiptPopupAction());
      dispatch(hideRequestFormPopupAction());
      dispatch(getSingleTransactionDetailsAction(item.id));
      dispatch(hideAllReceiptSectionAction());
      dispatch(hideCardActionsPopup());
    }

    if (inPage === inPages.wallet) {
      dispatch(showWalletReceiptPopupAction());
      dispatch(hideWalletRequestFormPopupAction());
      dispatch(getSingleWalletTransactionDetailsAction(item.id));
      dispatch(hideAllWalletReceiptSectionAction());
      // dispatch(hideWalletActionsPopup());
    }
  }, [item, isMobile]);

  const isInExpensesTab = useMemo(() => {
    return activeTab === transactionTabs.EXPENSES;
  }, [activeTab]);

  const isInDeclinesTab = useMemo(() => {
    return activeTab === transactionTabs.DECLINES;
  }, [activeTab]);

  const isInRefundTab = useMemo(() => {
    return activeTab === transactionTabs.REFUNDS;
  }, [activeTab]);

  const isInLoadsTab = useMemo(() => {
    return activeTab === transactionTabs.LOADS;
  }, [activeTab]);

  const showMerchantName = useMemo(() => {
    const isNotSplitInMyCards = inPage === inPages.myCards && !isSplit;
    const isInWallet = inPage === inPages.wallet;

    return !isInLoadsTab && (isNotSplitInMyCards || isInWallet);
  }, [isSplit, inPage]);

  const showDepartmentName = useMemo(() => {
    const isSplitItem = isSplit;
    const isInMyCard = inPage === inPages.myCards;

    return !isInLoadsTab && isInMyCard && isSplitItem;
  }, [isSplit, inPage]);

  return (
    <>
      {/* ================= Desktop Row ================= */}
      {!isMobile && (
        <AppTr
          className="cursor-pointer rounded-lg [&>*]:py-4"
          onClick={() => {
            handleViewReceipt();
          }}
        >
          {isSplit && (
            <>
              <AppTd className="relative">
                <SplitArrowLine isLast={isLast} />
                <FaArrowRightLong
                  className="absolute bottom-3 start-[11px] text-gray-5 rtl:rotate-180 rtl:scale-x-[1] rtl:scale-y-[-1]"
                  size={20}
                />
              </AppTd>

              <AppTd className="flex items-center gap-4">
                <span className="flex h-6 w-6 items-center justify-center rounded-full bg-stone-200">
                  {splitItemIndex + 1}
                </span>
                <ReceiptIcon item={item} />
              </AppTd>
            </>
          )}

          {!isSplit && (
            <>
              {activeTab !== transactionTabs.LOADS && activeTab !== transactionTabs.DECLINES && (
                <AppTd className={`w-24 ${item.splits?.length ? '' : 'border-b'}`}>
                  <ReceiptIcon item={item} />
                </AppTd>
              )}

              <AppTd className={`w-12 ${item.splits?.length ? '' : 'border-b'}`}>
                {formatDate(item.transaction_timestamp, 'HH:mm')}
              </AppTd>
            </>
          )}

          <>
            {showMerchantName && (
              <AppTd className="border-b">
                {item.brand_name === '' && (item.merchant_name || '-')}

                {item.brand_name !== '' && item.brand_logo === '' && (
                  <AppIcon iClass="Default" className="fa-kit fa-image pe-1" />
                )}

                {item.brand_name !== '' && item.brand_logo !== '' && (
                  <img
                    src={item.brand_logo}
                    alt={item.brand_name}
                    className="inline-block h-[18px] w-[18px] pe-1"
                  />
                )}
                {item.brand_name !== '' && item.brand_name}
              </AppTd>
            )}

            {showDepartmentName && (
              <AppTd className="border-b">
                <div>
                  <TranslatedData en={item.department_id.name} ar={item.department_id.name_ar} />
                </div>
              </AppTd>
            )}
          </>

          {isInLoadsTab && (
            <AppTd className="flex items-center gap-1 border-b">
              <span className="order-1 rtl:order-2">{t('Card')}</span>
              <span className="order-2 rtl:order-1">
                <TranslatedData
                  en={item.transaction_type || '-'}
                  ar={item.transaction_type_ar || '-'}
                />
              </span>
            </AppTd>
          )}

          {isInRefundTab && (
            <AppTd className="border-b">
              <TranslatedData
                en={item.transaction_type || '-'}
                ar={item.transaction_type_ar || '-'}
              />
            </AppTd>
          )}

          <AppTd className="border-b">
            <div
              className={`flex gap-1 ${lang === 'ar' ? 'justify-end' : ''}`}
              style={{ direction: 'ltr' }}
            >
              <div style={{ direction: 'ltr' }}>
                {toIntAndDecimalNumber(item.transaction_amount).integerPart}
                <span className="text-[13px] font-normal">
                  {toIntAndDecimalNumber(item.transaction_amount).decimalPart}
                </span>
              </div>
              <span>{item.transaction_currency}</span>
            </div>
          </AppTd>

          {inPage === inPages.wallet && (
            <AppTd className="border-b">
              <div className="flex gap-1">
                <span>
                  <TranslatedData
                    en={item?.user_id?.first_name}
                    ar={item?.user_id?.first_name_ar}
                  />
                </span>
                <span>
                  <TranslatedData en={item?.user_id?.last_name} ar={item?.user_id?.last_name_ar} />
                </span>
              </div>
            </AppTd>
          )}

          <AppTd className="border-b">
            <div className="flex gap-1">
              <span>{item.card_name}</span>
              <span>{item.card_last4}</span>
            </div>
          </AppTd>

          {inPage === inPages.wallet && (
            <AppTd className="border-b">
              <div>
                <TranslatedData en={item.department_id.name} ar={item.department_id.name_ar} />
              </div>
            </AppTd>
          )}

          {(isInExpensesTab || isInRefundTab) && inPage === inPages.myCards && (
            <AppTd className="border-b">
              <div className="flex gap-2">
                <AppIcon iClass="BaseFont" className="fa-regular fa-tag" />
                <TranslatedData en={item.tags?.name || '-'} ar={item.tags?.name_ar} />
              </div>
            </AppTd>
          )}

          {isInDeclinesTab && (
            <AppTd className="flex items-center gap-2 border-b">
              <TranslatedData en={item.status_description} ar={item.status_description_ar} />
            </AppTd>
          )}
        </AppTr>
      )}

      {/* ================= Mobile Row ================= */}
      {isMobile && (
        <div
          className={`text-xs font-bold xxs:text-sm ${isSplit ? 'ps-2' : 'relative z-20 bg-white'}`}
        >
          <div
            onClick={() => {
              handleViewReceipt();
            }}
            className={`${
              isSplit
                ? 'my-4 ms-auto min-h-16 w-[94%] gap-2 rounded-2xl border border-gray-5 px-4 py-2'
                : 'min-h-20 gap-12 rounded-xl p-4 shadow-lg'
            } mb-2 flex cursor-pointer items-center`}
          >
            {isSplit && (
              <>
                {/* ========== Arrows  ========== */}
                <div className="relative font-normal">
                  <div
                    className={`${
                      isLast ? '-top-14 h-[335%]' : '-top-[65px] h-[480%]'
                    } absolute -start-[40px] w-[2px] bg-gray-5`}
                  />
                  <FaArrowRightLong
                    className="relative text-gray-5 ltr:-translate-x-10 rtl:translate-x-10 rtl:rotate-180 rtl:scale-x-[1] rtl:scale-y-[-1]"
                    size={20}
                  />
                </div>

                {/* ========== Split Receipt Icon  ========== */}
                <div className="relative flex items-center gap-4 font-normal ltr:-translate-x-4 rtl:translate-x-4">
                  <span className="flex h-6 w-6 items-center justify-center rounded-full bg-stone-200">
                    {splitItemIndex + 1}
                  </span>
                  <ReceiptIcon item={item} />
                </div>
              </>
            )}

            {/* ========== Receipt Icon  ========== */}
            {!isSplit &&
              activeTab !== transactionTabs.LOADS &&
              activeTab !== transactionTabs.DECLINES && (
                <div>
                  <ReceiptIcon item={item} />
                </div>
              )}

            {!isInLoadsTab && (
              <div>
                <div className="flex flex-col gap-1">
                  {inPage === inPages.wallet && !isSplit && (
                    <div className="flex gap-1">
                      <span>
                        <TranslatedData
                          en={item?.user_id?.first_name}
                          ar={item?.user_id?.first_name_ar}
                        />
                      </span>
                      <span>
                        <TranslatedData
                          en={item?.user_id?.last_name}
                          ar={item?.user_id?.last_name_ar}
                        />
                      </span>
                    </div>
                  )}

                  {!isSplit && <span>{item.merchant_name || '-'}</span>}

                  {isSplit && (
                    <div>
                      <TranslatedData
                        en={item.department_id.name}
                        ar={item.department_id.name_ar}
                      />
                    </div>
                  )}

                  {(isSplit || inPage === inPages.myCards) && (
                    <span className="flex gap-2">
                      <span className="hidden xxs:inline">
                        <AppIcon className="fa-regular fa-tag" />
                      </span>
                      <TranslatedData en={item.tags?.name || '-'} ar={item.tags?.name_ar} />
                    </span>
                  )}
                </div>
              </div>
            )}

            {isInLoadsTab && (
              <div>
                {inPage === inPages.wallet && (
                  <div className="flex gap-1">
                    <span>
                      <TranslatedData
                        en={item?.user_id?.first_name}
                        ar={item?.user_id?.first_name_ar}
                      />
                    </span>
                    <span>
                      <TranslatedData
                        en={item?.user_id?.last_name}
                        ar={item?.user_id?.last_name_ar}
                      />
                    </span>
                  </div>
                )}
                <div className="flex items-center gap-1">
                  <span className="order-1 rtl:order-2">{t('Card')}</span>
                  <span className="order-2 rtl:order-1">
                    <TranslatedData
                      en={item.transaction_type || '-'}
                      ar={item.transaction_type_ar || '-'}
                    />
                  </span>
                </div>
              </div>
            )}

            <div
              className="ms-auto flex flex-col items-center justify-center"
              style={{ direction: 'ltr' }}
            >
              <div style={{ direction: 'ltr' }}>
                {toIntAndDecimalNumber(item.transaction_amount).integerPart}
                <span className="text-[13px] font-normal">
                  {toIntAndDecimalNumber(item.transaction_amount).decimalPart}
                </span>
              </div>
              <div>{item.transaction_currency}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TransactionTableRow;
