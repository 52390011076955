import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/withdraw.json';

function FileLottie() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <span className="inline-block">
      <Lottie options={defaultOptions} height={132} width={132} />
    </span>
  );
}

export default FileLottie;
