import React, { useMemo } from 'react';

import { cardStatusKeys } from '../../../../../helpers/constants';

function SingleColumnCardStatus({ card }) {
  const cardStatusKey = useMemo(() => {
    return card.status?.key.toUpperCase();
  }, [card]);

  const cardProgramStatusKey = useMemo(() => {
    return card.cardproduct?.status?.toUpperCase();
  }, [card]);

  const isClosedCardProgram = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(cardProgramStatusKey);
  }, [card]);

  const iconColor = useMemo(() => {
    return card.is_physical ? 'text-white' : 'text-black';
  }, [card]);

  if (cardStatusKey === cardStatusKeys.INACTIVE) {
    return <i className={`${iconColor} fa-kit fa-smile text-6xl lg:text-xl`} />;
  }

  if (
    (cardStatusKey === cardStatusKeys.TERMINATED || cardStatusKey === cardStatusKeys.EXPIRED) &&
    !isClosedCardProgram
  ) {
    return <i className={`${iconColor} fa-kit fa-replace text-6xl lg:text-xl`} />;
  }

  if (cardStatusKey === cardStatusKeys.SUSPENDED) {
    return <i className={`${iconColor} fa-kit fa-lock text-6xl lg:text-xl`} />;
  }
}

export default SingleColumnCardStatus;
