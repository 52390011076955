import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppNoData from '../../../../components/AppNoData';
import BackBtn from '../../../../components/BackBtn';
import {
  clearSelectedSingleUserCardAction,
  getSingleUserSelectedCardDetails,
} from '../../../../redux/UsersReducer/UsersActions';
import SingleUserCardDetailsPage from './SingleUserCardDetailsPage';

function SingleUserCardDetails({ handleBackToUsersList }) {
  const { singleUserSelectedCard, singleUserCards } = useSelector((state) => state.users);
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();

  useEffect(() => {
    if (singleUserSelectedCard) {
      dispatch(
        getSingleUserSelectedCardDetails({
          virtualCard: singleUserSelectedCard.virtualCard,
          physicalCard: singleUserSelectedCard.physicalCard,
        }),
      );
    }
  }, [singleUserSelectedCard]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedSingleUserCardAction());
    };
  }, []);

  return (
    <>
      {singleUserSelectedCard && <SingleUserCardDetailsPage />}
      {!singleUserSelectedCard && (
        <>
          <div className="mb-1 hidden pt-7 lg:block">
            <BackBtn className="mb-4" label={t('Back')} onClick={handleBackToUsersList} />
          </div>
          {!!singleUserCards?.length && (
            <AppNoData className="h-[70vh]" message={t('Select a Card to Display Its Details')} />
          )}

          {!singleUserCards?.length && (
            <AppNoData className="h-[70vh]" message={t('This User Has No Cards.')} />
          )}
        </>
      )}
    </>
  );
}

export default SingleUserCardDetails;
