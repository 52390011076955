import { Turnstile } from '@marsidev/react-turnstile';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../../AppRouter/history';
import idPreview from '../../../assets/png/idPreview.png';
import AppInput from '../../../components/AppFormInputs/AppInput';
import AppTooltip from '../../../components/AppTooltip';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../../components/html/html';
import { isDev } from '../../../helpers/Utils';
import { captchaKey } from '../../../helpers/constants';
import { http } from '../../../http';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';
import '../NotRobot.css';

function UserForgetPasswordForm({ setIsEmailSent }) {
  const { t } = useTranslation('forgetPasswordV2');
  const { loaders } = useSelector((state) => state);
  const { lang } = useSelector((state) => state.appConfig);
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = useCallback((values) => {
    const formBody = new FormData();

    formBody.append('id_number', values.id);
    formBody.append('email', values.email);

    http
      .post(`email/forget`, formBody, { loader: 'handleForgetPassword' })
      .then(() => {
        setIsEmailSent(true);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, []);

  const initialValues = useMemo(() => {
    return {
      id: '',
      email: '',
    };
  }, []);

  const validationSchema = yup.object({
    id: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    email: yup
      .string()
      .email(t('Please Enter a Valid Email', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  useEffect(() => {
    // ...
  }, [lang]);

  return (
    <div className="px-2 pt-14 xxs:px-4 xl:px-16">
      <div className="mx-auto max-w-[500px]">
        <AppHeader h="h2" className="mb-2 text-center">
          {t('Forget password')}
        </AppHeader>

        <AppBody pClass="Body1Medium" className="mb-10 text-center text-gray-6">
          {t(
            'Enter your registered ID number and Email below to receive password reset instruction',
          )}
        </AppBody>
      </div>

      <div className="mx-auto max-w-[400px]">
        <form onSubmit={formik.handleSubmit}>
          <label className="mb-8 block">
            <AppBody type="span" pClass="Body1Bold" className="mb-1">
              <span>{t('National ID, Iqama or passport No')}</span>

              <AppTooltip
                place="bottom"
                opacity={1}
                style={{ backgroundColor: 'white' }}
                id="preview"
                icon={<AppIcon className="fa-regular fa-circle-info ms-2 text-xl" />}
              >
                <div className="max-w-80">
                  <img src={idPreview} alt="Id Preview" />
                  <AppBody pClass="Body2Regular" className="mt-4 text-gray-6">
                    {t(
                      "National identity is a person's identity or sense of belonging to one or more states or to one or more nations.",
                    )}
                  </AppBody>
                </div>
              </AppTooltip>
            </AppBody>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.id}
              size="lg"
              name="id"
              type="text"
            />
            {formik.errors.id && formik.touched.id && (
              <small className="text-danger">{t(formik.errors.id)}</small>
            )}
          </label>

          <label className="mb-8 block">
            <AppBody type="span" pClass="Body1Bold" className="mb-1">
              {t('Email')}
            </AppBody>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              size="lg"
              name="email"
              type="text"
            />
            {formik.errors.email && formik.touched.email && (
              <small className="text-danger">{t(formik.errors.email)}</small>
            )}
          </label>

          {!isDev() && (
            <div id="notRobot" className="mb-8 flex justify-center">
              <Turnstile
                autoResetOnExpire
                siteKey={captchaKey}
                onError={() => {
                  setIsValidCaptcha(false);
                }}
                onSuccess={() => {
                  setIsValidCaptcha(true);
                }}
                options={{
                  theme: 'light',
                  size: 'flexible',
                  language: lang,
                }}
              />
            </div>
          )}

          <div className="mb-6 flex flex-col items-center justify-end gap-6 lg:gap-6">
            <AppButton
              disabled={!formik.isValid && isValidCaptcha}
              isLoading={loaders.handleForgetPassword}
              type="submit"
              size="lg"
              className="w-full"
              button="primary"
              rounded="xl"
            >
              {t('Send')}
            </AppButton>

            <AppButton
              button="link"
              className="text-center font-bold"
              onClick={() => {
                history.push('/auth/login');
              }}
              size="lg"
            >
              {t('Cancel')}
            </AppButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserForgetPasswordForm;
