import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppIcon } from '../../../../components/html/html';
import { userRoles } from '../../../../helpers/constants';
import useClickOutside from '../../../../hooks/useClickOutside';
import usePermissions from '../../../../hooks/usePermissions';
import {
  resendUserActivationEmailAction,
  sendChangeUserPasswordInvitationEmailAction,
} from '../../../../redux/UsersReducer/UsersActions';
import UserDetailsChangeStatusPopup from './UserDetailsChangeStatusPopup';

function UserDetailsActions() {
  const userCan = usePermissions();
  const { singleUserDetails } = useSelector((state) => state.users);
  const [showChangeStatusPopup, setShowChangeStatusPopup] = useState(false);
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();
  const { loaders, auth } = useSelector((state) => state);

  const changeStatusRef = useRef();

  useClickOutside(changeStatusRef, () => {
    setShowChangeStatusPopup(false);
  });

  const isUserPending = useMemo(() => {
    return singleUserDetails.status === 'Pending';
  }, [singleUserDetails]);

  const isUserBanned = useMemo(() => {
    return singleUserDetails.status === 'Banned';
  }, [singleUserDetails]);

  const showChangeStatusButton = useMemo(() => {
    const canChangeStatus = userCan('update_account');
    const notSameLoggedInUser = singleUserDetails.id !== auth.user.id;
    const notAccountOwner = !singleUserDetails.roles.includes(userRoles.Owner);
    return canChangeStatus && notSameLoggedInUser && notAccountOwner && !isUserPending;
  }, [singleUserDetails, auth, isUserPending]);

  return (
    !isUserBanned && (
      <div className="mb-4 flex justify-end gap-1 pe-6 lg:mb-12">
        {/* Change Status Button */}
        {showChangeStatusButton && (
          <button
            onClick={() => {
              setShowChangeStatusPopup(!showChangeStatusPopup);
            }}
            type="button"
            className="relative flex items-center gap-1 rounded-full px-3 py-2 text-xs font-bold text-primary hover:bg-gray-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
          >
            {!loaders[`changeUserStatusAction[${singleUserDetails.id}]`] && (
              <AppIcon iClass="LargeFont" className="fa-regular fa-user-pen text-primary" />
            )}

            {loaders[`changeUserStatusAction[${singleUserDetails.id}]`] && (
              <AppIcon
                iClass="BaseFont"
                className="fa-regular fa-loader me-1 animate-spin text-primary"
              />
            )}

            <div>{t('Change Status')}</div>

            <UserDetailsChangeStatusPopup
              showChangeStatusPopup={showChangeStatusPopup}
              setShowChangeStatusPopup={setShowChangeStatusPopup}
            />
          </button>
        )}

        {/* Resend Invite Button */}
        {isUserPending && (
          <button
            onClick={() =>
              dispatch(
                resendUserActivationEmailAction({
                  userId: singleUserDetails.id,
                  fromDetails: true,
                }),
              )
            }
            type="button"
            className="relative flex items-center gap-1 rounded-full px-3 py-2 text-xs font-bold text-primary hover:bg-gray-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
          >
            {/* Spinner Icon */}
            {loaders[`resendUserActivationEmailAction[${singleUserDetails.id}]`] && (
              <AppIcon
                iClass="BaseFont"
                className="fa-regular fa-loader me-1 animate-spin text-primary"
              />
            )}

            {/* Resend Invitation Icon */}
            {!loaders[`resendUserActivationEmailAction[${singleUserDetails.id}]`] && (
              <AppIcon
                iClass="LargeFont"
                className="fa-regular fa-paper-plane-top -rotate-45 text-primary"
              />
            )}

            <div>{t('Resend Invite')}</div>
          </button>
        )}

        {/* Reset Password Button */}
        {!isUserPending && (
          <button
            onClick={() =>
              dispatch(
                sendChangeUserPasswordInvitationEmailAction({
                  userId: singleUserDetails.id_number,
                  email: singleUserDetails.email,
                }),
              )
            }
            type="button"
            className="relative flex items-center gap-1 rounded-full px-3 py-2 text-xs font-bold text-primary hover:bg-gray-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
          >
            {/* Spinner Icon */}
            {loaders.sendChangeUserPasswordInvitationEmailAction && (
              <AppIcon
                iClass="BaseFont"
                className="fa-regular fa-loader me-1 animate-spin text-primary"
              />
            )}

            {/* Change Password Icon */}
            {!loaders.sendChangeUserPasswordInvitationEmailAction && (
              <AppIcon iClass="LargeFont" className="fa-regular fa-arrows-retweet text-primary" />
            )}

            <div>{t('Reset Password')}</div>
          </button>
        )}
      </div>
    )
  );
}
export default UserDetailsActions;
