import { objToQueryStr } from 'javascript-functions';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import masterCardLogo from '../../../../assets/png/Mastercard-logo.png';
import visaCardLogo from '../../../../assets/png/Visa-logo.png';
import cardProgramCard from '../../../../assets/png/cardProgramCardWithoutLogo.png';
import AppImageComponent from '../../../../components/AppImageComponent';
import AppSpinner from '../../../../components/AppSpinner';
import { AppIcon } from '../../../../components/html/html';
import { cardStatusKeys, walletTabs } from '../../../../helpers/constants';
import {
  getSingleColumnCardProgramBalanceAction,
  hideAllWalletPopups,
  selectSingleCardProgramAction,
  setActiveWalletMainTabAction,
} from '../../../../redux/WalletReducer/WalletActions';
import SingleCardProgramStatus from '../SingleCardProgramStatus';

function NqoodCardProgram({
  cardProgram,
  fromActions = false,
  fromList = false,
  actionSelect = false,
  onClick,
}) {
  const { appConfig, loaders } = useSelector((state) => state);
  const { singleSelectedCardProgramData } = useSelector((state) => state.wallet);

  const [showBalance, setShowBalance] = useState(false);
  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const smallerResponsiveTextSize = 'text-sm sm:text-3xl lg:text-[8px] lg:leading-3';
  const smallResponsiveTextSize = 'text-base sm:text-4xl lg:text-[10px] lg:leading-3';
  const responsiveMargin = 'mb-1 sm:mb-3 lg:mb-[2px]';

  const isCardProgramSelected = useMemo(() => {
    return singleSelectedCardProgramData?.id === cardProgram?.id;
  }, [singleSelectedCardProgramData, cardProgram]);

  const isClosedCardProgram = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(
      cardProgram.status?.toUpperCase(),
    );
  }, [cardProgram]);

  const renderScheme = useCallback((scheme) => {
    if (scheme.toUpperCase() === 'MASTER') {
      return <img src={masterCardLogo} alt="masterCard Logo" />;
    }
    if (scheme.toUpperCase() === 'VISA') {
      return <img src={visaCardLogo} alt="visa Logo" />;
    }
    return null;
  }, []);

  const handleSelectCardProgram = useCallback(
    (e) => {
      if (!e.target.closest('[data-id="balance-btn"]')) {
        if (fromList) {
          dispatch(setActiveWalletMainTabAction(walletTabs.REQUESTS));
          dispatch(hideAllWalletPopups());

          navigate({ search: objToQueryStr({ selectedCardProgramId: cardProgram.id }) });

          // Navigate to details in case mobile
          if (appConfig?.isMobile) {
            navigate(`/dashboard/wallet/${cardProgram.id}`);
          } else {
            dispatch(selectSingleCardProgramAction({ cardProgram }));
          }
        }

        if (fromActions && actionSelect && onClick) {
          onClick();
          dispatch(selectSingleCardProgramAction({ cardProgram }));
        }
      }
    },
    [appConfig],
  );

  const renderCardProgramDescription = useCallback(() => {
    const slicedDescription = cardProgram?.description?.split('-').slice(0, 2).join('-');
    return <p>{slicedDescription || '-'}</p>;
  }, [cardProgram]);

  const cardProgramClasses = useMemo(() => {
    let classes = 'relative w-full overflow-hidden rounded-xl shadow';

    if ((fromActions && actionSelect) || !fromActions) {
      classes += ' cursor-grab';
    }

    if (isCardProgramSelected && !fromActions) {
      classes += ' outline outline-2 outline-gray-3';
    }

    if (!isCardProgramSelected && fromActions) {
      classes += ' outline outline-2 outline-gray-3';
    }

    if (isCardProgramSelected && fromActions) {
      classes += ' outline outline-2 outline-black';
    }

    return classes;
  }, [isCardProgramSelected, fromActions, actionSelect]);

  return (
    <div className={cardProgramClasses} onClick={handleSelectCardProgram}>
      {/* =========== Card Program Image =========== */}
      <AppImageComponent
        alt="Card Program Logo"
        src={cardProgramCard}
        skeletonWidth={164}
        skeletonHeight={104}
        className={` ${isClosedCardProgram ? 'grayscale' : ''}`}
      />

      {/* =========== Noodlet Logo =========== */}
      <div className={`${isClosedCardProgram ? 'grayscale' : ''} absolute left-2 top-[4%] z-10`}>
        <AppIcon
          iClass={fromActions ? 'XLargeFont' : 'LargeFont'}
          className={`fa-kit fa-nqoodlet-logo-english lg:text-xs ${
            isClosedCardProgram ? 'text-gray-6' : ''
          }`}
        />
      </div>

      {/* =========== Card Status =========== */}
      {isClosedCardProgram && (
        <div className="absolute bottom-1/2 left-1/2 -translate-x-1/2 translate-y-[50%]">
          <SingleCardProgramStatus cardProgram={cardProgram} className="text-5xl lg:text-xl" />
        </div>
      )}

      {/* =========== Bank Logo =========== */}
      <div className={`${isClosedCardProgram ? 'grayscale' : ''} absolute right-3 top-[8%] z-10`}>
        <i
          className={`${cardProgram?.funding_source_type?.bank_logo} ${
            fromActions ? 'text-xl' : 'text-lg'
          } ${isClosedCardProgram ? 'text-gray-6' : ''}`}
        />
      </div>

      {/* =========== Bank Scheme =========== */}
      <div className="absolute bottom-3 right-3 w-6">{renderScheme(cardProgram.scheme)}</div>

      <div style={{ direction: 'ltr' }} className="absolute bottom-[7%] left-[5%]">
        {/* ====================== Description ====================== */}
        <div
          className={`flex items-center ${
            isClosedCardProgram ? 'text-gray-6' : ''
          } ${smallResponsiveTextSize} ${responsiveMargin}`}
          style={{ direction: 'ltr' }}
        >
          {renderCardProgramDescription()}
        </div>

        {/* ====================== Balance Button ====================== */}
        <button
          type="button"
          className={`relative z-20 flex items-center gap-1 text-gray-6 ${smallerResponsiveTextSize} ${responsiveMargin}`}
          data-id="balance-btn"
          onClick={(e) => {
            e.preventDefault();
            setShowBalance(!showBalance);
            if (!cardProgram.balance) {
              dispatch(getSingleColumnCardProgramBalanceAction(cardProgram));
            }
          }}
        >
          {!showBalance && <AppIcon iClass="EyeIcon" className="fa-regular fa-eye-slash" />}

          {showBalance && <AppIcon iClass="EyeIcon" className="fa-regular fa-regular fa-eye" />}

          <p>{t('Balance')}</p>
        </button>

        <div className="flex items-center" style={{ direction: 'ltr' }}>
          {/* ====================== Stars ====================== */}
          {!showBalance && (
            <span
              className={`me-1 ${
                isClosedCardProgram ? 'text-gray-6' : ''
              } ${smallResponsiveTextSize}`}
            >
              ******
            </span>
          )}

          {(!fromActions || (fromActions && actionSelect)) &&
            loaders[`getSingleColumnCardProgramBalanceAction[${cardProgram.id}]`] && (
              <div className="mr-2">
                <AppSpinner size={appConfig.isMobile ? 28 : 12} />
              </div>
            )}

          {/* ====================== Balance Amount ====================== */}
          {cardProgram.balance && showBalance && (
            <span
              className={`me-1 ${
                isClosedCardProgram ? 'text-gray-6' : ''
              } ${smallResponsiveTextSize}`}
            >
              {cardProgram.balance?.amount || 0}
            </span>
          )}

          {/* ====================== currency ====================== */}
          <span
            className={`${isClosedCardProgram ? 'text-gray-6' : ''} ${smallerResponsiveTextSize}`}
          >
            {cardProgram.currency}
          </span>
        </div>
      </div>
    </div>
  );
}

export default NqoodCardProgram;
