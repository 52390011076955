import { useSelector } from 'react-redux';
import ReceiptAttachments from '../../../../../../components/SharedComponents/ReceiptAttachments';
import ReceiptBalanceAndStatus from '../../../../../../components/SharedComponents/ReceiptBalanceAndStatus';
import ReceiptCardDetails from '../../../../../../components/SharedComponents/ReceiptCardDetails';
import ReceiptMerchantData from '../../../../../../components/SharedComponents/ReceiptMerchantData';
import ReceiptUpload from '../../../../../../components/SharedComponents/ReceiptUpload';
import ReceiptUploadAndAttachmentsPrint from '../../../../../../components/SharedComponents/ReceiptUploadAndAttachmentsPrint';
import SplitReceipt from '../../../../../../components/SharedComponents/SplitReceipt';
import { inPages, transactionTabs } from '../../../../../../helpers/constants';
import {
  getDepartmentsAction,
  getDocTypesAction,
  getTagsAction,
} from '../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function ExpensesAndRefundPopupContent() {
  const { selectedActiveTab, singleTransactionDetails, docTypes, tags, showSections, departments } =
    useSelector((state) => state.cardDetails);
  return (
    <>
      <div>
        <ReceiptBalanceAndStatus transactionDetails={singleTransactionDetails} />
      </div>

      <div>
        <div className="flex justify-between gap-4">
          <div>
            <ReceiptMerchantData
              activeTab={selectedActiveTab}
              transactionDetails={singleTransactionDetails}
              tags={tags}
              getTagsAction={getTagsAction}
              inPage={inPages.myCards}
            />
          </div>
          <div>
            <ReceiptUpload transactionDetails={singleTransactionDetails} inPage={inPages.myCards} />
          </div>
        </div>
      </div>

      <div>
        <ReceiptCardDetails
          transactionDetails={singleTransactionDetails}
          inPage={inPages.myCards}
        />
      </div>

      <div>
        <ReceiptAttachments
          transactionDetails={singleTransactionDetails}
          docTypes={docTypes}
          showSections={showSections}
          inPage={inPages.myCards}
          getDocTypesAction={getDocTypesAction}
        />
      </div>

      <div className="hidden print:block">
        <ReceiptUploadAndAttachmentsPrint transactionDetails={singleTransactionDetails} />
      </div>

      {selectedActiveTab === transactionTabs.EXPENSES && (
        <div>
          <SplitReceipt
            tags={tags}
            departments={departments}
            transactionDetails={singleTransactionDetails}
            showSections={showSections}
            getDepartmentsAction={getDepartmentsAction}
            getTagsAction={getTagsAction}
            inPage={inPages.myCards}
          />
        </div>
      )}
    </>
  );
}

export default ExpensesAndRefundPopupContent;
