import { uploadDocumentSteps } from '../../helpers/constants';
import { devLog } from '../../helpers/Utils';

const INITIAL_STATE = {
  activeDocumentStep: uploadDocumentSteps.OTP,
  documentCards: null,
  documentOTPData: null,
  singleDocumentCardDetails: null,
};

const UploadDocumentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_DOCUMENT_STEP': {
      return { ...state, activeDocumentStep: action.payload };
    }

    case 'SAVE_DOCUMENT_CARDS': {
      return { ...state, documentCards: action.payload };
    }

    case 'SAVE_DOCUMENT_OTP_DATA': {
      return { ...state, documentOTPData: action.payload };
    }

    case 'SET_SINGLE_DOCUMENT_CARD_DETAILS': {
      return { ...state, singleDocumentCardDetails: action.payload };
    }

    case 'UPDATE_SINGLE_DOCUMENT_CARD_DETAILS': {
      let updatedDocuments = { ...state.singleDocumentCardDetails };

      updatedDocuments = updatedDocuments.documents.map((ele) => {
        if (ele.id === action.payload.id) {
          devLog(`Document found and updated!`);
          return action.payload;
        }
        return ele;
      });

      return {
        ...state,
        singleDocumentCardDetails: {
          ...state.singleDocumentCardDetails,
          documents: updatedDocuments,
        },
      };
    }

    default:
      return state;
  }
};

export default UploadDocumentsReducer;
