import { objToQueryStr } from 'javascript-functions';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { transactionTabs } from '../../../../helpers/constants';
import {
    clearSelectedCardDetailsAction,
    hideAllPopups,
    setActiveTabAction,
} from '../../../../redux/CardDetailsReducer/CardDetailsActions';
import { selectSingleCardAction } from '../../../../redux/CardListReducer/CardListActions';
import NqoodCard from './NqoodCard';

function NqoodDoubleCard({ physicalCard, virtualCard }) {
  const { singleSelectedCardData } = useSelector((state) => state.cardList);

  const { appConfig } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isDoubleCardSelected = useMemo(() => {
    return (
      physicalCard.id === singleSelectedCardData?.physicalCard?.id &&
      virtualCard.id === singleSelectedCardData?.virtualCard?.id
    );
  }, [physicalCard, virtualCard, singleSelectedCardData]);

  const resetDetailsPage = useCallback(() => {
    dispatch(clearSelectedCardDetailsAction());
    dispatch(setActiveTabAction(transactionTabs.EXPENSES));
    dispatch(hideAllPopups());
  }, []);

  const handleSelectDoubleCard = useCallback(
    (e) => {
      if (!e.target.closest('[data-id="balance-btn"]')) {
        resetDetailsPage();

        navigate({
          search: objToQueryStr({ virtualCardId: virtualCard.id, physicalCardId: physicalCard.id }),
        });

        // Navigate to details in case mobile
        if (appConfig?.isMobile) {
          navigate(`/dashboard/my_cards/${virtualCard.id}?physicalCardId=${physicalCard.id}`);
        } else {
          dispatch(
            selectSingleCardAction({
              physicalCard,
              virtualCard,
            }),
          );
        }
      }
    },
    [appConfig],
  );

  return (
    <div
      className={`relative  w-full overflow-hidden rounded-xl lg:rounded ${
        isDoubleCardSelected ? 'shadow outline outline-2 outline-gray-3' : ''
      }`}
      onClick={handleSelectDoubleCard}
    >
      <div className="relative z-10">
        <NqoodCard card={virtualCard} enableClickEvent={false} />
      </div>
      <div className="relative -mb-[45%] -translate-y-[70%]">
        <NqoodCard card={physicalCard} showBalanceBtn={false} enableClickEvent={false} />
      </div>
    </div>
  );
}

export default NqoodDoubleCard;
