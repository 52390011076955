/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from 'react-tooltip';
import { ReactComponent as IdPic } from '../assets/svg/id.svg';
import { AppIcon } from './html/html';

function TooltipPackage({
  tooltipMessage,
  hasID,
  id,
  place,
  tooltipTextColor,
  tooltipTextSize,
  tooltipContentClassName,
  children,
  disable,
}) {
  return (
    <div className="relative inline-block">
      <a
        data-tooltip-id={id}
        className={`${`${tooltipTextColor} px-1` || 'px-5  text-gray-6'} ${
          tooltipTextSize ?? 'text-xs'
        } plus_minus_class inline-block font-medium`}
        data-tooltip-variant={tooltipContentClassName ?? 'light'}
      >
        {children ?? <AppIcon iClass="Small" className="fa-regular fa-circle-info" />}
      </a>
      <Tooltip
        place={place ?? 'bottom'}
        id={id}
        className={`z-10 inline-flex w-full max-w-xs flex-col items-center justify-end gap-2.5 bg-gray-1 shadow-lg  ${
          disable ? 'hidden' : ''
        } ${tooltipContentClassName ?? 'font-medium'}`}
      >
        {hasID && <IdPic className="pb-3" style={{ direction: 'ltr', maxWidth: '100%' }} />}
        <p className="text-sm ltr:text-left rtl:text-right">{tooltipMessage}</p>
      </Tooltip>
    </div>
  );
}

export default TooltipPackage;
