import React, { useCallback, useEffect, useRef, useState } from 'react';

function OTPInput({ count = 6, onComplete, isLoading }) {
  const [otp, setOtp] = useState(Array.from({ length: count }, () => ''));
  const inputRefs = useRef(Array.from({ length: count }, () => React.createRef()));

  useEffect(() => {
    // Set the initial focus to the first input
    inputRefs.current[0].current.focus();
  }, []);

  const handleInputChange = useCallback(
    (index) => (event) => {
      const newOtp = [...otp];

      // Clear all other inputs if changing any input
      if (event.target.value && newOtp.slice(index + 1).some((digit) => digit !== '')) {
        newOtp.fill('', index + 1);
      }

      newOtp[index] = event.target.value;

      // Move focus to the next input if available
      if (index < count - 1 && event.target.value) {
        inputRefs.current[index + 1].current.focus();
      }

      setOtp(newOtp);

      // Check if all OTP digits are filled
      if (!newOtp.includes('')) {
        onComplete(newOtp.join(''));
      }
    },
    [otp],
  );

  const handleKeyDown = useCallback(
    (index) => (event) => {
      if (event.key === 'Backspace' && index > 0) {
        // Prevent the default behavior of Backspace, which is to navigate back in the browser
        event.preventDefault();

        // Clear the value of the current input field
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);

        // Move focus to the previous input
        inputRefs.current[index - 1].current.focus();
      } else if (event.key === 'ArrowRight' && index < count - 1) {
        // Move focus to the next input on ArrowRight
        inputRefs.current[index + 1].current.focus();
      } else if (event.key === 'ArrowLeft' && index > 0) {
        // Move focus to the previous input on ArrowLeft
        inputRefs.current[index - 1].current.focus();
      }
    },
    [otp],
  );

  return (
    <div className="flex max-w-[350px] justify-between gap-2" style={{ direction: 'ltr' }}>
      {otp.map((digit, index) => (
        <input
          inputMode="numeric"
          name={`OTP-${index}`}
          autoComplete="off"
          aria-autocomplete="none"
          disabled={isLoading}
          className="h-[45px] w-[40px] rounded-lg border-gray-3 text-center disabled:cursor-not-allowed disabled:opacity-50 xs:h-[60px] xs:w-[55px]"
          key={JSON.stringify(index)}
          type="number"
          maxLength="1"
          value={digit}
          onChange={handleInputChange(index)}
          onKeyDown={handleKeyDown(index)}
          ref={inputRefs.current[index]}
          tabIndex={index + 1}
        />
      ))}
    </div>
  );
}

export default OTPInput;
