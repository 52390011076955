export const SET_FUNDS = 'SET_FUNDS';
export const SET_WIDGET_FUNDS = 'SET_WIDGET_FUNDS';
export const UPDATE_FUND_ITEM = 'UPDATE_FUND_ITEM';

const initialState = {
  funds: [],
  widgetFunds: [],
  selectedFund: null,
};

export const FundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };
    case SET_FUNDS:
      return {
        ...state,
        funds: action.payload,
      };
    case UPDATE_FUND_ITEM: {
      const draft = { ...state };
      const updatedItems = draft.funds.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...draft,
        funds: updatedItems,
      };
    }
    case SET_WIDGET_FUNDS:
      return {
        ...state,
        widgetFunds: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
