import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AppCloseButton from '../../components/AppCloseButton';
import { AppBody, AppHeader } from '../../components/html/html';
import { hideModalAction } from '../../redux/AppModalReducer/AppModalActions';

function RejectionReasonPopup({ documentName, rejectionReason }) {
  const { t } = useTranslation('commonV2');
  const dispatch = useDispatch();

  return (
    <div className="w-[90vw] max-w-[685px] rounded-2xl bg-white p-8 lg:w-[40vw]">
      <div className="flex justify-end">
        <AppCloseButton
          onClick={() => {
            dispatch(hideModalAction());
          }}
        />
      </div>
      <AppHeader h="h4" className="mb-16">
        {t(documentName)}
      </AppHeader>
      <AppBody pClass="Body1Bold" className="text-danger">
        {rejectionReason}
      </AppBody>
    </div>
  );
}

export default RejectionReasonPopup;
