import {AppBody} from "./html/html";

const UserPicture = function ({ initials, className, height, width }) {
  return (
    <div
      className={`${className || ''} ${height || 'h-10 xl:h-16'} ${
        width || 'w-10 xl:w-16'
      } relative flex flex-col justify-center`}
    >
      <AppBody type="div" pClass="Body1Bold" className="flex h-full w-full flex-col justify-center rounded-full border border-black bg-gray-2 text-center text-gray-6">
        {initials}
      </AppBody>
    </div>
  );
};

export default UserPicture;
