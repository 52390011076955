import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../../AppRouter/history';
import AppInput from '../../../components/AppFormInputs/AppInput';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../../components/html/html';
import { PasswordValidationPattern } from '../../../helpers/RegexPatterns';
import { http } from '../../../http';
import { hideModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';
import { setActiveProfileTabIndexAction } from '../../../redux/ProfileReducer/ProfileActions';

function UserChangePassword() {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { t } = useTranslation('profileV2');
  const { loaders } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleChangePassword = useCallback((values) => {
    const formData = new FormData();

    formData.append('old_password', values.oldPassword);
    formData.append('password', values.newPassword);
    formData.append('password_confirmation', values.passwordConfirmation);

    http
      .patch('users/password', null, {
        params: {
          old_password: values.oldPassword,
          password: values.newPassword,
          password_confirmation: values.passwordConfirmation,
        },
        loader: 'handleChangePassword',
      })
      .then(() => {
        if (!isMobile) {
          dispatch(hideModalAction());
        } else {
          history.push('/dashboard/profile/user');
          setTimeout(() => {
            dispatch(setActiveProfileTabIndexAction(2));
          }, 0);
        }
        dispatch(
          showToasterAction({
            type: 'success',
            message: t('Password Changed successfully!'),
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, []);

  const onSubmit = useCallback((values) => {
    handleChangePassword(values);
  }, []);

  const initialValues = useMemo(() => {
    return { oldPassword: '', newPassword: '', passwordConfirmation: '' };
  }, []);

  const validationSchema = yup.object({
    oldPassword: yup
      .string()
      .matches(PasswordValidationPattern, t('Please enter a valid password.'))
      .required(t('This field is required!', { ns: 'commonV2' })),
    newPassword: yup
      .string()
      .matches(PasswordValidationPattern, t('Please enter a valid password.'))
      .required(t('This field is required!', { ns: 'commonV2' })),
    passwordConfirmation: yup
      .string()
      .matches(PasswordValidationPattern, t('Please enter a valid password.'))
      .oneOf([yup.ref('newPassword'), null], t("Password confirmation doesn't match."))
      .required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema, enableReinitialize: true });

  return (
    <>
      {isMobile && (
        <button
          className="mt-8"
          type="button"
          onClick={() => {
            history.push('/dashboard/profile/user');
            setTimeout(() => {
              dispatch(setActiveProfileTabIndexAction(2));
            }, 0);
          }}
        >
          <AppIcon
            className="fa-regular fa-angle-left text-primary rtl:rotate-180"
            iClass="XXLargeFont"
          />
        </button>
      )}

      <AppHeader h="h1" className="mb-24 text-center">
        {t('Change Password')}
      </AppHeader>

      <form onSubmit={formik.handleSubmit} className="mx-auto grid gap-6 lg:w-[80%]">
        <div>
          <AppHeader h="h6" className="mb-2">
            {t('Old Password')}
          </AppHeader>
          <AppInput
            type="password"
            name="oldPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
          />
          {formik.errors.oldPassword && formik.touched.oldPassword && (
            <small className="text-danger">
              {t(formik.errors.oldPassword, { ns: 'commonV2' })}
            </small>
          )}
        </div>

        <div>
          <AppHeader h="h6" className="mb-2">
            {t('New Password')}
          </AppHeader>
          <AppInput
            type="password"
            name="newPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
          />
          {formik.errors.newPassword && formik.touched.newPassword && (
            <small className="text-danger">
              {t(formik.errors.newPassword, { ns: 'commonV2' })}
            </small>
          )}
        </div>

        <div>
          <AppHeader h="h6" className="mb-2">
            {t('Confirmation Password')}
          </AppHeader>
          <AppInput
            type="password"
            name="passwordConfirmation"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passwordConfirmation}
          />
          {formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && (
            <small className="text-danger">
              {t(formik.errors.passwordConfirmation, { ns: 'commonV2' })}
            </small>
          )}
          <AppBody pClass="Caption1Bold" className="mt-2 text-gray-6">
            {t(
              'Be a minimum of 8 characters ،Include at least one lower letter (a-z) ،Include at least one uppercase (A-Z) ، Include at least one number (9-0)',
            )}
          </AppBody>
        </div>

        <AppButton
          button="primary"
          size="lg"
          rounded="md"
          className="mb-12 mt-8 w-full"
          type="submit"
          isLoading={loaders.handleChangePassword}
          disabled={!formik.dirty}
        >
          {t('Confirm', { ns: 'commonV2' })}
        </AppButton>
      </form>
    </>
  );
}

export default UserChangePassword;
