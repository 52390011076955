import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppImageComponent from '../../../../components/AppImageComponent';
import BackBtn from '../../../../components/BackBtn';
import NqoodCardImg from '../../../../components/NqoodCardImg';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../../../components/html/html';
import { devLog } from '../../../../helpers/Utils';
import { cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import {
  setActiveCreateNewCardStepAction,
  setUserAgreeTermsAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';

function CreatePlasticCard() {
  const dispatch = useDispatch();
  const { t } = useTranslation('cardsV2');
  const { useMock } = useSelector((state) => state);
  const {
    createCardUserData,
    userPrimaryCard,
    pendingPrimaryVirtualCard,
    userAgreeTerm,
    selectedCardProgram,
    selectedCardType,
  } = useSelector((state) => state.createNewCard);
  const [agree, setAgree] = useState(userAgreeTerm);
  const [showBackBtn, setShowBackBtn] = useState(!userAgreeTerm);

  const isComingFromCreateOrReplaceStep = useMemo(() => {
    // user selected replace physical card
    if (
      (selectedCardType.name === cardTypesKeys.PHYSICAL &&
        selectedCardProgram.physical_can_create_and_replace) ||
      useMock.plasticCreateAndReplaceMock
    ) {
      return true;
    }

    // user selected replace virtual card
    if (
      (selectedCardType.name === cardTypesKeys.VIRTUAL &&
        selectedCardProgram.virtual_can_create_and_replace) ||
      useMock.virtualCreateAndReplaceMock
    ) {
      return true;
    }

    return false;
  }, [selectedCardType, selectedCardProgram, useMock, cardTypesKeys]);

  const handleNextStep = useCallback(() => {
    if (userPrimaryCard && pendingPrimaryVirtualCard) {
      const pendingVirtualCardStatus = pendingPrimaryVirtualCard?.status?.key;
      devLog(
        `User has ${pendingVirtualCardStatus} Primary Virtual Card, Need to Activate it First!`,
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.manualCardActivation));
    } else {
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.shippingDetails));
    }
  }, [userPrimaryCard, pendingPrimaryVirtualCard]);

  useEffect(() => {
    if (pendingPrimaryVirtualCard) {
      devLog(`Pending Virtual Card Found, Hide Terms and Back Button`);
      setAgree(true);
      setShowBackBtn(false);
      dispatch(setUserAgreeTermsAction(true));
    }
  }, [pendingPrimaryVirtualCard]);

  return (
    <div className="mx-auto mt-[10vh] w-full max-w-[410px] px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <div className="mb-4">
        {showBackBtn && (
          <BackBtn
            className="mb-4"
            onClick={() => {
              if (isComingFromCreateOrReplaceStep) {
                dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createOrReplaceCard));
              } else {
                dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardType));
              }
            }}
          />
        )}
        {userAgreeTerm && <AppHeader h="h3">{t("let's Request your New Plastic Card")}</AppHeader>}

        {!userAgreeTerm && <AppHeader h="h3">{t("let's Create your New Plastic Card")}</AppHeader>}
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t(
            'Your New Plastic Card will be linked to your primary virtual card sharing the same balance as shown below',
          )}
        </AppBody>
      </div>

      <div className="relative mx-auto mb-2 max-w-[350px] overflow-hidden rounded-md shadow-md">
        <AppImageComponent
          src={userPrimaryCard?.design?.attachment}
          skeletonWidth={288}
          skeletonHeight={170}
          alt="Virtual Primary Card"
        />

        <div className="absolute bottom-4 left-5" style={{ direction: 'ltr' }}>
          <p className="mb-6 font-bold">{userPrimaryCard?.last_four}</p>
          <AppHeader h="h6">
            <span className="text-sm">{`${createCardUserData.first_name} ${createCardUserData.last_name}`}</span>
          </AppHeader>
          <p className="text-sm">{userPrimaryCard?.card_name}</p>
        </div>
      </div>

      <div className="my-3 text-center">
        <AppIcon iClass="XLargeFont" className="fa-regular fa-link" />
      </div>

      <div className="relative mx-auto mb-4 max-w-[350px] overflow-hidden rounded-md shadow-md">
        <NqoodCardImg
          bankLogo={selectedCardProgram?.funding_source_type?.bank_logo}
          scheme={selectedCardProgram.scheme}
          skeletonWidth={288}
          skeletonHeight={170}
          type="plastic"
          alt="Plastic Card"
        />

        <div className="absolute bottom-4 left-5 font-bold" style={{ direction: 'ltr' }}>
          <AppHeader h="h6">
            <span className="text-sm text-white">{`${createCardUserData.first_name} ${createCardUserData.last_name}`}</span>
          </AppHeader>
        </div>
      </div>

      {!userAgreeTerm && (
        <div className="mb-4">
          <label className="flex gap-2">
            <input
              type="checkbox"
              className="mt-1 h-5 w-5 rounded checked:bg-black focus:ring-0"
              checked={agree}
              onChange={(e) => {
                setAgree(e.target.checked);
              }}
            />
            <span className="text-sm">
              {t(
                'I am aware that I will be restricted to having only one plastic card per card program associated with my account. and I understand that this plastic card will be linked to this virtual card using the same balance',
              )}
            </span>
          </label>
        </div>
      )}

      <div className="mt-6 flex justify-end gap-3 pb-5">
        <CancelBtn />

        <AppButton disabled={!agree} onClick={handleNextStep} isLoading={false} button="black">
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default CreatePlasticCard;
