import { useSelector } from 'react-redux';
import nqoodBG from '../../../assets/png/nqoodBG.png';
import QuickSignUpForm from './QuickSignUpForm';
import QuickSignUpSuccess from './QuickSignUpSuccess';

function QuickSignUp() {
  const { isFinishAndSuccess } = useSelector((state) => state.auth.quickSignUp);
  return (
    <div
      className="h-[98%] overflow-auto rounded-2xl bg-slate-50 bg-cover bg-center xs:border xs:p-5 lg:p-16"
      style={{
        backgroundImage: `url(${nqoodBG})`,
      }}
    >
      <div className="mx-auto h-full overflow-y-auto rounded-2xl border bg-gray-1 p-5 shadow-lg lg:w-[80%] lg:p-8">
        {!isFinishAndSuccess && <QuickSignUpForm />}
        {isFinishAndSuccess && <QuickSignUpSuccess />}
      </div>
    </div>
  );
}

export default QuickSignUp;
