function AfterSuccessAmountCurrency({ amount, currency }) {
  return (
    <span style={{ direction: 'ltr' }} className="flex gap-1">
      <span>{amount}</span>
      <span>{currency}</span>
    </span>
  );
}

export default AfterSuccessAmountCurrency;
