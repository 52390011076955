import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';

import {Doughnut} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import LoadingError from '../../components/LoadingError';
import {apiGetRequest} from '../../helpers/APIHelper';
import {setAction} from '../../helpers/ActionsHelper';
import {SET_TOP_TAGS} from '../../redux/StatisticsReducer';
import {AppHeader} from "../../components/html/html";

ChartJS.register(ArcElement, Tooltip, Legend);

const TopTags = function ({ daysFilter }) {
  const { t, i18n } = useTranslation(['dashboard']);
  const { tags: topTags } = useSelector((state) => state.statistics);
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(topTags.length === 0);
  const [errored, setErrored] = useState(false);

  const loadTopTags = () => {
    setLoading(true);
    apiGetRequest('transactions/stats/top-tags', cookies.token, {
      days: daysFilter,
      limit: 5,
    })
      .then((response) => {
        dispatch(setAction(SET_TOP_TAGS, response));
        setLoading(false);
        setErrored(false);
      })
      .catch(() => {
        setErrored(topTags.length === 0);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTopTags();
  }, [daysFilter, i18n.language]);

  return (
    <div className="col-span-2 flex flex-grow flex-col justify-start px-5 lg:col-span-1">
        <AppHeader h="h6" className="my-5 self-start capitalize">
        {t('tag_stat')}
        </AppHeader>
      <LoadingError show={errored && !loading} retryCallback={loadTopTags} />
      {loading && <Skeleton containerClassName="p-5" className="w-full" count={3} />}
      {!loading && topTags.length > 0 && (
        <Doughnut
          className="max-h-60 max-w-fit"
          options={{
            cutout: '85%',
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label(context) {
                    let label = context.label || '';

                    if (label) {
                      label += ': ';
                    }
                    label += `${
                      parseFloat(context.formattedValue) < 0
                        ? parseFloat(context.formattedValue) * -1
                        : context.formattedValue
                    }%`;

                    return label;
                  },
                },
              },
              legend: {
                labels: {
                  pointStyleWidth: 6,
                  pointStyleHeight: 6,
                  pointStyle: 'rect',
                  usePointStyle: true,
                  boxWidth: 6,
                  boxHeight: 6,
                },
                position: i18n.language === 'en' ? 'right' : 'left',
                fontFamily: 'Inter',
              },
            },
          }}
          data={{
            labels: topTags.map((tag) => (i18n.language === 'ar' ? tag.tag_name_ar : tag.tag_name)),
            datasets: [
              {
                data: topTags.map((tag) => {
                  if (tag.percentage < 0) {
                    return tag.percentage * 100 * -1;
                  }
                  return tag.percentage * 100;
                }),
                backgroundColor: topTags.map((tag) => tag.tag_color),
                borderColor: topTags.map((tag) => tag.tag_color),
              },
            ],
          }}
          type="doughnut"
        />
      )}
      {!loading && topTags.length === 0 && (
        <Doughnut
          className="max-h-60 max-w-fit"
          options={{
            cutout: '85%',
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                labels: {
                  pointStyleWidth: 6,
                  pointStyleHeight: 6,
                  pointStyle: 'rect',
                  usePointStyle: true,
                  boxWidth: 6,
                  boxHeight: 6,
                },
                position: i18n.language === 'en' ? 'right' : 'left',
                fontFamily: 'Inter',
              },
            },
          }}
          data={{
            labels: ['0%'],
            datasets: [
              {
                data: [100],
                backgroundColor: '#EFF1F5',
                borderColor: '#EFF1F5',
              },
            ],
          }}
          type="doughnut"
        />
      )}
    </div>
  );
};

export default TopTags;
