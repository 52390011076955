import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import deleteImg from '../../assets/svg/deleteAttachmentImg.svg';
import { inPages, receiptPopupIconIds } from '../../helpers/constants';
import { http } from '../../http';
import { hideModalAction } from '../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import {
  getSingleTransactionDetailsAction,
  hideReceiptSectionAction,
  updateCardTransactionsAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';
import {
  getSingleWalletTransactionDetailsAction,
  hideWalletReceiptSectionAction,
  updateWalletTransactionsAction,
} from '../../redux/WalletReducer/WalletActions';
import { AppBody, AppButton, AppHeader } from '../html/html';

function DeleteSplitConfirm({ transactionDetails, inPage }) {
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();
  const { loaders } = useSelector((state) => state);

  const deleteSplit = useCallback(() => {
    http
      .delete(`transactions/split/${transactionDetails.id}`, {
        loader: 'deleteSplit',
      })
      .then((response) => {
        dispatch(
          showToasterAction({
            type: 'success',
            message: t('The split was deleted successfully!'),
          }),
        );
        dispatch(hideModalAction());

        if (inPage === inPages.myCards) {
          dispatch(hideReceiptSectionAction(receiptPopupIconIds.SPLIT_RECEIPT));
          dispatch(getSingleTransactionDetailsAction(transactionDetails.id));
          dispatch(updateCardTransactionsAction(response.data));
        }
        if (inPage === inPages.wallet) {
          dispatch(hideWalletReceiptSectionAction(receiptPopupIconIds.SPLIT_RECEIPT));
          dispatch(getSingleWalletTransactionDetailsAction(transactionDetails.id));
          dispatch(updateWalletTransactionsAction(response.data));
        }
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err.data?.error,
          }),
        );
      });
  }, [transactionDetails]);

  return (
    <div className="h-96 w-80 rounded-lg bg-white">
      <div className="ps-5">
        <img src={deleteImg} alt="delete attachment" />
      </div>
      <div className="px-10">
        <AppHeader h="h6" className="mb-3">
          {t('Are You Sure You Want to Delete split Transaction?')}
        </AppHeader>
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t(
            'When you remove the sub-transaction, you will revert the transaction to its original status, and all the sub-transaction details will be deleted.',
          )}
        </AppBody>
      </div>
      <div className="mt-5 flex items-center justify-center gap-4 font-medium">
        <AppButton
          button="link"
          onClick={() => {
            dispatch(hideModalAction());
          }}
        >
          {t('Discard Changes', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          button="danger"
          className="px-3"
          isLoading={loaders.deleteSplit}
          loadingLabel={t('Deleting', { ns: 'commonV2' })}
          onClick={() => {
            deleteSplit();
          }}
        >
          {t('Delete', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default DeleteSplitConfirm;
