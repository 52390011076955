export const SET_TOP_TAGS = 'SET_TOP_TAGS';
export const SET_TOP_MERCHANTS = 'SET_TOP_MERCHANTS';
export const SET_TOP_DEPARTMENT = 'SET_TOP_DEPARTMENT';
const initialState = {
  tags: [],
  merchants: [],
  departments: [],
};

const StatisticsReducer = (state = initialState, action) => {
  const draft = { ...state };
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };
    case SET_TOP_TAGS:
      draft.tags = action.payload;
      return draft;
    case SET_TOP_MERCHANTS:
      draft.merchants = action.payload;
      return draft;
    case SET_TOP_DEPARTMENT:
      draft.departments = action.payload;
      return draft;
    default:
      return draft;
  }
};

export default StatisticsReducer;
