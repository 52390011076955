import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppNoData from '../../../../components/AppNoData';
import CardDetailsPage from './CardDetailsPage';

function CardDetails() {
  const { singleSelectedCardData } = useSelector((state) => state.cardList);
  const { t } = useTranslation('cardsV2');

  return (
    <>
      {singleSelectedCardData && <CardDetailsPage />}
      {!singleSelectedCardData && (
        <AppNoData className="h-full" message={t('Select a Card to Display Its Details')} />
      )}
    </>
  );
}

export default CardDetails;
