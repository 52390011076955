import { useTranslation } from 'react-i18next';
import AppAlert from '../../../../../components/AppAlert';
import { AppBody } from '../../../../../components/html/html';

function TransferNote() {
  const { t } = useTranslation('walletV2');

  return (
    <div className="lg:w-2/3">
      <AppAlert
        type="info"
        text={
          <AppBody pClass="Body2Medium">
            {t(
              'The actual amount depends on the currency exchange at the time you make the transfer.',
            )}
          </AppBody>
        }
      />
    </div>
  );
}

export default TransferNote;
