const INITIAL_STATE = {
  userCardList: null,
  singleSelectedCardData: null,
};

const CardListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_CARD_LIST': {
      return { ...state, userCardList: action.payload };
    }
    case 'CLEAR_CARD_LIST': {
      return { ...state, userCardList: null };
    }
    case 'SELECT_SINGLE_CARD': {
      const { virtualCard, physicalCard } = action.payload;

      return { ...state, singleSelectedCardData: { virtualCard, physicalCard } };
    }

    case 'CLEAR_SELECTED_SINGLE_CARD': {
      return { ...state, singleSelectedCardData: null };
    }

    case 'UPDATE_SINGLE_COLUMN_CARD': {
      const updated = state.userCardList.map((ele) => {
        if (ele.id === action.payload.id) {
          return {
            ...ele,
            ...action.payload,
          };
        }
        return ele;
      });
      return { ...state, userCardList: updated };
    }
    case 'UPDATE_SINGLE_COLUMN_CARD_STATUS': {
      const { card, cardStatus } = action.payload;

      const updated = state.userCardList.map((ele) => {
        if (ele.id === card.id) {
          return {
            ...ele,
            status: cardStatus,
          };
        }
        return ele;
      });
      return { ...state, userCardList: updated };
    }

    case 'UPDATE_SINGLE_COLUMN_CARD_BALANCE': {
      const updated = state.userCardList.map((ele) => {
        if (ele.id === action.payload.card.id) {
          return {
            ...ele,
            balance: action.payload.balance,
          };
        }
        return ele;
      });

      return { ...state, userCardList: updated };
    }

    default:
      return state;
  }
};

export default CardListReducer;
