import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Spinner } from '../../../assets/svg/spinner.svg';
import { apiPostFormDataRequest } from '../../../helpers/APIHelper';
import { hasAccessToken } from '../../../helpers/Utils';

function VerifyEmail() {
  const { t } = useTranslation(['signup']);
  const [cookies] = useCookies(['token']);
  const params = useParams();
  const [isVerify, setIsSVerify] = useState(true);
  const [error, serError] = useState(null);
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const form = new FormData();
    form.append('token', params.token);
    apiPostFormDataRequest('email/verify', form)
      .then(() => {
        if (hasAccessToken(cookies.token)) {
          navigate('/dashboard');
        } else {
          setIsVerified(true);
        }
      })
      .catch((err) => {
        serError((err && err.data && err.data.error) || t('complete_signup.error'));
        setIsSVerify(false);
      });
  }, [params.token]);

  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-grow flex-col items-center justify-center">
        {isVerify && !isVerified && (
          <>
            <Spinner className="h-10 w-10 animate-spin lg:h-16 lg:w-16" />
            <p>{t('verify_email.sentence')}</p>
          </>
        )}

        {!isVerify && !isVerified && <p>{error}</p>}

        {isVerified && (
          <p>
            {t('complete_signup.complete_login')}{' '}
            <Link to="/auth/login" className="font-bold ltr:pl-1 rtl:pr-1">
              {t('details.login')}
            </Link>
          </p>
        )}
      </div>
    </div>
  );
}

export default VerifyEmail;
