/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {MultiSelect} from 'react-multi-select-component';
import {useNavigate} from 'react-router-dom';
import {SelectInputCustom, TextInput} from '../../../../components/FormInputs/Inputs';
import CompanyStepBar from '../../../../components/Stepbar/CompanyStepBar';
import {HeadlineCenter} from '../../../../components/Titles';
import {apiDeleteRequest, apiGetRequest, apiPostFormDataRequest,} from '../../../../helpers/APIHelper';
import useNotifications from '../../../../helpers/useNotifications';

import ActionButton from '../../../../components/Button/ActionButton';

import {ReactComponent as BlueSpinner} from '../../../../assets/svg/BlueLoading.svg';
import {ReactComponent as BlueInfoIcon} from '../../../../assets/svg/blueInfo.svg';
import {ReactComponent as Spinner} from '../../../../assets/svg/spinner.svg';
import Dropzone from '../../../../components/Upload/Dropzone';
import {
  CardNameValidationPattern,
  NameValidationPattern,
  NumberValidationPattern,
} from '../../../../helpers/RegexPatterns';
import {AppBody} from "../../../../components/html/html";

function FundingMethod() {
  const { t, i18n } = useTranslation(['signup', 'common']);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
    control,
  } = useForm({ mode: 'onBlur' });
  const [cookies] = useCookies(['token']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [, addNotification] = useNotifications();
  const [fundSourceOther, setFundSourceOther] = useState(false);
  const [sendIban, setSendIban] = useState(false);
  const [sendIbanOther, setSendIbanOther] = useState(false);
  const [fileBankStatementError, setFileBankStatementError] = useState(null);
  const [fileBankStatement, setFileBankStatement] = useState([]);
  const [fileIBANLetterError, setFileIBANLetterError] = useState(null);
  const [fileIBANLetter, setFileIBANLetter] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [isSubmittingFill, setIsSubmittingFill] = useState(false);
  const [fundingMethods, setFundingMethods] = useState(null);
  const [deleteFund, setDeleteFund] = useState(false);
  const [editFundObject, setEditFundObject] = useState(null);
  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryObject, setSelectedCountryObject] = useState(null);
  const [editFundSource, setEditFundSource] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [accountTypeOther, setAccountTypeOther] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [countrySelectedReady, setCountrySelectedReady] = useState(false);

  const fundSourceObject = [
    { value: 'Investments', label: t('second_contact.investments') },
    { value: 'Trading Activities', label: t('second_contact.trading_activities') },
    { value: 'Retained Profits', label: t('second_contact.retained_profits') },
    {
      value: 'Other',
      label: `${t('second_contact.other')} (${t('second_contact.please_specify')})`,
    },
  ];
  const accountTypeObject = [
    { value: 'Business Account', label: t('funding_method.business_account') },
    { value: 'Current Account', label: t('funding_method.current_account') },
    { value: 'Saving Account', label: t('funding_method.saving_account') },
    { value: 'Salary Account', label: t('funding_method.salary_account') },
    {
      value: 'Other',
      label: `${t('second_contact.other')} (${t('second_contact.please_specify')})`,
    },
  ];

  const fundSource = (selected) => {
    setSelectedItems(selected);
    setFundSourceOther(false);
    Object.entries(selected).forEach(([, item]) => {
      if (item.value === 'Other') {
        setFundSourceOther(true);
      }
    });
  };

  const onAccountTypeChange = (selected) => {
    setValue('account_type', selected);
    if (selected === 'Other') {
      setAccountTypeOther(true);
    } else {
      setAccountTypeOther(false);
    }
  };

  useEffect(() => {
    if (editFundSource) {
      if (
        editFundSource.length > 0 &&
        !editFundSource[editFundSource.length - 1].includes([
          'Investments',
          'Trading Activities',
          'Retained Profits',
        ])
      ) {
        setValue('fund_source_other', editFundSource[editFundSource.length - 1]);
      }

      const editArray = [];
      Object.entries(fundSourceObject).forEach(([key, value]) => {
        if (editFundSource.includes(value.value)) {
          editArray.push(fundSourceObject[key]);
          if (value.value === 'Other') {
            setFundSourceOther(true);
          }
        }
      });
      setValue('fund_source', editArray);
      setSelectedItems(editArray);
    }
  }, [editFundSource]);

  const onInputChange = (e) => {
    setValue('country_code', e);
  };

  const fetchFundMethods = () => {
    apiGetRequest('funding_method', cookies.token, {}, [], {}, true).then(([response]) => {
      if (response.length) {
        setFundingMethods(response);
      } else {
        setFundingMethods(null);
      }
    });
  };

  const deleteFundMethods = (fundId) => {
    setDeleteFund(true);
    apiDeleteRequest(`funding_method/${fundId}`, {}, cookies.token).finally(() => {
      fetchFundMethods();
      setDeleteFund(false);
    });
  };

  useEffect(() => {
    fetchFundMethods();
  }, [isSubmitting]);

  const fetchIBANData = () => {
    setIsSubmittingFill(true);
    apiGetRequest(`funding_method/iban/${document.getElementById('iban').value}`, cookies.token)
      .then((response) => {
        setValue('bank_name', response.bank && response.bank.bank_name);
        setSelectedCountry(response.iso_alpha3);
        setValue('account_number', response.bank_account);
        setValue('swift', response.bank && response.bank.bic);
        setValue('currency', response.currency_code);
      })
      .catch(() => setCountrySelectedReady(true))
      .finally(() => {
        setIsSubmittingFill(false);
        setSendIban(true);
      });
  };

  const onSubmit = (data) => {
    if (!fileIBANLetter || (fileIBANLetter && fileIBANLetter.length === 0)) {
      setFileIBANLetterError(t('funding_method.iban_file_required'));
      return false;
    }

    if (!fileBankStatement || (fileBankStatement && fileBankStatement.length === 0)) {
      setFileBankStatementError(t('funding_method.statement_file_required'));
      return false;
    }

    const form = new FormData();
    setIsSubmitting(true);

    if (editFundObject) {
      form.append('id', editFundObject.id);
    }

    form.append('bank_name', data.bank_name);
    form.append('country_code', data.country_code);
    form.append('account_holder_name', data.account_holder_name);
    form.append('iban', data.iban);
    form.append('swift', data.swift);
    form.append('account_number', data.account_number);
    form.append(
      'account_type',
      data.account_type === 'Other' ? data.account_type_other : data.account_type,
    );
    form.append('currency', data.currency);

    if (fileIBANLetter && fileIBANLetter.length > 0) {
      form.append('fileIBANLetter', fileIBANLetter[0]);
    }
    if (fileBankStatement && fileBankStatement.length > 0) {
      form.append('fileBankStatement', fileBankStatement[0]);
    }

    let fundSources = [];
    for (const property in selectedItems) {
      if (selectedItems[property].value !== 'Other') {
        fundSources = [...fundSources, selectedItems[property].value];
      }
    }
    if (fundSourceOther && data.fund_source_other) {
      fundSources = [...fundSources, data.fund_source_other];
    }
    form.append('fund_source', fundSources.join('|'));

    apiPostFormDataRequest('funding_method', form, cookies.token)
      .then(() => {
        setSendIban(false);
        setSendIbanOther(false);
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setIsSubmitting(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setEditFundObject(null);
      });
    return true;
  };

  useEffect(() => {
    apiGetRequest('countries', cookies.token).then((countriesResponse) => {
      setCountries(countriesResponse);
    });

    clearErrors();
  }, [i18n.language]);

  useEffect(() => {
    if (editFundObject) {
      setFileIBANLetterError(null);
      setFileIBANLetter([]);
      setFileBankStatementError(null);
      setFileBankStatement([]);
    }
    setValue('account_number', editFundObject && editFundObject.account_number);
    setValue('bank_name', editFundObject && editFundObject.bank_name);
    setValue('country_code', editFundObject && editFundObject.country.code);
    setValue('account_holder_name', editFundObject && editFundObject.account_holder_name);
    setValue('iban', editFundObject && editFundObject.iban);
    setValue('swift', editFundObject && editFundObject.swift);
    setValue('currency', editFundObject && editFundObject.currency);
    setEditFundSource(
      editFundObject && editFundObject.fund_source && editFundObject.fund_source.split('|'),
    );

    if (
      editFundObject &&
      editFundObject.account_type !== 'Business Account' &&
      editFundObject.account_type !== 'Current Account' &&
      editFundObject.account_type !== 'Saving Account' &&
      editFundObject.account_type !== 'Salary Account' &&
      accountTypeObject &&
      accountTypeObject.length > 0
    ) {
      setValue('account_type_other', editFundObject && editFundObject.account_type);
      setValue('account_type', accountTypeObject[accountTypeObject.length - 1]);
      setSelectedAccountType(accountTypeObject[accountTypeObject.length - 1]);
    } else {
      setValue('account_type', editFundObject && editFundObject.account_type);
      setSelectedAccountType(
        editFundObject &&
          accountTypeObject.filter((acc) => acc.value === editFundObject.account_type),
      );
    }
  }, [editFundObject]);

  useEffect(() => {
    if (selectedCountry && countries) {
      const defaultCountry = countries.filter((count) => count.code === selectedCountry);
      if (defaultCountry.length > 0) {
        setValue('country_code', selectedCountry);
        setSelectedCountryObject(defaultCountry[0]);
      }
      setCountrySelectedReady(true);
    }
  }, [selectedCountry, countries]);

  useEffect(() => {
    if (editFundObject && editFundObject.country && countries) {
      const defaultCountry = countries.filter(
        (count) => count.code === editFundObject.country.code,
      );
      if (defaultCountry.length > 0) {
        setValue('country_code', editFundObject.country.code);
        setSelectedCountryObject(defaultCountry[0]);
      }
      setCountrySelectedReady(true);
    }
  }, [editFundObject, countries]);
  return (
    <div className="mx-auto flex w-full flex-col lg:w-8/12">
      <CompanyStepBar step={4} />
      <div className="m-auto mb-5 mt-0">
        <HeadlineCenter>{t('funding_method.title')}</HeadlineCenter>
        <AppBody type="span" pClass="Body2Regular" className="centerTitle relative  max-w-md pb-4 pt-2 text-center text-gray-6">
          {t('funding_method.subtitle')}
        </AppBody>
      </div>

      <AppBody type="div" pClass="Body2Regular"
        className="mb-5 mt-2 rounded-xl border border-blue-50 bg-blue-50 px-5 py-3 text-cyan-600 ltr:text-left rtl:text-right"
      >
        <BlueInfoIcon className="inline ltr:pr-2 rtl:pl-2" />
        <span className="block">{t('funding_method.hint')}</span>
        <AppBody pClass="Body1Bold" type="span" className="block">{t('funding_method.hint1')}</AppBody>
      </AppBody>

      {fundingMethods &&
        fundingMethods.map((item) => (
          <div
            className="mb-3 w-full rounded-xl border border-gray-6 p-5 lg:w-2/3"
            id={`fund-${item.id}`}
          >
            <div className="grid grid-cols-4" key={`fund_${item.id}`}>
              <div className="col-span-3 my-2 flex-grow">
                <span className="block">
                  <b>{item.account_holder_name}</b>
                </span>
                <span className="block">
                  {`${item.fund_source && item.fund_source.replaceAll('|', ', ')} - ${
                    item.account_type
                  } - ${item.bank_name}`}
                </span>
                <span className="block">{item.iban}</span>
                <span className="block">
                  <b>{item.currency}</b>
                  {' - '}
                  {item.country && item.country.name}
                </span>
                <span className="block">{`${item.account_number} - ${item.swift}`}</span>
              </div>
              <div className="col-span-1 justify-end">
                <button
                  type="button"
                  className="text-md bg-transparent text-gray-6"
                  onClick={() => {
                    deleteFundMethods(item.id);
                  }}
                >
                  {!deleteFund && <span>{t('delete', { ns: 'common' })}</span>}
                  {deleteFund && <Spinner className="h-6 w-6 animate-spin fill-gray" />}
                </button>
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="text-primary"
                  onClick={() => {
                    setEditFundObject(item);
                  }}
                >
                  {t('edit', { ns: 'common' })}
                </button>
              </div>
            </div>
          </div>
        ))}

      {(!fundingMethods || sendIbanOther || editFundObject) && (
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className=" rounded-xl border border-gray-3 p-5">
            <div className="grid grid-cols-2 gap-5 pt-5">
              <AppBody type="label" pClass="Body2Regular"
                className="col-span-2 flex flex-col lg:col-span-1"
                htmlFor="fund_source"
              >
                <AppBody pClass="Body2bold" type="span">{t('funding_method.inputs.fund_source')}</AppBody>
                <MultiSelect
                  id="fund_source"
                  disableSearch
                  onChange={fundSource}
                  options={fundSourceObject}
                  value={selectedItems}
                  className="mt-1 rounded-full border border-gray-3 "
                  hasSelectAll={false}
                  overrideStrings={{
                    selectSomeItems: t('select', { ns: 'common' }),
                    allItemsAreSelected: t('all_selected', { ns: 'common' }),
                  }}
                />

                {fundSourceOther && (
                  <div className="mx-4 rounded-b-2xl bg-gray-2 p-2">
                    <TextInput
                      className="mx-2 mt-1 rounded-full border border-gray-3  px-10 py-1 lg:mx-20"
                      placeholder={t('second_contact.please_specify')}
                      error={errors.fund_source_other}
                      id="fund_source_other"
                      {...register('fund_source_other', {
                        required: t('funding_method.inputs.validation.required.fund_source'),
                      })}
                    />
                  </div>
                )}
              </AppBody>
              <AppBody type="label" pClass="Body2Regular"
                className="col-span-2 flex flex-col lg:col-span-1"
                htmlFor="account_type"
              >
                <AppBody pClass="Body2bold" type="span">{t('funding_method.inputs.account_type')}</AppBody>
                {(selectedAccountType || !editFundObject) && (
                  <SelectInputCustom
                    onChange={(e) => onAccountTypeChange(e)}
                    error={errors.account_type}
                    name="account_type"
                    id="account_type"
                    control={control}
                    rules={{
                      required: t('funding_method.inputs.validation.required.account_type'),
                    }}
                    options={accountTypeObject}
                    defaultValue={editFundObject && selectedAccountType}
                  />
                )}

                {accountTypeOther && (
                  <div className="mx-4 rounded-b-2xl bg-gray-2 p-2">
                    <TextInput
                      className="mx-2 mt-1 rounded-full border border-gray-3  px-10 py-1 lg:mx-20"
                      placeholder={t('second_contact.please_specify')}
                      error={errors.account_type_other}
                      id="account_type_other"
                      {...register('account_type_other', {
                        required: t('funding_method.inputs.validation.required.account_type'),
                      })}
                    />
                  </div>
                )}
              </AppBody>
            </div>

            <div className="grid grid-cols-2 gap-5 pt-5">
              <AppBody type="label" pClass="Body2Regular"
                className="col-span-2 flex flex-col lg:col-span-1"
                htmlFor="account_holder_name"
              >
                <AppBody pClass="Body2bold" type="span">
                  {t('funding_method.inputs.account_holder_name')}
                </AppBody>
                <TextInput
                  style={{ direction: 'ltr' }}
                  error={errors.account_holder_name}
                  id="account_holder_name"
                  {...register('account_holder_name', {
                    required: t('funding_method.inputs.validation.required.account_holder_name'),
                    pattern: {
                      value: CardNameValidationPattern,
                      message: t('funding_method.inputs.validation.patterns.account_holder_name'),
                    },
                  })}
                />
              </AppBody>

              <AppBody type="label" pClass="Body2Regular"
                className="relative col-span-2 flex flex-col lg:col-span-1"
                htmlFor="iban"
              >
                <AppBody pClass="Body2bold" type="span">{t('funding_method.inputs.iban')}</AppBody>
                <TextInput
                  error={errors.iban}
                  id="iban"
                  {...register('iban', {
                    required: t('funding_method.inputs.validation.required.iban'),
                  })}
                />
                <div className="flex flex-col">
                  <button
                    type="button"
                    className="text-md flex cursor-pointer flex-row justify-end bg-transparent text-left align-baseline text-sm font-bold capitalize text-gray-6 text-primary disabled:bg-gray-3"
                    onClick={fetchIBANData}
                  >
                    <span>{t('send', { ns: 'common' })}</span>
                    {isSubmittingFill && (
                      <BlueSpinner className="h-6 w-6 animate-spin fill-white" />
                    )}
                  </button>
                </div>
              </AppBody>
            </div>

            {(sendIban || editFundObject) && (
              <>
                <div className="grid grid-cols-2 gap-5">
                  <AppBody type="label" pClass="Body2Regular"
                    className="col-span-2 flex flex-col lg:col-span-1"
                    htmlFor="bank_name"
                  >
                    <AppBody pClass="Body2bold" type="span">
                      {t('funding_method.inputs.bank_name')}
                    </AppBody>
                    <TextInput
                      id="bank_name"
                      error={errors.bank_name}
                      {...register('bank_name', {
                        required: t('funding_method.inputs.validation.required.bank_name'),
                        pattern: {
                          value: NameValidationPattern,
                          message: t('funding_method.inputs.validation.patterns.bank_name'),
                        },
                      })}
                    />
                  </AppBody>
                  <label className="col-span-2 flex flex-col lg:col-span-1" htmlFor="country_code">
                    <AppBody pClass="Body2bold" type="span">{t('funding_method.inputs.country')}</AppBody>
                    {!countries && <Skeleton />}
                    {countries && countrySelectedReady && (
                      <SelectInputCustom
                        onChange={(e) => onInputChange(e)}
                        defaultValue={
                          selectedCountryObject && {
                            value: selectedCountryObject.code,
                            label: selectedCountryObject.name,
                          }
                        }
                        error={errors.country_code}
                        name="country_code"
                        id="country_code"
                        control={control}
                        rules={{
                          required: t('funding_method.inputs.validation.required.country'),
                        }}
                        options={countries.map(({ code: value, name: label, ...rest }) => ({
                          value,
                          label,
                          ...rest,
                        }))}
                      />
                    )}
                  </label>
                </div>

                <div className="grid grid-cols-2 gap-5 pt-5">
                  <label
                    className="col-span-2 flex flex-col lg:col-span-2"
                    htmlFor="account_number"
                  >
                    <AppBody pClass="Body2bold" type="span">
                      {t('funding_method.inputs.account_number')}
                    </AppBody>
                    <TextInput
                      id="account_number"
                      error={errors.account_number}
                      {...register('account_number', {
                        required: t('funding_method.inputs.validation.required.account_number'),
                        pattern: {
                          value: NumberValidationPattern,
                          message: t('funding_method.inputs.validation.patterns.account_number'),
                        },
                      })}
                    />
                  </label>
                </div>

                <div className="grid grid-cols-2 gap-5 pt-5">
                  <label className="col-span-2 flex flex-col lg:col-span-1" htmlFor="currency">
                    <AppBody pClass="Body2bold" type="span">{t('second_contact.inputs.currency')}</AppBody>
                    <TextInput
                      id="currency"
                      error={errors.currency}
                      {...register('currency', {
                        required: t('second_contact.inputs.validation.required.currency'),
                      })}
                      maxLength={5}
                    />
                  </label>
                  <label className="col-span-2 flex flex-col lg:col-span-1" htmlFor="swift">
                    <AppBody pClass="Body2bold" type="span">{t('funding_method.inputs.swift')}</AppBody>
                    <TextInput
                      id="swift"
                      error={errors.swift}
                      {...register('swift', {
                        required: t('funding_method.inputs.validation.required.swift'),
                      })}
                    />
                  </label>
                </div>

                <div className="pb-10">
                  <Dropzone
                    oldValue={
                      editFundObject
                        ? editFundObject.attachments.filter(
                            (fund) => fund.file_type === 'Proof of the IBAN letter',
                          )
                        : null
                    }
                    setFile={setFileIBANLetter}
                    file={fileIBANLetter}
                    fileError={fileIBANLetterError}
                    setFileError={setFileIBANLetterError}
                    fileHint={t('rejectReason', { ns: 'common' })}
                    rejectReason={t('rejectReason_simple', { ns: 'common' })}
                    uploadInput={t('documents_or_photos', { ns: 'common' })}
                    label={t('funding_method.inputs.Proof of the IBAN letter')}
                    labelHint={t('funding_method.upload_hint')}
                    acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
                  />
                </div>

                <div className="pb-10">
                  <Dropzone
                    oldValue={
                      editFundObject
                        ? editFundObject.attachments.filter(
                            (fund) => fund.file_type === 'Bank statement',
                          )
                        : null
                    }
                    setFile={setFileBankStatement}
                    file={fileBankStatement}
                    fileError={fileBankStatementError}
                    setFileError={setFileBankStatementError}
                    fileHint={t('rejectReason', { ns: 'common' })}
                    rejectReason={t('rejectReason_simple', { ns: 'common' })}
                    uploadInput={t('documents_or_photos', { ns: 'common' })}
                    label={t('funding_method.inputs.Bank statement for the last six months')}
                    labelHint={t(
                      'funding_method.Please upload a valid  Bank statement for the last six months, show both the IBAN number and the company name of that account.',
                    )}
                    acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
                  />
                </div>

                <div className="mt-5 flex flex-row justify-end self-stretch pt-5">
                  {fundingMethods && (
                    <ActionButton
                      className="flex flex-row"
                      type="button"
                      text={t('cancel', { ns: 'common' })}
                      onClick={() => {
                        setSendIbanOther(false);
                        setEditFundObject(null);
                      }}
                    />
                  )}
                  <ActionButton
                    className="flex flex-row"
                    type="submit"
                    primary
                    text={t('save', { ns: 'common' })}
                    isLoading={isSubmitting}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      )}

      {fundingMethods && !sendIbanOther && !editFundObject && (
        <div className="mt-5 flex flex-row justify-end self-stretch pt-5">
          <ActionButton
            className="flex flex-row"
            type="button"
            text={t('funding_method.add_another_iban')}
            onClick={() => {
              setFileIBANLetter([]);
              setFileIBANLetterError(null);
              setFileBankStatement([]);
              setFileBankStatementError(null);
              setSendIbanOther(true);
              setValue('bank_name', '');
              setValue('country_code', '');
              setValue('account_holder_name', '');
              setValue('iban', '');
              setValue('swift', '');
              setValue('account_number', '');
              setValue('fund_source', '');
              setValue('fund_source_other', '');
              setSelectedCountryObject(null);
              setCountrySelectedReady(false);
              setSelectedItems([]);
              setValue('currency', '');
              setValue('account_type_other', '');
              setSelectedAccountType(null);
              setValue('account_type', selectedAccountType);
            }}
          />
          <ActionButton
            className="flex flex-row"
            type="button"
            primary
            text={t('continue', { ns: 'common' })}
            onClick={() => {
              navigate('/signup/verify_company/accept_terms');
            }}
          />
        </div>
      )}
    </div>
  );
}

export default FundingMethod;
