import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Nqoodlet from '../../../assets/png/nqoodlet.png';
import { ReactComponent as Attachment } from '../../../assets/svg/attachments.svg';
import { ReactComponent as SplitBlueIcon } from '../../../assets/svg/blue_split.svg';
import { ReactComponent as PrintIcon } from '../../../assets/svg/print.svg';
import { ReactComponent as Receipt } from '../../../assets/svg/receipt.svg';
import { ReactComponent as SplitIcon } from '../../../assets/svg/split.svg';
import { apiGetRequest, apiPostRequest } from '../../../helpers/APIHelper';

import ActionButton from '../../../components/Button/ActionButton';
import BackButton from '../../../components/Button/BackButton';
import LinkButton from '../../../components/Button/LinkButton';
import { TextArea } from '../../../components/FormInputs/Inputs';
import LoadingError from '../../../components/LoadingError';
import {
  Details,
  Headline,
  Headline2,
  Info,
  LargerTitle2,
  PrintTitle,
  SmallButton,
  XSmallText,
} from '../../../components/Titles';
import TooltipPackage from '../../../components/TooltipPackage';
import { AppBody } from '../../../components/html/html';
import { setAction } from '../../../helpers/ActionsHelper';
import { formatDate } from '../../../helpers/Utils';
import useNotifications from '../../../helpers/useNotifications';
import usePermissions from '../../../hooks/usePermissions';
import { PATCH_TRANSACTION, UPDATE_TAGS } from '../../../redux/TransactionsReducer';
import Head from '../../Head';
import AttachmentPreview from './Attachment/AttachmentPreview';
import UploadAttachmentPopup from './Attachment/UploadAttachmentPopup';
import ReceiptPreview from './Receipt/ReceiptPreview';
import DeleteSplitPopup from './Split/DeleteSplitPopup';
import SplitActions from './Split/SplitActions';
import SplitPart from './Split/SplitPart';
import TagsDropdown from './TagsDropdown';
import TransactionAttachmentDownload from './TransactionAttachmentDownload';

export default function TransactionDetailsPage({ isSplit }) {
  const params = useParams();
  return <TransactionDetails transactionId={params.id} isSplit={isSplit} />;
}

export function TransactionDetails({ transactionId, isSplit }) {
  const { user } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation(['transactions', 'common']);
  const [transaction, setTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const { tags } = useSelector((state) => state.transactions);
  const [, setAttachmentRender] = useState(false);
  const wrapperRef = useRef();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [cookies] = useCookies(['token']);
  const [, addNotification] = useNotifications();
  const [editDescription, setEditDescription] = useState(false);
  const [editDescriptionLength, setEditDescriptionLength] = useState(0);
  const [removeSplit, setRemoveSplit] = useState(false);
  const [removedSplit, setRemovedSplit] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(false);
  const [editReceipt, setEditReceipt] = useState(false);
  const [editTags, setEditTags] = useState(false);
  const editDescriptionHandler = () => {
    setValue('reason', transaction && transaction.reason);
    setEditDescriptionLength(transaction && transaction.reason.length);
    setEditDescription(true);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userCan = usePermissions();

  const fetchTransaction = () => {
    setIsLoading(true);
    apiGetRequest(`transactions/${transactionId}`, cookies.token)
      .then((response) => {
        setTransaction(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTransaction();
  }, [transactionId]);

  useEffect(() => {
    if (editTags) {
      fetchTransaction();
      setEditTags(false);
    }
  }, [editTags]);

  useEffect(() => {
    apiGetRequest('tags', cookies.token).then((data) => {
      dispatch(setAction(UPDATE_TAGS, data));
    });
  }, []);

  useEffect(() => {
    setEditDescription(false);
    setRemoveSplit(false);
    setRemovedSplit(false);
    setEditReceipt(false);
    setUploadDocument(false);
  }, [transaction]);

  const onSubmit = (data) => {
    apiPostRequest(
      `transactions/${transaction.id}`,
      {
        reason: data.reason,
      },
      cookies.token,
      {},
      false,
      'put',
    )
      .then((response) => {
        addNotification(t('des_added'));
        setEditDescription(false);
        dispatch(setAction(PATCH_TRANSACTION, response));
        setTransaction(response);
      })
      .catch((err) => {
        setEditDescription(false);
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      });
  };

  useEffect(() => {
    setEditDescription(false);
  }, [transaction]);

  if (!transaction) {
    return (
      <div className="flex w-full flex-col lg:w-10/12">
        <Head title={`${isSplit ? 'Expense' : 'Transactions'} Details`} />
        <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
          <div className="col-span-6 flex flex-col justify-start">
            {isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchTransaction} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col ltr:text-left rtl:text-right ${
        !isSplit && 'mx-auto w-full lg:w-8/12'
      } ${removeSplit ? 'overflow-x-hidden bg-gray-200 font-sans text-gray-900 antialiased' : ''}`}
    >
      {removeSplit && (
        <DeleteSplitPopup
          transaction={transaction}
          setRemoveSplit={setRemoveSplit}
          setRemovedSplit={setRemovedSplit}
          setTransaction={setTransaction}
        />
      )}

      {uploadDocument && (
        <UploadAttachmentPopup
          transactionId={transaction.id}
          setUploadDocument={setUploadDocument}
          setTransaction={setTransaction}
        />
      )}

      <div className="flex flex-row items-center justify-between px-2 lg:px-10">
        {!isSplit && <BackButton />}
        <div>
          {transaction.transaction_type.toLowerCase() !== 'split' &&
            transaction.can_upload_reciept && (
              <a
                title={!transaction.has_receipt ? t('add_receipt') : t('replace_receipt')}
                type="button"
                className="inline-block pb-2 pt-1 text-sm text-primary ltr:pr-4 ltr:font-light rtl:px-2 rtl:font-normal"
                href="#attachment_upload"
              >
                <Attachment className="inline-block" />{' '}
                <span className="hidden lg:inline">{t('attachment')}</span>
              </a>
            )}

          {(userCan('upload_account_receipt') ||
            (userCan('upload_own_transaction_receipt') && user.id === transaction.user_id?.id) ||
            userCan('edit_account_transaction_receipt') ||
            (userCan('edit_own_transaction_receipt') && user.id === transaction.user_id?.id)) &&
            transaction.can_upload_reciept && (
              <a
                title={!transaction.has_receipt ? t('add_receipt') : t('replace_receipt')}
                type="button"
                className="inline-block pb-2 pt-1 text-sm text-primary ltr:pr-4 ltr:font-light rtl:px-2 rtl:font-normal"
                href="#receipt_upload"
              >
                <Receipt className="inline-block" />{' '}
                <span className="hidden lg:inline">
                  {!transaction.has_receipt ? t('add_receipt') : t('replace_receipt')}
                </span>
              </a>
            )}

          {(userCan('create_account_transaction_splits') ||
            (userCan('create_own_transaction_splits') && user.id === transaction.user_id?.id)) &&
            ((Array.isArray(transaction.splits) && transaction.splits.length === 0) ||
              removedSplit) &&
            transaction.transaction_type.toLowerCase() === 'auth' &&
            transaction.status.toLowerCase() !== 'declined' && (
              <button
                title={t('split_transaction')}
                type="button"
                className="inline-block pb-2 pt-1 text-sm text-primary ltr:pr-4 ltr:font-light rtl:pl-4 rtl:font-normal"
                onClick={() => {
                  navigate(
                    `/dashboard/${isSplit ? 'expenses' : 'transactions'}/split/${transaction.id}`,
                  );
                }}
              >
                <SplitBlueIcon className="inline-block ltr:mr-1 rtl:ml-1" />{' '}
                <span className="hidden lg:inline">{t('split_transaction')}</span>
              </button>
            )}
          <ReactToPrint
            content={() => wrapperRef.current}
            documentTitle={`receipt_${transaction.transaction_reference}`}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <button
                  title={t('print', { ns: 'common' })}
                  type="button"
                  className="no_print pb-10 text-right text-sm text-primary"
                  onClick={handlePrint}
                >
                  <PrintIcon className="w-15 inline" />{' '}
                  <span className="hidden lg:inline">{t('print', { ns: 'common' })}</span>
                </button>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      </div>

      <div ref={wrapperRef} className="print_container">
        {/* PRINT TABLE Content */}
        <table className="w-full">
          <thead>
            <tr>
              <td>
                <div className="header-space">&nbsp;</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* PRINT TABLE Content */}
                <div className="flex flex-col">
                  <div
                    className="no_print flex flex-col items-start justify-between border-b-4 border-gray-2 border-opacity-40 px-2 py-5 lg:flex-row lg:px-10"
                    style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
                  >
                    <LargerTitle2 className="w-full break-all ltr:pr-5 rtl:pl-5">
                      {transaction.transaction_reference}
                    </LargerTitle2>
                    <AppBody
                      pClass="Body1Bold"
                      style={{ minWidth: '150px' }}
                      className="w-full pt-1 ltr:text-right ltr:tracking-tight rtl:text-left rtl:tracking-normal"
                    >
                      {Array.isArray(transaction.splits) &&
                        transaction.splits.length > 0 &&
                        !removedSplit && (
                          <SplitIcon className="inline-block p-0.5 ltr:mr-2 rtl:ml-2" />
                        )}
                    </AppBody>
                  </div>
                  <div className="my-1 bg-gray-1 p-2 lg:p-5">
                    <div className="grid grid-cols-3 gap-x-3 gap-y-5">
                      <div className="col-span-2">
                        <Details className="text-gray-6">
                          <div className="flex items-center py-1">
                            {t('transaction_amount')}
                            <TooltipPackage tooltipMessage={t('amount_hint')} id="infoPreview" />
                            <div className="h-px flex-grow bg-gray-5" />
                          </div>
                        </Details>
                      </div>
                      <AppBody
                        pClass="Body2bold"
                        type="div"
                        className="col-span-1"
                        style={{ direction: 'ltr' }}
                      >
                        {transaction.transaction_amount >= 0
                          ? `${parseFloat(transaction.transaction_amount).toLocaleString()} ${
                              transaction.transaction_currency
                            }`
                          : `(${parseFloat(
                              transaction.transaction_amount * -1,
                            ).toLocaleString()}) ${transaction.transaction_currency}`}
                      </AppBody>
                    </div>
                    <div className="grid grid-cols-3 gap-x-3 gap-y-5">
                      <div className="col-span-2">
                        <Details className="text-gray-6">
                          <div className="flex items-center py-1">
                            {t('billing_amount')}
                            <TooltipPackage tooltipMessage={t('billing_hint')} id="infoPreview1" />
                            <div className="h-px flex-grow bg-gray-5" />
                          </div>
                        </Details>
                      </div>
                      <AppBody
                        pClass="Body2bold"
                        type="div"
                        className="col-span-1"
                        style={{ direction: 'ltr' }}
                      >
                        {transaction.billing_amount >= 0
                          ? `${parseFloat(transaction.billing_amount).toLocaleString()} ${
                              transaction.billing_currency
                            }`
                          : `(${parseFloat(transaction.billing_amount * -1).toLocaleString()}) ${
                              transaction.billing_currency
                            }`}
                      </AppBody>
                    </div>
                    <div className="grid grid-cols-3 gap-x-3 gap-y-5">
                      <div className="col-span-2">
                        <Details className="text-gray-6">
                          <div className="flex items-center py-1">
                            {t('fx_fees')}
                            <TooltipPackage tooltipMessage={t('fx_hint')} id="infoPreview2" />
                            <div className="h-px flex-grow bg-gray-5" />
                          </div>
                        </Details>
                      </div>
                      <AppBody
                        pClass="Body2bold"
                        type="div"
                        className="col-span-1"
                        style={{ direction: 'ltr' }}
                      >
                        {transaction.fx_fee}
                      </AppBody>
                    </div>
                  </div>
                  <div className="my-1 bg-gray-1 p-2 lg:p-5">
                    <div className="flex flex-col justify-start text-left">
                      <SmallButton>
                        {t('status')}
                        {': '}
                        {i18n.language === 'ar'
                          ? `${transaction.status_description_ar} (${transaction.status_ar})`
                          : `${transaction.status_description} (${transaction.status})`}
                      </SmallButton>
                    </div>
                  </div>

                  {transaction.relative && (
                    <div className="my-1 bg-gray-1 p-2 lg:p-5">
                      <div className="flex flex-col justify-start text-left">
                        <Details className="block text-gray-6">
                          {transaction.transaction_type.toLowerCase() === 'auth' &&
                            transaction.relative.transaction_type.toLowerCase() === 'settled' &&
                            t('successfully_settled')}
                          {transaction.transaction_type.toLowerCase() === 'auth' &&
                            transaction.relative.transaction_type.toLowerCase() === 'reversal' &&
                            t('reversed_through')}
                          {transaction.transaction_type.toLowerCase() === 'settled' &&
                            t('settlement_for')}
                          {transaction.transaction_type.toLowerCase() === 'reversal' &&
                            t('reversal_for')}
                        </Details>
                        <SmallButton className="block">
                          <LinkButton
                            className="inline text-primary"
                            text={`(${transaction.relative.id})`}
                            onClick={() => {
                              navigate(`../transactions/details/${transaction.relative.id}`);
                            }}
                          />{' '}
                          {t('on')}{' '}
                          {formatDate(
                            transaction.relative.transaction_timestamp,
                            'DD / MMM / YYYY',
                          )}
                        </SmallButton>
                      </div>
                    </div>
                  )}

                  <div className="my-1 bg-gray-1 p-2 lg:p-5">
                    <div className="my-2 grid w-full grid-cols-2 gap-x-3 gap-y-5 lg:grid-cols-3">
                      <div className="col-span-1 lg:col-span-1">
                        <Details className="block text-gray-6">{t('name')}</Details>
                        <SmallButton className="block">
                          {i18n.language !== 'ar' && transaction.user_id
                            ? `${transaction.user_id.first_name} ${transaction.user_id.last_name}`
                            : ''}
                          {i18n.language === 'ar' && transaction.user_id
                            ? `${transaction.user_id.first_name_ar} ${transaction.user_id.last_name_ar}`
                            : ''}
                        </SmallButton>
                      </div>
                      <div className="col-span-1 lg:col-span-1">
                        <Details className="block text-gray-6">{t('date')}</Details>
                        <SmallButton className="block">
                          {formatDate(transaction.transaction_timestamp, 'DD / MMM / YYYY')}
                        </SmallButton>
                      </div>
                      <div className="col-span-1 lg:col-span-1">
                        <Details className="block text-gray-6">{t('time')}</Details>
                        <SmallButton className="block">
                          {formatDate(transaction.transaction_timestamp, 'HH:mm')}
                        </SmallButton>
                      </div>
                      <div className="col-span-1 lg:col-span-1">
                        <Details className="block text-gray-6">{t('merchant')}</Details>
                        <SmallButton className="block">{transaction.merchant_name}</SmallButton>
                      </div>
                      <div className="col-span-1 lg:col-span-1">
                        <Details className="block text-gray-6">{t('country')}</Details>
                        <SmallButton className="block">{transaction.merchant_country}</SmallButton>
                      </div>
                      <div className="col-span-1 lg:col-span-1">
                        <Details className="block text-gray-6">{t('city')}</Details>
                        <SmallButton className="block">{transaction.merchant_city}</SmallButton>
                      </div>
                    </div>
                  </div>
                  <div className="my-1 bg-gray-1 p-2 lg:p-5">
                    <div className="my-2 grid w-full grid-cols-2 gap-x-3 gap-y-5 lg:grid-cols-3">
                      <div className="col-span-1 lg:col-span-1">
                        <Details className="block text-gray-6">{t('card_details')}</Details>
                        <SmallButton className="block">{`${transaction.network}**${transaction.card_last4} ${transaction.card_name}`}</SmallButton>
                      </div>
                      <div className="col-span-1 lg:col-span-1">
                        <Details className="block text-gray-6">{t('transaction_type')}</Details>
                        <SmallButton className="block">
                          {i18n.language === 'ar'
                            ? transaction.transaction_type_ar
                            : transaction.transaction_type}
                        </SmallButton>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <Details className="block text-gray-6">{t('category')}</Details>
                        <SmallButton className="block">
                          {i18n.language === 'ar'
                            ? transaction.mcc?.name_ar
                            : transaction.mcc?.name_en}
                        </SmallButton>
                      </div>
                    </div>
                  </div>

                  <div className="my-1 bg-gray-1 p-2 lg:p-5">
                    <div className="my-2 grid w-full grid-cols-2 gap-x-3 gap-y-5">
                      <div className="col-span-2 lg:col-span-1">
                        <div className="mb-4 mt-6 block">
                          <Details className="block text-gray-6">{t('department_input')}</Details>
                          <Info className="block">
                            {!transaction.department_id && '---'}
                            {i18n.language === 'ar'
                              ? transaction.department_id?.name_ar
                              : transaction.department_id?.name}
                          </Info>
                        </div>
                        {(!transaction.splits ||
                          (Array.isArray(transaction.splits) &&
                            transaction.splits.length === 0)) && (
                          <div className="mb-4 block">
                            <Details className="block text-gray-6">{t('transaction_tag')}</Details>
                            <Info className="block">
                              {(userCan('add_account_transaction_tag') ||
                                userCan('edit_account_transaction_tag')) && (
                                <TagsDropdown
                                  className="w-full"
                                  transactionID={transaction.id}
                                  tags={tags}
                                  selectedTags={transaction.tags}
                                  noTagsEdit={false}
                                  setTransaction={setTransaction}
                                  setEditTags={setEditTags}
                                />
                              )}
                              {!userCan('add_account_transaction_tag') &&
                                userCan('add_own_transaction_tag') &&
                                user.id === transaction.user_id?.id &&
                                !transaction.tags && (
                                  <TagsDropdown
                                    className="w-full"
                                    transactionID={transaction.id}
                                    tags={tags}
                                    selectedTags={transaction.tags}
                                    noTagsEdit={false}
                                    setTransaction={setTransaction}
                                    setEditTags={setEditTags}
                                  />
                                )}
                              {!userCan('edit_account_transaction_tag') &&
                                userCan('edit_own_transaction_tag') &&
                                user.id === transaction.user_id?.id &&
                                transaction.tags && (
                                  <TagsDropdown
                                    className="w-full"
                                    transactionID={transaction.id}
                                    tags={tags}
                                    selectedTags={transaction.tags}
                                    noTagsEdit={false}
                                    setTransaction={setTransaction}
                                    setEditTags={setEditTags}
                                  />
                                )}
                              {!userCan('add_account_transaction_tag') &&
                                !userCan('edit_account_transaction_tag') &&
                                !userCan('add_own_transaction_tag') &&
                                !userCan('edit_own_transaction_tag') && (
                                  <TagsDropdown
                                    className="w-full"
                                    transactionID={transaction.id}
                                    tags={tags}
                                    selectedTags={transaction.tags}
                                    noTagsEdit
                                    setTransaction={setTransaction}
                                    setEditTags={setEditTags}
                                  />
                                )}
                            </Info>
                          </div>
                        )}
                        <div className="block ">
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="no_print flex flex-grow flex-col items-start justify-start self-stretch"
                          >
                            <Details className="relative block flex flex-row text-gray-6">
                              {t('description')}{' '}
                              {(userCan('add_account_transaction_description') ||
                                userCan('edit_account_transaction_description') ||
                                (userCan('add_own_transaction_description') &&
                                  user.id === transaction.user_id?.id) ||
                                (userCan('edit_own_transaction_description') &&
                                  user.id === transaction.user_id?.id)) &&
                                !editDescription && (
                                  <LinkButton
                                    className="no_print flex flex-row px-2 text-primary"
                                    text={
                                      transaction.reason === '' || !transaction.reason === ''
                                        ? t('add', { ns: 'common' })
                                        : t('edit', { ns: 'common' })
                                    }
                                    onClick={editDescriptionHandler}
                                  />
                                )}
                              {editDescription && (
                                <ActionButton
                                  className="no_print -mt-0.5 flex flex-row px-2 py-0 text-sm font-normal text-primary"
                                  text={t('save', { ns: 'common' })}
                                  type="submit"
                                />
                              )}
                            </Details>
                            <div className="block w-full max-w-xs">
                              {!editDescription && <Info>{transaction.reason ?? ''}</Info>}
                              {editDescription && (
                                <div className="flex w-full flex-col justify-start">
                                  <input
                                    type="hidden"
                                    name="transactionId"
                                    id="transactionId"
                                    value={transaction.id}
                                    {...register('transactionId')}
                                  />
                                  <div className="flex flex-grow flex-row self-start self-stretch">
                                    <AppBody
                                      pClass="Body1Bold"
                                      type="label"
                                      htmlFor="reason"
                                      className="flex flex-grow flex-col"
                                    >
                                      <TextArea
                                        {...register('reason', {
                                          required: t('reason_required'),
                                        })}
                                        existValue={editDescriptionLength}
                                        error={errors.reason}
                                        id="reason"
                                        maxLength="150"
                                        className={`${
                                          errors.reason ? 'border-red-400' : 'border-gray-3'
                                        } mt-0.5 w-full resize-none self-stretch rounded-lg border  p-2`}
                                      />
                                    </AppBody>
                                  </div>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="col-span-2 lg:col-span-1">
                        <div
                          className="no_print flex flex-col justify-center rounded-lg text-center"
                          id="receipt_upload"
                        >
                          <ReceiptPreview
                            transaction={transaction}
                            setEditReceipt={setEditReceipt}
                            editReceipt={editReceipt}
                            receipt={
                              transaction && transaction.has_receipt
                                ? transaction.receipts.filter(
                                    (rec) => rec.doc_type === 'Receipt',
                                  )[0]
                                : null
                            }
                            setTransaction={setTransaction}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {Array.isArray(transaction.splits) && transaction.splits.length > 0 && (
                    <div className="my-1 bg-gray-1 p-2 lg:p-5">
                      {!removedSplit && (
                        <SplitActions
                          transaction={transaction}
                          setRemoveSplit={setRemoveSplit}
                          isSplit={isSplit}
                        />
                      )}
                      {!removedSplit &&
                        transaction.splits.map((split, index) => (
                          <SplitPart
                            transaction={transaction}
                            split={split}
                            tags={tags}
                            index={index}
                            setEditTags={setEditTags}
                          />
                        ))}
                    </div>
                  )}

                  <div className="no_print my-1 bg-gray-1 p-2 lg:p-5">
                    {transaction.transaction_type.toLowerCase() !== 'split' &&
                      transaction.can_upload_reciept && (
                        <div
                          className="no_print mt-10 flex flex-row justify-between text-center"
                          id="attachment_upload"
                        >
                          <Headline2>{t('upload_attachments')}</Headline2>
                          {(userCan('upload_account_receipt') ||
                            (userCan('upload_own_transaction_receipt') &&
                              user.id === transaction.user_id?.id) ||
                            userCan('edit_account_transaction_receipt') ||
                            (userCan('edit_own_transaction_receipt') &&
                              user.id === transaction.user_id?.id)) &&
                            transaction.receipts.length <= 10 &&
                            transaction.can_upload_reciept && (
                              <ActionButton
                                primary
                                className="text-md no_print -mt-0.5 flex flex-row px-2 px-4 py-0 font-normal"
                                text={t('add', { ns: 'common' })}
                                type="button"
                                onClick={() => {
                                  setUploadDocument(true);
                                }}
                              />
                            )}
                        </div>
                      )}
                    <div className="no_print mt-10 grid grid-cols-3 gap-x-3 gap-y-5">
                      {transaction &&
                        transaction.receipts
                          .filter((item) => item.doc_type !== 'Receipt')
                          .map((attachment) => (
                            <AttachmentPreview
                              attachment={attachment}
                              transaction={transaction}
                              setTransaction={setTransaction}
                            />
                          ))}
                    </div>
                  </div>
                  {/* Attachment Appear in Print */}
                  {(removedSplit ||
                    (Array.isArray(transaction.splits) &&
                      transaction.splits.length === 0 &&
                      transaction.receipts.length > 0)) && (
                    <div className="hidePrint w-full">
                      <PrintTitle className="mb-3">{t('attachment_uploaded')}</PrintTitle>
                      {transaction.receipts.map((attachment, index) => (
                        <Headline2>
                          <span className="text-gray-6">#{index + 1}</span> {t(attachment.doc_type)}
                        </Headline2>
                      ))}
                      {transaction.receipts.map((attachment) => {
                        if (attachment.type.includes('application/')) {
                          return (
                            <div className="page-break-after">
                              <Headline className="mb-2">{t(attachment.doc_type)}</Headline>
                              <TransactionAttachmentDownload
                                forPrint
                                setAttachmentRender={setAttachmentRender}
                                attachmentId={attachment.id}
                                attachmentPath={attachment.path}
                                transaction={transaction}
                              />
                            </div>
                          );
                        }

                        return (
                          <div className="page-break-after">
                            <Headline className="mb-2">{t(attachment.doc_type)}</Headline>
                            <img
                              src={attachment.path}
                              className="m-auto h-auto max-h-fit w-auto max-w-full rounded-lg"
                              alt="attachment"
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!removedSplit &&
                    Array.isArray(transaction.splits) &&
                    transaction.splits.length > 0 &&
                    transaction.splits.map((split) => (
                      <div className="hidePrint w-full">
                        {split.receipts.length > 0 &&
                          split.receipts.map((attachment) => {
                            if (attachment?.type?.includes('application/')) {
                              return (
                                <div className="page-break-after">
                                  <TransactionAttachmentDownload
                                    forPrint
                                    setAttachmentRender={setAttachmentRender}
                                    attachmentId={attachment.id}
                                    attachmentPath={attachment.path}
                                    transaction={transaction}
                                  />
                                </div>
                              );
                            }

                            return (
                              <div className="page-break-after">
                                <img
                                  src={attachment.path}
                                  className="m-auto h-auto max-h-fit w-auto max-w-full rounded-lg"
                                  alt="attachment"
                                />
                              </div>
                            );
                          })}
                      </div>
                    ))}
                  {/* End Attachment Appear in Print */}

                  {/* PRINT TABLE Content */}
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="footer-space" />
              </td>
            </tr>
          </tfoot>
        </table>
        {/* PRINT TABLE Content */}

        {/* PRINT HEADER AND FOOTER */}
        <div className="divHeaderPrint flex w-full flex-row items-center justify-between">
          <XSmallText className="text-black">{transaction.transaction_reference}</XSmallText>
          <img
            alt="logo"
            src={Nqoodlet}
            className="mx-10 w-1/5"
            style={{
              maxHeight: '90px',
            }}
          />
        </div>
        <div
          className="divFooterPrint flex w-full flex-row justify-between"
          style={{ maxHeight: '90px' }}
        >
          <XSmallText className="text-black">Copyright &copy; Nqoodlet</XSmallText>
          <XSmallText className="page-number" />
        </div>
        {/* PRINT HEADER AND FOOTER */}
      </div>
    </div>
  );
}
