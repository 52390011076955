function NqoodLogo({ color, size = 8, mode = 'background' }) {
  return (
    <div className="relative">
      <div
        className="rotate-45"
        style={{
          backgroundColor: color || 'gray',
          width: size,
          height: size,
          borderRadius: `${size / 6}px`,
          opacity: mode === 'background' ? 0.5 : 1,
        }}
      />

      {mode === 'background' && (
        <div
          className="absolute -right-[50%] top-[50%] -translate-y-[50%] rotate-45"
          style={{
            backgroundColor: color || 'gray',
            width: size,
            height: size,
            borderRadius: `${size / 6}px`,
            opacity: 0.5,
          }}
        />
      )}

      {mode === 'logo' && (
        <div
          className="absolute -right-[50%] top-[50%] -translate-y-[50%] rotate-45"
          style={{
            border: `${size / 12}px solid ${color}`,
            width: size / 1.2,
            height: size / 1.2,
            borderRadius: `${size / 8}px`,
          }}
        />
      )}
    </div>
  );
}

export default NqoodLogo;
