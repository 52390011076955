import {useDispatch, useSelector} from 'react-redux';
import AppCloseButton from '../../../../../../components/AppCloseButton';
import {walletTabs} from '../../../../../../helpers/constants';
import {hideModalAction} from '../../../../../../redux/AppModalReducer/AppModalActions';
import {hideWalletReceiptPopupAction} from '../../../../../../redux/WalletReducer/WalletActions';
import WalletRequestIcons from './WalletRequestIcons';
import WalletTransactionsIcons from './WalletTransactionsIcons';
import WalletFundsActivityIcons from './WalletFundsActivityIcons';
import WalletCardsActivityIcons from './WalletCardsActivityIcons';

function WalletReceiptPopupHeader({
  walletRequestPrintWrapperRef,
  walletTransactionPrintWrapperRef,
  walletFundActivityPrintWrapperRef,
  walletCardActivityPrintWrapperRef,
}) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { selectedWalletMainActiveTab, selectedWalletSubActiveTab } = useSelector(
    (state) => state.wallet,
  );
  const dispatch = useDispatch();

  return (
    <div className="sticky top-0 z-30 flex justify-between bg-white py-5 print:hidden">
      <div className="flex gap-5">
        {/* ============= [Transactions] Icons ============= */}
        {selectedWalletMainActiveTab === walletTabs.TRANSACTIONS && (
          <WalletTransactionsIcons
            walletTransactionPrintWrapperRef={walletTransactionPrintWrapperRef}
          />
        )}

        {/* ============= [Requests] Icons ============= */}
        {selectedWalletMainActiveTab === walletTabs.REQUESTS && (
          <WalletRequestIcons walletRequestPrintWrapperRef={walletRequestPrintWrapperRef} />
        )}

        {/* ============= [Fund Activity] Icons ============= */}
        {selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
          selectedWalletSubActiveTab === walletTabs.FUNDSACTIVITY && (
            <WalletFundsActivityIcons
              walletRequestPrintWrapperRef={walletFundActivityPrintWrapperRef}
            />
          )}

        {/* ============= [Card Activity] Icons ============= */}
        {selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
          selectedWalletSubActiveTab === walletTabs.CARDSACTIVITY && (
            <WalletCardsActivityIcons
              walletRequestPrintWrapperRef={walletCardActivityPrintWrapperRef}
            />
          )}
      </div>

      {/* Close Icon */}
      <div>
        <AppCloseButton
          onClick={() => {
            dispatch(hideWalletReceiptPopupAction());
            if (isMobile) {
              dispatch(hideModalAction());
            }
          }}
        />
      </div>
    </div>
  );
}

export default WalletReceiptPopupHeader;
