import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cardStatusKeys, inPages, usersStatus } from '../../helpers/constants';
import usePermissions from '../../hooks/usePermissions';
import SingleCardDetailsReplace from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/SingleCardDetailsReplace';
import SingleCardDetailsStatus from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/SingleCardDetailsStatus';
import CardInfoPopup from '../../modules/Dashboard/MyCards/CardDetails/CardInfoPopup';
import CardSettingsPopup from '../../modules/Dashboard/MyCards/CardDetails/CardSettingsPopup';
import {
  addSettingsPopupDataAction,
  showCardInfoPopupAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';
import AppImageComponent from '../AppImageComponent';
import TooltipPackage from '../TooltipPackage';
import { AppBody, AppButton, AppIcon } from '../html/html';

function SingleCardDetailsCardDesktop({ card, cardDetails, inPage }) {
  const { appConfig, auth } = useSelector((state) => state);
  const { singleUserDetails } = useSelector((state) => state.users);

  const userCan = usePermissions();

  const isUserActive = useMemo(() => {
    let isActive = auth?.user?.status === usersStatus.Active;

    if (inPage === inPages.singleUserCardDetails) {
      isActive = singleUserDetails.status === usersStatus.Active;
    }

    return isActive;
  }, [auth, inPage]);

  const cardStatus = useMemo(() => {
    return card.status?.key.toUpperCase();
  }, [card, cardDetails]);

  const cardProgramStatus = useMemo(() => {
    return card.cardproduct.status?.toUpperCase();
  }, [card]);

  const isClosedCardProgram = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(cardProgramStatus);
  }, [cardProgramStatus]);

  const cardNameColor = useMemo(() => {
    if (
      cardStatus === cardStatusKeys.INACTIVE ||
      cardStatus === cardStatusKeys.REPLACED ||
      isClosedCardProgram
    ) {
      return 'gray';
    }

    return card.design?.color_hex;
  }, [card, isClosedCardProgram]);

  const cardImageColorClass = useMemo(() => {
    if (cardStatus === cardStatusKeys.REPLACED || isClosedCardProgram) {
      return 'grayscale';
    }

    if (cardStatus === cardStatusKeys.INACTIVE) {
      return 'grayscale blur-[2px]';
    }

    if (cardStatus !== cardStatusKeys.ACTIVE) {
      return 'blur-[2px]';
    }

    return '';
  }, [cardStatus, isClosedCardProgram]);

  const showCardStatus = useMemo(() => {
    return (
      (cardStatus === cardStatusKeys.INACTIVE || cardStatus === cardStatusKeys.SUSPENDED) &&
      !isClosedCardProgram
    );
  }, [cardStatus, isClosedCardProgram]);

  const showCardReplace = useMemo(() => {
    return (
      (cardStatus === cardStatusKeys.EXPIRED || cardStatus === cardStatusKeys.TERMINATED) &&
      !isClosedCardProgram &&
      isUserActive
    );
  }, [cardStatus, isClosedCardProgram, isUserActive]);

  const disableShowInfo = useMemo(() => {
    return (
      !userCan('show_card_secret') ||
      auth.user?.id !== card.user_id ||
      cardStatus !== cardStatusKeys.ACTIVE
    );
  }, [auth, cardStatus]);

  const canChangeStatus = useMemo(() => {
    let canChangeAccountCardStatus = userCan('change_account_card_status');
    if (
      canChangeAccountCardStatus &&
      cardStatus === cardStatusKeys.INACTIVE &&
      card.is_physical &&
      auth.user?.id !== card.user_id
    ) {
      canChangeAccountCardStatus = false;
    }
    const canChangeOwnCardStatus =
      userCan('change_own_card_status') && auth.user?.id === card.user_id;

    return canChangeAccountCardStatus || canChangeOwnCardStatus;
  }, [auth, card]);

  const canRenewStatus = useMemo(() => {
    const canChangeAccountCardStatus = userCan('renew_account_card');
    const canChangeOwnCardStatus = userCan('renew_own_card') && auth.user?.id === card.user_id;

    return canChangeAccountCardStatus || canChangeOwnCardStatus;
  }, [auth, card]);
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const showExpiryDate = useMemo(() => {
    return cardStatus === cardStatusKeys.ACTIVE || cardStatus === cardStatusKeys.SUSPENDED;
  }, [cardStatus]);

  return (
    <div className="relative w-full">
      <CardInfoPopup card={card} />

      <div className="relative w-52 overflow-hidden rounded-md shadow-lg">
        {/* ================ Card Image ================ */}
        {card?.design?.attachment && (
          <AppImageComponent
            src={card?.design?.attachment}
            alt="card"
            skeletonHeight={125}
            className={cardImageColorClass}
          />
        )}

        {/* ================ Card Name ================ */}
        {!card.is_physical && (
          <AppBody
            type="div"
            pClass="Body2Regular"
            className={`absolute left-3 top-3 flex items-center gap-2 ${
              cardStatus !== cardStatusKeys.ACTIVE ? 'blur-[2px]' : ''
            }`}
            style={{ color: cardNameColor }}
          >
            <AppIcon iClass="Default" className="fa-kit fa-nqoodlet-logo-english" />
          </AppBody>
        )}

        {/* ================ Card Image Placeholder ================ */}
        {!card?.design?.attachment && <div className="h-32 w-full bg-gray-6" />}

        {/* ================ Card Statuses ================ */}
        {showCardStatus && canChangeStatus && (
          <SingleCardDetailsStatus card={card} cardDetails={cardDetails} />
        )}

        {showCardReplace && canRenewStatus && (
          <SingleCardDetailsReplace inPage={inPage} card={card} cardDetails={cardDetails} />
        )}

        <div
          className={`absolute bottom-3 left-4 flex flex-col ${
            card?.is_physical ? 'text-white' : 'text-black'
          }`}
          style={{ direction: 'ltr' }}
        >
          {(cardStatus === cardStatusKeys.TERMINATED || cardStatus === cardStatusKeys.EXPIRED) && (
            <AppBody pClass="Caption2Medium">
              {/* ================ Card Status Key ================ */}
              {t(capitalize(card?.status.key))}

              {/* ================ Card Tooltip ================ */}
              {cardStatus === cardStatusKeys.TERMINATED && (
                <TooltipPackage
                  tooltipTextSize="text-xxs"
                  tooltipTextColor={card?.is_physical ? 'text-white' : 'text-black'}
                  tooltipContentClassName="bg-slate-50 font-normal leading-normal text-black"
                  place="top"
                  tooltipMessage={
                    appConfig.lang === 'en'
                      ? card?.status?.status_reason
                      : card?.status?.status_reason_ar
                  }
                  id={`info_terminate_reason_${card?.id}`}
                />
              )}
            </AppBody>
          )}

          <AppBody
            type="div"
            pClass="Caption2Medium"
            style={{ direction: 'ltr' }}
            className="tracking-wide"
          >
            {/* ================ Card Last Four ================ */}
            {cardStatus !== cardStatusKeys.INACTIVE && <span>{card?.last_four}</span>}

            {/* ================ Card Expiry Date ================ */}
            {showExpiryDate && (
              <span className={`ml-2 inline ${card?.is_physical ? 'text-white' : 'text-black'}`}>
                <span className="mr-[1px] inline-block w-4 text-[5px] leading-[5px]">
                  VALID THRU
                </span>
                <span className="text-xxs">{card.expiry_date}</span>
              </span>
            )}
          </AppBody>
        </div>
      </div>

      <div className="rounded-2xl bg-white">
        {/* ================ Card Buttons ================ */}
        <div className="relative mt-2 flex items-center justify-around font-bold">
          <CardSettingsPopup card={card} inPage={inPage} />

          <AppButton
            disabled={!isUserActive}
            button="link"
            className=" flex items-center gap-1"
            onClick={() => {
              dispatch(addSettingsPopupDataAction({ card }));
            }}
          >
            <span>
              <AppIcon iClass="BaseFont" className="fa-regular fa-toggle-off hidden pt-0.5" />
            </span>
            <AppBody pClass="Caption1Bold" type="span">
              {t('Settings')}
            </AppBody>
          </AppButton>

          <AppButton
            disabled={disableShowInfo}
            button="link"
            className="hidden items-center gap-1 lg:flex"
            onClick={() => {
              dispatch(showCardInfoPopupAction(card));
            }}
          >
            <span>
              <AppIcon iClass="Default" className="fa-kit fa-credit hidden" />
            </span>
            <AppBody pClass="Caption1Bold" type="span">
              {t('Show Info')}
            </AppBody>
          </AppButton>
        </div>
      </div>
    </div>
  );
}

export default SingleCardDetailsCardDesktop;
