import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cardStatusKeys, settingsPopupContent } from '../../../../../helpers/constants';

import AppSpinner from '../../../../../components/AppSpinner';
import HoverableFontIcon from '../../../../../components/HoverableFontIcon';
import { AppIcon } from '../../../../../components/html/html';
import { devLog } from '../../../../../helpers/Utils';
import { http } from '../../../../../http';
import { showToasterAction } from '../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  addSettingsPopupDataAction,
  setCurrentSettingsPopupContent,
  updateSelectedCardDetailsAction,
} from '../../../../../redux/CardDetailsReducer/CardDetailsActions';

function SingleCardDetailsStatus({ card, cardDetails }) {
  const { loaders } = useSelector((state) => state);
  const { lang, isMobile } = useSelector((state) => state.appConfig);
  const { t } = useTranslation(['cardsV2', 'commonV2']);

  const dispatch = useDispatch();

  const cardStatus = useMemo(() => {
    return card.status?.key.toUpperCase();
  }, [card, cardDetails]);

  const style = useMemo(() => {
    if (card.is_physical) {
      return { color: 'white' };
    }
    return {
      color:
        cardStatus === cardStatusKeys.INACTIVE ? '#000000' : card?.design?.color_hex ?? '#000000',
    };
  }, [card, cardStatus]);

  const iconClass = useMemo(() => {
    if (cardStatus === cardStatusKeys.INACTIVE) {
      return lang === 'ar' ? 'fa-kit fa-active-ar' : 'fa-kit fa-active';
    }

    if (cardStatus === cardStatusKeys.SUSPENDED) {
      return lang === 'ar' ? 'fa-kit fa-suspended-ar' : 'fa-kit fa-suspended';
    }

    return '';
  }, [card, lang, cardStatus, isMobile]);

  const hoverIconClass = useMemo(() => {
    if (cardStatus === cardStatusKeys.INACTIVE) {
      return lang === 'ar' ? 'fa-kit fa-click-active-ar' : 'fa-kit fa-click-active';
    }

    if (cardStatus === cardStatusKeys.SUSPENDED) {
      return lang === 'ar' ? 'fa-kit fa-click-suspend-ar' : 'fa-kit fa-click-suspend';
    }

    return '';
  }, [card, lang, cardStatus]);

  const mobileStatus = useMemo(() => {
    if (cardStatus === cardStatusKeys.INACTIVE) {
      return (
        <>
          <AppIcon className="fa-kit fa-smile font-normal" iClass="LargeFont" />
          <span>{t('New')}</span>
        </>
      );
    }

    if (cardStatus === cardStatusKeys.SUSPENDED) {
      return (
        <>
          <AppIcon className="fa-kit fa-lock font-normal" iClass="LargeFont" />
          <span>{t('Suspended')}</span>
        </>
      );
    }

    if (cardStatus === cardStatusKeys.TERMINATED) {
      return (
        <>
          <AppIcon className="fa-kit fa-replace font-normal" iClass="LargeFont" />
          <span>{t('Terminated')}</span>
        </>
      );
    }

    if (cardStatus === cardStatusKeys.EXPIRED) {
      return (
        <>
          <AppIcon className="fa-kit fa-replace font-normal" iClass="LargeFont" />
          <span>{t('Expired')}</span>
        </>
      );
    }

    return '';
  }, [card, cardStatus]);

  const handleSimplifiActivate = useCallback(() => {
    dispatch(addSettingsPopupDataAction({ card }));
    dispatch(setCurrentSettingsPopupContent(settingsPopupContent.verifyLastFour));
  }, [card]);

  const normalActivate = useCallback(() => {
    const cardId = card.id;

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('status', cardStatusKeys.ACTIVE);

    http
      .post(`cardmanager/cards/${cardId}/status`, formData, {
        loader: `handleActivateCard[${cardId}]`,
      })
      .then(() => {
        dispatch(
          showToasterAction({
            type: 'success',
            message: t('Activated Successfully'),
          }),
        );

        dispatch(
          updateSelectedCardDetailsAction({
            card: {
              ...card,
              status: {
                ...card.status,
                key: cardStatusKeys.ACTIVE,
                name: t('Active'),
              },
            },
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, [card]);

  const handleActivateCard = useCallback(() => {
    if (
      card.is_physical &&
      (cardStatus === cardStatusKeys.INACTIVE || cardStatus === cardStatusKeys.PENDING)
    ) {
      handleSimplifiActivate();
      devLog(`Card is [Physical] and it's status is ${cardStatus}`);
    } else {
      normalActivate();
    }
  }, [card, cardStatus]);

  return (
    <>
      {!isMobile && !loaders[`handleActivateCard[${card.id}]`] && (
        <button
          type="button"
          className="absolute bottom-10 left-4"
          onClick={() => {
            handleActivateCard();
          }}
        >
          <HoverableFontIcon
            iconClass={iconClass}
            hoverIconClass={hoverIconClass}
            className="text-3xl"
            style={style}
          />
        </button>
      )}

      {isMobile && (
        <div
          className={`flex items-center justify-center gap-1 text-xxs ${
            card.is_physical ? 'text-white' : ''
          }`}
        >
          {mobileStatus}
        </div>
      )}

      {loaders[`handleActivateCard[${card.id}]`] && (
        <div className="bottom-10 left-4 lg:absolute">
          <AppSpinner size={24} fill={style.color} />
        </div>
      )}
    </>
  );
}

export default SingleCardDetailsStatus;
