import { Turnstile } from '@marsidev/react-turnstile';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../components/Button/ActionButton';
import { TextInput } from '../../components/FormInputs/Inputs';
import { HeadlineCenter } from '../../components/Titles';
import TooltipPackage from '../../components/TooltipPackage';
import { apiPostFormDataRequest } from '../../helpers/APIHelper';
import { EmailValidationPattern } from '../../helpers/RegexPatterns';
import useNotifications from '../../helpers/useNotifications';
import Head from '../Head';
import { AppBody } from '../../components/html/html';

function ForgetPassword() {
  const { t, i18n } = useTranslation(['forget', 'common']);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: 'onBlur' });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHcapthchaValid, setIsHcapthchaValid] = useState(false);
  const [, addNotification] = useNotifications();
  const navigate = useNavigate();

  const submitForm = (data) => {
    setIsSubmitting(true);
    const form = new FormData();
    form.append('email', data.email);
    form.append('id_number', data.id_number);

    apiPostFormDataRequest('email/forget', form)
      .then(() => {
        navigate('/forget_password_thanks');
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);
  return (
    <div className="flex h-full items-center justify-center">
      <div className="m-auto flex w-full flex-col lg:w-6/12">
        <Head title="Forget Password" />
        <div className="flex flex-col text-center">
          <HeadlineCenter>{t('headline')}</HeadlineCenter>
          <AppBody type="span" pClass="Body2Regular" className="pt-2 text-gray-6">
            {t('subtitle')}
          </AppBody>
        </div>
        <form
          className="flex flex-grow flex-col items-center justify-start pt-10"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="flex w-full max-w-sm pb-5">
            <label htmlFor="id_number" className="flex w-full flex-col">
              <AppBody type="span" pClass="Body1Bold" className="relative">
                {t('inputs.id_number')}
                <TooltipPackage tooltipMessage={t('id_hint')} hasID id="infoPreview" />
              </AppBody>

              <TextInput
                type="text"
                error={errors.id_number}
                {...register('id_number', {
                  required: t('validation.required.id_number'),
                })}
              />
            </label>
          </div>
          <div className="flex w-full max-w-sm pb-5">
            <AppBody
              type="label"
              pClass="Body1Bold"
              htmlFor="email"
              className="flex w-full flex-col"
            >
              {t('inputs.email')}
              <TextInput
                error={errors.email}
                id="email"
                {...register('email', {
                  required: t('validation.required.email'),
                  pattern: {
                    value: EmailValidationPattern,
                    message: t('validation.patterns.email'),
                  },
                })}
              />
            </AppBody>
          </div>
          <div className="flex w-full max-w-sm pb-5">
            <input
              type="hidden"
              {...register('hcaptcha', {
                validate: {
                  hcaptche: (value) => {
                    if (!isHcapthchaValid) {
                      return t('validation.required.hcaptcha');
                    }
                    return null;
                  },
                },
              })}
            />
            <Turnstile
              autoResetOnExpire
              siteKey="0x4AAAAAAACsFx_X-jL1J_nl"
              onError={() => {
                setIsHcapthchaValid(false);
              }}
              onSuccess={() => {
                setIsHcapthchaValid(true);
              }}
              options={{
                theme: 'light',
                size: 'flexible',
                language: i18n.language,
              }}
            />
            <AppBody pClass="Caption1Regular" className="pt-2 text-xs text-red-700">
              {errors.hcaptcha && errors.hcaptcha.message}
            </AppBody>
          </div>

          <div className="mt-10 flex w-full flex-row items-baseline justify-end pt-2">
            <ActionButton
              className="flex flex-row"
              text={t('cancel', { ns: 'common' })}
              onClick={() => {
                navigate(-1);
              }}
            />
            <ActionButton
              className="flex flex-row"
              isLoading={isSubmitting}
              primary
              text={t('send', { ns: 'common' })}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgetPassword;
