import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { RealNumberInput, TextInput } from '../FormInputs/Inputs';

function TableFilter({
  filterObject,
  setSelectedFilters,
  selectedFilters,
  showFilterRow,
  setShowFilterRow,
  applyFilters,
  resetFilter,
  split,
  setPage,
}) {
  const filterKeys = filterObject && filterObject.map((filterObj) => filterObj.key);
  const selectedKeys = selectedFilters.map((selectedFilter) => selectedFilter.key);
  const filterDiff = filterKeys && filterKeys.filter((key) => !selectedKeys.includes(key));
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFFFFF80',
      borderColor: 'rgb(199,208,213)',
      fontWeight: 'normal',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingLeft: '0rem',
      paddingRight: '0rem',
      minHeight: '30px',
      marginTop: '0rem',
      boxShadow: 'none',
      borderRadius: '5px',
      fontSize: '0.75rem',
      lineHeight: '1rem',
    }),
  };

  useEffect(() => {
    if (selectedFilters.length === 0) {
      if (!showFilterRow) {
        resetFilter();
      }
      setShowFilterRow(!showFilterRow);
    }
  }, [selectedFilters]);

  return (
    <form id="filterForm">
      {selectedFilters.map((filter) => (
        <FilterItem
          customStyles={customStyles}
          applyFilters={applyFilters}
          setPage={setPage}
          filter={filter}
          filterDiff={filterDiff}
          selectedFilters={selectedFilters}
          filterObject={filterObject}
          setSelectedFilters={setSelectedFilters}
          split={split}
        />
      ))}
    </form>
  );
}

function FilterFields(props) {
  const { t } = useTranslation(['common']);
  const { currentFilter, split, setPage, applyFilters, selectedFilters, type, customStyles } =
    props;

  return (
    <label
      htmlFor={`filterValue_${currentFilter.key}`}
      className={`${
        split ? 'col-span-3' : 'col-span-3 lg:col-span-2'
      } w-full justify-start ltr:text-left rtl:text-right ${
        type === 'compound' ? 'filter-inline mx-1' : ''
      }`}
    >
      {currentFilter.type === 'text' && (
        <TextInput
          placeholder={t(currentFilter.label)}
          className="rlt:font-normal rlt:font-normal mt-2 inline-block w-auto border px-5 py-1 text-xs text-xs ltr:font-light ltr:font-light"
          style={{
            'border-radius': '4px',
            'border-color': 'rgb(199,208,213)',
            background: '#FFFFFF80',
            minHeight: '30px',
          }}
          value={currentFilter.value}
          id={`filterValue_${currentFilter.key}`}
          onChange={(e) => {
            currentFilter.value = null;
            if (e.target.value !== '') currentFilter.value = e.target.value;
            setPage(1);
            applyFilters(selectedFilters);
          }}
        />
      )}

      {currentFilter.type === 'number' && (
        <RealNumberInput
          placeholder={t(currentFilter.label)}
          className="rlt:font-normal mt-2 inline-block h-full w-full max-w-fit border px-5 py-1 text-xs ltr:font-light"
          style={{
            'border-radius': '4px',
            'border-color': 'rgb(199,208,213)',
            background: '#FFFFFF80',
            minHeight: '30px',
          }}
          value={currentFilter.value}
          id={`filterValue_${currentFilter.key}`}
          onChange={(e) => {
            currentFilter.value = null;
            if (e.target.value !== '') currentFilter.value = e.target.value;
            setPage(1);
            applyFilters(selectedFilters);
          }}
        />
      )}

      {currentFilter.type === 'list' && (
        <Select
          styles={customStyles}
          className={`${
            type === 'compound' ? 'inline-block' : ''
          } rlt:font-normal text-xs ltr:font-light`}
          key={`filterValue_${currentFilter.key}`}
          defaultValue={
            currentFilter &&
            currentFilter.options
              .filter((filter) =>
                filter.id ? filter.id === currentFilter.value : filter.key === currentFilter.value,
              )
              .map(
                (item) =>
                  item.value ?? (i18n.language === 'ar' ? item.name_ar ?? item.name : item.name),
              ).length === 1
              ? {
                  label: currentFilter.options
                    .filter((filter) =>
                      filter.id
                        ? filter.id === currentFilter.value
                        : filter.key === currentFilter.value,
                    )
                    .map(
                      (item) =>
                        item.value ??
                        (i18n.language === 'ar' ? item.name_ar ?? item.name : item.name),
                    )[0],
                  value: currentFilter.value,
                }
              : { label: currentFilter.key === 'currency' ? t('currency') : t('select'), value: '' }
          }
          isSearchable
          name={`filterValue_${currentFilter.key}`}
          options={currentFilter.options.map((options) => ({
            value: options.id ? options.id : options.key,
            label:
              options.value ??
              (i18n.language === 'ar' ? options.name_ar ?? options.name : options.name),
          }))}
          onChange={(e) => {
            currentFilter.value = null;
            if (e.value !== '') currentFilter.value = e.value;
            setPage(1);
            applyFilters(selectedFilters);
          }}
        />
      )}
      {currentFilter.type === 'compound' &&
        currentFilter.options.subfilters.map((filter) => (
          <FilterFields {...props} currentFilter={filter} type="compound" />
        ))}
    </label>
  );
}
function FilterItem({
  filterObject,
  setSelectedFilters,
  selectedFilters,
  filter,
  filterDiff,
  applyFilters,
  split,
  setPage,
  customStyles,
}) {
  const [currentFilter, setCurrentFilter] = useState(null);
  const { t } = useTranslation(['common']);
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    setCurrentFilter(filter);
  }, [selectedFilters]);

  const addFilterCallback = useCallback(() => {
    const filterToAdd = filterObject.filter((filterObj) => filterObj.key === filterDiff[0])[0];
    if (filterToAdd) {
      setSelectedFilters(filterToAdd, 'add');
    }
  }, [filterObject, filterDiff]);

  const removeFilterCallback = useCallback(() => {
    currentFilter.value = null;
    setSelectedFilters(currentFilter, 'delete');
    setPage(1);
    applyFilters(selectedFilters);
  }, [setSelectedFilters, currentFilter, selectedFilters]);

  if (!currentFilter) {
    return null;
  }
  if (selectedOption != null) {
    currentFilter.value = null;
    if (selectedOption !== '') currentFilter.value = selectedOption;
    applyFilters(selectedFilters);
  }

  const filterOptions = filterObject
    .filter((options) => filterDiff.includes(options.key) || options.key === currentFilter.key)
    .map((filterItem) => ({ value: filterItem.key, label: filterItem.label }));
  return (
    <div
      className={`filtering flex grid w-full flex-row items-center ${
        split ? 'grid-cols-3 gap-2 px-2' : 'grid-cols-3 gap-2 px-2 lg:grid-cols-5 lg:gap-5 lg:px-10'
      } border-b border-gray-3  py-2`}
      id={currentFilter.key}
    >
      <label
        htmlFor={`select_${currentFilter.key}`}
        className={`${
          split ? 'col-span-3' : 'col-span-3 lg:col-span-1'
        } w-full justify-start ltr:mr-3 ltr:text-left rtl:ml-3 rtl:text-right`}
      >
        <Select
          styles={customStyles}
          className="rlt:font-normal text-xs ltr:font-light"
          key={currentFilter.key}
          defaultValue={{ label: currentFilter.label, value: currentFilter.key }}
          name={`select_${currentFilter.key}`}
          id={`select_${currentFilter.key}`}
          onChange={(e) => {
            setCurrentFilter(filterObject.filter((item) => item.key === e.value)[0]);
            setSelectedFilters(
              {
                existing: currentFilter,
                updated: filterObject.filter((item) => item.key === e.value)[0],
              },
              'update',
            );
          }}
          options={filterOptions}
        />
      </label>

      <FilterFields
        currentFilter={currentFilter}
        split={split}
        setPage={setPage}
        applyFilters={applyFilters}
        selectedFilters={selectedFilters}
        customStyles={customStyles}
      />
      <label className={split ? 'hidden' : 'hidden lg:block'} />
      <div
        className={`${
          split ? 'col-span-3' : 'col-span-3 lg:col-span-1'
        } justify-end ltr:text-right rtl:text-left`}
      >
        {selectedFilters.length !== filterObject.length && (
          <button
            type="button"
            className="plus_minus_class text-2xl text-gray-6"
            onClick={addFilterCallback}
          >
            &#8853;
          </button>
        )}
        &nbsp;&nbsp;&nbsp;
        <button
          type="button"
          className="plus_minus_class text-2xl text-gray-6"
          onClick={removeFilterCallback}
        >
          &#8854;
        </button>
      </div>
    </div>
  );
}

export default TableFilter;
