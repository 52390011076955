import { getURLParams } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import AppStepper from '../../../../components/AppStepper';
import { setActiveProfileTabIndexAction } from '../../../../redux/ProfileReducer/ProfileActions';
import { clearFundMethodAction } from '../../../../redux/WalletReducer/WalletActions';
import EnterIBANNumberContent from './EnterIBANNumberContent';
import FundMethodFinish from './FundMethodFinish';
import UploadIBANLetterContent from './UploadIBANLetterContent';

function AddFundingMethod() {
  const { t } = useTranslation('walletV2');
  const { addFundMethod } = useSelector((state) => state.wallet);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});
  const dispatch = useDispatch();

  const handleBackStep = useCallback((stepIndex) => {
    setActiveStepIndex(stepIndex);
  }, []);

  const onNext = useCallback(({ stepIndex }) => {
    setCompletedSteps((prev) => {
      return {
        ...prev,
        [stepIndex]: true,
      };
    });
    setActiveStepIndex(stepIndex + 1);
  }, []);

  const handleCancel = useCallback(() => {
    const from = getURLParams('fromUrl');
    history.push(from);
    if (from.includes('/profile/company')) {
      setTimeout(() => {
        dispatch(setActiveProfileTabIndexAction(1));
      }, 0);
    }
  }, []);

  const fundingMethodsSteps = useMemo(() => {
    return [
      {
        label: t('Enter IBAN Number'),
        value: 'Enter IBAN Number',
        content: <EnterIBANNumberContent onNext={onNext} handleCancel={handleCancel} />,
      },
      {
        label: t('Upload IBAN Letter'),
        value: 'Upload IBAN Letter',
        content: (
          <UploadIBANLetterContent
            handleBackStep={handleBackStep}
            onNext={onNext}
            handleCancel={handleCancel}
          />
        ),
      },
    ];
  }, [t]);

  useEffect(() => {
    return () => {
      dispatch(clearFundMethodAction());
    };
  }, []);

  return (
    <div className="h-full pb-4 lg:pt-8">
      {!addFundMethod?.isFinish && (
        <AppStepper
          sideBarHeader={t('2 Steps to Add Funding Method')}
          activeStepIndex={activeStepIndex}
          steps={fundingMethodsSteps}
          completedSteps={completedSteps}
        />
      )}

      {addFundMethod?.isFinish && <FundMethodFinish />}
    </div>
  );
}

export default AddFundingMethod;
