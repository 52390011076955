import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppAlert from '../../components/AppAlert/index';
import AppSpinner from '../../components/AppSpinner';
import { AppBody, AppButton, AppHeader } from '../../components/html/html';
import { uploadDocumentSteps } from '../../helpers/constants';
import { getServerErrMessage } from '../../helpers/Utils';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import { setActiveDocumentStepAction } from '../../redux/UploadDocumentsReducer/UploadDocumentsActions';
import SingleDocumentCard from './SingleDocumentCard';

function DocumentCardsList() {
  const { t } = useTranslation('uploadDocuments');
  const { loaders } = useSelector((state) => state);
  const { documentCards, documentOTPData } = useSelector((state) => state.uploadDocuments);
  const dispatch = useDispatch();

  const isAllDocsUploaded = useMemo(() => {
    const requiredDocsToUpload = documentCards
      ?.map((ele) => ele.documents.length)
      .reduce((total, count) => total + count, 0);

    const totalUploadedDocuments = documentCards
      ?.map((element) => element.documents)
      .flat()
      .filter((document) => document.path).length;

    return requiredDocsToUpload === totalUploadedDocuments;
  }, [documentCards]);

  const handleSubmitForCompliance = useCallback(() => {
    http
      .get('documents/link/close', {
        params: {
          account_id: documentOTPData.account_id,
          verification_code: documentOTPData.verification_code,
          verification_token: documentOTPData.verification_token,
        },
        loader: 'handleSubmitForCompliance',
      })
      .then(() => {
        dispatch(setActiveDocumentStepAction(uploadDocumentSteps.documentUploadSuccess));
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [documentOTPData]);

  return (
    <div className="p-5 lg:p-16">
      {loaders.getDocumentCardsList && (
        <div className="h-full">
          <AppSpinner />
        </div>
      )}

      {!loaders.getDocumentCardsList && (
        <>
          <div className="mb-8">
            <AppHeader h="h4" className="my-4 text-center text-primary">
              {t('know your customer documents')}
            </AppHeader>

            <AppBody pClass="Body1Medium" className="mx-auto text-center text-gray-6 lg:w-[50%]">
              {t(
                'Based on our compliance review and the requirement from [Infinios] we need the following documents to open your account',
              )}
            </AppBody>
          </div>

          <div className="mb-8 grid gap-6">
            {documentCards?.map((ele) => {
              return <SingleDocumentCard key={ele.id} documentCard={ele} />;
            })}
          </div>

          <div className="mb-6 lg:w-3/4">
            <AppAlert
              type="info"
              text={
                <AppBody pClass="Body2Medium">
                  {t(
                    'By Click on submit, you agree to our privacy policy and sharing the provided documents with [Infinios] to open your account',
                  )}
                </AppBody>
              }
            />
          </div>

          <div className="mt-6 text-end">
            <AppButton
              onClick={handleSubmitForCompliance}
              button="primary"
              bClass="Button1Bold"
              size="lg"
              rounded="md"
              isLoading={loaders.handleSubmitForCompliance}
              className="w-full lg:w-[250px]"
              disabled={!isAllDocsUploaded}
            >
              {t('Submit for Compliance', { ns: 'uploadDocuments' })}
            </AppButton>
          </div>
        </>
      )}
    </div>
  );
}

export default DocumentCardsList;
