import { useTranslation } from 'react-i18next';
import { history } from '../../AppRouter/history';
import alreadyActivatedAccount from '../../assets/png/alreadyActivatedAccount.png';
import OTPResendTimer from '../../components/FormInputs/OTPResendTimer';
import { AppBody, AppButton, AppHeader } from '../../components/html/html';
import { isDev } from '../../helpers/Utils';

function AlreadyActivatedAccount() {
  const { t } = useTranslation('employeeRegistration');

  return (
    <div className="flex h-full items-center justify-center text-center">
      <div className="max-w-[550px] px-2 xxs:px-4">
        <div className="mb-12 flex justify-center">
          <img src={alreadyActivatedAccount} alt="Already Activated Account" />
        </div>

        <AppHeader h="h6" className="mb-5">
          {t('Your account has already been activated')}
        </AppHeader>

        <div className="mb-6 flex flex-col items-center gap-2 xxs:flex-row">
          <AppBody pClass="Body2Medium" className="text-gray-6">
            {t('you will be redirected to login page in')}
          </AppBody>

          <OTPResendTimer
            isResend={false}
            duration={isDev() ? 1000 : 10}
            onFinish={() => {
              history.push('/auth/login');
            }}
          />
        </div>

        <div>
          <AppButton
            onClick={() => {
              history.replace('/auth/login');
            }}
            className="mb-4 w-full lg:w-44"
            size="lg"
            rounded="lg"
            button="primary"
          >
            {t('Login')}
          </AppButton>
        </div>
      </div>
    </div>
  );
}

export default AlreadyActivatedAccount;
