import { dummyArr } from 'javascript-functions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import AppNoData from '../../../../../components/AppNoData';
import BackBtn from '../../../../../components/BackBtn';
import { AppBody, AppButton, AppHeader } from '../../../../../components/html/html';
import {
  addTransferToDataAction,
  getWhitlistedIBANIDAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import CardProgramFrom from '../CardProgramFrom';
import SingleBankCard from '../SingleBankCard';
import WithdrawNote from './WithdrawNote';

function WithdrawSelectWhitelistedContent({ handleBackStep, handleCancel, onNext }) {
  const { loaders } = useSelector((state) => state);
  const { actions } = useSelector((state) => state.wallet);

  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWhitlistedIBANIDAction());
  }, []);

  return (
    <div>
      <BackBtn
        className="mb-4"
        onClick={() => {
          handleBackStep(0);
        }}
      />

      <AppHeader h="h4" className="mb-6">
        {t('Select Your Whitelisted Bank Account')}
      </AppHeader>

      <div className="mb-4 p-1">
        <CardProgramFrom fromWithdraw />
      </div>

      <div>
        <AppBody pClass="Body1Bold" className="mb-2">
          {t('To', { ns: 'commonV2' })}
        </AppBody>
        <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
          {t('Please select your bank account that you need to withdraw to')}
        </AppBody>
      </div>

      <div className="mb-6 grid grid-cols-1 gap-4 p-1 lg:grid-cols-2 xl:grid-cols-3">
        {!loaders.getWhitlistedIBANIDAction &&
          actions?.whitelistedIBANS?.data?.map((ele) => {
            return (
              <SingleBankCard
                key={ele.id}
                onClick={() => {
                  dispatch(addTransferToDataAction(ele));
                }}
                item={ele}
                isSelected={actions.transferToData?.id === ele?.id}
              />
            );
          })}

        {loaders.getWhitlistedIBANIDAction &&
          dummyArr(8).map((_, i) => {
            return (
              <div key={JSON.stringify(i)}>
                <Skeleton height={130} />
              </div>
            );
          })}
      </div>

      {!loaders.getWhitlistedIBANIDAction && !actions?.whitelistedIBANS?.data?.length && (
        <AppNoData />
      )}

      <div className="mb-2">
        <WithdrawNote />
      </div>

      <div className="flex justify-end gap-8">
        <AppButton button="link" onClick={handleCancel} size="md">
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          size="md"
          className="min-w-[150px]"
          disabled={!actions.transferToData}
          onClick={() => {
            onNext({ stepIndex: 1 });
          }}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default WithdrawSelectWhitelistedContent;
