import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import suspendedSuccessfullyAnimation from '../../../../../../../assets/lotties/suspendedSuccessfully.json';
import AppLottie from '../../../../../../../components/AppLottie';
import { AppBody, AppButton, AppHeader } from '../../../../../../../components/html/html';
import { cardStatusKeys } from '../../../../../../../helpers/constants';
import { http } from '../../../../../../../http';
import { showToasterAction } from '../../../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  hideSettingsPopupDataAction,
  updateSelectedCardDetailsAction,
} from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';
import CardStatusImage from './CardStatusImage';

function SuspendCard() {
  const { loaders } = useSelector((state) => state);
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const handleSuspendCard = useCallback(() => {
    const cardId = settingsPopupCardDetails.id;

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('status', cardStatusKeys.SUSPENDED);

    http
      .post(`cardmanager/cards/${cardId}/status`, formData, {
        loader: 'handleSuspendCard',
      })
      .then((res) => {
        setShowSuccessMessage(true);

        dispatch(
          updateSelectedCardDetailsAction({
            card: {
              ...res.data,
              status: {
                ...res.data.status,
                key: cardStatusKeys.SUSPENDED,
                name: t('Suspended'),
              },
            },
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, []);

  useEffect(() => {
    return () => {
      setShowSuccessMessage(false);
    };
  }, []);

  return (
    <>
      {showSuccessMessage && (
        <div>
          <AppHeader h="h6" className="mb-3 flex flex-col items-center">
            <div className="flex gap-1">
              <span>{t('Card')}</span>
              <span>*{settingsPopupCardDetails.last_four}</span>
            </div>
            <span>{t('Suspended Successfully')}</span>
          </AppHeader>

          <CardStatusImage grayCard />

          <div className="absolute top-[50%] z-0">
            <AppLottie loop={false} animationData={suspendedSuccessfullyAnimation} width={350} />
          </div>
        </div>
      )}

      {!showSuccessMessage && (
        <div>
          <AppHeader h="h5" className="mb-3">
            <div className="flex gap-1">
              <span>{t('Suspend Card')}</span>
              <span>*{settingsPopupCardDetails.last_four}</span>
            </div>
          </AppHeader>

          <CardStatusImage grayCard />

          <div className="mx-auto mb-3 w-3/4">
            <AppHeader h="h6" className="mb-3">
              <span>{t('Are You Sure You Want to Suspend This Card?')}</span>
            </AppHeader>

            <AppBody pClass="Body2Medium" className="text-gray-6">
              <span>
                {t(
                  'While your card is suspended, all transactions, including purchases and withdrawals, are blocked. You can reactivate the card at any time.',
                )}
              </span>
            </AppBody>
          </div>

          <div className="mx-auto w-3/4">
            <div className="flex items-center justify-between">
              <AppButton
                button="link"
                className="text-sm"
                onClick={() => {
                  dispatch(hideSettingsPopupDataAction());
                }}
              >
                {t('Discard Changes', { ns: 'commonV2' })}
              </AppButton>
              <AppButton
                isLoading={loaders.handleSuspendCard}
                button="black"
                onClick={() => {
                  handleSuspendCard();
                }}
              >
                {t('Suspend')}
              </AppButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SuspendCard;
