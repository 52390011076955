import { useTranslation } from 'react-i18next';
import { AppHeader } from '../../components/html/html';

const DashboardHeader = function (props) {
  const { user } = props;
  const { t, i18n } = useTranslation(['dashboard']);
  const userName =
    i18n.language === 'ar' && user.first_name_ar !== ''
      ? `${user.first_name_ar}`
      : `${user.first_name}`;

  return (
    <div className="self-start ltr:text-left rtl:text-right">
      <span className="text-black">{`${t('hi')} ${userName},`}</span>
      <AppHeader h="h4" className="uppercase">
        {t('welcome_back')}
      </AppHeader>
    </div>
  );
};

export default DashboardHeader;
