/* eslint-disable no-use-before-define */
import axios from 'axios';
import { history } from '../../AppRouter/history';
import TranslatedData from '../../components/TranslatedData/index';
import { devLog, getServerErrMessage, translatedDataFunction } from '../../helpers/Utils';
import { http } from '../../http';
import InvitationSentPopup from '../../modules/Dashboard/UsersV2/SingleUserDetails/InvitationSentPopup';
import { showModalAction } from '../AppModalReducer/AppModalActions';
import { showToasterAction } from '../AppToastersReducer/AppToastersActions';
import store from '../store';

export const setActiveUserTabIndexAction = (activeTabIndex) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_USER_TAB_INDEX',
    payload: activeTabIndex,
  });
};

export const getUserListDepartmentsAction = () => (dispatch) => {
  devLog(`Getting User List Departments...`);

  const { lang } = store.getState().appConfig;

  http
    .get('departments', { params: { has_emp: 1 }, loader: 'getUserListDepartmentsAction' })
    .then((res) => {
      const serialized = res.data.map((ele) => {
        return {
          label: translatedDataFunction({ lang, ar: ele.name_ar, en: ele.name }),
          searchParams: { department_id: ele.id },
          show: true,
        };
      });
      const allTypesOption = {
        label: translatedDataFunction({ lang, en: 'All Users', ar: 'جميع المستخدمين' }),
        searchParams: { department_id: null },
        show: true,
      };

      dispatch({
        type: 'ADD_USERS_LIST_DEPARTMENTS',
        payload: [allTypesOption, ...serialized],
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: getServerErrMessage(err),
        }),
      );
    });
};

export const clearUsersListDepartmentsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_USERS_LIST_DEPARTMENTS',
  });
};

export const getUsersListAction =
  ({ params }) =>
  (dispatch) => {
    devLog(`Getting Users List...`);

    http
      .get('employees', { loader: 'getUsersListAction', params })
      .then((res) => {
        dispatch({
          type: 'ADD_USERS_LIST',
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

export const clearUsersListAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_USERS_LIST',
  });
};

export const updateSingleUserDetailsAction = (updatedUser) => (dispatch) => {
  dispatch({
    type: 'UPDATE_SINGLE_USER_DETAILS',
    payload: updatedUser,
  });
};

export const changeUserStatusAction =
  ({ userId, status, fromDetails = false }) =>
  (dispatch) => {
    http
      .patch('users/status', null, {
        loader: `changeUserStatusAction[${userId}]`,
        params: { user_id: userId, status },
      })
      .then((res) => {
        if (fromDetails) {
          dispatch(updateSingleUserDetailsAction(res.data));
          dispatch(
            showToasterAction({
              type: 'success',
              message: (
                <TranslatedData ar="تم تغيير الحالة بنجاح!" en="Status Changed Successfully!" />
              ),
            }),
          );
        } else {
          dispatch(updateUsersListAction(res.data));
        }
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

export const resendUserActivationEmailAction =
  ({ userId, fromDetails = false }) =>
  (dispatch) => {
    http
      .get(`employees/email/resend/${userId}`, {
        loader: `resendUserActivationEmailAction[${userId}]`,
      })
      .then(() => {
        if (fromDetails) {
          dispatch(
            showModalAction(
              <InvitationSentPopup
                message={
                  <TranslatedData ar="تم ارسال الدعوة بنجاح" en="Invitation Sent Successfully" />
                }
              />,
            ),
          );
        } else {
          dispatch(
            showToasterAction({
              type: 'success',
              message: (
                <TranslatedData ar="تم ارسال الدعوة بنجاح!" en="Invitation Sent Successfully!" />
              ),
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

export const sendChangeUserPasswordInvitationEmailAction =
  ({ userId, email }) =>
  (dispatch) => {
    const formBody = new FormData();
    formBody.append('email', email);
    formBody.append('id_number', userId);

    http
      .post(`email/forget/`, formBody, {
        loader: `sendChangeUserPasswordInvitationEmailAction`,
      })
      .then(() => {
        dispatch(
          showModalAction(
            <InvitationSentPopup
              message={
                <TranslatedData
                  ar="تم إرسال دعوة إعادة تعيين كلمة المرور بنجاح"
                  en="Reset Password Invitation Sent Successfully"
                />
              }
            />,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

export const hideAllUsersListPopups = () => (dispatch) => {
  dispatch(hideUsersListFilterPopupAction());
  dispatch(hideUsersListSearchPopupAction());
};

export const showUsersListFilterPopupAction = () => (dispatch) => {
  dispatch(hideAllUsersListPopups());

  dispatch({
    type: 'SHOW_USERS_LIST_FILTER_POPUP',
  });
};

export const hideUsersListFilterPopupAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_USERS_LIST_FILTER_POPUP',
  });
};

export const showUsersListSearchPopupAction = () => (dispatch) => {
  dispatch(hideAllUsersListPopups());
  dispatch({
    type: 'SHOW_USERS_LIST_SEARCH_POPUP',
  });
};

export const hideUsersListSearchPopupAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_USERS_LIST_SEARCH_POPUP',
  });
};

export const getSingleUserDetailsAction =
  ({ userId }) =>
  (dispatch) => {
    devLog(`Getting Single Users Details...`);

    http
      .get(`employees/${userId}`, { loader: 'getSingleUserDetailsAction' })
      .then((res) => {
        dispatch({
          type: 'ADD_SINGLE_USER_DETAILS',
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

export const updateUsersListAction = (updatedUser) => (dispatch) => {
  dispatch({
    type: 'UPDATE_USERS_LIST',
    payload: updatedUser,
  });
};

export const clearSingleUserDetailsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SINGLE_USER_DETAILS',
  });
};

export const setActiveUserDetailsTabIndexAction = (activeTabIndex) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_USER_DETAILS_TAB_INDEX',
    payload: activeTabIndex,
  });
};

export const getSingleUserCardsAction =
  ({ userId }) =>
  (dispatch) => {
    devLog(`Getting Single Users Cards...`);

    http
      .get(`cardmanager/cards/${userId}`, {
        params: { 'with[0]': 'cardproduct' },
        loader: 'getSingleUserCardsAction',
      })
      .then((res) => {
        dispatch({
          type: 'ADD_SINGLE_USER_CARDS',
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  };

export const selectSingleUserCardAction =
  ({ virtualCard, physicalCard }) =>
  (dispatch) => {
    dispatch({
      type: 'SELECT_SINGLE_USER_CARD',
      payload: { virtualCard, physicalCard },
    });
  };

export const clearSingleUserCardAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SINGLE_USER_SELECTED_CARD',
  });
};

export const getSingleUserCardDetailsBalanceAction = (cardId) => (dispatch) => {
  devLog(`Getting Single User card balance of Card ID [${cardId}]...`);

  http
    .get(`cardmanager/cards/balance/${cardId}`, {
      loader: `getSingleUserCardDetailsBalanceAction[${cardId}]`,
    })
    .then((response) => {
      dispatch({
        type: 'ADD_SINGLE_USER_CARD_DETAILS_BALANCE',
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: getServerErrMessage(err),
        }),
      );
    });
};

const getSingleUserDoubleCardDetailsAction =
  ({ physicalCard, virtualCard }) =>
  async (dispatch) => {
    try {
      const physicalCardRequest = http.get(`cardmanager/cards/show/${physicalCard.id}`, {
        loader: 'getSingleUserSelectedCardDetails',
      });
      const virtualCardRequest = http.get(`cardmanager/cards/show/${virtualCard.id}`, {
        loader: 'getSingleUserSelectedCardDetails',
      });

      const [physicalCardResponse, virtualCardResponse] = await axios.all([
        physicalCardRequest,
        virtualCardRequest,
      ]);

      const cardsData = {
        physicalCard: physicalCardResponse.data,
        virtualCard: virtualCardResponse.data,
      };

      // getting balance of virtualCard card
      await dispatch(getSingleUserCardDetailsBalanceAction(virtualCard.id));

      dispatch({
        type: 'ADD_SINGLE_USER_SELECTED_CARD_DETAILS',
        payload: cardsData,
      });
    } catch (error) {
      history.push('/404');
    }
  };

const getSingleUserSingleCardDetails =
  ({ physicalCard = null, virtualCard = null }) =>
  (dispatch) => {
    devLog(`Getting [${physicalCard ? 'Physical' : 'Virtual'}] Card Data...`);
    const singleCardId = physicalCard?.id || virtualCard?.id;
    http
      .get(`cardmanager/cards/show/${singleCardId}`, { loader: 'getSingleUserSelectedCardDetails' })
      .then((res) => {
        dispatch({
          type: 'ADD_SINGLE_USER_SELECTED_CARD_DETAILS',
          payload: {
            physicalCard: physicalCard && res.data,
            virtualCard: virtualCard && res.data,
          },
        });
      })
      .catch(() => {
        history.push('/404');
      });
  };

export const getSingleUserSelectedCardDetails =
  ({ physicalCard, virtualCard }) =>
  (dispatch) => {
    devLog(
      `Single User Card selected!
      virtualId: [${virtualCard?.id || null}]
      -
      physicalId:[${physicalCard?.id || null}]`,
    );
    if (virtualCard?.id && physicalCard?.id) {
      devLog('Getting Single User ((DOUBLE)) card details...');
      dispatch(getSingleUserDoubleCardDetailsAction({ physicalCard, virtualCard }));
    } else {
      devLog('Getting Single User ((SINGLE)) card details...');
      dispatch(getSingleUserSingleCardDetails({ physicalCard, virtualCard }));
    }
  };

export const clearSelectedSingleUserCardAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SINGLE_USER_SELECTED_CARD_DETAILS',
  });
};

export const updateSelectedSingleUserCardAction = (card) => (dispatch) => {
  devLog('Update selected single user card...');

  dispatch({
    type: 'UPDATE_SELECTED_SINGLE_USER_CARD',
    payload: card,
  });
};
