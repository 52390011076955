import { useTranslation } from 'react-i18next';

function AppNoData({ className, message }) {
  const { t } = useTranslation('cardsV2');

  return (
    <div className={`flex h-28 items-center justify-center text-gray-3 ${className}`}>
      <span>{message || t('No data to Display.')}</span>
    </div>
  );
}

export default AppNoData;
