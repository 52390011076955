import { useDispatch, useSelector } from 'react-redux';
import { hideModalAction } from '../../redux/AppModalReducer/AppModalActions';

function AppModal() {
  const { show, data } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  return (
    show && (
      <div
        className="fixed inset-0 z-[60] flex items-center justify-center bg-black/40"
        id="modal-bg"
        onClick={(e) => {
          if (e.target.id === 'modal-bg') {
            dispatch(hideModalAction());
          }
        }}
      >
        {data}
      </div>
    )
  );
}

export default AppModal;
