import { months } from 'constants-js';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AppSelect from '../../../../../components/FormInputs/AppSelect';
import { AppBody, AppButton } from '../../../../../components/html/html';
import { downloadFile } from '../../../../../helpers/Utils';
import { http } from '../../../../../http';
import { showToasterAction } from '../../../../../redux/AppToastersReducer/AppToastersActions';

function CardProgramStatementContent() {
  const { loaders } = useSelector((state) => state);
  const { singleSelectedCardProgramDetails } = useSelector((state) => state.wallet);
  const { t } = useTranslation(['walletV2', 'commonV2']);
  const [height, setHeight] = useState('auto');
  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();

  const yearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const fromYear = currentYear - 20;
    const years = [];

    Array.from({ length: currentYear - fromYear + 1 }, (_, index) => fromYear + index)
      .reverse()
      .forEach((year) => {
        years.push({ value: year, label: year });
      });
    return years;
  }, []);

  const monthsOptions = useMemo(() => {
    const serialized = months.map((ele) => {
      return {
        ...ele,
        label: t(ele.label, { ns: 'commonV2' }),
        value: ele.value + 1,
      };
    });
    return serialized;
  }, [months]);

  const onSubmit = useCallback(
    (values) => {
      http
        .get(`transactions/wallet/statement`, {
          loader: 'handleDownloadStatement',
          responseType: 'blob',
          params: {
            month: values.month.value,
            year: values.year.value,
            ref_id: singleSelectedCardProgramDetails.id,
          },
        })
        .then((res) => {
          downloadFile({
            data: res,
            fileName: `${singleSelectedCardProgramDetails.id}-${values.year.value}-${values.month.label}`,
            fileExtension: 'xlsx',
          });
        })
        .catch(() => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: t('No Results Found.', { ns: 'commonV2' }),
            }),
          );
        });
    },
    [singleSelectedCardProgramDetails],
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    const validations = {
      year: yup.mixed().required(t('This field is required!', { ns: 'commonV2' })),
      month: yup.mixed().required(t('This field is required!', { ns: 'commonV2' })),
    };

    setValidationSchema(yup.object(validations));
  }, [singleSelectedCardProgramDetails]);

  useEffect(() => {
    const currentMonthIndex = new Date().getMonth();
    setInitialValues({
      year: yearsOptions[0],
      month: monthsOptions[currentMonthIndex],
    });
  }, [singleSelectedCardProgramDetails]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="rounded-lg border border-gray-3 p-3"
      style={{ height }}
    >
      <div className="mb-4 grid lg:grid-cols-2 lg:gap-3">
        <label>
          <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">{t('Select Period')}</AppBody>
          <AppSelect
            className="w-full text-xs"
            isClearable
            name="year"
            placeholder={t('Select Year')}
            options={yearsOptions}
            onMenuOpen={() => {
              setHeight(500);
            }}
            onMenuClose={() => {
              setHeight('auto');
              formik.setFieldTouched('year');
            }}
            value={formik.values.year}
            onBlur={formik.handleBlur}
            onChange={(option) => {
              formik.setFieldValue('year', option);
            }}
          />
          {formik.errors.year && formik.touched.year && (
            <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">{formik.errors.year}</AppBody>
          )}
        </label>
        <label>
          <AppBody type="span" pClass="Caption1Bold" className="mb-5 block" />
          <AppSelect
            className="w-full text-xs"
            isClearable
            name="month"
            placeholder={t('Select Month')}
            options={monthsOptions}
            onMenuOpen={() => {
              setHeight(500);
            }}
            onMenuClose={() => {
              setHeight('auto');
              formik.setFieldTouched('month');
            }}
            value={formik.values.month}
            onBlur={formik.handleBlur}
            onChange={(option) => {
              formik.setFieldValue('month', option);
            }}
          />
          {formik.errors.month && formik.touched.month && (
            <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">{formik.errors.month}</AppBody>
          )}
        </label>
      </div>

      <p className="text-sm text-red-500">
        <span className="font-bold">{t('Note: ', { ns: 'commonV2' })}</span>
        <span>
          {t(
            'Card Program statements are issued on the 17th of each month for the prior month’s activity. For example, your September statement will be available on October 17th.',
          )}
        </span>
      </p>

      <div className="flex items-center justify-end gap-4">
        <AppButton
          isLoading={loaders.handleDownloadStatement}
          loadingLabel={t('Downloading', { ns: 'commonV2' })}
          disabled={!formik.isValid}
          button="black"
          type="submit"
        >
          {t('Download', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </form>
  );
}

export default CardProgramStatementContent;
