import React, { useState } from 'react';
import RegisterStepBar from '../../../../components/Stepbar/RegisterStepBar';
import ConfirmIdentityDetails from './ConfirmIdentityDetails';
import VerifyIdentity from './VerifyIdentity';

function IdentityVerification({ isOwner }) {
  const [identityDetails, setIdentityDetails] = useState(null);
  return (
    <div className="mx-auto flex w-full flex-col lg:w-8/12">
      {isOwner && <RegisterStepBar step={4} />}
      {!identityDetails && <VerifyIdentity setIdentityDetails={setIdentityDetails} />}
      {identityDetails && (
        <ConfirmIdentityDetails
          identityDetails={identityDetails}
          setIdentityDetails={setIdentityDetails}
          isOwner={isOwner}
        />
      )}
    </div>
  );
}

export default IdentityVerification;
