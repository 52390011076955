import { useFormik } from 'formik';
import { getURLParams } from 'javascript-functions';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../../../../AppRouter/history';
import AppInput from '../../../../../components/AppFormInputs/AppInput';
import { AppBody, AppButton, AppHeader } from '../../../../../components/html/html';
import {
  addActionAmountAction,
  addTransferFromDataAction,
  getCardProgramDetailsBalanceAction,
  getSelectedCardProgramDetailsAction,
} from '../../../../../redux/WalletReducer/WalletActions';
import CardProgramFrom from '../CardProgramFrom';
import TransferNote from './TransferNote';

function TransferDetermineAmountContent({ handleCancel, onNext }) {
  const { t } = useTranslation('walletV2');
  const { actions, singleSelectedCardProgramDetails } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return { amount: actions.amount || '' };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      amount: yup
        .number()
        .min(1, t('Amount is invalid must be more than 0', { ns: 'commonV2' }))
        .max(
          actions.transferFromData?.balance?.amount || 1,
          t('Amounts more than the current balance cannot be transferred.'),
        )
        .required(t('This field is required!', { ns: 'commonV2' })),
    });
  }, [actions]);

  const onSubmit = useCallback((values) => {
    onNext({ stepIndex: 0 });
    dispatch(addActionAmountAction(values.amount));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (getURLParams('selectedCardProgramId')) {
      dispatch(
        getSelectedCardProgramDetailsAction({
          cardProgramId: getURLParams('selectedCardProgramId'),
          fromActions: true,
        }),
      );
    } else {
      history.push('/dashboard/wallet');
    }
  }, []);

  useEffect(() => {
    if (singleSelectedCardProgramDetails) {
      dispatch(addTransferFromDataAction(singleSelectedCardProgramDetails));
    }
  }, [singleSelectedCardProgramDetails]);

  useEffect(() => {
    if (actions.transferFromData) {
      dispatch(
        getCardProgramDetailsBalanceAction({
          cardProgramId: getURLParams('selectedCardProgramId'),
          fromActions: true,
          getFromBalance: true,
        }),
      );
    }
  }, [actions.transferFromData]);

  return (
    <div>
      <div>
        <AppHeader h="h4" className="mb-2">
          {t('Determine Your Amount')}
        </AppHeader>
        <AppBody pClass="Body1Bold" className="mb-8">
          {t('How much do you want to transfer?')}
        </AppBody>
      </div>

      <div className="mb-8 p-1">
        <CardProgramFrom fromTransfer />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="mb-8 flex gap-5 p-1 lg:w-2/3">
          <label className="flex-1">
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Amount')}
            </AppBody>
            <AppInput
              className="rounded-xl"
              size="md"
              type="number"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!actions.transferFromData?.balance}
            />
            {formik.errors.amount && formik.touched.amount && (
              <small className="text-danger">{t(formik.errors.amount, { ns: 'commonV2' })}</small>
            )}
          </label>

          <label className="flex-1">
            <AppBody pClass="Body1Bold" type="span" className="mb-1 block">
              {t('Currency', { ns: 'commonV2' })}
            </AppBody>
            <AppInput
              disabled
              value={getURLParams('selectedCardProgramCurrency')}
              className="rounded-xl"
              size="md"
              name="currency"
            />
          </label>
        </div>

        <div className="mb-28 ">
          <TransferNote />
        </div>

        <div className="flex justify-end gap-8">
          <AppButton button="link" onClick={handleCancel} size="md">
            {t('Cancel', { ns: 'commonV2' })}
          </AppButton>
          <AppButton
            size="md"
            disabled={!formik.isValid || !formik.values.amount}
            className="min-w-[150px]"
            type="submit"
            button="black"
          >
            {t('Next', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </form>
    </div>
  );
}

export default TransferDetermineAmountContent;
