import { bytesToSizes, truncate } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppBody, AppHeader, AppIcon } from '../../components/html/html';
import { getServerErrMessage } from '../../helpers/Utils';
import { http } from '../../http';
import { hideModalAction, showModalAction } from '../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import {
  saveDocumentCardsAction,
  updateSingleDocumentCardDetailsAction,
} from '../../redux/UploadDocumentsReducer/UploadDocumentsActions';
import RejectionReasonPopup from './RejectionReasonPopup';
import UploadSingleDocumentPopup from './UploadSingleDocumentPopup';

function SingleDocumentUploadInput({ document }) {
  const { loaders } = useSelector((state) => state);
  const { documentOTPData, singleDocumentCardDetails } = useSelector(
    (state) => state.uploadDocuments,
  );
  const { token, accountId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation('uploadDocuments');
  const [fileInfo, setFileInfo] = useState({});

  const isDocumentRejected = useMemo(() => {
    return Boolean(document.reject_reason);
  }, [document, singleDocumentCardDetails]);

  const getDocumentCardsList = useCallback(() => {
    const params = {
      account_id: accountId,
      link_token: token,
      verification_token: documentOTPData.verification_token,
      verification_code: documentOTPData.verification_code,
    };
    http
      .get(`documents/link/open`, {
        loader: 'getDocumentCardsList',
        params,
      })
      .then((res) => {
        dispatch(saveDocumentCardsAction(res.data));
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  const handleFileDelete = useCallback(() => {
    http
      .delete('documents/delete', {
        params: {
          account_id: documentOTPData.account_id,
          verification_code: documentOTPData.verification_code,
          verification_token: documentOTPData.verification_token,
          register_browser: window.navigator.userAgent,
          document_id: document.id,
        },
        loader: `handleFileDelete[${document.id}]`,
      })
      .then(() => {
        dispatch(hideModalAction());
        dispatch(
          updateSingleDocumentCardDetailsAction({
            document: { ...document, path: '' },
          }),
        );
        getDocumentCardsList();
        setFileInfo({});
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [documentOTPData, document]);

  const handleFileUpload = useCallback(
    ({ FILE }) => {
      const formBody = new FormData();

      formBody.append('account_id', documentOTPData.account_id);
      formBody.append('verification_code', documentOTPData.verification_code);
      formBody.append('verification_token', documentOTPData.verification_token);
      formBody.append('register_browser', window.navigator.userAgent);
      formBody.append('document_id', document.id);
      formBody.append('document_file', FILE);

      http
        .post('documents/upload', formBody, { loader: 'handleFileUpload' })
        .then((res) => {
          dispatch(hideModalAction());
          dispatch(
            updateSingleDocumentCardDetailsAction({
              document: res.data,
            }),
          );
          getDocumentCardsList();
          setFileInfo({ name: res.data.path, size: res.data.size });
        })
        .catch((err) => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: getServerErrMessage(err),
            }),
          );
        });
    },
    [documentOTPData, document, singleDocumentCardDetails],
  );

  const handleChange = useCallback((FILE) => {
    handleFileUpload({ FILE });
  }, []);

  useEffect(() => {
    if (document.path) {
      setFileInfo({ name: document.path, size: document.size });
    }
  }, [document]);

  return (
    <div>
      <div
        className={`flex w-full flex-col items-center gap-4 rounded-md p-1 lg:flex-row lg:border-0 ${
          fileInfo.name ? 'border' : ''
        }`}
      >
        {/* ======== Upload File Input ======== */}
        <div
          className={`flex w-full flex-1 cursor-pointer items-center justify-between rounded-md p-3 lg:bg-gray-1 ${
            fileInfo.name ? '' : 'bg-gray-1'
          }`}
          onClick={() => {
            dispatch(
              showModalAction(
                <UploadSingleDocumentPopup document={document} handleChange={handleChange} />,
              ),
            );
          }}
        >
          <AppHeader h="h6">{t(document.label, { ns: 'commonV2' })}</AppHeader>
          {/* ======== Upload Icon ======== */}
          {(!fileInfo.name || isDocumentRejected) && (
            <AppIcon
              className="fa-regular fa-arrow-up-from-bracket text-primary"
              iClass="XXLargeFont"
            />
          )}

          {/* ======== Check Icon ======== */}
          {fileInfo.name && !isDocumentRejected && (
            <AppIcon className="fa-regular fa-circle-check text-success" iClass="XXXLargeFont" />
          )}
        </div>

        {/* ======== Rejected File Info ======== */}
        {fileInfo.name && isDocumentRejected && (
          <div className="flex w-full flex-1 items-center justify-between rounded-md bg-white p-3">
            <AppBody pClass="Body1Bold" className="text-danger">
              <span>{t('Document not approved')}</span>

              <button
                type="button"
                onClick={() => {
                  dispatch(
                    showModalAction(
                      <RejectionReasonPopup
                        documentName={document.label}
                        rejectionReason={document.reject_reason}
                      />,
                    ),
                  );
                }}
              >
                <AppIcon className="fa-regular fa-circle-info ms-2" iClass="LargeFont" />
              </button>
            </AppBody>
          </div>
        )}

        {/* ======== Uploaded File Info ======== */}
        {fileInfo.name && !isDocumentRejected && (
          <div className="flex w-full flex-1 items-center justify-between rounded-md bg-gray-1 p-3 lg:border lg:border-gray-3 lg:bg-white">
            <AppBody pClass="Body1Bold">
              {truncate(fileInfo.name, 25)}
              {fileInfo.name.slice(-4)}
            </AppBody>
            <div className="flex items-center">
              <AppBody
                pClass="Caption1Medium"
                className="text-gray-6 ltr:mr-3 rtl:ml-3"
                style={{ direction: 'ltr' }}
              >
                {bytesToSizes(fileInfo.size)}
              </AppBody>
              <button
                type="button"
                onClick={handleFileDelete}
                disabled={loaders[`handleFileDelete[${document.id}]`]}
              >
                {!loaders[`handleFileDelete[${document.id}]`] && (
                  <AppIcon className="fa-regular fa-trash-can text-danger" iClass="XXLargeFont" />
                )}

                {loaders[`handleFileDelete[${document.id}]`] && (
                  <AppIcon
                    className="fa-regular fa-arrows-rotate animate-spin text-danger"
                    iClass="XLargeFont"
                  />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default SingleDocumentUploadInput;
