/* eslint-disable camelcase */
import { randomId } from 'javascript-functions';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BackBtn from '../../../../components/BackBtn';
import NqoodCardImg from '../../../../components/NqoodCardImg';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { devLog } from '../../../../helpers/Utils';
import { cardStatusKeys, cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import {
  selectCardTypeAction,
  setActiveCreateNewCardStepAction,
  setPendingVirtualCardAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';

function SelectCardType() {
  const { useMock } = useSelector((state) => state);
  const { selectedCardProgram, selectedCardType, userPrimaryCard, availableCardTypes } =
    useSelector((state) => state.createNewCard);

  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const selectedClasses = useMemo(() => {
    return 'outline-2 outline-black';
  }, []);

  const unSelectedClasses = useMemo(() => {
    return 'outline-1 outline-stone-300';
  }, []);

  const selectedCardTypeKey = useMemo(() => {
    return selectedCardType?.name?.toUpperCase();
  }, [selectedCardType]);

  const benefits = useMemo(() => {
    return {
      [cardTypesKeys.VIRTUAL]: [
        { name: t('For online purchases and subscriptions') },
        { name: t('You can issue multiple cards depend based on the remaining limits') },
        { name: t('Instant issuing of the card') },
      ],
      [cardTypesKeys.PHYSICAL]: [
        { name: t('For in store purchases') },
        {
          name: (
            <span>
              <span>{t('You can make')}</span>
              <span className="font-bold"> {t('1 card')} </span>
              <span>{t('for each card program')}</span>
            </span>
          ),
        },
        {
          name: (
            <span>
              <span>{t('You can link the physical card with existing virtual card or you')}</span>{' '}
              <span className="font-bold">{t('can create a new virtual card')}</span>
            </span>
          ),
        },
        {
          name: (
            <span>
              <span>{t('The expected delivery time is between')}</span>
              <span className="font-bold"> {t('7 to 10 business days')}</span>
            </span>
          ),
        },
      ],
    };
  }, [t]);

  const handleNextPhysical = useCallback(() => {
    devLog('[Physical] Type Selected!');
    // ============== Max Physical Limit ============== //
    if (!selectedCardProgram.remaining_physical_count) {
      devLog(`Max Physical Card Reached!`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.cardMaxLimit));

      // ============== Replace AND Create Physical Card ============== //
    } else if (selectedCardProgram.physical_can_create_and_replace) {
      devLog(`[Replace or Create] Physical Card`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createOrReplaceCard));

      // ============== Replace Physical Card Only ============== //
    } else if (selectedCardProgram.physical_can_replace_only) {
      devLog(`[Replace] Physical Card`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectReplaceCard));

      // ==============  Create Physical Card With Primary Card ============== //
    } else if (userPrimaryCard) {
      devLog('[Select Card Type Page] User has a Primary Card!');
      const primaryCardStatus = userPrimaryCard?.status?.key?.toUpperCase();

      if (
        primaryCardStatus === cardStatusKeys.SUSPENDED ||
        primaryCardStatus === cardStatusKeys.INACTIVE
      ) {
        devLog(
          `[Select Card Type Page] Primary Card is ${primaryCardStatus}, Adding it to pendingPrimaryVirtualCard.`,
        );
        dispatch(setPendingVirtualCardAction(userPrimaryCard));
      }

      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPlasticCard));

      // ==============  Create Physical Card With No Primary Card ============== //
    } else {
      devLog(
        '[Select Card Type Page] User has [NO] Primary Card, Navigating to create a New Virtual Primary Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPrimaryCard));
    }
  }, [selectedCardProgram, userPrimaryCard]);

  const handleNextVirtual = useCallback(() => {
    devLog('[Virtual] Type Selected!');
    // ============== Max Virtual Limit ============== //
    if (!selectedCardProgram.remaining_virtual_count) {
      devLog(`Max Virtual Card Reached!`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.cardMaxLimit));

      // ============== Replace AND Create Virtual Card ============== //
    } else if (selectedCardProgram.virtual_can_create_and_replace) {
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createOrReplaceCard));

      // ============== Replace Virtual Card Only ============== //
    } else if (selectedCardProgram.virtual_can_replace_only) {
      devLog(`[Replace] Virtual Card`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectReplaceCard));

      // ==============  Create Virtual Card With No Primary Card ============== //
    } else if (!userPrimaryCard) {
      devLog(
        '[Select Card Type Page] User has [NO] Primary Card, Navigating to [Create] a New Virtual Primary Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPrimaryCard));

      // ==============  Create Virtual Card With Primary Card ============== //
    } else {
      devLog(
        '[Select Card Type Page] User [has] a Primary Card, Navigating to [Customize] the New Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.customizePrimaryCard));
    }
  }, [userPrimaryCard]);

  const handleNextPhysicalMock = useCallback(() => {
    devLog('[Physical] Type Selected!');
    // ============== Max Physical Limit ============== //
    if (useMock.maxPlasticLimitMock) {
      devLog(`Max Physical Card Reached!`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.cardMaxLimit));

      // ============== Replace AND Create Physical Card ============== //
    } else if (useMock.plasticCreateAndReplaceMock) {
      devLog(`[Replace or Create] Physical Card`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createOrReplaceCard));

      // ============== Replace Physical Card Only ============== //
    } else if (useMock.replacePlasticCardMock) {
      devLog(`[Replace] Physical Card`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectReplaceCard));

      // ==============  Create Physical Card With Primary Card ============== //
    } else if (userPrimaryCard) {
      devLog('[Select Card Type Page] User has a Primary Card!');
      const primaryCardStatus = userPrimaryCard?.status?.key?.toUpperCase();

      if (
        primaryCardStatus === cardStatusKeys.SUSPENDED ||
        primaryCardStatus === cardStatusKeys.INACTIVE
      ) {
        devLog(
          `[Select Card Type Page] Primary Card is ${primaryCardStatus}, Adding it to pendingPrimaryVirtualCard.`,
        );
        dispatch(setPendingVirtualCardAction(userPrimaryCard));
      }

      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPlasticCard));

      // ==============  Create Physical Card With No Primary Card ============== //
    } else {
      devLog(
        '[Select Card Type Page] User has [NO] Primary Card, Navigating to create a New Virtual Primary Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPrimaryCard));
    }
  }, [useMock, userPrimaryCard]);

  const handleNextVirtualMock = useCallback(() => {
    devLog('[Virtual] Type Selected!');
    // ============== Max Virtual Limit ============== //
    if (useMock.maxVirtualLimitMock) {
      devLog(`Max Virtual Card Reached!`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.cardMaxLimit));

      // ============== Replace AND Create Virtual Card ============== //
    } else if (useMock.virtualCreateAndReplaceMock) {
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createOrReplaceCard));

      // ============== Replace Virtual Card Only ============== //
    } else if (useMock.replaceVirtualCardMock) {
      devLog(`[Replace] Virtual Card`);
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectReplaceCard));

      // ==============  Create Virtual Card With No Primary Card ============== //
    } else if (!userPrimaryCard) {
      devLog(
        '[Select Card Type Page] User has [NO] Primary Card, Navigating to [Create] a New Virtual Primary Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPrimaryCard));

      // ==============  Create Virtual Card With Primary Card ============== //
    } else {
      devLog(
        '[Select Card Type Page] User [has] a Primary Card, Navigating to [Customize] the New Card.',
      );
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.customizePrimaryCard));
    }
  }, [useMock, userPrimaryCard]);

  const handleNextStep = useCallback(() => {
    const isMock = Object.values(useMock).filter((ele) => {
      if (typeof ele === 'boolean' && ele) {
        return ele;
      }
      return null;
    }).length;

    if (selectedCardTypeKey === cardTypesKeys.PHYSICAL) {
      if (isMock) {
        devLog(`Mock Activated!`);
        handleNextPhysicalMock();
      } else {
        handleNextPhysical();
      }
    }

    if (selectedCardTypeKey === cardTypesKeys.VIRTUAL) {
      if (isMock) {
        devLog(`Mock Activated!`);
        handleNextVirtualMock();
      } else {
        handleNextVirtual();
      }
    }
  }, [selectedCardTypeKey]);

  return (
    <div className="mx-auto mt-[10vh] w-full px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <div className="mb-5">
        <BackBtn
          className="mb-4"
          onClick={() => {
            dispatch(selectCardTypeAction(null));
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardProgram));
          }}
        />
        <AppHeader h="h3">{t("Let's Create Your New Card")}</AppHeader>
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('Select Virtual or Plastic card to continue')}
        </AppBody>
      </div>

      <div className="mb-5 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {availableCardTypes?.map((ele) => {
          return (
            <button
              onClick={() => {
                dispatch(selectCardTypeAction(ele));
              }}
              type="button"
              key={ele.id}
              disabled={ele.disabled}
              className={`
                ${
                  selectedCardType?.id === ele.id ? selectedClasses : unSelectedClasses
                } relative m-1 flex flex-col justify-between rounded-md bg-white text-start outline`}
            >
              <div className="px-5 py-5 md:px-8 lg:px-5 2xl:px-8">
                <div className="mb-4 overflow-hidden rounded-xl shadow">
                  <NqoodCardImg
                    bankLogo={selectedCardProgram?.funding_source_type?.bank_logo}
                    scheme={selectedCardProgram.scheme}
                    skeletonHeight={163}
                    skeletonWidth={260}
                    type={ele.name.toLowerCase()}
                    alt={`${ele.type} Card`}
                  />
                </div>
                <AppHeader h="h6">
                  {ele.name === cardTypesKeys.VIRTUAL ? t('Virtual Card') : t('Plastic Card')}
                </AppHeader>
                <AppBody pClass="Body2Medium" className="mb-3 text-gray-6">
                  {t('Feature and Benefits')}
                </AppBody>

                <ol className="list-inside list-decimal text-sm text-gray-6">
                  {benefits[ele.name].map((benefit) => {
                    return (
                      <li key={randomId()} className="mb-2">
                        {benefit.name}
                      </li>
                    );
                  })}
                </ol>
              </div>

              <div className="flex w-full items-center gap-2 border-t border-gray-3 p-5">
                <span className="text-sm">{t('Annual Fees: ')}</span>
                <AppBody
                  pClass="Body1Bold"
                  type="span"
                  style={{ direction: 'ltr' }}
                  className="flex gap-1"
                >
                  <span>{ele.fee}</span>
                  <span>{ele.currency}</span>
                </AppBody>
              </div>
            </button>
          );
        })}
      </div>

      <div className="flex justify-end gap-3 pb-5">
        <CancelBtn />

        <AppButton
          disabled={!selectedCardType}
          onClick={handleNextStep}
          isLoading={false}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default SelectCardType;
