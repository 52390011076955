import { useMemo } from 'react';
import Lottie from 'react-lottie';

function AppLottie({
  loop = true,
  autoplay = true,
  animationData,
  isStopped,
  width,
  height,
  className,
}) {
  const options = useMemo(() => {
    return {
      loop,
      autoplay,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }, []);

  return (
    <Lottie
      className={className}
      options={options}
      isStopped={isStopped}
      width={width}
      height={height}
    />
  );
}

export default AppLottie;
