import React from 'react';
import { useSelector } from 'react-redux';
import nqoodBG from '../../../assets/png/nqoodBG.png';
import { loginActiveStep } from '../../../helpers/constants';
import LoginBannerAndForm from './LoginBannerAndForm';
import LoginOTP from './LoginOTP';

function LoginPage() {
  const { activeLoginStep } = useSelector((state) => state.auth.loginProcess);

  return (
    <div
      className="h-[98%] overflow-auto rounded-xl border bg-slate-50 bg-cover bg-center p-5 scrollbar-none lg:p-10"
      style={{
        backgroundImage:
          activeLoginStep === loginActiveStep.loginAndBanner ? `url(${nqoodBG})` : 'none',
      }}
    >
      {activeLoginStep === loginActiveStep.loginAndBanner && <LoginBannerAndForm />}
      {activeLoginStep === loginActiveStep.loginOTP && <LoginOTP />}
    </div>
  );
}
export default LoginPage;
