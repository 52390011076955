import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {toIntAndDecimalNumber} from '../../helpers/Utils';
import {requestStatusIds} from '../../helpers/constants';
import {AppBody, AppHeader} from '../html/html';

function RequestBalanceAndStatus({ requestDetails }) {
  const { t } = useTranslation('commonV2');

  const isPendingRequest = useMemo(() => {
    return !requestDetails.ref_id && requestDetails.status_id === requestStatusIds.PENDING;
  }, [requestDetails]);

  const isApprovedSuccessRequest = useMemo(() => {
    return (
      requestDetails.ref_id &&
      requestDetails.status_id === requestStatusIds.APPROVED &&
      requestDetails.ref_id?.status?.key?.toUpperCase() === 'SUCCESS'
    );
  }, [requestDetails]);

  const isApprovedNOTSuccessRequest = useMemo(() => {
    return (
      requestDetails.ref_id &&
      requestDetails.status_id === requestStatusIds.APPROVED &&
      requestDetails.ref_id?.status?.key?.toUpperCase() !== 'SUCCESS'
    );
  }, [requestDetails]);

  const isRejectedRequest = useMemo(() => {
    return requestDetails.status_id === requestStatusIds.REJECTED;
  }, [requestDetails]);

  return (
    <>
      <div className="mb-4 flex items-center justify-center gap-2" style={{ direction: 'ltr' }}>
        <AppHeader h="h2" className="inline-block">
          {toIntAndDecimalNumber(requestDetails.amount).integerPart}
        </AppHeader>
        <span className="text-[34px]">
          {toIntAndDecimalNumber(requestDetails.amount).decimalPart}
        </span>
        <span className="text-xl font-medium">{requestDetails.currency}</span>
      </div>

      <AppBody pClass="Body1Bold" type="div" className="mx-auto w-2/3 break-words text-center">
        {/* ========== Pending Request ========== */}
        {isPendingRequest && <span className="text-gray-6">{t('Pending Approval')}</span>}

        {/* ========== Approved Request (SUCCESS) ========== */}
        {isApprovedSuccessRequest && (
          <span style={{ color: requestDetails.ref_id?.status?.color }}>{t('Successful')}</span>
        )}

        {/* ========== Approved Request (NOT SUCCESS) ========== */}
        {isApprovedNOTSuccessRequest && (
          <span style={{ color: requestDetails.ref_id?.status?.color }}>
            {requestDetails.ref_id?.status?.name}
          </span>
        )}

        {/* ========== Rejected Request Reason ========== */}
        {isRejectedRequest && (
          <span className="text-red-500">{requestDetails.rejection_reason}</span>
        )}
      </AppBody>
    </>
  );
}

export default RequestBalanceAndStatus;
