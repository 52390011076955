/* eslint-disable no-unsafe-optional-chaining */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { cardDetailsSectionIds, settingsPopupContent } from '../../../../../helpers/constants';
import CardSettingsPopupContent from './CardSettingsPopupContent';
import SettingsPopupHeader from './SettingsPopupHeader';

function CardSettingsPopup({ card, inPage }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { settingsPopupCardDetails, currentSettingsPopupContent } = useSelector(
    (state) => state.cardDetails,
  );
  const settingsPopupWidth = useMemo(() => {
    if (isMobile) {
      return '85vw';
    }
    if (
      currentSettingsPopupContent === settingsPopupContent.changePinNumber ||
      currentSettingsPopupContent === settingsPopupContent.simplifiActivate ||
      currentSettingsPopupContent === settingsPopupContent.verifyLastFour
    ) {
      return '32rem';
    }

    return '24rem';
  }, [currentSettingsPopupContent, isMobile]);

  return (
    settingsPopupCardDetails?.id === card.id && (
      <div
        className="absolute end-0 top-14 z-30 h-auto overflow-y-auto rounded-2xl border border-gray-3 bg-white p-5 pt-0 shadow lg:-end-7 lg:start-0 lg:top-8 lg:max-h-[400px]"
        style={{
          width: settingsPopupWidth,
          maxHeight:
            document.getElementById(cardDetailsSectionIds.RIGHT_SIDE_CONTAINER)?.clientHeight -
            document.getElementById(cardDetailsSectionIds.CARD_DETAILS_HEADER)?.clientHeight -
            20,
        }}
      >
        {/* Header */}
        <div className="sticky top-0 z-10 bg-white pb-3 pt-5">
          <SettingsPopupHeader />
        </div>

        {/* Content */}
        <div>
          <CardSettingsPopupContent inPage={inPage} />
        </div>
      </div>
    )
  );
}

export default CardSettingsPopup;
