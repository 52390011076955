import React from 'react';
import { useTranslation } from 'react-i18next';
import { DOTS, usePagination } from '../../helpers/usePagination';

function Pagination(props) {
  const { t } = useTranslation(['common']);

  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      <button
        type="button"
        className="px-5 disabled:opacity-25"
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        {t('previous')}
      </button>

      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <li className="px-3">&#8230;</li>;
        }

        return (
          <button
            key={pageNumber + 1}
            type="button"
            className={`rounded-none px-3 lg:rounded-xl ${
              currentPage === pageNumber ? 'bg-gray-6 bg-opacity-25 font-semibold' : ''
            }`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        );
      })}

      <button
        type="button"
        className="px-5 disabled:opacity-25"
        onClick={onNext}
        disabled={currentPage === lastPage}
      >
        {t('next')}
      </button>
    </>
  );
}

export default Pagination;
