import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as MenuArrow } from '../../assets/svg/menu_arrow.svg';
import useAccountReady from '../../helpers/useAccountReady';
import HeaderUserName from './HeaderUserName';

export function HeaderMenuItem({ label, icon, to, target }) {
  return (
    <div className="border-b border-gray-3 border-opacity-40 px-4 py-2 last:border-b-0 hover:bg-gray-100">
      <Link
        target={target ?? '_self'}
        to={to}
        className="flex flex-row items-baseline justify-between self-stretch"
      >
        <p className="text-sm">{label}</p>
        <span>{icon}</span>
      </Link>
    </div>
  );
}

export function ProfileMenuItem({ label, icon, to }) {
  return (
    <div className="border-b border-gray-3 border-opacity-40 last:border-b-0 hover:bg-gray-100">
      <Link to={to} className="flex flex-row items-baseline justify-between self-stretch">
        <p className="text-sm text-gray-6">{label}</p>
        <span>{icon}</span>
      </Link>
    </div>
  );
}

function HeaderMenu({ children }) {
  const [userToggle, setUserToggle] = useState(false);
  const wrapperRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const accountReady = useAccountReady();

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setUserToggle(false);
    } else if (['P', 'A'].includes(event.target.tagName)) {
      setUserToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  if (!user) {
    return null;
  }

  return (
    <div
      className={`relative flex flex-row justify-between py-2 ${
        userToggle
          ? 'rounded-2xl rounded-b-none rounded-tl-xl rounded-tr-xl border border-b-0 border-t-0 border-white bg-white'
          : ''
      }`}
    >
      <div ref={wrapperRef} className={`w-full ${accountReady ? 'hidden lg:block' : ''}`}>
        <button
          className="z-50 flex h-full w-full justify-between"
          type="button"
          onClick={() => {
            setUserToggle(!userToggle);
          }}
        >
          <div className="hidden text-end lg:block">
            <HeaderUserName user={user} />
          </div>
          <div className="ms-4 h-full border-s border-gray-5 ps-4">
            <MenuArrow className="h-full" />
          </div>
        </button>
      </div>
      {userToggle && (
        <div className="absolute right-1 top-12 z-40 flex w-48 flex-col rounded-2xl rounded-t-none rounded-bl-xl rounded-br-xl border border-white bg-white pt-4 shadow-md transition-all lg:w-60">
          {children}
        </div>
      )}
    </div>
  );
}

export default HeaderMenu;
