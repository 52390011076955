import React from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ConfirmIcon from '../../../../assets/png/remove_confrimation.png';
import LinkButton from '../../../../components/Button/LinkButton';
import { Headline2, Input } from '../../../../components/Titles';
import { apiDeleteRequest } from '../../../../helpers/APIHelper';
import { setAction } from '../../../../helpers/ActionsHelper';
import useNotifications from '../../../../helpers/useNotifications';
import { PATCH_TRANSACTION } from '../../../../redux/TransactionsReducer';

function DeleteSplitPopup({ setRemoveSplit, setRemovedSplit, transaction, setTransaction }) {
  const { t } = useTranslation(['transactions', 'common']);
  const [cookies] = useCookies(['token']);

  const [, addNotification] = useNotifications();
  const dispatch = useDispatch();

  return (
    <div className="">
      <div className="absolute inset-0 z-10 h-full w-full bg-black opacity-50" />
      <div className="confirm_box lg:max-w-auto fixed inset-x-0 z-50 mb-4 w-full p-4 px-10 py-5 lg:mx-auto lg:w-1/2">
        <div className="absolute top-5 ltr:right-5 rtl:left-5">
          <LinkButton isClose onClick={() => setRemoveSplit(false)} />
        </div>
        <div className="flex items-center">
          <div className="w-full">
            <img alt="confirm" src={ConfirmIcon} />
            <Headline2>{t('delete_title')}</Headline2>
            <Input className="mt-1 text-sm text-gray-700">{t('delete_p')}</Input>
          </div>
        </div>
        <div className="mt-4 flex ltr:justify-end rtl:justify-start">
          <LinkButton
            text={t('discard_changes')}
            onClick={() => setRemoveSplit(false)}
            className="mt-4 block w-full px-4 py-3 text-center text-sm lg:order-1 lg:mt-0 lg:inline-block lg:w-auto lg:py-2"
          />
          <LinkButton
            onClick={() => {
              apiDeleteRequest(`transactions/split/${transaction.id}`, {}, cookies.token)
                .then((response) => {
                  setRemovedSplit(true);
                  dispatch(setAction(PATCH_TRANSACTION, response));
                  setTransaction(response);
                  addNotification(t('deleted_split'));
                })
                .catch((err) => {
                  addNotification(
                    (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
                    false,
                  );
                })
                .finally(() => {
                  setRemoveSplit(false);
                });
            }}
            text={t('delete', { ns: 'common' })}
            className="order-2 block w-full rounded-full border border-red-400 bg-transparent px-4 py-3 text-center text-sm text-red-700 lg:inline-block lg:w-auto  lg:py-2"
          />
        </div>
      </div>
    </div>
  );
}

export default DeleteSplitPopup;
