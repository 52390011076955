import { useEffect, useRef, useState } from 'react';

export const useDropdown = () => {
  const [collapsed, setCollapsed] = useState(true);
  const wrapperRef = useRef(null);

  return {
    collapsed,
    setCollapsed,
    wrapperRef,
  };
};
function CustomDropDown({
  children,
  collapsed,
  header,
  wrapperRef,
  innerWrapperClassName,
  setCollapsed,
}) {
  const innerWrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setCollapsed(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  return (
    <div className="relative z-30 flex flex-grow flex-col">
      {header}
      <div
        style={{
          maxHeight: '220px',
          overflowY: 'scroll',
        }}
        ref={innerWrapperRef}
        className={`absolute top-7 flex flex-grow flex-col rounded-b-lg ${
          collapsed ? 'hidden' : ''
        } animate-animated animate-faster animate-fadeIn items-start ltr:text-left rtl:text-right ${
          innerWrapperClassName ?? ''
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default CustomDropDown;
