import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable, { DataTableItem } from '../../../components/DataTable/DataTable';
import EmptyTable from '../../../components/DataTable/EmptyTable';
import { LargerTitle2 } from '../../../components/Titles';
import { apiGetRequest } from '../../../helpers/APIHelper';
import { setAction } from '../../../helpers/ActionsHelper';
import useNotifications from '../../../helpers/useNotifications';
import { SET_FUNDS, UPDATE_FUND_ITEM } from '../../../redux/FundsReducer';
import Head from '../../Head';
import FundPreview from './FundPreview';
import FundTableItem from './FundTableItem';
import { approveClickHandler } from './Helper';
import RejectionForm from './RejectionForm';

const mapStateToProps = (state) => ({
  funds: state.funds.funds,
});

const FundTab = function (props) {
  const {
    setFilterStatusID,
    currentStatusID,
    text,
    statusID,
    setCurrentPage,
    setSelectedItem,
    setRejectingFund,
  } = props;
  const dispatch = useDispatch();
  return (
    <button
      type="button"
      className={`pb-2 pt-1 text-lg capitalize ltr:pr-9 rtl:pl-9 ${
        currentStatusID === statusID ? 'tab_underline text-primary' : ''
      }`}
      onClick={() => {
        if (statusID === currentStatusID) {
          return;
        }
        setCurrentPage(1);
        dispatch(setAction(SET_FUNDS, []));
        setFilterStatusID(parseInt(statusID, 10));
        setSelectedItem(null);
        setRejectingFund(null);
      }}
    >
      {text}
    </button>
  );
};

function Funds(props) {
  const { funds } = props;
  const userFunds = funds;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies(['token']);
  const [invalidateData, setInvalidateData] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rejectingFund, setRejectingFund] = useState(null);
  const [filterStatusID, setFilterStatusID] = useState(
    parseInt(process.env.REACT_APP_OPEN_STATUS_ID, 10),
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLimit] = useState(10);
  const [totalResults, setTotalResults] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const params = useParams();
  const { t } = useTranslation(['funds', 'common']);
  const [searchKeywords, setSearchKeywords] = useState('');
  const [, addNotification] = useNotifications();

  useEffect(() => {
    setUpdating(true);
    apiGetRequest(
      'funds',
      cookies.token,
      {
        status_id: filterStatusID,
        paginate: paginationLimit,
        page: currentPage,
        created_at: 'desc',
        days: '180',
        ...(searchKeywords.length > 0 && { keyword: searchKeywords }),
      },
      ['status', 'attachments'],
      {},
      true,
    )
      .then(([data, paginator]) => {
        dispatch(setAction(SET_FUNDS, data));
        setTimeout(() => {
          setInvalidateData(invalidateData + 1);
        }, 1000 * 60);
        setTotalResults(paginator.total);
        setUpdating(false);
      })
      .catch(() => {
        setUpdating(false);
      });
  }, [invalidateData, filterStatusID, currentPage, searchKeywords]);

  useEffect(() => {
    if (params.id) {
      setSelectedItem(funds.filter((fund) => fund.id === parseInt(params.id, 10))[0]);
    }
  }, []);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div className="flex flex-grow flex-col">
      <Head title="Fund Request" />

      <div className="mb-5 flex flex-row justify-between self-stretch">
        <LargerTitle2>{t('requests')}</LargerTitle2>
      </div>
      <div className="flex flex-row flex-wrap pb-4 font-semibold ltr:text-left rtl:text-right">
        <div className="flex flex-grow flex-row items-start self-start ltr:text-left rtl:text-right">
          <FundTab
            setRejectingFund={setRejectingFund}
            setSelectedItem={setSelectedItem}
            setCurrentPage={setCurrentPage}
            currentStatusID={filterStatusID}
            setFilterStatusID={setFilterStatusID}
            statusID={parseInt(process.env.REACT_APP_OPEN_STATUS_ID, 10)}
            text={t('pending')}
          />
          <FundTab
            setRejectingFund={setRejectingFund}
            setSelectedItem={setSelectedItem}
            setCurrentPage={setCurrentPage}
            currentStatusID={filterStatusID}
            setFilterStatusID={setFilterStatusID}
            statusID={parseInt(process.env.REACT_APP_APPROVAL_STATUS_ID, 10)}
            text={t('approved')}
          />
          <FundTab
            setRejectingFund={setRejectingFund}
            setSelectedItem={setSelectedItem}
            setCurrentPage={setCurrentPage}
            currentStatusID={filterStatusID}
            setFilterStatusID={setFilterStatusID}
            statusID={parseInt(process.env.REACT_APP_REJECTION_STATUS_ID, 10)}
            text={t('rejected')}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-20">
        <DataTable
          setSearch={setSearchKeywords}
          pagination
          currentCount={userFunds.length}
          setPage={setCurrentPage}
          totalResults={totalResults}
          limit={paginationLimit}
          currentPage={currentPage}
          updating={updating}
          split
        >
          {userFunds.length === 0 && <EmptyTable />}
          {userFunds.map((fundItem, key) => {
            if (fundItem.status_id === filterStatusID) {
              return (
                <DataTableItem
                  className="px-4 pt-2"
                  last={key === userFunds.length - 1}
                  selected={selectedItem && fundItem.id === selectedItem.id}
                  key={fundItem.id}
                >
                  <FundTableItem
                    last={key === userFunds.length - 1}
                    className={
                      selectedItem && fundItem.id !== selectedItem.id && rejectingFund !== null
                        ? 'opacity-40'
                        : ''
                    }
                    fundItem={fundItem}
                    onClick={() => {
                      if (isMobile) {
                        navigate(`../funds/details/${fundItem.id}`);
                      } else {
                        navigate(`${fundItem.id}`);
                        setSelectedItem(fundItem);
                        setRejectingFund(null);
                      }
                    }}
                  />
                </DataTableItem>
              );
            }
            return null;
          })}
        </DataTable>
        {rejectingFund === null && (
          <FundPreview
            rejectAction={() => Promise.resolve(setRejectingFund(selectedItem))}
            approveAction={() =>
              approveClickHandler(selectedItem.id, cookies.token)
                .then((result) => {
                  dispatch(setAction(UPDATE_FUND_ITEM, result));
                  setSelectedItem(null);
                })
                .catch((err) => {
                  addNotification(
                    (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
                    false,
                  );
                })
            }
            selectedItem={selectedItem}
          />
        )}
        {rejectingFund !== null && (
          <RejectionForm
            setRejectingFund={setRejectingFund}
            selectedFund={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Funds);
