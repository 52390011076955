import { useDispatch, useSelector } from 'react-redux';
import AppCloseButton from '../../../../../components/AppCloseButton';
import { AppHeader } from '../../../../../components/html/html';
import { hideCardProgramActionsPopup } from '../../../../../redux/WalletReducer/WalletActions';

function CardProgramActionsPopupHeader() {
  const { currentCardProgramActionsPopupContent } = useSelector((state) => state.wallet);

  const dispatch = useDispatch();

  return (
    <div className="flex items-center justify-between">
      <AppHeader h="h5">{currentCardProgramActionsPopupContent?.header}</AppHeader>

      <AppCloseButton
        className="ms-auto"
        onClick={() => {
          dispatch(hideCardProgramActionsPopup());
        }}
      />
    </div>
  );
}

export default CardProgramActionsPopupHeader;
