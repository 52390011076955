import { AppIcon } from '../html/html';

function AppAlert({ text, type }) {
  const info = {
    containerClasses: 'border-info-1 bg-info-light',
    textClasses: 'text-info',
  };

  const classes = {
    info,
  };

  return (
    <div className={`flex gap-2 rounded-2xl border p-5 ${classes[type].containerClasses}`}>
      <div>
        <AppIcon iClass="LargeFont" className="fa-solid fa-circle-info text-info" />
      </div>
      <div className={`font-bold ${classes[type].textClasses}`}>{text}</div>
    </div>
  );
}

export default AppAlert;
