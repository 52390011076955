// eslint-disable-next-line import/no-extraneous-dependencies
import Intercom from '@intercom/messenger-js-sdk';
import { status } from 'constants-js';
import Cookies from 'js-cookie';
import { history } from '../../AppRouter/history';
import {
  deleteLocalData,
  devLog,
  isAccountReady,
  navigateUncompletedAccountTo,
  setLocalData,
} from '../../helpers/Utils';
import { http } from '../../http/index';
import { showToasterAction } from '../AppToastersReducer/AppToastersActions';
import store from '../store';

export const setRedirectURLAction = (url) => (dispatch) => {
  dispatch({
    type: 'SET_REDIRECT_URL',
    payload: url,
  });
};

export const setActiveLoginStepAction = (step) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_LOGIN_STEP',
    payload: step,
  });
};

export const setCachedLoginFormDataAction = (loginFormData) => (dispatch) => {
  dispatch({
    type: 'SET_CACHED_LOGIN_STEP_DATA',
    payload: loginFormData,
  });
};

export const setUserTokenAction =
  ({ userToken, expires }) =>
  (dispatch) => {
    const now = Date.now();
    const expiresAtDate = new Date(now);
    expiresAtDate.setSeconds(expires);
    Cookies.set('token', userToken, { expires: expiresAtDate });

    dispatch({
      type: 'SET_USER_TOKEN',
      payload: userToken,
    });
  };

export const clearUserDataAction = () => (dispatch) => {
  deleteLocalData('userinfo');
  deleteLocalData('capabilities');
  Cookies.remove('token');

  dispatch({
    type: 'CLEAR_USER_DATA',
  });
};

export const logOutAction = () => (dispatch) => {
  const { userToken } = store.getState().auth;

  const logOut = () => {
    history.replace('/auth/login');

    dispatch(clearUserDataAction());

    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_ID,
      hide_default_launcher: true,
    });
  };

  if (userToken) {
    http.get('users/logout').then(() => {
      logOut();
    });
  } else {
    logOut();
  }
};

export const setFinishQuickSignUpAction = (flag) => (dispatch) => {
  dispatch({
    type: 'SET_FINISH_QUICK_SIGN_UP',
    payload: flag,
  });
};

export const getUserPermissionsAction = () => (dispatch) => {
  http
    .get('users/role_permissions')
    .then((res) => {
      setLocalData('capabilities', res.data);
      dispatch({
        type: 'SET_USER_CAPABILITIES',
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: err?.data?.error,
        }),
      );
    });
};

export const getUserDataAction = () => (dispatch) => {
  devLog(`Getting User Data...`);
  http
    .get('users/me')
    .then((res) => {
      const userData = res.data;
      setLocalData('userinfo', userData);
      dispatch({
        type: 'SET_USER_DATA',
        payload: userData,
      });

      if (isAccountReady(userData)) {
        devLog(`Account is Ready!, Getting user Permissions...`);
        dispatch(getUserPermissionsAction());
      } else {
        devLog(`Account is NOT Ready!, Navigating user to complete account...`);
        history.replace(navigateUncompletedAccountTo(userData));
      }
    })
    .catch((err) => {
      if (err?.status === status.unauthorized) {
        dispatch(logOutAction());
      }

      dispatch(
        showToasterAction({
          type: 'error',
          message: err?.data?.error,
        }),
      );
    });
};
