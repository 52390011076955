import { randomId } from 'javascript-functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AppPagination({ currentPage, totalPages, onPageChange, isDisabled }) {
  const { t } = useTranslation('commonV2');

  const maxVisiblePages = 5;
  const pages = [];
  const activePageClasses = 'bg-primary font-bold text-white';

  const range = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const handlePageChange = (page) => {
    if (page !== '...') {
      onPageChange(page);
    }
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  if (totalPages <= maxVisiblePages) {
    pages.push(...range(1, totalPages));
  } else {
    const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
    if (currentPage <= halfMaxVisiblePages) {
      pages.push(...range(1, maxVisiblePages));
      pages.push('...');
      pages.push(totalPages);
    } else if (currentPage >= totalPages - halfMaxVisiblePages) {
      pages.push(1);
      pages.push('...');
      pages.push(...range(totalPages - maxVisiblePages + 1, totalPages));
    } else {
      pages.push(1);
      pages.push('...');
      pages.push(...range(currentPage - halfMaxVisiblePages, currentPage + halfMaxVisiblePages));
      pages.push('...');
      pages.push(totalPages);
    }
  }

  return (
    <nav className={isDisabled ? 'pointer-events-none opacity-50' : ''}>
      <ul className="flex items-center gap-1">
        <li
          className={`cursor-pointer px-2 ${currentPage === 1 ? 'opacity-30' : ''}`}
          onClick={handlePrevClick}
        >
          {t('Previous')}
        </li>
        {pages.map((page) => (
          <li
            key={randomId()}
            className={`flex h-7 w-7 cursor-pointer items-center justify-center rounded-full ${
              page === currentPage ? activePageClasses : ''
            }`}
            onClick={() => {
              handlePageChange(page);
            }}
          >
            {page}
          </li>
        ))}
        <li
          className={`cursor-pointer px-2 ${currentPage === totalPages ? 'opacity-30' : ''}`}
          onClick={handleNextClick}
        >
          {t('Next')}
        </li>
      </ul>
    </nav>
  );
}

export default AppPagination;
