import { useTranslation } from 'react-i18next';
import Head from '../../Head';
import CardDetails from './CardDetails';
import CardList from './CardList';

function MyCards() {
  const { t } = useTranslation('cardsV2');

  return (
    <>
      <Head title={t('My Cards')} />

      <div className="flex h-[99%] w-full overflow-hidden rounded-lg border shadow-md">
        <div className="flex w-full flex-col justify-between overflow-y-auto bg-gray-4 px-2 pt-8 lg:w-[223px]">
          {/* Left Side */}
          <CardList />
        </div>

        {/* Right Side */}
        <div className="hidden w-full lg:block">
          <CardDetails />
        </div>
      </div>
    </>
  );
}

export default MyCards;
