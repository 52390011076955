import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formateDateToYYYYMMDD } from '../../../helpers/Utils';
import { hideModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import AppCloseButton from '../../AppCloseButton';
import AppDatePicker from '../../AppDatePicker';
import AppSelect from '../../FormInputs/AppSelect';
import { AppButton, AppHeader } from '../../html/html';

function TimeRangeFilterMobile({
  timeRangeFilters,
  filterType,
  setFilterType,
  daysOptions,
  days,
  setDays,
  monthsOptions,
  month,
  setMonth,
  yearsOptions,
  year,
  setYear,
  customFrom,
  setCustomFrom,
  customTo,
  setCustomTo,
  updateTimeRange,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation('commonV2');

  useEffect(() => {
    // ! ============ First Call For Mobile ============ //
    updateTimeRange();
  }, []);

  return (
    <div className="top-[41vh] z-30 h-fit w-[90vw] overflow-y-auto rounded-2xl border border-gray-3 bg-white p-5 pt-0 shadow md:w-96 lg:absolute lg:end-0 lg:top-12 lg:min-h-fit">
      {/* Header */}
      <div className="sticky top-0 z-10 mb-2 bg-white pb-3 pt-5">
        <div className="flex items-center">
          <AppHeader h="h5">{t('Time Range')}</AppHeader>

          <AppCloseButton
            className="ms-auto"
            onClick={() => {
              dispatch(hideModalAction());
            }}
          />
        </div>
      </div>

      {/* Content */}
      <div className="grid gap-4">
        {/* Filter Type */}
        <div>
          <AppSelect
            options={timeRangeFilters}
            value={filterType}
            onChange={(value) => {
              setFilterType(value);
            }}
            className="w-full text-xs"
          />
        </div>

        {filterType?.value === 'days' && (
          <div>
            <AppSelect
              placeholder={t('Select')}
              options={daysOptions}
              value={days}
              onChange={(value) => {
                setDays(value);
              }}
              className="w-full text-xs"
            />
          </div>
        )}

        {filterType?.value === 'monthOf' && (
          <>
            {/* Month */}
            <AppSelect
              placeholder={t('Select')}
              options={monthsOptions}
              value={month}
              onChange={(value) => {
                setMonth(value);
                setYear(null);
              }}
              className="w-full text-xs"
            />

            {/* Year */}
            <AppSelect
              placeholder={t('Select')}
              options={yearsOptions}
              value={year}
              onChange={(value) => {
                setYear(value);
              }}
              className="w-full text-xs"
              isDisabled={!month}
            />
          </>
        )}

        {filterType?.value === 'custom' && (
          <>
            <AppDatePicker
              inputClass="mobile-focus text-xs h-10 w-full rounded-xl text-black border-gray-3 focus:border-primary disabled:cursor-not-allowed disabled:opacity-50 focus:border-gray-3"
              calendarPosition="bottom"
              maxDate={new Date()}
              placeholder={t('Select From')}
              value={customFrom}
              onChange={(val) => {
                setCustomTo('');
                setCustomFrom(formateDateToYYYYMMDD(val));
              }}
            />
            <AppDatePicker
              inputClass="mobile-focus text-xs h-10 w-full rounded-xl text-black border-gray-3 focus:border-primary disabled:cursor-not-allowed disabled:opacity-50 focus:border-gray-3"
              calendarPosition="bottom"
              maxDate={moment(customFrom).add(3, 'months').toDate()}
              minDate={moment(customFrom).add(1, 'days').toDate()}
              placeholder={t('Select To')}
              disabled={!customFrom}
              value={customTo}
              onChange={(val) => {
                setCustomTo(formateDateToYYYYMMDD(val));
              }}
            />
          </>
        )}

        <div className="mt-5 flex items-center justify-end gap-4 font-medium">
          <AppButton
            onClick={() => {
              updateTimeRange();
              dispatch(hideModalAction());
            }}
            className="px-4"
          >
            {t('Apply', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </div>
    </div>
  );
}

export default TimeRangeFilterMobile;
