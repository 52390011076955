import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrintButton from '../../../../../../components/AppPrint/PrintButton';
import { hideWalletCardActivityFormPopupAction } from '../../../../../../redux/WalletReducer/WalletActions';
import {AppIcon} from "../../../../../../components/html/html";

function WalletCardsActivityIcons({ walletRequestPrintWrapperRef }) {
  const { showWalletCardsActivityFormPopup, singleWalletCardActivityDetails } = useSelector(
    (state) => state.wallet,
  );
  const dispatch = useDispatch();

  const showBackBtn = useMemo(() => {
    return showWalletCardsActivityFormPopup;
  }, [showWalletCardsActivityFormPopup]);

  const showRequestPrint = useMemo(() => {
    return !showWalletCardsActivityFormPopup && singleWalletCardActivityDetails;
  }, [showWalletCardsActivityFormPopup, singleWalletCardActivityDetails]);

  return (
    <>
      {/* Back Btn */}
      {showBackBtn && (
        <button
          className="hover:text-primary rtl:rotate-180"
          type="button"
          onClick={() => {
            dispatch(hideWalletCardActivityFormPopupAction());
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-arrow-left" />
        </button>
      )}

      {/* Print Request */}
      {showRequestPrint && (
        <PrintButton
          ref={walletRequestPrintWrapperRef}
          documentTitle={`card_activity_${singleWalletCardActivityDetails?.id}`}
        >
          <AppIcon iClass="XLargeFont" className="fa-regular fa-print hover:text-primary" />
        </PrintButton>
      )}
    </>
  );
}

export default WalletCardsActivityIcons;
