import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../../../../components/Button/ActionButton';
import {Checkbox} from '../../../../components/FormInputs/Inputs';
import LoadingError from '../../../../components/LoadingError';
import CompanyStepBar from '../../../../components/Stepbar/CompanyStepBar';
import {HeadlineCenter} from '../../../../components/Titles';
import {apiGetRequest, apiPatchRequest} from '../../../../helpers/APIHelper';
import useNotifications from '../../../../helpers/useNotifications';
import {AppBody} from "../../../../components/html/html";

function AcceptTerms({ isOwner }) {
  const { t, i18n } = useTranslation(['signup', 'common']);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [cookies] = useCookies(['token']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [, addNotification] = useNotifications();

  const [terms, setTerms] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [content, setContent] = useState('');

  const prepare = () => {
    setIsLoading(true);
    apiGetRequest(`agreements/key/${isOwner ? 'company' : 'user'}-agreement`, cookies.token)
      .then((response) => {
        setTerms(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    prepare();
    clearErrors();
  }, [i18n.language]);

  useEffect(() => {
    if (terms) {
      const contentDB = i18n.language === 'ar' ? terms.content_ar : terms.content;
      setContent(contentDB);
    }
  }, [terms]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    apiPatchRequest('users/terms/accept', {}, cookies.token)
      .then((response) => {
        if (isOwner) {
          navigate('/signup/thanks');
        } else {
          navigate('/dashboard');
        }
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setIsSubmitting(false);
      })
      .finally((err) => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="mx-auto flex w-full flex-col lg:w-8/12">
      {isOwner && <CompanyStepBar step={5} />}
      <HeadlineCenter>{t('terms.title')}</HeadlineCenter>
      <AppBody type="span" pClass="Body2Regular" className="centerTitle relative m-auto max-w-md pb-4 pb-5 pt-2 text-center text-gray-6">
        {t('terms.subtitle')}
      </AppBody>
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5 mt-5">
          {!terms && (
            <div className="mx-auto flex w-full flex-col lg:w-10/12">
              <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
                <div className="col-span-6 flex flex-col justify-start">
                  {isLoading && <Skeleton containerClassName="w-full" count={3} />}
                  <LoadingError show={errored && !isLoading} retryCallback={prepare} />
                </div>
              </div>
            </div>
          )}
          {/* eslint-disable-next-line react/no-danger */}
          {terms && (
            <div
              className="ql-editor ltr:text-left rtl:text-right"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}

          <AppBody pClass="Body1Bold" type="label"
            htmlFor="accept"
            className="mt-20 flex flex-row items-baseline"
          >
            <Checkbox
              className="scale-125 transform ltr:mr-2 rtl:ml-2"
              id="accept"
              name="accept"
              {...register('accept', {
                required: t('terms.required'),
              })}
            />
            <AppBody pClass="Body2bold" type="span">{t('terms.accept')}</AppBody>
          </AppBody>
          <AppBody pClass="Caption1Regular" className="pt-2 text-red-700 ltr:text-left rtl:text-right">
            {errors.accept && errors.accept.message}
          </AppBody>
        </div>

        <div className="mt-5 flex flex-row justify-end self-stretch pt-5">
          <ActionButton
            className="flex flex-row"
            type="submit"
            primary
            text={t('submit', { ns: 'common' })}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
}

export default AcceptTerms;
