import React from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

const PrintButton = React.forwardRef(({ documentTitle, children }, ref) => {
  return (
    <ReactToPrint content={() => ref.current} documentTitle={documentTitle}>
      <PrintContextConsumer>
        {({ handlePrint }) => (
          <button type="button" onClick={handlePrint}>
            {children}
          </button>
        )}
      </PrintContextConsumer>
    </ReactToPrint>
  );
});

export default PrintButton;
