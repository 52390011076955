import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppBody } from '../../../components/html/html';
import { showModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import {
  showUsersListFilterPopupAction,
  showUsersListSearchPopupAction,
} from '../../../redux/UsersReducer/UsersActions';
import UsersListFilterPopup from './UsersListFilterPopup';
import UsersListSearchPopup from './UsersListSearchPopup/index';

function UsersListTableActionButtons({
  handleOnSearch,
  filterTags,
  handleApplyFilters,
  isDisabled,
}) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();

  const tableActionButtons = useMemo(() => {
    return [
      {
        label: t('Search', { ns: 'commonV2' }),
        onClick: () => {
          if (isMobile) {
            dispatch(showModalAction(<UsersListSearchPopup onSearch={handleOnSearch} />));
          }
          dispatch(showUsersListSearchPopupAction());
        },
        show: true,
      },
      {
        label: t('Filter', { ns: 'commonV2' }),
        onClick: () => {
          if (isMobile) {
            dispatch(
              showModalAction(
                <UsersListFilterPopup filterTags={filterTags} onApply={handleApplyFilters} />,
              ),
            );
          }
          dispatch(showUsersListFilterPopupAction());
        },
        show: true,
      },
    ];
  }, [isMobile, filterTags, t]);

  return (
    <AppBody
      type="div"
      pClass="Caption1Bold"
      className={`flex text-primary ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}
    >
      {tableActionButtons.map((ele) => {
        return (
          ele.show && (
            <button
              disabled={ele.disabled}
              key={ele.label}
              onClick={ele.onClick}
              type="button"
              className="rounded-full px-3 py-2 text-xs text-primary hover:bg-gray-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
            >
              {ele.label}
            </button>
          )
        );
      })}
    </AppBody>
  );
}

export default UsersListTableActionButtons;
