import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';

import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import {useDispatch, useSelector} from 'react-redux';
import LoadingError from '../../components/LoadingError';
import {apiGetRequest} from '../../helpers/APIHelper';
import {setAction} from '../../helpers/ActionsHelper';
import {SET_TOP_DEPARTMENT} from '../../redux/StatisticsReducer';
import {AppBody} from "../../components/html/html";

const TopDept = function ({ daysFilter }) {
  const { t, i18n } = useTranslation(['dashboard']);
  const { departments: topDept } = useSelector((state) => state.statistics);
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [total, setTotal] = useState(0);

  const loadDepartments = () => {
    setLoading(true);
    apiGetRequest('transactions/stats/top-departments', cookies.token, {
      days: daysFilter,
      limit: 5,
    })
      .then((response) => {
        dispatch(setAction(SET_TOP_DEPARTMENT, response));
        if (response.length > 0) {
          setTotal(
            response
              .map((item) => item.billing_amount)
              .reduce((prev, curr) => {
                if (curr < 0) {
                  // eslint-disable-next-line no-param-reassign
                  curr *= -1;
                }
                if (prev < 0) {
                  // eslint-disable-next-line no-param-reassign
                  prev *= -1;
                }
                return parseFloat(prev) + parseFloat(curr);
              }),
          );
        }
        setLoading(false);
        setErrored(false);
      })
      .catch(() => {
        setErrored(topDept.length === 0);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDepartments();
  }, [daysFilter, i18n.language]);

  return (
    <div className="flex flex-col items-start justify-start p-5">
      <AppBody pClass="Body1Bold" className="pb-5 capitalize lg:text-xl ltr:text-left rtl:text-right">
        {t('department_stat')}
      </AppBody>
      {loading && <Skeleton containerClassName="w-full" count={3} />}
      <LoadingError show={errored && !loading} retryCallback={loadDepartments} />
      {!loading &&
        topDept.length > 0 &&
        topDept.map((dept) => (
          <div className="mb-4 w-full" key={dept.department_id && dept.department_id.name}>
            <div className="grid grid-cols-2 pb-2 pt-2">
              {dept.department_id && (
                <span className="col-span-1 ltr:text-left rtl:text-right">
                  {i18n.language === 'ar' ? dept.department_id.name_ar : dept.department_id.name}
                </span>
              )}
              {!dept.department_id && (
                <span className="col-span-1 ltr:text-left rtl:text-right">---</span>
              )}
              {dept.billing_amount >= 0 && (
                <span className="col-span-1 text-right">
                  <NumberFormat
                    value={parseFloat(dept.billing_amount).toFixed(2)}
                    displayType="text"
                    thousandSeparator
                    prefix={`${dept.billing_currency} (`}
                  />
                </span>
              )}
              {dept.billing_amount < 0 && (
                <span className="col-span-1 ltr:text-right rtl:text-left">
                  <NumberFormat
                    value={parseFloat(dept.billing_amount).toFixed(2) * -1}
                    displayType="text"
                    thousandSeparator
                    prefix={`${dept.billing_currency} (`}
                  />
                  )
                </span>
              )}
            </div>
            <div className="dark:bg-bg-black h-2.5 w-full rounded-full bg-gray-2">
              {dept.billing_amount >= 0 && (
                <div
                  className="h-2.5 rounded-full bg-black"
                  style={{ width: `${(dept.billing_amount / total) * 100}%` }}
                />
              )}
              {dept.billing_amount < 0 && (
                <div
                  className="h-2.5 rounded-full bg-black"
                  style={{ width: `${((dept.billing_amount * -1) / total) * 100}%` }}
                />
              )}
            </div>
          </div>
        ))}
      {!loading && topDept.length === 0 && (
        <>
          <div className="mb-4 w-full" key="dept-none1">
            <div className="grid grid-cols-2 pb-2 pt-2">
              <span className="col-span-1 ltr:text-left rtl:text-right">-</span>
              <span className="col-span-1 ltr:text-right rtl:text-left">0.0</span>
            </div>
            <div className="dark:bg-bg-black h-2.5 w-full rounded-full bg-gray-2">
              <div className="h-2.5 rounded-full bg-black" style={{ width: '0%' }} />
            </div>
          </div>
          <div className="mb-4 w-full" key="dept-none2">
            <div className="grid grid-cols-2 pb-2 pt-2">
              <span className="col-span-1 ltr:text-left rtl:text-right">-</span>
              <span className="col-span-1 ltr:text-right rtl:text-left">0.0</span>
            </div>
            <div className="dark:bg-bg-black h-2.5 w-full rounded-full bg-gray-2">
              <div className="h-2.5 rounded-full bg-black" style={{ width: '0%' }} />
            </div>
          </div>
          <div className="mb-4 w-full" key="dept-none3">
            <div className="grid grid-cols-2 pb-2 pt-2">
              <span className="col-span-1 ltr:text-left rtl:text-right">-</span>
              <span className="col-span-1 ltr:text-right rtl:text-left">0.0</span>
            </div>
            <div className="dark:bg-bg-black h-2.5 w-full rounded-full bg-gray-2">
              <div className="h-2.5 rounded-full bg-black" style={{ width: '0%' }} />
            </div>
          </div>
          <div className="mb-4 w-full" key="dept-none4">
            <div className="grid grid-cols-2 pb-2 pt-2">
              <span className="col-span-1 ltr:text-left rtl:text-right">-</span>
              <span className="col-span-1 ltr:text-right rtl:text-left">0.0</span>
            </div>
            <div className="dark:bg-bg-black h-2.5 w-full rounded-full bg-gray-2">
              <div className="h-2.5 rounded-full bg-black" style={{ width: '0%' }} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TopDept;
