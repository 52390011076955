import {useTranslation} from 'react-i18next';
import {AppBody} from "../html/html";

const EmptyTable = function () {
  const { t } = useTranslation(['common']);

  return <AppBody type="div" pClass="Caption1Regular" className="my-10 self-center text-gray-6">{t('empty_table')}</AppBody>;
};

export default EmptyTable;
