import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import nqoodBG from '../../assets/png/nqoodBG.png';
import { employeeRegistrationSteps } from '../../helpers/constants';
import { setRegistrationStepAction } from '../../redux/EmployeeRegistrationReducer/EmployeeRegistrationActions';
import ActivateAccount from './ActivateAccount';
import AlreadyActivatedAccount from './AlreadyActivatedAccount';
import EmployeePasswordSetSuccess from './EmployeePasswordSetSuccess';
import ExpiredLink from './ExpiredLink';
import InvalidToken from './InvalidToken';
import SetupPassword from './SetupPassword';

function EmployeeRegistration() {
  const { activeRegistrationStep } = useSelector((state) => state.employeeRegistration);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setRegistrationStepAction(employeeRegistrationSteps.activateAccount));
    };
  }, []);

  return (
    <div
      className="h-full bg-gray-1 bg-cover bg-no-repeat p-0 xxs:p-4 xs:p-7 md:p-12 lg:h-[98%] lg:rounded-xl lg:border lg:border-gray-5 lg:px-24 lg:py-9 xl:px-40"
      style={{ backgroundImage: `url(${nqoodBG})` }}
    >
      <div className="h-full overflow-y-auto rounded-2xl bg-gray-1 shadow-xl">
        {activeRegistrationStep === employeeRegistrationSteps.activateAccount && (
          <ActivateAccount />
        )}
        {activeRegistrationStep === employeeRegistrationSteps.setupPassword && <SetupPassword />}
        {activeRegistrationStep === employeeRegistrationSteps.passwordSetSuccess && (
          <EmployeePasswordSetSuccess />
        )}
        {activeRegistrationStep === employeeRegistrationSteps.expiredLink && <ExpiredLink />}
        {activeRegistrationStep === employeeRegistrationSteps.alreadyActivatedAccount && (
          <AlreadyActivatedAccount />
        )}
        {activeRegistrationStep === employeeRegistrationSteps.invalidToken && <InvalidToken />}
      </div>
    </div>
  );
}

export default EmployeeRegistration;
