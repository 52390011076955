import React from 'react';
import { useTranslation } from 'react-i18next';
import NoUserCardImgOnly from '../../../assets/svg/Card.svg';
import AppImageComponent from '../../../components/AppImageComponent';
import { AppHeader } from '../../../components/html/html';
import LoginForm from './LoginForm';

function LoginBannerAndForm() {
  const { t } = useTranslation('loginV2');

  return (
    <div className="grid h-full grid-cols-1 gap-12 lg:grid-cols-2">
      <div className="flex flex-col items-center justify-center gap-14">
        <AppImageComponent
          src={NoUserCardImgOnly}
          alt="Card Image"
          skeletonWidth={288}
          skeletonHeight={236}
        />
        <AppHeader className="hidden lg:block">
          <span className="block">{t('It’s more than')}</span>
          <span className="block">{t('issuing a card!')}</span>
        </AppHeader>
      </div>
      <div>
        <LoginForm />
      </div>
    </div>
  );
}
export default LoginBannerAndForm;
