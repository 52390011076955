/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {MultiSelect} from 'react-multi-select-component';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../../../../components/Button/ActionButton';
import CustomPhone from '../../../../components/FormInputs/CustomPhone';
import {SelectInputCustom, TextInput} from '../../../../components/FormInputs/Inputs';
import CompanyStepBar from '../../../../components/Stepbar/CompanyStepBar';
import {HeadlineCenter} from '../../../../components/Titles';
import {apiPostFormDataRequest} from '../../../../helpers/APIHelper';
import {NameValidationPattern, UrlValidationPattern} from '../../../../helpers/RegexPatterns';
import useNotifications from '../../../../helpers/useNotifications';
import {AppBody} from "../../../../components/html/html";

function BusinessInfo() {
  const { user } = useSelector((state) => state.auth);

  const { t, i18n } = useTranslation(['signup', 'common']);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: 'onBlur' });

  const [useServiceOther, setUServiceOther] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [cookies] = useCookies(['token']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [, addNotification] = useNotifications();
  const servicesItems = [
    { value: 'Corporate Expense', label: t('second_contact.corporate_expense') },
    { value: 'Airlines', label: t('second_contact.airlines') },
    { value: 'Hotels', label: t('second_contact.hotels') },
    { value: 'Travel Agencies', label: t('second_contact.travel_agencies') },
    { value: 'Car Hires', label: t('second_contact.car_hires') },
    { value: 'Insurance Company', label: t('second_contact.insurance_company') },
    { value: 'Tour Operators', label: t('second_contact.tour_operators') },
    {
      value: 'Other',
      label: `${t('second_contact.other')}(${t('second_contact.please_specify')})`,
    },
  ];

  const useServices = (selected) => {
    setSelectedItems(selected);
    setUServiceOther(false);
    Object.entries(selected).forEach(([, item]) => {
      if (item.value === 'Other') {
        setUServiceOther(true);
      }
    });
  };
  const onSubmit = (data) => {
    const form = new FormData();
    setIsSubmitting(true);

    let useOfServices = [];
    for (const property in selectedItems) {
      if (selectedItems[property].value !== 'Other') {
        useOfServices = [...useOfServices, selectedItems[property].value];
      }
    }

    if (useServiceOther && data.use_service_other) {
      useOfServices = [...useOfServices, data.use_service_other];
    }
    form.append('use_services', useOfServices.join('|'));

    form.append('company_short_name', data.short_company_name);
    form.append('employee_count', data.employee_count);
    form.append('avg_spend_monthly', data.monthly_spending);
    form.append('avg_annual_revenue', data.annual_revenue);
    form.append('website', data.website);
    form.append('mobile', data.mobile);
    form.append('job_title', data.job_title);

    apiPostFormDataRequest(`accounts/${user.account_id}/business`, form, cookies.token)
      .then(() => {
        navigate('/signup/verify_company/company_address');
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setIsSubmitting(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  return (
    <div className="flex h-full items-center justify-center">
      <div className="mx-auto flex w-full flex-col lg:w-8/12">
        <CompanyStepBar step={1} />
        <div className="pb-10">
          <HeadlineCenter>{t('business_info.tell_us')}</HeadlineCenter>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-5 pb-5">
            <AppBody type="label" pClass="Body2Regular" className="col-span-2 flex flex-col lg:col-span-1" htmlFor="job_title">
              <AppBody pClass="Body1Bold" type="span">{t('business_info.job_title')}</AppBody>
              <TextInput
                id="job_title"
                error={errors.job_title}
                {...register('job_title', {
                  required: t('details.validation.required.job_title'),
                  pattern: {
                    value: NameValidationPattern,
                    message: t('details.validation.patterns.job_title'),
                  },
                })}
              />
            </AppBody>
            <AppBody type="label" pClass="Body2Regular"
              className="col-span-2 flex flex-col lg:col-span-1"
              htmlFor="employee_count"
            >
              <AppBody pClass="Body1Bold" type="span">{t('business_info.employee_count')}</AppBody>
              <SelectInputCustom
                onChange={(e) => {
                  setValue('employee_count', e);
                }}
                error={errors.employee_count}
                name="employee_count"
                id="employee_count"
                control={control}
                rules={{ required: t('business_info.employee_count_required') }}
                options={[
                  { value: '1-5', label: '1-5' },
                  { value: '6-49', label: '6-49' },
                  { value: '50-249', label: '50-249' },
                  { value: 'More than 249', label: 'More than 249' },
                ]}
              />
            </AppBody>
          </div>

          <div className="grid grid-cols-2 gap-5 pb-5">
            <AppBody type="label" pClass="Body2Regular" className="col-span-2 flex flex-col lg:col-span-1" htmlFor="website">
              <AppBody pClass="Body1Bold" type="span">{t('business_info.company_website')}</AppBody>
              <TextInput
                id="website"
                error={errors.website}
                {...register('website', {
                  required: t('details.validation.required.website'),
                  pattern: {
                    value: UrlValidationPattern,
                    message: t('details.validation.patterns.website'),
                  },
                })}
                type="text"
              />
            </AppBody>
            <AppBody type="label" pClass="Body2Regular"
              className="relative col-span-2 flex flex-col lg:col-span-1"
              htmlFor="short_company_name"
            >
              <AppBody pClass="Body1Bold" type="span">{t('business_info.short_name')}</AppBody>
              <TextInput
                id="short_company_name"
                error={errors.short_company_name}
                {...register('short_company_name', {
                  required: t('details.validation.required.short_company_name'),
                  pattern: {
                    value: NameValidationPattern,
                    message: t('details.validation.patterns.short_company_name'),
                  },
                  maxLength: {
                    value: 15,
                    message: t('details.validation.max.short_company_name'),
                  },
                })}
                maxLength={15}
              />
            </AppBody>
            <AppBody pClass="Caption1Regular" type="span" className="absolute bottom-0 text-gray-6 ltr:right-0 rtl:left-0">
              {t('business_info.max_char')}
            </AppBody>
          </div>

          <label className="flex flex-col pb-5" htmlFor="mobile">
            <AppBody pClass="Body2bold" type="span">{t('business_info.mobile')}</AppBody>
            <CustomPhone
              name="mobile"
              control={control}
              errors={errors.mobile}
              patternError={t('details.validation.patterns.mobile')}
              requiredString={t('details.validation.required.mobile')}
            />
          </label>

          <div className="grid grid-cols-2 gap-5 pb-5">
            <label className="col-span-2 flex flex-col lg:col-span-1" htmlFor="monthly_spending">
              <AppBody pClass="Body2bold" type="span">{t('business_info.monthly_spending')}</AppBody>
              <SelectInputCustom
                onChange={(e) => {
                  setValue('monthly_spending', e);
                }}
                error={errors.monthly_spending}
                name="monthly_spending"
                id="monthly_spending"
                control={control}
                rules={{ required: t('business_info.monthly_spending_required') }}
                options={[
                  { value: '3,000 - 10,000 S.R', label: '3,000 - 10,000 S.R' },
                  { value: '10,000 - 50,000 S.R', label: '10,000 - 50,000 S.R' },
                  { value: '50,000 - 100,000 S.R', label: '50,000 - 100,000 S.R' },
                  { value: 'Above 100,000 S.R', label: 'Above 100,000 S.R' },
                ]}
              />
            </label>
            <label className="col-span-2 flex flex-col lg:col-span-1" htmlFor="annual_revenue">
              <AppBody pClass="Body2bold" type="span">{t('business_info.annual_revenue')}</AppBody>
              <SelectInputCustom
                onChange={(e) => {
                  setValue('annual_revenue', e);
                }}
                error={errors.annual_revenue}
                name="annual_revenue"
                id="annual_revenue"
                control={control}
                rules={{ required: t('business_info.annual_revenue_required') }}
                options={[
                  { value: '10,000 - 3M S.R', label: '10,000 - 3M S.R' },
                  { value: '3M S.R - 40M S.R', label: '3M S.R - 40M S.R' },
                  { value: '40M S.R - 200M S.R', label: '40M S.R - 200M S.R' },
                  { value: 'Above 200M S.R', label: 'Above 200M S.R' },
                ]}
              />
            </label>
          </div>

          <label className="flex flex-col pt-5" htmlFor="use_service">
            <AppBody pClass="Body2bold" type="span">{t('second_contact.inputs.use_service')}</AppBody>
            <MultiSelect
              disableSearch
              onChange={useServices}
              options={servicesItems}
              value={selectedItems}
              className="mt-1 rounded-full border border-gray-3 "
              hasSelectAll={false}
              overrideStrings={{
                selectSomeItems: t('select', { ns: 'common' }),
                allItemsAreSelected: t('all_selected', { ns: 'common' }),
              }}
            />

            {useServiceOther && (
              <div className="mx-4 rounded-b-2xl bg-gray-2 p-2">
                <TextInput
                  className="mx-2 mt-1 rounded-full border border-gray-3  px-10 py-1 lg:mx-20"
                  placeholder={t('second_contact.please_specify')}
                  error={errors.use_service_other}
                  id="use_service_other"
                  {...register('use_service_other', {
                    required: t('second_contact.inputs.validation.required.use_service'),
                  })}
                />
              </div>
            )}
          </label>

          <div className="mt-5 flex flex-row justify-end self-stretch pt-5">
            <ActionButton
              className="flex flex-row"
              type="submit"
              primary
              text={t('submit', { ns: 'common' })}
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default BusinessInfo;
