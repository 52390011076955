import React from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import ar from 'react-phone-number-input/locale/ar.json';
import en from 'react-phone-number-input/locale/en.json';
import {AppBody} from "../html/html";

function CustomPhone({ requiredString, patternError, errors, control, name, defaultValue }) {
  const { i18n } = useTranslation([]);
  return (
    <>
      <Controller
        defaultValue={defaultValue}
        name={name}
        id={name}
        control={control}
        rules={{
          required: requiredString,
          // eslint-disable-next-line no-shadow
          validate: (value) => isValidPhoneNumber(value),
        }}
        render={({ field: { onChange, value, onBlur, ref } }) => (
          <PhoneInput
            inputRef={ref}
            onBlur={onBlur}
            value={value || defaultValue}
            onChange={onChange}
            className={`text-sm font-normal ${
              errors ? 'border-red-400' : 'border-gray-3'
            } mt-1 rounded-full border border-gray-3   px-5 py-1.5`}
            style={{ direction: 'ltr' }}
            countries={['SA', 'OM', 'AE', 'EG', 'KW', 'QA', 'BH']}
            defaultCountry="SA"
            international
            id={name}
            labels={i18n.language === 'ar' ? ar : en}
            flagUrl="/images/{XX}.png"
          />
        )}
      />
        {errors && <AppBody pClass="Caption1Regular" className="pt-2 text-red-700">{patternError}</AppBody>}
    </>
  );
}

export default CustomPhone;
