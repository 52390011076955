import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServerErrMessage } from '../helpers/Utils';
import { simplifiSharedConfig } from '../helpers/constants';
import { http } from '../http';
import { showToasterAction } from '../redux/AppToastersReducer/AppToastersActions';

const useSimplifi = ({ cardId, userId, simplifiAction }) => {
  const { loaders } = useSelector((state) => state);
  const dispatch = useDispatch();

  const simplifiId = useMemo(() => {
    return 'SIMPLIFI_CONTAINER';
  }, []);

  const simplifiLoaderName = useMemo(() => {
    return 'getSimplifyToken';
  }, []);

  const iframe = useMemo(() => {
    return <div id={simplifiId} className={simplifiId} />;
  }, [simplifiId]);

  const isSimplifiLoading = useMemo(() => {
    return loaders[simplifiLoaderName];
  }, [loaders]);

  const generateSimplifiIframe = useCallback(
    (token) => {
      const CONFIG = {
        token,
        cardId,
        userId,
        wrapperId: simplifiId,
        class: simplifiId,
        ...simplifiSharedConfig,
      };

      const simplifi = new window.Simplifi(CONFIG);
      simplifi[simplifiAction]();
    },
    [cardId, userId],
  );

  const getSimplifyToken = useCallback(() => {
    http
      .get(`cardmanager/simplifi/web-token/${cardId}`, { loader: simplifiLoaderName })
      .then((res) => {
        generateSimplifiIframe(res.data.access_token);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [cardId]);

  useEffect(() => {
    if (cardId && userId && simplifiAction) {
      getSimplifyToken();
    }
  }, [cardId]);

  return { iframe, isSimplifiLoading };
};

export default useSimplifi;
