import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppCloseButton from '../../../../../components/AppCloseButton';
import { AppHeader } from '../../../../../components/html/html';
import { hideModalAction } from '../../../../../redux/AppModalReducer/AppModalActions';
import { hideFilterPopupAction } from '../../../../../redux/CardDetailsReducer/CardDetailsActions';

function FilterPopupHeader() {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="flex items-center">
      <AppHeader h="h5">{t('Filter', { ns: 'commonV2' })}</AppHeader>

      <AppCloseButton
        className="ms-auto"
        onClick={() => {
          if (isMobile) {
            dispatch(hideModalAction());
          }
          dispatch(hideFilterPopupAction());
        }}
      />
    </div>
  );
}

export default FilterPopupHeader;
