import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../AppRouter/history';
import AppInput from '../../components/AppFormInputs/AppInput';
import DeveloperComponent from '../../components/DeveloperComponent';
import { AppButton, AppHeader } from '../../components/html/html';
import { employeeIdentityVerification } from '../../helpers/constants';
import { isDev, restrictLanguageInput } from '../../helpers/Utils';
import { setIdentityVerificationsStepAction } from '../../redux/EmployeeRegistrationReducer/EmployeeRegistrationActions';

function EmployeeIdentityVerificationFullNameForm() {
  const { t } = useTranslation('employeeRegistration');
  const dispatch = useDispatch();

  const onSubmit = useCallback((values) => {
    history.push('/signup/employee-accept-terms');
  }, []);

  const initialValues = useMemo(() => {
    return {
      firstName: '',
      fatherName: '',
      middleName: '',
      lastName: '',
      firstNameAr: '',
      fatherNameAr: '',
      middleNameAr: '',
      lastNameAr: '',
    };
  }, []);

  const validationSchema = yup.object({
    firstName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    fatherName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    middleName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    lastName: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    firstNameAr: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    fatherNameAr: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    middleNameAr: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    lastNameAr: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <div className="px-2 pt-14 xxs:px-4 xl:px-16">
      <AppHeader h="h2" className="mb-24 text-center">
        <span>{t('Hi')}</span>
        <span> Waad</span>
        <span>,</span>
      </AppHeader>

      <form onSubmit={formik.handleSubmit}>
        {/* ================= English Form ================= */}
        <div
          style={{ direction: 'ltr' }}
          className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4"
        >
          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">First Name</span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              size="md"
              name="firstName"
              type="text"
              placeholder="Aseel"
              onKeyPress={(e) => restrictLanguageInput(e, 'english')}
            />
            {formik.errors.firstName && formik.touched.firstName && (
              <small className="text-danger">This field is required!</small>
            )}
          </label>

          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">Father Name</span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fatherName}
              size="md"
              name="fatherName"
              type="text"
              placeholder="Mohamed"
              onKeyPress={(e) => restrictLanguageInput(e, 'english')}
            />
            {formik.errors.fatherName && formik.touched.fatherName && (
              <small className="text-danger">This field is required!</small>
            )}
          </label>

          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">Middle Name</span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.middleName}
              size="md"
              name="middleName"
              type="text"
              placeholder="Mahmoud"
              onKeyPress={(e) => restrictLanguageInput(e, 'english')}
            />
            {formik.errors.middleName && formik.touched.middleName && (
              <small className="text-danger">This field is required!</small>
            )}
          </label>

          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">Last Name</span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              size="md"
              name="lastName"
              type="text"
              placeholder="Milyani"
              onKeyPress={(e) => restrictLanguageInput(e, 'english')}
            />
            {formik.errors.lastName && formik.touched.lastName && (
              <small className="text-danger">This field is required!</small>
            )}
          </label>
        </div>

        {/* ================= Line ================= */}

        <div className="my-11 h-[1px] bg-gray-3" />
        {/* ================= Arabic Form ================= */}
        <div
          style={{ direction: 'rtl' }}
          className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4"
        >
          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">الإسم الأول</span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstNameAr}
              size="md"
              name="firstNameAr"
              type="text"
              placeholder="اصيل"
              onKeyPress={(e) => restrictLanguageInput(e, 'arabic')}
            />
            {formik.errors.firstNameAr && formik.touched.firstNameAr && (
              <small className="text-danger">هذا الحقل إلزامى!</small>
            )}
          </label>

          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">اسم الأب</span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fatherNameAr}
              size="md"
              name="fatherNameAr"
              type="text"
              placeholder="محمد"
              onKeyPress={(e) => restrictLanguageInput(e, 'arabic')}
            />
            {formik.errors.fatherNameAr && formik.touched.fatherNameAr && (
              <small className="text-danger">هذا الحقل إلزامى!</small>
            )}
          </label>

          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">اسم الجد</span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.middleNameAr}
              size="md"
              name="middleNameAr"
              type="text"
              placeholder="محمود"
              onKeyPress={(e) => restrictLanguageInput(e, 'arabic')}
            />
            {formik.errors.middleNameAr && formik.touched.middleNameAr && (
              <small className="text-danger">هذا الحقل إلزامى!</small>
            )}
          </label>

          <label className="mb-7 block">
            <span className="mb-1 block text-xl font-bold">الإسم الأخير</span>
            <AppInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastNameAr}
              size="md"
              name="lastNameAr"
              type="text"
              placeholder="ملياني"
              onKeyPress={(e) => restrictLanguageInput(e, 'arabic')}
            />
            {formik.errors.lastNameAr && formik.touched.lastNameAr && (
              <small className="text-danger">هذا الحقل إلزامى!</small>
            )}
          </label>
        </div>

        <div className="mb-6 flex flex-col items-center justify-end gap-6 lg:flex-row lg:gap-11">
          <AppButton
            button="link"
            className="order-2 text-center font-bold lg:order-1"
            onClick={() => {
              dispatch(
                setIdentityVerificationsStepAction(
                  employeeIdentityVerification.identityVerificationForm,
                ),
              );
            }}
            size="lg"
          >
            {t('Back')}
          </AppButton>

          <AppButton
            disabled={!formik.isValid}
            // isLoading={loaders.xxx}
            type="submit"
            size="lg"
            className="order-1 w-full lg:order-2 lg:w-44"
            button="primary"
            rounded="xl"
          >
            {t('Next', { ns: 'commonV2' })}
          </AppButton>
        </div>
      </form>

      {isDev() && (
        <DeveloperComponent>
          <pre>{JSON.stringify(formik.values, null, 4)}</pre>
        </DeveloperComponent>
      )}
    </div>
  );
}

export default EmployeeIdentityVerificationFullNameForm;
