import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as InfoIcon} from '../../../../assets/svg/info.svg';
import {ReactComponent as Plus} from '../../../../assets/svg/plus.svg';
import {ReactComponent as XIcon} from '../../../../assets/svg/x.svg';
import ActionButton from '../../../../components/Button/ActionButton';
import BackButton from '../../../../components/Button/BackButton';
import {NumberInput, SelectInputCustom, TextInput,} from '../../../../components/FormInputs/Inputs';
import LoadingError from '../../../../components/LoadingError';
import MultiColorProgressBar from '../../../../components/MultiColorProgressBar';
import {Details, Headline2, Input, LargerTitle2, Messages, SmallButton,} from '../../../../components/Titles';
import TooltipPackage from '../../../../components/TooltipPackage';
import DropzoneSimple from '../../../../components/Upload/DropzoneSimple';
import DropzoneSingleFileForm from '../../../../components/Upload/DropzoneSingleFileForm';
import {apiGetRequest, apiPostFormDataRequest} from '../../../../helpers/APIHelper';
import {POSTIVENUMBERPATTERN} from '../../../../helpers/RegexPatterns';
import {formatDate} from '../../../../helpers/Utils';
import useNotifications from '../../../../helpers/useNotifications';
import Head from '../../../Head';
import {AppBody} from "../../../../components/html/html";

function SplitTransaction({ isEdit }) {
  const { t, i18n } = useTranslation(['transactions', 'common']);
  const params = useParams();
  const [cookies] = useCookies(['token']);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    control,
    setValue,
    getValues,
    setError,
    resetField,
  } = useForm({ mode: 'onBlur' });
  const [, addNotification] = useNotifications();
  const navigate = useNavigate();

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disable] = useState(true);
  const [errored, setErrored] = useState(false);
  const [departments, setDepartments] = useState(null);
  const [tags, setTags] = useState(null);
  const [remaingAmount, setRemainingAmount] = useState(0);
  const [completeSplit, setCompleteSplit] = useState(false);
  const [completeSplitError, setCompleteSplitError] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [splits, setSplits] = useState([
    {
      id: 1,
      name: '',
      value: 0,
      color: '',
      amount: 0,
      isEdit: 0,
      department_id: 0,
      department_name: '',
      tag_id: 0,
      tag_name: '',
      reason: '',
      file: false,
    },
    {
      id: 2,
      name: '',
      value: 0,
      color: '',
      amount: 0,
      isEdit: 0,
      department_id: 0,
      department_name: '',
      tag_id: 0,
      tag_name: '',
      reason: '',
      file: false,
    },
  ]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const loadDepartment = () => {
    apiGetRequest('departments', cookies.token).then((response) => {
      setDepartments(response);
    });
  };

  const loadTags = () => {
    apiGetRequest('tags', cookies.token).then((data) => {
      setTags(data);
    });
  };

  const fetchTransaction = (transId) => {
    setIsLoading(true);
    apiGetRequest(`transactions/${transId}`, cookies.token)
      .then((response) => {
        setSelectedTransaction(response);
        if (isEdit && Array.isArray(response.splits) && response.splits.length > 0) {
          const splitsArray = [];
          const transactionAmount =
            response.transaction_amount >= 0
              ? parseFloat(response.transaction_amount)
              : parseFloat(response.transaction_amount * -1);
          response.splits.forEach((arrayItem) => {
            const splitAmount =
              arrayItem.transaction_amount >= 0
                ? parseFloat(arrayItem.transaction_amount)
                : parseFloat(arrayItem.transaction_amount * -1);

            splitsArray.push({
              id: arrayItem.id,
              name: arrayItem.tags?.name,
              value: (splitAmount / transactionAmount) * 100,
              color: arrayItem.tags?.color,
              amount: splitAmount,
              isEdit: 1,
              department_id: arrayItem.department_id?.id,
              department_name:
                i18n.language === 'ar'
                  ? arrayItem.department_id?.name_ar
                  : arrayItem.department_id?.name,
              tag_id: arrayItem.tags?.id,
              tag_name: i18n.language === 'ar' ? arrayItem.tags?.name_ar : arrayItem.tags?.name,
              reason: arrayItem.reason,
              file: true,
            });

            setValue(`transaction_amount_${arrayItem.id}`, splitAmount);
            setValue(`department_id_${arrayItem.id}`, arrayItem.department_id?.id);
            setValue(`reason_${arrayItem.id}`, arrayItem.reason);
            setValue(`tag_id_${arrayItem.id}`, arrayItem.tags?.id);
          });
          setSplits(splitsArray.sort((a, b) => a.id - b.id));
          setRemainingAmount(0);
          setCompleteSplit(true);
        }
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const addMore = () => {
    const splitsArray = [
      ...splits,
      {
        id: splits[splits.length - 1].id + 1,
        name: '',
        value: 0,
        color: '',
        amount: 0,
        isEdit: 0,
        department_id: 0,
        department_name: '',
        tag_id: 0,
        tag_name: '',
        reason: '',
        file: false,
      },
    ];
    setSplits(splitsArray);
  };

  const onSubmit = () => {
    let failed = false;
    setIsSubmitting(true);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < splits.length; i++) {
      if (
        document.getElementsByName(`receipt_${splits[i].id}`) &&
        document.getElementsByName(`receipt_${splits[i].id}`)[0].value === '' &&
        splits[i].isEdit === 0
      ) {
        failed = true;
        break;
      }
    }

    if (failed) {
      setSubmitFailed(true);
      setIsSubmitting(false);
      return false;
    }
    const form = new FormData();
    const splitArray = [];
    form.append('parent_transaction_id', selectedTransaction.id);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < splits.length; i++) {
      splitArray[i] = {
        transaction_amount: parseFloat(getValues(`transaction_amount_${splits[i].id}`)) * 1,
        department_id: getValues(`department_id_${splits[i].id}`),
        tag_id: getValues(`tag_id_${splits[i].id}`),
        reason: getValues(`reason_${splits[i].id}`),
      };
      form.append(`splits[${i}][transaction_amount]`, splitArray[i].transaction_amount);
      form.append(`splits[${i}][department_id]`, splitArray[i].department_id);
      form.append(`splits[${i}][tag_id]`, splitArray[i].tag_id);
      form.append(`splits[${i}][reason]`, splitArray[i].reason);
      if (
        document.getElementsByName(`receipt_${splits[i].id}`).length > 0 &&
        document.getElementsByName(`receipt_${splits[i].id}`)[0].value !== ''
      ) {
        form.append(
          `splits[${i}][receipt]`,
          document.getElementsByName(`receipt_${splits[i].id}`)[0].files[0],
        );
      }

      if (isEdit && splits[i].isEdit) {
        form.append(`splits[${i}][id]`, splits[i].id);
      }
    }

    apiPostFormDataRequest(
      `transactions/split${isEdit ? `/${selectedTransaction.id}` : ''}`,
      form,
      cookies.token,
    )
      .then(() => {
        addNotification(t('split_done'));
        navigate(-1);
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    return true;
  };

  useEffect(() => {
    loadDepartment();
    loadTags();
    clearErrors();
  }, [i18n.language]);

  useEffect(() => {
    if (params.id) {
      fetchTransaction(parseInt(params.id, 10));
    }
  }, [params.id, i18n.language]);

  useEffect(() => {
    if (selectedTransaction && !isEdit) {
      setRemainingAmount(
        selectedTransaction.transaction_amount >= 0
          ? parseFloat(selectedTransaction.transaction_amount).toFixed(2)
          : parseFloat(selectedTransaction.transaction_amount * -1).toFixed(2),
      );
    }
  }, [selectedTransaction]);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  if (!selectedTransaction || !tags || !departments) {
    return (
      <div className="mx-auto flex w-full flex-col lg:w-10/12">
        <Head title="Split Transaction" />
        <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
          <div className="col-span-6 flex flex-col justify-start">
            {isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchTransaction} />
          </div>
        </div>
      </div>
    );
  }

  const amount =
    selectedTransaction.transaction_amount >= 0
      ? parseFloat(selectedTransaction.transaction_amount)
      : parseFloat(selectedTransaction.transaction_amount * -1);
  const currency = selectedTransaction.transaction_currency;
  return (
    <div className="mx-auto flex w-full flex-col lg:w-10/12">
      <div className="flex w-full flex-row items-center justify-between lg:w-2/3">
        <BackButton />
      </div>

      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col justify-start text-left">
            <LargerTitle2>{t('split_transaction')}</LargerTitle2>
          </div>
        </div>

        <div className="flex flex-col justify-between self-stretch border-b-4 border-gray-2 border-opacity-40 px-2 py-5 lg:px-10">
          <MainTransactionInfo selectedTransaction={selectedTransaction} />

          <div className="my-2">
            {!completeSplitError && !completeSplit && (
              <Details className="mb-2 text-center text-purple-600">
                {t('remaining_amount')} {remaingAmount} {currency}
              </Details>
            )}
            {completeSplitError && !completeSplit && (
              <AppBody pClass="Body2Regular" className="mb-2 text-red-700 ltr:text-right rtl:text-left">
                {t('reach')} <AppBody pClass="Body1Bold" type="span">{t('max_amount')}</AppBody>
              </AppBody>
            )}
            {!completeSplitError && completeSplit && (
              <h2 className="mb-2 text-sm font-normal text-green-600 ltr:text-right rtl:text-left">
                {t('you_just')} <AppBody pClass="Body1Bold" type="span">{t('split_all')}</AppBody>
              </h2>
            )}
            {splits && (
              <MultiColorProgressBar
                completeSplit={completeSplit}
                completeSplitError={completeSplitError}
                readings={splits}
              />
            )}
            <p className="text-gray-6 ltr:text-left rtl:text-right">
              <InfoIcon className="inline" width="15" />
              <Input className="inline px-2">{t('split_hint')}</Input>
            </p>
          </div>

          <div className="my-2">
            <div className="my-2 hidden w-full grid-cols-8 gap-3 lg:grid">
              <div className="col-span-8 lg:col-span-2">
                <SmallButton className="block">{t('department_input')}</SmallButton>
              </div>
              <div className="col-span-8 lg:col-span-1">
                <SmallButton className="block">{t('amount')}</SmallButton>
              </div>
              <div className="col-span-8 lg:col-span-2">
                <SmallButton className="block">{t('tag')}</SmallButton>
              </div>
              <div className="col-span-8 m-auto lg:col-span-1">
                <SmallButton className="block">{t('upload')}</SmallButton>
                <SmallButton className="block">{t('receipt')}</SmallButton>
              </div>
              <div className="col-span-8 lg:col-span-2">
                <SmallButton className="block">
                  {t('description')}{' '}
                  <AppBody pClass="Caption1Regular" type="span" className="contents text-gray-6">
                    {` (${t('optional', { ns: 'common' })})`}
                  </AppBody>
                </SmallButton>
              </div>
            </div>
            <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
              {splits &&
                Array.isArray(splits) &&
                splits.map((split, key) => (
                  <SplitForm
                    splitTransaction={
                      isEdit
                        ? selectedTransaction.splits.filter((elm) => elm.id === split.id)
                        : null
                    }
                    splitKey={key}
                    control={control}
                    resetField={resetField}
                    setError={setError}
                    setValue={setValue}
                    errors={errors}
                    register={register}
                    clearErrors={clearErrors}
                    splits={splits}
                    setSplits={setSplits}
                    splitId={split.id}
                    tags={tags}
                    departments={departments}
                    transactionAmount={amount}
                    setRemainingAmount={setRemainingAmount}
                    setCompleteSplit={setCompleteSplit}
                    setCompleteSplitError={setCompleteSplitError}
                    submitFailed={submitFailed}
                    setSubmitFailed={setSubmitFailed}
                    isMobile={isMobile}
                  />
                ))}

              <div className="mt-5 flex flex-row justify-between self-stretch pt-2">
                <button
                  disabled={completeSplit || splits.length > 9 ? disable : ''}
                  onClick={addMore}
                  className="flex flex-row items-center justify-start"
                  // eslint-disable-next-line react/button-has-type
                  type="button"
                >
                  <AppBody pClass="Body1Bold" type="div" className="inline-block h-8 w-8 rounded-full bg-primary text-white">
                    <Plus className="m-auto pt-2" />
                  </AppBody>
                  <Input className="mx-2 inline-block text-gray-6">{t('add_more')}</Input>
                </button>

                <ActionButton
                  disabled={completeSplit ? '' : disable}
                  className="flex flex-row items-center justify-end"
                  isLoading={isSubmitting}
                  primary
                  text={t('save', { ns: 'common' })}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function SplitForm({
  splitId,
  splits,
  setSplits,
  tags,
  departments,
  register,
  control,
  resetField,
  clearErrors,
  errors,
  setValue,
  setError,
  transactionAmount,
  setCompleteSplit,
  setRemainingAmount,
  setCompleteSplitError,
  submitFailed,
  setSubmitFailed,
  splitTransaction,
  splitKey,
  isMobile,
}) {
  const { t, i18n } = useTranslation(['transactions', 'common']);
  const [files, setFiles] = useState(null);
  const [filesError, setFilesError] = useState(null);

  const updateAmount = (updatedSplits, id = null) => {
    let i;
    let sumAllAmount = 0;
    let sumAllValues = 0;
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < updatedSplits.length; i++) {
      sumAllAmount += parseFloat(updatedSplits[i].amount);
    }
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < updatedSplits.length; i++) {
      // eslint-disable-next-line no-unused-vars
      sumAllValues += updatedSplits[i].value;
    }

    if (sumAllAmount === transactionAmount) {
      setCompleteSplitError(false);
      setCompleteSplit(true);
    } else if (sumAllAmount > transactionAmount) {
      setCompleteSplitError(true);
      setCompleteSplit(false);
      if (id !== null) {
        setError(`transaction_amount_${id}`, {
          type: 'manual',
          message: t('more_allowed'),
          shouldFocus: true,
        });
      }
    } else {
      setCompleteSplit(false);
      setCompleteSplitError(false);
    }

    setRemainingAmount(parseFloat(transactionAmount - sumAllAmount).toFixed(2));
  };

  const updateItem = (id, itemAttributes) => {
    clearErrors();
    const index = splits.findIndex((x) => x.id === id);
    if (index !== -1) {
      const updatedSplits = [
        ...splits.slice(0, index),
        { ...splits[index], ...itemAttributes },
        ...splits.slice(index + 1),
      ];

      updateAmount(updatedSplits, id);

      setSplits(updatedSplits);
    }
  };

  const onChangeTag = (changeSplitId, value) => {
    const index = splits.findIndex((sp) => sp.id === changeSplitId);
    if (index !== -1) {
      // eslint-disable-next-line no-param-reassign
      const tagValue = value;
      if (tagValue !== '') {
        const selectedTag = tags.findIndex((tag) => tag.id === tagValue);
        const newSplits = splits[index];
        // eslint-disable-next-line no-param-reassign
        newSplits.color = selectedTag !== -1 ? tags[selectedTag].color : '';
        // eslint-disable-next-line no-param-reassign
        newSplits.name = selectedTag !== -1 ? tags[selectedTag].name : '';
        // eslint-disable-next-line no-param-reassign
        newSplits.tag_id = selectedTag !== -1 ? tags[selectedTag].id : '';
        if (i18n.language === 'ar') {
          // eslint-disable-next-line no-param-reassign
          newSplits.tag_name = selectedTag !== -1 ? tags[selectedTag].name_ar : '';
        } else {
          // eslint-disable-next-line no-param-reassign
          newSplits.tag_name = selectedTag !== -1 ? tags[selectedTag].name : '';
        }
        updateItem(newSplits.id, newSplits);
      }
    }
  };

  const onChangeDepartment = (changeSplitId, value) => {
    const index = splits.findIndex((sp) => sp.id === changeSplitId);
    if (index !== -1) {
      // eslint-disable-next-line no-param-reassign
      const deptValue = value;
      if (deptValue !== '') {
        const selectedDept = departments.findIndex((dept) => dept.id === deptValue);
        const newSplits = splits[index];
        // eslint-disable-next-line no-param-reassign
        newSplits.department_id = selectedDept !== -1 ? departments[selectedDept].id : '';
        if (i18n.language === 'ar') {
          // eslint-disable-next-line no-param-reassign
          newSplits.department_name = selectedDept !== -1 ? departments[selectedDept].name_ar : '';
        } else {
          // eslint-disable-next-line no-param-reassign
          newSplits.department_name = selectedDept !== -1 ? departments[selectedDept].name : '';
        }

        updateItem(newSplits.id, newSplits);
      }
    }
  };

  const onChangeAmount = (changeSplitId, value) => {
    const index = splits.findIndex((sp) => sp.id === changeSplitId);
    if (index !== -1) {
      if (transactionAmount !== 0) {
        const newSplits = splits[index];
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(parseFloat(value))) {
          // eslint-disable-next-line no-param-reassign
          newSplits.value = 0;
          newSplits.amount = 0;
        } else if (parseFloat(value) < 0) {
          newSplits.value = 0;
          newSplits.amount = 0;
        } else {
          // eslint-disable-next-line no-param-reassign
          newSplits.value = (value / transactionAmount) * 100;
          newSplits.amount = value;
        }

        updateItem(newSplits.id, newSplits);
      }
    }
  };

  const removeOne = (splitIdVal) => {
    clearErrors();
    resetField(`department_id_${splitIdVal}`);
    resetField(`transaction_amount_${splitIdVal}`);
    resetField(`tag_id_${splitIdVal}`);
    resetField(`reason_${splitIdVal}`);
    setFiles(null);
    setFilesError(null);

    const updatedSplits = splits.filter((obj) => obj.id !== splitIdVal);
    updateAmount(updatedSplits);
    setSplits(updatedSplits);
  };

  const selectedKey = splitKey;
  const selectedDept =
    splits[selectedKey].department_id === 0
      ? null
      : { value: splits[selectedKey]?.department_id, label: splits[selectedKey]?.department_name };
  const selectedTag =
    splits[selectedKey].tag_id === 0
      ? null
      : { value: splits[selectedKey]?.tag_id, label: splits[selectedKey]?.tag_name };

  useEffect(() => {
    if (files) {
      setSubmitFailed(false);
      const newSplits = splits[selectedKey];
      newSplits.file = true;
      updateItem(newSplits.id, newSplits);
    }
  }, [files]);

  return (
    <div className="relative my-2 grid w-full grid-cols-8 gap-3" key={`split_trans_${splitId}`}>
      <div className="col-span-8 mt-3 lg:col-span-2 lg:hidden">
        <Headline2>
          {t('split_counter')} {splitKey + 1}
        </Headline2>
      </div>
      <div className="col-span-8 lg:col-span-2">
        <label htmlFor={`department_id_${splitId}`} className="ltr:text-left rtl:text-right">
          <AppBody type="span" pClass="Body2Bold" className="block lg:hidden ltr:text-left rtl:text-right">
            {t('department_input')}{' '}
          </AppBody>
          {departments.length > 0 && (
            <SelectInputCustom
              defaultValue={selectedDept}
              onChange={(e) => {
                onChangeDepartment(splitId, e);
                setValue(`department_id_${splitId}`, e);
              }}
              error={errors[`department_id_${splitId}`]}
              name={`department_id_${splitId}`}
              id={`department_id_${splitId}`}
              control={control}
              rules={{
                required: t('department_required'),
              }}
              options={
                i18n.language === 'ar'
                  ? departments.map(({ id: value, name_ar: label, ...rest }) => ({
                      value,
                      label,
                      ...rest,
                    }))
                  : departments.map(({ id: value, name: label, ...rest }) => ({
                      value,
                      label,
                      ...rest,
                    }))
              }
            />
          )}
          {departments.length === 0 && (
            <span className="pt-5 font-normal">{t('add.inputs.loading')}</span>
          )}
        </label>
      </div>

      <div className="col-span-8 lg:col-span-1">
        <label htmlFor={`transaction_amount_${splitId}`} className="ltr:text-left rtl:text-right">
          <AppBody type="span" pClass="Body2Bold" className="block lg:hidden ltr:text-left rtl:text-right">
            {t('amount')}{' '}
          </AppBody>
          <NumberInput
            defaultValue={splits[selectedKey].amount ?? 0}
            small="true"
            onInput={(e) => {
              onChangeAmount(splitId, e.currentTarget.value);
            }}
            error={errors[`transaction_amount_${splitId}`]}
            name={`transaction_amount_${splitId}`}
            id={`transaction_amount_${splitId}`}
            {...register(`transaction_amount_${splitId}`, {
              required: t('amount_required'),
              pattern: {
                value: POSTIVENUMBERPATTERN,
                message: t('amount_minus_error'),
              },
            })}
          />
        </label>
      </div>

      <div className="col-span-8 lg:col-span-2">
        <label htmlFor={`tag_id_${splitId}`} className="ltr:text-left rtl:text-right">
          <AppBody type="span" pClass="Body2Bold" className="block lg:hidden ltr:text-left rtl:text-right">
            {t('tag')}{' '}
          </AppBody>
          {tags.length > 0 && (
            <SelectInputCustom
              defaultValue={selectedTag}
              onChange={(e) => {
                onChangeTag(splitId, e);
                setValue(`tag_id_${splitId}`, e);
              }}
              error={errors[`tag_id_${splitId}`]}
              name={`tag_id_${splitId}`}
              id={`tag_id_${splitId}`}
              control={control}
              rules={{
                required: t('tag_required'),
              }}
              options={
                i18n.language === 'ar'
                  ? tags.map(({ id: value, name_ar: label, ...rest }) => ({
                      value,
                      label,
                      ...rest,
                    }))
                  : tags.map(({ id: value, name: label, ...rest }) => ({
                      value,
                      label,
                      ...rest,
                    }))
              }
            />
          )}
          {tags.length === 0 && <span className="pt-5 font-normal">{t('add.inputs.loading')}</span>}
        </label>
      </div>

      <div className="col-span-8 lg:col-span-1">
        <div className="m-auto flex flex-col pt-2">
          <AppBody type="span" pClass="Body2Bold" className="mb-1 block lg:hidden ltr:text-left rtl:text-right">
            {t('upload')} {t('receipt')}{' '}
          </AppBody>

          {!isMobile && (
            <DropzoneSimple
              oldFile={splits[selectedKey]?.file}
              name={`receipt_${splitId}`}
              setFileError={setFilesError}
              setFile={setFiles}
              fileError={filesError}
              file={files}
              rejectReason={t('rejectReason', { ns: 'common' })}
              uploadInput={t('documents_or_photos', { ns: 'common' })}
              acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
            />
          )}

          {isMobile && (
            <div className="relative flex flex-row items-center">
              <DropzoneSingleFileForm
                oldValue={
                  splits[selectedKey]?.file
                    ? splitTransaction.length === 1 && splitTransaction[0].receipts
                    : null
                }
                name={`receipt_${splitId}`}
                setFileError={setFilesError}
                setFile={setFiles}
                fileError={filesError}
                file={files ?? []}
                rejectReason={t('rejectReason', { ns: 'common' })}
                uploadInput={t('documents_or_photos', { ns: 'common' })}
                acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
              />
            </div>
          )}
          {submitFailed && !splits[selectedKey]?.file && (
            <AppBody pClass="Caption1Regular" className="pt-2 text-center text-red-700">{t('receipt_required')}</AppBody>
          )}
        </div>
      </div>

      <div className="col-span-8 lg:col-span-2">
        <label htmlFor={`reason_${splitId}`} className="ltr:text-left rtl:text-right">
          <AppBody type="span" pClass="Body2Bold" className="block lg:hidden ltr:text-left rtl:text-right">
            {t('description')}{' '}
          </AppBody>
          <TextInput
            maxLength="150"
            defaultValue={splits[selectedKey].reason ?? ''}
            error={errors[`reason_${splitId}`]}
            name={`reason_${splitId}`}
            id={`reason_${splitId}`}
            {...register(`reason_${splitId}`)}
            className={`${
              errors[`reason_${splitId}`] ? 'border-red-400' : 'border-gray-3'
            } mt-1 rounded-full border  px-10 py-1`}
          />
        </label>
      </div>
      {splitId > 2 && !splitTransaction && (
        <div className="absolute top-2 ltr:-right-4 rtl:-left-4">
          <button type="button" onClick={() => removeOne(splitId)}>
            <XIcon />
          </button>{' '}
        </div>
      )}
      {splitTransaction && splitKey >= 2 && (
        <div className="absolute top-2 ltr:-right-4 rtl:-left-4">
          <button type="button" onClick={() => removeOne(splitId)}>
            <XIcon />
          </button>{' '}
        </div>
      )}
    </div>
  );
}

function MainTransactionInfo({ selectedTransaction }) {
  const { t, i18n } = useTranslation(['transactions', 'common']);

  return (
    <>
      <div className="my-1 bg-gray-1 p-2 lg:p-5">
        <div className="flex flex-row justify-between text-left">
          <Headline2>{selectedTransaction.transaction_reference}</Headline2>
          <Messages>
            {i18n.language === 'ar'
              ? selectedTransaction.transaction_type_ar
              : selectedTransaction.transaction_type.toUpperCase()}
          </Messages>
        </div>
      </div>
      <div className="my-1 bg-gray-1 p-2 lg:p-5">
        <div className="my-2 grid w-full grid-cols-2 gap-x-3 gap-y-5 lg:grid-cols-4">
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">{t('name')}</Details>
            <SmallButton className="block">
              {i18n.language !== 'ar' && selectedTransaction.user_id
                ? `${selectedTransaction.user_id.first_name} ${selectedTransaction.user_id.last_name}`
                : ''}
              {i18n.language === 'ar' && selectedTransaction.user_id
                ? `${selectedTransaction.user_id.first_name_ar} ${selectedTransaction.user_id.last_name_ar}`
                : ''}
            </SmallButton>
          </div>
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">{t('date')}</Details>
            <SmallButton className="block">
              {formatDate(selectedTransaction.transaction_timestamp, 'DD / MMM / YYYY')}
            </SmallButton>
          </div>
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">{t('time')}</Details>
            <SmallButton className="block">
              {formatDate(selectedTransaction.transaction_timestamp, 'HH:mm')}
            </SmallButton>
          </div>
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">{t('card_details')}</Details>
            <SmallButton className="block">{`${selectedTransaction.network}**${selectedTransaction.card_last4}`}</SmallButton>
          </div>

          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">{t('merchant')}</Details>
            <SmallButton className="block">{selectedTransaction.merchant_name}</SmallButton>
          </div>
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">{t('country')}</Details>
            <SmallButton className="block">{selectedTransaction.merchant_country}</SmallButton>
          </div>
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">{t('city')}</Details>
            <SmallButton className="block">{selectedTransaction.merchant_city}</SmallButton>
          </div>
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">{t('category')}</Details>
            <SmallButton className="block">
              {i18n.language === 'ar'
                ? selectedTransaction.mcc?.name_ar
                : selectedTransaction.mcc?.name_en}
            </SmallButton>
          </div>
        </div>
      </div>
      <div className="my-1 bg-gray-1 p-2 lg:p-5">
        <div className="my-2 grid w-full grid-cols-2 gap-x-3 gap-y-5 lg:grid-cols-4">
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">
              {t('transaction_amount')}
              <TooltipPackage tooltipMessage={t('amount_hint')} id="infoPreview" />
            </Details>
            <SmallButton className="block" style={{ direction: 'ltr' }}>
              {selectedTransaction.transaction_amount >= 0
                ? `${parseFloat(selectedTransaction.transaction_amount).toLocaleString()} ${
                    selectedTransaction.transaction_currency
                  }`
                : `(${parseFloat(selectedTransaction.transaction_amount * -1).toLocaleString()}) ${
                    selectedTransaction.transaction_currency
                  }`}
            </SmallButton>
          </div>
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">
              {t('billing_amount')}
              <TooltipPackage tooltipMessage={t('billing_hint')} id="infoPreview1" />
            </Details>
            <SmallButton className="block" style={{ direction: 'ltr' }}>
              {selectedTransaction.billing_amount >= 0
                ? `${parseFloat(selectedTransaction.billing_amount).toLocaleString()} ${
                    selectedTransaction.billing_currency
                  }`
                : `(${parseFloat(selectedTransaction.billing_amount * -1).toLocaleString()}) ${
                    selectedTransaction.billing_currency
                  }`}
            </SmallButton>
          </div>
          <div className="col-span-1 lg:col-span-1">
            <Details className="block text-gray-6">
              {t('fx_fees')}
              <TooltipPackage tooltipMessage={t('fx_hint')} id="infoPreview2" />
            </Details>
            <SmallButton className="block" style={{ direction: 'ltr' }}>
              {selectedTransaction.fx_fee}
            </SmallButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default SplitTransaction;
