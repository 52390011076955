import { randomId } from 'javascript-functions';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppSpinner from '../../components/AppSpinner/index';
import OTPInput from '../../components/FormInputs/OTPInput';
import OTPResendTimer from '../../components/FormInputs/OTPResendTimer';
import { AppBody, AppHeader } from '../../components/html/html';
import LTRForce from '../../components/LTRForce/index';
import { uploadDocumentSteps } from '../../helpers/constants';
import { getServerErrMessage, isDev } from '../../helpers/Utils';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import {
  saveDocumentCardsAction,
  saveDocumentsOTPDataAction,
  setActiveDocumentStepAction,
} from '../../redux/UploadDocumentsReducer/UploadDocumentsActions';

function DocumentsOTP() {
  const { t } = useTranslation('commonV2');
  const { loaders } = useSelector((state) => state);
  const [OTPInputKey, setOTPInputKey] = useState(randomId());
  const [linkNotFound, setLinkNotFound] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const { token, accountId } = useParams();
  const dispatch = useDispatch();

  const getUserInfo = useCallback(() => {
    setOTPInputKey(randomId());
    http
      .get('documents/link/open', {
        loader: 'getUserInfo',
        params: {
          account_id: accountId,
          link_token: token,
        },
      })
      .then((res) => {
        setUserInfo(res.otp);
      })
      .catch(() => {
        setLinkNotFound(true);
      });
  }, [accountId, token]);

  const verifyOTP = useCallback(
    (code) => {
      const params = {
        account_id: accountId,
        link_token: token,
        verification_token: userInfo.token,
        verification_code: code,
      };
      http
        .get(`documents/link/open`, {
          loader: 'verifyOTP',
          params,
        })
        .then((res) => {
          dispatch(saveDocumentCardsAction(res.data));
          dispatch(saveDocumentsOTPDataAction(params));
          dispatch(setActiveDocumentStepAction(uploadDocumentSteps.documentList));
        })
        .catch((err) => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: getServerErrMessage(err),
            }),
          );
        });
    },
    [userInfo],
  );

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="flex h-full items-center justify-center">
      <div className="max-w-[400px]">
        {loaders.getUserInfo && !linkNotFound && (
          <div className="h-full">
            <AppSpinner />
          </div>
        )}

        {linkNotFound && (
          <div className="m-auto flex w-full max-w-sm flex-col text-center lg:w-7/12">
            <img alt="not found" src="/images/NotFound.png" className="m-auto max-w-xs" />
          </div>
        )}

        {!loaders.getUserInfo && !linkNotFound && (
          <div>
            <AppHeader h="h3">{t('One - Time Password')}</AppHeader>

            <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
              <span className="me-1">{t('Please enter the verification code we sent to')}</span>
              <span className="block text-start">
                <span>{t('Mobile')} </span>
                <span className="font-bold">
                  <LTRForce>{userInfo?.mobile}</LTRForce>
                </span>
              </span>
              <span>
                <span className="font-bold">{t('OR')} </span>
                <span className="block font-bold">{userInfo?.email}</span>
              </span>
            </AppBody>

            <AppHeader className="mb-3" h="h5">
              {t('Enter The Code')}
            </AppHeader>

            <div className="mb-6">
              <OTPInput
                key={OTPInputKey}
                isLoading={loaders.verifyOTP}
                count={5}
                onComplete={(code) => {
                  verifyOTP(code);
                }}
              />
            </div>

            <div className="flex items-center gap-2">
              <AppBody pClass="Body2Medium" className="text-gray-6">
                {t("Didn't receive the one time code?")}
              </AppBody>
              <OTPResendTimer
                duration={isDev() ? 2 : 180}
                onResend={() => {
                  getUserInfo();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DocumentsOTP;
