import axios from 'axios';
import { status } from 'constants-js';
import { history } from '../AppRouter/history';
import { showToasterAction } from '../redux/AppToastersReducer/AppToastersActions';
import store from '../redux/store';
import { devLog, isDev } from './Utils';

const apiBase = process.env.REACT_APP_API_BASE;

const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    const c = ca[i].replace(' ', '');
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
const axiosInstance = axios.create({
  baseURL: apiBase,
  timeout: 150000, // 50000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const axiosInstanceDownload = axios.create({
  baseURL: apiBase,
  timeout: 150000,
  responseType: 'blob',
});

const handleAPIResponseDownload = (req) =>
  req
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }

      return null;
    })
    .catch((err) => Promise.reject(err.response));

const handleAPIResponse = (req) =>
  req
    .then((response) => {
      if (response.status >= 200 && response.status < 300 && response.data) {
        return response.data.data;
      }

      return null;
    })
    .catch((err) => {
      if (err.response.status === status.unauthorized) {
        devLog('[Unauthenticated!], Redirecting user to Login Page...');
        if (isDev()) {
          store.dispatch(
            showToasterAction({
              type: 'error',
              message: '[Unauthenticated!], Redirecting user to Login Page...',
            }),
          );
        }
        history.push('/auth/login');
        return Promise.resolve(err.response);
      }

      return Promise.reject(err.response);
    });

const handlePaginatedAPIResponse = (req) =>
  req
    .then((response) => {
      if (response.status === 200 && response.data) {
        return [response.data.data, response.data.paginator];
      }

      return null;
    })
    .catch((err) => {
      if (err.response.status === status.unauthorized) {
        devLog('[Unauthenticated!], Redirecting user to Login Page...');
        if (isDev()) {
          store.dispatch(
            showToasterAction({
              type: 'error',
              message: '[Unauthenticated!], Redirecting user to Login Page...',
            }),
          );
        }
        history.push('/auth/logout');
        return Promise.resolve(err.response);
      }

      return Promise.reject(err.response);
    });

export const apiPatchRequest = (path, body, token = null, headers = null) => {
  const params = new URLSearchParams(body);
  return handleAPIResponse(
    axiosInstance.patch(path, params.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': getCookie('lang') || 'en',
        ...headers,
      },
    }),
  );
};

export const apiPostRequest = (
  path,
  body,
  token = null,
  headers = {},
  isJson = false,
  method = 'post',
) => {
  let params = null;
  if (isJson === true) {
    params = JSON.stringify(body);
  } else {
    params = new URLSearchParams(body).toString();
  }

  return handleAPIResponse(
    axiosInstance[method](path, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': getCookie('lang') || 'en',
        ...headers,
      },
    }),
  );
};

export const apiDeleteRequest = (path, params = {}, token = null, headers = {}) => {
  const urlParams = new URLSearchParams(params);
  return handleAPIResponse(
    axiosInstance.delete(`${path}/?${urlParams.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': getCookie('lang') || 'en',
        ...headers,
      },
    }),
  );
};

export const apiPostFormDataRequest = (path, formData, token, headers = {}, method = 'post') =>
  handleAPIResponse(
    axiosInstance[method](path, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': getCookie('lang') || 'en',
        ...headers,
      },
    }),
  );

export const apiGetRequest = (
  path,
  token = null,
  params = {},
  withParams = [],
  headers = {},
  paginated = false,
  cancelToken = null,
) => {
  let handler = handleAPIResponse;
  if (paginated) {
    handler = handlePaginatedAPIResponse;
  }
  const mappedWithParams = withParams
    .map((param, index) => ({ [`with[${index}]`]: `${param}` }))
    .reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        ...currentValue,
      }),
      {},
    );
  const urlParams = new URLSearchParams({
    ...mappedWithParams,
    ...params,
  });

  return handler(
    axiosInstance.get(`${path}/?${urlParams.toString()}`, {
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': getCookie('lang') || 'en',
        ...headers,
      },
    }),
  );
};

export const apiGetRequestDownload = (path, token = null, params = {}) =>
  handleAPIResponseDownload(
    axiosInstanceDownload.get(`${path}/?${new URLSearchParams(params).toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );

export const getCancelToken = () => axios.CancelToken.source();
