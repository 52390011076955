/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import DatePicker from 'react-multi-date-picker';
import {useSelector} from 'react-redux';
// eslint-disable-next-line camelcase,import/no-extraneous-dependencies
import arabic_en from 'react-date-object/locales/arabic_en';
// eslint-disable-next-line camelcase,import/no-extraneous-dependencies
import arabic from 'react-date-object/calendars/arabic';
// eslint-disable-next-line camelcase,import/no-extraneous-dependencies
import gregorian from 'react-date-object/calendars/gregorian';
// eslint-disable-next-line camelcase,import/no-extraneous-dependencies
import moment from 'moment-hijri';
import 'moment/locale/en-gb';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import {useNavigate} from 'react-router-dom';
import InfoBlue from '../../../assets/png/info_blue.png';
import {ReactComponent as Correct} from '../../../assets/svg/correct.svg';
import ActionButton from '../../../components/Button/ActionButton';
import {TextInput} from '../../../components/FormInputs/Inputs';
import LoadingError from '../../../components/LoadingError';
import FileLottie from '../../../components/Lottie/FileLottie';
import ProgressLottie from '../../../components/Lottie/ProgressLottie';
import SuccessLottie from '../../../components/Lottie/SuccessLottie';
import ProgressBar from '../../../components/ProgressBar';
import {HeadlineCenterAlways} from '../../../components/Titles';
import TooltipPackage from '../../../components/TooltipPackage';
import Dropzone from '../../../components/Upload/Dropzone';
import {apiGetRequest, apiPostFormDataRequest} from '../../../helpers/APIHelper';
import {IDNUMBERPATTERN, IQAMANUMBERPATTERN, PASSPORTPATTERN,} from '../../../helpers/RegexPatterns';
import useNotifications from '../../../helpers/useNotifications';
import Head from '../../Head';
import {AppBody} from "../../../components/html/html";

function EditExpireDocuments({ stepNumber }) {
  const { user } = useSelector((state) => state.auth);
  const [cookies] = useCookies(['token']);
  const { t, i18n } = useTranslation(['expire', 'common']);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [, addNotification] = useNotifications();
  const [step, setStep] = useState(stepNumber);
  const [expireDocuments, setExpireDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [calendarType, setCalendarType] = useState(gregorian);
  const [calendarLocaleType, setCalendarLocaleType] = useState(gregorian_en);
  const [IDRegex, setIDRegex] = useState(IDNUMBERPATTERN);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [finish, setFinish] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [percent, setPercent] = useState(0);
  const navigate = useNavigate();

  const fetchExpireDocuments = (userId) => {
    apiGetRequest(
      'expire',
      cookies.token,
      {
        paginate: 100,
        page: 0,
        user_id: userId,
      },
      [],
      {},
      true,
    )
      .then(([response, pagination]) => {
        setExpireDocument(response);
        if (response.length === 0) {
          setFinish(true);
        }
        setErrored(false);
        setIsLoading(false);
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      fetchExpireDocuments(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (
      user &&
      expireDocuments &&
      expireDocuments[step]?.document.document_type?.edit_expire_column_json &&
      Array.isArray(expireDocuments[step]?.document.document_type?.edit_expire_column_json) &&
      expireDocuments[step]?.document.document_type?.edit_expire_column_json.length &&
      expireDocuments[step]?.document.document_type?.edit_expire_column_json.includes('id_number')
    ) {
      if (user.id_type === 'nid') {
        setCalendarType(arabic);
        setCalendarLocaleType(arabic_en);
        setIDRegex(IDNUMBERPATTERN);
      } else if (user.id_type === 'passport') {
        setCalendarType(gregorian);
        setCalendarLocaleType(gregorian_en);
        setIDRegex(PASSPORTPATTERN);
      } else {
        setCalendarType(gregorian);
        setCalendarLocaleType(gregorian_en);
        setIDRegex(IQAMANUMBERPATTERN);
      }
    }
  }, [user, expireDocuments]);

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const onSubmit = (data) => {
    let submitFailed = false;
    if (expireDate === null || expireDate === '') {
      setDateError(true);
      submitFailed = true;
    }

    if (!files || (files && files.length === 0)) {
      setFileError(
        t('upload_your_required').replaceAll(
          '{{fileName}}',
          expireDocuments[step]?.document.document_type?.name,
        ),
      );
      submitFailed = true;
    }

    if (!submitFailed) {
      const form = new FormData();

      if (files) {
        files.forEach((file) => {
          form.append('new_document_file', file);
        });
      }
      form.append(
        'new_expiry_date',
        moment(new Date(expireDate)).locale('en').format('YYYY-MM-DD'),
      );

      const newExpireRelatedJSON = [];
      if (
        expireDocuments &&
        expireDocuments[step]?.document.document_type?.edit_expire_column_json &&
        Array.isArray(expireDocuments[step]?.document.document_type?.edit_expire_column_json) &&
        expireDocuments[step]?.document.document_type?.edit_expire_column_json.length
      ) {
        expireDocuments[step]?.document.document_type?.edit_expire_column_json.forEach((item) => {
          newExpireRelatedJSON[item] = data[item];
        });

        form.append('new_expire_related_json', JSON.stringify({ ...newExpireRelatedJSON }));
      }

      form.append('_method', 'PUT');

      setIsSubmitting(true);
      apiPostFormDataRequest(`expire/${expireDocuments[step].id}`, form, cookies.token)
        .then(async (res) => {
          if (Number(step) < expireDocuments.length - 1) {
            setFiles([]);
            setFileError(null);
            setStep(Number(step) + 1);
            setExpireDate(null);
            setDateError(false);
            setCalendarType(gregorian);
            setCalendarLocaleType(gregorian_en);
          } else {
            setFinish(true);
            setProgressBar(true);
            setPercent(0.5);
            await sleep(1000).then(() => {
              setPercent(0.75);
            });
            setPercent(1);
            await sleep(2000).then(() => {
              setProgressBar(false);
            });
          }
        })
        .catch((err) => {
          addNotification(
            (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
            false,
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
    return false;
  };

  if (!expireDocuments) {
    return (
      <div className="mx-auto flex w-full flex-col lg:w-10/12">
        <Head title="Expire Documents" />
        <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
          <div className="col-span-6 flex flex-col justify-start">
            {isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchExpireDocuments} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto flex w-full flex-col lg:w-10/12">
      <Head title="Expire Documents" />
      {finish && (
        <div className="mb-3 flex flex-grow flex-col items-stretch">
          <div className="flex w-full flex-row rounded-xl bg-blue-50 px-4 py-4 text-cyan-600 lg:py-2">
            <span className="ltr:pl-2 rtl:pr-2">
              <img alt="info icon" src={InfoBlue} className="inline-block px-0.5" />
              {t('added')}
            </span>
          </div>
        </div>
      )}
      {expireDocuments && Array.isArray(expireDocuments) && expireDocuments.length > 1 && (
        <DocumentsStepBar step={step} expireList={expireDocuments} />
      )}

      {!finish && (
        <HeadlineCenterAlways>
          {t('upload_update')}
          {i18n.language === 'ar'
            ? expireDocuments[step]?.document.document_type?.name_ar
            : expireDocuments[step]?.document.document_type?.name}
        </HeadlineCenterAlways>
      )}

      {finish && progressBar && (
        <div
          className="flex flex-col items-center justify-center pt-10"
          style={{ paddingTop: '15%' }}
        >
          <ProgressLottie />
          <div className="inline-block">
            <ProgressBar percent={percent} width={400} />
          </div>
        </div>
      )}

      {finish && !progressBar && (
        <div className="flex flex-col items-center justify-center" style={{ paddingTop: '15%' }}>
          <FileLottie />
          <div className="flex flex-row items-center justify-center pt-5">
            <SuccessLottie />
            <AppBody pClass="Body1Bold" type="span" className="inline-block text-xl text-green-500"> {t('has_sent')}</AppBody>
          </div>
        </div>
      )}

      {finish === false && (
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5">
            <Dropzone
              setFile={setFiles}
              file={files}
              fileError={fileError}
              setFileError={setFileError}
              fileHint={t('rejectReason', { ns: 'common' })}
              rejectReason={t('rejectReason_simple', { ns: 'common' })}
              uploadInput={t('documents_or_photos', { ns: 'common' })}
              label={t('upload_your').replaceAll(
                '{{fileName}}',
                expireDocuments[step]?.document.document_type?.name,
              )}
              acceptArray={['.jpeg', '.jpg', '.JPEG', '.JPG', '.PNG', '.png', '.pdf', '.PDF']}
            />
          </div>

          <div className="grid grid-cols-2 gap-10 pt-10 ltr:text-left rtl:text-right">
            {expireDocuments[step]?.document.document_type?.edit_expire_column_json &&
              Array.isArray(
                expireDocuments[step]?.document.document_type?.edit_expire_column_json,
              ) &&
              expireDocuments[step]?.document.document_type?.edit_expire_column_json.length &&
              expireDocuments[step]?.document.document_type?.edit_expire_column_json.includes(
                'id_number',
              ) && (
                <label className="col-span-2 flex flex-col lg:col-span-1">
                  <AppBody pClass="Body2bold" type="span" className="relative">
                    {t('national_no')}
                    <TooltipPackage tooltipMessage={t('id_hint')} hasID id="idPreview" />
                  </AppBody>

                  <TextInput
                    type="text"
                    error={errors.id_number}
                    {...register('id_number', {
                      required: t('id_required'),
                      pattern: {
                        value: IDRegex,
                        message: t('id_pattern'),
                      },
                    })}
                  />
                </label>
              )}
            <div
              className="ltr:pl-50 rtl:pr-50 col-span-2 flex flex-col lg:col-span-1"
              htmlFor={expireDocuments[step]?.document.document_type?.edit_expire_date_column_name}
            >
              <AppBody pClass="Body1Bold" type="span">{t('expiry_date')}</AppBody>
              <DatePicker
                id={expireDocuments[step]?.document.document_type?.edit_expire_date_column_name}
                className="w-full"
                style={{ width: '100%' }}
                containerClassName="w-full"
                minDate={new Date()}
                calendar={calendarType}
                locale={calendarLocaleType}
                inputClass={`${
                  dateError ? 'border-red-400' : 'border-gray-3'
                } border rounded-full py-1 px-10 mt-1 `}
                onChange={(date) => {
                  if (date) {
                    setDateError(false);
                    setExpireDate(date);
                  }
                }}
                dropdownMode="scroll"
                showYearDropdown
                value={expireDate}
              />
              <AppBody pClass="Caption1Regular" className="pt-2 text-red-700">
                {(dateError || expireDate === '') && t('expiry_date_required')}
              </AppBody>
            </div>
          </div>

          <div className="flex flex-row">
            {expireDocuments &&
              expireDocuments[step]?.document.document_type?.edit_expire_column_json &&
              Array.isArray(
                expireDocuments[step]?.document.document_type?.edit_expire_column_json,
              ) &&
              expireDocuments[step]?.document.document_type?.edit_expire_column_json.length &&
              // eslint-disable-next-line array-callback-return,consistent-return
              expireDocuments[step]?.document.document_type?.edit_expire_column_json.map(
                (column, index) => {
                  if (column !== 'id_number') {
                    return (
                      <label
                        className={`relative flex w-1/2 flex-col ${
                          index + 1 <
                          expireDocuments[step]?.document.document_type?.edit_expire_column_json
                            .length
                            ? 'ltr:mr-10 rtl:ml-10'
                            : ''
                        }`}
                        htmlFor={column}
                      >
                        <AppBody pClass="Body2bold" type="span" className="ltr:text-left rtl:text-right">
                          {t(column)}
                        </AppBody>
                        <TextInput
                          id={column}
                          error={errors[column]}
                          {...register(column, {
                            required: t(`${column}_required`),
                          })}
                        />
                      </label>
                    );
                  }
                },
              )}
          </div>

          <div className="mt-5 flex flex-row justify-end self-stretch border-t border-gray-3 pt-2">
            <ActionButton
              className="flex flex-row"
              isLoading={isSubmitting}
              primary
              text={
                Number(step) === expireDocuments.length - 1
                  ? t('submit', { ns: 'common' })
                  : t('next', { ns: 'common' })
              }
              type="submit"
            />
          </div>
        </form>
      )}
    </div>
  );
}

function DocumentsStepBar({ step, expireList }) {
  const { t, i18n } = useTranslation(['expire']);

  return (
    <div className="w-full px-0 pb-6 pt-0 lg:px-10 lg:pt-6">
      {/* eslint-disable-next-line array-callback-return,consistent-return */}
      {expireList.map((value, index) => {
        if (step === index) {
          return (
            <span className="block text-gray-6 lg:hidden">
              {i18n.language === 'ar'
                ? value?.document.document_type?.name_ar
                : value?.document.document_type?.name}
            </span>
          );
        }
      })}

      <div className="hidden lg:flex">
        {expireList.map((value, index) => (
          <div
            className={`w-1/${Number(expireList.length)} ${
              Number(expireList.length) === 4 ? 'widthQ' : ''
            }`}
          >
            <div
              className={`text-center text-xs lg:text-base ${
                step >= index ? 'font-bold' : 'text-gray-6'
              }`}
            >
              <span className="twe_line mb-1 block">
                {i18n.language === 'ar'
                  ? value?.document.document_type?.name_ar
                  : value?.document.document_type?.name}
              </span>
            </div>
            <div className="relative mb-2">
              {index > 0 && (
                <div className="align-center stepWidth absolute flex content-center items-center align-middle">
                  <div className="stepLightGray align-center w-full flex-1 items-center align-middle">
                    <div
                      className={`stepDarkGray linePadding ${step >= index ? 'w-full' : 'w-0'}`}
                    />
                  </div>
                </div>
              )}
              <div className="step mx-auto flex items-center text-lg">
                <span
                  className={`text-center text-white ${step >= index ? 'borderDarkGrey' : ''} ${
                    step >= index ? 'stepBorderDarkGray' : 'stepBorderLightGray'
                  }`}
                >
                  {step > index && <Correct />}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EditExpireDocuments;
