import successCheckAnimation from '../../assets/lotties/successCheckAnimation.json';
import AppLottie from '../AppLottie';
import { AppHeader, AppBody } from '../html/html';

function ActionSuccess({ requestAction }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-2">
        <AppLottie loop={false} animationData={successCheckAnimation} width={220} />
      </div>
      <AppBody pClass="Body2Medium" className="text-gray-6">
        {requestAction.amount}
      </AppBody>
      <AppHeader h="h6" className="mb-3 flex flex-col items-center">
        <span>{requestAction.message}</span>
      </AppHeader>
    </div>
  );
}

export default ActionSuccess;
