function AppTextInput({ onBlur, name, value, onChange, defaultValue, maxLength, disabled }) {
  return (
    <input
      defaultValue={defaultValue}
      className="app-input"
      type="text"
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      maxLength={maxLength}
      disabled={disabled}
    />
  );
}

export default AppTextInput;
