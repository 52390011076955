import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppBody } from '../../../../components/html/html';
import { usersStatus } from '../../../../helpers/constants';
import useClickOutside from '../../../../hooks/useClickOutside';
import { showModalAction } from '../../../../redux/AppModalReducer/AppModalActions';
import {
  changeUserStatusAction,
  resendUserActivationEmailAction,
} from '../../../../redux/UsersReducer/UsersActions';
import ChangeStatusConfirmationPopup from '../ChangeStatusConfirmationPopup';

function UserDetailsChangeStatusPopup({ setShowChangeStatusPopup, showChangeStatusPopup }) {
  const { singleUserDetails } = useSelector((state) => state.users);
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();

  const changeStatusRef = useRef();

  useClickOutside(changeStatusRef, () => {
    setShowChangeStatusPopup(false);
  });

  const isActive = useMemo(() => {
    return singleUserDetails.status === usersStatus.Active;
  }, [singleUserDetails]);

  const isPending = useMemo(() => {
    return singleUserDetails.status === usersStatus.Pending;
  }, [singleUserDetails]);

  const isSuspended = useMemo(() => {
    return singleUserDetails.status === usersStatus.Suspended;
  }, [singleUserDetails]);

  const isDeactivated = useMemo(() => {
    return singleUserDetails.status === usersStatus.Deactivate;
  }, [singleUserDetails]);

  const changeUserStatus = useCallback(
    (status) => {
      if (status === usersStatus.Suspended) {
        dispatch(
          showModalAction(
            <ChangeStatusConfirmationPopup fromDetails item={singleUserDetails} status={status} />,
          ),
        );
      } else if (status === usersStatus.Deactivate) {
        dispatch(
          showModalAction(
            <ChangeStatusConfirmationPopup fromDetails item={singleUserDetails} status={status} />,
          ),
        );
      } else {
        dispatch(
          changeUserStatusAction({ userId: singleUserDetails.id, status, fromDetails: true }),
        );
      }
    },
    [singleUserDetails],
  );

  const resendActivationEmail = useCallback(() => {
    dispatch(resendUserActivationEmailAction({ userId: singleUserDetails.id }));
  }, [singleUserDetails]);

  return (
    <button
      type="button"
      className="relative me-1.5 text-black"
      onClick={() => {
        setShowChangeStatusPopup(!showChangeStatusPopup);
      }}
      ref={changeStatusRef}
    >
      {showChangeStatusPopup && (
        <AppBody
          pClass="Caption1Regular"
          type="div"
          className="absolute end-0 top-6 z-10 grid min-w-36 rounded bg-white p-2 text-start shadow"
        >
          {(isSuspended || isDeactivated) && (
            <span
              className="block py-1 ps-2 hover:bg-gray-1"
              onClick={() => {
                changeUserStatus(usersStatus.Active);
              }}
            >
              {t('Reactivate')}
            </span>
          )}

          {(isActive || isDeactivated) && (
            <span
              className="block py-1 ps-2 hover:bg-gray-1"
              onClick={() => {
                changeUserStatus(usersStatus.Suspended);
              }}
            >
              {t('Suspend')}
            </span>
          )}

          {(isActive || isSuspended) && (
            <span
              className="block py-1 ps-2 hover:bg-gray-1"
              onClick={() => {
                changeUserStatus(usersStatus.Deactivate);
              }}
            >
              {t('Deactivate(v)')}
            </span>
          )}

          {isPending && (
            <span className="block py-1 ps-2 hover:bg-gray-1" onClick={resendActivationEmail}>
              {t('Resend Activation')}
            </span>
          )}
        </AppBody>
      )}
    </button>
  );
}

export default UserDetailsChangeStatusPopup;
