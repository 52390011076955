import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formateDateToYYYYMMDD } from '../../../helpers/Utils';
import {
  hideFilterPopupAction,
  hideSearchPopupAction,
} from '../../../redux/CardDetailsReducer/CardDetailsActions';
import AppDatePicker from '../../AppDatePicker/index';
import AppSelect from '../../FormInputs/AppSelect/index';

function TimeRangeFilterDesktop({
  timeRangeFilters,
  filterType,
  setFilterType,
  daysOptions,
  days,
  setDays,
  monthsOptions,
  month,
  setMonth,
  yearsOptions,
  year,
  setYear,
  customFrom,
  setCustomFrom,
  customTo,
  setCustomTo,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation('commonV2');

  return (
    <div className="flex items-center gap-2 py-2">
      <p className="text-xs xs:text-sm">{t('Time Range: ')}</p>

      <div className="flex flex-wrap items-center gap-2">
        {/* Filter Type */}
        <div>
          <AppSelect
            options={timeRangeFilters}
            value={filterType}
            onMenuOpen={() => {
              dispatch(hideFilterPopupAction());
              dispatch(hideSearchPopupAction());
            }}
            onChange={(value) => {
              setFilterType(value);
            }}
            className="w-32 text-xs"
          />
        </div>

        {filterType?.value === 'days' && (
          <div>
            <AppSelect
              placeholder={t('Select')}
              options={daysOptions}
              value={days}
              onMenuOpen={() => {
                dispatch(hideFilterPopupAction());
                dispatch(hideSearchPopupAction());
              }}
              onChange={(value) => {
                setDays(value);
              }}
              className="w-32 text-xs"
            />
          </div>
        )}

        {filterType?.value === 'monthOf' && (
          <div className="flex flex-wrap gap-2">
            {/* Month */}
            <AppSelect
              placeholder={t('Select')}
              options={monthsOptions}
              value={month}
              onMenuOpen={() => {
                dispatch(hideFilterPopupAction());
                dispatch(hideSearchPopupAction());
              }}
              onChange={(value) => {
                setMonth(value);
                setYear(null);
              }}
              className="w-32 text-xs"
            />

            {/* Year */}
            <AppSelect
              placeholder={t('Select')}
              options={yearsOptions}
              value={year}
              onMenuOpen={() => {
                dispatch(hideFilterPopupAction());
                dispatch(hideSearchPopupAction());
              }}
              onChange={(value) => {
                setYear(value);
              }}
              className="w-32 text-xs"
              isDisabled={!month}
            />
          </div>
        )}

        {filterType?.value === 'custom' && (
          <div className="flex flex-wrap gap-2">
            <label className="flex items-center gap-2">
              <span className="text-sm">{t('From')}</span>
              <div className="w-32 text-xxs">
                <AppDatePicker
                  maxDate={new Date()}
                  placeholder={t('Select From')}
                  value={customFrom}
                  onChange={(val) => {
                    dispatch(hideFilterPopupAction());
                    setCustomTo('');
                    setCustomFrom(formateDateToYYYYMMDD(val));
                  }}
                />
              </div>
            </label>
            <label className="flex items-center gap-2">
              <span className="text-sm">{t('To')}</span>
              <div className="w-32 text-xs">
                <AppDatePicker
                  maxDate={moment(customFrom).add(3, 'months').toDate()}
                  minDate={moment(customFrom).add(1, 'days').toDate()}
                  placeholder={t('Select To')}
                  disabled={!customFrom}
                  value={customTo}
                  onChange={(val) => {
                    dispatch(hideFilterPopupAction());
                    setCustomTo(formateDateToYYYYMMDD(val));
                  }}
                />
              </div>
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeRangeFilterDesktop;
