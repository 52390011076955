/* eslint-disable camelcase */
import { apiGetRequest } from './APIHelper';
import { getLocalData, isAccountReady } from './Utils';

/* Returns true when 5 minutes have passed since last userinfo fetch */
const shouldInvalidateLocalUserInfo = (userInfo) => {
  if (userInfo.stored_at) {
    const now = Date.now();
    return now - userInfo.stored_at >= 1000 * 60 * 5;
  }
  return true;
};

export const refreshUserData = async (token) => {
  let capabilities = getLocalData('capabilities');
  let userinfo = getLocalData('userInfo');
  let accountReady = false;

  // load me always when email not verified
  let emailVerify = false;
  if (userinfo !== null && userinfo.email_verified === false) {
    emailVerify = true;
  }
  if (
    userinfo === null ||
    capabilities === null ||
    shouldInvalidateLocalUserInfo(userinfo) ||
    emailVerify
  ) {
    const apiData = await apiGetRequest('users/me', token);
    if (apiData) {
      apiData.stored_at = Date.now();
      userinfo = apiData;
      localStorage.setItem('userinfo', JSON.stringify(apiData));
      accountReady = isAccountReady(apiData);
    }
  } else {
    accountReady = isAccountReady(userinfo);
  }

  if (accountReady) {
    capabilities = await apiGetRequest('users/role_permissions', token);

    if (capabilities) {
      localStorage.setItem('capabilities', JSON.stringify(capabilities));
    }
  }

  return [userinfo, capabilities, accountReady];
};
