export const SET_USERS = 'SET_USERS';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SET_EMPLOYEE_FILTER = 'SET_EMPLOYEE_FILTER';
export const RESET_EMPLOYEE_FILTER = 'RESET_EMPLOYEE_FILTER';
const initialState = {
  users: [],
  filters: [],
};

const EmployeesReducer = (state = initialState, action) => {
  const draft = { ...state };
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };
    case RESET_EMPLOYEE_FILTER:
      draft.filters = [];
      return draft;
    case SET_EMPLOYEE_FILTER: {
      const { type, object } = action.payload;
      switch (type) {
        case 'add':
          draft.filters.push(object);
          break;
        case 'delete':
          if (object.type === 'compound') {
            Object.keys(object.options.subfilters).forEach((i) => {
              object.options.subfilters[i].value = null;
            });
          }
          draft.filters = draft.filters.filter((obj) => obj.key !== object.key);
          break;
        case 'update': {
          const { existing, updated } = object;
          const indexToUpdate = draft.filters.findIndex((item) => item.key === existing.key);
          draft.filters[indexToUpdate] = updated;
          break;
        }
        default:
          return draft;
      }
      return draft;
    }
    case SET_USERS:
      draft.users = action.payload;
      return draft;
    case UPDATE_FILTERS:
      draft.filters = action.payload;
      return draft;
    case UPDATE_EMPLOYEE: {
      const updatedItems = draft.users.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...draft,
        users: updatedItems,
      };
    }
    default:
      return draft;
  }
};

export default EmployeesReducer;
