import { AppIcon } from '../html/html';

function AppCloseButton({ onClick, className, ...rest }) {
  return (
    <button className={className} type="button" onClick={onClick} {...rest}>
      <AppIcon iClass="XXLargeFont" className="fa-regular fa-circle-xmark hover:text-primary" />
    </button>
  );
}

export default AppCloseButton;
