import { randomId } from 'javascript-functions';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../AppRouter/history';
import OTPInput from '../../../components/FormInputs/OTPInput';
import OTPResendTimer from '../../../components/FormInputs/OTPResendTimer';
import LTRForce from '../../../components/LTRForce/index';
import { AppBody, AppHeader } from '../../../components/html/html';
import { devLog, getServerErrMessage, isDev } from '../../../helpers/Utils';
import { loginActiveStep } from '../../../helpers/constants';
import { http } from '../../../http';
import { updateLang } from '../../../redux/AppConfigReducer/AppConfigActions';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';
import {
  setActiveLoginStepAction,
  setCachedLoginFormDataAction,
  setRedirectURLAction,
  setUserTokenAction,
} from '../../../redux/AuthReducer/AuthActions';

function LoginOTP() {
  const { t } = useTranslation(['loginV2', 'commonV2']);
  const dispatch = useDispatch();
  const { loaders, useMock } = useSelector((state) => state);
  const { cachedLoginStepData } = useSelector((state) => state.auth.loginProcess);
  const { redirectURL } = useSelector((state) => state.auth);
  const [OTPKey, setOTPKey] = useState(randomId());

  const handleLoginOTP = useCallback(
    ({ code }) => {
      devLog(`Logging In...`);
      const isMockUsed = useMock.employeeYakeenVerified;
      if (isMockUsed) {
        history.push('/signup/employee-accept-terms');
      } else {
        const formData = new FormData();

        formData.append('email', cachedLoginStepData.email);
        formData.append('password', cachedLoginStepData.password);
        formData.append('sms_token', cachedLoginStepData.smsToken);
        formData.append('code', code);

        http
          .post('login', formData, { loader: 'handleLoginOTP', noToken: true })
          .then((res) => {
            dispatch(updateLang(res.data.lang));
            dispatch(
              setUserTokenAction({ userToken: res.data.token, expires: res.data.expires_at }),
            );
            dispatch(setActiveLoginStepAction(loginActiveStep.loginAndBanner));

            if (redirectURL && redirectURL.includes('dashboard')) {
              history.push(redirectURL);
              dispatch(setRedirectURLAction(null));
            } else {
              history.push('/dashboard');
            }
          })
          .catch((err) => {
            dispatch(
              showToasterAction({
                type: 'error',
                message: getServerErrMessage(err),
              }),
            );
          });
      }
    },
    [cachedLoginStepData, redirectURL],
  );
  const handleResend = useCallback(() => {
    devLog(`Resend OTP...`);
    setOTPKey(randomId());
    const formData = new FormData();

    formData.append('email', cachedLoginStepData.email);
    formData.append('password', cachedLoginStepData.password);

    http
      .post('login', formData, { loader: 'handleLoginOTP' })
      .then((res) => {
        dispatch(
          setCachedLoginFormDataAction({
            ...cachedLoginStepData,
            smsToken: res.data.token,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [cachedLoginStepData]);

  return (
    <div className="mx-auto mt-[23vh] max-w-[400px] px-0 pb-5 md:px-5">
      <AppHeader h="h3">{t('One - Time Password', { ns: 'commonV2' })}</AppHeader>

      <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
        <span className="me-1">
          {t('Please enter the verification code we sent to', { ns: 'commonV2' })}
        </span>
        <span className="block text-start">
          <span>{t('Mobile')} </span>
          <span className="font-bold">
            <LTRForce>{cachedLoginStepData?.hashedMobile}</LTRForce>
          </span>
        </span>
        <span>
          <span className="font-bold">{t('OR', { ns: 'commonV2' })} </span>
          <span className="block font-bold">{cachedLoginStepData?.hashedEmail}</span>
        </span>
      </AppBody>

      <AppHeader className="mb-3" h="h5">
        {t('Enter The Code', { ns: 'commonV2' })}
      </AppHeader>

      <div className="mb-6">
        <OTPInput
          key={OTPKey}
          isLoading={loaders.handleLoginOTP}
          count={5}
          onComplete={(code) => {
            handleLoginOTP({ code });
          }}
        />
      </div>

      <div className="flex items-center gap-2">
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t("Didn't receive the one time code?", { ns: 'commonV2' })}
        </AppBody>
        <OTPResendTimer
          duration={isDev() ? 2 : 180}
          onResend={() => {
            handleResend();
          }}
        />
      </div>
    </div>
  );
}

export default LoginOTP;
