import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../AppRouter/history';
import AppSpinner from '../../../components/AppSpinner';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../../components/html/html';
import TranslatedData from '../../../components/TranslatedData';
import { http } from '../../../http';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';

function FundingMethodContent() {
  const { t } = useTranslation('profileV2');
  const { loaders } = useSelector((state) => state);

  const [fundingMethods, setFundingMethods] = useState([]);
  const dispatch = useDispatch();

  const getFundingMethods = useCallback(() => {
    http
      .get('funding_method', { loader: 'getFundingMethods' })
      .then((res) => {
        setFundingMethods(res.data);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  });

  const renderSingleFundMethod = useCallback((item) => {
    const isPending = item.status === 'New';
    return (
      <div className="relative overflow-hidden rounded-2xl border border-gray-5 p-5">
        <div className={`${isPending ? 'opacity-60' : ''} grid gap-6 lg:grid-cols-2`}>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Bank Name', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">
              <AppIcon
                iClass="LargeFont"
                className="fa-kit fa-landmark me-1 font-light text-gray-6"
              />
              <span>{item.bank_name || '-'}</span>
            </AppBody>
          </div>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Account Holder Name', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">{item.account_holder_name || '-'}</AppBody>
          </div>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Account Type', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">{item.account_type || '-'}</AppBody>
          </div>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Source of Fund', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">{item.fund_source || '-'}</AppBody>
          </div>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('IBAN', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">{item.iban || '-'}</AppBody>
          </div>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Currency', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">{item.currency || '-'}</AppBody>
          </div>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Country', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">
              <TranslatedData en={item?.country?.name || '-'} ar={item?.country?.name_ar || '-'} />
            </AppBody>
          </div>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Account Number', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">{item.account_number || '-'}</AppBody>
          </div>
          <div>
            <AppBody pClass="Body2Bold" className="text-gray-6">
              {t('Swift', { ns: 'commonV2' })}
            </AppBody>
            <AppBody pClass="Body1Bold">{item.swift || '-'}</AppBody>
          </div>
        </div>

        {isPending && (
          <div className="absolute -end-8 top-6 w-[130px] bg-gray-3 text-center ltr:rotate-45 rtl:-rotate-45">
            {t('Pending', { ns: 'commonV2' })}
          </div>
        )}
      </div>
    );
  }, []);

  useEffect(() => {
    getFundingMethods();
  }, []);

  return (
    <>
      {loaders.getFundingMethods && (
        <div className="h-[80vh] rounded-2xl border border-gray-5">
          <AppSpinner />
        </div>
      )}

      {!loaders.getFundingMethods && (
        <div className="grid gap-8">
          <div>
            <div className="flex justify-end">
              <AppButton
                button="primary"
                rounded="md"
                bClass="Button2Bold"
                size="md"
                className="py-2.5"
                onClick={() => {
                  history.push(
                    '/dashboard/wallet/add-funding-method?fromUrl=/dashboard/profile/company',
                  );
                }}
              >
                + {t('Funding Method')}
              </AppButton>
            </div>
            {fundingMethods.filter((f) => f.status === 'Active').length > 0 && (
              <AppHeader h="h6" className="mb-4">
                {t('Active Funding Methods')}
              </AppHeader>
            )}

            {/* Active Funding Methods */}
            <div className="grid gap-5">
              {fundingMethods.map((ele) => {
                return ele.status === 'Active' && renderSingleFundMethod(ele);
              })}
            </div>
          </div>

          {!!fundingMethods.length && (
            <div>
              {fundingMethods.filter((f) => f.status === 'New').length > 0 && (
                <AppHeader h="h6" className="mb-4">
                  {t('Pending Funding Methods')}
                </AppHeader>
              )}

              {/* Pending Funding Methods */}
              <div className="grid gap-5">
                {fundingMethods.map((ele) => {
                  return ele.status === 'New' && renderSingleFundMethod(ele);
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default FundingMethodContent;
