import { useFormik } from 'formik';
import { getURLParams } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { history } from '../../../../AppRouter/history';
import AppInput from '../../../../components/AppFormInputs/AppInput';
import AppPhoneInput from '../../../../components/AppFormInputs/AppPhoneInput';
import AppSpinner from '../../../../components/AppSpinner';
import AppSelect from '../../../../components/FormInputs/AppSelect';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../../../components/html/html';
import { inPages, userRoles } from '../../../../helpers/constants';
import {
  devLog,
  getServerErrMessage,
  translatedDataFunction,
  validateMobileNumber,
} from '../../../../helpers/Utils';
import { http } from '../../../../http';
import { showModalAction } from '../../../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  clearSingleUserDetailsAction,
  getSingleUserDetailsAction,
  updateUsersListAction,
} from '../../../../redux/UsersReducer/UsersActions';
import EditUserSuccessOrFail from './EditUserSuccessOrFail';
import EditUserSuccessOrFailPopup from './EditUserSuccessOrFailPopup';

function EditSingleUserForm({ inPage }) {
  const { t } = useTranslation('usersV2');
  const { singleUserDetails } = useSelector((state) => state.users);
  const { loaders } = useSelector((state) => state);
  const { lang, isMobile } = useSelector((state) => state.appConfig);
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [showPermissionDetails, setShowPermissionDetails] = useState(false);
  const [showEditAnimation, setShowEditAnimation] = useState({
    show: false,
    isSuccess: null,
  });
  const [userPermissions, setUserPermissions] = useState([]);
  const [failedError, setFailedError] = useState(null);
  const [validMobileNumber, setValidMobileNumber] = useState(true);
  const [isMobileExist, setIsMobileExist] = useState(false);

  const { userId } = useParams();

  const isUserOwner = useMemo(() => {
    return singleUserDetails?.roles?.includes(userRoles.Owner);
  }, [singleUserDetails]);

  const handleBack = useCallback(() => {
    if (getURLParams('backToList')) {
      history.push(`/dashboard/users`);
    } else {
      history.push(`/dashboard/users/${singleUserDetails.id}`);
    }
  }, []);

  const initialValues = useMemo(() => {
    return {
      email: singleUserDetails?.email || '',
      mobileNumber: singleUserDetails?.mobile,
      jobTitle: singleUserDetails?.job_title || '',
      department: singleUserDetails?.department.id || '',
      userType: singleUserDetails?.roles[0] || '',
    };
  }, [singleUserDetails]);

  const onSubmit = useCallback(
    (values) => {
      http
        .put(`employees/${singleUserDetails.id}`, null, {
          params: {
            email: values.email,
            mobile: values.mobileNumber,
            job_title: values.jobTitle,
            department_id: values.department,
            role: isUserOwner ? userRoles.Owner : values.userType,
          },
          loader: 'handleEditUser',
        })
        .then((res) => {
          if (isMobile) {
            dispatch(
              showModalAction(<EditUserSuccessOrFailPopup isSuccess handleBack={handleBack} />),
            );
          } else {
            setShowEditAnimation({ show: true, isSuccess: true });
            dispatch(getSingleUserDetailsAction({ userId: singleUserDetails.id }));

            if (inPage === inPages.usersList) {
              dispatch(updateUsersListAction(res.data));
            }
          }
        })
        .catch((err) => {
          setFailedError(err.status);
          if (isMobile) {
            dispatch(
              showModalAction(
                <EditUserSuccessOrFailPopup isSuccess={false} errCode={err.status} />,
              ),
            );
          } else {
            setShowEditAnimation({ show: true, isSuccess: false });
          }
        });
    },
    [singleUserDetails, isUserOwner],
  );

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('Please enter a valid Email.', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
    mobileNumber: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    jobTitle: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    department: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    userType: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema, enableReinitialize: true });

  const getDepartments = useCallback(() => {
    http
      .get(`departments`, {
        loader: 'getDepartments',
      })
      .then((res) => {
        const serialized = res.data.map((ele) => {
          return {
            label: translatedDataFunction({ lang, en: ele.name, ar: ele.name_ar }),
            value: ele.id,
          };
        });
        setDepartments(serialized);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [lang]);

  const getUserTypes = useCallback(() => {
    http
      .get('employees/types', { loader: 'getUserTypes' })
      .then((res) => {
        const serializedTypes = res.data.map((ele) => {
          return {
            label: ele.value,
            value: ele.key,
          };
        });

        const serializedPermissions = res.data.map((ele) => {
          return {
            label: ele.value,
            value: ele.description,
          };
        });
        setUserTypes(serializedTypes);
        setUserPermissions(serializedPermissions);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [t]);

  const handleCheckMobileNumber = useCallback(() => {
    if (formik.values.mobileNumber === singleUserDetails.mobile) {
      devLog('Use mobile number did not change!');
      formik.submitForm();
    } else {
      devLog('Use mobile number changed!, Validating existence...');
      http
        .get(`employees/mobile/exist`, {
          loader: 'handleCheckMobile',
          params: { mobile: formik.values.mobileNumber },
        })
        .then(() => {
          formik.submitForm();
        })
        .catch(() => {
          setIsMobileExist('The mobile number has already been taken.');
        });
    }
  }, [formik.values.mobileNumber]);

  const handleEditUser = useCallback(() => {
    if (validateMobileNumber(formik.values.mobileNumber)) {
      handleCheckMobileNumber();
    } else {
      setValidMobileNumber(false);
    }
  }, [formik.values.mobileNumber]);

  const isFormValid = useMemo(() => {
    return formik.isValid && !isMobileExist && validMobileNumber && !loaders.handleCheckMobile;
  }, [formik.isValid, validMobileNumber, loaders, isMobileExist]);

  useEffect(() => {
    getDepartments();
    getUserTypes();
  }, []);

  useEffect(() => {
    if (isMobile) {
      dispatch(getSingleUserDetailsAction({ userId }));
    }
  }, [isMobile, lang]);

  useEffect(() => {
    return () => {
      if (isMobile) {
        dispatch(clearSingleUserDetailsAction());
      }
    };
  }, [isMobile]);

  return (
    <>
      {isMobile && (
        <button
          className="mt-8"
          type="button"
          onClick={() => {
            handleBack();
          }}
        >
          <AppIcon
            className="fa-regular fa-angle-left text-primary rtl:rotate-180"
            iClass="XXLargeFont"
          />
        </button>
      )}

      {!loaders.getSingleUserDetailsAction && (
        <>
          {showEditAnimation.show && showEditAnimation.isSuccess && (
            <EditUserSuccessOrFail isSuccess />
          )}

          {showEditAnimation.show && !showEditAnimation.isSuccess && (
            <EditUserSuccessOrFail isSuccess={false} errCode={failedError} />
          )}

          {!showEditAnimation.show && (
            <>
              <AppHeader h="h1" className="mb-11 text-center">
                {t('Edit User')}
              </AppHeader>

              <form onSubmit={formik.handleSubmit}>
                {/* ============= Form Inputs ============= */}
                <div className="mx-auto mt-1.5 lg:max-w-96">
                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('Email', { ns: 'commonV2' })}
                    </AppHeader>
                    <AppInput
                      disabled
                      placeholder="solo@nqoodlet.com"
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <small className="text-danger">
                        {t(formik.errors.email, { ns: 'commonV2' })}
                      </small>
                    )}
                  </div>

                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('Phone Number', { ns: 'commonV2' })}
                    </AppHeader>
                    <AppPhoneInput
                      name="mobileNumber"
                      initialValue={singleUserDetails?.mobile}
                      onChange={({ value }) => {
                        setValidMobileNumber(true);
                        setIsMobileExist(false);
                        formik.setFieldValue('mobileNumber', value);
                      }}
                      onBlur={({ isValid }) => {
                        setValidMobileNumber(isValid);
                      }}
                    />

                    {formik.errors.mobileNumber && formik.touched.mobileNumber && (
                      <span className="mb-2 text-sm text-danger">{formik.errors.mobileNumber}</span>
                    )}

                    {!validMobileNumber && (
                      <small className="text-danger">
                        {t('Please enter a valid phone number.', { ns: 'commonV2' })}
                      </small>
                    )}

                    {isMobileExist && (
                      <small className="text-danger">{t(isMobileExist, { ns: 'commonV2' })}</small>
                    )}
                  </div>

                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('Job Title', { ns: 'commonV2' })}
                    </AppHeader>
                    <AppInput
                      placeholder={t('Junior product designer')}
                      type="text"
                      name="jobTitle"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.jobTitle}
                    />
                    {formik.errors.jobTitle && formik.touched.jobTitle && (
                      <small className="text-danger">
                        {t(formik.errors.jobTitle, { ns: 'commonV2' })}
                      </small>
                    )}
                  </div>

                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('Department', { ns: 'commonV2' })}
                    </AppHeader>
                    <AppSelect
                      isLoading={loaders.getDepartments}
                      options={departments}
                      value={departments.find((ele) => ele.value === formik.values.department)}
                      name="department"
                      onChange={({ value }) => {
                        formik.setFieldValue('department', value);
                      }}
                      styleType="inForm"
                    />
                    {formik.errors.department && formik.touched.department && (
                      <small className="text-danger">
                        {t(formik.errors.department, { ns: 'commonV2' })}
                      </small>
                    )}
                  </div>

                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('User Type', { ns: 'commonV2' })}
                    </AppHeader>

                    {isUserOwner && (
                      <AppInput
                        disabled
                        type="text"
                        name="userType"
                        onBlur={formik.handleBlur}
                        value={t('Account Owner')}
                      />
                    )}

                    {!isUserOwner && (
                      <AppSelect
                        isLoading={loaders.getUserTypes}
                        options={userTypes}
                        value={userTypes.find((ele) => ele.value === formik.values.userType)}
                        name="userType"
                        onChange={({ value }) => {
                          formik.setFieldValue('userType', value);
                        }}
                        styleType="inForm"
                      />
                    )}

                    {formik.errors.userType && formik.touched.userType && (
                      <small className="text-danger">
                        {t(formik.errors.userType, { ns: 'commonV2' })}
                      </small>
                    )}
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      setShowPermissionDetails(!showPermissionDetails);
                    }}
                  >
                    <AppBody pClass="Body2Bold" className="text-primary">
                      {!showPermissionDetails && t('Show User Permission details')}
                      {showPermissionDetails && t('Hide User Permission details')}
                    </AppBody>
                  </button>
                </div>

                {/* ============= User Permissions ============= */}
                {showPermissionDetails && (
                  <div className="mt-9 lg:px-9">
                    <div className="grid gap-10 rounded-3xl border border-gray-5 px-6 py-9">
                      {loaders.getUserPermissions && <AppSpinner />}
                      {!loaders.getUserPermissions &&
                        userPermissions.map((ele) => {
                          return (
                            <div>
                              <AppHeader h="h6" className="mb-3">
                                {ele.label}
                              </AppHeader>
                              <AppBody pClass="Body1Bold" className="text-gray-6">
                                {ele.value}
                              </AppBody>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                <div className="mx-auto mt-9 pb-14 lg:max-w-96">
                  <AppButton
                    isLoading={loaders.handleEditUser}
                    disabled={!isFormValid}
                    button="primary"
                    rounded="md"
                    size="lg"
                    className="w-full"
                    onClick={handleEditUser}
                  >
                    {t('Confirm', { ns: 'commonV2' })}
                  </AppButton>
                </div>
              </form>
            </>
          )}
        </>
      )}

      {loaders.getSingleUserDetailsAction && <AppSpinner />}
    </>
  );
}

export default EditSingleUserForm;
