import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BackButton = function ({ twoSteps, text, stateValue, stateBackPath }) {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  let stateNavigate = {};

  if (window.history.length > 1) {
    if (stateValue) {
      stateNavigate = {
        state: { ...stateValue },
      };
    }

    return (
      <button
        type="button"
        className="rlt:font-bold mb-5 align-baseline text-base text-primary ltr:text-left ltr:font-semibold rtl:text-right"
        onClick={() => {
          navigate(stateBackPath || (twoSteps ? -2 : -1), stateNavigate);
        }}
      >
        {'< '}
        {text || t('back')}
      </button>
    );
  }

  return (
    <button
      type="button"
      className="rlt:font-bold mb-5 align-baseline text-base text-primary ltr:text-left ltr:font-semibold rtl:text-right"
      onClick={() => {
        navigate('/dashboard', { replace: true });
      }}
    >
      {'< '}
      {text || t('back')}
    </button>
  );
};

export default BackButton;
