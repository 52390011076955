export function LockIconComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} fill="none" {...props}>
      <path
        stroke="#292524"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.313 4.5v-1C8.313 2.12 7.053 1 5.5 1 3.947 1 2.687 2.12 2.687 3.5v1M5.5 6.75v1M3.7 10h3.6c.945 0 1.418 0 1.779-.164.316-.143.574-.372.737-.655C10 8.86 10 8.44 10 7.6v-.7c0-.84 0-1.26-.184-1.581a1.611 1.611 0 0 0-.737-.655C8.717 4.5 8.245 4.5 7.3 4.5H3.7c-.945 0-1.418 0-1.779.163a1.611 1.611 0 0 0-.737.656C1 5.639 1 6.06 1 6.9v.7c0 .84 0 1.26.184 1.581.163.283.421.512.737.656C2.283 10 2.755 10 3.7 10Z"
      />
    </svg>
  );
}

export function NotAllowedIconComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} fill="none" {...props}>
      <path
        fill="#333"
        d="M7.17 8.057 1.944 2.829A3.75 3.75 0 0 0 7.17 8.056Zm.887-.886A3.75 3.75 0 0 0 2.83 1.944L8.056 7.17ZM10 5A5 5 0 1 1 0 5a5 5 0 0 1 10 0Z"
      />
    </svg>
  );
}

export function ClosedEyeIconComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={9} fill="none" {...props}>
      <path
        stroke="#7B8284"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.836 4.9C.765 4.788.73 4.732.71 4.646a.61.61 0 0 1 0-.232.779.779 0 0 1 .126-.255C1.42 3.234 3.159.893 5.809.893s4.39 2.34 4.974 3.266c.07.112.106.168.126.255a.608.608 0 0 1 0 .232.779.779 0 0 1-.126.254c-.584.926-2.323 3.266-4.974 3.266-2.65 0-4.389-2.34-4.973-3.266Z"
      />
      <path
        stroke="#7B8284"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.358.893 9.26 8.044"
      />
    </svg>
  );
}

export function OpenEyeIconComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={9} fill="none" {...props}>
      <path
        stroke="#7B8284"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.836 4.9C.765 4.788.73 4.732.71 4.646a.61.61 0 0 1 0-.232.779.779 0 0 1 .126-.255C1.42 3.234 3.159.893 5.809.893s4.39 2.34 4.974 3.266c.07.112.106.168.126.255a.608.608 0 0 1 0 .232.779.779 0 0 1-.126.254c-.584.926-2.323 3.266-4.974 3.266-2.65 0-4.389-2.34-4.973-3.266Z"
      />
    </svg>
  );
}

export function RequestCardProgramIconComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={15} fill="none" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.15}
        d="M14.25 13.5V9M12 11.25h4.5m0-6h-15m15 1.5V3.9c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656c-.32-.163-.74-.163-1.581-.163H3.9c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v5.7c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.581.164H9"
      />
    </svg>
  );
}

export function AddFundingMethodIconComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={15} fill="none" {...props}>
      <path
        stroke="#2D2926"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.889 6.66v3.56m2-3.56v3.56m2.222-3.56v3.56m2-3.56v3.56M6 10.932v.356c0 .25 0 .374.048.469.043.084.111.152.195.194.095.049.22.049.468.049h6.578c.249 0 .373 0 .468-.049a.445.445 0 0 0 .195-.194c.048-.095.048-.22.048-.47v-.355c0-.25 0-.374-.048-.47a.445.445 0 0 0-.195-.194c-.095-.048-.22-.048-.468-.048H6.71c-.249 0-.373 0-.468.048a.445.445 0 0 0-.195.195c-.048.095-.048.22-.048.469Zm3.846-6.909-3.29.732c-.198.045-.297.067-.371.12a.445.445 0 0 0-.149.186C6 5.145 6 5.247 6 5.45v.496c0 .25 0 .374.048.47.043.083.111.151.195.194.095.048.22.048.468.048h6.578c.249 0 .373 0 .468-.048a.445.445 0 0 0 .195-.195c.048-.095.048-.22.048-.469V5.45c0-.203 0-.305-.036-.39a.444.444 0 0 0-.149-.185c-.074-.053-.173-.075-.372-.12l-3.289-.732a.927.927 0 0 0-.115-.021.443.443 0 0 0-.078 0 .926.926 0 0 0-.115.021Z"
      />
      <rect
        width={17.85}
        height={12.85}
        x={1.075}
        y={1.075}
        stroke="#000"
        strokeWidth={1.15}
        rx={2.425}
      />
    </svg>
  );
}
