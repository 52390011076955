import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AppNumberInput from '../../../../../components/FormInputs/AppNumberInput';
import AfterSuccessAmountCurrency from '../../../../../components/SharedComponents/AfterSuccessAmountCurrency';
import CardProgramLogo from '../../../../../components/SharedComponents/CardProgramLogo';
import { AppBody, AppButton } from '../../../../../components/html/html';
import { devLog, toIntAndDecimalNumber } from '../../../../../helpers/Utils';
import { cardActionsPopupContent, cardStatusKeys, inPages } from '../../../../../helpers/constants';
import { http } from '../../../../../http';
import {
    getCardDetailsBalanceAction,
    setCurrentCardActionsPopupContent,
} from '../../../../../redux/CardDetailsReducer/CardDetailsActions';
import { getSingleUserCardDetailsBalanceAction } from '../../../../../redux/UsersReducer/UsersActions';

function CardUnLoadContent({ cardDetails, cardDetailsBalance, inPage }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const { loaders } = useSelector((state) => state);
  const { userDetailsData } = useSelector((state) => state.cardDetails);
  const { singleUserDetails } = useSelector((state) => state.users);

  const getCardCurrency = useCallback(() => {
    return cardDetails.virtualCard
      ? cardDetails.virtualCard?.cardproduct?.currency
      : cardDetails.physicalCard?.cardproduct?.currency;
  }, [cardDetails]);

  const getCardId = useCallback(() => {
    // in card details if user single physical card selected, send physical card id
    if (cardDetails.physicalCard && !cardDetails.virtualCard) {
      return cardDetails.physicalCard?.id;
    }

    // send physical card id only if virtual card is !== active
    if (
      cardDetails.physicalCard &&
      cardDetails?.virtualCard?.status?.key !== cardStatusKeys.ACTIVE
    ) {
      devLog(`[Virtual] Card Status is: ${cardDetails?.virtualCard?.status?.key}`);
      devLog(`Sending [Physical] Card ID: ${cardDetails.physicalCard?.id}`);
      return cardDetails.physicalCard?.id;
    }
    return cardDetails.virtualCard.id;
  }, [cardDetails]);

  const handleUnLoad = useCallback(
    (values) => {
      const formBody = new FormData();
      formBody.append('business_transfer', 1);
      formBody.append('currency', getCardCurrency());
      formBody.append('amount', values.amount);

      formBody.append('is_deposit', 0);

      formBody.append('sender_type', 'CARD');
      formBody.append('recipient_type', 'CARDHOLDER');

      formBody.append('sender_id', getCardId());

      if (inPage === inPages.singleUserCardDetails) {
        formBody.append('recipient_id', singleUserDetails?.id);
      }

      if (inPage === inPages.myCards) {
        formBody.append('recipient_id', userDetailsData?.id);
      }

      http
        .post(`cardmanager/transfer`, formBody, {
          loader: 'handleUnLoad',
        })
        .then(() => {
          dispatch(
            setCurrentCardActionsPopupContent({
              content: cardActionsPopupContent.actionSuccess,
              amount: (
                <AfterSuccessAmountCurrency amount={values.amount} currency={getCardCurrency()} />
              ),
              message: t('Card Unloaded Successfully', { ns: 'cardsV2' }),
            }),
          );

          setTimeout(() => {
            if (inPage === inPages.myCards) {
              dispatch(getCardDetailsBalanceAction(getCardId()));
            }
            if (inPage === inPages.singleUserCardDetails) {
              dispatch(getSingleUserCardDetailsBalanceAction(getCardId()));
            }
          }, 2000);
        })
        .catch((err) => {
          dispatch(
            setCurrentCardActionsPopupContent({
              content: cardActionsPopupContent.actionFail,
              reason: err?.data?.error,
              message: t('Failed to Unload the Amount', { ns: 'cardsV2' }),
            }),
          );
        });
    },
    [userDetailsData, singleUserDetails],
  );

  const initialValues = {
    amount: '',
  };

  const validationSchema = yup.object({
    amount: yup
      .number()
      .min(1, t('Amount is invalid must be more than 0'))
      .required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: handleUnLoad,
    validationSchema,
  });
  return (
    <>
      <div className="mb-5 flex items-start justify-between">
        <div className="flex flex-col gap-2">
          <AppBody pClass="Body2Bold" type="span">
            {cardDetails?.virtualCard?.cardproduct?.description}
          </AppBody>
        </div>
        <div className="flex items-center gap-1">
          <CardProgramLogo cardproduct={cardDetails?.virtualCard?.cardproduct} />
        </div>
      </div>

      <div className="rounded-lg border border-gray-3 p-3">
        <AppBody
          pClass="Body2Medium"
          className="mb-5  flex items-center justify-between text-gray-6"
        >
          <span>{t('Card Balance')}</span>
          <span className="flex items-center gap-1">
            <span>{toIntAndDecimalNumber(cardDetailsBalance.amount).fullNumber}</span>
            <span>{cardDetailsBalance.currency}</span>
          </span>
        </AppBody>

        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3 grid gap-4 pb-4">
            <label>
              <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                {t('Amount')}
              </AppBody>
              <AppNumberInput
                symbol={getCardCurrency()}
                name="amount"
                value={formik.values.amount}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue('amount', e.target.value);
                }}
              />
              {formik.errors.amount && formik.touched.amount && (
                <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">
                  {formik.errors.amount}
                </AppBody>
              )}
            </label>
          </div>
          <div className="flex items-center justify-end gap-4">
            <AppButton
              isLoading={loaders.handleUnLoad}
              loadingLabel={t('Loading...', { ns: 'commonV2' })}
              disabled={!formik.isValid}
              button="black"
              type="submit"
            >
              {t('Unload', { ns: 'cardsV2' })}
            </AppButton>
          </div>
        </form>
      </div>
    </>
  );
}

export default CardUnLoadContent;
