import { useDispatch, useSelector } from 'react-redux';
import { useMockAction } from '../../redux/UseMockReducer/UseMockActions';
import AppSwitchInput from '../AppSwitchInput';

function EmployeeRegistrationMockContent() {
  const { useMock } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-2 text-xs [&>*]:border-b-2 [&>*]:pb-2">
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.employeeExpiredLink}
            onChange={() => {
              dispatch(
                useMockAction({
                  employeeExpiredLink: !useMock.employeeExpiredLink,
                  employeeAlreadyActivatedAccount: false,
                  employeeYakeenVerified: false,
                }),
              );
            }}
          />
          <span>Employee Link is Expired</span>
        </label>
      </div>
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.employeeAlreadyActivatedAccount}
            onChange={() => {
              dispatch(
                useMockAction({
                  employeeAlreadyActivatedAccount: !useMock.employeeAlreadyActivatedAccount,
                  employeeExpiredLink: false,
                  employeeYakeenVerified: false,
                }),
              );
            }}
          />
          <span>Employee Already Activated Account</span>
        </label>
      </div>
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.employeeInvalidToken}
            onChange={() => {
              dispatch(
                useMockAction({
                  employeeInvalidToken: !useMock.employeeInvalidToken,
                  employeeAlreadyActivatedAccount: false,
                  employeeExpiredLink: false,
                  employeeYakeenVerified: false,
                }),
              );
            }}
          />
          <span>Employee Invalid Token</span>
        </label>
      </div>
      <div>
        <label className="flex items-center gap-1">
          <AppSwitchInput
            size="sm"
            checked={useMock.employeeYakeenVerified}
            onChange={() => {
              dispatch(
                useMockAction({
                  employeeYakeenVerified: !useMock.employeeYakeenVerified,
                  employeeAlreadyActivatedAccount: false,
                  employeeExpiredLink: false,
                }),
              );
            }}
          />
          <span>Employee Yakeen Verified</span>
        </label>
      </div>
    </div>
  );
}

export default EmployeeRegistrationMockContent;
