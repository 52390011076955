import { useDispatch } from 'react-redux';
import AppCloseButton from '../../../components/AppCloseButton/index';
import { hideModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import AddUserForm from './AddUserForm';

function AddUserPopup() {
  const dispatch = useDispatch();

  return (
    <div className="h-[90vh] w-[684px] overflow-y-auto rounded-2xl bg-gray-1 shadow">
      <div className="me-9 mt-9 text-end">
        <AppCloseButton
          onClick={() => {
            dispatch(hideModalAction());
          }}
        />
      </div>

      <div className="h-[90%]">
        <AddUserForm />
      </div>
    </div>
  );
}

export default AddUserPopup;
