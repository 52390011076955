export const PhoneValidationPattern = /[+-]?\d+(?:[.,]\d+)?/;
export const EmailValidationPattern =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const DigitsValidationPattern = /^\d+$/;
export const PasswordValidationPattern = /^(?=.*[A-Z])(?=.*\d).+$/;
export const PostalValidationPattern = /\b\d{5}\b/g;
export const NumberValidationPattern = /[0-9]/g;
export const CardNameValidationPattern = /^[a-zA-Z ]{2,32}$/;
export const UrlValidationPattern =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
export const NameValidationPattern = /^[a-zA-Z ]+$/;
export const ArabicNamePattern = /[\u0600-\u06FF\u0750-\u077F]/;
export const IDNUMBERPATTERN = /^(1)[0-9]\d+$/;
export const IQAMANUMBERPATTERN = /^(2)[0-9]\d+$/;
export const PASSPORTPATTERN = /^(?!^0+$)[a-zA-Z0-9]{6,9}$/;
export const AddressValidationPattern = /^[a-zA-Z0-9\s,'-]*$/;
export const IBANPattern =
  /^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/;
export const SWIFTPattern = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
export const POSTIVENUMBERPATTERN = /^\+?(\d*[1-9]\d*\.?|\d*\.\d*[1-9]\d*)$/;
