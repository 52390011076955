import { apiGetRequest, apiPatchRequest, apiPostFormDataRequest } from './APIHelper';

export const userStatusHandler = (userId, status, token) =>
  apiPatchRequest(
    'users/status',
    {
      user_id: userId,
      status,
    },
    token,
  );

export const cardStatusHandler = (cardId, status, token) => {
  const formData = new FormData();
  formData.append('status', status);
  formData.append('_method', 'PUT');
  return apiPostFormDataRequest(`cardmanager/cards/${cardId}/status`, formData, token);
};

export const cardBalance = (cardId, token) =>
  apiGetRequest(`cardmanager/cards/balance/${cardId}`, token);
