import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as workerTimers from 'worker-timers';
import LinkButton from './Button/LinkButton';

function Timer({ sendOtp, isLoading }) {
  const { t, i18n } = useTranslation(['common']);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 3); // 5 minutes

      timerId = workerTimers.setInterval(() => {
        setCountDown((newCountDown) => newCountDown - 1);
      }, 1000);
    } else {
      workerTimers.clearInterval(timerId);
    }

    return () => workerTimers.clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => {
    setRunTimer((time) => !time);
    sendOtp();
  };

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  return (
    <p
      className="pt-10 text-gray-6"
      style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
    >
      {t('resend_question')}
      {runTimer && <span className="text-primary">{` ${minutes}:${seconds}`}</span>}
      {!runTimer && (
        <LinkButton
          text={t('resend')}
          className="inline px-2 text-primary"
          onClick={togglerTimer}
          isLoading={isLoading}
        />
      )}
    </p>
  );
}

export default Timer;
