import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import AppSpinner from '../../../../components/AppSpinner';
import {AppBody, AppHeader} from '../../../../components/html/html';
import {cardTypesKeys} from '../../../../helpers/constants';

function ShippingAddress() {
  const { t } = useTranslation('cardsV2');
  const { user } = useSelector((state) => state.auth);

  const { loaders } = useSelector((state) => state);
  const { shippingAddress, availableCardTypes } = useSelector((state) => state.createNewCard);

  const cardCurrencyAndFeesData = useMemo(() => {
    return availableCardTypes?.find((ele) => ele.name === cardTypesKeys.PHYSICAL);
  }, [availableCardTypes]);

  return (
    <div className="mb-8 rounded-lg border-2 bg-white">
      <div className="border-b-2 p-5">
        <AppHeader h="h6">{t('Shipping Address')}</AppHeader>

        <div className="my-2">
          {!loaders.getShippingAddress && shippingAddress && (
            <>
              <p>{user?.company_name}</p>
              <p>{shippingAddress.address1}</p>
              <p>{shippingAddress.address2}</p>
              <p>
                <span>{shippingAddress.city?.name}</span>
                <span>, {shippingAddress.postal_code}</span>
              </p>
              <p>
                <span>{shippingAddress.state}</span>
                <span>, {shippingAddress.country?.name}</span>
              </p>
            </>
          )}

          {!loaders.getShippingAddress && !shippingAddress && (
            <p className="text-red-600">{t('No Shipping Address!')}</p>
          )}

          {loaders.getShippingAddress && (
            <div className="inline-block">
              <AppSpinner size={22} />
            </div>
          )}
        </div>

        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('If the company address is not correct please contact your account admin')}
        </AppBody>
      </div>

      <div className="flex items-center gap-1 p-5">
        <span className="text-sm">{t('Annual Fees: ')}</span>

        <span style={{ direction: 'ltr' }} className="flex gap-1">
          <AppBody pClass="Body1Bold" type="span" >
            {cardCurrencyAndFeesData?.fee}
          </AppBody>
          <AppBody pClass="Body1Bold" type="span" >
            {cardCurrencyAndFeesData?.currency}
          </AppBody>
        </span>
      </div>
    </div>
  );
}

export default ShippingAddress;
