import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AppBody, AppHeader} from '../../../../components/html/html';

function ContactUs({ header, emailSubject, emailError }) {
  const { user } = useSelector((state) => state.auth);
  const { createCardUserData } = useSelector((state) => state.createNewCard);
  const { t } = useTranslation('commonV2');

  const subject = useMemo(() => {
    return `${emailSubject} for ${createCardUserData.email}`;
  }, []);

  const body = useMemo(() => {
    const userInformation = `
      ${emailSubject} for:
      User ID: ${createCardUserData?.id}
      User Email: ${createCardUserData?.email}
      Company ID: ${user?.account_id}
      Company Name: ${user?.company_name}
      User Full Name: ${createCardUserData?.first_name} ${createCardUserData?.last_name}
      Error: ${emailError || 'No Error'}
    `;

    return encodeURIComponent(userInformation);
  }, [createCardUserData, user, emailSubject, emailError]);

  return (
    <a href={`mailto:support@nqoodlet.com?subject=${subject}&body=${body}`}>
      <AppHeader h="h6" className="mb-1">
        {header || t('Contact Us')}
      </AppHeader>
      <div className="flex items-center justify-between rounded-lg border border-gray-3 bg-white px-4 py-3">
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t('Email: ')}
        </AppBody>

        <AppBody pClass="Body1Bold">support@nqoodlet.com</AppBody>
      </div>
    </a>
  );
}

export default ContactUs;
