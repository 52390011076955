import { months } from 'constants-js';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AppSelect from '../../../../../components/FormInputs/AppSelect';
import { AppBody, AppButton } from '../../../../../components/html/html';
import { downloadFile } from '../../../../../helpers/Utils';
import { http } from '../../../../../http';
import { showToasterAction } from '../../../../../redux/AppToastersReducer/AppToastersActions';

function StatementContent({ cardDetails }) {
  const { loaders } = useSelector((state) => state);
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const [height, setHeight] = useState('auto');
  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();

  const isDoubleCard = useMemo(() => {
    return Boolean(cardDetails.physicalCard) && Boolean(cardDetails.virtualCard);
  }, [cardDetails]);

  const cardOptions = useMemo(() => {
    return [
      {
        label: (
          <div className="flex items-center justify-between gap-1">
            <div className="flex gap-1">
              <span>{cardDetails.virtualCard?.card_name || t('Untitled', { ns: 'commonV2' })}</span>
              <span>{`*${cardDetails.virtualCard?.last_four}`}</span>
            </div>
            <span>
              <img
                className="shadow"
                alt="card img"
                width={35}
                src={cardDetails.virtualCard?.design?.attachment}
              />
            </span>
          </div>
        ),
        value: cardDetails.virtualCard?.id,
      },
      {
        label: (
          <div className="flex items-center justify-between gap-1">
            <div className="flex gap-1">
              <span>
                {cardDetails.physicalCard?.card_name || t('Untitled', { ns: 'commonV2' })}
              </span>
              <span>{`*${cardDetails.physicalCard?.last_four}`}</span>
            </div>
            <span>
              <img
                className="shadow"
                alt="card img"
                width={35}
                src={cardDetails.physicalCard?.design?.attachment}
              />
            </span>
          </div>
        ),
        value: cardDetails.physicalCard?.id,
      },
    ];
  }, [cardDetails]);

  const yearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const fromYear = currentYear - 20;
    const years = [];

    Array.from({ length: currentYear - fromYear + 1 }, (_, index) => fromYear + index)
      .reverse()
      .forEach((year) => {
        years.push({ value: year, label: year });
      });
    return years;
  }, []);

  const monthsOptions = useMemo(() => {
    const serialized = months.map((ele) => {
      return {
        ...ele,
        label: t(ele.label, { ns: 'commonV2' }),
        value: ele.value + 1,
      };
    });
    return serialized;
  }, [months]);

  const onSubmit = useCallback(
    (values) => {
      http
        .get(`transactions/statement`, {
          loader: 'handleDownloadStatement',
          responseType: 'blob',
          params: {
            month: values.month.value,
            year: values.year.value,
            card_id: values.card.value,
          },
        })
        .then((res) => {
          downloadFile({
            data: res,
            fileName: `${values.card.value}-${values.year.value}-${values.month.label}`,
            fileExtension: 'xlsx',
          });
        })
        .catch(() => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: t('No Results Found.', { ns: 'cardsV2' }),
            }),
          );
        });
    },
    [cardDetails, isDoubleCard],
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    const validations = {
      year: yup.mixed().required(t('This field is required!', { ns: 'commonV2' })),
      month: yup.mixed().required(t('This field is required!', { ns: 'commonV2' })),
    };

    if (isDoubleCard) {
      validations.card = yup.mixed().required(t('This field is required!', { ns: 'commonV2' }));
    }

    setValidationSchema(yup.object(validations));
  }, [isDoubleCard, cardDetails]);

  useEffect(() => {
    const currentMonthIndex = new Date().getMonth();
    setInitialValues({
      card: isDoubleCard ? '' : { value: cardDetails.virtualCard?.id },
      year: yearsOptions[0],
      month: monthsOptions[currentMonthIndex],
    });
  }, [isDoubleCard, cardDetails]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="rounded-lg border border-gray-3 p-3"
      style={{ height }}
    >
      {isDoubleCard && (
        <div className="mb-4">
          <label>
            <AppBody pClass="Caption1Bold" type="span" className="mb-1 block">
              {t('Select Card')}
            </AppBody>
            <AppSelect
              mobileSelect={false}
              className="w-full text-xs"
              isClearable
              name="card"
              placeholder={t('Select Card')}
              options={cardOptions}
              onMenuClose={() => {
                formik.setFieldTouched('card');
              }}
              value={formik.values.card}
              onBlur={formik.handleBlur}
              onChange={(option) => {
                formik.setFieldValue('card', option);
              }}
            />
            {formik.errors.card && formik.touched.card && (
              <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">
                {formik.errors.card}
              </AppBody>
            )}
          </label>
        </div>
      )}

      <div className="mb-4 grid lg:grid-cols-2 lg:gap-3">
        <label>
          <AppBody pClass="Caption1Bold" type="span" className="mb-1 block">
            {t('Select Period')}
          </AppBody>
          <AppSelect
            className="w-full text-xs"
            isClearable
            name="year"
            placeholder={t('Select Year')}
            options={yearsOptions}
            onMenuOpen={() => {
              setHeight(500);
            }}
            onMenuClose={() => {
              setHeight('auto');
              formik.setFieldTouched('year');
            }}
            value={formik.values.year}
            onBlur={formik.handleBlur}
            onChange={(option) => {
              formik.setFieldValue('year', option);
            }}
          />
          {formik.errors.year && formik.touched.year && (
            <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">
              {formik.errors.year}
            </AppBody>
          )}
        </label>
        <label>
          <AppBody pClass="Caption1Bold" type="span" className="mb-5 block" />
          <AppSelect
            className="w-full text-xs"
            isClearable
            name="month"
            placeholder={t('Select Month')}
            options={monthsOptions}
            onMenuOpen={() => {
              setHeight(500);
            }}
            onMenuClose={() => {
              setHeight('auto');
              formik.setFieldTouched('month');
            }}
            value={formik.values.month}
            onBlur={formik.handleBlur}
            onChange={(option) => {
              formik.setFieldValue('month', option);
            }}
          />
          {formik.errors.month && formik.touched.month && (
            <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">
              {formik.errors.month}
            </AppBody>
          )}
        </label>
      </div>

      <AppBody pClass="Body2Regular" className="text-sm text-red-500">
        <span className="font-bold">{t('Note: ')}</span>
        <span>
          {t(
            'Card statements are issued on the 16th of each month for the prior month’s activity. For example, your September statement will be available on October 16th.',
          )}
        </span>
      </AppBody>

      <div className="flex items-center justify-end gap-4">
        <AppButton
          isLoading={loaders.handleDownloadStatement}
          loadingLabel={t('Downloading', { ns: 'commonV2' })}
          disabled={!formik.isValid}
          button="black"
          type="submit"
        >
          {t('Download', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </form>
  );
}

export default StatementContent;
