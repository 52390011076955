import { randomId } from 'javascript-functions';
import React from 'react';
import { useSelector } from 'react-redux';
import { DynamicElement } from '../../../../../components/html/html';
import { formatDate } from '../../../../../helpers/Utils';
import CardsActivityTableRow from './CardsActivityTableRow';

const WalletCardsActivityTableBody = ({ requestsDates, walletCardActivities }) => {
  const { isMobile } = useSelector((state) => state.appConfig);

  return Object.values(requestsDates).map((tDate) => {
    return (
      <React.Fragment key={randomId()}>
        <DynamicElement element={isMobile ? 'div' : 'tr'} className="hover:bg-transparent">
          <DynamicElement
            element={isMobile ? 'div' : 'td'}
            className="min-w-28 py-3 text-sm font-bold"
          >
            {tDate}
          </DynamicElement>
        </DynamicElement>

        {walletCardActivities?.data?.map((item) => {
          return (
            formatDate(item.created_at, 'DD MMM YYYY') === tDate && (
              <CardsActivityTableRow key={item.id} item={item} />
            )
          );
        })}
      </React.Fragment>
    );
  });
};

export default WalletCardsActivityTableBody;
