import { useEffect, useState } from 'react';
import { GiJumpAcross } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { isDev, isTesting } from '../../helpers/Utils';
import { showModalAction } from '../../redux/AppModalReducer/AppModalActions';
import UseMockButtonContent from './UseMockButtonContent';

function UseMockButton() {
  const dispatch = useDispatch();
  const { useMock } = useSelector((state) => state);
  const [isMockUsed, setIsMockUsed] = useState(false);

  useEffect(() => {
    const used = Object.values(useMock).filter((ele) => {
      if (typeof ele === 'boolean' && ele) {
        return ele;
      }
      return null;
    });
    setIsMockUsed(used);
  }, [useMock]);
  return (
    (isTesting() || isDev()) && (
      <button
        onClick={() => {
          dispatch(showModalAction(<UseMockButtonContent />));
        }}
        type="button"
        className="absolute -start-20 bottom-5 z-30 flex h-16 w-16 items-center justify-center rounded-full bg-teal-800 p-1 shadow"
      >
        <GiJumpAcross size={32} className="text-white" />
        {!!isMockUsed?.length && (
          <div className="absolute left-0 top-0 h-6 w-6 rounded-full bg-red-500 text-white">
            {isMockUsed?.length}
          </div>
        )}
      </button>
    )
  );
}

export default UseMockButton;
