import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LinkButton from '../../../components/Button/LinkButton';
import RegisterStepBar from '../../../components/Stepbar/RegisterStepBar';
import { HeadlineCenterAlways } from '../../../components/Titles';
import { apiGetRequest } from '../../../helpers/APIHelper';
import useNotifications from '../../../helpers/useNotifications';
import {AppBody} from "../../../components/html/html";

function VerifyEmailStep() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['token']);
  const [, addNotifications] = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = localStorage.getItem('userinfo')
    ? JSON.parse(localStorage.getItem('userinfo'))
    : null;
  const { t } = useTranslation(['signup']);
  const [email] = useState(user && user.email);

  const resendEmail = () => {
    setIsSubmitting(true);
    apiGetRequest('users/email/resend', cookies.token)
      .then(() => {
        setIsSubmitting(false);
        addNotifications(t('complete_signup.success'));
      })
      .catch((err) => {
        addNotifications((err && err.data && err.data.error) || t('complete_signup.error'), false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (!user) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <div className="mx-auto flex w-full flex-col lg:w-8/12">
        <div className="centerTitle flex flex-col text-center">
          <RegisterStepBar step={3} />
          <HeadlineCenterAlways>{t('verify_email.title')}</HeadlineCenterAlways>
          <AppBody type="span" pClass="Body2Regular" className="pb-4 pt-2 text-center text-gray-6">
            {t('verify_email.sentence')}
          </AppBody>

          <p className="pt-10 text-gray-6">
            {t('verify_email.sentence2', {
              user_email: email,
            })}
          </p>
          <p className="m-auto text-center">
            {!user.email_verified && (
              <LinkButton
                isLoading={isSubmitting}
                className="flex flex-row font-bold capitalize text-primary"
                onClick={resendEmail}
                text={t('complete_signup.resend')}
              />
            )}
            {user.email_verified && navigate('/signup/verify_identity')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmailStep;
