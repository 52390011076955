import { useState } from 'react';
import { ReactComponent as BlueSpinner } from '../../assets/svg/BlueLoading.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/closeIconRed.svg';
import { ReactComponent as Edit } from '../../assets/svg/edit.svg';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';
import { ReactComponent as Trash } from '../../assets/svg/trash.svg';

const LinkButton = function (props) {
  const { onClick, text, className, disabled, isEdit, isTrash, isClose, isDeleteRed } = props;
  const [isLoading, setIsLoading] = useState(false);
  const onClickWrapper = () => {
    const promise = new Promise((resolve, reject) => {
      const result = onClick();
      if (result instanceof Promise) {
        setIsLoading(true);
        result.then((response) => resolve(response)).catch((e) => reject(e));
      } else {
        resolve(true);
      }
    });
    promise.finally(() => {
      setIsLoading(false);
    });
  };
  return (
    <button
      type="button"
      className={`${
        className || ''
      } cursor-pointer text-left align-baseline text-sm disabled:bg-gray-3`}
      onClick={onClickWrapper}
      disabled={isLoading || disabled}
    >
      {isEdit && <Edit className="no_print inline-block px-0.5" />}
      {isTrash && <Trash className="no_print inline-block px-0.5" />}
      {isClose && <CloseIcon className="no_print inline-block px-0.5" />}
      {isDeleteRed && <DeleteIcon className="no_print inline-block px-0.5" />}
      <span
        className={`no_print ${
          className && className.includes('text-primary') ? 'text-primary' : ''
        }`}
      >
        {text}
      </span>
      {isLoading && className && className.includes('text-primary') && (
        <BlueSpinner className="h-6 w-6 animate-spin fill-white" />
      )}
      {isLoading && className && className.includes('bg-transparent') && (
        <Spinner className="h-6 w-6 animate-spin fill-gray" />
      )}
      {isLoading && !className && (
        <Spinner
          className={`${
            className && className.includes('text-primary') ? 'fill-lightGray' : 'fill-white'
          } h-6 w-6 animate-spin`}
        />
      )}
    </button>
  );
};

export default LinkButton;
