import { ToggleSwitch } from 'flowbite-react';
import { useMemo } from 'react';

function AppSwitchInput({ checked, onChange, disabled, size = 'md' }) {
  const theme = useMemo(() => {
    return {
      root: {
        base: 'group relative flex items-center rounded-lg focus:outline-none',
        active: {
          on: 'cursor-pointer',
          off: 'cursor-not-allowed opacity-50',
        },
        label: 'ml-3 text-sm font-medium text-gray-900 dark:text-gray-300',
      },
      toggle: {
        base: 'toggle-bg rounded-full border group-focus:ring-4 group-focus:ring-primary/30',
        checked: {
          on: 'after:translate-x-full after:border-white',
          off: 'border-gray-200 bg-gray-200 dark:border-gray-600 dark:bg-gray-700',
          color: {
            primary: ' bg-primary border-primary-dark',
          },
        },
        sizes: {
          sm: 'w-9 h-5 after:absolute after:top-[2px] after:left-[2px] after:h-4 after:w-4',
          md: 'w-11 h-6 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5',
          lg: 'w-14 h-7 after:absolute after:top-0.5 after:left-[4px] after:h-6 after:w-6',
        },
      },
    };
  }, []);
  return (
    <ToggleSwitch
      disabled={disabled}
      color="primary"
      theme={theme}
      checked={checked}
      onChange={onChange}
      sizing={size}
    />
  );
}

export default AppSwitchInput;
