import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppCloseButton from '../../../../../components/AppCloseButton';
import { AppHeader, AppIcon } from '../../../../../components/html/html';
import { settingsPopupContent } from '../../../../../helpers/constants';
import {
  hideSettingsPopupDataAction,
  setCurrentSettingsPopupContent,
} from '../../../../../redux/CardDetailsReducer/CardDetailsActions';

function SettingsPopupHeader() {
  const { currentSettingsPopupContent } = useSelector((state) => state.cardDetails);
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const showBackBtn = useMemo(() => {
    const {
      changeStatus,
      cardControl,
      changeColorOrName,
      suspendCard,
      terminateCard,
      showPinNumber,
      changePinNumber,
    } = settingsPopupContent;

    const showBackPages = {
      [changeStatus]: true,
      [cardControl]: true,
      [changeColorOrName]: true,
      [suspendCard]: true,
      [terminateCard]: true,
      [showPinNumber]: true,
      [changePinNumber]: true,
    };

    return showBackPages[currentSettingsPopupContent];
  }, [currentSettingsPopupContent]);

  const showSettingHeader = useMemo(() => {
    const isSettingList = currentSettingsPopupContent === settingsPopupContent.settingsList;
    return isSettingList;
  }, [currentSettingsPopupContent]);

  return (
    <div className="flex items-center">
      {showSettingHeader && <AppHeader h="h5">{t('Settings')}</AppHeader>}

      {showBackBtn && (
        <button
          className="hover:text-primary rtl:rotate-180"
          type="button"
          onClick={() => {
            dispatch(setCurrentSettingsPopupContent(settingsPopupContent.settingsList));
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-arrow-left" />
        </button>
      )}

      <AppCloseButton
        className="ms-auto text-black"
        onClick={() => {
          dispatch(hideSettingsPopupDataAction());
        }}
      />
    </div>
  );
}

export default SettingsPopupHeader;
