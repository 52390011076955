export const showModalAction = (data) => (dispatch) => {
  dispatch({
    type: 'SHOW_MODAL',
    payload: data,
  });
};

export const hideModalAction = () => (dispatch) => {
  dispatch({
    type: 'HIDE_MODAL',
  });
};
