import { useSelector } from 'react-redux';
import ReceiptAttachments from '../../../../../../components/SharedComponents/ReceiptAttachments';
import ReceiptBalanceAndStatus from '../../../../../../components/SharedComponents/ReceiptBalanceAndStatus';
import ReceiptCardDetails from '../../../../../../components/SharedComponents/ReceiptCardDetails';
import ReceiptMerchantData from '../../../../../../components/SharedComponents/ReceiptMerchantData';
import ReceiptUpload from '../../../../../../components/SharedComponents/ReceiptUpload';
import ReceiptUploadAndAttachmentsPrint from '../../../../../../components/SharedComponents/ReceiptUploadAndAttachmentsPrint';
import SplitReceipt from '../../../../../../components/SharedComponents/SplitReceipt';
import { inPages, walletTabs } from '../../../../../../helpers/constants';
import {
  getWalletDepartmentsAction,
  getWalletDocTypesAction,
  getWalletTagsAction,
} from '../../../../../../redux/WalletReducer/WalletActions';

function WalletExpensesAndRefundPopupContent() {
  const {
    selectedWalletSubActiveTab,
    singleWalletTransactionDetails,
    walletTags,
    walletDocTypes,
    walletDepartments,
    showWalletSections,
  } = useSelector((state) => state.wallet);
  return (
    <>
      <div>
        <ReceiptBalanceAndStatus transactionDetails={singleWalletTransactionDetails} />
      </div>

      <div>
        <div className="flex justify-between gap-4">
          <div>
            <ReceiptMerchantData
              activeTab={selectedWalletSubActiveTab}
              transactionDetails={singleWalletTransactionDetails}
              tags={walletTags}
              getTagsAction={getWalletTagsAction}
              inPage={inPages.wallet}
            />
          </div>
          <div>
            <ReceiptUpload
              transactionDetails={singleWalletTransactionDetails}
              inPage={inPages.wallet}
            />
          </div>
        </div>
      </div>

      <div>
        <ReceiptCardDetails
          transactionDetails={singleWalletTransactionDetails}
          inPage={inPages.wallet}
        />
      </div>

      <div>
        <ReceiptAttachments
          transactionDetails={singleWalletTransactionDetails}
          docTypes={walletDocTypes}
          showSections={showWalletSections}
          inPage={inPages.wallet}
          getDocTypesAction={getWalletDocTypesAction}
        />
      </div>

      <div className="hidden print:block">
        <ReceiptUploadAndAttachmentsPrint transactionDetails={singleWalletTransactionDetails} />
      </div>

      {selectedWalletSubActiveTab === walletTabs.EXPENSES && (
        <div>
          <SplitReceipt
            tags={walletTags}
            departments={walletDepartments}
            transactionDetails={singleWalletTransactionDetails}
            showSections={showWalletSections}
            getDepartmentsAction={getWalletDepartmentsAction}
            getTagsAction={getWalletTagsAction}
            inPage={inPages.wallet}
          />
        </div>
      )}
    </>
  );
}

export default WalletExpensesAndRefundPopupContent;
