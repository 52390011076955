import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../AppRouter/history';
import AppSpinner from '../../../components/AppSpinner';
import { AppBody, AppButton, AppHeader } from '../../../components/html/html';
import TranslatedData from '../../../components/TranslatedData';
import { showModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import { getUserInfoAction } from '../../../redux/ProfileReducer/ProfileActions';
import UserEditAddressPopup from './UserEditAddressPopup';

function PersonalInformationContent() {
  const { isMobile, lang } = useSelector((state) => state.appConfig);
  const { userInfo } = useSelector((state) => state.profile);
  const { t } = useTranslation('profileV2');
  const { loaders } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfoAction());
  }, [lang]);

  return (
    <>
      {loaders.getUserInfoAction && (
        <div className="h-[80vh] rounded-2xl border border-gray-5">
          <AppSpinner />
        </div>
      )}

      {!loaders.getUserInfoAction && (
        <div className="grid gap-6">
          {/* ============= Main Info ============= */}
          <div className="rounded-2xl border-gray-5 lg:border lg:p-5">
            <AppHeader h="h6">
              <div className="flex gap-1.5">
                <span>
                  <TranslatedData ar={userInfo?.first_name_ar} en={userInfo?.first_name} />
                </span>
                <span>
                  <TranslatedData ar={userInfo?.last_name_ar} en={userInfo?.last_name} />
                </span>
              </div>
            </AppHeader>
            <AppBody pClass="Body1Bold" className="text-gray-6">
              {userInfo?.job_title}
            </AppBody>
            {userInfo?.address?.city && userInfo?.address?.country && (
              <AppBody pClass="Body1Bold" className="text-gray-6">
                {userInfo?.address?.city?.name} / {userInfo?.address?.country?.name}
              </AppBody>
            )}
          </div>

          {/* ============= Personal Information ============= */}
          <div className="rounded-2xl border border-gray-5 p-5">
            <AppHeader h="h6" className="mb-8">
              {t('Personal Information', { ns: 'commonV2' })}
            </AppHeader>
            <div className="grid gap-8 lg:grid-cols-2">
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('First Name', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">
                  <TranslatedData ar={userInfo?.first_name_ar} en={userInfo?.first_name} />
                </AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Last Name', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">
                  <TranslatedData ar={userInfo?.last_name_ar} en={userInfo?.last_name} />
                </AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Email Address', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.email}</AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Phone Number', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.mobile}</AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Job Title', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.job_title}</AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Department', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">
                  <TranslatedData
                    ar={userInfo?.department?.name_ar}
                    en={userInfo?.department?.name}
                  />
                </AppBody>
              </div>

              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('User Type', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">
                  {!!userInfo?.roles?.length && t(userInfo.roles[0], { ns: 'commonV2' })}
                </AppBody>
              </div>
            </div>
          </div>

          {/* ============= Address ============= */}
          <div className="rounded-2xl border border-gray-5 p-5">
            <div className="flex justify-between">
              <AppHeader h="h6" className="mb-8">
                {t('Address', { ns: 'commonV2' })}
              </AppHeader>

              <AppButton
                button="link"
                className="font-bold text-primary"
                bClass="ButtonHeader1"
                onClick={() => {
                  if (!isMobile) {
                    dispatch(showModalAction(<UserEditAddressPopup />));
                  } else {
                    history.push('/dashboard/profile/user/edit-address');
                  }
                }}
              >
                {t('Edit', { ns: 'commonV2' })}
              </AppButton>
            </div>

            <div className="grid gap-8 lg:grid-cols-2">
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Country', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.address?.country?.name}</AppBody>
              </div>
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('City/State', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.address?.city?.name}</AppBody>
              </div>
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Postal Code', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.address?.postal_code}</AppBody>
              </div>
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Address Line 1', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.address?.address1}</AppBody>
              </div>
              <div>
                <AppBody pClass="Body2Bold" className="text-gray-6">
                  {t('Address Line 2', { ns: 'commonV2' })}
                </AppBody>
                <AppBody pClass="Body1Bold">{userInfo?.address?.address2}</AppBody>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PersonalInformationContent;
