import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import ForgetPassword from './modules/Auth/ForgetPassword';
import LoginPage from './modules/Auth/LoginPage';
import Logout from './modules/Auth/Logout';
import QuickSignUp from './modules/Auth/QuickSignUp';
import ResetPassword from './modules/Auth/ResetPassword';
import ResetVerifyPhone from './modules/Auth/ResetVerifyPhone';
import Signup from './modules/Auth/signup';
import VerifyCompany from './modules/Auth/signup/Company/VerifyCompany';
import CompleteSignup from './modules/Auth/signup/CompleteSignup';
import IdentityVerification from './modules/Auth/signup/Identity/IdentityVerification';
import AcceptTerms from './modules/Auth/signup/OwnerInfo/AcceptTerms';
import BusinessInfo from './modules/Auth/signup/OwnerInfo/BusinessInfo';
import CompanyAddress from './modules/Auth/signup/OwnerInfo/CompanyAddress';
import FundingMethod from './modules/Auth/signup/OwnerInfo/FundingMethod';
import SecondContact from './modules/Auth/signup/OwnerInfo/SecondContact';
import SignupUserDetails from './modules/Auth/signup/SignupUserDetails';
import ForgetPasswordThanks from './modules/Auth/signup/Thank/ForgetPasswordThanks';
import RegistrationStep1ThankYou from './modules/Auth/signup/Thank/RegistrationStep1ThankYou';
import RegistrationThankYou from './modules/Auth/signup/Thank/RegistrationThankYou';
import VerifyEmail from './modules/Auth/signup/VerifyEmail';
import VerifyEmailStep from './modules/Auth/signup/VerifyEmailStep';
import VerifyPhone from './modules/Auth/signup/VerifyPhone';
import UserForgetPassword from './modules/Auth/UserForgetPassword';
import Dashboard from './modules/Dashboard';
import EmployeeIdentityVerification from './modules/EmployeeRegistration/EmployeeIdentityVerification';
import EmployeeTermsAndConditions from './modules/EmployeeRegistration/EmployeeTermsAndConditions';
import EmployeeRegistration from './modules/EmployeeRegistration/index';
import NotFound from './modules/NotFound';
import UploadDocuments from './modules/UploadDocuments';

function AppRoutes() {
  const { userToken } = useSelector((state) => state.auth);

  return (
    <main className="h-[90vh] pt-5 lg:h-[92vh] lg:pt-0">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<NotFound />} />

        {/* =================== Quick Signup =================== */}
        <Route path="signup">
          <Route path="quick-signup" element={<QuickSignUp />} />
        </Route>

        {/* =================== Employee Signup =================== */}
        <Route path="signup">
          <Route path="verify-employee-identity" element={<EmployeeIdentityVerification />} />
          <Route path="employee-accept-terms" element={<EmployeeTermsAndConditions />} />
        </Route>

        <Route path="signup" element={<Signup />}>
          {userToken && (
            <>
              <Route path="verify_phone" element={<VerifyPhone />} />
              <Route path="complete" element={<CompleteSignup />} />
              <Route path="verify_email_resend" element={<VerifyEmailStep />} />
              <Route path="verify_identity_employee" element={<IdentityVerification />} />
              <Route path="verify_identity" element={<IdentityVerification isOwner />} />
              <Route path="verify_company" element={<VerifyCompany />} />
              <Route path="verify_company/business_info" element={<BusinessInfo />} />
              <Route path="verify_company/company_address" element={<CompanyAddress />} />
              <Route path="verify_company/second_contact" element={<SecondContact />} />
              <Route path="verify_company/funding_method" element={<FundingMethod />} />
              <Route path="verify_company/accept_terms" element={<AcceptTerms isOwner />} />
              <Route path="accept_terms" element={<AcceptTerms />} />
              <Route path="contact_thanks" element={<RegistrationThankYou isContact />} />
              <Route path="thanks" element={<RegistrationThankYou />} />
              <Route path="thanks_step1" element={<RegistrationStep1ThankYou />} />
            </>
          )}
          <>
            <Route path="details" element={<SignupUserDetails />} />
            <Route path="verify_email/:token" element={<VerifyEmail />} />
          </>
        </Route>

        {/* =================== Employee Registration =================== */}
        <Route path="complete-registration/:employeeToken" element={<EmployeeRegistration />} />

        {/* =================== Verify Phone =================== */}
        <Route path="reset_verify_phone/:token" element={<ResetVerifyPhone />} />

        {/* =================== Reset/Forget Password =================== */}
        <Route path="forget-password" element={<UserForgetPassword />} />
        <Route path="forget_password" element={<ForgetPassword />} />
        <Route path="forget_password_thanks" element={<ForgetPasswordThanks />} />
        <Route path="reset_password/:token" element={<ResetPassword />} />

        {/* =================== Auth =================== */}
        <Route path="auth">
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<Logout />} />
        </Route>

        {/* =================== Dashboard =================== */}
        {userToken && <Route path="dashboard/*" element={<Dashboard />} />}

        {/* =================== Upload Documents =================== */}
        <Route path="compliance">
          <Route path=":accountId/:token" element={<UploadDocuments />} />
        </Route>
      </Routes>
    </main>
  );
}

export default AppRoutes;
