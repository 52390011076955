const INITIAL_STATE = {
  show: false,
};

const AppToasterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_TOASTER': {
      return { ...state, ...action.payload };
    }
    case 'HIDE_TOASTER': {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default AppToasterReducer;
