import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactCodeInput from 'react-verification-code-input';
import { HeadlineCenterAlways } from '../../../components/Titles';
import { apiPatchRequest, apiPostFormDataRequest } from '../../../helpers/APIHelper';
import { setAction } from '../../../helpers/ActionsHelper';
import { refreshUserData } from '../../../helpers/UserHelper';
import useNotifications from '../../../helpers/useNotifications';

import ActionButton from '../../../components/Button/ActionButton';
import LinkButton from '../../../components/Button/LinkButton';
import CustomPhone from '../../../components/FormInputs/CustomPhone';
import LoadingError from '../../../components/LoadingError';
import RegisterStepBar from '../../../components/Stepbar/RegisterStepBar';
import Timer from '../../../components/Timer';
import { AppBody } from '../../../components/html/html';
import {
  SET_REGISTRATION_SMS_TOKEN,
  SET_USER_CAPABILITIES,
  SET_USER_DATA,
} from '../../../redux/AuthReducer';

function VerifyPhone() {
  const { t } = useTranslation(['signup', 'common']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { registration, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [, addNotification] = useNotifications();
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [complete, setComplete] = useState(false);
  const codeRef = useRef();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: 'onBlur' });
  const [editPhone, setEditPhone] = useState(false);
  const [phone, setPhone] = useState(user && user.mobile);

  const editPhoneHandler = () => {
    setEditPhone(true);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('mobile', data.mobile);
    setIsLoading(true);
    apiPatchRequest('users/phone', formData, cookies.token)
      .then((response) => {
        addNotification(t('verify_phone.phone_changed'));
        setEditPhone(false);
        setPhone(response.mobile ?? '');
        dispatch(setAction(SET_USER_DATA, response));
        setIsLoading(false);
      })
      .catch((err) => {
        setEditPhone(false);
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setIsLoading(false);
      });
  };

  function clear() {
    codeRef.current.state.values[0] = '';
    codeRef.current.state.values[1] = '';
    codeRef.current.state.values[2] = '';
    codeRef.current.state.values[3] = '';
    codeRef.current.state.values[4] = '';
  }

  const sendOtp = () => {
    clear();
    apiPostFormDataRequest('users/otp/send', null, cookies.token)
      .then((response) => {
        dispatch(setAction(SET_REGISTRATION_SMS_TOKEN, response.token));
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };

  useEffect(() => {
    sendOtp();
  }, [editPhone]);

  const handleCompletion = (code) => {
    setComplete(true);

    const { smsToken } = registration;
    const registrationForm = new FormData();
    setIsSubmitting(true);
    registrationForm.append('token', smsToken);
    registrationForm.append('code', code);

    apiPostFormDataRequest('users/otp/verify', registrationForm, cookies.token)
      .then(() => {
        refreshUserData(cookies.token)
          .then(() => {
            navigate('/signup/verify_email_resend');
          })
          .catch((err) => {
            if (err.status && err.status === 401) {
              dispatch(setAction('RESET_APP', null));
              dispatch(setAction(SET_USER_DATA, null));
              dispatch(setAction(SET_USER_CAPABILITIES, null));
            }
          });
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
        setComplete(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="mx-auto flex w-full flex-col lg:w-8/12">
        <RegisterStepBar step={2} />
        <div className="flex flex-col text-center">
          <HeadlineCenterAlways>{t('verify_phone.headline')}</HeadlineCenterAlways>
          <AppBody type="span" pClass="Body2Regular" className="pb-4 pt-2 text-center text-gray-6">
            {t('verify_phone.subtitle')}
          </AppBody>
          <div className="text-md text-gray-6" style={{ direction: 'ltr' }}>
            {!editPhone && (
              <span style={{ direction: 'ltr' }}>
                {phone}
                <LinkButton
                  className="inlineBlock px-5 text-primary"
                  text={t('verify_phone.edit')}
                  onClick={editPhoneHandler}
                />
              </span>
            )}
            {editPhone && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-8 flex max-w-sm flex-grow flex-col items-start justify-start self-stretch"
                style={{ margin: '0 auto' }}
              >
                <div className="flex w-full flex-col justify-start">
                  <div className="flex flex-grow flex-row self-start self-stretch">
                    <AppBody
                      type="label"
                      pClass="Body1Bold"
                      htmlFor="mobile"
                      className="flex flex-grow flex-col"
                    >
                      <CustomPhone
                        name="mobile"
                        control={control}
                        errors={errors.mobile}
                        patternError={t('details.validation.patterns.mobile')}
                        requiredString={t('details.validation.required.mobile')}
                      />
                    </AppBody>
                  </div>
                </div>
                <div className="flex flex-row justify-end self-stretch pt-2">
                  <ActionButton
                    text={t('verify_phone.resend')}
                    type="submit"
                    className="flex flex-row text-primary"
                    isLoading={isLoading}
                  />
                </div>
              </form>
            )}
          </div>
          {isLoading && <Skeleton containerClassName="pt-5" className="pt-5" count={3} />}
          {!isLoading && <LoadingError show={errored} retryCallback={sendOtp} />}
          {!isLoading && !errored && (
            <div className="directionLTR flex flex-col pt-10">
              <AppBody pClass="Body1Bold">{t('verify_phone.enter_code')}</AppBody>
              <ReactCodeInput
                ref={codeRef}
                fields={5}
                type="number"
                className="verify-code mt-5 self-center"
                loading={isSubmitting}
                onComplete={(code) => {
                  if (!complete) {
                    handleCompletion(code);
                  }
                }}
              />

              <Timer isLoading={isLoading} sendOtp={sendOtp} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyPhone;
