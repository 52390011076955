import { useDispatch, useSelector } from 'react-redux';
import { setAction } from './ActionsHelper';
import { ADD_NOTIFICATION, READ_NOTIFICATION } from '../redux/NotificationsReducer';

const useNotifications = () => {
  const { notifications } = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  const addNotification = (body, success = true) => {
    dispatch(
      setAction(ADD_NOTIFICATION, {
        body,
        success,
      }),
    );
  };

  const readNotification = (index) => {
    dispatch(setAction(READ_NOTIFICATION, index));
  };

  return [notifications, addNotification, readNotification];
};

export default useNotifications;
