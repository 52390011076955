/* eslint-disable no-undefined */
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppInput from '../../../../../../../components/AppFormInputs/AppInput';
import AppImageComponent from '../../../../../../../components/AppImageComponent';
import { AppBody, AppButton, AppHeader } from '../../../../../../../components/html/html';
import { devLog } from '../../../../../../../helpers/Utils';
import { settingsPopupContent } from '../../../../../../../helpers/constants';
import { setCurrentSettingsPopupContent } from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function VerifyPlasticLastFour() {
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const { t } = useTranslation(['cardsV2']);
  const Xes = 'XXXX-XXXX-XXXX-';
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState(Xes);
  const [lastFourValue, setLastFourValue] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const dispatch = useDispatch();

  const cardLastFour = useMemo(() => {
    return settingsPopupCardDetails?.last_four;
  }, [settingsPopupCardDetails]);

  const handleChange = useCallback(
    (e) => {
      const lastFour = e.target.value.split(Xes)[1]?.slice(0, 4);
      if (isError) {
        setIsError(false);
      }

      if (lastFour !== undefined) {
        setLastFourValue(lastFour);
        setInputValue(Xes + lastFour);
      }
    },
    [isError],
  );

  const handleVerify = useCallback(() => {
    setIsVerifying(true);

    setTimeout(() => {
      setIsVerifying(false);

      if (lastFourValue === cardLastFour) {
        dispatch(setCurrentSettingsPopupContent(settingsPopupContent.simplifiActivate));
      } else {
        devLog(`Card last 4 is [${cardLastFour}], but got [${lastFourValue}]`);
        setIsError(true);
      }
    }, 500);
  }, [lastFourValue]);

  return (
    <>
      <AppHeader h="h5" className="mb-3 text-center">
        <span>{t('Activate Your Card')}</span>
      </AppHeader>
      <div className="mx-auto w-72">
        <div className="relative">
          <AppImageComponent src={settingsPopupCardDetails.design.attachment} />
          <p className="absolute bottom-4 left-4 text-base font-thin text-white">
            xxxx-xxxx-xxxx-<span className="text-danger">xxxx</span>
          </p>
        </div>

        <AppHeader h="h6" className="my-3 text-center">
          <span className="text-base">{t('Enter the last 4 digits on your card')}</span>
        </AppHeader>

        <AppInput
          style={{ direction: 'ltr' }}
          className="bg-gray-1 font-normal"
          value={inputValue}
          onChange={handleChange}
          invalid={isError}
          showInvalidIcon={false}
        />
        {isError && (
          <AppBody pClass="Body2Regular" className="mb-2 text-danger">
            {t('The last four digits do not match')}
          </AppBody>
        )}

        <div className="mt-4 flex justify-center">
          <AppButton
            loadingLabel={t('Verifying...')}
            isLoading={isVerifying}
            disabled={lastFourValue.length < 4}
            className="w-1/2"
            size="md"
            onClick={handleVerify}
          >
            {t('Verify')}
          </AppButton>
        </div>
      </div>
    </>
  );
}

export default VerifyPlasticLastFour;
