import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BackBtn from '../../../../components/BackBtn';
import NqoodCardImg from '../../../../components/NqoodCardImg';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import {
  setActiveCreateNewCardStepAction,
  setUserAgreeTermsAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';

function CreatePrimaryCard() {
  const { useMock } = useSelector((state) => state);
  const { createCardUserData, userAgreeTerm, selectedCardProgram, selectedCardType } = useSelector(
    (state) => state.createNewCard,
  );
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const isComingFromCreateOrReplaceStep = useMemo(() => {
    // user selected replace physical card
    if (
      (selectedCardType.name === cardTypesKeys.PHYSICAL &&
        selectedCardProgram.physical_can_create_and_replace) ||
      useMock.plasticCreateAndReplaceMock
    ) {
      return true;
    }

    // user selected replace virtual card
    if (
      (selectedCardType.name === cardTypesKeys.VIRTUAL &&
        selectedCardProgram.virtual_can_create_and_replace) ||
      useMock.virtualCreateAndReplaceMock
    ) {
      return true;
    }

    return false;
  }, [selectedCardType, selectedCardProgram, useMock, cardTypesKeys]);

  return (
    <div className="mx-auto mt-[10vh] w-full p-5 sm:max-w-[500px]">
      <div className="mb-5">
        <BackBtn
          className="mb-4"
          onClick={() => {
            if (isComingFromCreateOrReplaceStep) {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createOrReplaceCard));
            } else {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardType));
            }
          }}
        />
        <AppHeader h="h3">{t("Let's Create Your Primary Card")}</AppHeader>
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t(
            'This will be your primary card, you will be able to issue a plastic card Only on top of it',
          )}
        </AppBody>
      </div>

      <div className="relative mx-auto mb-4 max-w-[350px] overflow-hidden rounded-2xl shadow">
        <NqoodCardImg
          bankLogo={selectedCardProgram?.funding_source_type?.bank_logo}
          scheme={selectedCardProgram.scheme}
          skeletonWidth={350}
          skeletonHeight={220}
          alt="Card"
        />
        <span className="absolute bottom-5 left-5">
          <span className="block font-bold">{`${createCardUserData?.first_name} ${createCardUserData?.last_name}`}</span>
        </span>
      </div>

      <div>
        <label className="flex gap-1">
          <input
            type="checkbox"
            className="mt-1 h-5 w-5 rounded checked:bg-black focus:ring-0"
            checked={userAgreeTerm || ''}
            onChange={(e) => {
              dispatch(setUserAgreeTermsAction(e.target.checked));
            }}
          />
          <span>
            {t(
              'I am aware that I will be restricted to having only one plastic card per card program associated with my account. and I understand that this plastic card will be linked to this virtual card using the same balance',
            )}
          </span>
        </label>
      </div>

      <div className="mt-4 flex justify-end gap-3 pb-5">
        <CancelBtn />

        <AppButton
          disabled={!userAgreeTerm}
          onClick={() => {
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.customizePrimaryCard));
          }}
          isLoading={false}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default CreatePrimaryCard;
