/* eslint-disable no-unsafe-optional-chaining */
import { useSelector } from 'react-redux';
import WalletFilterPopupContent from './WalletFilterPopupContent';
import WalletFilterPopupHeader from './WalletFilterPopupHeader';

function WalletFilterPopup({ onApply, filterTags }) {
  const { showWalletFilterPopup } = useSelector((state) => state.wallet);

  return (
    showWalletFilterPopup && (
      <div className="top-[41vh] z-30 max-h-[90vh] min-h-[25vh] w-96 overflow-y-auto rounded-2xl border border-gray-3 bg-white p-5 pt-0 shadow lg:absolute lg:end-0 lg:top-12 lg:max-h-[50vh]">
        {/* Header */}
        <div className="sticky top-0 z-10 mb-2 bg-white pb-3 pt-5">
          <WalletFilterPopupHeader />
        </div>

        {/* Content */}
        <div>
          <WalletFilterPopupContent filterTags={filterTags} onApply={onApply} />
        </div>
      </div>
    )
  );
}

export default WalletFilterPopup;
