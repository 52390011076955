import { getURLParams } from 'javascript-functions';
import { useTranslation } from 'react-i18next';
import NqoodCardImg from '../../../../components/NqoodCardImg';
import { AppHeader, AppBody } from '../../../../components/html/html';
import ContactUs from './ContactUs';

function VirtualCardActivationFailed() {
  const { t } = useTranslation('cardsV2');

  return (
    <div className="mx-auto mt-[25vh] max-w-[350px] px-5 pb-5 sm:px-0">
      <AppHeader h="h3">{t('Card Activation Failed')}</AppHeader>

      <AppBody pClass="Body2Medium" className="text-gray-6">
        {t('Please contact customer support team')}
      </AppBody>

      <div className="my-5 overflow-hidden rounded-md shadow-xl blur-[2px] grayscale">
        <NqoodCardImg hideLogos skeletonWidth={350} skeletonHeight={226} alt="Failed Card" />
      </div>

      <ContactUs emailSubject="Card Activation Failed" emailError={getURLParams('creationError')} />
    </div>
  );
}

export default VirtualCardActivationFailed;
