import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isFilePDF } from '../../helpers/Utils';
import { inPages } from '../../helpers/constants';
import usePermissions from '../../hooks/usePermissions';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import {
    getSingleTransactionDetailsAction,
    updateCardTransactionsAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';
import {
    getSingleWalletTransactionDetailsAction,
    updateWalletTransactionsAction,
} from '../../redux/WalletReducer/WalletActions';
import AppUpload from '../FormInputs/AppUpload';
import PreviewButton from '../FormInputs/AppUpload/PreviewButton';

function ReceiptUpload({ transactionDetails, inPage }) {
  const { loaders } = useSelector((state) => state);
  const [uploadedFilePreview, setUploadedFilePreview] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const userCan = usePermissions();
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const canUpload = useMemo(() => {
    const userCanUploadReceipt =
      userCan('upload_account_receipt') || userCan('edit_account_transaction_receipt');

    const userCanUploadOwnReceipt =
      (userCan('upload_own_transaction_receipt') || userCan('edit_own_transaction_receipt')) &&
      auth.user?.id === transactionDetails.user_id?.id;

    const transactionCanUploadReceipt = transactionDetails.can_upload_reciept;

    return (userCanUploadReceipt || userCanUploadOwnReceipt) && transactionCanUploadReceipt;
  }, [transactionDetails, auth]);

  const handleUploadReceipt = (file) => {
    setUploadError(false);

    const formBody = new FormData();
    formBody.append('receipts[]', file);
    formBody.append('transaction_id', transactionDetails.id);
    formBody.append('doc_type_id', 1);

    http
      .post('transactions/receipts', formBody, { loader: 'handleUploadReceipt' })
      .then((res) => {
        res.data.receipts.forEach((ele) => {
          if (ele.doc_type === 'Receipt') {
            setUploadedFilePreview(ele);
          }
        });

        dispatch(
          showToasterAction({
            type: 'success',
            message: t('The receipt was updated successfully!'),
          }),
        );
        if (inPage === inPages.myCards) {
          dispatch(updateCardTransactionsAction(res.data));
          dispatch(getSingleTransactionDetailsAction(transactionDetails.id));
        }
        if (inPage === inPages.wallet) {
          dispatch(updateWalletTransactionsAction(res.data));
          dispatch(getSingleWalletTransactionDetailsAction(transactionDetails.id));
        }
      })
      .catch((err) => {
        setUploadError(err.data.error);
      });
  };

  useEffect(() => {
    if (transactionDetails) {
      transactionDetails.receipts.forEach((ele) => {
        if (ele.doc_type === 'Receipt') {
          setUploadedFilePreview(ele);
        }
      });
    }
  }, [transactionDetails]);

  return (
    <div className="print:hidden">
      {canUpload && (
        <AppUpload
          width={130}
          placeHolder={
            <div className="flex gap-1 text-[8px] font-bold">
              <span>{t('Upload the')}</span>
              <span className="text-primary-dark">{t('receipt')}</span>
            </div>
          }
          onChange={({ FILE }) => {
            handleUploadReceipt(FILE);
          }}
          uploadedFilePreview={uploadedFilePreview}
          isLoading={loaders.handleUploadReceipt}
          uploadError={uploadError}
        />
      )}

      {!canUpload && uploadedFilePreview && (
        <div className="h-[150px] w-[150px] overflow-hidden rounded-lg border-2 border-gray-3">
          <PreviewButton href={uploadedFilePreview.path} isPDF={isFilePDF(uploadedFilePreview)} />
        </div>
      )}
    </div>
  );
}

export default ReceiptUpload;
