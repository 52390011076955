import { getURLParams } from 'javascript-functions';
import { devLog, getServerErrMessage } from '../../helpers/Utils';
import { http } from '../../http';
import { showToasterAction } from '../AppToastersReducer/AppToastersActions';
import store from '../store';

export const setActiveCreateNewCardStepAction = (step) => (dispatch) => {
  dispatch({
    type: 'ADD_ACTIVE_CREATE_NEW_CARD_STEP',
    payload: step,
  });
};

export const getCardProgramsAction = () => (dispatch) => {
  devLog(`Getting card programs...`);
  const loggedInUserId = store.getState().auth.user.id;
  const userId = getURLParams('createCardUserId') || loggedInUserId;
  http
    .get(`cardmanager/cardproducts/creation`, {
      loader: `getCardProgramsAction`,
      params: { status: 'ACTIVE', user_id: userId },
    })
    .then((res) => {
      dispatch({
        type: 'ADD_CARD_PROGRAMS',
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: getServerErrMessage(err),
        }),
      );
    });
};

export const selectCardTypeAction = (cardType) => (dispatch) => {
  dispatch({
    type: 'SELECT_CARD_TYPE',
    payload: cardType,
  });
};

export const setUserHasCardHolder =
  ({ userHasCardHolder }) =>
  (dispatch) => {
    dispatch({
      type: 'SET_HAS_CARD_HOLDER',
      payload: userHasCardHolder,
    });
  };

export const selectCardDesignAction = (cardDesign) => (dispatch) => {
  dispatch({
    type: 'SELECT_CARD_DESIGN',
    payload: cardDesign,
  });
};

export const addOTPDataAction = (otpData) => (dispatch) => {
  dispatch({
    type: 'ADD_OTP_DATA',
    payload: otpData,
  });
};

export const saveNewCreatedCardAction = (createdCard) => (dispatch) => {
  dispatch({
    type: 'SAVE_NEW_CREATED_CARD',
    payload: createdCard,
  });
};

export const clearAllCreateNewCardStateAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_ALL',
  });
};

export const setPendingVirtualCardAction = (pendingPrimaryVirtualCard) => (dispatch) => {
  dispatch({
    type: 'SET_PENDING_VIRTUAL_CARD',
    payload: pendingPrimaryVirtualCard,
  });
};

export const setUserPrimaryCardAction = (primaryCard) => (dispatch) => {
  dispatch({
    type: 'SET_USER_PRIMARY_CARD',
    payload: primaryCard,
  });
};

export const getCreateCardUserDataAction = (createCardUserId) => (dispatch) => {
  if (createCardUserId) {
    devLog(`Getting [createCardUserId=${createCardUserId}] User Data...`);
    http
      .get(`employees`, {
        loader: `getTargetedUserData`,
        params: { id: createCardUserId },
      })
      .then((res) => {
        dispatch({
          type: 'SET_CREATE_CARD_USER_DATA',
          payload: res.data[0],
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  } else {
    devLog(`No [createCardUserId] User, Using Logged In User Data...`);
    const loggedInUserData = store.getState().auth.user;
    dispatch({
      type: 'SET_CREATE_CARD_USER_DATA',
      payload: loggedInUserData,
    });
  }
};

export const setShippingAddressAction = (address) => (dispatch) => {
  dispatch({
    type: 'SET_SHIPPING_ADDRESS',
    payload: address,
  });
};

export const setUserAgreeTermsAction = (flag) => (dispatch) => {
  dispatch({
    type: 'SET_USER_AGREE_TERM',
    payload: flag,
  });
};

export const setAvailableCardTypesAction = (availableCardTypes) => (dispatch) => {
  dispatch({
    type: 'SET_AVAILABLE_CARD_TYPES',
    payload: availableCardTypes,
  });
};

export const selectCardProgramAction = (cardProgram) => (dispatch) => {
  dispatch({
    type: 'SELECT_CARD_PROGRAM',
    payload: cardProgram,
  });

  if (cardProgram?.card_types.length) {
    devLog(`Card Program Selected!, Adding Available Card Types...`);
    dispatch(setAvailableCardTypesAction(cardProgram.card_types));
  }
};

export const selectCardToReplaceAction = (cardToReplace) => (dispatch) => {
  dispatch({
    type: 'SELECT_CARD_TO_REPLACE',
    payload: cardToReplace,
  });
};
