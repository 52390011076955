import { useSelector } from 'react-redux';

const usePermissions = () => {
  const userPermissions = useSelector((state) => state.auth.capabilities);

  const userCan = (permission) => {
    return userPermissions && userPermissions[permission];
  };

  return userCan;
};

export default usePermissions;
