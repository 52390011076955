import { AppBody } from '../../components/html/html';
import { isDev, isTesting } from '../../helpers/Utils';

function TestingAlter() {
  return (
    (isTesting() || isDev()) && (
      <AppBody
        type="div"
        pClass="Caption1Regular"
        className="mt-2 rounded bg-yellow-100 px-1 text-yellow-700"
      >
        You are in the Test Server
      </AppBody>
    )
  );
}

export default TestingAlter;
