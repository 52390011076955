/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undefined */
import Compressor from 'compressorjs';
import { getURLParams, getUniqueObjs, objToQueryStr, randomId } from 'javascript-functions';
import parsePhoneNumberFromString from 'libphonenumber-js';
import moment from 'moment';
import momentHijri from 'moment-hijri';
import 'moment/locale/ar-ly';
import 'moment/locale/en-gb';
import { useTranslation } from 'react-i18next';
import { history } from '../AppRouter/history';
import dummyPlaneCard from '../assets/png/dummyPlaneCard.png';
import dummyPlanePlasticCard from '../assets/png/dummyPlanePlasticCard.png';
import { cardStatusKeys, inPages } from './constants';

const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      if (c.substring(name.length, c.length) === 'ar') {
        return 'ar-ly';
      }

      return c.substring(name.length, c.length);
    }
  }
  return '';
};
export const hasAccessToken = (token) => token !== null && token !== undefined;
export const smartDateFormat = (timestamp) => {
  const { t } = useTranslation(['common']);

  return moment(new Date(timestamp * 1000).getTime())
    .locale(getCookie('lang') || 'en')
    .calendar(null, {
      sameDay: `[${t('today')}]`,
      nextDay: `[${t('tomorrow')}]`,
      nextWeek: 'dddd',
      lastDay: `[${t('yesterday')}]`,
      lastWeek: `dddd [${t('last')}]`,
      sameElse: 'DD/MM/YYYY',
    });
};

export const formatDate = (timestamp, format) =>
  moment(new Date(timestamp * 1000).getTime())
    .locale(getCookie('lang') || 'en')
    .format(format);

export const formattedJSON = (data) => {
  return JSON.stringify(data, null, 4);
};

export const toIntAndDecimalNumber = (amount) => {
  const integerPart = Math.trunc(amount).toLocaleString('en-US');
  const decimalPart = `.${(amount % 1).toFixed(2).split('.')[1]}` || '00';
  return { integerPart, decimalPart, fullNumber: integerPart + decimalPart };
};

export const handleCharacterLimit = (value, maxLength, event) => {
  const { key } = event;
  const currentLength = value.length;

  if (currentLength >= maxLength && key !== 'Backspace') {
    event.preventDefault();
  } else if (currentLength > maxLength) {
    return value.substring(0, maxLength + 1);
  }

  return value;
};

export const isFilePDF = (f) => {
  return f?.type?.split('/')[1] === 'pdf';
};

export const compressFile = (file) => {
  const resultToFile = (result) => {
    const f = new File([result], result.name, { type: result.type });
    f.path = file.path;
    f.preview = URL.createObjectURL(result);
    return f;
  };

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const fileFormate = resultToFile(result);
        resolve(fileFormate);
      },
      error(error) {
        reject(error);
      },
    });
  });
};

export const preventNonNumericalInput = (e) => {
  const E = e || window.event;
  const charCode = typeof E.which === 'undefined' ? E.keyCode : E.which;
  const charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9]+$/)) E.preventDefault();
};

export const translatedDataFunction = ({ lang, ar, en }) => {
  return lang === 'en' ? en : ar;
};

export const windowScrollTo = (id) => {
  const scrollEl = document.getElementById(id);
  scrollEl.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  });
};

export const elementScrollTo = (containerId, targetId) => {
  // Get the HTML element of the container div
  const containerDiv = document.getElementById(containerId);
  // Get the HTML element with the specified id within the container div
  const scrollEl = document.getElementById(targetId);

  if (containerDiv && scrollEl) {
    // Calculate the distance to scroll
    const offsetTop = scrollEl.offsetTop - containerDiv.offsetTop - 85;

    // Scroll the container div smoothly to the calculated distance
    containerDiv.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });
  }
};

export const isDev = () => {
  return process.env.REACT_APP_ENV === 'Develop';
};

export const isTesting = () => {
  return process.env.REACT_APP_ENV === 'Testing';
};

export const devLog = (...message) => {
  if (process.env.REACT_APP_ENV === 'Develop') {
    console.log(...message);
  }
};

export const downloadFile = ({ data, fileName, fileExtension }) => {
  // Create a Blob object from the provided data
  const blob = new Blob([data]);

  // Create a URL for the Blob object
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set attributes for the link
  link.href = url;
  link.download = `${fileName}.${fileExtension}`;

  // Append the link to the document body
  document.body.appendChild(link);

  // Simulate a click on the link to trigger the download
  link.click();

  // Clean up by removing the link and revoking the Blob URL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

// Getting the first day and the last day of a month in formate MM-DD based on a year
// coz for ex february might be 28 days or 29 days
export const getFirstAndLastDaysOfAMonthBasedOnAYear = (monthNumber, year) => {
  // Helper function to format a date as "MM-DD"
  function formatMonthDayWithYear(date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}-${day}`;
  }

  // Create a date object for the first day of the given month and year
  const firstDay = new Date(year, monthNumber - 1, 1);

  // Calculate the last day of the month
  const nextMonth = new Date(year, monthNumber, 0);
  const lastDay = nextMonth.getDate();

  // Format the dates as strings in "MM-DD" format
  const firstDayStr = formatMonthDayWithYear(firstDay);
  const lastDayStr = formatMonthDayWithYear(new Date(year, monthNumber - 1, lastDay));

  return [firstDayStr, lastDayStr];
};

export const formateDateToYYYYMMDD = (date, calendarType = 'Georgian') => {
  return (
    date &&
    momentHijri(new Date(date))
      .locale('en')
      .format(calendarType === 'Hijri' ? 'iYYYY-iMM-iDD' : 'YYYY-MM-DD')
  );
};

export const generateCardIdQueryParams = (cardDetails) => {
  const cardIdParams = {};

  if (cardDetails.physicalCard) {
    cardIdParams['card_ids[0]'] = cardDetails?.physicalCard?.id;
    cardIdParams['card_ids[1]'] = cardDetails?.virtualCard?.id;
  } else {
    cardIdParams.card_id = cardDetails?.virtualCard?.id;
  }

  return cardIdParams;
};

export const getServerErrMessage = (errObj) => {
  return errObj?.data?.error || errObj?.data?.message;
};

export const getImageDimensions = (src) => {
  const img = new Image();
  img.src = src;

  img.onload = () => {
    return {
      width: img.width,
      height: img.height,
    };
  };
};

export const generateMockCard = ({ status, design, isPhysical = false }) => {
  return {
    id: `mock-${randomId()}`,
    card_name: 'Mock Card',
    last_four: '0000',
    name_on_card: 'Mock Name On Card',
    is_physical: isPhysical,
    cardholder: {
      first_name: 'fMock',
      last_name: 'lMock',
    },
    status: {
      key: status || 'MOCK',
      name: 'Mock',
    },
    cardproduct: {
      id: '07775000',
      description: 'Mock Card Product Description',
    },
    design: design || {
      id: `mock-${randomId()}`,
      color_name: 'Mock Design Name',
      arabic_color_name: 'Mock Design Name',
      color_hex: '#179c56',
      attachment: isPhysical ? dummyPlanePlasticCard : dummyPlaneCard,
    },
  };
};

export const setLocalData = (name, data) => {
  return localStorage.setItem(name, JSON.stringify(data));
};

export const getLocalData = (name) => {
  if (localStorage.getItem(name)) {
    return JSON.parse(localStorage.getItem(name));
  }
  return null;
};

export const deleteLocalData = (name) => {
  return localStorage.removeItem(name);
};

export const generateReplaceCardURL = ({ cardId, inPage, singleUserDetails }) => {
  let pathName = '';
  let userId = null;
  // From My Cards Page
  if (inPage === inPages.myCards) {
    pathName = 'my_cards';
  }

  // From My Cards Page (Details Without SideBar)
  if (+getURLParams('userDetailsId')) {
    pathName = 'users';
    userId = +getURLParams('userDetailsId');
  }

  // From Users List Page
  if (inPage === inPages.singleUserCardDetails) {
    pathName = 'users';
    userId = singleUserDetails.id;
  }

  const querySearch = objToQueryStr({
    cardToReplaceId: cardId,
    userDetailsId: userId,
  });

  return `/dashboard/${pathName}/create-new-card?${querySearch}`;
};

export const sortCardsByCreatedDate = (cards) => {
  return cards.sort((a, b) => {
    return b.created_at - a.created_at;
  });
};

export const groupCards = (cards) => {
  let primaryCards = cards.filter((ele) => ele.card_mode === 'PRIMARY');
  primaryCards = sortCardsByCreatedDate(primaryCards);

  let activeCards = cards.filter((ele) => ele?.status?.key.toUpperCase() === cardStatusKeys.ACTIVE);
  activeCards = sortCardsByCreatedDate(activeCards);

  let inactiveCards = cards.filter(
    (ele) => ele?.status?.key.toUpperCase() === cardStatusKeys.INACTIVE,
  );
  inactiveCards = sortCardsByCreatedDate(inactiveCards);

  let suspendedCards = cards.filter(
    (ele) => ele?.status?.key.toUpperCase() === cardStatusKeys.SUSPENDED,
  );
  suspendedCards = sortCardsByCreatedDate(suspendedCards);

  let expiredCards = cards.filter(
    (ele) => ele?.status?.key.toUpperCase() === cardStatusKeys.EXPIRED,
  );
  expiredCards = sortCardsByCreatedDate(expiredCards);

  let terminatedCards = cards.filter(
    (ele) => ele?.status?.key.toUpperCase() === cardStatusKeys.TERMINATED,
  );
  terminatedCards = sortCardsByCreatedDate(terminatedCards);

  // Grouping
  return getUniqueObjs([
    ...primaryCards,
    ...activeCards,
    ...inactiveCards,
    ...suspendedCards,
    ...expiredCards,
    ...terminatedCards,
  ]);
};

export const getFilteredCards = (allCards) => {
  const notArchived = [];
  const archived = [];
  const related = {};

  allCards.forEach((ele) => {
    const currentCardStatus = ele.status?.key.toUpperCase();
    const cardProgramStatus = ele.cardproduct.status?.toUpperCase();
    const isClosedCardProgram = [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(
      cardProgramStatus,
    );

    if (currentCardStatus === cardStatusKeys.REPLACED || isClosedCardProgram) {
      if (ele.related_card) {
        related[ele.related_card] = ele;
      } else {
        archived.push(ele);
      }
    }

    if (
      currentCardStatus !== cardStatusKeys.PENDING &&
      currentCardStatus !== cardStatusKeys.REPLACED &&
      !isClosedCardProgram &&
      cardProgramStatus !== cardStatusKeys.HIDDEN
    ) {
      if (ele.related_card) {
        related[ele.related_card] = ele;
      } else {
        notArchived.push(ele);
      }
    }
  });

  return {
    related,
    notArchived: groupCards(notArchived),
    archived: sortCardsByCreatedDate(archived),
  };
};

export const initializeCardSelection = () => {
  const virtualCardId = getURLParams('virtualCardId');
  const physicalCardId =
    getURLParams('physicalCardId') === 'null' ? null : getURLParams('physicalCardId');

  devLog(`Checking cached card IDs in URL
    virtualCardId: [${virtualCardId}]
    physicalCardId: [${physicalCardId}]
  `);

  const virtualCard = virtualCardId ? { id: virtualCardId } : null;
  const physicalCard = physicalCardId ? { id: physicalCardId } : null;

  return { virtualCard, physicalCard };
};

export const generateCardName = (input) => {
  const words = input.split(' ').map((word) => {
    return word.substring(0, 12);
  });

  // && input.split(' ').length >= 12
  if (words.length > 2) {
    return words.slice(0, 2).join(' ');
  }

  return words.join(' ');
};

export const navigateDifferentUserCardIdsDetails = ({ cardId, cardUserId, loggedInUserId }) => {
  const sameUserIds = cardUserId === loggedInUserId;

  const inMyCardsPage = Boolean(getURLParams('virtualCardId'));

  if (inMyCardsPage && !sameUserIds) {
    devLog(
      '[[[[[ Card user ID and Logged in user ID are Different!, Navigating to User Card Details Page... ]]]]]',
    );
    history.push(`/dashboard/users/my_cards/${cardId}?userDetailsId=${cardUserId}`);
  }
};

export const monthsEnAndAr = [
  { name: 'January', name_ar: 'يناير', value: 1 },
  { name: 'February', name_ar: 'فبراير', value: 2 },
  { name: 'March', name_ar: 'مارس', value: 3 },
  { name: 'April', name_ar: 'ابريل', value: 4 },
  { name: 'May', name_ar: 'مايو', vlue: 5 },
  { name: 'June', name_ar: 'يونيو', value: 6 },
  { name: 'July', name_ar: 'يوليو', value: 7 },
  { name: 'August', name_ar: 'أغسطس', value: 8 },
  { name: 'September', name_ar: 'سبتمبر', value: 9 },
  { name: 'October', name_ar: 'اكتوبر', value: 10 },
  { name: 'November', name_ar: 'نوفمبر', value: 11 },
  { name: 'December', name_ar: 'ديسمبر', value: 12 },
];

export const validateMobileNumber = (mobileNumber) => {
  return parsePhoneNumberFromString(mobileNumber)?.isValid() || false;
};

export const restrictLanguageInput = (e, language) => {
  const regex = language === 'english' ? /^[A-Za-z\s]*$/ : /^[\u0600-\u06FF\s]*$/;
  if (!regex.test(e.key)) {
    e.preventDefault();
  }
};

export const isAccountReady = (userData) => {
  if (!userData) return false;

  const {
    phone_verified,
    email_verified,
    yakeen_verified,
    account_verified,
    account_id,
    business_verified,
    address_verified,
    accept_terms,
    account,
  } = userData;

  const basicVerifications =
    phone_verified && email_verified && yakeen_verified && account_verified;

  const businessRequirements = business_verified && address_verified && accept_terms;

  const validAccountStatus = account?.status === 'Activated' || account?.status === 'Suspended';

  if (!basicVerifications || account_id === 0) {
    return false;
  }

  return businessRequirements && validAccountStatus;
};

export const navigateUncompletedAccountTo = (userData) => {
  if (!userData) return '/signup/thanks';

  const {
    roles,
    phone_verified,
    email_verified,
    yakeen_verified,
    account_verified,
    business_verified,
    address_verified,
    accept_terms,
    contact,
    account,
  } = userData;

  const isAccountOwner = roles && roles[0] === 'Account-Owner';

  if (isAccountOwner) {
    if (!phone_verified) return '/signup/verify_phone';
    if (!email_verified) return '/signup/verify_email_resend';
    if (!yakeen_verified) return '/signup/verify_identity';
    if (!account_verified) return '/signup/verify_company';
    if (contact && userData.account_id === 0) return '/signup/contact_thanks';
    if (!business_verified) return '/signup/verify_company/business_info';
    if (!address_verified) return '/signup/verify_company/company_address';
    if (account?.status === 'Verifying') {
      if (!account.second_contact) return '/signup/verify_company/second_contact';
      if (!account.funding_method || account.funding_method.length === 0)
        return '/signup/verify_company/funding_method';
      if (!accept_terms) return '/signup/verify_company/accept_terms';
    }
    // ======= Employee Registration ======= //
  } else if (userData.account_verified) {
    // if (!yakeen_verified) return '/signup/verify_identity_employee';
    // if (!accept_terms) return '/signup/accept_terms';
    if (!yakeen_verified) return '/signup/verify-employee-identity';
    if (!accept_terms) return '/signup/employee-accept-terms';
  }

  return '/signup/thanks';
};
