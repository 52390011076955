import React from 'react';
import masterCardLogo from '../../assets/png/Mastercard-logo.png';
import visaCardLogo from '../../assets/png/Visa-logo.png';

function CardProgramSchemeLogo({ cardProgramScheme }) {
  if (cardProgramScheme === 'MASTER') {
    return <img src={masterCardLogo} alt="masterCard Logo" width={35} />;
  }
  if (cardProgramScheme === 'VISA') {
    return <img src={visaCardLogo} alt="visa Logo" width={35} />;
  }
}

export default CardProgramSchemeLogo;
