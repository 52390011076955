import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SectionTitle } from '../../../../components/Titles';
import TooltipPackage from '../../../../components/TooltipPackage';
import NonCompanyOwnerContactDetails from './NonCompanyOwnerContactDetails';
import { AppBody } from '../../../../components/html/html';
import { apiPostFormDataRequest } from '../../../../helpers/APIHelper';
import { setAction } from '../../../../helpers/ActionsHelper';
import { SET_USER_DATA } from '../../../../redux/AuthReducer';
import useNotifications from '../../../../helpers/useNotifications';

function CompanyOwnerData({ isManager, crNumber }) {
  const [uploadCR, setUploadCR] = useState(true);
  const { t } = useTranslation(['signup']);
  const [cookies] = useCookies(['token']);
  const [, addNotification] = useNotifications();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <AppBody
        type="span"
        pClass="Body2Regular"
        className="centerTitle relative m-auto max-w-md pb-4 pt-2 text-center text-gray-6"
      >
        {t('company_account.subtitle')}
        <TooltipPackage tooltipMessage={t('company_account.subtitle_more')} id="infoPreview" />
      </AppBody>

      <SectionTitle>{t('company_account.is_manager')}</SectionTitle>
      <div className="flex flex-row items-start pt-5">
        <div className="flex flex-row items-baseline ltr:pl-12 rtl:pr-12">
          <div className="radio-item">
            <input
              type="radio"
              className="scale-125 transform"
              name="non_owner_option"
              id="non_owner_option1"
              defaultChecked
              onChange={(evt) => {
                if (evt.target.checked) {
                  const form = new FormData();
                  form.append('cr_number', crNumber);
                  form.append('is_manager', +isManager);
                  apiPostFormDataRequest('accounts', form, cookies.token, {
                    'Content-Type': 'multipart/form-data',
                  })
                    .then((response) => {
                      user.account_id = response.id;
                      dispatch(setAction(SET_USER_DATA, user));
                      navigate('../thanks_step1');
                    })
                    .catch((err) => {
                      addNotification(
                        (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
                        false,
                      );
                    });
                }
              }}
            />
            <label htmlFor="non_owner_option1">
              <AppBody pClass="Body2bold" type="span">
                {t('company_account.yes_manager')}
              </AppBody>
            </label>
          </div>
        </div>

        <div className="flex flex-row items-baseline ltr:pl-12 rtl:pr-12">
          <div className="radio-item">
            <input
              type="radio"
              className="scale-125 transform ltr:pr-2 rtl:pl-2"
              name="non_owner_option"
              id="non_owner_option2"
              onChange={(evt) => {
                if (evt.target.checked) {
                  setUploadCR(false);
                }
              }}
            />
            <AppBody pClass="Body2bold" type="label" htmlFor="non_owner_option2">
              {t('company_account.no_manager')}
            </AppBody>
          </div>
        </div>
      </div>

      {!uploadCR && <NonCompanyOwnerContactDetails />}
    </div>
  );
}

export default CompanyOwnerData;
